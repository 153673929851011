<div class="container-fluid">
  <div class="row mt50px justify-content-center overflow-hidden">
    <div class="col-12 col-md-10">
      <div class="d-flex titlerow">
        <div class="titleTriangleworks"
      >LATEST DESIGN SPACES
  </div>
      <span class="arrow-down"></span>
    <img
      src="../assets/images/Fill-117.svg"
      class="smallLogoIcon zI1"
      alt="vwuy"
    />
      </div>
    </div>
    <!-- <div [innerHtml]="yt_iframe_html"></div>
    <div [innerHtml]="vimeo_iframe_html"></div> -->
    <div class="col-sm-12 col-md-10 mt50px mb-3">

      <div id="LDS1CarouselControls" class="carousel slide ml15per" data-ride="" data-pause="false">
        <div class="carousel-inner">

          <div class="carousel-item active">
            <iframe
            src="https://www.youtube.com/embed/21KjRjtrbJM"
            frameborder="0"
            allowfullscreen
          ></iframe>

      </div>
            <div class="carousel-item">
              <iframe
              src="https://www.youtube.com/embed/IKgtsi78Pz0"
              frameborder="0"
              allowfullscreen
            ></iframe>

        </div>
        <a class="carousel-control-prev d-none" id="LDS1CarouselPrev" href="#LDS1CarouselControls" role="button"
          data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next d-none" id="LDS1CarouselNext" href="#LDS1CarouselControls" role="button"
          data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <div class="LDSCarouselDisc p-4"> 
        <div id="LDS2CarouselControls" class="carousel slide" data-ride="" data-pause="false">
          <div class="carousel-inner">
            <ng-container *ngFor="let space of response?.data; let i=index">
              <div class="carousel-item" [ngClass]="{'active':i===0}">
                <div class="LDSCarouselItem">
                  <h6>{{space.type.replace('_', ' ')}}</h6>
                  <h4>{{space.attributes.title}}</h4>
                  <p>{{space.attributes.description}}</p>
                  <div class="d-flex align-items-center">
                    <div class="mr-4 profileSection">
                      <h6 class="mb-0">{{space.attributes.customer_name}}</h6>
                      <p>{{space.attributes.customer_location}}</p>
                    </div>
                    <img [src]="space.attributes.customer_image" alt="customer_image" onerror="src='https://s3.amazonaws.com/arrivae-production/design_spaces/customer_images/000/000/003/medium/radhika.jpg?1609826439'" class="cus_img pb-3" />
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <a class="carousel-control-prev d-none" id="LDS2CarouselPrev" href="#LDS2CarouselControls" role="button"
            data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next d-none" id="LDS2CarouselNext" href="#LDS2CarouselControls" role="button"
            data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <div class="d-flex mt-4 position-relative justify-content-center" >

          <button class="prevButton cusBtn position-relative" (click)="carouserHandler('prev')">
            <span><i class="fa fa-arrow-left prevButtonIcon"></i></span> Prev
            <div class="mask1"></div>
            <div class="mask2"></div>
            <div class="mask3"></div>
          </button>
          <button class="nextButton cusBtn position-relative" (click)="carouserHandler('next')">
            <span>Next <i class="fa fa-arrow-right nextButtonIcon"></i></span>
            <div class="mask1"></div>
            <div class="mask2"></div>
            <div class="mask3"></div>
          </button>
        </div>
      </div> 
    </div>
  </div>
</div>
</div>


<!-- <div class="container-fluid">
  <div class="col-12 col-md-10">
    <div class="d-flex">
      <div class="titleTriangle"
    >LATEST DESIGN SPACES
</div>
    <span class="arrow-down"></span>
  <img
    src="../assets/images/Fill-117.svg"
    class="smallLogoIcon zI1"
    alt="vwuy"
  />
    </div>
  </div>
</div>
<div id="slider" class="carousel slide" data-ride="carousel">
  <ol class="carousel-indicators">
    <li data-target="#slider" data-slide-to="0" class="active"></li>
    <li data-target="#slider" data-slide-to="1"></li>
  </ol>
  <div class="carousel-inner">
    <div class="carousel-item active" >
      <iframe
              src="https://www.youtube.com/embed/IKgtsi78Pz0"
              frameborder="0"
              allowfullscreen
            ></iframe>
    </div>
    <div class="carousel-item">
      <iframe
  src="https://www.youtube.com/embed/21KjRjtrbJM"
  frameborder="0"
  allowfullscreen
></iframe>
    </div> -->
    <!-- <div class="container-fluid profile">
      <div class="row py-3 items-carous justify-content-center">
        <div class="col-6 col-md-2">
          <div class="d-flex justify-content-center">
            <div class="footer1">
              <img src="../../../assets/images/footer-1.png" />
            </div>
            <div>
              <div class="text12500">12500</div>
              <div class="textProDel">Project Deliver</div>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-2">
          <div class="d-flex justify-content-center">
            <div class="footer1">
              <img src="../../../assets/images/footer-2.png" />
            </div>
            <div>
              <div class="text12500">19 Cities</div>
              <div class="textProDel">and counting</div>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-2">
          <div class="d-flex justify-content-center">
            <div class="footer1">
              <img src="../../../assets/images/footer-3.png" />
            </div>
            <div>
              <div class="text12500">10 Lakh</div>
              <div class="textProDel">Designs</div>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-2">
          <div class="d-flex justify-content-center">
            <div class="footer1">
              <img src="../../../assets/images/footer-4.png" />
            </div>
            <div>
              <div class="text12500">45 Days</div>
              <div class="textProDel">Delivery</div>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-2">
          <div class="d-flex justify-content-center">
            <div class="footer1">
              <img src="../../../assets/images/footer-5.png" />
            </div>
            <div>
              <div class="text12500">10 Years</div>
              <div class="textProDel">Warranty</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  
