import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-vrlinks',
  templateUrl: './vrlinks.component.html',
  styleUrls: ['./vrlinks.component.scss']
})
export class VRlinksComponent implements OnInit {
  sanitizedUrl!: SafeUrl;
  vrlink: any;

  constructor(
    public route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.vrlink=params['link']
    });
    this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.vrlink);

  }
}
