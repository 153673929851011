import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApartmentService } from 'src/app/apartment/apartment.service';
declare var $:any;
declare function designpersondetail(
  param1: any,
  param2: any,
  param3: any,
  param4: any,
  param5: any
): any;
@Component({
  selector: 'app-spaces-get-this-design-form',
  templateUrl: './spaces-get-this-design-form.component.html',
  styleUrls: ['./spaces-get-this-design-form.component.scss']
})
export class SpacesGetThisDesignFormComponent implements OnInit {

  @Input() public items: any;
  @Input() public data: any;
  @Input() public num:any;
  

  constructor( private modal: NgbActiveModal,
    private fb: FormBuilder, private apartmentservice:ApartmentService,
    private toastr:ToastrService) { }

  spacesGetThisDesignForm1:any= FormGroup;
  spacesGetThisDesignForm2:any= FormGroup;
  spacesGetThisDesignForm3:any= FormGroup;
  spacesGetThisDesignForm4:any= FormGroup;

  disabledCity: boolean = true;
  selectedCityname:any;
  pincodedata:any;

  isChecked: boolean = false;
  leadData:any;
  updateLeadData:any;
  selectedScopeWork:any;
  hidesearch : boolean = false;
  mymodelpincode:any =  '';
  mymodelcheckbox:any = '';
  hideFinancialAssistance: boolean = false;
  selectedHomeloadValue:any
  possession_date:any
  project_start_date:any;
  loader:boolean = false;

  ngOnInit(): void {
    this.createFirstForm();
    this.createSecondForm();
    this.createThirdForm();
    this.createForthForm();
    this.spacesGetThisDesignForm1.get('city').disable();
    this.getNext12MonthNamesWithYear()
    this.loadScript()
  }

  onBlurName(event: any) {
    if (event.target.value !== "") {
      this.errormsgname = false;
    }
  }
  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  onBlur(event:any) {
    if (event.target.value.length === 10) {
      this.errormsgmobile = false;
    }
  }

  activbtn:any = {
  pillsActiveOne:   true,
  pillsActiveTwo:  false,
  pillsActiveThree: false,
  pillsActiveFour:  false,
  }
  
  changePillsOne() {
    this.activbtn.pillsActiveOne = true;
    this.activbtn.pillsActiveTwo = false;
    this.activbtn.pillsActiveThree = false;
    this.activbtn.pillsActiveTwo = false;
  }

  changePillsTwo() {
    this.activbtn.pillsActiveOne = true;
    this.activbtn.pillsActiveTwo = true;
    this.activbtn.pillsActiveThree = false;
    this.activbtn.pillsActiveTwo = false;
  }

  changePillsThree() {
    this.activbtn.pillsActiveOne = true;
    this.activbtn.pillsActiveTwo = true;
    this.activbtn.pillsActiveThree = true;
    this.activbtn.pillsActiveTwo = false;
  }

  changePillsFour() {
    this.activbtn.pillsActiveOne = true;
    this.activbtn.pillsActiveTwo = true;
    this.activbtn.pillsActiveThree = true;
    this.activbtn.pillsActiveTwo = true;
  } 

  createFirstForm() {
    this.spacesGetThisDesignForm1 = this.fb.group({
      fullName: ['', [Validators.required]],
      mobileNumber: ['', [Validators.required]],
      emailAddress: [''],
      scopeOfWork:[''],
      city: [''],
      pincode: ['']
    });
  }

  get f() { return this.spacesGetThisDesignForm1.controls; }

  createSecondForm() {
    this.spacesGetThisDesignForm2 = this.fb.group({
      propertyName: [''],
      accomandationType: [''],
      homeValue: [''],
      doYouHaveFloorPlan: [''],
      uploadfloorPlanFile:[''],
    });
  }

  createThirdForm() {
    this.spacesGetThisDesignForm3 = this.fb.group({
      lookingFor: [''],
      possessionProperty_date: [''],
      startProject_date: [''],
      yourBudge:[''],
    });
  }

  createForthForm() {
    this.spacesGetThisDesignForm4 = this.fb.group({
      existingHomeLone: [''],
      financialassistance: [''],
      purposeProperty: [''],
      tellMeAboutYourself: ['']
    });
  }

  onChangehomeloanvalue(event:any) {
    this.selectedHomeloadValue = event.target.value;
    if(this.selectedHomeloadValue === 'Yes') {
      this.hideFinancialAssistance = true;
    } else {
      this.hideFinancialAssistance = false
    }
  }

  scopeWork(event:any) {
    this.selectedScopeWork = event.target.value
    if(!this.selectedScopeWork) {
      this.spacesGetThisDesignForm1.get('city').disable();
    } else {
      this.spacesGetThisDesignForm1.get('city').enable();
    }
  }

  selectedCity(event:any) {
    this.selectedCityname = event.target.value;
    this.apartmentservice.getPincode(this.selectedCityname).subscribe(res =>{
      this.pincodedata = res;
    });
  }

  valuechange(newValue:any) {
    this.mymodelpincode = newValue;
    if(!this.mymodelpincode) {
      this.hidesearch = false
    } else {
      this.hidesearch = true
    }
  }

  mymodelcheckboxYes:boolean = true
  mymodelcheckboxNo:boolean = false
  radiobuttonUploadCondition: boolean = false
  valuechangeUploadYes(event:any) {
    this.mymodelcheckboxYes = event;
    this.radiobuttonUploadCondition = true;
  }

  valuechangeUploadNo(event:any) {
    this.mymodelcheckboxNo = event;
    this.radiobuttonUploadCondition = false;
  }

  valuechangeUploadCheckbox(event:any) {
    this.mymodelcheckbox = event;
  }

  cancelOne() {
    this.modal.close();
  }
  email_validation: boolean = false;
  firstValidation() {
    var email = /^[(A-Z)(a-z)0-9._%+-]+@[(A-Z)(a-z)0-9.-]+\.[(a-z)(A-Z)]{2,4}$/;
    if (this.spacesGetThisDesignForm1.valid) {
      if (this.spacesGetThisDesignForm1.value.emailAddress) {
        if (this.spacesGetThisDesignForm1.value.emailAddress.match(email)) {
          this.email_validation = false;
          this.errormsgname = false;
          this.errormsgmobile = false;
          designpersondetail(
            this.spacesGetThisDesignForm1.value.fullName,
            this.spacesGetThisDesignForm1.value.mobileNumber,
            this.spacesGetThisDesignForm1.value.emailAddress,
            this.spacesGetThisDesignForm1.value.scopeOfWork,
            this.spacesGetThisDesignForm1.value.pincode
          );
          this.nextOne('pills-2','pills-1','pills-2-tab','pills-1-tab');
        }
        else {
          this.email_validation = true;
        } 
      }
      else {
        this.email_validation = false;
        this.errormsgname = false;
        this.errormsgmobile = false;
       this.nextOne('pills-2','pills-1','pills-2-tab','pills-1-tab');
      }
    }else{
      this.errormsgname = true;
      this.errormsgmobile = true;
    }
  }
  errormsgname: boolean = false;
  errormsgmobile: boolean = false;
  nextOne(next:any,prev:any,activetab:any,deactivetab:any) {
    if (this.spacesGetThisDesignForm1.valid) {
      $('#'+next).show();
      $('#'+prev).hide();
      $("#"+activetab).addClass("active");
    }
  }

  nextOne1(next:any,prev:any,activetab:any,deactivetab:any) {
    $('#'+next).show();
      $('#'+prev).hide();
      $("#"+activetab).addClass("active");
     $("#"+deactivetab).removeClass("active");
  }

  nextOne2(next:any,prev:any,activetab:any,deactivetab:any) {
    $('#'+next).show();
      $('#'+prev).hide();
      $("#"+activetab).addClass("active");
     $("#"+deactivetab).removeClass("active");
  }

  nextOne3(next:any,prev:any,activetab:any,deactivetab:any) {
    $('#'+next).show();
      $('#'+prev).hide();
      $("#"+activetab).addClass("active");
     $("#"+deactivetab).removeClass("active");
  }

  onSubmit() {
    this.loader = true;
    const postObj =
     {
        lead: {
           "name" : this.spacesGetThisDesignForm1.value.fullName,
           "contact" : this.spacesGetThisDesignForm1.value.mobileNumber,
           "lead_source": 'website-space',
           "lead_campaign": '',
           'lead_utm_medium': '',
           'lead_utm_term': '',
           'lead_utm_content': '',
           'email': this.spacesGetThisDesignForm1.value.emailAddress,
           'scope_of_work': this.spacesGetThisDesignForm1.scopeOfWork,
           'city': this.spacesGetThisDesignForm1.value.city,
           'pincode': this.spacesGetThisDesignForm1.value.pincode,
           'location': this.spacesGetThisDesignForm1.value.city,
           'property_name': this.spacesGetThisDesignForm2.value.propertyName,
           'additional_comments': this.spacesGetThisDesignForm4.value.tellMeAboutYourself || "",
        }
    }

    if(this.spacesGetThisDesignForm1.valid) {
      this.apartmentservice.createBookConsultation(postObj).subscribe(res =>{
        this.leadData = res;
        this.modal.close(res);
        
        if (this.leadData.lead.duplicate === false) {
          if(this.spacesGetThisDesignForm1.value.emailAddress || this.spacesGetThisDesignForm1.value.scopeOfWork || this.spacesGetThisDesignForm1.value.city || this.spacesGetThisDesignForm1.value.pincode || this.spacesGetThisDesignForm3.value.possessionProperty_date ||     this.spacesGetThisDesignForm3.value.startProject_date || this.spacesGetThisDesignForm2.value.propertyName || this.spacesGetThisDesignForm4.value.purposeProperty || 
            this.spacesGetThisDesignForm3.value.yourBudge || this.spacesGetThisDesignForm2.value.accomandationType || this.spacesGetThisDesignForm2.value.homeValue
            || this.spacesGetThisDesignForm4.value.existingHomeLone || this.spacesGetThisDesignForm4.value.tellMeAboutYourself || this.fileupload || this.data || this.items
          ){
            this.updateleadstatusdata();
          }
          else{
            this.toastr.success("Thanks for sharing your preferences. Our designers will get in touch with you in 24 hours");
          }
        }
        else{
          this.loader = false;
          this.toastr.error('User Already Exist');
        }

 
      }, error =>  {
        this.toastr.error(error.error.message);
         this.modal.close();
      });
    } else {
      this.toastr.error('Please enter the valid details');
    }
  
  }


  fileupload:any;
  onFileSelect(event:any){
    if (event.target.files.length > 0) {
      this.fileupload = event.target.files[0];
    }
    
  }

  updateleadstatusdata() {
    this.all_items = [];

    if (this.num === 1) {
      for (let i = 0; i < this.data.space_items.data.length; i++){
        this.all_items.push({
          'name': this.data.space_items.data[i].attributes.item_name,
          'price': 0,
          'quantity': 1
        })
      }
    }

    let formdt = new FormData();

    formdt.append(
      'lead_source','website-spaces'
    )
    formdt.append(
    "accomodation_type", this.spacesGetThisDesignForm2.value.accomandationType
    )
    formdt.append(
    "possession_status_date", this.spacesGetThisDesignForm3.value.possessionProperty_date
    )
    formdt.append(
      "pincode", this.spacesGetThisDesignForm1.value.pincode
    )
    formdt.append(
      "purpose_of_property",this.spacesGetThisDesignForm4.value.purposeProperty
    )
    formdt.append(
      "lead_campaign", 'null'
    )
    formdt.append(
      "lead_source", 'website-spaces'
    )
    formdt.append(
      "location", ''
    )
    formdt.append(
    "email", this.spacesGetThisDesignForm1.value.emailAddress      
    )
    formdt.append(
    "scope_of_work", this.spacesGetThisDesignForm1.value.scopeOfWork
    )
    formdt.append(
      "city", this.spacesGetThisDesignForm1.value.city
    )
    formdt.append(
      "project_start_date", this.spacesGetThisDesignForm3.value.startProject_date 
    )
    formdt.append(
    "property_name", this.spacesGetThisDesignForm2.value.propertyName
    )
    formdt.append(
    "customer_budget",this.spacesGetThisDesignForm3.value.yourBudge
    )
    formdt.append(
    "home_value", this.spacesGetThisDesignForm2.value.homeValue
    )
    formdt.append(
    "have_home_loan", this.spacesGetThisDesignForm4.value.existingHomeLone
    )
    formdt.append(
      "home_type",this.spacesGetThisDesignForm3.value.lookingFor
    )
    
    if (this.fileupload) {
      formdt.append(
        'lead_floorplan[]',  this.fileupload, this.fileupload.name
      ) 
    }
    else {
      formdt.append(
        'lead_floorplan[]',  '',
      )
    }
    formdt.append(
      "from_fasttrack_page",'true'
    )
    formdt.append(
      "possession_status",this.spacesGetThisDesignForm3.value.possessionProperty_date ? 'new':''
    )
    //Lead status data
    if (this.spacesGetThisDesignForm3.value.possessionProperty_date && this.spacesGetThisDesignForm3.value.startProject_date) {
      if (this.spacesGetThisDesignForm1.value.scopeOfWork == "Full Home Interiors") {
        if (["Mumbai"].includes(this.spacesGetThisDesignForm1.value.city) && (["70 - 99 Lacs", "1 - 1.25 Cr", "1.26 - 1.75 Cr", "1.76 - 2.50 Cr", "2.5 Cr - 4.00 Cr", "Above 4.00 Cr"].includes(this.spacesGetThisDesignForm2.value.homeValue))) {
          formdt.append('lead_status', "not_attempted");
        }
        else if (["Delhi NCR", "Ghaziabad", "Faridabad"].includes(this.spacesGetThisDesignForm1.value.city) && (["1 - 1.25 Cr", "1.26 - 1.75 Cr", "1.76 - 2.50 Cr", "2.5 Cr - 4.00 Cr", "Above 4.00 Cr"].includes(this.spacesGetThisDesignForm2.value.homeValue))) {
          formdt.append('lead_status', "not_attempted");
        }
        else if (["Bangalore", "Pune"].includes(this.spacesGetThisDesignForm1.value.city) && (["30 - 50 Lacs", "50 - 70 Lacs", "70 - 99 Lacs", "1 - 1.25 Cr", "1.26 - 1.75 Cr", "1.76 - 2.50 Cr", "2.5 Cr - 4.00 Cr", "Above 4.00 Cr"].includes(this.spacesGetThisDesignForm2.value.homeValue))) {
          formdt.append('lead_status', "not_attempted");
        }
        else{
          formdt.append('lead_status', "not_attempted");
        }
      }
      else {
        if (this.spacesGetThisDesignForm3.value.yourBudge != "Less than 1.5 Lac") {
          if (["Mumbai"].includes(this.spacesGetThisDesignForm1.value.city) && (["70 - 99 Lacs", "1 - 1.25 Cr", "1.26 - 1.75 Cr", "1.76 - 2.50 Cr", "2.5 Cr - 4.00 Cr", "Above 4.00 Cr"].includes(this.spacesGetThisDesignForm2.value.homeValue))) {
            formdt.append('lead_status', "not_attempted");
          }
          else if (["Delhi NCR", "Ghaziabad", "Faridabad"].includes(this.spacesGetThisDesignForm1.value.city) && (["1 - 1.25 Cr", "1.26 - 1.75 Cr", "1.76 - 2.50 Cr", "2.5 Cr - 4.00 Cr", "Above 4.00 Cr"].includes(this.spacesGetThisDesignForm2.value.homeValue))) {
            formdt.append('lead_status', "not_attempted");
          }
          else if (["Bangalore", "Pune", "Chennai", "Aurangabad", "Ahmednagar", "Kochi"].includes(this.spacesGetThisDesignForm1.value.city) && (["30 - 50 Lacs", "50 - 70 Lacs", "70 - 99 Lacs", "1 - 1.25 Cr", "1.26 - 1.75 Cr", "1.76 - 2.50 Cr", "2.5 Cr - 4.00 Cr", "Above 4.00 Cr"].includes(this.spacesGetThisDesignForm2.value.homeValue))) {
            formdt.append('lead_status', "not_attempted");
          }
        }
        else{
          formdt.append('lead_status', "not_attempted");
        }
      }
    }
    else {
      formdt.delete('lead_status');
      formdt.append('lead_status', "not_attempted");
    }
    //Lead status data end
    formdt.append(
    "have_floorplan", ((this.radiobuttonUploadCondition === true) ? "Yes" : "No")
    )
    // formdt.append(
    //   "purpose_of_property",(this.spacesGetThisDesignForm4.value.purposeProperty === "Yes" ? this.spacesGetThisDesignForm4.value.financialassistance : null)
    // )
    formdt.append(
    "additional_comments", this.spacesGetThisDesignForm4.value.tellMeAboutYourself
    )
    formdt.append(
      "financial_solution_required",(this.spacesGetThisDesignForm4.value.existingHomeLone === "Yes" ? this.spacesGetThisDesignForm4.value.financialassistance : null)
    )

    formdt.append(
      "lead_questionaire_items_attributes", JSON.stringify(this.num === 2 ? this.items != undefined ? this.items : 'null' : this.all_items != undefined ? this.all_items : 'null')
    )

    this.apartmentservice.updateItems(this.leadData.lead.id,formdt).subscribe(res =>{
      this.updateLeadData = res;
      this.loader = false;
       this.toastr.success("Thanks for sharing your preferences. Our designers will get in touch with you in 24 hours");
      this.modal.close(res);
    }, error =>{
      this.toastr.error(error.error.message);
    });
  }

  cancelTwo() {
    this.modal.close();
  }
  nextTwo() {
  }
  cancelThree() {
    this.modal.close();
  }
  nextThree() {
  }
  cancelFour() {
    this.modal.close();
  }
  submit() {
  }

 

  // ------------ Manually data for dropdowns (dont change any values)------------

  getNext12MonthNamesWithYear() {
    var now = new Date();
    var month = now.getMonth();
    var year = now.getFullYear();
    var names = ['January', 'February', 'March', 'April', 'May', 'June',
                 'July', 'August', 'September', 'October', 'November', 'December'];
        this.possession_date = [];
        for (var i = 0; i < 13; ++i) {
            this.possession_date.push(names[month] + ' ' + year);
            if (++month === 12) {
                month = 0;
                ++year;
            }
        }
        this.project_start_date = this.possession_date;
        return this.possession_date;
    }

  CityData: any = [
    {id:0, cityname:"Delhi NCR"},
    {id:1, cityname:"Mumbai"},
    {id:2, cityname:"Pune"},
    {id:3, cityname:"Bangalore"},
    {id:4, cityname:"Chennai"},
    {id:5, cityname:"Chandigarh"},
    {id:6, cityname:"Ahmedabad"},
    {id:7, cityname:"Kochi"},
    {id:8, cityname:"Aurangabad"},
    {id:9, cityname:"Trivandrum"},
    {id:10, cityname:"Calicut"},
    {id:11, cityname:"Faridabad"},
    {id:12, cityname:"Hyderabad"},
    {id:13, cityname:"Jaipur"}
  ]

  ScopeWorkData: any = [
    {id:0, scopeworkname:"Full Home Interiors"},
    {id:1, scopeworkname:"Modular Kitchen & Wardrobe"},
    {id:2, scopeworkname:"Modular Kitchen"},
    {id:3, scopeworkname:"Modular Wardrobe"},
  ]

  AccomandationTypeData: any = [
    {id:0, accomandationtname:"1RK"},
    {id:1, accomandationtname:"1BHK"},
    {id:2, accomandationtname:"1.5BHK"},
    {id:3, accomandationtname:"2BHK"},
    {id:4, accomandationtname:"2.5BHK"},
    {id:5, accomandationtname:"3BHK"},
    {id:6, accomandationtname:"3.5BHK"},
    {id:7, accomandationtname:"4BHK"},
    {id:8, accomandationtname:"4.5BHK"},
    {id:9, accomandationtname:"5BHK"},
    {id:10, accomandationtname:"Villa"},
    {id:11, accomandationtname:"Bungalow"},

  ]

  HomeValueData: any = [
    {id:0, homevalue:"Less than 30 Lacs"},
    {id:1, homevalue:"30 - 50 Lacs"},
    {id:2, homevalue:"50 - 70 Lacs"},
    {id:3, homevalue:"70 - 99 Lacs"},
    {id:4, homevalue:"1 - 1.25 Cr"},
    {id:5, homevalue:"1.26 - 1.75 Cr"},
    {id:6, homevalue:"1.76 - 2.50 Cr"},
    {id:7, homevalue:"2.5 Cr - 4.00 Cr"},
    {id:8, homevalue:"Above 4.00 Cr"},
  ]

   LookingForData: any = [
     {id:1, value:'New (No one has ever stayed in that home)'},
     {id:1, value:'Old (Currently staying in the house)'}
   ]
  
  PurposeOfProperty: any = [
     { id:0, value:'Own Stay'},
     { id:1, value:'Rental' },
     { id:2, value:'Not Disclosed'},
  ]

   BudgeValueData:any = [
    {id:0, budgevalue:"Less than 1.5 Lacs"},
    {id:0, budgevalue:"1.5 - 3 Lacs"},
    {id:0, budgevalue:"3 - 5 Lacs"},
    {id:0, budgevalue:"5 - 8 Lacs"},
    {id:0, budgevalue:"8 - 12 Lacs"},
    {id:0, budgevalue:"Above 12 Lacs"},
    {id:0, budgevalue:"Not Disclosed"},
   ]

   homeloadData:any = [
     {id:1, value: "Yes"},
     {id:2, value: "No"}
   ]
   financialassistanceData: any = [
    {id:1, value: "Yes"},
    {id:2, value: "No"}
   ]
  
  
  all_items: any = [];


}
