import { Component, OnInit, Sanitizer } from '@angular/core';
import { Slick } from 'ngx-slickjs';
declare var $:any;

@Component({
  selector: 'app-customer-review',
  templateUrl: './customer-review.component.html',
  styleUrls: ['./customer-review.component.scss']
})

export class CustomerReviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  videoslist = [
    { image: "assets/Longform/images/video1.webp", url:'K32d62yDqxc', title: "3BHK, Made By The Bhargavas", location: "Whitefield, Bengaluru", videourl: "video1.mp4", descript:"I'm so happy! What I saw in 3D is exactly what I got. So that is the best part! I got regular mails from them.They were connected with me. When there was a small concern, they attended it. Immediately I got a call from the Mumbai office. So I'm very happy with their service. What they promised they'd delivered." },
    { image: "assets/Longform/images/video2.webp", url:'sdL3SzUNcGw', title: "3BHK, Made By The Bhatts", location: "Serilingampally, Hyderabad", videourl: "video2.mp4", descript:"We are very, very happy the way Arrivae has done the job, the timely delivery and as well as the cooperation throughout the project! It was entirely a beautiful journey for us. We never felt that we have been doing such a great change in the house.”" },
    { image: "assets/Longform/images/video3.webp", url:'MKSqjdsLjpY', title: "2BHK, Made By The Mausles", location: "Andheri, Mumbai", videourl: "video3.mp4", descript:"“My experience with Arrivae was fabulous— perfectly blending my ideas, my imagery with their expertise and experience in this particular field to bring out a beautifully decorated home. Throughout the design and development process, Arrivae's attention to detail was impeccable and second to none.”" },
    { image: "assets/Longform/images/video6.webp", url:'crszMbpKJag', title: "2BHK, Made By The Khots", location: "Nerul, Navi Mumbai", videourl: "video4.mp4",descript:"“When I had this house interior project, I did extensive research on multiple agencies, multiple companies. They have a personal interaction with the client, so that's why I chose Arrivae. What I liked about Arrivae was their openness to my ideas and my suggestions and my thoughts, and then they took it from me and then they transformed into something that really worked for us.”" },
    { image: "assets/Longform/images/video5.webp", url:'NmNTN357uKU', title: "2BHK, Made By The Rajes", location: "Balkum, Thane", videourl: "video5.mp4", descript:"I'm so happy! What I saw in 3D is exactly what I got. So that is the best part! I got regular mails from them.They were connected with me. When there was a small concern, they attended it. Immediately I got a call from the Mumbai office. So I'm very happy with their service. What they promised they'd delivered." },
    { image: "assets/Longform/images/video4.webp", url:'HZMaErn5sjM', title: "3BHK, Made By The Goyals", location: "Gurugram", videourl: "video6.mp4", descript:"We are very, very happy the way Arrivae has done the job, the timely delivery and as well as the cooperation throughout the project! It was entirely a beautiful journey for us. We never felt that we have been doing such a great change in the house.”" }
  ];

  playthevideo=false;
  id:any='K32d62yDqxc'


  public ytEvent:any

  private player! :YT.Player

  playerVars = {
    cc_lang_pref: 'en',
  };

  videoWidth: any  = 100+"%"
  videoHeight:any  = 100+"%"


  savePlayer(player:any) {
    this.player = player;
  }
  onStateChange(event:any) {
    this.ytEvent = event.data;
  }

  pauseVideo() {
    this.player.pauseVideo();
  }

  mobilevideoPlayIn(id :any){
    this.playthevideo = true
    this.id =  id
    this.playVideo(id)
  }
  playVideo(id:any) {
    this.player.loadVideoById(id)
    this.player.playVideo();

  }

  isModalOpen = false; // Track modal state
  currentVideoUrl:any='https://www.youtube.com/embed/9L8szVqoJr8';

  openVideoModal(videoUrl: string) {
    $('#Youtubevideoplayer').modal('show');
    console.log(videoUrl)
    this.isModalOpen = true;
    this.id =  videoUrl
    this.player.loadVideoById(videoUrl)
    this.player.playVideo();
    
  }
  // Method to close the modal
  closeVideoModal() {
    if (this.player) {
      this.player.pauseVideo()
    }

    this.isModalOpen = false;
  }

  config: Slick.Config = {
    centerMode : true,
    centerPadding : '60px',
    infinite: true,
    slidesToShow: 4,
    // slidesToScroll: 1,
    // dots: false,
    autoplay: false,
    autoplaySpeed: 2000,
    draggable: true,
    mouseWheelMove: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          centerMode: true,
          centerPadding : '40px',

        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding : '40px',
          arrows : false,
          centerMode : true
        },
      },
    ],
  };




}
