import { Component, OnInit } from '@angular/core';
import { Slick } from 'ngx-slickjs';

@Component({
  selector: 'app-arriave-living',
  templateUrl: './arriave-living.component.html',
  styleUrls: ['./arriave-living.component.scss']
})
export class ArriaveLivingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  config: Slick.Config = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    draggable: true,
    mouseWheelMove: false,
    arrows: false,
    responsive: [

      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  roletoSet(){
    var width = Math.max(window.screen.width, window.innerWidth)
     return width

  }
}
