import { Component, OnInit,ElementRef, ViewChild,AfterViewInit  } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared/shared.service';
import { LandingService } from '../landing.service';
import { BookConsultationFormComponent } from 'src/app/shared/book-consultation-form/book-consultation-form.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Slick } from 'ngx-slickjs';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";



@Component({
  selector: 'app-review-detail',
  templateUrl: './review-detail.component.html',
  styleUrls: ['./review-detail.component.scss']
})
export class ReviewDetailComponent implements OnInit {

  @ViewChild('leftside') leftSide!: ElementRef;
  @ViewChild('rightside') rightSide!: ElementRef;

  ngAfterViewInit() {
    // Initial synchronization of scroll positions
    this.syncRightScroll();
    this.rightSide.nativeElement.scrollTop = 0;

  }

  onLeftScroll(e:any) {
    // If left side reaches the bottom, scroll the right side to the bottom
    const leftScrollTop = this.leftSide.nativeElement.scrollTop;
    const leftScrollHeight = this.leftSide.nativeElement.scrollHeight;
    const leftClientHeight = this.leftSide.nativeElement.clientHeight;

    // Calculate if the left side has reached the bottom
    const leftAtBottom = leftScrollTop + leftClientHeight >= leftScrollHeight - 1;

    // Smoothly synchronize the right side with the left side's scroll position
    this.rightSide.nativeElement.scrollTo({
        top: leftScrollTop,
        behavior: 'smooth'
    });

    // If the left side reaches the bottom, smoothly scroll the right side to the bottom
    if (leftAtBottom) {
        this.rightSide.nativeElement.scrollTo({
            top: this.rightSide.nativeElement.scrollHeight - this.rightSide.nativeElement.clientHeight,
            behavior: 'smooth'
        });
    }

  }

  syncRightScroll() {
    // Synchronize the right side to scroll to the bottom
    this.rightSide.nativeElement.scrollTop = this.rightSide.nativeElement.scrollHeight - this.rightSide.nativeElement.clientHeight;
  }



  constructor(
    private toastr: ToastrService,
    private sharedService: SharedService,
    private route:ActivatedRoute,
    private router: Router,
    private landing : LandingService,
    private modalService: NgbModal,
    private sanitize: DomSanitizer,

  ) { }

  ReviewId:any
  ReviewDetail:any
  video:SafeResourceUrl | undefined;
  medium_image:any
  loader = false
  IsProjectDetails = false;



  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.ReviewId = params['Review_id'];
    });
    this.GetReviewDetail();
  }

  Setmedium(url:any){
    this.iframeon=false
    this.medium_image = url
  }

  images: string[] = [
    "../../../assets/images/latestblog1.png",
    "../../../assets/images/latestblog2.png",
    "../../../assets/images/latestblog3.png",
    "../../../assets/images/latestblog1.png",
    "../../../assets/images/latestblog2.png",
    "../../../assets/images/latestblog3.png",
  ];
  
 
  ShowProjectDetails(){
    this.IsProjectDetails = ! this.IsProjectDetails
  }

  GetReviewDetail(){
    this.images =[];
    this.loader = true
    this.landing.GetReview(this.ReviewId).subscribe((res:any)=>{
    if(res.data.attributes.video_url){
      const url=res.data.attributes.video_url
      const videoId = url.split('v=')[1]
      this.video=this.sanitize.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/'+videoId);

    }
    this.ReviewDetail = res.data.attributes;
    this.images = this.ReviewDetail.images.map((image:any) => image.medium);
    this.images.unshift(this.ReviewDetail.feature_url	)
    this.medium_image = this.images[0]
    setTimeout(()=>{
     this.loader = false
    },2000)
  })
  console.log(this.ReviewDetail.images,this.images)
}

sanitizeUrl(url: string): SafeResourceUrl {
  return this.sanitize.bypassSecurityTrustResourceUrl(url);
}


OpenInDeatil(id:any){
  window.open('/review/detail?Review_id=' + id, '_self');
}

bookConsultationLandingPage() {
  const modal = this.modalService.open(BookConsultationFormComponent, {
    size: 'md',
  });
  if (modal.componentInstance) {
    modal.componentInstance.page = "get_the_price";
  }
}

config: Slick.Config = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  centerPadding: '25%', 
  autoplaySpeed: 2000,
  draggable: true,
  mouseWheelMove: false,
  arrows: false,
  centerMode: true, 
  responsive: [

    {
      breakpoint: 1700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '0%', 
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '0%', 
      },
    },
  ],
};
config2: Slick.Config = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  centerPadding: '20%', 
  autoplaySpeed: 2000,
  draggable: true,
  mouseWheelMove: false,
  arrows: false,
  centerMode: true, 
  responsive: [

    {
      breakpoint: 1700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '10%', 
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '10%', 
      },
    },
  ],
};
config3: Slick.Config = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  dots: false,
  centerPadding: '0', 
  autoplaySpeed: 2000,
  draggable: true,
  mouseWheelMove: false,
  arrows: false,
  
  responsive: [

    {
      breakpoint: 1700,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: '0%', 
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: '0%', 
      },
    },
  ],
};
roletoSet(){
  var width = Math.max(window.screen.width, window.innerWidth)
   return width

}
playvideo(){
  this.iframeon=true
}

navigateAll(){
  this.router.navigate(['/Reviews'])
}
navigateAllspace(){
  this.router.navigate(['/spaces'])
}
ClickOnSpace(id:any){
  window.open('/space-inclusion/' + id, '_blank');
}
iframeon:any=false

}
