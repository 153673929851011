import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-testimonials',
  templateUrl: './customer-testimonials.component.html',
  styleUrls: ['./customer-testimonials.component.scss']
})
export class CustomerTestimonialsComponent implements OnInit {

  customerTestimonials: any = [
    {
      id: 1,
      content: 'We feel blessed that we choose Arrivae whose expert designer make our dream come true with our newly bought 2 BHK with his out of box design suggestions.',
      name: 'Swathy Sajjalgud',
      imgUrl: 'https://s3.amazonaws.com/arrivae-uat/design_spaces/customer_images/000/000/001/medium/unnamed_%281%29.png?1628147780',
    },
    {
      id: 2,
      content: 'We feel blessed that we choose Arrivae whose expert designer make our dream come true with our newly bought 2 BHK with his out of box design suggestions.',
      name: 'Suman Gupta',
      imgUrl: 'https://s3.amazonaws.com/arrivae-uat/design_spaces/customer_images/000/000/003/medium/unnamed.png?1628147740'
    },
    {
      id: 3,
      content: 'We feel blessed that we choose Arrivae whose expert designer make our dream come true with our newly bought 2 BHK with his out of box design suggestions.',
      name: 'Suman Gupta',
      imgUrl: 'https://s3.amazonaws.com/arrivae-uat/design_spaces/customer_images/000/000/003/medium/unnamed.png?1628147740'
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
