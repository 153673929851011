<div class="wrapper">
    <nav class="navbar navbar-light px-lg-5 px-3">
        <!-- <div class="d-lg-none d-block">
            <img src="../../../assets/images/icon _ chevron down_2021-12-09/icon/chevron down.png" >
        </div>  -->
        <div class="mx-auto mx-lg-0">
            <a class="navbar-brand" href="/">
                <img src="../../../assets/images/logo.png"  height="40" alt="">
            </a>
        </div>
    </nav>

    <section class="main-wrapper">
        <div class="start-container" *ngIf = to_show_form>
            <div class="steps-container">
                <h1 class="mb-4">Your Free Design Quote is just few Steps Away</h1>
                <p class="mb-4">Let us help you design your dream home with just 4 simple steps</p>
                <div class="list-container">
                    <p><span>1</span>Let's get to know you better</p>
                    <div class="dotted-line"></div>
                    <p><span>2</span>Where are you located</p>
                    <div class="dotted-line"></div>
                    <p><span>3</span>Select the spaces to be designed</p>
                    <div class="dotted-line"></div>
                    <p><span>4</span>Discuss the finances</p>
                </div>
                <button type="button" (click)= "next()" class="start-button">Let's Start <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                </svg></button>
            </div>
            <div class="background-container">
                <img class="img-fluid" src="../../../assets/images/sign-up-to-service/Group 675@3x.png" alt="background-image">
            </div>
        </div>



        <div class="container-fluid my-4" *ngIf = !to_show_form>
            <div class="row justify-content-center">
                <div class="col-md-8">
            <div id="applying_styles_steps">
            <ul  class="nav nav-pills"  id="pills-tab" role="tablist">
                <li id="border-1" class="nav-item">
                  <a
                    class="nav-link active"
                    id="pills-1-tab"
                    data-toggle="pill"
                    href="#pills-1"
                    role="tab"
                    aria-controls="pills-1"
                    aria-selected="true"
                    >1</a
                  >
                </li>
                <li id="border-2" class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-2-tab"
                    data-toggle="pill"
                    href="#pills-2"
                    role="tab"
                    aria-controls="pills-2"
                    aria-selected="false"
                    >2</a
                  >
                </li>
                <li id="border-3" class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-3-tab"
                    data-toggle="pill"
                    href="#pills-3"
                    role="tab"
                    aria-controls="pills-3"
                    aria-selected="false"
                    >3</a
                  >
                </li>
                <li id="border-4" class="nav-item">
                  <a
                    class="nav-link"
                    id="pills-4-tab"
                    data-toggle="pill"
                    href="#pills-4"
                    role="tab"
                    aria-controls="pills-4"
                    aria-selected="false"
                    >4</a
                  >
                </li>
              </ul>
            </div>
        </div>
        </div>
        </div>



            <div id="pills-1" class="pills1">
                <div class="sign-up-container" *ngIf = !to_show_form>
                    <div class="text-center know_you_better">
                        <p>Let’s get to know you better</p>
                    </div>


                    <div class="w_30_form   m-auto">
                        <form [formGroup]= "signUpForm1">
                            <div class="form-group">
                                <label for="inputName">Name</label>
                                <input type="text" class="form-control"  formControlName="name"  aria-describedby="emailHelp"  (blur)="onBlurname($event)">
                              
                                <span *ngIf="error"><p class="text-danger" *ngIf="signUpForm1.get('name').errors && 
                                    signUpForm1.get('name').hasError('required')">Name is required </p>
                                </span>
                            </div>
                            <div class="form-group">
                                <label for="email">Email ID</label>
                                <input type="text" formControlName="email"  class="form-control" [(ngModel)] ="email">

                                <!-- <div *ngIf="error">
                                    <p class="text-danger" *ngIf="email == null">Email is required </p>
                                </div>
								<div *ngIf="signUpForm1.controls['email'].errors && !signUpForm1.controls['email'].pristine" class="text-danger">
									<div>Email must be a valid email address
                                    </div>
                                </div> -->

                                <div *ngIf="error && signUpForm1.controls.email.errors">
                                    <p *ngIf="signUpForm1.controls.email.errors.required" class="text-danger" >Email is required</p>
                                    <p *ngIf="signUpForm1.controls.email.errors.email" class="text-danger">Please enter a valid Email Address</p>
                                </div>



                            </div>

                            <div class="form-group">
                                <label for="inputMobile">Phone Number</label>
                                <input onKeyPress="if(this.value.length==10) return false;" type="number" formControlName="phoneNumber" class="form-control" [(ngModel)]="mobilenumber" aria-describedby="numberHelp" (blur)="onBlurPhoneNumber($event)">

                                <!-- <div *ngIf="error">
                                    <p class="text-danger" *ngIf="mobilenumber == null">Mobile number is required</p>
                                </div>
										<div *ngIf="signUpForm1.controls['phoneNumber'].errors && !signUpForm1.controls['phoneNumber'].pristine" class="text-danger">

										  <div [hidden]="signUpForm1.controls['phoneNumber'].valid">Enter valid Number.</div>
										</div> -->

                                        <div *ngIf="error && signUpForm1.controls.phoneNumber.invalid">  
                                            <p class="text-danger" *ngIf="signUpForm1.controls.phoneNumber.errors.required">Mobile Number is required.</p>  
                                            <p class="text-danger" *ngIf="signUpForm1.controls.phoneNumber.errors.pattern">Please enter a 10-digit Mobile Number.</p>  
                                        </div>  
                            </div>

                            <div class="form-check">
                                <input type="checkbox"  class="form-check-input">
                                <label class="form-check-label" for="exampleCheck1">Get updates on WhatsApp</label>
                            </div>
                        </form>
                    </div>

                    <div class=" my-lg-5 my-0 mx-auto w_15 d-flex justify-content-around buttons_next_back">
                        
                            <button (click)= "goback()" type="button" class="btn btn-outline-primary d-lg-block d-none">Back</button>
                            <button type="button" (click) ="firstvalidation()" class="btn btn-outline-secondary">Next</button>
                            <div (click)= "goback()"   class="d-lg-none d-block mobile_view_arrow_icon">
                                <img src="../../../assets/images/icon _ chevron down_2021-12-09/icon/chevron down.png" >
                            </div> 
                    </div>
                </div>
            </div>



            
            <div id="pills-2" class="pills2">
                <div class="sign-up-container" *ngIf = !to_show_form>
                    <div class="text-center know_you_better">
                        <p>Tell us where you are located</p>
                    </div>


                    <div class="w_30_form  m-auto">
                        <form [formGroup]= "signUpForm2">
                            <div>
                            <div class="form-group" style="position: relative;">
                                <label class="email">City</label>

                                <input type="text" #selectcity  style="display: none;"   formControlName="selctCity">

                                
                                
                                <div class="customDropdown" (click)="selectStatus()" selectcity>

                                    <div class="entityInput">
                                        <!-- <div class="defaultColor">Select Employment Status</div> -->
                                        <div class="d-flex justify-content-center align-content-center">
                                            <div style="height: 45px;">
                                                <img [src]="imagesrc"/>
                                            </div>
                                            <div style="margin-left: 12px;" class="align-self-center">
                                                <span>{{selectedOptionItem}}</span>
                                            </div>
                                        </div>

                                        <!-- <div class="text-black">{{selectedOptionItem}}</div> -->

                                        <div>
                                            <img style="transform: rotate(90deg);" src="../../../assets/images/vectordown.png" alt="">
                                        </div>

                                    </div>
                                </div>
                                <!-- <span *ngIf="error">
                                    <p class="text-danger" *ngIf="selctCity == null || selctCity == '' ">Please Select City </p>
                                </span> -->

                                <div class="chooseInput" *ngIf="status == true">
                                    <div class="select">
                                        <div class="choosenInput" (click)="selectOptions('Ahmedabad','../../../assets/images/All_city_icons/Ahmedabad/Cities.png')" >
                                           <div class="d-flex">
                                                <div class="pr-2">
                                                    <img src="../../../assets/images/All_city_icons/Ahmedabad/Cities.png">
                                                </div>
                                                <div class="align-self-end mb-0">
                                                    Ahmedabad
                                                </div>
                                           </div>
                                        </div>
                                        <div class="choosenInput" (click)="selectOptions('Bangalore' , '../../../assets/images/All_city_icons/Bngl/Cities.png' )" >
                                            <div class="d-flex">
                                                <div class="pr-2">
                                                    <img src="../../../assets/images/All_city_icons/Bngl/Cities.png">
                                                </div>
                                                <div class="align-self-end mb-0">
                                                    Bangalore
                                                </div>
                                           </div>
                                        </div>
                                        <div class="choosenInput" (click)="selectOptions('Chandigarh', '../../../assets/images/All_city_icons/Chandighar/Cities.png')">
                                            <div class="d-flex">
                                                <div class="pr-2">
                                                    <img src="../../../assets/images/All_city_icons/Chandighar/Cities.png">
                                                </div>
                                                <div class="align-self-end mb-0">
                                                    Chandigarh
                                                </div>
                                           </div>
                                        </div>
                                        <div class="choosenInput" (click)="selectOptions('Delhi' , '../../../assets/images/All_city_icons/Delhi/Cities.png' )">
                                            <div class="d-flex">
                                                <div class="pr-2">
                                                    <img src="../../../assets/images/All_city_icons/Delhi/Cities.png">
                                                </div>
                                                <div class="align-self-end mb-0">
                                                    Delhi
                                                </div>
                                           </div>
                                        </div>
                                        <div class="choosenInput" (click)="selectOptions('Hyderabad' , '../../../assets/images/All_city_icons/Hyderabad/Cities.png')">
                                            
                                            <div class="d-flex">
                                                <div class="pr-2">
                                                    <img src="../../../assets/images/All_city_icons/Hyderabad/Cities.png">
                                                </div>
                                                <div class="align-self-end mb-0">
                                                    Hyderabad
                                                </div>
                                           </div>
                                        </div>
                                        <div class="choosenInput" (click)="selectOptions('Kochi' , '../../../assets/images/All_city_icons/Kochi/Cities.png')">
                                            
                                            <div class="d-flex">
                                                <div class="pr-2">
                                                    <img src="../../../assets/images/All_city_icons/Kochi/Cities.png">
                                                </div>
                                                <div class="align-self-end mb-0">
                                                    Kochi
                                                </div>
                                           </div>
                                        </div>
                                        <div class="choosenInput" (click)="selectOptions('Kolkata' , '../../../assets/images/All_city_icons/kolkata/Cities.png')">
                                            
                                            <div class="d-flex">
                                                <div class="pr-2">
                                                    <img src="../../../assets/images/All_city_icons/kolkata/Cities.png">
                                                </div>
                                                <div class="align-self-end mb-0">
                                                    Kolkata
                                                </div>
                                           </div>
                                        </div>
                                        <div class="choosenInput" (click)="selectOptions('Mumbai' , '../../../assets/images/All_city_icons/Mumbai/Cities.png')">
                                            
                                            <div class="d-flex">
                                                <div class="pr-2">
                                                    <img src="../../../assets/images/All_city_icons/Mumbai/Cities.png">
                                                </div>
                                                <div class="align-self-end mb-0">
                                                    Mumbai
                                                </div>
                                           </div>
                                        </div>
                                        <div class="choosenInput" (click)="selectOptions('Pune' , '../../../assets/images/All_city_icons/Pune/Cities.png')">
                                            
                                            <div class="d-flex">
                                                <div class="pr-2">
                                                    <img src="../../../assets/images/All_city_icons/Pune/Cities.png">
                                                </div>
                                                <div class="align-self-end mb-0">
                                                    Pune
                                                </div>
                                           </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            </div>


                            <div class="form-row col-lg-12 pl-2 px-0">

                            <div class="form-group col-lg-12 px-0">
                                <label for="pincode">Pincode</label>
                                <input  onKeyPress="if(this.value.length==6) return false;" type="number" formControlName="pinCodeNumber"  class="form-control"   
                                (blur)="onBlurPinCodeNumber($event)"
                                [ngModel] = "mymodelpincode" (ngModelChange) = "valuechange($event)"
                                list="wizards-list"  autocomplete="off"
                                >
                               
                               
                                    
                                <div style="width: 100% !important" *ngIf="hidesearch" > 
                                    <datalist style="width: 100% !important" id="wizards-list"  style=" max-height: 150px; overflow-y: auto;">
                                            <option  *ngFor="let pincode of pincodedata; let i=index;"> {{pincode}}</option>
                                    </datalist>
                                </div>
                                
                             
                            </div>

                            </div>
                        </form>
                    </div>

                    <div class=" my-5 mx-auto w_15 d-flex justify-content-around buttons_next_back">
                        
                            <button (click)= "nextOne1('pills-1', 'pills-2', 'pills-1-tab', 'pills-2-tab'  )" type="button" class="btn btn-outline-primary d-lg-block d-none">Back</button>
                            <button type="button"  (click) ="secondValidation()" class="btn btn-outline-secondary">Next</button>
                            <div (click)= "nextOne1('pills-1', 'pills-2', 'pills-1-tab', 'pills-2-tab'  )"   class="d-lg-none d-block mobile_view_arrow_icon">
                                <img src="../../../assets/images/icon _ chevron down_2021-12-09/icon/chevron down.png" >
                            </div> 
                        
                    </div>
                </div>
            </div>


            
            <div id="pills-3" class="pills3" >
                <div class="sign-up-container" *ngIf = !to_show_form>
                    <div class="text-center Choose_the_spaces">
                        <p>Choose the spaces to be designed</p>
                    </div>


                    <div class="">
                            <div class="row w_20_form mx-auto">
                                <div  class="col-6 col-lg-6">
                                    <div  (click)= "imageSpace_1('image_one', 'Full Home') ; selectSpace('Full Home Interiors')" [ngClass]="imageStyle_1 ? 'applying_position_relative_image' : 'applying_position_relative_image_opacity'">
                                        <img src="../../../assets/images/Full home/Rectangle 122_2021-12-08/Rectangle 122.png">
                                        <div [ngClass]="imageStyle_1 ? 'placing_text_in_image_center' : 'placing_text_in_image'">
                                            <p>Full Home</p>
                                        </div>
                                    </div>
                        
                                </div>

                                <div class="col-6 col-lg-6 ">
                                    <div  (click)= "imageSpace_2('image_two' , 'Modular Kitchen') ; selectSpace('Modular Kitchen')" [ngClass]="imageStyle_2 ? 'applying_position_relative_image' : 'applying_position_relative_image_opacity'">
                                        <img src="../../../assets/images/Modular kitchen/Rectangle 122_2021-12-08/Rectangle 122.png">
                                        <div [ngClass]="imageStyle_2 ? 'placing_text_in_image_center' : 'placing_text_in_image'">
                                            <p>Modular Kitchen </p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 col-lg-6 mt-5 ">

                                    <div  (click)= "imageSpace_3('image_three' , 'Modular Wardrobe' ) ; selectSpace('Modular Wardrobe') " [ngClass]="imageStyle_3 ? 'applying_position_relative_image' : 'applying_position_relative_image_opacity'">
                                        <img src="../../../assets/images/Modular wardrobe/Rectangle 122_2021-12-08/Rectangle 122.png">
                                        <div [ngClass]="imageStyle_3 ? 'placing_text_in_image_center' : 'placing_text_in_image'">
                                            <p>Modular Wardrobe</p>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-6 col-lg-6 mt-5 ">
                                    <div  (click)= "imageSpace_4('image_four' , 'Kitchen/Wardrobe') ; selectSpace('Modular Kitchen & Wardrobe') " [ngClass]="imageStyle_4 ? 'applying_position_relative_image' : 'applying_position_relative_image_opacity'">
                                        <img src="../../../assets/images/MicrosoftTeams-image (7).png">
                                        <div [ngClass]="imageStyle_4 ? 'placing_text_in_image_center' : 'placing_text_in_image'">
                                            <p>Kitchen/Wardrobe</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>

                    <div class=" my-5 mx-auto w_15 d-flex justify-content-around buttons_next_back">
                        
                            <button  type="button" (click)="nextOne2('pills-2', 'pills-3', 'pills-2-tab', 'pills-3-tab')" class="btn btn-outline-primary d-lg-block d-none">Back</button>
                            <button type="button" (click) ="thirdValidation()" class="btn btn-outline-secondary">Next</button>
                            <div (click)="nextOne2('pills-2', 'pills-3', 'pills-2-tab', 'pills-3-tab')"   class="d-lg-none d-block mobile_view_arrow_icon">
                                <img src="../../../assets/images/icon _ chevron down_2021-12-09/icon/chevron down.png" >
                            </div> 
                        
                    </div>
                </div>
            </div>


            
            <div id="pills-4" class="pills4">
                <div class="sign-up-container" *ngIf = !to_show_form>
                    <div class="text-center know_you_better">
                        <p>Let’s discuss the finances</p>
                    </div>


                    <div class="w_30_form  m-auto">
                        <form [formGroup]= "signUpForm4">
                            <div class="form-group">
                                <label for="inputName">Budget</label>
                                <select class="form-control" id="exampleFormControlSelect1" formControlName="budget">
                                    <option selected value="" disabled>Budget Value</option>
                                    <option
                                    *ngFor="let budge of BudgeValueData; let i = index"
                                    [value]="budge.budgevalue"
                                  >
                                    {{ budge.budgevalue }}
                                  </option>

                                </select>
            
                                <!-- <div *ngIf= "error && signUpForm4.controls.budget.errors">
                                    <div *ngIf = "signUpForm4.controls.budget.errors.required">
                                        <p class="text-danger">please select budget value</p>
                                    </div>
                                </div> -->
                              </div>

                              <div class="form-check">
                                <input type="checkbox"  class="form-check-input" (click)= "checkbox($event)"  >
                                <label class="form-check-label" for="exampleCheck1">Avail the EMI options</label>
                            </div>

                            <div *ngIf= "show_field" class="mt-4">
                                <div class="form-group">
                                    <label for="inputName">EMI Plan</label>
                                    <select class="form-control" id="exampleFormControlSelect2" formControlName="emiPlan" (change)="selectedValue($event)">
                                        <option selected value="" disabled>EMI Value</option>
                                        <option
                                        *ngFor="let emi of EmiPlan; let i = index"
                                        [value]="emi.budgevalue"
                                      >
                                        {{ emi.budgevalue }}
                                      </option>
    
                                    </select>

                                    <!-- <div *ngIf = "errorEmi && signUpForm4.controls.emiPlan.errors">
                                        <div *ngIf="signUpForm4.controls.emiPlan.errors.required">
                                            select emi plan
                                        </div>
                                    </div> -->


                                   
                                  </div>
                                
                            </div>
                        </form>
                    </div>

                    <div class=" my-5 mx-auto w_15 d-flex justify-content-around buttons_next_back">
                        
                            <button (click)= "nextOne3('pills-3', 'pills-4', 'pills-3-tab', 'pills-4-tab')" type="button" class="btn btn-outline-primary d-lg-block d-none">Back</button>
                            <button type="button" (click)= "fourthValidation()"  class="btn btn-outline-secondary">Submit</button>
                            <div (click)= "nextOne3('pills-3', 'pills-4', 'pills-3-tab', 'pills-4-tab')"    class="d-lg-none d-block mobile_view_arrow_icon">
                                <img src="../../../assets/images/icon _ chevron down_2021-12-09/icon/chevron down.png" >
                            </div> 
                        
                    </div>
                </div>
            </div>

            <div id="pills-5" class="pills4">
                <div class="d-flex" >
                    <div class="m-auto">
                        <div class="text-center">
                            <p class="mb-0">You are done!</p>
                        </div>

                        <div class="text-center you_are_done">
                            <p class="mb-0">One of our designers will get in touch with you soon</p>
                        </div>

                        <div  class="d-flex justify-content-center">
                            <img src="../../../assets/images/Your_done/Group 718.png" class="img-fluid-1">
                        </div>


                        <div class="text-center my-4 my-lg-4">
                            <button [routerLink]="['/']"  type="button" class="btn btn-outline-continue">Continue</button>
                        </div>
                        <div (click)= "nextOne4('pills-4', 'pills-5', 'pills-4-tab', 'pills-5-tab')"    class="d-lg-none d-block mobile_view_arrow_icon">
                            <img src="../../../assets/images/icon _ chevron down_2021-12-09/icon/chevron down.png" >
                        </div> 
                    </div>
                </div>

            </div>

            <div class="loading d-flex justify-content-center" *ngIf="loader">
                <div class="spinner-border" style="color: #f3be17" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
    </section>






    <footer class="navbar navbar-light px-5 py-3">
        <div class="details">
            <h6>Arrivae, Made by you</h6>
            <a class="href_text" href="tel:+91 7406343311">Call : +91 7406343311</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a class="href_text" href="mailto:wecare@arrivae.com"> Email ID : wecare@arrivae.com</a>
        </div>
        <div class="social-links">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item active">
                    <a class="nav-link" target="_blank" href="https://www.instagram.com/arrivae/?hl=en"><img src="../../../assets/images/icons/instagram-line.svg"></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" target="_blank" href="https://www.facebook.com/Arrivae/"><img src="../../../assets/images/icons/facebook-circle-fill.svg"></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" target="_blank" href="https://www.pinterest.com/arrivaecom/"><img src="../../../assets/images/icons/pinterest-line.svg"></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" target="_blank" href="https://www.linkedin.com/company/13439634/"><img src="../../../assets/images/icons/linkedin-box-fill.svg"></a>
                </li>
            </ul>
        </div>
    </footer>
</div>