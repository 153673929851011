<div class="w-100 Latest-blog py-4   px-3 ">
    <p class="pl-3">
       <span>Latest</span> Blogs
    </p>
    <span class="explore_homes pl-3">Find inspiration for your dream home </span>
    <div *ngIf="roletoSet() > 650" class="Buildings_container row mx-0 mt-5 ">
       <div class="col-lg-4 col-md-6 col-sm-6 cursor-pointer pr-0" >
          <a href="https://blog.arrivae.com/the-7-biggest-interior-design-trends-of-2022" target="_blank">
             <div  class="image_carrier d-flex align-items-start" style="background-image:url('../../../assets/images/latestblog1.png') ;">
                <div class="px-5 py-3 imgHeading w-79 ">
                   <span>The 7 Biggest 
                   Interior Design 
                   Trends Of 2022</span>
                </div>
             </div>
          </a>
          <p class="mt-3 w-79"> 6 decor ideas, from traditional to eclectic, 
             to help you spread the Christmas cheer in your home
          </p>
       </div>
       <div class="col-lg-4 col-md-6 col-sm-6 cursor-pointer pr-0">
          <a href="https://blog.arrivae.com/throw-the-best-nye-party-with-these-5-tips" target="_blank">
             <div class="image_carrier d-flex align-items-start" style="background-image:url('../../../assets/images/latestblog2.png') ;">
                <div class="px-5 py-3 imgHeading w-83">
                   <span>Throw The Best 
                   NYE Party With
                   These 5 Tips</span>
                </div>
             </div>
          </a>
          <p class="mt-3 w-79"> With our compilation of our experts’ tips, 
             ring in 
             the new year with style!
            
          </p>
         
       </div>
       <div class="col-lg-4 col-md-6 col-sm-6 cursor-pointer pr-0">
          <a href="https://blog.arrivae.com/throw-the-best-nye-party-with-these-5-tips" target="_blank">
             <div class="image_carrier d-flex align-items-start" style="background-image:url('../../../assets/images/latestblog3.png') ;">
                <div class="px-5 py-3 imgHeading w-79 ">
                   <span>6 Décor Ideas 
                   For A Cheerful
                   Christmas </span>
                </div>
             </div>
          </a>
          <p class="mt-3 w-79"> Take a look back at some of the top interior design 
             trends of 2022 with our quick recap.
          </p>
       </div>
    </div>
    <div *ngIf="roletoSet() < 650" class="  Buildings_container ">


 <div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel">
         <!-- Indicators -->
         <!-- <ol class="carousel-indicators">
           <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
           <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
           <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
         </ol> -->
         <!-- Wrapper for slides -->
         <div class="carousel-inner">
           <div class="carousel-item active">


            <a href="https://blog.arrivae.com/the-7-biggest-interior-design-trends-of-2022" target="_blank">
               <div  class="image_carrier d-flex align-items-start" style="background-image:url('../../../assets/images/latestblog1.png') ;">
                  <div class="px-5 py-3 imgHeading w-79 ">
                     <span>The 7 Biggest 
                     Interior Design 
                     Trends Of 2022</span>
                  </div>
               </div>
            </a>
            <p class="mt-3 w-79 pl-2"> 6 decor ideas, from traditional to eclectic, 
               to help you spread the Christmas cheer in your home
            </p>



           </div>
           <div class="carousel-item ">


            <a href="https://blog.arrivae.com/throw-the-best-nye-party-with-these-5-tips" target="_blank">
               <div class="image_carrier d-flex align-items-start" style="background-image:url('../../../assets/images/latestblog2.png') ;">
                  <div class="px-5 py-3 imgHeading w-83">
                     <span>Throw The Best 
                     NYE Party With
                     These 5 Tips</span>
                  </div>
               </div>
            </a>
            <p class="mt-3 w-79 pl-2"> With our compilation of our experts’ tips, 
               ring in 
               the new year <br>with style! 
            </p>
           



           </div>
           <div class="carousel-item ">


            <a href="https://blog.arrivae.com/6-decor-ideas-for-a-cheerful-christmas" target="_blank">
               <div class="image_carrier d-flex align-items-start" style="background-image:url('../../../assets/images/latestblog3.png') ;">
                  <div class="px-5 py-3 imgHeading w-79 ">
                     <span>6 Décor Ideas 
                     For A Cheerful
                     Christmas </span>
                  </div>
               </div>
            </a>
            <p class="mt-3 w-79 pl-2"> Take a look back at some of the top interior design 
               trends of 2022 with our <br> quick recap.
            </p>



           </div>
           <a class="carousel-control-prev" href="#carouselExampleIndicators2" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
            </a>
              <a class="carousel-control-next" href="#carouselExampleIndicators2" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
            </a>


    </div>
 </div>
</div>