import { Component, ElementRef, OnInit, ViewChild, } from '@angular/core';
import { SharedService } from '../../shared/shared.service';
import {TemplateRef, HostListener, Input, Output, EventEmitter  } from '@angular/core';
import { SpaceService } from 'src/app/spaces/space.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Route } from '@angular/compiler/src/core';
import { ActivatedRoute } from '@angular/router';
import { ApartmentService } from '../../apartment/apartment.service';
declare var $: any;
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Router} from '@angular/router';
import { debug } from 'console';
declare function setAttribute1(param1: any,param2:any,param3:any,param4:any): any;
declare function buildSpace(
  param1: any,
  param2: any,
  param3: any,
  param4: any
): any;
declare function personaliedDesign(
  param1: any,
  param2: any
): any;
declare function personalDetails(
  param1: any,
  param2: any,
  param3: any,
  param4: any
): any;

@Component({
  selector: 'app-get-your-estimate',
  templateUrl: './get-your-estimate.component.html',
  styleUrls: ['./get-your-estimate.component.scss'],
})
export class GetYourEstimateComponent implements OnInit {
  templatevalue: any = 'searchedApartment';
  defaultApartment: boolean = true;
  defaultbuild: boolean = false;
  searchApartment: any;
  placeholdervalue: any;
  selectedCity: any;
  accesstoken: any;
  search = '';
  selectsize: boolean = true;
  optionValue: any;
  spaceSize: any;
  apartmentName: any;
  pincode: any;
  errormsg: string = '';
  warning: boolean = false;
  checkbox1: boolean = false;
  checkbox2: boolean = false;
  checkbox3: boolean = false;
  checkbox4: boolean = false;
  checkbox5: boolean = false;
  checkbox6: boolean = false;
  x_token: any;
  client: any;
  uid: any;
  loader: boolean = false;
  // public headers_res: HttpHeaders = new HttpHeaders;
  name: any;
  mobile: any;
  email: any;
  spacebuild: any;
  spaceListArr = [
    { id: 0, name: 'dining_room' },
    { id: 1, name: 'living_room' },
    { id: 2, name: 'kitchen' },
    { id: 3, name: 'bedroom' },
  ];
  signupForum: any = FormGroup;
  public headers: HttpHeaders = new HttpHeaders();
  buildingName: any;
  areaType: any;
  constructor(
    public sharedService: SharedService,
    public http: HttpClient,
    public route: ActivatedRoute,
    public apartmentService: ApartmentService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    $('.num').counterUp({ delay: 10, time: 1000 });
    if (!this.selectedCity) {
      this.selectedCity = localStorage.getItem('selectedCity');
      this.onOptionsSelected();
    }
    this.sharedService.data.subscribe((data) => {
      this.selectedCity = data;
      this.SocietyNameBuilding(this.selectedCity, this.search);
    });
    this.accesstoken = localStorage.getItem('');
    this.accesstoken = localStorage.getItem('accesstoken')
      ? localStorage.getItem('accesstoken')
      : '';
    this.getCities();
    this.SocietyNameBuilding(this.selectedCity, this.search);
    this.signupForum = this.fb.group({
      name: ['', [Validators.required]],
      mobile: ['', [Validators.required]],
      purposeProperty: [''],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      // scopeOfWork:[''],
      // city: [''],
      // pincode: ['']
    });
    this.loadScript()
  }

  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  selectedradioTab: any;
  template(value: any) {
    this.selectedradioTab = value;
    if (value == 'searchedApartment') {
      this.defaultApartment = true;
      this.resetSpace();
    }
    if (value == 'build') {
      this.defaultbuild = true;
      this.resetApartment();
    }
    this.templatevalue = value;
  }
  resetApartment() {
    this.selectedBuildingtype = '';
    this.selectedBuilding = [];
    this.flatsize = [];
  }
  resetSpace() {
    this.spaceSize = '';
    this.apartmentName = '';
    this.pincode = '';
  }
  checkActiveTemplate() {
    if (this.templatevalue == 'searchedApartment') {
      this.defaultApartment = true;
      this.defaultbuild = false;
    }
    if (this.templatevalue == 'build') {
      this.defaultbuild = true;
      this.defaultApartment = false;
    }
  }
  resetForm() {
    this.signupForum.reset();
    this.selectedBuilding = [];
    this.selectedBuildingtype = [];
    this.spaceSize = '';
    this.selectedtheme = '';
    this.customSpacelifestage = '';
  }
  clickradio(searchedApartment: any) {
    if (searchedApartment === 'searchedApartment') {
    }
  }

  selectetab: any = 'Define your space';
  clicktobutton(event: any, val: any) {
    this.selectetab = val;
  }
  activetab: any = 'your space';
  next(selectedTab: any) {
    this.activetab = selectedTab;
  }
  citydata: any;
  getCities() {
    this.sharedService.getCities().subscribe((data) => {
      this.citydata = data;
    });
  }

  building: any = [];
  dummyArr: any;
  SocietyNameBuilding(city: any, search: any) {
    this.apartmentService.getbuilding(city, search).subscribe((data) => {
      this.building = data;
      if (this.building && this.building.building_crawlers === undefined) {
        this.selectedBuilding = undefined;
        this.selectedBuildingtype = undefined;
      }
      if (this.building && this.building.building_crawlers.length === 0) {
        this.selectedBuilding = '';
        this.selectedBuildingtype = '';
      }
      if (this.building && this.building.building_crawlers) {
        this.building = [];
      }
    });
  }
  searchSociety(event: any) {
    let searchWord = event.target.value;
    this.SocietyNameBuilding(this.selectedCity, searchWord);
  }
  onOptionsSelected(event?: any) {
    this.selectedCity = event ? event.target.value : this.selectedCity;
    this.SocietyNameBuilding(this.selectedCity, this.search);
  }
  selectedBuilding: any;
  getselectedbuilding(event: any) {
    this.selectedBuilding = event;
    let obj = this.building.find(
      (elem: any) => elem.id === this.selectedBuilding
    );
    this.buildingName = obj.building_name;
    this.flatsizeType(event);
  }
  flatsize: any;
  storesize: any;
  flatsizeType(id: any) {
    this.apartmentService.flatsizetype(id).subscribe((data) => {
      this.storesize = data;
      if (this.storesize && this.storesize.bhk) {
        this.flatsize = this.storesize.bhk;
      } else {
        this.flatsize = [];
      }
    });
  }

  selectedBuildingtype: any;
  getselectedbuildingtypes(event: any) {
    this.selectedBuildingtype = event.target.value;

    let obj = this.flatsize.find(
      (elem: any) => elem.type === this.selectedBuildingtype
    );
    this.areaType = obj.area;
  }
  customSpace: any = [];
  dummies: any = [];

  custumselect(event: any, val: any) {
    if (event.target.checked) {
      this.customSpace.push({ name: this.getReplcaeChar(val.name) });
      this.dummies.push({ id: val.id });
    } else {
      this.customSpace.splice(this.customSpace.indexOf({ id: val.id }), 1);
      this.dummies.splice(this.dummies.indexOf({ id: val.id }), 1);
    }
    console.log(this.dummies, 'customSpace');
  }
  setCheckboxVal() {
    this.dummies.forEach((item: any) => {
      setTimeout(() => {
        $('#ch-' + item.id).prop('checked', true);
      }, 500);
    });
  }

  handleSpaceClone(space: any) {
    this.spaceListArr.splice(this.spaceListArr.indexOf(space) + 1, 0, {
      id: 121,
      name: space.name,
    });
    for (const [index, value] of this.spaceListArr.entries()) {
      this.spaceListArr[index]['id'] = index;
    }
  }
  customSpacelifestage: any;
  custumselectlifestage(val: any) {
    this.customSpacelifestage = val;
  }
  selectedtheme: any;
  selecttheme(event: any) {
    this.selectedtheme = event.target.value;
  }
  selectedpurpose: any;
  selectpurpose(event: any) {
    this.selectedpurpose = event.target.value;
    console.log('Purpse selected', this.selectedpurpose);
  }
  getReplcaeChar(str: any) {
    return str.replace(/_/g, ' ');
  }
  dummy: any;
  dummysociety: any;
  headers_res: any;
  signupForm() {
    if (this.flatsize && this.flatsize.length > 0) {
      this.flatsize.forEach((Items: any) => {
        if (Items.type == this.selectedBuildingtype) {
          this.dummy = Items;
        }
      });
    }
    if (this.building && this.building.length > 0) {
      this.building.forEach((Items: any) => {
        if (Items.id == this.selectedBuilding) {
          this.dummysociety = Items;
        }
      });
    }
    var name = $('#name').val();
    var mobile = $('#mobile').val();
    var email = $('#email').val();
    var customespacesize = this.spaceSize;
    var customaprtmentName = this.apartmentName;
    if (this.apartmentName === undefined) {
      customaprtmentName = this.buildingName;
    }
    if (this.apartmentName === '') {
      customaprtmentName = this.buildingName;
    }
    var custompincode = this.pincode;

    console.log(customespacesize, customaprtmentName, custompincode, 'value');
    var whatsaap = $('#gridCheck').prop('checked');
    this.selectedCity;
    this.selectedBuilding;
    this.selectedBuildingtype;
    this.customSpace;
    this.customSpacelifestage;
    this.selectedtheme;
    // this.areaType = this.areaType ? this.areaType.replace(" ",""):'';
    this.selectedBuildingtype = this.selectedBuildingtype
      ? this.selectedBuildingtype.replace(' ', '')
      : '';
    console.log(this.selectedBuilding, this.selectedBuildingtype);

    var obj = {
      city: this.selectedCity,
      society: customaprtmentName,
      accomodation_type: this.selectedBuildingtype,
      space: this.customSpace,
      space_size: customespacesize,
      area_of_site:
        this.selectedradioTab === 'build' ? customespacesize : this.areaType,
      lifestage: [{ name: this.customSpacelifestage }],
      theme: [{ name: this.selectedtheme }],
      purpose_of_property: this.selectedpurpose,
      lead: {
        name: name,
        contact: mobile,
        email: email,
        pincode: custompincode,
        lead_source: 'website-get_your_estimate',
      },
    };
    console.log(obj, 'obj');
    this.loader = true;
    personalDetails(name, mobile, this.selectedpurpose, email);
    this.apartmentService.signup(obj).subscribe((data) => {
      const jsonData = JSON.stringify(data.body);
      const parseJsonData = JSON.parse(jsonData);
      this.x_token = data.headers.get('access-token');
      this.uid = data.headers.get('uid');
      this.client = data.headers.get('client');
      console.log('values', this.x_token, this.uid, this.client);
      localStorage.setItem('access-token', this.x_token);
      localStorage.setItem('UID', this.uid);
      localStorage.setItem('client', this.client);
      localStorage.setItem('lead_id', parseJsonData.id);
      setAttribute1(name, mobile, email, parseJsonData.id);
      console.log(this.x_token);
      // this.loader=false;
      this.toastr.success('Registered successfully');
      this.router.navigateByUrl('/get-estimate-package');
    });
  }

  buildEngage( param2: any, param3: any, param4: any) {
    buildSpace(this.customSpace[0].name, param2, param3, param4);
  }

  personDesign(param1:any, param2:any){
    personaliedDesign(param1, param2);
  }
}
