<app-new-header-for-home></app-new-header-for-home>
<div *ngIf ="!loader">
<div class="container-fluid mt-0">
  <div class="row justify-content-lg-center">
    <div class="col-10 inclusionHeader">
      <div class="d-flex">
        <div class="titleTriangleinclusion">INCLUSION OF THE DESIGN</div>
        <span class="arrow-down"></span>
        <img
          src="../assets/images/Fill-117.svg"
          class="smallLogoIcon zI1"
          alt="vwuy"
        />
      </div>
     
    </div>
  </div>
</div>
<div class="container-fluid mt-4">
  <div class="row justify-content-center">
    <div class="col-md-10 col-sm-12">
      <div class="row">
      <div class="col-lg-6 col-12">
        <button *ngIf ="fetchData.data.attributes.theme !== ''" type="button" class="btn mb-1 mb-lg-0 modernVibes mr-1 mr-lg-2 px-2">{{fetchData.data?.attributes?.theme}}</button>
        <button *ngIf ="fetchData.data.attributes.space !== ''" type="button" class="btn mb-1 mb-lg-0 modernVibes mr-1 mr-lg-2 px-2">{{fetchData.data?.attributes?.space}}</button>
        <button *ngIf ="fetchData.data.attributes.lifestage !== ''" type="button" class="btn modernVibes mr-1 mr-lg-2 px-2 mb-1">{{ fetchData.data?.attributes?.lifestage |spacePipe }}</button>
        <button *ngIf ="fetchData.data.attributes.shortcode !== ''" type="button" class="btn mb-1 modernVibes numbers   mr-1 mr-lg-2 px-2">{{ fetchData.data?.attributes?.shortcode }}</button>
      </div>
        <div class="btn-style-space col-6 d-flex view-space-inc justify-content-end">
          <a routerLink="/get-finance" target="_blank" class="cursorPointer colorBurgentee vr-finance mt-1 align-self-center ">Get Finance</a>
          <button type="button" class="btn btn-sm  getTheDesign ml-2" (click)="bookConsultationSpacePage()">
            <span class="text-btn-2">Get this design</span>
          </button>
        </div>
        <div class="col-md-12 mt-2">
          <div class="profile2">
            <div
              id="carouselExampleControls"
              class="carousel slide"
              data-ride="carousel"
              *ngIf="fetchData && fetchData.data.attributes.images.length != 0"
            >
              <div class="carousel-inner">
                <div
                  *ngFor="
                    let image of fetchData.data.attributes.images
                      .slice()
                      .reverse();
                    let i = index
                  "
                  [ngClass]="i === 0 ? 'active' : ''"
                  class="carousel-item"
                >
                  <img  class="d-block w-100 car-images" src="{{ image }}" />
                  <!-- <img class="d-block w-100" src="{{ image }}" /> -->
                </div>
              </div>
              <a
                *ngIf="fetchData.data.attributes.images.length > 1"
                class="carousel-control-prev"
                href="#carouselExampleControls"
                role="button"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </a>
              <a
                *ngIf="fetchData.data.attributes.images.length > 1"
                class="carousel-control-next"
                href="#carouselExampleControls"
                role="button"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
            <div
              *ngIf="fetchData && fetchData.data.attributes.images.length == 0"
            >
              <img
                src="{{ fetchData.data.attributes.attachment_file }}"
                onerror="src='../../../assets/images/default-apartment.png'"
                class="w-100"
              />
            </div>
            <!-- </ng-container> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  


<div class="container-fluid mt-3">
  <div class="row justify-content-center">
    <div class="col-md-10 col-10">
      <!-- <div class="pl-3 pr-3 pl-lg-0 pr-lg-0"> -->
      <div class="row">
        <ng-container
          *ngFor="let item of fetchData.data.attributes.space_items.data"
        >
          <!-- <div class="row"> -->
          <div class="col-12 col-md-3 rectangular1">
            <div class="row">
              <div class="col-8">
                <div class="oven text-capitalize">
                  {{ item.attributes.item_name }}
                </div>
                <div style="font-size: 13px">
                  {{ item.attributes.description }}
                  <p class="include d-none">
                    Include Me:
                    <label class="switch">
                      <input
                        type="checkbox"
                        value="{{ item.id }}"
                        (change)="getItems($event, item.id, item.attributes)"
                        checked
                      />
                      <span class="slider round"></span>
                    </label>
                  </p>
                </div>
              </div>
              <div class="col-4">
                <img
                  class="img-fluid"
                  src="{{ item.attributes.image }}"
                  onerror="src='../../../assets/images/default-apartment.png'"
                />
              </div>
            </div>
          </div>
          <!-- </div> -->
          <!-- </ng-container> -->
        </ng-container>
      </div>
      <!-- </div> -->
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-md-10 col-12">
      <div class="row justify-content-between">
        <div class="col-md-6"  *ngIf ='fetchData.data.attributes.price_cents'>
          <!-- <div class="emi-dta-styl my-2">
            <span class="emi-style"> EMI Starts From:</span>
            <span class="data-style numbers">{{fetchData.data.attributes.price_cents | currency: "INR":true}}
            </span>
          </div> -->
        </div>
        <div class="col-md-6">
          <div class="btn-style-space d-flex d-block d-md-none justify-content-center">
          <a routerLink="/get-finance" target="_blank" class="cursorPointer colorBurgentee vr-finance mt-1 align-self-center ">Get Finance</a>
          <button type="button" class="btn btn-sm  getTheDesign ml-2" (click)="bookConsultationSpacePage()">
            <span class="text-btn-2">Get this design</span>
          </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  <div class="container-fluid custom-card" *ngIf ="MoreSpaceItems.all_space.length > 0">
      <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="row my-3">
              <div class="col-10">
                <div class="d-flex">
                  <div class="titleTriangleinclusion">More Spaces To Explore</div>
                  <span class="arrow-down"></span>
                  <img
                    src="../assets/images/Fill-117.svg"
                    class="smallLogoIcon zI1"
                    alt="vwuy"
                  />
                </div>
              </div>
            </div>
            <div class="row mobile-row-blog justify-content-center">
              <div class="previousAndNextIcons d-lg-block d-none" *ngIf ="MoreSpaceItems.total_space > 3">
                <img [ngClass] = "moreItemsPage === 1 ? 'd-none' : 'd-inline-block'"  (click) = "previousPage()" class="cursorPointer prevIcon" src="../../../assets/images/carosel-icons/previous-customer.png" alt="previous" >
                <img  [ngClass] = "TotalPages === moreItemsPage ? 'd-none' : 'd-inline-block'" (click) = "nextPage()" class="cursorPointer nxtIcon" src="../../../assets/images/carosel-icons/next-customer.png" alt="nxt" >
              </div>
              <ng-container *ngFor="let blog of MoreSpaceItems.all_space; let i = index">
                  <div class="col-lg-4 col-12" (click)="currentNextSpace(blog.id)">
                      <div class="row cursorPointer">
                          <div class="col-12">
                              <img class="blog__images px-3 px-lg-0"  [src]="[blog.image]" alt="" onerror="src='../../../assets/images/default-apartment.png'">
                          </div>
                      </div>
                      <div class="row mb-4">
                          <div class="col-12 pl-lg-3" style="padding-left: 2rem;">
                            <p *ngIf ="blog.shortcode" class="mb-0 my-2 numbers">{{blog.shortcode}}</p>
                            <a *ngIf ="blog.lifestage !== '' " class="home-btn  px-2 mr-1 py-1 text-capitalize">{{blog.lifestage.replace('_', ' ')}}</a>
                            <a *ngIf =" blog.theme !== ''" class="home-btn  px-2 mr-1 py-1">{{blog.theme}}</a>
                            <a *ngIf =" blog.space !== ''" class="home-btn  px-2  py-1">{{blog.space}}</a>
                          </div>
                      </div>
                  </div>
              </ng-container>
              <div class="previousAndNextIcons  d-lg-none d-block" *ngIf ="MoreSpaceItems.total_space > 3">
                <img [ngClass] = "moreItemsPage === 1 ? 'd-none' : 'd-inline-block'"  (click) = "previousPage()" class="cursorPointer" src="../../../assets/images/carosel-icons/previous-customer.png" alt="previous" >
                <img [ngClass] = "TotalPages === moreItemsPage ? 'd-none' : 'd-inline-block'" (click) = "nextPage()" class="cursorPointer" src="../../../assets/images/carosel-icons/next-customer.png" alt="nxt" >
              </div>
            </div>

            <div class="loader-progrees" *ngIf = "loaderPreviousNext">
              <div class="row mt-4 loader bg-white border-outer align-items-center" >
                <div class="col-12 d-flex justify-content-center">
                  <div class="ss-spinner ss-spinner--orange mt-0">
                    <div class="ss-spinner__item1"></div>
                    <div class="ss-spinner__item2"></div>
                    <div class="ss-spinner__item3"></div>
                    <div class="ss-spinner__item4"></div>
                  </div>
                </div>
              </div>
            </div>

          </div>
      </div>
  </div>
</div>

<app-footer></app-footer>    
<div class="loading d-flex justify-content-center" *ngIf ="loader"  >
  <div class="spinner-border" style="color: #8d0528" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
