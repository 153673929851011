import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spacePipe'
})
export class SpacePipePipe implements PipeTransform {

  transform(value: string, args?: string): any {
    // return null;
    switch(value || null) {
      case 'livingroom':
        return 'Living';
      case 'living room':
        return 'Living';
      case 'diningroom':
        return 'Dining Room';
      case 'dining room':
        return 'Dining Room';
      case 'bedroom':
        return 'Bedroom';
      case 'studyroom':
        return 'Study Room';
      case 'kitchen':
        return 'Kitchen';
      case 'essential_interiors':
        return 'Basic Package';
      case 'luxury_interiors':
        return 'Plus Package';
      case 'love_vibes':
        return 'Love Vibes';
      case 'baby_vibes':
        return 'Baby Vibes';
      case 'lively_vibes':
        return 'Lively Vibes';
      case 'all_space':
        return "All Spaces";
      case 'happy_vibes':
        return 'Happy Vibes';
      case 'young_vibes':
        return 'Young Vibes';
      default:
          return value;
  }
  }

}
