<div class="card space-card border-0">
  <img [src]="data.attributes.attachment_file" class="spaceImg br_10" alt="space card image"
  onerror="src='../../../assets/images/default-apartment.png'">
    <div [ngClass]="{'no-arrow':data.attributes.package_type === ''}" class="d-flex justify-content-center titlediv">
      <!-- <span class="ml-2">{{
        transform(portfolio.attributes.lifestage) 
      }}</span> -->
      <span  class="titleTriangle2 text-capitalize align-items-center d-flex">
        <span class="ml-2">{{
          data.attributes.package_type
        }}</span>
        <div [ngClass]="{'no-arrow':data.attributes.package_type === ''}" class="arrow-down"></div>
      </span>
    </div>
  <div class="card-body p-2">
    <h6 class="card-title m-0">{{data.attributes && data.attributes.space}}</h6>
    <!-- <p class="card-text m-0">{{data.attributes && data.attributes.price_cents}} sq ft</p> -->
    <p class="card-text m-0">EMI starts @{{data.attributes && data.attributes.price_cents}} INR/Month</p>
    <div class="mt-3 d-flex justify-content-between align-items-center">
      <button class="btn btn-sm py-1 px-3 viewMoreBtn" (click)="viewSpaceHandler(data.attributes.id)">View More</button>
        <button *ngIf="data.attributes && data.attributes.is_present_in_leads_preset; else otherbutton" class="btn btn-sm py-1 px-3 getThisBtn" disabled>Selected Design</button>
        <ng-template #otherbutton>
          <button class="btn btn-sm py-1 px-3 getThisBtn" (click)="spaceTabHandler(data.attributes.id)">Get this design</button>
        </ng-template>
    </div>
  </div>
</div>
