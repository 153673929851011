import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApartmentService } from '../apartment.service';
import { SpaceService } from 'src/app/spaces/space.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApartmentGetThisDesignFormComponent } from '../apartment-get-this-design-form/apartment-get-this-design-form.component';
declare var $: any;
declare function apartmentViewed(param1: any, param2: any, param3: any,param4:any): any;
declare function getthisDesign(param1: any, param2: any, param3: any): any;
@Component({
  selector: 'app-updated-apartment',
  templateUrl: './updated-apartment.component.html',
  styleUrls: ['./updated-apartment.component.scss'],
})
export class UpdatedApartmentComponent implements OnInit {
  apartmentDetailsData: any;
  apartmentName: any;
  departmentName: any;
  unidData: any;
  bhkData: any;
  apartvarandspaceData: any;
  apartmentImageData: any;
  cityFilter: any;
  perPageValue: any;
  lifeStageFilter: any;
  themeFilter: any;
  vrlinkFilter: any;
  searchFilter: any;

  constructor(
    private apartmentservice: ApartmentService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private spaceService: SpaceService,
    private router: Router
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.route.queryParams.subscribe((params) => {
      console.log(params);
      this.apartmentName = params['apartment_name'];
      this.departmentName = params['developer_name'];
      this.unidData = params['unid'];
    });
    this.getApartmentVarients();
    this.loadScript()
  }
  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  previousApart: any;
  nextApart: any;
  getApartmentVarients() {
    this.route.queryParams.subscribe((params) => {
      this.cityFilter = params['city'];
      this.perPageValue = params['perPage'];
      this.lifeStageFilter = params['lifestage'];
      this.themeFilter = params['theme'];
      this.vrlinkFilter = params['vrlink'];
      this.searchFilter = params['search'];
    });
    this.apartmentservice
      .getVarients(
        this.unidData,
        this.cityFilter,
        this.searchFilter,
        this.perPageValue,
        this.lifeStageFilter,
        this.themeFilter,
        this.vrlinkFilter
      )
      .subscribe((res) => {
        this.apartmentDetailsData = res;
        this.previousApart = this.apartmentDetailsData.previous;
        this.nextApart = this.apartmentDetailsData.next;
        this.bhkData = this.apartmentDetailsData.varients[0];
        this.getApartmentnamedata(this.bhkData);
      });
  }
  spaceFilter(event: any) {
    this.bhkData = event.target.value.toLowerCase();
    this.getApartmentnamedata(this.bhkData);
  }
  initialId: any;
  initialSpace: any;
  spreadingArea: any;
  secondId: any;
  secondSpace: any;
  formatType: any;
  uid: any;
  getApartmentnamedata(bhk: any) {
    this.apartmentservice
      .getVarientandspace(this.unidData, this.bhkData)
      .subscribe((res) => {
        this.apartvarandspaceData = res;

        if (this.apartvarandspaceData.length > 1) {
          console.log('if condition executed');
          for (var i = 0; i < this.apartvarandspaceData.length; i++) {
            if (
              this.apartvarandspaceData[i]?.attributes.apartment_spaces.length >
              0
            ) {
              this.initialId =
                this.apartvarandspaceData[i]?.attributes.apartment_spaces[0].id;
              this.initialSpace =
                this.apartvarandspaceData[
                  i
                ]?.attributes.apartment_spaces[0].name;
              this.formatType = this.apartvarandspaceData[i]?.attributes.format;
              this.uid = this.apartvarandspaceData[i]?.attributes.unid;
              apartmentViewed(
                this.apartmentName,
                this.departmentName,
                this.apartvarandspaceData[i].attributes.area.toString(),
                this.apartvarandspaceData[i].attributes.format
              );
              this.onClickSpaces(
                this.initialId,
                i,
                this.initialSpace,
                this.formatType,
                this.uid
              );
            }
          }
        } else {
          console.log('else condition executed');
          if (
            this.apartvarandspaceData[0]?.attributes.apartment_spaces.length > 0
          ) {
            this.initialId =
              this.apartvarandspaceData[0]?.attributes.apartment_spaces[0].id;
            this.initialSpace =
              this.apartvarandspaceData[0]?.attributes.apartment_spaces[0].name;
            this.formatType = this.apartvarandspaceData[0]?.attributes.format;
            this.uid = this.apartvarandspaceData[0]?.attributes.unid;
            apartmentViewed(
              this.apartmentName,
              this.departmentName,
              this.apartvarandspaceData[0].attributes.area.toString(),
              this.apartvarandspaceData[0].attributes.format
            );
            this.onClickSpaces(
              this.initialId,
              0,
              this.initialSpace,
              this.formatType,
              this.uid
            );
          }
        }
      });
  }
  selectedSpaces: any;
  spaceType: any;
  selectunid: any;
  selectformat: any;
  activated: any = [];
  xyz: any = [];
  storedURL: any;
  checkImageData: any;
  inclusionImageData: any = [];
  onClickSpaces(data: any, tabIndex: any, name: any, format: any, ud: any) {
    // data --- id
    // tabindex --- index
    // name --- space name , living
    // format ---- 2bhk
    console.log(data, tabIndex, name, format, ud);
    this.selectedSpaces = data;
    this.spaceType = name;
    this.selectunid = ud;
    this.selectformat = format;
    if (data === undefined) {
      data = this.initialId;
    }
    if (name === undefined) {
      name = this.initialSpace;
    }
    if (format === undefined) {
      format = this.formatType;
    }
    if (ud === undefined) {
      ud = this.uid;
    }
    this.apartmentservice
      .getSpacecontent(
        this.selectedSpaces,
        this.selectunid,
        this.selectformat,
        this.spaceType
      )
      .subscribe((res) => {
        this.apartmentImageData = res;
        if (this.apartmentImageData?.attributes.contents.length > 1) {
          this.checkImageData = true;
        } else {
          this.checkImageData = false;
        }
        this.xyz[tabIndex] = this.apartmentImageData?.attributes.contents;
        this.activated[tabIndex] = name;
        if (
          res !== null &&
          this.apartmentImageData?.attributes.contents.length !== 0
        ) {
          this.storedURL =
            this.apartmentImageData?.attributes.contents[0].attributes.document;
        }
        if (this.apartmentImageData?.attributes.contents.length === 0) {
          this.storedURL = '';
        }
      });
    this.onIntialInclusion(this.selectedSpaces);
  }
  storedInitialItems: any;
  onIntialInclusion(data: any) {
    this.apartmentservice.getApartmentSpaceItems(data).subscribe((res) => {
      this.storedInitialItems = res;
    });
  }
  inclusionName: any;
  inclusionId: any;
  inclusionEachData: any;
  nextName: any;
  previousName: any;
  nextId: any;
  previousId: any;
  onClickInclusionSpaces(id: any, ud: any, format: any, name: any) {
    // id , uid , format , space
    this.inclusionId = id;
    // this.inclusionName =name
    this.apartmentservice
      .getSpacecontent(id, ud, format, name)
      .subscribe((res) => {
        this.inclusionEachData = res;
        if (this.inclusionEachData.next) {
          this.nextName = this.inclusionEachData.next.space;
        }
        if (this.inclusionEachData.previous) {
          this.previousName = this.inclusionEachData.previous.space;
        }
        this.inclusionImageData.length = 0;
        for (
          var i = 0;
          i < this.inclusionEachData.attributes.contents.length;
          i++
        ) {
          let x =
            this.inclusionEachData.attributes.contents[i].attributes.document;
          this.inclusionImageData.push(x);
        }
      });
    this.onClickInclusion(id);
  }
  nextSection(id: any, spaceName: any) {
    this.onClickInclusionSpaces(id, this.uid, this.typeOfFormat, spaceName);
  }
  previousSection(id: any, spaceName: any) {
    this.onClickInclusionSpaces(id, this.uid, this.typeOfFormat, spaceName);
  }
  viewInclusionData: any;
  typeOfFormat: any;
  imageopen(presetObject: any) {
    console.log(this.viewInclusionData);
    this.viewInclusionData = presetObject;
    this.typeOfFormat = this.viewInclusionData.attributes.format;
    this.inclusionId = this.viewInclusionData.attributes.apartment_spaces[0].id;
    this.inclusionName =
      this.viewInclusionData.attributes.apartment_spaces[0].name;
    this.uid = this.viewInclusionData.attributes.unid;
    this.onClickInclusionSpaces(
      this.inclusionId,
      this.uid,
      this.typeOfFormat,
      this.inclusionName
    );
    $('#apartinclusionModal').modal('show');
    $('#apartinclusionModal').modal('show');
  }
  selectapartment: any;
  selectcity: any;
  selectbhk: any;
  selectpin: any;
  selectId: any;
  selectfloorplan: string = '';
  selected_item_data: any = [];
  getThisDesign(
    apartment_name: any,
    cityname: any,
    pincode: number,
    bhk: any,
    id: any,
    floorplan: any
  ) {
    this.selectapartment = apartment_name;
    this.selectcity = cityname;
    this.selectpin = pincode;
    this.selectbhk = bhk;
    this.selectId = id;
    if (floorplan === null) {
      this.selectfloorplan = '';
    } else {
      this.selectfloorplan = floorplan;
    }
    const modal = this.modalService.open(ApartmentGetThisDesignFormComponent, {
      size: 'md',
    });
    modal.componentInstance.apartment_name = apartment_name;
    modal.componentInstance.cityname = cityname;
    modal.componentInstance.pincode = pincode;
    modal.componentInstance.bhk = bhk;
    modal.componentInstance.id = id;
    modal.componentInstance.floorplan = floorplan;
    modal.componentInstance.num = 2;
    modal.componentInstance.items = this.selected_item_data;
    modal.result.then((res) => {});
  }
  openimagedata: any;
  openImges(layout: any) {
    this.openimagedata = layout;
    $('#inclusionModal').modal('show');
  }
  closemodalimage() {
    $('#inclusionModal').modal('hide');
  }
  vrvideospace(data: any) {
    window.open(data.attributes.vr_link, '_blank');
  }
  closemodal() {
    $('#apartinclusionModal').modal('hide');
  }

  storeditems: any;
  lineitems: any;
  onClickInclusion(data: any) {
    this.apartmentservice.getApartmentSpaceItems(data).subscribe((res) => {
      this.storeditems = res;
      this.lineitems = this.storeditems.data;
    });
  }
  getThisDesignmodal() {
    const modal = this.modalService.open(ApartmentGetThisDesignFormComponent, {
      size: 'md',
    });
    modal.componentInstance.apartment_name = this.selectapartment;
    modal.componentInstance.cityname = this.selectcity;
    modal.componentInstance.pincode = this.selectpin;
    modal.componentInstance.bhk = this.selectbhk;
    modal.componentInstance.num = 2;
    modal.componentInstance.floorplan = this.selectfloorplan;
    modal.componentInstance.items = this.selected_item_data;
    modal.result.then((res) => {});
  }
  vrvideoInclusion(data: any) {
    window.open(data, '_blank');
  }

  ngOnDestroy() {
    $('#apartinclusionModal').modal('hide');
  }

  engageDesign(param1:any,param2:any,param3:any){
    getthisDesign(param1, param2, param3)
  }
}
