import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LandingService } from '../landing.service';

@Component({
  selector: 'app-new-landing-page',
  templateUrl: './new-landing-page.component.html',
  styleUrls: ['./new-landing-page.component.scss'],
})
export class NewLandingPageComponent implements OnInit {
  selectedCity: any;
  cityData: any;
  citiesData: any;
  city_id: any;
  points: any;
  experience: any;
  multiCenters: any;
  singleCenters: any;
  tokenForMagic: any;
  loader: any;
  accessToken: any;
  url: any;
  carouselImages: any = [
    {
      url: './assets/images/Optimized-Arrivae_Website-Banners_updated_01.png',
      alt: 'Arrivae Banner 01',
    },
    {
      url: './assets/images/Optimized-Arrivae_Website-Banners_Updated_02.png',
      alt: 'Arriave Banner 02',
    },
    {
      url: './assets/images/3rd-image-mob.png',
      alt: 'Arrivae Banner 03',
    },
  ];

  constructor(private landingService: LandingService, private router: Router) {}

  ngOnInit(): void {
    this.url = localStorage.getItem('loginuser');
    this.accessToken = localStorage.getItem('access-token')
      ? localStorage.getItem('access-token')
      : '';
    localStorage.setItem('logintoken', this.url);
    let tokenMagic = this.url.split('=');
    this.tokenForMagic = tokenMagic[1];
    this.fetchPoints();
    this.magicLogin();
  }
  cname: any;

  magicLogin() {
    this.landingService.Magiclogin(this.tokenForMagic).subscribe((res) => {
      this.cname = res;
      this.loader = true;
      setTimeout(() => {
        this, (this.loader = false);
        this.router.navigate(['/customer-dashboard']);
      }, 4000);

      localStorage.setItem('cname', this.cname.user.name);
    });
  }

  fetchPoints() {
    if (localStorage.getItem('selectedCity') == null) {
      this.selectedCity = 'mumbai';
    } else {
      this.selectedCity = localStorage.getItem('selectedCity');
    }
    this.landingService.getCities().subscribe((data) => {
      this.cityData = data;
      this.citiesData = this.cityData.data;
      for (let ct of this.citiesData) {
        if (ct.attributes.name == this.selectedCity) {
          this.city_id = ct.id;
        }
      }

      this.landingService.getpoints(this.city_id).subscribe((res) => {
        if (res) {
          this.points = res;
          this.experience = this.points.data;
          if (this.experience.length > 1) {
            this.multiCenters = true;
            this.singleCenters = false;
          }
          if (this.experience.length == 1) {
            this.singleCenters = true;
            this.multiCenters = false;
          }
        }
      });
    });
  }

  customFunc(data: any) {
    this.selectedCity = data.selectedCity;
    this.fetchPoints();
  }
}
