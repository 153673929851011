<div class="project-gallary">
  <div class="row justify-content-between">
    <div class="col-lg-4">
      <p class="our-gallery mb-lg-0">Our Project Gallery</p>
    </div>
    <div class="col-lg-4 d-flex justify-content-lg-end justify-content-center">
      <a class="vr-button d-flex align-self-center"
        href="/spaces"
        target="_blank">
        <div class="d-flex align-items-center">
          <div class="d-flex align-self-center" style="margin-top: 0.5rem; margin-right: 0.4rem;">
            <img src="../../../assets/images/carosel-icons/VR white.png">
          </div>
          <div>
            View Projects in VR
          </div>
        </div>
        </a
      >
    </div>

    <div class="col-lg-12">
      <div class="row my-5">
        <div class="gallery-container col-lg-7 px-lg-3 px-0">
          <div class="your-class">
            <ng-container *ngFor="let item of items">
              <div>
                <img [src]="item.image" class="d-block w-100 car-images">
              </div>
            </ng-container>
          </div>
          <button id="previous-gallery-button" class="previous-gallery-button">
            <img class="img-fluid" src="../../../assets/images/Landing_page_updated/left_arrow.svg">
          </button>
          <button id="next-gallery-button" class="next-gallery-button">
            <img class="img-fluid" src="../../../assets/images/Landing_page_updated/right_arrow.svg">
          </button>
        </div>

        <div class="col-lg-5 align-self-center our-project-right-part pl-lg-5 mt-lg-0 mt-4">
          <div class="row">
            <div class="col-lg-12 mb-2">
              <button class="btn px-0">
                <a [routerLink]="['/spaces']" [queryParams]="{theme_name: items[active_slide].spaceLink[0], page:'1'}" target="_blank" class="mr-1 px-3 py-1">{{items[active_slide].space[0]}}</a>
              </button>
              <button class="btn px-0">
                <a [routerLink]="['/spaces']" [queryParams]="{space_name: items[active_slide].spaceLink[1], page:'1'}" target="_blank" class="mx-1 px-3 py-1">{{items[active_slide].space[1]}}</a>
              </button>
              <button class="btn px-0">
                <a class="mx-1 px-3 py-1" [routerLink]="['/spaces']" [queryParams]="{lifestage_name: items[active_slide].spaceLink[2], page:'1'}" target="_blank" >{{items[active_slide].space[2]}}</a>
              </button>
            </div>
            <div class="col-lg-12 col-12">
              <p class="client-name mb-0">{{items[active_slide].name}}</p>
              <div class="d-flex">
                <span class="address"><a style="background-color: unset;" [href]="items[active_slide].apartmentLink">{{items[active_slide].apartmentName}}</a>, {{items[active_slide].bhkType}}</span>
                <span class="align-self-center circle mx-2"></span>
                <span style="font-size: 14px; font-weight: 500;">{{items[active_slide].area}} Sq. Ft.</span>
              </div>
            </div>

            <div class="col-lg-9 mt-4">
              <p>“{{items[active_slide].content}}”</p>
            </div>

            <div class="col-lg-8 d-flex d-lg-block justify-content-center justify-content-lg-start mt-4">
              <button class="get-the-design" (click)="bookConsultationLandingPage()">
                Get This Design
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
