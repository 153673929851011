<div class="Italian-Designs pt-5 mx-md-5 mx-0 ">
   <div class="row  mobile-padding ">
    <!-- heading -->
    <div class="main-heading col-12  order-md-1 ">
      <p *ngIf ="!newLandingPage()" class="mb-1 mx-2 mx-md-0"><span class="pb-1">ITALIAN DESIGN THEMES,</span> INDIAN PRICES</p>
      <p *ngIf ="newLandingPage()" style="font-weight: 800;" class="mb-1 mx-2 mx-md-0"><span class="pb-1">WHAT IS THE SCOPE</span> OF OUR SERVICE?</p>
       <span>{{newLandingPage() ? 'Enjoy a hassle-free experience with our comprehensive interior services':'Our exclusive design themes from Italy can be customised for you'}}</span>
    </div>

    <div *ngIf ='!newLandingPage()' class="button col-12  d-flex justify-content-center justify-content-md-start order-5 order-md-2 ">
      <a routerLink="/spaces" routerLinkActive="active" target="_blank">
      <button  type="button " class=" d-flex align-items-center mt-2">SEE OUR DESIGNS &nbsp;
         <span>
            <img src="../../../assets/images/Arrow 1.png"/>
         </span>
       </button>
       </a>
    </div>
    <!-- images -->
    <div class="Designs col-12 order-md-3 mt-4 mt-md-0 px-md-4 ">    
      
    <div class=" d-md-block d-none lisitingImages mx-0 my-5 ">
       <div class="row mx-0">
          <div class="col-lg-3 col-md-4 col-sm-4 px-0">
               <ng-container *ngIf ="!newLandingPage()">
                  <img (click)="navigateTo('Dream Ballet')" class="image_carrier d-flex align-items-end cursorPointer" src='../../../assets/images/arrivaeHomePageImages/1.png'>
                  <div class="py-2  imgHeading">
                     <span class="main-page">Dream Ballet</span>
                  </div>
                  <p class="">Dream Ballet celebrates the versatility of the colour grey combined with sophisticated elements- orange accents, clean lines, marble and copper-frosted glass. </p>
               </ng-container>

               <ng-container *ngIf ="newLandingPage()">
                  <img class="image_carrier d-flex align-items-end mb-1" src='../../../assets/images/new_landing/1.png'>
                  <div class="py-2  imgHeading">
                     <span class="new-landing" >Modular Kitchens</span>
                  </div>
               </ng-container>

          </div>
          <div class="col-lg-3 col-md-4 col-sm-4 px-0">

            <ng-container *ngIf ="!newLandingPage()">
               <img (click)="navigateTo('Dark Knight')"  class="image_carrier d-flex align-items-end cursorPointer" src='../../../assets/images/../../../assets/images/doldesign2.png'>
                <div class=" py-2  imgHeading">
                   <span class="main-page">Dark Knight</span>
                </div>
               <p>The personification of opulence, Dark Knight uses a deep neutral palette, plush textiles, metal accents and sleek lighting to create elevated interiors. </p>
            </ng-container>

            <ng-container *ngIf ="newLandingPage()">
               <img   class="image_carrier d-flex align-items-end  mb-1" src='../../../assets/images/new_landing/2.png'>
                <div class=" py-2  imgHeading">
                   <span class="new-landing">Space Planning</span>
                </div>
            </ng-container>


          </div>
          <div class="col-lg-3 col-md-4 col-sm-4 px-0">
               <ng-container *ngIf ="!newLandingPage()">
                  <img (click)="navigateTo('Morandi')"  class="image_carrier d-flex align-items-end cursorPointer" src='../../../assets/images/../../../assets/images/doldesign3.png'>
                <div class="py-2  imgHeading">
                  <span class="main-page">Morandi</span>
                </div>         
               <p>Morandi pairs classic materials steel and wood with a chic urban colour palette, hints of texture and smoky green accents for modern sophisticated interiors.</p>
               </ng-container>

               <ng-container *ngIf ="newLandingPage()">
                  <img  class="image_carrier d-flex align-items-end  mb-1" src='../../../assets/images/new_landing/3.png'>
                <div class="py-2  imgHeading">
                   <span class="new-landing">Decor & Furnishing</span>
                </div>         
               </ng-container>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-4 px-0">
               <ng-container *ngIf ="!newLandingPage()">
                  <img  (click)="navigateTo('Modica')"  class="image_carrier d-flex align-items-end cursorPointer" src='../../../assets/images/../../../assets/images/doldesign4.png'>
                <div class="py-2  imgHeading">
                   <span class="main-page">Modica</span>
                </div>
               <p>Modica marries functionality and minimalism with simple and clean design, adept utilisation of dark wood tones, leather and glass to create elegant interiors.</p>
               </ng-container>

               <ng-container *ngIf ="newLandingPage()">
                  <img    class="image_carrier d-flex align-items-end  mb-1" src='../../../assets/images/new_landing/4a.png'>
                <div class="py-2  imgHeading">
                   <span class="new-landing">Furniture</span><span style="font-size: 22px;" class="new-landing"> (Modular & Custom)</span>
                </div>
               </ng-container>

          </div>
          <div class="col-lg-3 col-md-4 col-sm-4 px-0">
               <ng-container *ngIf ="!newLandingPage()">
                  <img (click)="navigateTo('Urban Elegance')"  class="image_carrier d-flex align-items-end cursorPointer" src='../../../assets/images/../../../assets/images/doldesign5.png'>
                <div class="py-2  imgHeading">
                   <span class="main-page">Urban Elegance</span>
                </div>
               <p> Intelligent storage solutions and versatile, luxurious neutral colour palette are at the forefront of Urban Elegance— perfect for modern homes. </p>
               </ng-container>
               <ng-container *ngIf ="newLandingPage()">
                  <img   class="image_carrier d-flex align-items-end  mb-1" src='../../../assets/images/new_landing/5.png'>
                <div class="py-2  imgHeading">
                   <span class="new-landing">Bathrooms</span>
                </div>
               </ng-container>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-4 px-0">
               <ng-container *ngIf ="!newLandingPage()">
                  <img (click)="navigateTo('Modern Chic')"  class="image_carrier d-flex align-items-end cursorPointer" src='../../../assets/images/../../../assets/images/doldesign6.png'>
                <div class="py-2  imgHeading">
                   <span class="main-page">Modern Chic</span>
                </div>
               <p>Modern Chic blends Art Deco glamour with modern minimalism, featuring gold accents, arrowhead design, metal and glass embellishments, and maximised storage.</p>
               </ng-container>
               <ng-container *ngIf ="newLandingPage()">
                  <img  class="image_carrier d-flex align-items-end  mb-1" src='../../../assets/images/new_landing/6.png'>
                <div class="py-2  imgHeading">
                   <span class="new-landing" >Lighting & Electricals</span>
                </div>
               </ng-container>
          </div>
          <div class="col-lg-3 col-md-4 col-sm-4 px-0">
              <ng-container *ngIf ="!newLandingPage()">
               <img (click)="navigateTo('Golden Murano')"  class="image_carrier d-flex align-items-end cursorPointer" src='../../../assets/images/../../../assets/images/doldesign7.png'>
               <div class="py-2  imgHeading">
                  <span class="main-page">Golden Murano</span>
               </div>
              <p>Perfect for large spaces, Golden Murano creates an ambience of luxury with the expert use of wood and shades of white punctuated with gold accents for a bold look.</p>
              </ng-container>

              <ng-container *ngIf ="newLandingPage()">
               <img   class="image_carrier d-flex align-items-end  mb-1" src='../../../assets/images/new_landing/7.png'>
               <div class="py-2  imgHeading">
                  <span class="new-landing">Civil Work</span>
               </div>
              </ng-container>
            
          </div>
          <div class="col-lg-3 col-md-4 col-sm-4 px-0">
            <ng-container *ngIf ="!newLandingPage()">
               <img (click)="navigateTo('Macaron')"  class="image_carrier d-flex align-items-end cursorPointer" src='../../../assets/images/../../../assets/images/doldesign8.png'>
               <div class="py-2  imgHeading">
                  <span class="main-page">Macaron</span>
              </div>
            <p>Macaron's palette of whites and pinks peppered with textures and light wood accents lends to a charming yet dynamic contemporary aesthetic. 
            </p>
            </ng-container>

            <ng-container *ngIf ="newLandingPage()">
               <img  class="image_carrier d-flex align-items-end  mb-1" src='../../../assets/images/new_landing/8.png'>
               <div class="py-2  imgHeading">
                  <span class="new-landing">Painting Services</span>
               </div>
            </ng-container>

         </div>
          <!-- <div class="col-lg-4 col-md-6 col-sm-6">
             <div  class="image_carrier d-flex align-items-end " style="background-image:url('../../../assets/images/../../../assets/images/doldesign8.png') ;">
                <div class="px-5 py-3  imgHeading">
                   <span>Macaron</span>
                </div>
             </div>
             <p>Contemporary, Youthful And Dynamic Designs
             </p>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6">
             <div   class="image_carrier d-flex align-items-end " style="background-image:url('../../../assets/images/../../../assets/images/doldesign9.png') ;">
                <div class="px-5 py-3  imgHeading">
                   <span>Wooden Fun Kids</span>
                </div>
             </div>
             <p>Playful, Multifunctional And Child-Proof Designs
             </p>
          </div> -->
       </div>
    </div>


    <div class=" d-md-none d-block lisitingImages ">
      <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
         <!-- Indicators -->
         <!-- <ol class="carousel-indicators">
           <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
           <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
           <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
         </ol> -->
         <!-- Wrapper for slides -->
         <div class="carousel-inner">
         <ng-container *ngIf ="!newLandingPage()">
            <div class="carousel-item active text-center ">
               <img (click)="navigateTo('Dream Ballet')" class="image_carrier d-flex align-items-end cursorPointer" src='../../../assets/images/arrivaeHomePageImages/1.png'>
                  <div class="py-1  imgHeading ">
                     <span class="main-page">Dream Ballet</span>
                  </div>
                  <p class="">Dream Ballet celebrates the versatility of the colour grey combined with sophisticated elements- orange accents, clean lines, marble and copper-frosted glass. </p> 
            </div>
            <div class="carousel-item text-center">
                  <img (click)="navigateTo('Dark Knight')"  class="image_carrier d-flex align-items-end cursorPointer" src='../../../assets/images/../../../assets/images/doldesign2.png'>
                  <div class=" py-1  imgHeading">
                     <span class="main-page">Dark Knight</span>
                  </div>
                  <p>The personification of opulence, Dark Knight uses a deep neutral palette, plush textiles, metal accents and sleek lighting to create elevated interiors. </p>
            </div>
            <div class="carousel-item text-center">
                  <img (click)="navigateTo('Morandi')"  class="image_carrier d-flex align-items-end cursorPointer" src='../../../assets/images/../../../assets/images/doldesign3.png'>
                  <div class="py-1  imgHeading">
                     <span class="main-page">Morandi</span>
                  </div>         
                  <p>Morandi pairs classic materials steel and wood with a chic urban colour palette, hints of texture and smoky green accents for modern sophisticated interiors.</p>
            </div>
            <div class="carousel-item text-center">
                  <img  (click)="navigateTo('Modica')"  class="image_carrier d-flex align-items-end cursorPointer" src='../../../assets/images/../../../assets/images/doldesign4.png'>
                  <div class="py-1  imgHeading">
                     <span class="main-page">Modica</span>
                  </div>
                  <p>Modica marries functionality and minimalism with simple and clean design, adept utilisation of dark wood tones, leather and glass to create elegant interiors.</p>
            </div>
            <div class="carousel-item text-center">
                  <img (click)="navigateTo('Urban Elegance')"  class="image_carrier d-flex align-items-end cursorPointer" src='../../../assets/images/../../../assets/images/doldesign5.png'>
                  <div class="py-1  imgHeading">
                     <span class="main-page">Urban Elegance</span>
                  </div>
                  <p> Intelligent storage solutions and versatile, luxurious neutral colour palette are at the forefront of Urban Elegance— perfect for modern homes. </p>
         
            </div>
            <div class="carousel-item text-center">
                  <img (click)="navigateTo('Modern Chic')"  class="image_carrier d-flex align-items-end cursorPointer" src='../../../assets/images/../../../assets/images/doldesign6.png'>
                  <div class="py-1  imgHeading">
                     <span class="main-page">Modern Chic</span>
                  </div>
                  <p>Modern Chic blends Art Deco glamour with modern minimalism, featuring gold accents, arrowhead design, metal and glass embellishments, and maximised storage.</p>
               
            </div>
            <div class="carousel-item text-center">
                  <img (click)="navigateTo('Golden Murano')"  class="image_carrier d-flex align-items-end cursorPointer" src='../../../assets/images/../../../assets/images/doldesign7.png'>
                  <div class="py-1  imgHeading">
                     <span class="main-page">Golden Murano</span>
                  </div>
                  <p>Perfect for large spaces, Golden Murano creates an ambience of luxury with the expert use of wood and shades of white punctuated with gold accents for a bold look.</p>   
            </div>
            <div class="carousel-item text-center">
                  <img (click)="navigateTo('Macaron')"  class="image_carrier d-flex align-items-end cursorPointer" src='../../../assets/images/../../../assets/images/doldesign8.png'>
                     <div class="py-1  imgHeading">
                        <span class="main-page">Macaron</span>
                    </div>
                  <p>Macaron's palette of whites and pinks peppered with textures and light wood accents lends to a charming yet dynamic contemporary aesthetic. </p>
            </div>
         </ng-container>
         <ng-container *ngIf ="newLandingPage()">
            <div class="carousel-item active text-center ">
               <img  class="image_carrier d-flex align-items-end cursorPointer mb-1" src='../../../assets/images/new_landing/1.png'>
                  <div class="py-1  imgHeading ">
                     <span class="new-landing">Modular Kitchens</span>
                  </div>
            </div>
            <div class="carousel-item  text-center ">
               <img  class="image_carrier d-flex align-items-end cursorPointer mb-1" src='../../../assets/images/new_landing/2.png'>
                  <div class="py-1  imgHeading ">
                     <span class="new-landing">Space Planning</span>
                  </div>
            </div>
            <div class="carousel-item  text-center ">
               <img  class="image_carrier d-flex align-items-end cursorPointer mb-1" src='../../../assets/images/new_landing/3.png'>
                  <div class="py-1  imgHeading ">
                     <span class="new-landing">Decor & Furnishing</span>
                  </div>
            </div>
            <div class="carousel-item  text-center ">
               <img  class="image_carrier d-flex align-items-end cursorPointer mb-1" src='../../../assets/images/new_landing/4a.png'>
                  <div class="py-1  imgHeading ">
                     <span class="new-landing">Furniture</span><span style="font-size: 22px;" class="new-landing"> (Modular & Custom)</span>
                  </div>
            </div>
            <div class="carousel-item  text-center ">
               <img  class="image_carrier d-flex align-items-end cursorPointer mb-1" src='../../../assets/images/new_landing/5.png'>
                  <div class="py-1  imgHeading ">
                     <span class="new-landing">Bathrooms</span>
                  </div>
            </div>
            <div class="carousel-item  text-center ">
               <img  class="image_carrier d-flex align-items-end cursorPointer mb-1" src='../../../assets/images/new_landing/6.png'>
                  <div class="py-1  imgHeading ">
                     <span class="new-landing">Lighting & Electricals</span>
                  </div>
            </div>
            <div class="carousel-item  text-center ">
               <img  class="image_carrier d-flex align-items-end cursorPointer mb-1" src='../../../assets/images/new_landing/7.png'>
                  <div class="py-1  imgHeading ">
                     <span class="new-landing">Civil Work</span>
                  </div>
            </div>
            <div class="carousel-item  text-center ">
               <img  class="image_carrier d-flex align-items-end cursorPointer mb-1" src='../../../assets/images/new_landing/8.png'>
                  <div class="py-1  imgHeading ">
                     <span class="new-landing">Painting Services</span>
                  </div>
            </div>
         </ng-container>
           <!-- <div class="carousel-item ">


            <div  class="image_carrier d-flex align-items-end " style="background-image:url('../../../assets/images/../../../assets/images/doldesign8.png') ;">
               <div class="px-5 py-3  imgHeading">
                  <span>Macaron</span>
               </div>
            </div>
            <p>Contemporary, Youthful And Dynamic Designs
            </p>
            
            
           </div>
           <div class="carousel-item ">


            <div   class="image_carrier d-flex align-items-end " style="background-image:url('../../../assets/images/../../../assets/images/doldesign9.png') ;">
               <div class="px-5 py-3  imgHeading">
                  <span>Wooden Fun Kids</span>
               </div>
            </div>
            <p>Playful, Multifunctional And Child-Proof Designs
            </p>
            
            
           </div> -->
          
         <!-- Controls -->
      <a [ngClass] = "newLandingPage() ? 'landingPage' : 'mainPage'" class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
       <span class="carousel-control-prev-icon" aria-hidden="true"></span>
       <span class="sr-only">Previous</span>
      </a>
      <a [ngClass] = "newLandingPage() ? 'landingPage' : 'mainPage'"  class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
       <span class="carousel-control-next-icon" aria-hidden="true"></span>
       <span class="sr-only">Next</span>
      </a>
       </div>

    </div>

    
   </div>
   </div>

 </div>