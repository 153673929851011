<app-new-header-for-home ></app-new-header-for-home>
<!-- banner -->
<div id="banner-image"  class="banner-image mt-md-5  mx-md-0 mx-2">
    <div class="w-100 banner-imagew pb-md-4 pb-0 d-flex justify-content-lg-between align-items-end" >
       <div class="tagline pl-md-5 pl-4  ml-lg-5 mb-3 mb-md-5">
          <p class="">
             Finance Suited<br> 
             for you, made<br>       
             for you<br>
          </p>
          <button class="get-price button_book d-none d-md-block my-5" (click)="bookConsultationLandingPage()" >GET THE BEST PRICE</button>
          <div class="jayaho_logo d-block "> 
          </div>
       </div>  
    </div>
</div>

 <div class="choose-us container-fluid">

     <!-- WHY CHOOSE US -->

    <div class="row">
        <div class="col-md-12">

            <div class="row justify-content-center text-center choose-test mt-5 pt-lg-4">
                <h3>WHY CHOOSE ARRIVAE?</h3>
            </div>

            <div class="row collateral-root justify-content-center mx-lg-5 my-4 my-md-5 p-lg-5">
                <div class="col-md-12 mx-lg-5">
                    <div class="row justify-content-around  each-item mx-md-5">
                        <div class="col-md-4 p-0 ">
                            <div class="row justify-content-center" >
                                <div class="col-md-11 d-flex flex-column align-items-center">
                                    <div class="d-flex justify-content-center mb-3">
                                        <img src="../../../assets/images/finance/money-bag 1.png" class="" />
                                    </div>
                                    <div class="mt-3 img-text">
                                        <p class="mb-3 text-center">Collateral<br>Free Easy Loans</p>
                                    </div>
                                    <div class=" text-center w-75 description">
                                        <p>Our plans lets you choose a suitable load tenure on your  home interior solution</p>
                                    </div>
                               </div>
                               <div class="d-md-none my-4" style="border-bottom: 3px solid #000000; width: 160px;"></div>
                               <!-- <div class="d-none d-md-block" style="border-left: 1px solid gray;"></div> -->
                            </div>
                        </div>
                        <div class="col-md-4 p-0 online-app" style="border-right: 1px solid grey;border-left: 1px solid grey;;">
                           <div class="row justify-content-center">
                               <div class="col-md-11 d-flex flex-column align-items-center">
                                    <div class="mb-3 d-flex justify-content-center">
                                        <img src="../../../assets/images/finance/online-resume 1.png" class="" />
                                    </div>
                                    <div class="mt-3 img-text">
                                        <p class="mb-3 text-center">Online<br>Application</p>
                                    </div>
                                    <div class=" text-center w-75 description">
                                        <p>Apply from anywhere at any time while using a device that is connected to the internet</p>
                                    </div>
                               </div>
                               <!-- <div class="d-none d-md-block" style="border-left: 1px solid gray;"></div> -->
                               <div class="d-md-none my-4" style="border-bottom: 3px solid #000000; width: 160px;"></div>
                           </div>
                        </div>
                        <div class="col-md-4 p-0">
                            <div class="row justify-content-center">
                                <div class="col-lg-11 d-flex flex-column align-items-center">
                                    <div class="mb-3 d-flex justify-content-center">
                                        <img src="../../../assets/images/finance/lean 1.png" class="" />
                                    </div>
                                    <div class="mt-3 img-text">
                                        <p class="mb-3 text-center">Fast<br>Processing</p>
                                    </div>
                                    <div class=" text-center w-75 description">
                                        <p>Address your capital needs within 3 days by availing quick and easy process</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>

     <!-- KNOW YOUR EMI -->

     <div class="row know-emi py-5 mx-n3">
        <div class="col-lg-12">
            <div class="row justify-content-center  choose-test  emi-heading">
                <p class="mb-0">KNOW YOUR EMI</p>
            </div>
            <div class="row">
                <div class="col-lg-12 my-3 my-md-5">
                    <nav class="nav d-flex justify-content-center">
                        <a class="nav-link active"(click)="loandetails('zero-tab')" id="zero-tab"   data-toggle="tab"   href="#zero" aria-controls="zero" role="tab" aria-selected="true">ZERO PLAN</a>
                        <a class="nav-link"       (click)="loandetails('rapid-tab')" id="rapid-tab"  data-toggle="tab"   href="#rapid" role="tab" aria-selected="false">RAPID 12</a>
                        <a class="nav-link"        (click)="loandetails('rapid24-tab')"id="rapid24-tab" data-toggle="tab" href="#rapid24" role="tab" aria-selected="false">RAPID 24</a>
                        <a class="nav-link"        (click)="loandetails('home-tab')"id="home-tab"   data-toggle="tab"   href="#home" role="tab" aria-selected="false">HOME IMPROVEMENT</a>
                        <a class="nav-link"        (click)="loandetails('topup-tab')"id="topup-tab"  data-toggle="tab"   href="#topup" role="tab" aria-selected="false">HOMELOAN TOPUP</a>
                        <a class="nav-link"        (click)="loandetails('personal-tab')"id="personal-tab" data-toggle="tab"href="#personal" role="tab" aria-selected="false">PERSONAL LOAN</a>
                    </nav>
                </div>
            </div>
            <div class="d-none d-sm-flex row my-1 emi-image mx-0 mx-md-5 px-md-5" >
                <div class="col-lg-3 col-sm-6 pr-sm-1 col-12 mb-2 ">
                    <div class="each-emi h-100">
                        <div class="row px-3 py-3 mx-0">
                            <div class="col-2">
                                <img src="../../../assets/images/finance/loan 1.png" class="img-fluid"/>
                            </div>
                            <div class="col-10 align-self-end pl-sm-2 pl-0">
                                <div class="ml-5 right-text">
                                    <p class="mb-0">LOAN AMOUNT:</p>
                                    <p >10 LAKH</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 pr-sm-1 col-12 mb-2">
                    <div class="each-emi h-100">
                        <div class="row px-3 special-row mx-0">
                            <div class="col-2">
                                <img src="../../../assets/images/finance/interest-rate 1.png" class="img-fluid"/>
                            </div>
                            <div class="col-10 align-self-center">
                                <div class="ml-5 right-text">
                                    <p class="mb-0">INTEREST: {{this.intrest_per}}%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="col-lg-3 col-sm-6 pr-sm-1 col-12 mb-2">
                    <div class="each-emi h-100">
                        <div class="row px-3 py-3 mx-0">
                            <div class="col-2">
                                <img src="../../../assets/images/finance/down 1.png" class="img-fluid"/>
                            </div>
                            <div class="col-10 align-self-end">
                               <div class="ml-5 right-text">
                                    <p class="mb-0">DOWNPAYMENT:</p>
                                    <p>{{this.downpayment}} LAKH</p>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-sm-6 pr-sm-1 col-12 mb-2">
                    <div class="each-emi h-100">
                        <div class="row px-3 py-3 mx-0">
                            <div class="col-2">
                                <img src="../../../assets/images/finance/Layer 50 1 (1).png" class="img-fluid"/>
                            </div>
                            <div class="col-10 align-self-end">
                                <div class="ml-5 right-text">
                                    <p class="mb-0">TENURE:</p>
                                    <p>{{this.tenure}} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="d-sm-none row my-1 emi-image " >
                <div class=" col-6 mb-2 px-0 pr-1">
                    <div class="each-emi ">
                        <div class="row align-items-center w-100">
                            <div class="col-3 ">
                                <img src="../../../assets/images/finance/loan 1.png" class="img-fluid" style="height: 30px; width:30px"/>
                            </div>
                            
                            <div class="col-9 " >
                                <p class="mb-0" >LOAN AMOUNT:<br>
                                    10 LAKH</p>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class=" col-6  px-0 pl-1 mb-2">
                    <div class="each-emi ">
                        <div class="row align-items-center w-100">
                            <div class="col-3 ">
                                <img src="../../../assets/images/finance/interest-rate 1.png" class="img-fluid" style="height: 30px; width:30px" />
                            </div>
                            <div class="col-9 " >
                                <p class="mb-0" >INTEREST: {{this.intrest_per}}%</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class=" col-6  px-0 pr-1 mb-2">
                    <div class="each-emi ">
                        <div class="row align-items-center w-100">
                            <div class="col-3  ">
                                <img src="../../../assets/images/finance/down 1.png" class="img-fluid" style="height: 30px; width:30px"/>
                            </div>
                            <div class="col-9 " >
                                 <p class="mb-0" >DOWNPAYMENT:<br>
                                    {{this.tenure}} LAKH</p>
                            </div>
                        </div>
                    </div>
                </div>
                  <div class="col-6  px-0 pl-1 mb-2">
                    <div class="each-emi">
                        <div class="row align-items-center w-100">
                            <div class="col-3 ">
                                <img src="../../../assets/images/finance/Layer 50 1 (1).png" class="img-fluid" style="height: 30px; width:30px"/>
                            </div>
                            <div class="col-9 " >
                                <p class="mb-0" >TENURE:<br>
                                    {{this.tenure}} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-content" id="nav-tabContent">
                <!--Zero tab Content Starte here-->
            <div id="zero" role="tabpanel" class="tab-pane fade show active no-gutters pt-2" aria-labelledby="zero-tab">
                <div class="row">
                    <div class="col-12 p-0">
                        <div class="row justify-content-center  text-center mt-md-4">
                            <h5 class="h5Text">ZERO PLAN</h5>
                        </div>
                        <div class="row justify-content-center mt-2">
                            <div class="col-12 col-sm-11 col-md-7">
                                <p class="paraLength mb-0">Our most attractive 0% interest rate plan. The short term loan will give you the extra edge
                                    to get your dream home interior design without any compromise. 
                                    We believe in complete transparency. There are no hidden charges involved.
                                </p> 
                            </div>
                        </div>
                        
                        <div class="row justify-content-center my-lg-3">
                            <div class="col-10 col-md-4 col-lg-3">
                                <div class="row justify-content-center mt-5 divForButton">
                                    <div class="col-lg-12">
                                        <p class="text-center mb-0 mt-3 start_from">EMI STARTS FROM</p>
                                        <p class="text-center rate">&#8377; 2,50,000</p>
                                        <div class="d-flex justify-content-center mb-3">
                                            <button (click)="bookConsultationLandingPage()" class="Add_plan">ADD THE PLAN</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Zero Tab Content Ends Here-->

            <!-- Rapid 12 Tab content-->
            <div id="rapid" role="tabpanel" class="tab-pane fade show no-gutters pt-2" aria-labelledby="rapid-tab">

                <div class="row">
                    <div class="col-12 p-0">
                        <div class="row justify-content-center  text-center mt-md-4">
                            <h5 class="h5Text">RAPID 12 PLAN</h5>
                        </div>
                        <div class="row justify-content-center mt-2">
                            <div class="col-12 col-sm-11 col-md-7">
                                <p class="paraLength mb-0">Our 12 months low interest rate plan lets you go easy with the monthly EMI
                                    The short term loan will give you the extra edge
                                    to get your dream home interior design without any compromise. 
                                    We believe in complete transparency. There are no hidden charges involved.
                                </p> 
                            </div>
                        </div>

                        <div class="row justify-content-center my-lg-3">
                            <div class="col-10 col-md-4 col-lg-3">
                                <div class="row justify-content-center mt-5 divForButton">
                                    <div class="col-lg-12">
                                        <p class="text-center mb-0 mt-3 start_from">EMI STARTS FROM</p>
                                        <p class="text-center rate">&#8377; 64,000</p>
                                        <div class="d-flex justify-content-center mb-3">
                                            <button (click)="bookConsultationLandingPage()" class="Add_plan">ADD THE PLAN</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Rapid 12 tab content ends here-->


            <!--Rapid 24 tab Content -->
            <div id="rapid24" role="tabpanel" class="tab-pane fade show no-gutters pt-2" aria-labelledby="rapid24-tab">
                <div class="row">
                    <div class="col-12 p-0">
                        <div class="row justify-content-center  text-center mt-md-4">
                            <h5 class="h5Text">RAPID 24 PLAN</h5>
                        </div>
                        <div class="row justify-content-center mt-lg-2">
                            <div class="col-12 col-sm-11 col-md-7">
                                <p class="paraLength mb-0">Our 24 months easy monthly EMI help you plan a repayment over 2 years time period. The short term loan will give you the extra edge to get your dream home interior design without any compromise. We believe in complete transparency. There are no hidden charges involved.
                                </p> 
                            </div>
                        </div>

                        <div class="row justify-content-center my-lg-3">
                            <div class="col-10 col-md-4 col-lg-3">
                                <div class="row justify-content-center mt-5 divForButton">
                                    <div class="col-lg-12">
                                        <p class="text-center mb-0 mt-3 start_from">EMI STARTS FROM</p>
                                        <p class="text-center rate">&#8377; 34,364</p>
                                        <div class="d-flex justify-content-center mb-3">
                                            <button (click)="bookConsultationLandingPage()" class="Add_plan">ADD THE PLAN</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Rapid 24 Content ends here-->

            <!--Home Improvement tab content-->
            <div id="home" role="tabpanel" class="tab-pane fade  show no-gutters pt-2" aria-labelledby="home-tab">
                <div class="row">
                    <div class="col-12 p-0">
                        <div class="row justify-content-center  text-center mt-md-4">
                            <h5 class="h5Text">HOME IMPROVEMENT PLAN</h5>
                        </div>

                        <div class="row justify-content-center mt-lg-2">
                            <div class="col-12 col-sm-11 col-md-7">
                                <p class="paraLength mb-0">Our loan offer with a nominal interest rate spread across <span>60</span> months is perfect for your 
                                    Pockets. With nominal EMI you can opt to get the right home interior solution without 
                                    compromising on the designs. 
                                    We believe in complete transparency. There are no hidden charges involved.
                                </p> 
                            </div>
                        </div>

                        <div class="row justify-content-center my-lg-3">
                            <div class="col-10 col-md-4 col-lg-3">
                                <div class="row justify-content-center mt-5 divForButton">
                                    <div class="col-lg-12">
                                        <p class="text-center mb-0 mt-3 start_from">EMI STARTS FROM</p>
                                        <p class="text-center rate">&#8377; 18,249</p>
                                        <div class="d-flex justify-content-center mb-3">
                                            <button (click)="bookConsultationLandingPage()" class="Add_plan">ADD THE PLAN</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <!--Home Improvement tab content ends here-->

            <!--TOP UP Tab Content-->
            <div id="topup" role="tabpanel" class="tab-pane fade show no-gutters pt-2" aria-labelledby="topup-tab">
                <div class="row">
                    <div class="col-12 p-0">
                        <div class="row justify-content-center  text-center mt-md-4">
                            <h5 class="h5Text">HOMELOAN TOPUP</h5>
                        </div>
                        <div class="row justify-content-center mt-lg-2">
                            <div class="col-12 col-sm-11 col-md-7">
                                <p class="paraLength mb-0">Why manage multiple loan account when Arrivae can help you get a top-up loan on your existing home loan. Your complete interior project costing will be clubbed with your home Loan account and the best part is you are not required to make any down payments We believe in complete transparency. There are no hidden charges involved.
                                </p> 
                            </div>
                        </div>

                        <div class="row justify-content-center my-lg-3">
                            <div class="col-10 col-md-4 col-lg-3">
                                <div class="row justify-content-center mt-5 divForButton">
                                    <div class="col-lg-12">
                                        <p class="text-center mb-0 mt-3 start_from">EMI STARTS FROM</p>
                                        <p class="text-center rate">&#8377; 7,533</p>
                                        <div class="d-flex justify-content-center mb-3">
                                            <button (click)="bookConsultationLandingPage()" class="Add_plan">ADD THE PLAN</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--TOP UP Tab content ends here-->

            <!-- Personal Loan content-->
            <div id="personal" role="tabpanel" class="tab-pane fade show no-gutters pt-2" aria-labelledby="personal-tab">
                <div class="row">
                    <div class="col-12 p-0">
                        <div class="row justify-content-center  text-center mt-md-4 ">
                            <h5 class="h5Text">PERSONAL LOAN</h5>
                        </div>
                        <div class="row justify-content-center mt-lg-2">
                            <div class="col-12 col-sm-11 col-md-7 ">
                                <p class="paraLength mb-0">Our extensive tie-ups with multiple bank can help fetch you the personal loan for your home interior solution. Under this loan your down payment is effectively <span>0</span>. You can opt for Multiple tenure options best suited to your need. We believe in complete transparency. There are no hidden charges involved.
                                </p> 
                            </div>
                        </div>

                        <div class="row justify-content-center my-lg-3">
                            <div class="col-10 col-md-4 col-lg-3">
                                <div class="row justify-content-center mt-5 divForButton">
                                    <div class="col-lg-12">
                                        <p class="text-center mb-0 mt-3 start_from">EMI STARTS FROM</p>
                                        <p class="text-center rate">&#8377;  22,753</p>
                                        <div class="d-flex justify-content-center mb-3">
                                            <button (click)="bookConsultationLandingPage()" class="Add_plan">ADD THE PLAN</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Personal loan tan content ends here-->
            </div>

        </div>
    </div>

 </div>
 
<div class="row justify-content-center px-lg-4 mx-0">
    <div class="col-10 col-md-8 col-lg-8 px-lg-5  px-0">
        <div class="row justify-content-center my-5 px-lg-1">
            <div class="col-md-6  mb-4 mb-md-0 p-0 ">
                <div class="row align-items-center rectangular5  rounded-pill">
                  <div class="col-4  text-center">
                    <img src="../../../assets/images/meeting 1.png" class="consulation" />
                  </div>
                  <div class="col-8 free px-md-2 px-0">
                    <span>FREE CONSULTATION <br>
                        Easy Booking</span>
                     <!-- <a (click)="bookConsultationLandingPage()"><div class="know cursorPointer">KNOW MORE</div></a> -->
                  </div>
                </div>
                <a class="know" (click)="bookConsultationLandingPage()"><div class=" cursorPointer">KNOW MORE</div></a>
            </div>
            <div class="col-md-6 pl-md-3 p-0">
                <div class="row align-items-center rectangular5 rounded-pill">
                    <div class="col-4  text-center">
                      <img src="../../../assets/images/money 1.png" class="consulation" />
                    </div>
                    <div class="col-8 free px-md-2 px-0">
                      <span>EMI OPTION ON<br>
                       COMPLETE PROJECT</span>
                       <!-- <a style="text-decoration: none;" href="/get-finance" target="_blank"><div class="know">KNOW MORE</div></a> -->
                    </div>
                </div>
                <a class="know" style="text-decoration: none;" href="/get-finance" target="_blank"><div class=" cursorPointer">KNOW MORE</div></a>
            </div>
        </div>
    </div>
</div>

<app-madeby-you-stories-new></app-madeby-you-stories-new>
<!-- <app-footer ></app-footer> -->
<app-footer></app-footer>
