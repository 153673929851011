<div class=" arrivae-living   p-md-0 py-4 mx-md-5 mx-3">
      <div class="d-flex flex-column justify-content-start  main-heading ">
         <p class="mb-1"><span class="pb-1">ARRIVAE LIVING</span></p>
         <span class="mobile_discription">Find homes designed by Arrivae in your apartment complex
         </span>
         <div *ngIf="roletoSet() > 650"  >
            <button  type="button " class="mt-2 button d-flex align-items-center" routerLink='/apartment' target="_blank">SEARCH YOUR CITY &nbsp;
               <span>
                  <img src="../../../assets/images/Arrow 1.png"/>
               </span>
             </button>
         </div>
      </div>

   <div class="row align-items-center Slider-mobile px-lg-1 mx-lg-4">

      <div class="col-1 prev">
         <div class="float-right">
            <div class="other-mobile-boqs"  >
              <button   class="mx-lg-1 button-one" (click)="slickController.prev()">
                <img class="cursorPointer"  src="../../assets/images/sign-up-to-service/Arrow copy 2 2.png" alt="previous" >
              </button>
            </div>
        </div>   
      </div>

      <div class="col-10 ">
         <div class="Buildings_container row mx-0 mt-4">
            <div class="mobile-carosel-active-boqs w-100">
              <div slickContainer #slickController="slick" [slickConfig]="config">
                <ng-container *ngIf="slickController.initialize">
               
                    <div slickItem class="card ml-lg-3  cursorPointer" >
                       <a href="https://web.arrivae.com/25-south" target="_blank">


                          <img  class="image_carrier d-flex align-items-end" src='https://s3.amazonaws.com/arrivae-production/contents/documents/000/077/824/original/data?1670059825'>
                             <!-- <div class="w-100 logo-style">
                                <img  src="../../assets/images/25 South logo 1.png" alt="" class="">
                             </div> -->
                             <div class=" py-3 imgHeading">
                                <span>25 South</span>
                                <h3>MUMBAI</h3>
                             </div>
                          
                       </a>
                    </div>
                    <div slickItem class="card ml-lg-3  cursorPointer"  >
                       <a href="https://web.arrivae.com/140-shivaji" target="_blank">
                          <img class="image_carrier d-flex align-items-end" src='../../../assets/images/shiv3.png'>
                          <!-- <div class="w-100 logo-style">
                             <img  src="../../assets/images/logoshivpng.png" alt="" class="">
                          </div> -->
                          <div class=" py-3 imgHeading ">
                             <span>140 Shivaji Park</span>
                             <h3>MUMBAI</h3>
                          </div>
                       </a>
                    </div>
                    <div slickItem class="card ml-lg-3  cursorPointer"  >
                       <a href="https://web.arrivae.com/marathon-nexzone" target="_blank" >
                          <img class="image_carrier d-flex align-items-end" src='https://s3.amazonaws.com/arrivae-production/contents/documents/000/087/707/original/data?1681469134'>
                             <div class="py-3 imgHeading">
                                <span> Marathon Nexzone </span>
                                <h3>NAVI MUMBAI</h3>
                             </div>
                       </a>
                    </div>
                    <div slickItem class="card ml-lg-3  cursorPointer" >
                       <a href="https://web.arrivae.com/anantraj-villa" target="_blank">
                          <img  class="image_carrier d-flex align-items-end" src='../../../assets/images/anath4.png'>
                             <!-- <div class="w-100 logo-style">
                                <img  src="../../assets/images/ananthlogo.png" alt="" class="">
                             </div> -->
                             <div class=" py-3 imgHeading">
                                <span>Anantraj Villa</span>
                                <h3>GURUGRAM</h3>

                             </div>
                       </a>
                    </div>
                 
                    <div slickItem class="card ml-lg-3  cursorPointer" >
                       <a href="https://web.arrivae.com/southern-crest" target="_blank">
                          <img  class="image_carrier d-flex align-items-end" src='../../../assets/images/sriram3.png'>
                             <div class="py-3 imgHeading">
                                <span>Southern Crest</span>
                                <h3>BENGALURU</h3>

                             </div>
                       </a>
                    </div>
                    <div slickItem class="card ml-lg-3  cursorPointer"  >
                       <a href="https://web.arrivae.com/sky-forest" target="_blank">
                          <img class="image_carrier d-flex align-items-end" src='https://s3.amazonaws.com/arrivae-production/contents/documents/000/079/659/original/data?1671785847'>

                             <div class=" py-3 imgHeading">
                                <span>Sky Forest</span>
                                <h3>MUMBAI</h3>

                             </div>
                       </a>
                    </div>
                    <div slickItem class="card ml-lg-3  cursorPointer"  >
                       <a href="https://web.arrivae.com/m3m-skywalk" target="_blank">
                          <img  class="image_carrier d-flex align-items-end" src='../../../assets/images/skywalk4.png'>
                             <!-- <div class="w-100 logo-style">
                                <img  src="../../assets/images/skywalklogo.png" alt="" class="">
                             </div> -->
                             <div class=" py-3 imgHeading">
                                <span>M3M Skywalk</span>
                                <h3>GURGAON</h3>
                           </div>
                     </a>
                  </div>
                  <div slickItem class="card ml-lg-3  cursorPointer" >
                     <a href="https://web.arrivae.com/Tridhaatu-Morya" target="_blank">
                        <img  class="image_carrier d-flex align-items-end" src='../../../assets/images/mourya/4.png'>
                        <div class=" py-3 imgHeading">
                           <span>Tridhaatu Morya</span>
                           <h3>MUMBAI</h3>

                        </div>
                     </a>
                  </div>
                  <div slickItem class="card ml-lg-3  cursorPointer"  >
                     <a href="https://web.arrivae.com/Hubtown_Seasons" target="_blank">
                        <img  class="image_carrier d-flex align-items-end" src='../../../assets/images/Hubtown4.png'>
                           <!-- <div class="w-100 logo-style">
                              <img  src="../../assets/images/Hubtownlogo.png" alt="" class="">
                           </div> -->
                           <div class="py-3 imgHeading">
                              <span>Hubtown Seasons</span>
                              <h3>MUMBAI</h3>

                           </div>
                     </a>
                  </div>
                  <div slickItem class="card ml-lg-3  cursorPointer" >
                     <a href="https://web.arrivae.com/Hubtown_Heaven" target="_blank">
                        <img  class="image_carrier d-flex align-items-end" src='../../../assets/images/Hub_Hev4.png'>
                           <!-- <div class="w-100 logo-style">
                              <img  src="../../assets/images/Hubtownlogo.png" alt="" class="">
                           </div> -->
                           <div class=" py-3 imgHeading">
                              <span>Hubtown Heaven</span>
                              <h3>MUMBAI</h3>

                           </div>
                     </a>
                  </div>
                  <div slickItem class="card ml-lg-3  cursorPointer"   >
                     <a href="https://web.arrivae.com/Hubtown_Harmony" target="_blank">
                        <img  class="image_carrier d-flex align-items-end" src='../../../assets/images/Hub_Har4.png'>
                           <!-- <div class="w-100 logo-style">
                              <img  src="../../assets/images/Hubtownlogo.png" alt="" class="">
                           </div> -->
                           <div class="py-3 imgHeading">
                              <span>Hubtown Harmony</span>
                              <h3>MUMBAI</h3>

                           </div>
                     </a>
                  </div>
                  <div slickItem class="card ml-lg-3  cursorPointer" >
                     <a href="https://web.arrivae.com/Tridhaatu-Aranya" target="_blank">
                        <img  class="image_carrier d-flex align-items-end" src='../../../assets/images/aranya3.png'>
                           <div class="py-3 imgHeading">
                              <span>Tridhaatu Aranya</span>
                              <h3>Chembur</h3>
                           </div>
                     </a>
                  </div>   
                  <div slickItem class="card ml-lg-3  cursorPointer"  >
                     <a href="https://web.arrivae.com/adhiraj-capital-city" target="_blank">
                        <img class="image_carrier d-flex align-items-end" src='https://s3.amazonaws.com/arrivae-production/contents/documents/000/098/809/original/data?1696654061'>

                           <div class=" py-3 imgHeading">
                              <span>Adhiraj Capital City</span>
                              <h3>NAVI MUMBAI</h3>

                           </div>
                     </a>
                  </div>   
                  <div slickItem class="card ml-lg-3  cursorPointer"  >
                     <a [routerLink]="['/Runwal_Gardens']"  target="_blank">
                        <img class="image_carrier d-flex align-items-end" src='https://s3.amazonaws.com/arrivae-production/contents/documents/000/048/321/original/data?1633691901'>
                           <div class=" py-3 imgHeading">
                              <span>Runwal Gardens</span>
                              <h3>MUMBAI</h3>

                           </div>
                     </a>
                  </div>   
                  <div slickItem class="card ml-lg-3  cursorPointer"  >
                     <a [routerLink]="['/dosti-pine']"  target="_blank">
                        <img class="image_carrier d-flex align-items-end" src='https://s3.amazonaws.com/arrivae-production/contents/documents/000/100/064/original/data?1698300683'>
                           <div class=" py-3 imgHeading">
                              <span>Dosti Pine</span>
                              <h3>MUMBAI</h3>

                           </div>
                     </a>
                  </div>    
                  <div slickItem class="card ml-lg-3  cursorPointer"  >
                     <a [routerLink]="['/lodha-amara']"  target="_blank">
                        <img class="image_carrier d-flex align-items-end" src='../../../assets/images/sky3.png'>
                           <div class=" py-3 imgHeading">
                              <span>Lodha Amara</span>
                              <h3>MUMBAI</h3>

                           </div>
                     </a>
                  </div>      
                  <div slickItem class="card ml-lg-3  cursorPointer"  >
                     <a [routerLink]="['/Birla-Alokya']"  target="_blank">
                        <img class="image_carrier d-flex align-items-end" src='https://s3.amazonaws.com/arrivae-production/contents/documents/000/044/743/original/data?1629376524'>
                           <div class=" py-3 imgHeading">
                              <span>Birla Alokya</span>
                              <h3>BENGALURU</h3>

                           </div>
                     </a>
                  </div>    
                  <div slickItem class="card ml-lg-3  cursorPointer"  >
                     <a [routerLink]="['/Rustomjee-Summit']"  target="_blank">
                        <img class="image_carrier d-flex align-items-end" src='../../../assets/images/Rust1.jpg'>
                           <div class=" py-3 imgHeading">
                              <span>Rustomjee Summit</span>
                              <h3>MUMBAI</h3>

                           </div>
                     </a>
                  </div>       
              </ng-container>
            </div>
         
       <!-- <div class="d-lg-none d-block mt-2 text-center">
            <div class="other-mobile-boqs mobile-indicators" >
              <button   class="mx-1 button-one" (click)="slickController.prev()">
                <img class="cursorPointer"  src="../../assets/images/sign-up-to-service/Arrow copy 2 2.png" alt="previous" >
              </button>
           <button  class="mx-1 button-two" (click)="slickController.next()">
             <img class="cursorPointer" src="../../assets/images/sign-up-to-service/Arrow copy 2 1.png" alt="next" >
           </button>
         </div>
     </div> -->
   </div>

   <a *ngIf="roletoSet() < 650"  routerLink='/apartment' target="_blank">
      <button  type="button " class="my-2 button d-flex align-items-center">SEARCH YOUR CITY &nbsp;
         <span>
            <img src="../../../assets/images/Arrow 1.png"/>
         </span>
       </button>
   
   </a>



       
    </div>
      </div>

    <div class="col-1 next">
      <div class="float-right floatInMobile ">
         <div class="other-mobile-boqs"  >
           
           <button  class="mx-lg-1 button-one" (click)="slickController.next()">
             <img class="cursorPointer" src="../../assets/images/sign-up-to-service/Arrow copy 2 1.png" alt="next" >
           </button>
         </div>
      </div>
     
   </div>

   </div>
 </div>