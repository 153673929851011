export let MadeByYouStories: any = [
    {
        "heading": "Priyanka and Anand",
        "city": "Mumbai",
        "content": "Anand is a financial analyst. His motto in life is to live life fully without any regrets. He loves watching films of various genres and wishes to write a book someday. Priyanka being the home minister wanted her home to be open, colourful with a warm and comfortable ambience."
    }
]

export let MadeByStoriesFirst: any = [
    {
        "id": 1,
        "heading": "Radhika and Suyash",
        "city": "Mumbai",
        "images": [
            "assets/images/Landing_page_updated/MadebyyouStories_files/Beacon_Image_2.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Kitchen_Image_2.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Living_Room_2.jpg"
        ],
        "content": "Radhika and Suyash are young entrepreneurs and they have a 5 year old daughter. They are art lovers and like to lead a simple and tasteful life. For their 2BHK home, they wanted an open space with a premium look and they preferred modular furniture that saves them space."
    },
    {
        "id": 2,
        "heading": "Chetana and Neeraj",
        "city": "Mumbai",
        "images": [
            "assets/images/Landing_page_updated/MadebyyouStories_files/New_Image_Cust_1.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/New_Image_Cust_2.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/New_Image_Cust_3.jpg",
        ],
        "content": "The one word brief that we got from Chetana and Neeraj for their house is – calmness. They know the value of a peaceful mind and its impact on mental and physical health. Basis their brief, we designed a minimalistic look for their 1BHK home."
    }
]

export let MadeByYouStoryData: any = [
    {
        "id": 3,
        "heading": "Meenal and Sukesh",
        "city": "Mumbai",
        "content": "Meenal and Sukesh love nature. They love going for camping and trekking often. Taking inspiration from their passion, we came up with a jungle theme for their bedroom interior. We explored the magical world of wallpapers and came up with this beautiful wallpaper.",
        "imageUrl": [
            "assets/images/Landing_page_updated/MadebyyouStories_files/Beacon_Image_4.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Kitchen_Image_4.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Living_Room_4.jpg"
        ]
    },
    {
        "id": 4,
        "heading": "Shreya and Punit",
        "city": "Mumbai",
        "content": "Shreya and Punit live in their 2BHK flat in Mumbai. They love colours and preferred a rich texture for their interiors. Taking their brief, we decided to give a premium look to the space. The different shades of grey on the couch and the walls matched well with the rich burgundy drapes.",
        "imageUrl": [
            "assets/images/Landing_page_updated/MadebyyouStories_files/Beacon_Image_5.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Kitchen_Image_5.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Living_Room_5.jpg",
        ]
    },
    {
        "id": 5,
        "heading": "Famous Co-Working Space",
        "city": "Mumbai",
        "content": "A designer’s worst nightmares are columns, pillars and other vertical elements that obstruct the synergy of the flow of design. Our client in Bengaluru faced a similar challenge. He wanted us to design his co-working office space of around 12,500 sq. ft.",
        "imageUrl": [
            "assets/images/Landing_page_updated/MadebyyouStories_files/Beacon_Image_6.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Kitchen_Image_6.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Living_Room_6.jpg",
        ]
    },
    {
        "id": 6,
        "heading": "Leading Beverage Brand",
        "city": "Mumbai",
        "content": "The area in an office is always limited but maximising space in that area itself, always poses as a challenge. The task was to design a 1,500 sq.ft. office space for a start-up in Calicut. There was space constraint and that required highly effective space optimisation.",
        "imageUrl": [
            "assets/images/Landing_page_updated/MadebyyouStories_files/Beacon_Image_7.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Kitchen_Image_7.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Living_Room_7.jpg",
        ]
    }
]

export let MadeByStoryViewData: any = [
    {
        "heading": "Priyanka and Anand",
        "city": "Mumbai",
        "title": "Arrivae designers made intelligent use of the available space to bring our dream home interiors to life.",
        "content": "Anand is a financial analyst. His motto in life is to live life fully without any regrets. He loves watching films of various genres and wishes to write a book someday.Priyanka being the home minister wanted her home to be open, colourful with a warm and comfortable ambience. Their brief for their 1BHK interiors led us to explore colours and we made bold colours, a signature statement of the house. Wooden texture in the bedroom with washed out walls gives an industrial look to the room. The all-white L shaped kitchen, with a splash of mustard yellow on the tiles makes the space vibrant and brings in a lot of positivity.",
        "header": "Priyanka Singh",
        "somethingNew": "The Start of Something New",
        "contentNew1": "What drew us to Arrivae was their 45-day delivery promise and their professionalism. Their after-sales service has been superb. We’ve been treated like family by the Chennai team.",
        "contentNew2": "The name Arrivae popped up on the internet when I searched for ‘best home interiors’. It was easy to understand the design process because of HomeLane’s detailed website. Excited to take the next steps, I got in touch with their Design Partner Karthikeyan who was phenomenal. He took care of the job with minimal efforts and supervision from us. I am glad I made the decision of going ahead with Arrivae.",
        "images": [
            "assets/images/Landing_page_updated/MadebyyouStories_files/Kitchen_Image_1.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Beacon_Image_1.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Living_Room_1.jpg"
        ]
    },
    {
        "heading": "Radhika and Suyash",
        "city": "Mumbai",
        "title": "Arrivae designers made intelligent use of the available space to bring our dream home interiors to life.",
        "content": "Radhika and Suyash are young entrepreneurs and they have a 5 year old daughter. They are art lovers and like to lead a simple and tasteful life. For their 2BHK home, they wanted an open space with a premium look and they preferred modular furniture that saves them space when not in use. This got our interior design team all excited to create something different for this family. In the lliving room, we created a bookshelf designed with a unique low sofa with foldable dining table inside. With a movable pantry table having hidden wheels, it doubles up as a work table. The premium looking kitchen maximises storage to keep the space clutter free. The most beautiful part is the kids’ room interior, with a tree house with an additional space for play area too. A murphy bed with storage is used to make the most of the space.",
        "header": "Radhika and Suyash",
        "somethingNew": "The Start of Something New",
        "contentNew1": "What drew us to Arrivae was their 45-day delivery promise and their professionalism. Their after-sales service has been superb. We’ve been treated like family by the Chennai team.",
        "contentNew2": "The name Arrivae popped up on the internet when I searched for ‘best home interiors’. It was easy to understand the design process because of HomeLane’s detailed website. Excited to take the next steps, I got in touch with their Design Partner Karthikeyan who was phenomenal. He took care of the job with minimal efforts and supervision from us. I am glad I made the decision of going ahead with Arrivae.",
        "images": [
            "assets/images/Landing_page_updated/MadebyyouStories_files/Kitchen_Image_2.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Beacon_Image_2.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Living_Room_2.jpg"
        ]
    },
    {
        "heading": "Chetana and Neeraj",
        "city": "Mumbai",
        "title": "Arrivae designers made intelligent use of the available space to bring our dream home interiors to life.",
        "content": "The one word brief that we got from Chetana and Neeraj for their house is – calmness. They know the value of a peaceful mind and its impact on mental and physical health. Basis their brief, we designed a minimalistic look for their 1BHK home that doesn’t clutter the space unnecessarily and leaves a lot of open space. The L shape sofa gives enough space for sitting and there are a couple of pouffes and pillows for a more leisurely sit out. The colour combination of hues of yellow and blue is a lovely contrast to the white walls and makes the space bright and cheerful. The solid wood drawer and the TV unit are complimenting the overall look and adding in a natural element.",
        "header": "Chetana and Neeraj",
        "somethingNew": "The Start of Something New",
        "contentNew1": "What drew us to Arrivae was their 45-day delivery promise and their professionalism. Their after-sales service has been superb. We’ve been treated like family by the Chennai team.",
        "contentNew2": "The name Arrivae popped up on the internet when I searched for ‘best home interiors’. It was easy to understand the design process because of HomeLane’s detailed website. Excited to take the next steps, I got in touch with their Design Partner Karthikeyan who was phenomenal. He took care of the job with minimal efforts and supervision from us. I am glad I made the decision of going ahead with Arrivae.",
        "images": [
            "assets/images/Landing_page_updated/MadebyyouStories_files/Kitchen_Image_3.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Beacon_Image_3.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Living_room_3.jpg"
        ]
    },
    {
        "heading": "Meenal and Sukesh",
        "city": "Mumbai",
        "title": "Arrivae designers made intelligent use of the available space to bring our dream home interiors to life.",
        "content": "Meenal and Sukesh love nature. They love going for camping and trekking every now and then. Taking inspiration from their passion, we came up with a jungle theme for their bedroom interior. We explored the magical world of wallpapers and came up with this beautiful wallpaper that goes really well with the theme. With a neutral coloured bed, we added a unique headboard that runs along the length and curve of one wall for the much needed comfort. The dark green blanket and pouffe lift the look of the room by a few notches. The dark wooden flooring completes the look giving a warm and cosy vibe. For a special camping feel, we added lamps suspended in the air converting the bedroom into a wonderful trekking experience.",
        "header": "Meenal and Sukesh",
        "somethingNew": "The Start of Something New",
        "contentNew1": "What drew us to Arrivae was their 45-day delivery promise and their professionalism. Their after-sales service has been superb. We’ve been treated like family by the Chennai team.",
        "contentNew2": "The name Arrivae popped up on the internet when I searched for ‘best home interiors’. It was easy to understand the design process because of HomeLane’s detailed website. Excited to take the next steps, I got in touch with their Design Partner Karthikeyan who was phenomenal. He took care of the job with minimal efforts and supervision from us. I am glad I made the decision of going ahead with Arrivae.",
        "images": [
            "assets/images/Landing_page_updated/MadebyyouStories_files/Kitchen_Image_4.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Beacon_Image_4.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Living_Room_4.jpg"
        ]
    },
    {
        "heading": "Shreya and Punit",
        "city": "Mumbai",
        "title": "Arrivae designers made intelligent use of the available space to bring our dream home interiors to life.",
        "content": "Shreya and Punit live in Mumbai in their 2BHK apartment. They love colours and preferred a rich texture for their interiors. Taking their brief, we decided to give an uber premium look to the space. The different shades of grey on the walls and the couch are boldly supported by the rich burgundy drapes. This combination is again complimented by the generous touches of golden accessories. The drama of this space gets further heightened by warm mood lighting, which gives a palatial feel to the ambience.",
        "header": "Shreya and Punit",
        "somethingNew": "The Start of Something New",
        "contentNew1": "What drew us to Arrivae was their 45-day delivery promise and their professionalism. Their after-sales service has been superb. We’ve been treated like family by the Chennai team.",
        "contentNew2": "The name Arrivae popped up on the internet when I searched for ‘best home interiors’. It was easy to understand the design process because of HomeLane’s detailed website. Excited to take the next steps, I got in touch with their Design Partner Karthikeyan who was phenomenal. He took care of the job with minimal efforts and supervision from us. I am glad I made the decision of going ahead with Arrivae.",
        "images": [
            "assets/images/Landing_page_updated/MadebyyouStories_files/Kitchen_Image_5.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Beacon_Image_5.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Living_Room_5.jpg",
        ]
    },
    {
        "heading": "Famous Co-Working Space",
        "city": "Mumbai",
        "title": "Arrivae designers made intelligent use of the available space to bring our dream home interiors to life.",
        "content": "A designer’s worst nightmares are columns, pillars and other vertical elements that obstruct the synergy of the flow of design. Our client in Bengaluru faced a similar challenge. He wanted us to design his co-working office space of around 12,500 sq. ft. At Arrivae, we truly understand that space utilisation is the most important aspect of an office design. At the same time, the space needs to be modern and vibrant. We tackled the space around pillars, by making a sitting area around it with movable tables. This created a fun and comfortable working space that utilised the area in an interesting and appealing way.",
        "header": "Famous Co-Working Space",
        "somethingNew": "The Start of Something New",
        "contentNew1": "What drew us to Arrivae was their 45-day delivery promise and their professionalism. Their after-sales service has been superb. We’ve been treated like family by the Chennai team.",
        "contentNew2": "The name Arrivae popped up on the internet when I searched for ‘best home interiors’. It was easy to understand the design process because of HomeLane’s detailed website. Excited to take the next steps, I got in touch with their Design Partner Karthikeyan who was phenomenal. He took care of the job with minimal efforts and supervision from us. I am glad I made the decision of going ahead with Arrivae.",
        "images": [
            "assets/images/Landing_page_updated/MadebyyouStories_files/Kitchen_Image_6.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Beacon_Image_6.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Living_Room_6.jpg",
        ]
    },
    {
        "heading": "Leading Beverage Brand",
        "city": "Mumbai",
        "title": "Arrivae designers made intelligent use of the available space to bring our dream home interiors to life.",
        "content": "The area in an office is always limited but maximising space in that area itself, always poses as a challenge. The task was to design a 1,500 sq.ft. office space for a start-up in Calicut. There was space constraint and that required highly effective space optimisation. When discussing how to manage the space constraint, we realised that a passage is a space that is most neglected. This project gave us an opportunity to break the monotony of the passage and utilise it in a collaborative way. We added a few sliding glass panels that doubled up as a writing board. Next, we created a sitting area and put a few portable tables for keeping laptop and books. This way the aisle could be used as a multifunctional space.",
        "header": "Leading Beverage Brand",
        "somethingNew": "The Start of Something New",
        "contentNew1": "What drew us to Arrivae was their 45-day delivery promise and their professionalism. Their after-sales service has been superb. We’ve been treated like family by the Chennai team.",
        "contentNew2": "The name Arrivae popped up on the internet when I searched for ‘best home interiors’. It was easy to understand the design process because of HomeLane’s detailed website. Excited to take the next steps, I got in touch with their Design Partner Karthikeyan who was phenomenal. He took care of the job with minimal efforts and supervision from us. I am glad I made the decision of going ahead with Arrivae.",
        "images": [
            "assets/images/Landing_page_updated/MadebyyouStories_files/Kitchen_Image_7.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Beacon_Image_7.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Living_Room_7.jpg",
        ]
    },
    {
        "heading": "Prominent Financial Brand",
        "city": "Mumbai",
        "title": "Arrivae designers made intelligent use of the available space to bring our dream home interiors to life.",
        "content": "When designing a commercial space, a big challenge is to bring alive the philosophy of the organisation into design and creating an employee friendly ambience. We received a brief for designing a 10,000 sq.ft. office space of a beverage company. The challenging aspect was to transform a dead curved corner into a multi-purpose work cum break out space. Since it was a beverage company, it gave us scope to have a lot of fun and flexibility to make the ambience light and chilled out. We decided to add fluidity in our designs when doing up the curved corner by creating multi-layer sitting arrangement and turned the corner into an amphitheatre cum break out zone where any internal events could be planned or the space could generally be used for unwinding, by the employees.",
        "header": "Prominent Financial Brand",
        "somethingNew": "The Start of Something New",
        "contentNew1": "What drew us to Arrivae was their 45-day delivery promise and their professionalism. Their after-sales service has been superb. We’ve been treated like family by the Chennai team.",
        "contentNew2": "The name Arrivae popped up on the internet when I searched for ‘best home interiors’. It was easy to understand the design process because of HomeLane’s detailed website. Excited to take the next steps, I got in touch with their Design Partner Karthikeyan who was phenomenal. He took care of the job with minimal efforts and supervision from us. I am glad I made the decision of going ahead with Arrivae.",
        "images": [
            "assets/images/Landing_page_updated/MadebyyouStories_files/Kitchen_Image_8.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Kids_Room_1.jpg",
            "assets/images/Landing_page_updated/MadebyyouStories_files/Living_Room_8.jpg",
        ]
    }
]
