<div *ngIf="errorissue">
  <div *ngIf="!customerTimeline">
    <app-errorpage></app-errorpage>
  </div>
</div>
<div *ngIf="customerTimeline">
  <div class="mt-lg-5 mt-0">
    <div class="row">
      <div
        class="rk-ui-notification"
        style="height: 20px !important"
        *ngIf="successalert"
        style="z-index: 1000000 !important"
      >
        <span id="notificationMessageText" style="margin: auto">{{
          successMessage
        }}</span>
        <a
          class="close rk-linknotification"
          style="color: white"
          (click)="successalert = false"
          >×</a
        >
      </div>
      <div class="rk-ui-notification err" *ngIf="erroralert">
        <span id="notificationMessageText">{{ errorMessage }}</span>
        <a
          class="close rk-linknotification"
          style="color: white"
          (click)="erroralert = false"
          >×</a
        >
      </div>
    </div>
    <div class="position-sticky sticky-top bg_8d0528">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-md-12">
            <div class="row">
              <div class="col-md-12">
                <nav class="navbar navbar-expand-lg ml-md-2 px-0 px-md-3">
                  <a class="navbar-brand" href="#">
                    <img
                      src="../assets/images/logocb.png"
                      alt="image"
                      class="Group-573"
                    />
                  </a>
                  <a
                    role="button"
                    class="navbar-toggler p-0"
                    type="button"
                    style="-webkit-appearance: none !important"
                    data-toggle="collapse"
                    data-target="#navbarNavAltMarkup"
                    aria-controls="navbarNavAltMarkup"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <img
                      src="../../../assets/images/navar-button.png"
                      class=""
                    />
                  </a>
                  <span class="navbar-toggler-icon"></span>
                  <div
                    class="collapse navbar-collapse spaces-Get-Finance"
                    id="navbarNavAltMarkup"
                  >
                    <div class="navMainSection my-4">
                      <ul class="navbar-nav nav">
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            routerLink="/customer-dashboard"
                            routerLinkActive="current"
                            >Dashboard</a
                          >
                        </li>
                      
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            target="_blank"
                            href="https://web.arrivae.com/terms-and-conditions"
                            >Terms and Conditions</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            target="_blank"
                            href="https://arrivae.com/arrivae_stories"
                            >About Arrivae</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            style="cursor: pointer"
                            class="nav-link"
                            (click)="logout()"
                            >Logout</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="summary-links pt-4 px-4 sticky-top">
    <a class="mx-lg-3  mx-4 pb-3" (click) ="scrollToSummary('#projsummary')"  [ngClass] = "{'summary-active' : linksActive == 'summary'}" >Summary</a>
    <ng-container *ngIf ="costBreakUpData && costBreakUpData.type_wise_breakup.length !==0  &&  costBreakUpData.space_wise_breakup.space_uniq.length !==0">
      <a class="mx-lg-3 pb-3   mx-4" (click) = "scrollToCostBreakup('#valueCostBreak')"  [ngClass] = "{'summary-active' : linksActive == 'costBreak'}" >Cost Breakup</a>
    </ng-container>
    <ng-container *ngIf ="paymentSection">
      <a  *ngIf = "paymentSection.lead_payment_request.amount_to_be_paid.ten_per.length !==0  ||  paymentSection.lead_payment_request.amount_to_be_paid.forty_per.length !==0 || paymentSection.lead_payment_request.amount_to_be_paid.fifty_per.length !==0  "class="mx-lg-3  mx-4 pb-3"  (click) = "scrollToPayment('#paymentSection22')"   
      [ngClass] = "{'summary-active' : linksActive == 'paymentDetail' }">Payment Details</a>
    </ng-container>
    <ng-container *ngIf ="AllBoqs && AllBoqs.length > 0">
      <a class="mx-lg-3  mx-4 pb-3" (click) ="scrollToAllBoqs('#fullBoq')"  [ngClass] = "{'summary-active' : linksActive == 'otherActiveBoqs'}"  >All BOQs</a>
    </ng-container>
    <a class="mx-lg-3 pb-3  mx-4" (click) = "extraItems('#scrollItems')"  [ngClass] = "{'summary-active' : linksActive == 'mooreItems'  }">Explore Items</a>
    <a class="mx-lg-3 pb-3 mx-4" (click) = "boardInspiration('#rwerwe')" [ngClass] = "{'summary-active' : linksActive == 'nexxtSteps'}" >Inspiration Board</a>
    <a class="mx-lg-3 pb-3 mx-4" (click) = "termsConditions('#termstop')"  [ngClass] = "{'summary-active' : linksActive == 'termsconditions'}" >Terms & Conditions</a>
  </div>


<div id="projsummary">

  <div (scroll)="scrollHandler()" class="hello-section">
    <p class="hello-with-name mb-0">
      Hello <span class="colorBurguntee font-weight-bold">{{ customer_name }}</span>
    </p>
  
    <p *ngIf = "paymentSection.customer_dashboard_status  !== 'Handover' ; else handoverBlock ">
      You are just
      <span style="font-size: 1.4rem;" class="font-weight-bold">{{ projectDirectory.remaining_days_to_complete === 'NA'  ? 'few' : (projectDirectory.remaining_days_to_complete) }}</span>  days away from your dream
      home!
      <span>Your project is in
          <span *ngIf ="paymentSection.customer_dashboard_status !== 'Initial BOQ Shared' &&   paymentSection.customer_dashboard_status !== 'Approved/Rejected' &&  paymentSection.customer_dashboard_status !== 'Pending' " style="text-transform: capitalize;" class="colorBurguntee font-weight-bold">{{paymentSection.customer_dashboard_status}}</span>
          <span *ngIf ="paymentSection.customer_dashboard_status === 'Initial BOQ Shared'" style="text-transform: capitalize;" class="colorBurguntee font-weight-bold">BOQ Shared</span>
          <span *ngIf ="paymentSection.customer_dashboard_status === 'Approved/Rejected'" style="text-transform: capitalize;" class="colorBurguntee font-weight-bold">BOQ Approved</span>
          <span *ngIf ="paymentSection.customer_dashboard_status === 'Pending'" style="text-transform: capitalize;" class="colorBurguntee font-weight-bold">WIP</span>
          Stage</span>
    </p>
    <ng-template #handoverBlock>
      <p>Congratulations!! Your Dream Home has been handed over!</p>
    </ng-template>
  </div>

  <!-- desktop view -->
  <div class="progress-timeline d-sm-none d-md-block d-none"  [ngClass]="{'d-lg-block':  boq_status_level.boq_timeline_level === 'level_2' ||  boq_status_level.boq_timeline_level === 'level_3', 'd-lg-none' : boq_status_level.boq_timeline_level === 'level_1'  }">
    <div id="timeline-accordian" class="accordion1 horizontal  justify-content-center d-flex">
     <ul>
       <li [ngClass]="{'d-none': !check_1, '': check_1}">
         <input type="radio" id="vert-1" name="vert-accordion" (click)="check1()"/>
<label for="vert-1" style="position: relative;">
  <span class="mt-4" style="writing-mode: vertical-lr; transform: rotate(-180deg);">&nbsp;&nbsp; Order&nbsp;Booking</span>
<span class="staic_number">1</span>
<span [ngClass]="{ 'arrow_right': checked1, '': !checked1}"></span>
</label>
<div class="content m-3">
  <div id="applying_styles_steps">
 <ul class="nav nav-pills mt-4"  id="pills-tab-one" role="tablist">
   <li id="border-1" class="nav-item active_before" style="width: 79px;">
     <a
        class="nav-link active mb-3"
        id="pills-1-tab"
        data-toggle="pill"
        href="#pills-1"
        role="tab"
        aria-controls="pills-1"
        aria-selected="true"
        style="width: 40px; height: 41px;"
        >
        <span>
          <!-- <i class="fa fa-check" aria-hidden="true"></i> -->
          <img src="../../assets/images/Vector.png" style="margin-left: -8px;
          margin-top: -1px;">
        </span>
        <!-- <ng-template #elseBlock>
          <span>1</span>
        </ng-template> -->
        </a
      >
      <a class="date_name_style">Estimate Shared</a>
        <div class="date_name_style numbers" *ngIf="level1_data.shared_date !== null">({{
                          level1_data.shared_date
                            | date: "dd MMM YYYY"
                        }})</div>
        <!-- <div *ngIf="boq_status_data.level_1.shared_date === null"></div> -->
      
   </li>
   <li id="border-2" class="nav-item" style="width: 79px;" [ngClass]="{
      'changes2': boq_status_level.boq_status_service !== 'Initial BOQ Shared'
    }">
      <a
        class="nav-link mb-3"
        [ngClass]="{
      active: boq_status_level.boq_status_service !== 'Initial BOQ Shared'
    }"
        id="pills-2-tab"
        data-toggle="pill"
        href="#pills-2"
        role="tab"
        aria-controls="pills-2"
        aria-selected="false"
        style="width: 40px; height: 41px;"
        >
        <span *ngIf="boq_status_level.boq_status_service !== 'Initial BOQ Shared'; else elseBlock11112"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
          margin-top: -1px;"></span>
        <ng-template #elseBlock11112>
          <span>2</span>
        </ng-template></a
      >
      <a class="date_name_style">Estimate Approved</a>
      <div class="date_name_style numbers" *ngIf="level1_data.approved_date !== null">({{
                          level1_data.approved_date
                            | date: "dd MMM YYYY"
                        }})</div>
      <div *ngIf="level1_data.approved_date === null"></div>
    </li>
    <li id="border-3" class="nav-item" style="width: 79px;" [ngClass]="{
      'changes2': (boq_status_level.boq_status_service !== 'Initial BOQ Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected')
    }">
      <a
        class="nav-link mb-3"
        id="pills-3-tab"
        [ngClass]="{
      active: (boq_status_level.boq_status_service !== 'Initial BOQ Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected')
    }"
        data-toggle="pill"
        href="#pills-3"
        role="tab"
        aria-controls="pills-3"
        aria-selected="false"
        style="width: 40px; height: 41px;"
        ><span *ngIf="(boq_status_level.boq_status_service !== 'Initial BOQ Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected'); else elseBlock111"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
          margin-top: -1px;"></span>
        <ng-template #elseBlock111>
          <span>3</span>
        </ng-template></a
      >
      <a class="date_name_style"><span class="numbers">10%</span> Paid</a>
      <div class="date_name_style numbers" *ngIf="level1_data['10_per_paid_date'] !== null">({{level1_data['10_per_paid_date'] | date: "dd MMM YYYY"}})</div>
      <div *ngIf="level1_data['10_per_paid_date'] === null"></div>
    </li>
    
 </ul> 
 </div>  
</div>
         </li>

         <li [ngClass]="{'d-none': !check_2, '': check_2}">
    <input type="radio" id="vert-2" name="vert-accordion" (click)="check2()"/>
    <label for="vert-2" style="position: relative;">
     <span class="mt-4" style="writing-mode: vertical-lr; transform: rotate(-180deg);">&nbsp;&nbsp; Order&nbsp;Confirmation</span>
     <span class="staic_number">2</span>
     <span [ngClass]="{ 'arrow_right': checked2, '': !checked2}"></span>
    </label>
    <div class="content3 m-3">
      <div id="applying_styles_steps23" class="mt-4">
     <ul class="nav nav-pills"  id="pills-tab" role="tablist">

       <li id="border-1" class="nav-item" style="width: 91px;" [ngClass]="{
          'changes2': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid')
        }">
         <a
            class="nav-link mb-3"
            [ngClass]="{
          'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid')
        }"
            id="pills-1-tab"
            data-toggle="pill"
            href="#pills-1"
            role="tab"
            aria-controls="pills-1"
            aria-selected="true"
            style="width: 40px; height: 41px;"
            ><span *ngIf="(boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid'); else elseBlock21"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
              margin-top: -1px;"></span>
            <ng-template #elseBlock21>
              <span>1</span>
            </ng-template></a
          >
          <a class="date_name_style">Site Measurement </a>
         <div class="date_name_style numbers" *ngIf="level2_data['site_measurement_date'] !== null">({{level2_data['site_measurement_date'] | date: "dd MMM YYYY"}})</div>
      <div *ngIf="level2_data['site_measurement_date'] === null"></div>
       </li>
       <li id="border-2" class="nav-item" style="width: 91px;" [ngClass]="{
        'changes2': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement')
      }">
       <a
          class="nav-link mb-3"
          [ngClass]="{
        'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement')
      }"
          id="pills-1-tab"
          data-toggle="pill"
          href="#pills-1"
          role="tab"
          aria-controls="pills-1"
          aria-selected="true"
          style="width: 40px; height: 41px;"
          ><span *ngIf="(boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement'); else elseBlock22"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
            margin-top: -1px;"></span>
          <ng-template #elseBlock22>
            <span>2</span>
          </ng-template></a
        >
         <a class="date_name_style">Design Iteration </a>
       <div class="date_name_style numbers" *ngIf="level2_data.design_iteration_meeting != null"> ({{level2_data.design_iteration_meeting_data | date: "dd MMM YYYY"}})</div>
    <div *ngIf="level2_data.design_iteration_meeting == null"></div>
     </li>

       <li id="border-2" class="nav-item" style="width: 91px;" [ngClass]="{
          'changes2': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Site Measurement')
        }">
         <a
            class="nav-link mb-3"
            [ngClass]="{
          'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Site Measurement')
        }"
            id="pills-1-tab"
            data-toggle="pill"
            href="#pills-1"
            role="tab"
            aria-controls="pills-1"
            aria-selected="true"
            style="width: 40px; height: 41px;"
            ><span *ngIf="(boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Site Measurement'); else elseBlock2222"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
              margin-top: -1px;"></span>
            <ng-template #elseBlock2222>
              <span>3</span>
            </ng-template></a
          >
           <a class="date_name_style">Material Selection</a>
         <div class="date_name_style numbers" *ngIf="level2_data['material_selection_date'] !== null">({{level2_data['material_selection_date'] | date: "dd MMM YYYY"}})</div>
      <div *ngIf="level2_data['material_selection_date'] === null"></div>
       </li>
       <li id="border-2" class="nav-item" style="width: 91px;" [ngClass]="{
          'changes2': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Material Selection') 
        }">
         <a
            class="nav-link mb-3"
            [ngClass]="{
          'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Material Selection')
        }"
            id="pills-1-tab"
            data-toggle="pill"
            href="#pills-1"
            role="tab"
            aria-controls="pills-1"
            aria-selected="true"
            style="width: 40px; height: 41px;"
            ><span *ngIf="(boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Material Selection'); else elseBlock23"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
              margin-top: -1px;"></span>
            <ng-template #elseBlock23>
              <span>4</span>
            </ng-template></a
          >
          <a class="date_name_style">Site Validation</a>
         <div class="date_name_style numbers" *ngIf="level2_data['Site_validation_date'] !== null">({{level2_data['Site_validation_date'] | date: "dd MMM YYYY"}})</div>
      <div *ngIf="level2_data['Site_validation_date'] === null"></div>
       </li>


       <li id="border-2" class="nav-item" style="width: 91px;" [ngClass]="{
          'changes2': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation')
        }">
         <a
            class="nav-link mb-3"
            [ngClass]="{
          'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation')
        }"
            id="pills-1-tab"
            data-toggle="pill"
            href="#pills-1"
            role="tab"
            aria-controls="pills-1"
            aria-selected="true"
            style="width: 40px; height: 41px;"
            ><span *ngIf="(boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation'); else elseBlock24"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
              margin-top: -1px;"></span>
            <ng-template #elseBlock24>
              <span>5</span>
            </ng-template></a
          >
          <a class="date_name_style">QC Approval</a>
         <div class="date_name_style numbers" *ngIf="level2_data['qc_approval_date'] !== null">({{level2_data['qc_approval_date'] | date: "dd MMM YYYY"}})</div>
      <div *ngIf="level2_data['qc_approval_date'] === null"></div>
       </li>



      <li id="border-2" class="nav-item" style="width: 91px;" [ngClass]="{
          'changes2': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved')
        }">
         <a
            class="nav-link mb-3"
            [ngClass]="{
          'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved')
        }"
            id="pills-1-tab"
            data-toggle="pill"
            href="#pills-1"
            role="tab"
            aria-controls="pills-1"
            aria-selected="true"
            style="width: 40px; height: 41px;"
            ><span *ngIf="(boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved'); else elseBlock25"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
              margin-top: -1px;"></span>
            <ng-template #elseBlock25>
              <span>6</span>
            </ng-template></a
          >
          <a class="date_name_style">Final BOQ Shared</a>
         <div class="date_name_style numbers" *ngIf="level2_data['final_boq_shared_date'] !== null">({{level2_data['final_boq_shared_date'] | date: "dd MMM YYYY"}})</div>
      <div *ngIf="level2_data['final_boq_shared_date'] === null"></div>
       </li>


       <li id="border-2" class="nav-item" style="width: 91px;" [ngClass]="{
          'changes2': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared')
        }">
         <a
            class="nav-link mb-3"
            [ngClass]="{
          'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared')
        }"
            id="pills-1-tab"
            data-toggle="pill"
            href="#pills-1"
            role="tab"
            aria-controls="pills-1"
            aria-selected="true"
            style="width: 40px; height: 41px;"
            ><span *ngIf="(boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared'); else elseBlock26"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
              margin-top: -1px;"></span>
            <ng-template #elseBlock26>
              <span>7</span>
            </ng-template></a
          >
          <a class="date_name_style">Final BOQ Approved</a>
         <div class="date_name_style numbers" *ngIf="level2_data['final_boq_approved_date'] !== null">({{level2_data['final_boq_approved_date'] | date: "dd MMM YYYY"}})</div>
      <div *ngIf="level2_data['final_boq_approved_date'] === null"></div>
       </li>
       <li id="border-2" class="nav-item" style="width: 91px;" [ngClass]="{
          'changes2': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved')
        }">
         <a
            class="nav-link mb-3"
            [ngClass]="{
          'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved')
        }"
            id="pills-1-tab"
            data-toggle="pill"
            href="#pills-1"
            role="tab"
            aria-controls="pills-1"
            aria-selected="true"
            style="width: 40px; height: 41px;"
            ><span *ngIf="(boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved'); else elseBlock27"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
              margin-top: -1px;"></span>
            <ng-template #elseBlock27>
              <span>8</span>
            </ng-template></a
          >
          <a class="date_name_style">Sent to Production</a>
         <div class="date_name_style numbers" *ngIf="level2_data['sent_to_production_date'] !== null">({{level2_data['sent_to_production_date'] | date: "dd MMM YYYY"}})</div>
      <div *ngIf="level2_data['sent_to_production_date'] === null"></div>
       </li>
     </ul> 
     </div>  
    </div>
</li>
<!-- level three -->
    <li [ngClass]="{'d-none': !check_3, '': check_3}">
    <input type="radio" id="vert-3" name="vert-accordion" (click)="check3()"/>
    <label for="vert-3" style="position: relative;">
     <span class="mt-4" style="writing-mode: vertical-lr; transform: rotate(-180deg);">&nbsp;&nbsp; Order&nbsp;Execution</span>
     <span class="staic_number">3</span>
      <span [ngClass]="{ 'arrow_right': checked3, '': !checked3}"></span>
    </label>
      <!-- Servie -->
      <div class="content2 mx-3"  [ngClass] = " !paymentSection.level_3_service_hidden  && !paymentSection.level_3_non_service_hidden ? 'apply_height_level_three_display_two'  : 'apply_height_level_three_display_one'" >
    <ng-container *ngIf = "paymentSection && !paymentSection.level_3_service_hidden">
      <div id="applying_styles_steps2">  
        <div style="font-size: 20px; color: #8D0528;" class="d-flex justify-content-center mb-1">Service</div>
     <ul class="nav nav-pills"  id="pills-tab" role="tablist">
       <li id="border-1" class="nav-item" style="width: 91px;" [ngClass]="{
          'changes2': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_service !== 'Sent to Production')
        }">
         <a
            class="nav-link mb-3"
            [ngClass]="{
          'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_service !== 'Sent to Production')
        }"
            id="pills-1-tab"
            data-toggle="pill"
            href="#pills-1"
            role="tab"
            aria-controls="pills-1"
            aria-selected="true"
            style="width: 40px; height: 41px;"
            ><span *ngIf="(boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_service !== 'Sent to Production'); else elseBlock1000"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
              margin-top: -1px;"></span>
            <ng-template #elseBlock1000>
              <span>1</span>
            </ng-template></a
          >
          <a class="date_name_style">Service Start</a>
         <div class="date_name_style numbers" *ngIf="level3_data_service['service_start_date'] !== null">({{level3_data_service['service_start_date'] | date: "dd MMM YYYY"}})</div>
      <div *ngIf="level3_data_service['service_start_date'] === null"></div>
       </li>
       <li id="border-2" class="nav-item" style="width: 91px;" [ngClass]="{
          'changes2': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_service !== 'Sent to Production') && (boq_status_level.boq_status_service !== 'Service Start')
        }">
         <a
            class="nav-link mb-3"
            [ngClass]="{
          'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_service !== 'Sent to Production') && (boq_status_level.boq_status_service !== 'Service Start')
        }"
            id="pills-1-tab"
            data-toggle="pill"
            href="#pills-1"
            role="tab"
            aria-controls="pills-1"
            aria-selected="true"
            style="width: 40px; height: 41px;"
            ><span *ngIf="(boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_service !== 'Sent to Production') && (boq_status_level.boq_status_service !== 'Service Start'); else elseBlock1001"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
              margin-top: -1px;"></span>
            <ng-template #elseBlock1001>
              <span>2</span>
            </ng-template></a
          >
          <a class="date_name_style">Service 50%</a>
         <div class="date_name_style numbers" *ngIf="level3_data_service['service_50_per_date'] !== null">({{level3_data_service['service_50_per_date'] | date: "dd MMM YYYY"}})</div>
      <div *ngIf="level3_data_service['service_50_per_date'] === null"></div>
       </li>
       <li id="border-2" class="nav-item" style="width: 91px;" [ngClass]="{
          'changes2': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_service !== 'Sent to Production') && (boq_status_level.boq_status_service !== 'Service Start') && (boq_status_level.boq_status_service !== 'Service 50%')
        }">
         <a
            class="nav-link mb-3"
            [ngClass]="{
          'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_service !== 'Sent to Production') && (boq_status_level.boq_status_service !== 'Service Start') && (boq_status_level.boq_status_service !== 'Service 50%')
        }"
            id="pills-1-tab"
            data-toggle="pill"
            href="#pills-1"
            role="tab"
            aria-controls="pills-1"
            aria-selected="true"
            style="width: 40px; height: 41px;"
            ><span *ngIf="(boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_service !== 'Sent to Production') && (boq_status_level.boq_status_service !== 'Service Start') && (boq_status_level.boq_status_service !== 'Service 50%'); else elseBlock1003"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
              margin-top: -1px;"></span>
            <ng-template #elseBlock1003>
              <span>3</span>
            </ng-template></a
          >
          <a class="date_name_style">Site Readiness</a>
         <div class="date_name_style numbers" *ngIf="level3_data_service['site_readiness_date'] !== null">({{level3_data_service['site_readiness_date'] | date: "dd MMM YYYY"}})</div>
      <div *ngIf="level3_data_service['site_readiness_date'] === null"></div>
       </li>

       <li id="border-2" class="nav-item" style="width: 91px;" [ngClass]="{
          'changes2': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_service !== 'Sent to Production') && (boq_status_level.boq_status_service !== 'Service Start') && (boq_status_level.boq_status_service !== 'Service 50%') && (boq_status_level.boq_status_service !== 'Site Readiness')
        }">
         <a
            class="nav-link mb-3"
            [ngClass]="{
          'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_service !== 'Sent to Production') && (boq_status_level.boq_status_service !== 'Service Start') && (boq_status_level.boq_status_service !== 'Service 50%') && (boq_status_level.boq_status_service !== 'Site Readiness')
        }"
            id="pills-1-tab"
            data-toggle="pill"
            href="#pills-1"
            role="tab"
            aria-controls="pills-1"
            aria-selected="true"
            style="width: 40px; height: 41px;"
            ><span *ngIf="(boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_service !== 'Sent to Production') && (boq_status_level.boq_status_service !== 'Service Start') && (boq_status_level.boq_status_service !== 'Service 50%') && (boq_status_level.boq_status_service !== 'Site Readiness'); else elseBlock1004"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
              margin-top: -1px;"></span>
            <ng-template #elseBlock1004>
              <span>4</span>
            </ng-template></a
          >
          <a class="date_name_style mx-0">Handover</a>
         <div class="date_name_style numbers" *ngIf="level3_data_service['handover_date'] !== null">({{level3_data_service['handover_date'] | date: "dd MMM YYYY"}})</div>
      <div *ngIf="level3_data_service['handover_date'] === null"></div>
       </li>
      
     </ul> 
      </div>  
    </ng-container>
    
    <!-- furniture -->
    <ng-container *ngIf = "paymentSection && !paymentSection.level_3_non_service_hidden">
     <div id="applying_styles_steps26">
      <div style="font-size: 20px; color: #8D0528;" class="d-flex justify-content-center mt-2 mb-1">Furniture</div>
     <ul class="nav nav-pills"  id="pills-tab" role="tablist">

       <li id="border-1" class="nav-item" style="width: 91px;" [ngClass]="{
          'changes2': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved')  && (boq_status_level.boq_status_non_service !== 'Sent to Production')
        }">
         <a
            class="nav-link mb-3"
            [ngClass]="{
          'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_non_service !== 'Sent to Production')
        }"
            id="pills-1-tab"
            data-toggle="pill"
            href="#pills-1"
            role="tab"
            aria-controls="pills-1"
            aria-selected="true"
            style="width: 40px; height: 41px;"
            ><span *ngIf="(boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved')  && (boq_status_level.boq_status_non_service !== 'Sent to Production') ; else elseBlock1005"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
              margin-top: -1px;"></span>
            <ng-template #elseBlock1005>
              <span>1</span>
            </ng-template></a
          >
          <a class="date_name_style" style="margin-left: -18px !important;">
            Raw Material Received
          </a>
         <div class="date_name_style numbers" *ngIf="level3_data_nonservice['raw_material_received_date'] !== null">({{level3_data_nonservice['raw_material_received_date'] | date: "dd MMM YYYY"}})</div>
      <div *ngIf="level3_data_nonservice['raw_material_received_date'] === null"></div>
       </li>

       <li id="border-1" class="nav-item" style="width: 91px;" [ngClass]="{
          'changes2': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approval') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved')  && (boq_status_level.boq_status_non_service !== 'Raw Material Recieved') && (boq_status_level.boq_status_non_service !== 'Sent to Production')
        }">
         <a
            class="nav-link mb-3"
            [ngClass]="{
          'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_non_service !== 'Raw Material Recieved') && (boq_status_level.boq_status_non_service !== 'Sent to Production')
        }"
            id="pills-1-tab"
            data-toggle="pill"
            href="#pills-1"
            role="tab"
            aria-controls="pills-1"
            aria-selected="true"
            style="width: 40px; height: 41px;"
            ><span *ngIf="(boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_non_service !== 'Raw Material Recieved') && (boq_status_level.boq_status_non_service !== 'Sent to Production')  ; else elseBlock1006"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
              margin-top: -1px;"></span>
            <ng-template #elseBlock1006>
              <span>2</span>
            </ng-template></a
          >
          <a class="date_name_style">Furniture Prod. Start</a>
         <div class="date_name_style numbers" *ngIf="level3_data_nonservice['furniture_production_start_date'] !== null">({{level3_data_nonservice['furniture_production_start_date'] | date: "dd MMM YYYY"}})</div>
      <div *ngIf="level3_data_nonservice['furniture_production_start_date'] === null"></div>
       </li>



       <li id="border-1" class="nav-item" style="width: 91px;" [ngClass]="{
          'changes2': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_non_service !== 'Raw Material Recieved') && (boq_status_level.boq_status_non_service !== 'Furniture Production Start') && (boq_status_level.boq_status_non_service !== 'Sent to Production')}"
          >
         <a
            class="nav-link mb-3"
            [ngClass]="{
          'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved')  && (boq_status_level.boq_status_non_service !== 'Raw Material Recieved') && (boq_status_level.boq_status_non_service !== 'Furniture Production Start') && (boq_status_level.boq_status_non_service !== 'Sent to Production')
        }"
            id="pills-1-tab"
            data-toggle="pill"
            href="#pills-1"
            role="tab"
            aria-controls="pills-1"
            aria-selected="true"
            style="width: 40px; height: 41px;"
            ><span *ngIf="(boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_non_service !== 'Raw Material Recieved') && (boq_status_level.boq_status_non_service !== 'Furniture Production Start') && (boq_status_level.boq_status_non_service !== 'Sent to Production'); else elseBlock1007"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
              margin-top: -1px;"></span>
            <ng-template #elseBlock1007>
              <span>3</span>
            </ng-template></a
          >
          <a class="date_name_style">Furniture Dispatch</a>
         <div class="date_name_style numbers" *ngIf="level3_data_nonservice['furniture_dispatch_date'] !== null">({{level3_data_nonservice['furniture_dispatch_date'] | date: "dd MMM YYYY"}})</div>
      <div *ngIf="level3_data_nonservice['furniture_dispatch_date'] === null"></div>
       </li>


       <li id="border-1" class="nav-item" style="width: 91px;" [ngClass]="{
          'changes2': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_non_service !== 'Raw Material Recieved') && (boq_status_level.boq_status_non_service !== 'Furniture Production Start') && (boq_status_level.boq_status_non_service !== 'Furniture Dispatch')  && (boq_status_level.boq_status_non_service !== 'Sent to Production') }">
         <a
            class="nav-link mb-3"
            [ngClass]="{
          'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved')  && (boq_status_level.boq_status_non_service !== 'Raw Material Recieved') && (boq_status_level.boq_status_non_service !== 'Furniture Production Start') && (boq_status_level.boq_status_non_service !== 'Furniture Dispatch') && (boq_status_level.boq_status_non_service !== 'Sent to Production')
        }"
            id="pills-1-tab"
            data-toggle="pill"
            href="#pills-1"
            role="tab"
            aria-controls="pills-1"
            aria-selected="true"
            style="width: 40px; height: 41px;"
            ><span *ngIf="(boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved')  && (boq_status_level.boq_status_non_service !== 'Raw Material Recieved') && (boq_status_level.boq_status_non_service !== 'Furniture Production Start') && (boq_status_level.boq_status_non_service !== 'Furniture Dispatch') && (boq_status_level.boq_status_non_service !== 'Sent to Production') ; else elseBlock1008"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
              margin-top: -1px;"></span>
            <ng-template #elseBlock1008>
              <span>4</span>
            </ng-template></a
          >
          <a class="date_name_style mx-0">Installation</a>
         <div class="date_name_style numbers" *ngIf="level3_data_nonservice['installation_date'] !== null">({{level3_data_nonservice['installation_date'] | date: "dd MMM YYYY"}})</div>
      <div *ngIf="level3_data_nonservice['installation_date'] === null"></div>
       </li>


       <li id="border-1" class="nav-item" style="width: 91px;" [ngClass]="{
          'changes2': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_non_service !== 'Raw Material Recieved') && (boq_status_level.boq_status_non_service !== 'Furniture Production Start') && (boq_status_level.boq_status_non_service !== 'Furniture Dispatch') && (boq_status_level.boq_status_non_service !== 'Installation') && (boq_status_level.boq_status_non_service !== 'Sent to Production') }">
         <a
            class="nav-link mb-3"
            [ngClass]="{
          'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_non_service !== 'Raw Material Recieved') && (boq_status_level.boq_status_non_service !== 'Furniture Production Start') && (boq_status_level.boq_status_non_service !== 'Furniture Dispatch') && (boq_status_level.boq_status_non_service !== 'Installation') && (boq_status_level.boq_status_non_service !== 'Sent to Production')
        }"
            id="pills-1-tab"
            data-toggle="pill"
            href="#pills-1"
            role="tab"
            aria-controls="pills-1"
            aria-selected="true"
            style="width: 40px; height: 41px;"
            ><span *ngIf="(boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved')  && (boq_status_level.boq_status_non_service !== 'Raw Material Recieved') && (boq_status_level.boq_status_non_service !== 'Furniture Production Start') && (boq_status_level.boq_status_non_service !== 'Furniture Dispatch') && (boq_status_level.boq_status_non_service !== 'Installation') && (boq_status_level.boq_status_non_service !== 'Sent to Production'); else elseBlock1009"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
              margin-top: -1px;"></span>
            <ng-template #elseBlock1009>
              <span>5</span>
            </ng-template></a
          >
          <a class="date_name_style mx-0">Handover</a>
         <div class="date_name_style numbers" *ngIf="level3_data_nonservice['handover_date'] !== null">({{level3_data_nonservice['handover_date'] | date: "dd MMM YYYY"}})</div>
      <div *ngIf="level3_data_nonservice['handover_date'] === null"></div>
       </li>

     </ul> 
     </div>
    </ng-container>
    </div>
</li>
       </ul> 
    </div>
  </div>

  <!-- mobile view -->

  <div  class="container d-lg-none d-block" [ngClass]="{'d-block':  boq_status_level.boq_timeline_level === 'level_2' ||  boq_status_level.boq_timeline_level === 'level_3', 'd-none' : boq_status_level.boq_timeline_level === 'level_1'  }">
    <div class="accordion" id="accordionSection" [ngClass]="{'d-none':  boq_status_level.boq_timeline_level === 'level_1' && boq_status_level.boq_status_service === 'Pending'}">
      <ng-container *ngIf ="boq_status_level.boq_timeline_level === 'level_1'  || boq_status_level.boq_timeline_level === 'level_2' || boq_status_level.boq_timeline_level === 'level_3'">
        <div class="row mx-0 py-2 br_5 order-component">
          <div
            data-toggle="collapse"
            href="#orderBooking"
            role="button"
            aria-expanded="false"
            aria-controls="orderBooking"
            class="col-lg-12 d-flex justify-content-center py-1"
            data-toggle="collapse"
          >
            <h4 class="mb-0">Order Booking</h4>
            <button class="accordionArrow">
              <img src="../../assets/images/icons/down-arrow-order.png" />
            </button>
          </div>
        </div>
        <div
          class="collapse"
          id="orderBooking"
          data-parent="#accordionSection"
          [ngClass]="{
            show:
              orderBookingDetails.initial_boq_shared &&
              !paymentStatusDetails.per_10_payment
          }"
        >
          <div
            class="row justify-content-center align-items-center flex-column mb-5"
          >
            <div class="col-12 col-md-6">
              <div class="root-navmenu">
                <div class="navmenu">
                  <ul>
                    <li class="active">
                      <!-- active class is implemented on div -->
                      <div
                        class="mb-5"
                        [ngClass]="{
                          'active': (boq_status_level.boq_status_service === 'Initial BOQ Shared')  || (boq_status_level.boq_status_service === 'Approved/Rejected') || (boq_status_level.boq_timeline_level === 'level_2') || (boq_status_level.boq_timeline_level === 'level_3') || (boq_status_level.boq_status_service === '10% Paid')
                        }"
                      >
                        <p class="navmenu-detail mb-0">Estimate Shared</p>
                        <p style="font-size: 12px;" *ngIf="level1_data.shared_date !== null">({{(level1_data.shared_date | date: "dd-MM-YYYY")}})</p>
                        <p style="font-size: 12px;" *ngIf="level1_data.shared_date === null"></p>
                      </div>
                    </li>
                    <li class="active">
                      <div
                        class="mb-5"
                        [ngClass]="{
                          'active': (boq_status_level.boq_status_service === 'Approved/Rejected') || (boq_status_level.boq_status_service === '10% Paid')  || (boq_status_level.boq_timeline_level === 'level_2') || (boq_status_level.boq_timeline_level === 'level_3')
                        }"
                      >
                        <p class="navmenu-detail mb-0">
                          Estimate Approved
                        </p>
                          <p style="font-size: 12px;" *ngIf="level1_data.approved_date !== null">({{(level1_data.approved_date | date: "dd-MM-YYYY")}})</p>
                          <p style="font-size: 12px;" *ngIf="level1_data.approved_date === null"></p>

                      </div>
                    </li>
                    <li>
                      <div
                        [ngClass]="{
                          'active':  (boq_status_level.boq_status_service === '10% Paid')  || (boq_status_level.boq_timeline_level === 'level_2') || (boq_status_level.boq_timeline_level === 'level_3')
                        }"
                      >
                        <p class="navmenu-detail mb-0">
                          <span class="numbers">10%</span> Paid
                        </p>
                        <p style="font-size: 12px;" *ngIf="level1_data['10_per_paid_date'] !== null">({{(level1_data['10_per_paid_date'] | date: "dd-MM-YYYY")}})</p>
                        <p style="font-size: 12px; margin-bottom: 1.4rem;" *ngIf="level1_data['10_per_paid_date'] === null"></p>
                      </div>
                    </li>
                    <div
                      class="orderBookingActiveLine"
                      [ngClass]="{
                        'progress50':boq_status_level.boq_status_service === 'Approved/Rejected',
                        'progress100': (boq_status_level.boq_status_service === '10% Paid') || (boq_status_level.boq_timeline_level === 'level_2') || (boq_status_level.boq_timeline_level === 'level_3')
                      }"
                    ></div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <!-- level two -->
      <ng-container *ngIf ="boq_status_level.boq_timeline_level === 'level_2' || boq_status_level.boq_timeline_level === 'level_3' ">
        <div class="row mx-0 py-2 br_5 order-component">
          <div
            data-toggle="collapse"
            href="#orderBookingLevelTwo"
            role="button"
            aria-expanded="false"
            aria-controls="orderBooking"
            class="col-lg-12 d-flex justify-content-center py-1"
            data-toggle="collapse"
          >
            <h4 class="mb-0">Order Conformation</h4>
            <button class="accordionArrow">
              <img src="../../assets/images/icons/down-arrow-order.png" />
            </button>
          </div>
        </div>
        <div
          class="collapse"
          id="orderBookingLevelTwo"
          data-parent="#accordionSection"
          [ngClass]="{
            show:
              orderBookingDetails.initial_boq_shared &&
              !paymentStatusDetails.per_10_payment
          }"
        >
          <div
            class="row justify-content-center align-items-center flex-column mb-5"
          >
            <div class="col-12 col-md-6">
              <div class="root-navmenu  orderCOnformationLevelTwo">
                <div class="navmenu">
                  <ul>
                    <li class="active">
                      <!-- active class is implemented on div -->
                      <div
                        [ngClass]="{
                          'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid')
                        }"
                      >
                        <p class="navmenu-detail mb-0">
                          Site Measurement 
                        </p>
                        <p style="font-size: 12px;" *ngIf="level2_data.site_measurement_date !== null">({{(level2_data.site_measurement_date | date: "dd-MM-YYYY")}})</p>
                      </div>
                    </li>
                    <li class="active">
                    
                      <div
                        [ngClass]="{
                          'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== 'Site Measurement')
                        }"
                      >
                        <p class="navmenu-detail mb-0">
                          Design Iteration
                        </p>
                        <p style="font-size: 12px;" *ngIf="level2_data.design_iteration_meeting !== null">({{(level2_data.design_iteration_meeting_data  | date: "dd-MM-YYYY")}})</p>
                      </div>
                    </li>
                    <li class="active">
                      <div
                        [ngClass]="{
                          'active': (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== 'Site Measurement') 
                        }"
                      >
                        <p class="navmenu-detail mb-0">
                          Material Selection 
                        </p>
                        <p style="font-size: 12px;" *ngIf="level2_data.material_selection_date !== null">({{(level2_data.material_selection_date | date: "dd-MM-YYYY")}})</p>
                      </div>
                    </li>
                    <li>
                      <div
                        [ngClass]="{
                          'active': (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection')
                        }"
                      >
                        <p class="navmenu-detail mb-0">
                          Site Validation
                        </p>
                        <p style="font-size: 12px;" *ngIf="level2_data.Site_validation_date !== null">({{(level2_data.Site_validation_date | date: "dd-MM-YYYY")}})</p>
                      </div>
                    </li>

                    <li>
                      <div
                        [ngClass]="{
                          'active': (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') 
                        }"
                      >
                        <p class="navmenu-detail mb-0">
                          QC Approved
                        </p>
                        <p style="font-size: 12px;" *ngIf="level2_data.qc_approval_date !== null">({{(level2_data.qc_approval_date | date: "dd-MM-YYYY")}})</p>
                      </div>
                    </li>

                    <li>
                      <div
                      [ngClass]="{
                        'active': (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') 
                      }"
                      >
                        <p class="navmenu-detail mb-0">
                         
                          BOQ Shared
                        </p>
                        
                        <p style="font-size: 12px;" *ngIf="level2_data.final_boq_shared_date !== null">({{(level2_data.final_boq_shared_date | date: "dd-MM-YYYY")}})</p>
                      </div>
                    </li>

                    <li>
                      <div
                      [ngClass]="{
                        'active': (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') 
                      }"
                      >
                        <p class="navmenu-detail mb-0">
                         
                          BOQ Approved
                        </p>
                        <p style="font-size: 12px;" *ngIf="level2_data.final_boq_approved_date !== null">({{(level2_data.final_boq_approved_date | date: "dd-MM-YYYY")}})</p>
                      </div>
                    </li>

                    <li>
                      <div
                      [ngClass]="{
                        'active': (boq_status_level.boq_status_service !== 'Design Iteration Meeting') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared')   && (boq_status_level.boq_status_service !== 'Final BOQ Approved')
                      }"
                      >
                        <p class="navmenu-detail mb-0">
                          Sent to Production
                        </p>
                        <p style="font-size: 12px;" *ngIf="level2_data.sent_to_production_date !== null">({{(level2_data.sent_to_production_date | date: "dd-MM-YYYY")}})</p>
                      </div>
                    </li>
                    <div
                      class="orderBookingActiveLine"
                      [ngClass]="{
                        'progressmaterial':boq_status_level.boq_status_service === 'Material Selection',
                        'progressDesigneriter':boq_status_level.boq_status_service === 'Design Iteration Meeting',
                        'progressSiteValidation':boq_status_level.boq_status_service === 'Site Validation',
                        'progressQcApproved':boq_status_level.boq_status_service === 'QC Approved',
                        'progressBoqshared':boq_status_level.boq_status_service === 'Final BOQ Shared',
                        'progressBoqApproved':boq_status_level.boq_status_service === 'Final BOQ Approved',
                        'progressSentToProduction': (boq_status_level.boq_status_service === 'Sent to Production')  || (boq_status_level.boq_timeline_level === 'level_3')
                      }"
                    ></div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>



      <!-- level three service , non service -->

      <ng-container *ngIf ="(boq_status_level.boq_timeline_level === 'level_2' && boq_status_level.boq_status_service ==='Sent to Production') || boq_status_level.boq_timeline_level === 'level_3' ">
        <div class="row mx-0 py-2 br_5 order-component">
          <div
            data-toggle="collapse"
            href="#level-three"
            role="button"
            aria-expanded="false"
            aria-controls="orderBooking"
            class="col-lg-12 d-flex justify-content-center py-1"
            data-toggle="collapse"
          >
            <h4 class="mb-0">Order Execution</h4>
            <button class="accordionArrow">
              <img src="../../assets/images/icons/down-arrow-order.png" />
            </button>
          </div>
        </div>
        <div
          class="collapse"
          id="level-three"
          data-parent="#accordionSection"
          [ngClass]="{
            show:
              orderBookingDetails.initial_boq_shared &&
              !paymentStatusDetails.per_10_payment
          }"
        >
          <div class="row justify-content-center align-items-center flex-column mb-5">
              <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                  <div  *ngIf ="!paymentSection.level_3_non_service_hidden"  class="carousel-item active  col-12">
                    <div class="root-navmenu  orderCOnformationLevelThreee-furniture">
                      <p class = 'colorBurguntee' style="border-bottom: 1px solid #8d0528;" >Furniture</p>
                      <div class="navmenu">
                        <ul>
                          <li class="active">
                            <!-- active class is implemented on div -->
                            <div
                              [ngClass]="{
                                'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved'),
                                'inactive' : boq_status_level.boq_status_non_service === 'Sent to Production'
                              }"
                            >
                              <p class="navmenu-detail mb-0">
                                Raw Material Received
                              </p>
                              <p style="font-size: 12px;" *ngIf="level3_data_nonservice.raw_material_received_date !== null">({{(level3_data_nonservice.raw_material_received_date | date: "dd-MM-YYYY")}})</p>
                            </div>
                          </li>
                          <li class="active">
                            <div
                              [ngClass]="{
                                'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approval') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved')  && (boq_status_level.boq_status_non_service !== 'Raw Material Recieved'),
                                'inactive' : boq_status_level.boq_status_non_service === 'Sent to Production'
                              }"
                            >
                              <p class="navmenu-detail mb-0">
                                Furniture Production Start
                              </p>
                              <p style="font-size: 12px;" *ngIf="level3_data_nonservice.furniture_production_start_date !== null">({{(level3_data_nonservice.furniture_production_start_date | date: "dd-MM-YYYY")}})</p>
                            </div>
                          </li>
                          <li>
                            <div
                              [ngClass]="{
                                'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_non_service !== 'Raw Material Recieved') && (boq_status_level.boq_status_non_service !== 'Furniture Production Start'),
                                'inactive' : boq_status_level.boq_status_non_service === 'Sent to Production'
                              }"
                            >
                              <p class="navmenu-detail mb-0">
                                Furniture Dispatch
                              </p>
                              <p style="font-size: 12px;" *ngIf="level3_data_nonservice.furniture_dispatch_date !== null">({{(level3_data_nonservice.furniture_dispatch_date | date: "dd-MM-YYYY")}})</p>
                              <p style="font-size: 12px;" *ngIf="level3_data_nonservice.furniture_dispatch_date === null"></p>
                            </div>
                          </li>

                          <li>
                            <div
                              [ngClass]="{
                                'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved')  && (boq_status_level.boq_status_non_service !== 'Raw Material Recieved') && (boq_status_level.boq_status_non_service !== 'Furniture Production Start') && (boq_status_level.boq_status_non_service !== 'Furniture Dispatch'),
                                'inactive' : boq_status_level.boq_status_non_service === 'Sent to Production'
                              }"
                            >
                              <p class="navmenu-detail mb-0">
                                Installation
                              </p>
                                <p style="font-size: 12px;" *ngIf="level3_data_nonservice.installation_date !== null">({{(level3_data_nonservice.installation_date | date: "dd-MM-YYYY")}})</p>
                                <p style="font-size: 12px;" *ngIf="level3_data_nonservice.installation_date === null"></p>
                            </div>
                          </li>

                          <li>
                            <div
                            [ngClass]="{
                              'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_non_service !== 'Raw Material Recieved') && (boq_status_level.boq_status_non_service !== 'Furniture Production Start') && (boq_status_level.boq_status_non_service !== 'Furniture Dispatch') && (boq_status_level.boq_status_non_service !== 'Installation'),
                              'inactive' : boq_status_level.boq_status_non_service === 'Sent to Production'
                            }"
                            >
                              <p class="navmenu-detail mb-0">
                                Handover
                              </p>
                              <p style="font-size: 12px;" *ngIf="level3_data_nonservice.handover_date !== null">({{(level3_data_nonservice.handover_date | date: "dd-MM-YYYY")}})</p>
                            </div>
                          </li>                          
                          <div
                            class="orderBookingActiveLine"
                            [ngClass]="{
                              'progressprodStart':boq_status_level.boq_status_non_service === 'Furniture Production Start',
                              'progressdispatch':boq_status_level.boq_status_non_service === 'Furniture Dispatch',
                              'progressinstallation':boq_status_level.boq_status_non_service === 'Installation',
                              'progressHandover': (boq_status_level.boq_status_non_service === 'Handover')
                            }"
                          >
                        </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div *ngIf ="!paymentSection.level_3_service_hidden" class="carousel-item col-12">
                    <div class="root-navmenu  orderCOnformationLevelThreee-Service">
                      <p class = 'colorBurguntee' style="border-bottom: 1px solid #8d0528;" >Service</p>
                      <div class="navmenu">
                        <ul>
                          <li class="active">
                            <!-- active class is implemented on div -->
                            <div
                              [ngClass]="{
                                'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_service !== 'Sent to Production')
                              }"
                            >
                              <p class="navmenu-detail mb-0">
                                Service Start
                              </p>
                              <p style="font-size: 12px;" *ngIf="level3_data_service.service_start_date !== null">({{(level3_data_service.service_start_date | date: "dd-MM-YYYY")}})</p>
                            </div>
                          </li>
                          <li class="active">
                            <div
                              [ngClass]="{
                                'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_service !== 'Sent to Production') && (boq_status_level.boq_status_service !== 'Service Start')
                              }"
                            >
                              <p class="navmenu-detail mb-0">
                                Service 50%
                              </p>
                              <p style="font-size: 12px;" *ngIf="level3_data_service.service_50_per_date !== null">({{(level3_data_service.service_50_per_date | date: "dd-MM-YYYY")}})</p>
                            </div>
                          </li>
                          <li>
                            <div
                              [ngClass]="{
                                'active':  (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_service !== 'Sent to Production') && (boq_status_level.boq_status_service !== 'Service Start') && (boq_status_level.boq_status_service !== 'Service 50%')
                              }"
                            >
                              <p class="navmenu-detail mb-0">
                                Site Readiness
                              </p>
                              <p style="font-size: 12px;" *ngIf="level3_data_service.site_readiness_date !== null">({{(level3_data_service.site_readiness_date | date: "dd-MM-YYYY")}})</p>
                            </div>
                          </li>

                          <li>
                            <div
                              [ngClass]="{
                                'active':  (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_service !== 'Sent to Production') && (boq_status_level.boq_status_service !== 'Service Start') && (boq_status_level.boq_status_service !== 'Service 50%') && (boq_status_level.boq_status_service !== 'Site Readiness')
                              }"
                            >
                              <p class="navmenu-detail mb-0">
                                Handover
                              </p>
                              <p style="font-size: 12px;" *ngIf="level3_data_service.handover_date !== null">({{(level3_data_service.handover_date | date: "dd-MM-YYYY")}})</p>
                            </div>
                          </li>            
                          <div
                            class="orderBookingActiveLine"
                            [ngClass]="{
                              'servicefifty':boq_status_level.boq_status_service === 'Service 50%',
                              'serviceSiteRedy':boq_status_level.boq_status_service === 'Site Readiness',
                              'serviceHandover':boq_status_level.boq_status_service === 'Handover'
                            }"
                          >
                        </div>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <ng-container *ngIf =" !paymentSection.level_3_service_hidden  && !paymentSection.level_3_non_service_hidden">
                    <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </ng-container>
                  </div>
              </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>





          <!-- project summarry -->
          <div>
            <div class="mx-lg-3 mx-3">
              <div class="row">
                <div class="col-lg-7 mb-3 mb-lg-0" *ngIf ="customerTimeline">
                  <div class="customerDetails h-100">
                    <div class="project px-3 d-flex align-items-end pb-1 pt-4">
                      <div class="project_directory">
                        <p class="mb-0">Project Summary</p>
                      </div>
                    </div>
                    <div class="projectDetails pt-4 px-4 pb-2">
                      <div class="row">
                          <div class="col-lg-12">
                            <div class="row">
                              <div class="col-lg-3 col-5 d-flex justify-content-between">
                                <div>
                                  <p class=" apply-font-mb">Lead ID</p>
                                  <p class="apply-mobile-sm numbers" >{{leadId}}</p>
                                </div>
                                <div class="d-lg-block d-none" style="border-left: 1px solid #cccc; height: 3rem; margin-right: 0.5rem;"></div>
                              </div>
                              <div class="col-lg-4 col-7 d-flex justify-content-lg-between justify-content-end">
                                <div>
                                  <p class=" apply-font-mb" style="white-space: nowrap;">Customer Phone Number</p>
                                  <p class="apply-mobile-sm numbers">{{projectDirectory.customer_mobile_number}}</p>
                                </div>
                                <div class="d-lg-block d-none" style="border-left: 1px solid #cccc; height: 3rem; margin-right: 1.5rem;"></div>
                              </div>
                              <div class="col-lg-5 col-12">
                                <p class="id-lead  apply-font-mb">Customer E-Mail</p>
                                <p class="lead-num apply-mobile-sm"> {{projectDirectory.customer_email}}</p>
                              </div>
                            </div>
                          </div>
                         <div class="col-lg-12">
                          <div class="row">
                            <div class="col-lg-3 col-6 mb-3 mb-lg-0 d-flex justify-content-between">
                              <div>
                                <p class=" apply-font-mb">MKW / Full Home</p>
                                <span class="type-of-fur apply-mobile-sm text-capitalize"  *ngFor="let type of mkw_full_home; last as isLast">{{type.replace('_', ' ')}}{{isLast?'':', '}}</span>
                                <span *ngIf ="mkw_full_home === null || mkw_full_home.length === 0  ">---</span>
                              </div>
                              <div class="d-lg-block d-none" style="border-left: 1px solid #cccc; height: 3rem; margin-right: 0.5rem;"></div>
                            </div>
                            <div class="col-lg-4 col-6 d-flex justify-content-between">
                              <div>
                                <p class=" apply-font-mb" style="white-space: nowrap;">Area</p>
                                <span class="apply-mobile-sm numbers"> {{ projectDirectory.area ? (projectDirectory.area) : "---"  }}</span> <span class="apply-mobile-sm" *ngIf="projectDirectory.area">Sq. Ft.</span> 
                              </div>
                              <div class="d-lg-block d-none" style="border-left: 1px solid #cccc; height: 3rem; margin-right: 1.5rem;"></div>
                            </div>
      
                            <div class="col-lg-5 col-6">
                              <p class="id-lead  apply-font-mb">Number Of Rooms</p>
                              <span class="lead-num apply-mobile-sm numbers">{{projectDirectory.no_of_rooms ? (projectDirectory.no_of_rooms) : "---" }}</span>
                            </div>

                            <div class="col-lg-3 col-6 d-flex justify-content-between mt-lg-4">
                              <div>
                                <p class=" apply-font-mb">Order Book Date</p>
                                <p class="pb-3 apply-mobile-sm">
                                  {{
                                    customerTimeline.timeline["Order Details"]
                                      .order_book_date
                                      ? (customerTimeline.timeline["Order Details"]
                                          .order_book_date | date: "dd MMMM yyyy")
                                      : "---"
                                  }}
                                </p>
                              </div>
                              <div class="d-lg-block d-none" style="border-left: 1px solid #cccc; height: 3rem; margin-right: 0.5rem;"></div>
                            </div>
                            <div class="col-lg-4 col-6 d-flex justify-content-between mt-lg-4">
                              <div>
                                <p class=" apply-font-mb" style="white-space: nowrap;">Order Confirmation Date</p>
                                <p class="pb-3 apply-mobile-sm">
                                  {{
                                    customerTimeline.timeline["Order Details"]
                                      .order_confirmation_date
                                      ? (customerTimeline.timeline["Order Details"]
                                          .order_confirmation_date
                                          | date: "dd MMMM yyyy" )
                                      : "---"
                                  }}
                                </p>
                              </div>
                              <div class="d-lg-block d-none" style="border-left: 1px solid #cccc; height: 3rem; margin-right: 1.5rem;"></div>
                            </div>
        
                            <div class="col-lg-5 col-6 mt-lg-4">
                              <p class="id-lead  apply-font-mb">Total Price</p>
                              <p class="lead-num apply-mobile-sm numbers">
                                {{
                                customerTimeline.timeline.total_price
                                
                                  ? (customerTimeline.timeline.total_price | currency :'INR':'symbol':'1.0-0')
                                  : "---"
                                }}
                              </p>                         
                            </div>
                          </div>
                         </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-5 events-button">
                  <div class="customerDetails h-100">
                    <div class="project px-3 d-flex align-items-end pb-1 pt-4">
                      <div class="project_directory">
                        <p class="mb-0">Event</p>
                      </div>
                    </div>
                    <div class="mx-2 py-4">
                      <button (click)="eventTypes('upcoming')" [ngClass] = "{'active-button' : eventString == 'upcoming'  }" class="upcoming-button px-lg-5 px-3 py-lg-2">Upcoming</button>
                      <button (click)="eventTypes('older')" [ngClass] = "{'active-button' : eventString == 'older'  }"  class="older-button px-lg-5 py-lg-2 px-4 ">Older</button>
                    </div>
                    <div class="mx-4 upcommingBtnClass" *ngIf ="eventString == 'upcoming'  &&  user_events">
                        <div class="row pb-3" *ngFor ="let data of upcoming_events">
                          <div class="col-lg-12">
                              <div class="row">
                                <div class="eventdate col-1">
                                  {{data.scheduled_at |   date: "dd MMM" }}
                                </div>
            
                                <div class="col-lg-11 col-10">
                                  <div class="row">
                                    <div class="col-lg-6 col-8 event-mobile-fortag">
                                      <p style="text-transform: capitalize ; font-size: 14px;" class="mb-0">
                                        {{ data.agenda | replaceChar }}
                                      </p>
                                      <p style="white-space: nowrap;">
                                        Meeting scheduled at
                                        {{ data.scheduled_at | date: "shortTime":"h:mm a" }}
                                      </p>
                                    </div>
                                    <div class="col-lg-6 col-4 d-flex justify-content-lg-end align-self-baseline">
                                      <button (click) ="openUpdateModal(
                                        closeUpdateModal,
                                        'Design & BOQ Presentation Meeting',
                                        detailsForTheEvents.design_boq_presentation_meeting_date
                                        .date,
                                      detailsForTheEvents.design_boq_presentation_meeting_date
                                        .event_id,
                                      detailsForTheEvents.design_boq_presentation_meeting_date
                                        .event_location,
                                      detailsForTheEvents.design_boq_presentation_meeting_date
                                        .event_contact_type,
                                      detailsForTheEvents.design_boq_presentation_meeting_date
                                        .event_emails,
                                      detailsForTheEvents.design_boq_presentation_meeting_date
                                        .event_description,
                                      detailsForTheEvents.design_boq_presentation_meeting_date
                                        .event_remark)" *ngIf ="data.completed === false &&  data.agenda === 'design_and_boq_presentation' " class="btn-primary border-0">
                                        Reschedule 
                                      </button>
              
                                       <button (click) ="openUpdateModal(
                                        closeUpdateModal,
                                        'Follow Up Meeting', 
                                        detailsForTheEvents.follow_up_date.date, 
                                        detailsForTheEvents.follow_up_date.event_id,
                                        detailsForTheEvents.follow_up_date.event_location,
                                        detailsForTheEvents.follow_up_date.event_contact_type,
                                        detailsForTheEvents.follow_up_date.event_emails,
                                        detailsForTheEvents.follow_up_date.event_description,
                                        detailsForTheEvents.follow_up_date.event_remark
                                       )" *ngIf ="!data.completed &&  data.agenda === 'follow_up_meeting'" class="btn-primary border-0">
                                        Reschedule 
                                      </button>
          
                                      <button (click) ="openUpdateModal(
                                        closeUpdateModal,
                                        'Follow Up Call', 
                                        detailsForTheEvents.follow_up_call_date.date, 
                                        detailsForTheEvents.follow_up_call_date.event_id,
                                        detailsForTheEvents.follow_up_call_date.event_location,
                                        detailsForTheEvents.follow_up_call_date.event_contact_type,
                                        detailsForTheEvents.follow_up_call_date.event_emails,
                                        detailsForTheEvents.follow_up_call_date.event_description,
                                        detailsForTheEvents.follow_up_call_date.event_remark
                                       )" *ngIf ="!data.completed &&  data.agenda === 'follow_up_call'" class="btn-primary border-0">
                                        Reschedule 
                                      </button>
              
                                      <button  (click) = "openUpdateModal(
                                        closeUpdateModal,
                                        'Closure Meeting',
                                        detailsForTheEvents.closure_date.date, 
                                        detailsForTheEvents.closure_date.event_id,
                                        detailsForTheEvents.closure_date.event_location,
                                        detailsForTheEvents.closure_date.event_contact_type,
                                        detailsForTheEvents.closure_date.event_emails,
                                        detailsForTheEvents.closure_date.event_description,
                                        detailsForTheEvents.closure_date.event_remark
                                      )"   *ngIf ="data.completed === false &&  data.agenda === 'closure' " class="btn-primary border-0">
                                        Reschedule 
                                      </button> 
                                      <button (click) ="openUpdateModal(
                                        closeUpdateModal,
                                        'First Meeting',
                                        detailsForTheEvents.first_meeting_date.date,
                                        detailsForTheEvents.first_meeting_date.event_id,
                                        detailsForTheEvents.first_meeting_date.event_location,
                                        detailsForTheEvents.first_meeting_date
                                          .event_contact_type,
                                        detailsForTheEvents.first_meeting_date.event_emails,
                                        detailsForTheEvents.first_meeting_date
                                          .event_description,
                                        detailsForTheEvents.first_meeting_date.event_remark
                                      )" *ngIf ="data.completed === false &&  data.agenda === 'first_meeting'" class="btn-primary border-0">
                                        Reschedule 
                                      </button>
                                      <button (click) ="openUpdateModal(
                                        closeUpdateModal,
                                        'Site Measurement Request',
                                              detailsForTheEvents.site_measurement_request_date.actual
                                                ? detailsForTheEvents.site_measurement_request_date
                                                    .actual
                                                : detailsForTheEvents.site_measurement_request_date
                                                    .expected,
                                              '',
                                              '',
                                              '',
                                              '',
                                              '',
                                              ''
                                      )" *ngIf ="data.completed === false &&  data.agenda === 'post_bid_site_measurement_request'" class="btn-primary border-0">
                                        Reschedule 
                                      </button>
              
              
                                      <button (click) ="openUpdateModal(
                                        closeUpdateModal,
                                        'Site Validation Request',
                                              detailsForTheEvents.site_validation_request_date.actual
                                                ? detailsForTheEvents.site_validation_request_date
                                                    .actual
                                                : detailsForTheEvents.site_validation_request_date
                                                    .expected,
                                              '',
                                              '',
                                              '',
                                              '',
                                              '',
                                              ''
                                      )" *ngIf ="data.completed === false &&  data.agenda === 'site_validation' " class="btn-primary border-0" >
                                        Reschedule 
                                      </button>
              
                                      <button (click) ="openUpdateModal(
                                        closeUpdateModal,
                                        'Material Finalisation Meeting',
                                        materialFinalisation.length > 0 &&
                                          materialFinalisation[0].actual
                                          ? materialFinalisation[0].actual
                                          : materialFinalisation[0].expected,
                                        '',
                                        '',
                                        '',
                                        '',
                                        '',
                                        ''
                                      )" *ngIf ="data.completed === false &&  data.agenda === 'material_finalisation_meeting' " class="btn-primary border-0">
                                        Reschedule 
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                 
                                </div>
                              </div>
                          </div>
                        </div>
    
                        <div *ngIf =" upcoming_events.length === 0">
                          <p class="pb-2">No Events Found</p>
                        </div>
                    </div>
    
                    <div class="mx-4" *ngIf =" eventString == 'older'  ">
                      <div class="row pb-3" *ngFor ="let data of past_events">
                        <div class="col-lg-12">
                            <div class="row">
                              <div class="eventdate col-1">
                                {{data.datetime.split('- ')[0] |   date: "dd MMM" }}
                              </div>
                              <div class="col-lg-11 col-10 event-mobile-fortag">
                                <div class="row">
                                  <div class="col-lg-6 col-8">
                                    <p style="text-transform: capitalize ; font-size: 14px;" class="mb-0">
                                      {{ data.agenda | replaceChar }}
                                    </p>
                                  </div>
                                  <div class="col-lg-6 col-4 d-flex d-lg-block">
                                    <p class="mb-0 d-flex justify-content-end" style="color: #23B727;" *ngIf ="data.completed === true">Completed <span> <img src="../../assets/images/carosel-icons/charm_circle-tick.png">   </span>  </p>
                                  </div>
                                </div>
                                <p class="mb-0">
                                  Meeting Completed at
                                  {{ data.datetime.split('- ')[1] }}
                                </p>
                              </div>
                            </div>
                        </div>
                      </div>
                      <div *ngIf =" past_events.length === 0">
                        <p class="pb-2">No Events Found</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
            <div class="order mx-lg-3 mx-3 my-lg-4 my-3">
              <div class="row">
                <div class="col-lg-6 col-12 cursorPointer mb-lg-0 mb-3" (click)="downloadWellcomeKit()">
                  <div class="order-tab d-flex justify-content-between px-4 h-100">
                    <div class="d-flex py-3">
                      <div class="align-self-center">
                        <img src="../../assets/images/carosel-icons/shake-hand.png"/>
                      </div>
                      <div class="ml-4">
                        <p class="font-weight-bold">Your Welcome Kit</p>
                        <p class="mb-0">Click here to download welcome kit</p>
                      </div>
                    </div>
                    <div class="align-self-center">
                      <img src="../../assets/images/carosel-icons/arrow-blackk.png"/>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-12 cursorPointer mb-lg-0 mb-3" (click)="getOtp(bookingFormdownload, bookingFormotp)">
                  <div class="order-tab d-flex justify-content-between px-4">
                    <div class="d-flex py-3">
                      <div class="align-self-center">
                        <img src="../../assets/images/carosel-icons/file.png"/>
                      </div>
                      <div class="ml-4">
                        <p class="font-weight-bold">Your Booking Form</p>
                        <p class="mb-0">Click here to download. You will receive an OTP for approving it</p>
                      </div>
                    </div>
                    <div class="align-self-center">
                      <img src="../../assets/images/carosel-icons/arrow-blackk.png"/>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          </div>



          <!-- cost-break-up data -->

          <div  id="valueCostBreak">
            <div (scroll)="scrollHandler()" *ngIf ="costBreakUpData && costBreakUpData.type_wise_breakup.length !==0  &&  costBreakUpData.space_wise_breakup.space_uniq.length !==0" class="costBreakup mx-lg-4  my-3 mx-3">
              <div class="row">
                <div class="col-lg-12">
                  <div class="row" >
                    <div class="col-lg-6 col-12">
            
                      <div style="border-bottom: 2px solid #8D0528;" class="d-inline-block my-3">
                        <p style="margin-bottom : 0.5rem ; font-size: 16px; color: #8D0528; font-weight: 700;" class="mb-0 mx-3">Cost Breakup</p>
                      </div>
            
                      <div class="text-center mt-4">
                        <button (click)="costBreakUp('spaceWise')" [ngClass] = "{'costBreakActiveClass' : spaceString == 'spaceWise'  }"   class="py-lg-3 py-1 cost-space-button">Cost breakup space-wise</button>
                        <button (click) = "costBreakUp('typeWise')" [ngClass] = "{'costBreakActiveClass' : spaceString == 'typeWise'}"    class="py-lg-3 py-1 cost-break-button">Cost breakup type-wise</button>
                      </div>
      
                      <div class="my-5"  id="two">
                        <ng-container *ngIf ="costBreakUpData">
                          <div class="row justify-content-center mobile-view-mx-auto">
                            <div class="col-lg-11">
                              <div class="row mobile-view-mx-auto">
                                <div class="col-lg-4" *ngFor ="let item of spaceWiseData" >
                                 <div class="d-flex align-items-baseline">
                                  <span  style="height: 10px; width: 10px; display: inline-block; height: 11px; width: 45px; " [ngStyle]="{'background-color':item.color}"  ></span>
                                  <p class="px-1 numbers" style="font-size:14px;">{{item.space}}:<span class="numbers">({{item.amount /  costBreakUpData.calculations.net_amount * 100  | number: '1.0-0'}}%</span>)</p> 
                                 </div>
                                </div>
                              </div>
                            </div>
                        </div>  
                        </ng-container>
                        <canvas id="myChart"></canvas>
                      </div>
                      <div class="my-5 d-none" [ngClass] = "{'d-block' : spaceString == 'typeWise'}" id="four" >
                        <ng-container *ngIf ="costBreakUpData">
                          <div class="row justify-content-center mobile-view-mx-auto">
                            <div class="col-lg-11">
                              <div class="row mobile-view-mx-auto">
                                <div class="col-lg-4" *ngFor ="let item of typeWiseData" >
                                 <div class="d-flex align-items-baseline">
                                  <span  style="height: 10px; width: 10px; display: inline-block; height: 11px; width: 45px; " [ngStyle]="{'background-color':item.color}"  ></span>
                                  <p class="px-1 " style="font-size:14px;">{{item.capitalized_quote_type}}:<span class="numbers">({{item.amount /  costBreakUpData.calculations.net_amount * 100  | number: '1.0-0'}}%)</span></p> 
                                 </div>
                                </div>
                              </div>
                            </div>
                        </div>  
                        </ng-container>
                        <canvas id="typeChart"></canvas>
                      </div>
  
                    </div>
            
                    <div class="col-lg-6 col-12" style="overflow-y: auto;"  [ngClass]="{'class50': costBreakUpData.space_wise_breakup.space_uniq.length  >10  , 'class38': costBreakUpData.space_wise_breakup.space_uniq.length  < 10}">
                      <table>
                        <ng-container *ngIf = "spaceString == 'spaceWise'" >
                          <tr *ngFor = "let space of  spaceWiseData">
                            <th
                            style="font-weight: normal">{{space.space}}</th>
                            <td style="cursor: pointer;" class="numbers" data-toggle="modal" data-target="#levelTwoModal">{{space.amount  | currency :'INR':'symbol':'1.0-0' }}</td>      
                          </tr>  
                        </ng-container>
            
                        <ng-container *ngIf = "spaceString == 'typeWise'">
                          <tr *ngFor = "let type of typeWiseData">
                            <th 
                            style="font-weight: normal" > {{type.capitalized_quote_type}}  </th>
                            <td style="cursor: pointer;" class="numbers" data-toggle="modal" data-target="#spaceModal">{{type.amount | currency :'INR':'symbol':'1.0-0'}}</td>      
                          </tr>  
                        </ng-container>

                        <tr *ngIf ="costBreakUpData.calculations.cleaning_fee && spaceString == 'typeWise' &&  costBreakUpData.calculations.cleaning_fee > 0  ">
                          <th  style="font-weight: normal" >Others</th>  
                          <td>{{costBreakUpData.calculations.cleaning_fee | currency :'INR':'symbol':'1.0-0' }}</td>      
                        </tr>  
                                    
                        <tr>
                          <th>Net Amount</th>  
                          <td class="numbers">{{costBreakUpData.calculations.net_amount | currency :'INR':'symbol':'1.0-0' }}</td>      
                        </tr>  
                      
                        <ng-container *ngIf = "costBreakUpData && costBreakUpData.calculations.promo_applied.length > 0 ">
                          <tr>
                            <th>
                              <p>Promotions Applied</p>
  
                              <ng-container *ngFor = "let promo of costBreakUpData.calculations.promo_applied">
                                <p style="font-weight: normal; color: #8D0528;" class="mb-2">{{promo.code}}
                                  <span style="font-weight: 400; color: black;" *ngIf ="promo.expiry_date">(Valid Till <span class="numbers">{{promo.expiry_date}}</span>)</span>
                                </p>
                              </ng-container>
                            </th>
                            <td> 
                              <p class="mb-0 numbers">{{costBreakUpData.calculations.total_promo_discount}}%</p>
                              <p class="numbers">{{costBreakUpData.calculations.total_amount_discount_applied  | currency :'INR':'symbol':'1.0-0' }}</p>
                            </td>
                          </tr>
                        </ng-container>
            
                        <tr>
                          <th>Project Management Fee</th>  
                          <td class="numbers">{{costBreakUpData.calculations.project_management_fee | currency :'INR':'symbol':'1.0-0' }}</td>      
                        </tr> 
                        
                        <tr>
                          <th>Total(Incl of all Taxes & Freight)</th>  
                          <td class="numbers">{{costBreakUpData.calculations.grand_total_amount | currency :'INR':'symbol':'1.0-0'}}</td>      
                        </tr>  
                      </table>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

    
          <!--Desktop View-->
          <div class="progress-timeline d-sm-none d-md-block d-none"  [ngClass]="{'d-lg-none': (boq_status_level.boq_status_service === 'Pending') || (boq_status_level.boq_timeline_level === 'level_2') || (boq_status_level.boq_timeline_level === 'level_3') , 'd-lg-block' : (boq_status_level.boq_timeline_level === 'level_1') &&  (boq_status_level.boq_status_service !== 'Pending') }">
            <div id="timeline-accordian" class="accordion1 horizontal  justify-content-center d-flex">
             <ul>
              
              <li [ngClass]="{'d-none': !check_1, '': check_1}">
                 <input type="radio" id="vert-12" name="vert-accordionfdsfs"  checked="checked"/>
        <label for="vert-12" style="position: relative;">
          <span class="mt-4" style="writing-mode: vertical-lr; transform: rotate(-180deg);">&nbsp;&nbsp; Order&nbsp;Booking</span>
        <span class="staic_number">1</span>
        <span  class="arrow_right_12"  ></span>
      </label>
      <div class="content m-3">
          <div id="applying_styles_steps">
         <ul class="nav nav-pills mt-4"  id="pills-tab-one" role="tablist">


          <!-- Estimate Shared -->

           <li id="border-1" class="nav-item active_before" style="width: 79px;">
             <a
                class="nav-link active mb-3"
                id="pills-1-tab"
                data-toggle="pill"
                href="#pills-1"
                role="tab"
                aria-controls="pills-1"
                aria-selected="true"
                style="width: 40px; height: 41px;"
                >
                <span>
                  <!-- <i class="fa fa-check" aria-hidden="true"></i> -->
                  <img src="../../assets/images/Vector.png" style="margin-left: -8px;
                  margin-top: -1px;">
                </span>
                <!-- <ng-template #elseBlock>
                  <span>1</span>
                </ng-template> -->
                </a
              >
              <a class="date_name_style">Estimate Shared</a>
                <div class="date_name_style numbers " *ngIf="level1_data.shared_date !== null">({{
                                  level1_data.shared_date
                                    | date: "dd MMM YYYY"
                                }})</div>
                <!-- <div *ngIf="boq_status_data.level_1.shared_date === null"></div> -->
              
           </li>


           <!-- Estimate Approved -->

           <li id="border-2" class="nav-item" style="width: 79px;" [ngClass]="{
              'changes2': (level1_data['approved_date'] !== null) && (level1_data['shared_date'] !== null )
            }">
              <a
                class="nav-link mb-3"
                [ngClass]="{
              active: level1_data['approved_date'] !== null
            }"
                id="pills-2-tab"
                data-toggle="pill"
                href="#pills-2"
                role="tab"
                aria-controls="pills-2"
                aria-selected="false"
                style="width: 40px; height: 41px;"
                >
                <span *ngIf="level1_data['approved_date'] !== null; else elseBlock11112"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                  margin-top: -1px;"></span>
                <ng-template #elseBlock11112>
                  <span>2</span>
                </ng-template></a
              >
              <a class="date_name_style">Estimate Approved</a>
              <div class="date_name_style numbers" *ngIf="level1_data['approved_date'] !== null">({{
                                  level1_data.approved_date
                                    | date: "dd MMM YYYY"
                                }})</div>
              <div *ngIf="level1_data.approved_date === null"></div>
            </li>




            <!-- 10% paid Date -->
            <li id="border-3" class="nav-item" style="width: 79px;" [ngClass]="{
              'changes2': (level1_data['10_per_paid_date'] !== null)
            }">
              <a
                class="nav-link mb-3"
                id="pills-3-tab"
                [ngClass]="{
              active: (level1_data['10_per_paid_date'] !== null)
            }"
                data-toggle="pill"
                href="#pills-3"
                role="tab"
                aria-controls="pills-3"
                aria-selected="false"
                style="width: 40px; height: 41px;"
                ><span *ngIf=" (level1_data['10_per_paid_date'] !== null); else elseBlock111"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                  margin-top: -1px;"></span>
                <ng-template #elseBlock111>
                  <span>3</span>
                </ng-template></a
              >
              <a class="date_name_style"><span class="numbers">10%</span> Paid</a>
              <div class="date_name_style numbers" *ngIf="level1_data['10_per_paid_date'] !== null">({{level1_data['10_per_paid_date'] | date: "dd MMM YYYY"}})</div>
              <div *ngIf="level1_data['10_per_paid_date'] === null"></div>
            </li>
            
         </ul> 
         </div>  
        </div>
              </li>
              </ul> 
            </div>
          </div>

          <!-- mobile view -->

          <div class="container d-lg-none d-block" [ngClass]="{'d-none': (boq_status_level.boq_status_service === 'Pending') || (boq_status_level.boq_timeline_level === 'level_2') || (boq_status_level.boq_timeline_level === 'level_3') , 'd-block' : (boq_status_level.boq_timeline_level === 'level_1') &&  (boq_status_level.boq_status_service !== 'Pending') }" >
            <div class="accordion" id="accordionSection" [ngClass]="{'d-none':  boq_status_level.boq_timeline_level === 'level_1' && boq_status_level.boq_status_service === 'Pending'}">
              <ng-container *ngIf ="boq_status_level.boq_timeline_level === 'level_1'  || boq_status_level.boq_timeline_level === 'level_2' || boq_status_level.boq_timeline_level === 'level_3'">
                <div class="row mx-0 py-2 br_5 order-component">
                  <div
                    data-toggle="collapse"
                    href="#orderBooking"
                    role="button"
                    aria-expanded="false"
                    aria-controls="orderBooking"
                    class="col-lg-12 d-flex justify-content-center py-1"
                    data-toggle="collapse"
                  >
                    <h4 class="mb-0">Order Booking</h4>
                    <button class="accordionArrow">
                      <img src="../../assets/images/icons/down-arrow-order.png" />
                    </button>
                  </div>
                </div>
                <div
                  class="collapse"
                  id="orderBooking"
                  data-parent="#accordionSection"
                  [ngClass]="{
                    show:
                      orderBookingDetails.initial_boq_shared &&
                      !paymentStatusDetails.per_10_payment
                  }"
                >
                  <div
                    class="row justify-content-center align-items-center flex-column mb-5"
                  >
                    <div class="col-12 col-md-6">
                      <div class="root-navmenu">
                        <div class="navmenu">
                          <ul>
                            <li class="active">
                              <!-- active class is implemented on div -->
                              <div
                                class="mb-5"
                                [ngClass]="{
                                  'active': (boq_status_level.boq_status_service === 'Initial BOQ Shared')  || (boq_status_level.boq_status_service === 'Approved/Rejected') || (boq_status_level.boq_timeline_level === 'level_2') || (boq_status_level.boq_timeline_level === 'level_3') || (boq_status_level.boq_status_service === '10% Paid')
                                }"
                              >
                                <p class="navmenu-detail mb-0">Estimate Shared</p>
                                <p style="font-size: 12px;" class="numbers" *ngIf="level1_data.shared_date !== null">({{(level1_data.shared_date | date: "dd-MM-YYYY")}})</p>
                                <p style="font-size: 12px;" *ngIf="level1_data.shared_date === null"></p>
                              </div>
                            </li>
                            <li class="active">
                              <div
                                class="mb-5"
                                [ngClass]="{
                                  'active': (boq_status_level.boq_status_service === 'Approved/Rejected') || (boq_status_level.boq_status_service === '10% Paid')  || (boq_status_level.boq_timeline_level === 'level_2') || (boq_status_level.boq_timeline_level === 'level_3')
                                }"
                              >
                                <p class="navmenu-detail mb-0">
                                  Estimate Approved
                                </p>
                                  <p style="font-size: 12px;" class="numbers" *ngIf="level1_data.approved_date !== null">({{(level1_data.approved_date | date: "dd-MM-YYYY")}})</p>
                                  <p style="font-size: 12px;" *ngIf="level1_data.approved_date === null"></p>

                              </div>
                            </li>
                            <li>
                              <div
                                [ngClass]="{
                                  'active':  level1_data['10_per_paid_date'] !== null
                                }"
                              >
                                <p class="navmenu-detail mb-0">
                                  <span class="numbers">10%</span> Paid
                                </p>
                                <p style="font-size: 12px;" class="numbers" *ngIf="level1_data['10_per_paid_date'] !== null">({{(level1_data['10_per_paid_date'] | date: "dd-MM-YYYY")}})</p>
                                <p style="font-size: 12px; margin-bottom: 1.4rem;" *ngIf="level1_data['10_per_paid_date'] === null"></p>
                              </div>
                            </li>
                            <div
                              class="orderBookingActiveLine"
                              [ngClass]="{
                                'progress50':boq_status_level.boq_status_service === 'Approved/Rejected',
                                'progress100': level1_data['10_per_paid_date'] !== null
                              }"
                            ></div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!-- level two -->
              <ng-container *ngIf ="boq_status_level.boq_timeline_level === 'level_2' || boq_status_level.boq_timeline_level === 'level_3' ">
                <div class="row mx-0 py-2 br_5 order-component">
                  <div
                    data-toggle="collapse"
                    href="#orderBookingLevelTwo"
                    role="button"
                    aria-expanded="false"
                    aria-controls="orderBooking"
                    class="col-lg-12 d-flex justify-content-center py-1"
                    data-toggle="collapse"
                  >
                    <h4 class="mb-0">Order Conformation</h4>
                    <button class="accordionArrow">
                      <img src="../../assets/images/icons/down-arrow-order.png" />
                    </button>
                  </div>
                </div>
                <div
                  class="collapse"
                  id="orderBookingLevelTwo"
                  data-parent="#accordionSection"
                  [ngClass]="{
                    show:
                      orderBookingDetails.initial_boq_shared &&
                      !paymentStatusDetails.per_10_payment
                  }"
                >
                  <div
                    class="row justify-content-center align-items-center flex-column mb-5"
                  >
                    <div class="col-12 col-md-6">
                      <div class="root-navmenu  orderCOnformationLevelTwo">
                        <div class="navmenu">
                          <ul>
                            <li class="active">
                              <!-- active class is implemented on div -->
                              <div
                                [ngClass]="{
                                  'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid')
                                }"
                              >
                                <p class="navmenu-detail mb-0">
                                  Site Measurement
                                </p>
                                <p style="font-size: 12px;" class="numbers" *ngIf="level2_data.site_measurement_date !== null">({{(level2_data.site_measurement_date | date: "dd-MM-YYYY")}})</p>
                              </div>
                            </li>
                            <li class="active">
                              <div
                                [ngClass]="{
                                  'active': (boq_status_level.boq_status_service !== 'Site Measurement') 
                                }"
                              >
                                <p class="navmenu-detail mb-0">
                                  Material Selection
                                </p>
                                <p style="font-size: 12px;" class="numbers" *ngIf="level2_data.material_selection_date !== null">({{(level2_data.material_selection_date | date: "dd-MM-YYYY")}})</p>
                              </div>
                            </li>
                            <li>
                              <div
                                [ngClass]="{
                                  'active': (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection')
                                }"
                              >
                                <p class="navmenu-detail mb-0">
                                  Site Validation
                                </p>
                                <p style="font-size: 12px;" class="numbers" *ngIf="level2_data.Site_validation_date !== null">({{(level2_data.Site_validation_date | date: "dd-MM-YYYY")}})</p>
                              </div>
                            </li>
  
                            <li>
                              <div
                                [ngClass]="{
                                  'active': (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') 
                                }"
                              >
                                <p class="navmenu-detail mb-0">
                                  QC Approved
                                </p>
                                <p style="font-size: 12px;" class="numbers" *ngIf="level2_data.qc_approval_date !== null">({{(level2_data.qc_approval_date | date: "dd-MM-YYYY")}})</p>
                              </div>
                            </li>
  
                            <li>
                              <div
                              [ngClass]="{
                                'active': (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') 
                              }"
                              >
                                <p class="navmenu-detail mb-0">
                                 
                                  BOQ Shared
                                </p>
                                
                                <p style="font-size: 12px;" class="numbers" *ngIf="level2_data.final_boq_shared_date !== null">({{(level2_data.final_boq_shared_date | date: "dd-MM-YYYY")}})</p>
                              </div>
                            </li>
  
                            <li>
                              <div
                              [ngClass]="{
                                'active': (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') 
                              }"
                              >
                                <p class="navmenu-detail mb-0">
                                 
                                  BOQ Approved
                                </p>
                                <p style="font-size: 12px;" class="numbers" *ngIf="level2_data.final_boq_approved_date !== null">({{(level2_data.final_boq_approved_date | date: "dd-MM-YYYY")}})</p>
                              </div>
                            </li>
  
                            <li>
                              <div
                              [ngClass]="{
                                'active': (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared')   && (boq_status_level.boq_status_service !== 'Final BOQ Approved')
                              }"
                              >
                                <p class="navmenu-detail mb-0">
                                  Sent to Production
                                </p>
                                <p style="font-size: 12px;" class="numbers" *ngIf="level2_data.sent_to_production_date !== null">({{(level2_data.sent_to_production_date | date: "dd-MM-YYYY")}})</p>
                              </div>
                            </li>
                            <div
                              class="orderBookingActiveLine"
                              [ngClass]="{
                                'progressmaterial':boq_status_level.boq_status_service === 'Material Selection',
                                'progressSiteValidation':boq_status_level.boq_status_service === 'Site Validation',
                                'progressQcApproved':boq_status_level.boq_status_service === 'QC Approved',
                                'progressBoqshared':boq_status_level.boq_status_service === 'Final BOQ Shared',
                                'progressBoqApproved':boq_status_level.boq_status_service === 'Final BOQ Approved',
                                'progressSentToProduction': (boq_status_level.boq_status_service === 'Sent to Production')  || (boq_status_level.boq_timeline_level === 'level_3')
                              }"
                            ></div>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>



              <!-- level three service , non service -->

              <ng-container *ngIf ="boq_status_level.boq_timeline_level === 'level_3'">
                <div class="row mx-0 py-2 br_5 order-component">
                  <div
                    data-toggle="collapse"
                    href="#level-three"
                    role="button"
                    aria-expanded="false"
                    aria-controls="orderBooking"
                    class="col-lg-12 d-flex justify-content-center py-1"
                    data-toggle="collapse"
                  >
                    <h4 class="mb-0">Order Execution</h4>
                    <button class="accordionArrow">
                      <img src="../../assets/images/icons/down-arrow-order.png" />
                    </button>
                  </div>
                </div>
                <div
                  class="collapse"
                  id="level-three"
                  data-parent="#accordionSection"
                  [ngClass]="{
                    show:
                      orderBookingDetails.initial_boq_shared &&
                      !paymentStatusDetails.per_10_payment
                  }"
                >
                  <div class="row justify-content-center align-items-center flex-column mb-5">
                      <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                          <div class="carousel-item active col-12">
                            <div class="root-navmenu  orderCOnformationLevelThreee-furniture">
                              <p class = 'colorBurguntee' style="border-bottom: 1px solid #8d0528;" >Furniture</p>
                              <div class="navmenu">
                                <ul>
                                  <li class="active">
                                    <!-- active class is implemented on div -->
                                    <div
                                      [ngClass]="{
                                        'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved'),
                                        'inactive' : boq_status_level.boq_status_non_service === 'Sent to Production'
                                      }"
                                    >
                                      <p class="navmenu-detail mb-0">
                                        Raw Material Received
                                      </p>
                                      <p style="font-size: 12px;" class="numbers" *ngIf="level3_data_nonservice.raw_material_received_date !== null">({{(level3_data_nonservice.raw_material_received_date | date: "dd-MM-YYYY")}})</p>
                                    </div>
                                  </li>
                                  <li class="active">
                                    <div
                                      [ngClass]="{
                                        'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approval') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved')  && (boq_status_level.boq_status_non_service !== 'Raw Material Recieved'),
                                        'inactive' : boq_status_level.boq_status_non_service === 'Sent to Production'
                                      }"
                                    >
                                      <p class="navmenu-detail mb-0">
                                        Furniture Production Start
                                      </p>
                                      <p style="font-size: 12px;" class="numbers" *ngIf="level3_data_nonservice.furniture_production_start_date !== null">({{(level3_data_nonservice.furniture_production_start_date | date: "dd-MM-YYYY")}})</p>
                                    </div>
                                  </li>
                                  <li>
                                    <div
                                      [ngClass]="{
                                        'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_non_service !== 'Raw Material Recieved') && (boq_status_level.boq_status_non_service !== 'Furniture Production Start'),
                                        'inactive' : boq_status_level.boq_status_non_service === 'Sent to Production'
                                      }"
                                    >
                                      <p class="navmenu-detail mb-0">
                                        Furniture Dispatch
                                      </p>
                                      <p style="font-size: 12px;" class="numbers" *ngIf="level3_data_nonservice.furniture_dispatch_date !== null">({{(level3_data_nonservice.furniture_dispatch_date | date: "dd-MM-YYYY")}})</p>
                                      <p style="font-size: 12px;" *ngIf="level3_data_nonservice.furniture_dispatch_date === null"></p>
                                    </div>
                                  </li>
        
                                  <li>
                                    <div
                                      [ngClass]="{
                                        'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved')  && (boq_status_level.boq_status_non_service !== 'Raw Material Recieved') && (boq_status_level.boq_status_non_service !== 'Furniture Production Start') && (boq_status_level.boq_status_non_service !== 'Furniture Dispatch'),
                                        'inactive' : boq_status_level.boq_status_non_service === 'Sent to Production'
                                      }"
                                    >
                                      <p class="navmenu-detail mb-0">
                                        Installation
                                      </p>
                                        <p style="font-size: 12px;" class="numbers" *ngIf="level3_data_nonservice.installation_date !== null">({{(level3_data_nonservice.installation_date | date: "dd-MM-YYYY")}})</p>
                                        <p style="font-size: 12px;" *ngIf="level3_data_nonservice.installation_date === null"></p>
                                    </div>
                                  </li>
        
                                  <li>
                                    <div
                                    [ngClass]="{
                                      'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_non_service !== 'Raw Material Recieved') && (boq_status_level.boq_status_non_service !== 'Furniture Production Start') && (boq_status_level.boq_status_non_service !== 'Furniture Dispatch') && (boq_status_level.boq_status_non_service !== 'Installation'),
                                      'inactive' : boq_status_level.boq_status_non_service === 'Sent to Production'
                                    }"
                                    >
                                      <p class="navmenu-detail mb-0">
                                        Handover
                                      </p>
                                      <p style="font-size: 12px;" class="numbers" *ngIf="level3_data_nonservice.handover_date !== null">({{(level3_data_nonservice.handover_date | date: "dd-MM-YYYY")}})</p>
                                    </div>
                                  </li>                          
                                  <div
                                    class="orderBookingActiveLine"
                                    [ngClass]="{
                                      'progressprodStart':boq_status_level.boq_status_non_service === 'Furniture Production Start',
                                      'progressdispatch':boq_status_level.boq_status_non_service === 'Furniture Dispatch',
                                      'progressinstallation':boq_status_level.boq_status_non_service === 'Installation',
                                      'progressHandover': (boq_status_level.boq_status_non_service === 'Handover')
                                    }"
                                  >
                                </div>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div class="carousel-item col-12">
                            <div class="root-navmenu  orderCOnformationLevelThreee-Service">
                              <p class = 'colorBurguntee' style="border-bottom: 1px solid #8d0528;" >Service</p>
                              <div class="navmenu">
                                <ul>
                                  <li class="active">
                                    <!-- active class is implemented on div -->
                                    <div
                                      [ngClass]="{
                                        'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_service !== 'Sent to Production')
                                      }"
                                    >
                                      <p class="navmenu-detail mb-0">
                                        Service Start
                                      </p>
                                      <p style="font-size: 12px;" class="numbers" *ngIf="level3_data_service.service_start_date !== null">({{(level3_data_service.service_start_date | date: "dd-MM-YYYY")}})</p>
                                    </div>
                                  </li>
                                  <li class="active">
                                    <div
                                      [ngClass]="{
                                        'active': (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_service !== 'Sent to Production') && (boq_status_level.boq_status_service !== 'Service Start')
                                      }"
                                    >
                                      <p class="navmenu-detail mb-0">
                                        Service <span class="numbers">50%</span>
                                      </p>
                                      <p style="font-size: 12px;" *ngIf="level3_data_service.service_50_per_date !== null">({{(level3_data_service.service_50_per_date | date: "dd-MM-YYYY")}})</p>
                                    </div>
                                  </li>
                                  <li>
                                    <div
                                      [ngClass]="{
                                        'active':  (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_service !== 'Sent to Production') && (boq_status_level.boq_status_service !== 'Service Start') && (boq_status_level.boq_status_service !== 'Service 50%')
                                      }"
                                    >
                                      <p class="navmenu-detail mb-0">
                                        Site Readiness
                                      </p>
                                      <p style="font-size: 12px;" *ngIf="level3_data_service.site_readiness_date !== null">({{(level3_data_service.site_readiness_date | date: "dd-MM-YYYY")}})</p>
                                    </div>
                                  </li>
        
                                  <li>
                                    <div
                                      [ngClass]="{
                                        'active':  (boq_status_level.boq_status_service !== 'Shared') && (boq_status_level.boq_status_service !== 'Approved/Rejected') && (boq_status_level.boq_status_service !== '10% Paid') && (boq_status_level.boq_status_service !== 'Site Measurement') && (boq_status_level.boq_status_service !== 'Material Selection') && (boq_status_level.boq_status_service !== 'Site Validation') && (boq_status_level.boq_status_service !== 'QC Approved') && (boq_status_level.boq_status_service !== 'Final BOQ Shared') && (boq_status_level.boq_status_service !== 'Final BOQ Approved') && (boq_status_level.boq_status_service !== 'Sent to Production') && (boq_status_level.boq_status_service !== 'Service Start') && (boq_status_level.boq_status_service !== 'Service 50%') && (boq_status_level.boq_status_service !== 'Site Readiness')
                                      }"
                                    >
                                      <p class="navmenu-detail mb-0">
                                        Handover
                                      </p>
                                      <p style="font-size: 12px;" *ngIf="level3_data_service.handover_date !== null">({{(level3_data_service.handover_date | date: "dd-MM-YYYY")}})</p>
                                    </div>
                                  </li>            
                                  <div
                                    class="orderBookingActiveLine"
                                    [ngClass]="{
                                      'servicefifty':boq_status_level.boq_status_service === 'Service 50%',
                                      'serviceSiteRedy':boq_status_level.boq_status_service === 'Site Readiness',
                                      'serviceHandover':boq_status_level.boq_status_service === 'Handover'
                                    }"
                                  >
                                </div>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                          </a>
                          <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                          </a>
                          </div>
                      </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>

         <!-- Patment Section -->
          <div id="paymentSection22" >
            <div (scroll)="scrollHandler()"  class="paymentSection" *ngIf = "paymentSection.lead_payment_request.amount_to_be_paid.ten_per.length !==0 ||  paymentSection.lead_payment_request.amount_to_be_paid.fifty_per.length  !==0 || paymentSection.lead_payment_request.amount_to_be_paid.forty_per.length  !==0">
              <div class="payment-default mx-lg-4 mx-3 my-4">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="d-inline-block my-3" style="border-bottom: 2px solid #8D0528;">
                      <p class="mb-0 mx-3" style="margin-bottom: 0.5rem; font-size: 16px; color: #8D0528; font-weight: 700;">Payment Details</p>
                    </div>
                  </div>
                </div>
  
                <div class="row justify-content-center">
                  <div class="col-lg-6">
                    <p class="text-center">Please select one or more BOQs</p>
                  </div>
                </div>
  
               <div class="row justify-content-center payment-cards">
                <div class="col-lg-3" *ngIf ="paymentSection.lead_payment_request.amount_to_be_paid.ten_per.length > 0">
                  <div class="row flex-column">
                    <div class="text-center mb-3">
                      <span class="percentageForEachCard numbers" >10%</span>
                    </div>
                    <div class="col-lg-11" *ngFor ="let ten of paymentSection.lead_payment_request.amount_to_be_paid.ten_per ;  let p = index">
                      <div class="mb-4 w-100">
                        <input (click) = "clickOnfirstBoq(ten.boq_number , p , ten.selected , ten.amount_to_be_paid , $event , ten.id , ten.boq_value)" type="checkbox" class="d-none" id="tenCheckbox{{p}}" >
                        <label for="tenCheckbox{{p}}" class="w-100 px-lg-0 px-3">
                          <div  id="tenPercentBoq{{p}}"  class="apply-border cursorPointer">
                            <div class="card padd-for-card">
                              <div class="card-body">
                                <img class="d-block mx-auto my-4" id="tenImg{{p}}" src="{{addingDynamicImg}}">
                                <p class="card-title text-center font-weight-bold numbers">{{ten.boq_number}}</p>
                                <p class="status-boq text-center font-weight-bold">{{ten.boq_status  | number: '1.2-2'}}% paid(i.e.,Rs {{ten.paid_amount |  number: '1.0-0'}} )</p>
                              </div>
                            </div>
                          </div>
                        </label>
                    </div>
                    </div>
                  </div>
                </div>
  
                <div class="col-lg-3" *ngIf ="paymentSection.lead_payment_request.amount_to_be_paid.forty_per.length > 0">
                  <div class="row flex-column">
                    <div class="text-center mb-3">
                      <span class="percentageForEachCard numbers">40%</span>
                    </div>
                    <div class="col-lg-11" *ngFor ="let forty of paymentSection.lead_payment_request.amount_to_be_paid.forty_per ; let q = index">
                      <div class="mb-4 w-100">
                        <input (click) = "clickonFortyBoq( forty.boq_number , q , forty.selected , forty.amount_to_be_paid , $event , forty.id , forty.boq_value)" type="checkbox" class="d-none" id="fortyCheckbox{{q}}">
                        <label for="fortyCheckbox{{q}}" class="w-100 px-lg-0 px-3">
                          <div  id="fortypercentBoq{{q}}"  class="apply-border cursorPointer">
                            <div class="card padd-for-card">
                              <div class="card-body">
                                <img class="d-block mx-auto my-4" id="fortyImg{{q}}" src="{{addingDynamicImg}}">
                                <p class="card-title text-center font-weight-bold">{{forty.boq_number}}</p>
                                <p class="status-boq text-center font-weight-bold numbers">{{forty.boq_status | number: '1.2-2' }}% paid(i.e.,Rs {{forty.paid_amount |  number: '1.0-0' }} )</p>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div class="col-lg-3" *ngIf ="paymentSection.lead_payment_request.amount_to_be_paid.fifty_per.length > 0">
                  <div class="row flex-column">
                    <div class="text-center mb-3">
                      <span class="percentageForEachCard numbers">50%</span>
                    </div>
                    <div class="col-lg-11" *ngFor ="let fifty of paymentSection.lead_payment_request.amount_to_be_paid.fifty_per ; let r= index">
                      <div class="mb-4 w-100 ">
                        <input (click) = "clickonFiftyBOq(fifty.boq_number,r, fifty.selected , fifty.amount_to_be_paid , $event , fifty.id)"  type="checkbox" id="fiftyCheckbox{{r}}" class="d-none"/>
                        <label class="w-100 px-lg-0 px-3"  for="fiftyCheckbox{{r}}">
                          <div  id="fiftypercentBoq{{r}}"  class="apply-border cursorPointer">
                            <div class="card padd-for-card">
                              <div class="card-body">
                                <img class="d-block mx-auto my-4" id="firtyImg{{r}}"  src="{{addingDynamicImg}}" >
                                <p class="card-title text-center font-weight-bold numbers">{{fifty.boq_number}}</p>
                                <p class="status-boq text-center font-weight-bold numbers">{{fifty.boq_status |  number: '1.2-2' }}% paid(i.e.,Rs {{fifty.paid_amount  |  number: '1.0-0' }} )</p>
                              </div>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>



               </div>
  
               <div class="row justify-content-center">
                <div class="col-lg-9 col-9">
                  <p class="font-weight-bold">Amount to be paid : <span class="numbers">{{totalAmount  | currency :'INR':'symbol':'1.0-0' }}</span> </p>
                </div>
               </div>
               <div class="row justify-content-center pb-5" *ngIf ="totalAmount > 0">
                  <button  (click) = "OpenpaymentModal(paymentModal)"  style="border: none; color: white; background-color: #8D0528;" class="px-5 py-1">Click here to pay</button>
               </div>
              </div>
            </div>
          </div>

          <!-- All Boqs -->
          <div id="fullBoq" #entireBoqs>
            <div (scroll)="scrollHandler()"  *ngIf ="AllBoqs && AllBoqs.length > 0"  class="other-active-boqs mx-lg-4 mx-3 my-4 pb-4">
              <div class="active-boqs py-3">
                <p class="px-4 d-inline-block">All BOQs</p>
              </div>
            <div class="mobile-carosel-active-boqs">
                  <div slickContainer #slickController="slick" [slickConfig]="config">
                    <ng-container *ngIf="slickController.initialize">
                    <div *ngFor = "let data of AllBoqs ; let q = index">
                        <div slickItem class="card ml-lg-5 mx-4 cursorPointer" style="border: 0.2px solid #F0F1F5;" (click)="customerLink(data.magic_link)">
                          <div  class="card-body p-0">
                            <div style="background-color: #FFEAEF;" class="py-5">
                              <p class="text-center mb-0 font-weight-bold numbers">{{data.boq_number}}</p>
                            </div>
                            <div class="py-4 ml-3">
                              <p>BOQ Status</p>
                              <p class="mb-0 font-weight-bold" style="color: #8D0528;">{{data.boq_status}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="d-lg-block d-none">
                    <div class="other-mobile-boqs"  [ngClass] = "{'d-none' : AllBoqs.length <= 4  }">
                      <button   class="mx-1 button-one" (click)="slickController.prev()">
                        <img class="cursorPointer"  src="../../assets/images/sign-up-to-service/previous (1).png" alt="previous" >
                      </button>
                      <button  class="mx-1 button-two" (click)="slickController.next()">
                        <img class="cursorPointer" src="../../assets/images/sign-up-to-service/next (1).png" alt="next" >
                      </button>
                    </div>
                </div>
                <div class="d-lg-none d-block">
                  <div class="other-mobile-boqs" [ngClass] = "{'d-none' : AllBoqs.length === 1  }">
                    <button   class="mx-1 button-one" (click)="slickController.prev()">
                      <img class="cursorPointer"  src="../../assets/images/sign-up-to-service/previous (1).png" alt="previous" >
                    </button>
                    <button  class="mx-1 button-two" (click)="slickController.next()">
                      <img class="cursorPointer" src="../../assets/images/sign-up-to-service/next (1).png" alt="next" >
                    </button>
                  </div>
              </div>
            </div>
          </div> 
          </div>


          <!-- more Items to explore -->          
          <div id="scrollItems" #exploreItems class="more-items-explore  mx-lg-4 mx-3 my-4 pb-4">
            <div  (scroll) ="scrollHandler()" class="items-tag py-3 d-flex justify-content-between align-items-center">
              <div>
                <p class="px-4 mt-3  d-inline-block">More Items To Explore</p>
              </div>
                <div class="d-lg-block d-none">
                  <div *ngIf = "initialLosseValue === 'furniture' ">
                    <img class="cursorPointer" (click) = "previousButton('furniture')"  src="../../assets/images/sign-up-to-service/previous (1).png" alt="previous" >
                    <img class="cursorPointer" (click) = "nextButton('furniture')" src="../../assets/images/sign-up-to-service/next (1).png" alt="next" >
                  </div>
                  <div *ngIf = "initialLosseValue === 'appliances' ">
                    <img class="cursorPointer" (click) = "previousButton('appliances')"  src="../../assets/images/sign-up-to-service/previous (1).png" alt="previous" >
                    <img class="cursorPointer" (click) = "nextButton('appliances')" src="../../assets/images/sign-up-to-service/next (1).png" alt="next" >
                  </div>
                </div>
            </div>
            
            <div class="pl-4 mb-4">
              <span (click) ="looseFurniture('furniture')" [ngClass] = "{'activeMore' : initialLosseValue == 'furniture'  }" class="cursorPointer initial-class">Loose Furniture</span>
              <span (click) ="looseFurniture('appliances')" [ngClass] = "{'activeMore' : initialLosseValue == 'appliances'  }" class="pl-4 cursorPointer initial-class">Appliances</span>
            </div>

            <div class="row justify-content-center mx-lg-2">
                <ng-container *ngFor ="let data of exploreMoreItems">
                <div class="col-lg-2 col-5 mb-lg-0 mb-3 px-lg-3 px-1">
                    <div class="card h-100" style="border: 1px solid rgba(0,0,0,.125);">
                    <img class="card-img-top" src="{{data.image}}" onerror="src='../../assets/images/carosel-icons/no-image-with-out-border.png'" alt="Card image cap">
                    <div class="card-body" style="background-color: #f4f5f7;">
                      <p style="font-size: 14px;" class="card-title font-weight-bold">{{data.name}}</p>
                      <p class="numbers">{{data.price  | currency :'INR':'symbol':'1.0-0'}}</p>
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="d-lg-none d-block">
                <div *ngIf = "initialLosseValue === 'furniture' ">
                  <img class="cursorPointer" (click) = "previousButton('furniture')"  src="../../assets/images/sign-up-to-service/previous (1).png" alt="previous" >
                  <img class="cursorPointer" (click) = "nextButton('furniture')" src="../../assets/images/sign-up-to-service/next (1).png" >
                </div>
                <div *ngIf = "initialLosseValue === 'appliances' ">
                  <img class="cursorPointer" (click) = "previousButton('appliances')"  src="../../assets/images/sign-up-to-service/previous (1).png" alt="previous" >
                  <img class="cursorPointer" (click) = "nextButton('appliances')" src="../../assets/images/sign-up-to-service/next (1).png" alt="next" >
                </div>
              </div>
            </div>
            <div *ngIf ="loader_explore" style="position: absolute;" class="loader-progrees">
              <div class="row loader mt-4 bg-white border-outer align-items-center">
                <div class="col-12 d-flex justify-content-center">
                  <div class="ss-spinner ss-spinner--orange mt-0">
                    <div class="ss-spinner__item1"></div>
                    <div class="ss-spinner__item2"></div>
                    <div class="ss-spinner__item3"></div>
                    <div class="ss-spinner__item4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

           <!-- project directory -->
           <div  id="rwerwe">
            <div  (scroll) ="scrollHandler()" class="project-directory mx-lg-4 mx-3 my-4">
              <div class="d-inline-block my-3" style="border-bottom: 2px solid #8D0528;">
                <p class="mb-0 mx-3" style="margin-bottom: 0.5rem; font-size: 16px; color: #8D0528; font-weight: 700;">My Inspiration Board</p>
              </div>
              <div class="mx-0 mx-md-4">
                <div class="card-upload">
                  <div class="row">
                    <ng-container *ngIf="Inspirations">
                      <div
                        class="col-6 col-md-6 col-lg-3 my-3 inspirations"
                        *ngFor="let ins of Inspirations"
                      >
                        <a
                          *ngIf="
                            ins.file_info &&
                            ins.file_info.content_type !== 'application/pdf' &&
                            ins.file_info.content_type !== 'application/msword'
                          "
                          href="{{ ins.file_url }}"
                          target="_blank"
                          download
                        >
                          <img
                            *ngIf="ins.file_info.url"
                            src="../../../assets/images/file.png"
                          />
                          <p *ngIf="ins.file_info.file_name" class="file-name">
                            {{ ins.file_info.file_name }}
                          </p>
                        </a>
                        <a
                          *ngIf="
                            ins.file_info &&
                            ins.file_info.content_type == 'application/pdf'
                          "
                          href="{{ ins.file_url }}"
                          target="_blank"
                          download
                        >
                          <img src="../../../assets/images/placeholder-pdf.jpeg" />
                          <p *ngIf="ins.file_info.file_name" class="file-name">
                            {{ ins.file_info.file_name }}
                          </p>
                        </a>
                        <a
                          *ngIf="
                            ins.file_info &&
                            ins.file_info.content_type == 'application/msword'
                          "
                          href="{{ ins.file_url }}"
                          target="_blank"
                          download
                        >
                          <img src="../../../assets/images/6818351_preview.png" />
                          <p *ngIf="ins.file_info.file_name" class="file-name">
                            {{ ins.file_info.file_name }}
                          </p>
                        </a>
                      </div>
                    </ng-container>
                    
                    <ng-container *ngIf ="Inspirations">
                      <div class="col-6 col-md-6 col-lg-3 my-3" *ngIf="Inspirations.length == 0 && !editable">
                        <p>No files are Uploaded.</p>
                      </div>
                    </ng-container>


                    <div class="col-6 col-md-6 col-lg-3 mb-3 upload-insio">
                      <div *ngIf="editable" class="file-uploadsins p-4" (click)="files2.click()">
                        <img src="../assets/images/Union.png" />
                        <p>Add Files</p>
                        <form [formGroup]="fileuploadForm">
                          <input
                            type="file"
                            accept="image/x-png,image/gif,image/jpeg,application/pdf,application/msword"
                            name="profile"
                            (change)="onChange($event)"
                            #files2
                            class="custom-checkbox-input inputfile clearField"
                          />
                          <label for="file">Upload your files JPG, PDF/Doc</label>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             </div>
          </div>


          <!-- Terms and conditions -->  
          <div  id="termstop">
            <img src="../../assets/images/termsandconditions/T_and_C-0006.jpg" class="img-fluid w-100">
            <img src="../../assets/images/termsandconditions/T_and_C-0007.jpg" class="img-fluid w-100">
            <img src="../../assets/images/termsandconditions/T_and_C-0008.jpg" class="img-fluid w-100">
            <img src="../../assets/images/termsandconditions/T_and_C-0009.jpg" class="img-fluid w-100">
            <img src="../../assets/images/termsandconditions/T_and_C-0010.jpg" class="img-fluid w-100">
            <img src="../../assets/images/termsandconditions/T_and_C-0011.jpg" class="img-fluid w-100">
            <img src="../../assets/images/termsandconditions/T_and_C-0012.jpg" class="img-fluid w-100">
            <img src="../../assets/images/termsandconditions/T_and_C-0013.jpg" class="img-fluid w-100">
            <img src="../../assets/images/termsandconditions/T_and_C-0014.jpg" class="img-fluid w-100">
              
          </div>
      
      
    

  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModalLong"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLongTitle"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      role="document"
      style="max-width: 80%; min-height: 600px"
    >
      <div class="modal-content modal-bg" style="height: 600px">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-form"></div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="exampleModalLong2"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLongTitle"
    aria-hidden="true"
  >
    <div
      class="modal-dialog"
      role="document"
      style="max-width: 90%; min-height: 500px"
    >
      <div
        class="modal-content modal-bg"
        style="height: 800px; padding-bottom: 40px"
      >
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="padding-bottom: 40px">
          <img src="{{ imgfile }}" style="height: 600px; width: 100%" />
        </div>
        <div class="modal-footer">
          <button
            style="margin-left: 80%"
            type="button"
            class="btn btn-cancel"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button type="button" class="btn btn-getotp" (click)="deletefile()">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="exampleModalLong3"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLongTitle"
    aria-hidden="true"
  >
    <div
      class="modal-dialog downl-modal"
      role="document"
      style="max-width: 30%; min-height: 500px"
    >
      <div
        class="modal-content modal-bg"
        style="height: 400px; padding-bottom: 40px"
      >
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" style="padding-bottom: 40px">
          <img
            src="../../../assets/images/no_image (1).svg"
            style="width: 100%; height: 200px"
          />
        </div>
        <div class="modal-footer">
          <a
            target="_blank"
            href="{{ imgfile }}"
            style="margin-left: -30%"
            class="btn btn-getotp view-pdf"
            >View PDF</a
          >
          <button
            style="position: relavtive; left: -5%"
            type="button"
            class="btn btn-cancel"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-getotp Deletepdf"
            (click)="deletefile()"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="boqCheckModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-keyboard="false"
    data-backdrop="static"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header border">
          <h5
            class="modal-title"
            id="exampleModalLabel"
            style="font-family: 'algerian' !important"
          >
            CHOOSE BOQ PDF FORMAT
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="clearCheckBox()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <label class="mr-3">
                <input
                  type="checkbox"
                  value="boq"
                  (change)="selectPdfFormat($event)"
                  name="check1"
                  [(ngModel)]="isChecked1"
                />
                BOQ
              </label>
              <label class="mr-3">
                <input
                  type="checkbox"
                  value="annexure"
                  (change)="selectPdfFormat($event)"
                  name="check2"
                  [(ngModel)]="isChecked2"
                />
                ANNEXURE
              </label>
              <label>
                <input
                  type="checkbox"
                  value="summary"
                  (change)="selectPdfFormat($event)"
                  name="check3"
                  [(ngModel)]="isChecked3"
                />
                SUMMARY
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary cursor-pointer btn-close-download"
            data-dismiss="modal"
            (click)="clearCheckBox()"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-download-pdf"
            (click)="downloadCustomerBoqPdf('')"
          >
            Download Customer BOQ pdf
          </button>
        </div>
      </div>
    </div>
  </div>

<ng-template  #bookingFormotp let-modal >
    <div class="modal-dialog bookingForm" role="document">
      <div class="modal-content border-0">
        <div *ngIf="editable" class="modal-body" style="border-top: none !important">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true"
              ><img class="closeImg" src="../assets/images/closebooking.png"
            /></span>
          </button>
          <div class="opt-form">
            <h4>Your Booking Form</h4>
            <p class="otpverification">
              Your mobile number will get OTP for verification.
            </p>
            <input
              type="number"
              class="mobile-number-text p-2 w-75"
              placeholder="Enter Mobile Number"
              [(ngModel)]="otpRecievedMobile"
              disabled
            />
          </div>
          <div class="justify-content-start modal-footer p-0 mt-4">
            <!-- <button
              *ngIf="!isotpApproved"
              (click)="getDownloadBookingform()"
              class="action-btn-view px-4 py-1"
              data-dismiss="modal"
            >
              Download Booking Form
            </button> -->
            <!-- <a  *ngIf="isotpApproved" target="_blank" (click)="downloadbooking()"  style="left: -31px;width: 230px;padding-bottom: 10px"  class="btn btn-getotp" 
        href="{{customerTimeline.timeline.signed_booking_form_details.url}}">Download Signed Booking Form</a>
         -->
            <!-- <button
              type="button"
              class="action-btn-view px-4 py-1"
              data-dismiss="modal"
            >
              Cancel
            </button> -->
            <button
              *ngIf="!isotpApproved"
              [disabled]="otpRecievedMobile == null"
              type="button"
              class="action-btn-view px-4 py-1"
              (click)="getotverification(bookingFormverification)"
            >
              Get OTP
            </button>
            <button
              *ngIf="isotpApproved"
              type="button"
              class="downloadBtn px-4 py-1"
            >
              OTP Already Verified
            </button>
          </div>
        </div>
        <div *ngIf="!editable" class="modal-body p-4" style="border-top: none !important">
          <div class="opt-form mt-4 text-center">
            <img src="../assets/images/Pending_icon.png" />
            <h4 class="booking-area text-center">Verification Pending</h4>
            <p class="otpverification text-center">
              Customer has not completed verification. But you can download Booking Form.
            </p>
          </div>
          <div class="text-center">
            <button
              *ngIf="!isotpApproved"
              (click)="getDownloadBookingform()"
              class="action-btn-view px-4 py-1 m-auto"
              data-dismiss="modal"
            >
              Download Booking Form
            </button>
          </div>
        </div>
      </div>
    </div>
  <!-- </div> -->
  </ng-template>
  
<!-- 
  class="modal bookingFormmodal fade"
  style="margin-top: 140px !important"
  id="bookingFormverification"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true" -->

  <ng-template #bookingFormverification let-modal
  >
    <div class="modal-dialog bookingForm" role="document">
      <div class="modal-content" style="background: #fafafa ; border: none;">
        <div class="modal-body" style="border-top: none !important">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
          >
            <span aria-hidden="true"
              ><img class="closeImg" src="../assets/images/closebooking.png"
            /></span>
          </button>
          <div>
            <h4>Enter OTP Send to {{ otpRecievedMobile }}</h4>
            <p class="otpverification">
              Enter your received OTP to your mobile number for verification.
            </p>
            <input
              type="number"
              class="mobile-number-text"
              placeholder="Enter Otp"
              [(ngModel)]="otp"
              onKeyPress="if(this.value.length==6) return false;"
            /><br />
            <span class="otp-not-recieved"
              >Not received your OTP?<span
                class="resend-otp"
                (click)="otpresend()"
              >
                Resend OTP</span
              ></span
            >
          </div>
          <div class="modal-footer pb-0 mt-3">
            <!-- <button type="button" class="btn-cancel left0" data-dismiss="modal">
              Cancel
            </button> -->
            <button
              type="button"
              class="action-btn-view px-4 py-1"
              (click)="getverification(bookingFormdownload)"
            >
              Verify
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>


  <ng-template #bookingFormdownload let-modal >
    <div class="modal-dialog bookingForm" role="document">
      <div class="modal-content" style="background: #fafafa ; border: none;">
        <div class="modal-body p-4" style="border-top: none !important">
          <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true"
            ><img class="closeImg" src="../assets/images/closebooking.png"
          /></span>
        </button>
          <div class="opt-form mt-4 text-center">
            <img src="../assets/images/verified.png" />
            <h4 class="booking-area text-center">Verification Successful</h4>
            <p class="otpverification text-center">
              You have completed verification. You can download booking form.
            </p>
          </div>
          <div class="text-center">
            <button
              class="action-btn-view mt-4 mx-auto px-4 py-2"
              (click)="downloadbooking2()"
            >
              Download Booking Form
            </button>
          </div>
        </div>
      </div>
    </div>
</ng-template>

  <div class="loading d-flex justify-content-center" *ngIf="loader">
    <div class="spinner-border" style="color: #8d0528" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>

<ng-template #closeUpdateModal let-modal>
    <div class="modal-content modal-change">
      <div class="modal-header bgf0f1f5 align-items-center">
        <h4 class="modal-title">{{ modalTypeData }} Date details</h4>
        <div class="iconbox mr-3">
          <button
            type="button"
            class="close close-icon p-0"
            (click)="modal.dismiss('Cross click')"
          >
            &times;
          </button>
        </div>
      </div>
      <div class="modal-body modal-body-change px-0">
        <div class="row justify-content-center mx-0">
          <div class="col-md-11">
            <div class="row mt-2">
              <div class="col-md-5 col-6 text-nowrap">Current Expected Date:</div>
              <div class="col-md-7 col-6 detailscolor fw6 numbers">
                {{ modalDateData | date: "dd/MM/yyyy h:mm a" }}
              </div>
            </div>
            <form>
              <div class="row mt-2">
                <label  class="col-md-5 col-form-label"
                  >Choose New Date:<span class="astric text-danger"
                    >*</span
                  ></label
                >
                <div class="col-md-7">
                  <input
                    type="datetime-local"
                    class="form-control"
                    [ngClass]="{ borderRed: newDateErr }"
                    required="true"
                    name="newDate"
                    value="{{ newDate }}"
                    min="{{ todayDate }}"
                    (change)="reasonNewDateHandler($event)"
                  />
                </div>
              </div>
              <div class="row mt-2">
                <label class="col-md-5 col-form-label"
                  >Notes:<span class="astric text-danger">*</span></label
                >
                <div class="col-md-7">
                  <input
                    type="text"
                    class="form-control"
                    [ngClass]="{ borderRed: notesValErr }"
                    required="true"
                    name="notesVal"
                    value="{{ notesVal }}"
                    (change)="reasonNotesHandler($event)"
                  />
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12 col-6">
                  <button
                    class="br_5 float-right primaryBtn px-3 py-1 mt-2"
                    (click)="rescheduleHandlerHandler()"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
</ng-template>


  <ng-template #paymentModal let-modal>
      <div class="modal-content modal-change">
        <div class="modal-header bgf0f1f5 align-items-center">
          <h4 class="modal-title"> Payment Details</h4>
          <div class="iconbox mr-3">
            <button type="button" class="close close-icon p-0" (click)="modal.dismiss('Cross click'); clearMessages() ">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div class="modal-body modal-body-change p-4">
          <div class="DivForForm mx-5 my-4">
            <form  [formGroup]="customerPaymentForm">
              <div class="form-group">
                <label for="exampleInputEmail1">Amount</label>
                <input   [min]="minVal" [max] = 'maxVal' type="number" class="form-control" formControlName="amount" id="Amount" aria-describedby="emailHelp" placeholder="Enter Amount">
                <!-- <ng-container *ngIf="submitform">
                  <span *ngIf="customerPaymentForm.controls.amount.hasError('min')  || customerPaymentForm.controls.amount.errors ||  customerPaymentForm.controls.amount.hasError('max') ">
                    <small class="text-danger">Please enter a amount greater than 1000 or less than 10 lakh</small>
                  </span>
                </ng-container> -->
                <ng-container *ngIf ="messageForthreekTenK">
                  <small style="font-family: sans-serif;" class="text-danger">{{DisplayErrormsg}}</small>
                </ng-container>
              </div>
              
              <div class="form-group">
                <label for="exampleInputPassword1">Email</label>
                <input type="email" class="form-control" formControlName="email"  id="exampleInputPassword1" placeholder="Enter email">
                <ng-container *ngIf="submitform">
                  <small class="text-danger"  *ngIf="customerPaymentForm.controls.email.errors ">Email must be a valid email address</small>
                </ng-container>
              </div>
          
              <div class="form-group">
                <label for="exampleInputPassword1">Phone Number</label>
                <input onKeyPress="if(this.value.length==10) return false;" type="number" class="form-control numbers" formControlName="mobile_number" id="exampleInputPassword1" placeholder="Enter Phone Number">
                <ng-container *ngIf="submitform">
                  <small class="text-danger" *ngIf="customerPaymentForm.controls.mobile_number.status === 'INVALID' ">Please enter valid phone number</small>
                </ng-container>
              </div>
              <button  id="tenForty" *ngIf ="payment_stage != 'final_payment' " [disabled]="!customerPaymentForm.valid"   (click)="checkThreepercenttenkValidation()" style="background-color: #8D0528;" type="submit" class="btn btn-primary mb-3 w-100">Pay Now</button>
              <button  id="finalPayment" *ngIf ="payment_stage === 'final_payment' " [disabled]="!customerPaymentForm.valid"   (click)="paymentSubmit()" style="background-color: #8D0528;" type="submit" class="btn btn-primary mb-3 w-100">Pay Now</button>
            </form>
          </div>
        </div>
      </div>
  </ng-template>
