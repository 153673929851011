import { Component, OnInit } from '@angular/core';
declare function fbClick(): any;
declare function igClick(): any;
declare function liClick(): any;
declare function piClick(): any;

@Component({
  selector: 'app-aboutusone',
  templateUrl: './aboutusone.component.html',
  styleUrls: ['./aboutusone.component.scss'],
})
export class AboutusoneComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    this.loadScript()
  }

  fbClick() {
    fbClick();
  }

  igClick() {
    igClick();
  }

  liClick() {
    liClick();
  }

  piClick(){
    piClick();
  }
  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
