import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { BookConsultationFormComponent } from '../../shared/book-consultation-form/book-consultation-form.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

declare var $: any;
@Component({
  selector: 'app-our-project-gallery-updatedd',
  templateUrl: './our-project-gallery-updatedd.component.html',
  styleUrls: ['./our-project-gallery-updatedd.component.scss'],
})
export class OurProjectGalleryUpdateddComponent implements OnInit {
  constructor(private cdRef: ChangeDetectorRef,private modalService: NgbModal,
    ) {}

  items: any = [
    {
      image:
        '../../../assets/images/Landing_page_updated/project_gallery/Varinder_bansal/image (5).png',
      apartmentName: 'Ashok Towers',
      bhkType: '3BHK',
      area: '1200',
      city: 'Mumbai, Maharashtra',
      space: ['Contemporary', 'Full Home Interior', 'Love Vibes'],
      spaceLink: ['contemporary', 'full_home_interior', 'love_vibes'],
      name: 'Mr. Varinder Shinde',
      content:
        '2 people who were an important part of this journey when we built this home were my wife and the designer. One thing that distinguishes Arrive is their design team.They are helping you with every aspect of your thought and think like you will think. The team gives you several ideas and not just one. ',
    },
    {
      image:
        '../../../assets/images/Landing_page_updated/project_gallery/Varinder_bansal/image (6).png',
      apartmentName: 'Ashok Towers',
      bhkType: '3BHK',
      area: '1200',
      city: 'Mumbai, Maharashtra',
      space: ['Contemporary', 'Full Home Interior', 'Love Vibes'],
      spaceLink: ['contemporary', 'full_home_interior', 'love_vibes'],
      name: 'Mr. Varinder Shinde',
      content:
        '2 people who were an important part of this journey when we built this home were my wife and the designer. One thing that distinguishes Arrive is their design team.They are helping you with every aspect of your thought and think like you will think. The team gives you several ideas and not just one. ',
    },
    {
      image:
        '.../../../assets/images/Landing_page_updated/project_gallery/Varinder_bansal/image (7).png',
      apartmentName: 'Ashok Towers',
      bhkType: '3BHK',
      area: '1200',
      city: 'Mumbai, Maharashtra',
      space: ['Contemporary', 'Full Home Interior', 'Love Vibes'],
      spaceLink: ['contemporary', 'full_home_interior', 'love_vibes'],
      name: 'Mr. Varinder Shinde',
      content:
        '2 people who were an important part of this journey when we built this home were my wife and the designer. One thing that distinguishes Arrive is their design team.They are helping you with every aspect of your thought and think like you will think. The team gives you several ideas and not just one. ',
    },
    {
      image:
        '../../../assets/images/Landing_page_updated/project_gallery/Varinder_bansal/image (8).png',
      apartmentName: 'Ashok Towers',
      bhkType: '3BHK',
      area: '1200',
      city: 'Mumbai, Maharashtra',
      space: ['Contemporary', 'Full Home Interior', 'Love Vibes'],
      spaceLink: ['contemporary', 'full_home_interior', 'love_vibes'],
      name: 'Mr. Varinder Shinde',
      content:
        '2 people who were an important part of this journey when we built this home were my wife and the designer. One thing that distinguishes Arrive is their design team.They are helping you with every aspect of your thought and think like you will think. The team gives you several ideas and not just one. ',
    },
    {
      image:
        '../../../assets/images/Landing_page_updated/project_gallery/Varinder_bansal/livign.png',
      apartmentName: 'Ashok Towers',
      bhkType: '3BHK',
      area: '1200',
      city: 'Mumbai, Maharashtra',
      space: ['Contemporary', 'Full Home Interior', 'Love Vibes'],
      spaceLink: ['contemporary', 'full_home_interior', 'love_vibes'],
      name: 'Mr. Varinder Shinde',
      content:
        '2 people who were an important part of this journey when we built this home were my wife and the designer. One thing that distinguishes Arrive is their design team.They are helping you with every aspect of your thought and think like you will think. The team gives you several ideas and not just one. ',
    },
    {
      image:
        '../../../assets/images/Landing_page_updated/project_gallery/02 Ronald Noronha/Temp-673x460 (8).jpg',
      apartmentName: 'Western Heights',
      apartmentLink: '#',
      bhkType: '3BHK',
      area: '806',
      city: 'Mumbai, Maharashtra',
      space: ['Contemporary', 'Full Home Interior', 'Happy Vibes'],
      spaceLink: ['contemporary', 'full_home_interior', 'happy_vibes'],
      name: 'Mrs. Ronal Noronha',
      content:
        'The customized furniture came out very well, also the bed, it came out very well. It is a hydraulic bed and not a normal bed.',
    },
    {
      image:
        '../../../assets/images/Landing_page_updated/project_gallery/02 Ronald Noronha/Temp-673x460 (9).jpg',
      apartmentName: 'Western Heights',
      apartmentLink: '#',
      bhkType: '3BHK',
      area: '806',
      city: 'Mumbai, Maharashtra',
      space: ['Contemporary', 'Full Home Interior', 'Happy Vibes'],
      spaceLink: ['contemporary', 'full_home_interior', 'happy_vibes'],
      name: 'Mrs. Ronal Noronha',
      content:
        'The customized furniture came out very well, also the bed, it came out very well. It is a hydraulic bed and not a normal bed.',
    },
    {
      image:
        '../../../assets/images/Landing_page_updated/project_gallery/02 Ronald Noronha/Temp-673x460 (10).jpg',
      apartmentName: 'Western Heights',
      apartmentLink: '#',
      bhkType: '3BHK',
      area: '806',
      city: 'Mumbai, Maharashtra',
      space: ['Contemporary', 'Full Home Interior', 'Happy Vibes'],
      spaceLink: ['contemporary', 'full_home_interior', 'happy_vibes'],
      name: 'Mrs. Ronal Noronha',
      content:
        'The customized furniture came out very well, also the bed, it came out very well. It is a hydraulic bed and not a normal bed.',
    },
  ];

  active_slide: any = 0;
  ngOnInit(): void {
    this.initSlickSlide();
    this.loadScript()
  }

  initSlickSlide() {
    $(document).ready(function () {
      $('.your-class').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: $('#next-gallery-button'),
        prevArrow: $('#previous-gallery-button'),
        autoplay: true,
      });
    });
    $('.your-class').on(
      'afterChange',
      (event: any, slick: any, currentSlide: any, nextSlide: any) => {
        var current = currentSlide;
        this.active_slide = current;
        this.cdRef.detectChanges();
      }
    );
  }
  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  bookConsultationLandingPage() {
    const modal = this.modalService.open(BookConsultationFormComponent, {
      size: 'md',
    });
  }
}
