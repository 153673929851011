<div class="Reviewsection w-100">
  <app-new-header-for-home></app-new-header-for-home>
  <div id="banner-image" class="banner-image mt-md-5  mx-md-0 mx-3">
    <div class="w-100 banner-imagew pb-md-4 pb-0 d-flex justify-content-lg-between align-items-end">
      <div class="tagline pl-md-5 pl-4  ml-lg-5 mb-md-3 ">
        <p class="text-dark">
          We'll let our <br>
          Happy Clients do <br>
          the Talking!<br>
        </p>
        <button class="get-price button_book d-none d-md-block my-5" (click)="bookConsultationLandingPage()">GET THE
          BEST PRICE</button>
        <div class="jayaho_logo d-block ">
        </div>
      </div>
    </div>
  </div>

  <div class="px-lg-5 select-filters w-100">
    <div class="col-lg-11" style="margin: auto;">
      <div class="row m-1 mt-3 my-lg-5 applybackground">
        <div class="col-lg-12 justify-content-center pb-4">
          <div *ngIf="roletoSet() > 650" class="row justify-content-center">

            <div class="col-lg-2 col-0">

            </div>

            <div class="col-lg-3 theme my-lg-4 mx-lg-0 mx-3 align-self-center">
              <div class="row">
                <div class="col-lg-12 my-lg-1">
                  <p class="mb-lg-1 mb-0 mt-lg-0 mt-4 text-center heading">City</p>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 pr-lg-0 align-self-center search align-self-center">
                  <label class="d-lg-block d-none" style="position: absolute; padding: 0.3rem 1rem;">
                    <img src="../../../assets/images/236-2363361_search-icon-pin-gray-location-icon-png 1.png" />
                  </label>
                  <select class="w-100  Select_bg bg_pos text-center cursorPointer" (change)="cityselected($event)" [ngModel]="city">
                    <option value="">All</option>
                    <ng-container *ngFor="let item of cities">
                      <option class="text-capitalize" [value]=item>{{item}}</option>
                    </ng-container>
                  </select>
                 
                </div>
              </div>
            </div>

            <div class="col-lg-5 theme my-lg-4 mx-lg-0 mx-3 align-self-center">
              <div class="row">
                <div class="col-lg-12 my-lg-1">
                  <p class="mb-lg-1  mb-0 mt-lg-0 mt-4 text-center heading">Apartment</p>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12 pr-lg-0 align-self-center search align-self-center">
                  <select class="w-100  text-center Select_bg cursorPointer" (change)="apartmentfilter($event)" [ngModel]="apartment">
                    <option value="">All</option>
                    <ng-container *ngFor="let item of apartments">
                      <option class="text-capitalize" [value]=item>{{item}}</option>
                    </ng-container>
                  </select>
                 
                </div>
              </div>
            </div>

            <div class="col-lg-2 align-self-center mt-4 pt-1">
              <button (click)="clearfilters()" title="clear filters" class="btn p-0 btn-Hov2 cursorPointer px-2 py-1" style="font-size: Raleway;">
                Reset
              </button>
            </div>
          </div>
          <div  *ngIf="roletoSet() < 650" class="row justify-content-center">

          <div class="w-100 mx-4 pt-3 pb-1 row">

            <select class="w-100 px-lg-3 mt-3 mx-3 py-3 px-2 mobileSelect text-lef cursorPointer" (change)="cityselected($event)" [ngModel]="city">
              <option selected disabled value="">City</option>
              <option  value="">All</option>
              <ng-container *ngFor="let item of cities">
                <option class="text-capitalize" [value]=item>{{item}}</option>
              </ng-container>
            </select>
           
            <select class="w-100  mx-3 mt-3 py-3 px-2 mobileSelect text-left cursorPointer" (change)="apartmentfilter($event)" [ngModel]="apartment">
              <option selected disabled value="">Apartment</option>
              <option  value="">All</option>
              <ng-container *ngFor="let item of apartments">
                <option class="text-capitalize" [value]=item>{{item}}</option>
              </ng-container>
            </select>


            <div class="w-100 mt-3 d-flex justify-content-center">
              <button (click)="clearfilters()" title="clear filters" class="btn p-0 btn-Hov2 cursorPointer px-2 py-1">
                Reset
              </button>
            </div>

          
          

          </div>

            
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="reviews pb-3 col-12 mx-md-0">
    <div class="header pt-5">
      <h2>WE'LL LET OUR HAPPY <br>CLIENTS DO THE TALKING!</h2>
    </div>

    <div *ngIf="roletoSet() > 650" class="row justify-content-center d-flex flex-column">
    <div class="row container d-flex justify-content-around py-3">
      <ng-container *ngFor="let item of reviews">
        <div class="card col-md-4 mt-5" (click)="OpenInDeatil(item.id)">
          <img [src]=item.attributes.feature_url class="cursorPointer" alt="">
          <p class="rating cursorPointer pt-2 m-0">
            <span *ngFor="let star of [1, 2, 3, 4, 5]" class="star px-1"
              [class.active]="star <= item.attributes.rating"><i class="fa fa-star"></i></span>
          </p>
          <p class="name m-0 py-2 cursorPointer"> {{item.attributes.customer_name}}</p>
          <p class="review truncate cursorPointer" style="height: 76px;">
            {{item.attributes.review}}
          </p>
          <button class="btn p-0 button cursorPointer btn-pink">Know more</button>
        </div>
      </ng-container>
    </div>

    <div  class="d-flex justify-content-center my-5">
      <button *ngIf="allreviews.length != size && this.morereviews.length==0" class="mt-5 btn btn-pink view-more" (click)="addreviews(false)">VIEW MORE HOMES</button>
      <button *ngIf="allreviews.length != size && this.morereviews.length>0" class="mt-5 btn btn-pink view-more" (click)="addreviews(true)">VIEW MORE HOMES</button>
    </div>
    </div>

    <div *ngIf="roletoSet() < 650" class="row justify-content-center">
    <div class="row container d-flex justify-content-around">
      <ng-container *ngFor="let item of reviews2">
        <div class="card col-md-4 mt-5" (click)="OpenInDeatil(item.id)">
          <img [src]=item.attributes.feature_url class="cursorPointer" alt="">
          <p class="rating cursorPointer pt-2 m-0">
            <span *ngFor="let star of [1, 2, 3, 4, 5]" class="star px-1"
              [class.active]="star <= item.attributes.rating"><i class="fa fa-star"></i></span>
          </p>
          <p class="name m-0 py-2 cursorPointer"> {{item.attributes.customer_name}}</p>
          <p class="review truncate cursorPointer">
            {{item.attributes.review}}
          </p>
          <button class="btn btn-pink button p-0 cursorPointer ">Know more</button>


        </div>
      </ng-container>
    </div>
    <div *ngIf="(allreviews.length == size2 && morereviews.length > allreviews.length) || allreviews.length != size2" class="d-flex justify-content-center align-items-center my-5">
      <button *ngIf="this.morereviews.length==0" class="btn btn-pink view-more2" (click)="addreviews2(false)">VIEW MORE HOMES&nbsp;
        <span>
          <img src="../../../assets/images/Arrow 1.png"/>
       </span>
      </button>
      <button *ngIf="!(this.morereviews.length==0)" class=" btn btn-pink view-more2" (click)="addreviews2(true)">VIEW MORE HOMES&nbsp;
        <span>
          <img src="../../../assets/images/Arrow 1.png"/>
       </span>
      </button>

    </div>
    </div>
  </div>


  <app-madeby-you-stories-new></app-madeby-you-stories-new>
  <app-frequent-questions></app-frequent-questions>
  <app-footer></app-footer>
</div>







<div class="loading d-flex justify-content-center" *ngIf="loader">
  <div class="spinner-border" style="color: #8d0528;" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>