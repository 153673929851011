import { Component, OnInit ,  ElementRef, ViewChild,OnDestroy } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../authentication/authentication.service';
import { ToastrService } from 'ngx-toastr';
declare var $:any


@Component({
  selector: 'app-cheque-payment',
  templateUrl: './cheque-payment.component.html',
  styleUrls: ['./cheque-payment.component.scss']
})
export class ChequePaymentComponent implements OnInit {
  @ViewChild("filePicker") myInputVariable: ElementRef | any;
 

  constructor(
    private formBuilder : FormBuilder,
    private landingservice: AuthenticationService,
    private toastr:ToastrService,
  ) {}

  get_payment_token :any
  paymentForm:any
  ngOnInit(): void {
    if(this.roletoSet()> 1300){
      $('body').css('overflow', 'hidden')
    }
    const cheque_payment = window.location.href; 
    let d = cheque_payment.split('&')[0]
    let paymentToken = d.split('=')
    this.get_payment_token = paymentToken[1]
    console.log(this.get_payment_token);    
    this.paymentForm = this.formBuilder.group({
      amount: [ '', Validators.required],
      bank: ["" , Validators.required],
      branch: ["",Validators.required],
      date_of_checque: ["",Validators.required],
      image: [""],
      cheque_number: ["",Validators.required],
      token : this.get_payment_token,
      payment_stage : [""]
    });
    this.fetchAmountPaymentStatus()
  }
  paymentResponse :any
  submitPaymentDetails(){
    this.paymentForm.patchValue({
      image : this.base64textString
    })
    this.landingservice.chequepaymentSubmit(this.paymentForm.value).subscribe(res=> {
      this.paymentResponse = res
      this.toastr.success(this.paymentResponse.message)
      this.paymentForm.reset()
      this.reset()
      this.fetchAmountPaymentStatus()
      console.log(this.paymentResponse.message);
    },(err) => {
      this.toastr.error('Amount entered shold be greater than the Lead payment request amount.')
    })
  }
  base64textString: any;
  _handleReaderLoaded1(readerEvt: any) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
  }

  file_name : any = ""

  onImagePicked(event: any) {
    this.file_name = event.target.files[0].name
    var files = event.target.files;
    var file = files[0];
    if (files && file) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded1.bind(this);
      reader.readAsBinaryString(file);
    }
  }
  reset() {
    this.myInputVariable.nativeElement.value = "";
    this.file_name = ''
  }
  repeatLink :any
  requestNewLink(){
    this.landingservice.resendPaymentLink(this.get_payment_token).subscribe(res => {
      this.repeatLink = res
      this.toastr.success(this.repeatLink.message)
      this.fetchAmountPaymentStatus()
    }, (err) => {
      this.toastr.error('You need to be logged in.')
    })
  }
  paymentStatusGetamount :any
  fetchAmountPaymentStatus(){
    this.landingservice.getAmountpaymentStatus(this.get_payment_token).subscribe(res => {
      this.paymentStatusGetamount = res
      this.paymentForm.patchValue({
        amount : this.paymentStatusGetamount.amount,
        payment_stage : this.paymentStatusGetamount.payment_stage
      })
    })
  }
  roletoSet(){
    var width = Math.max(window.screen.width, window.innerWidth)
     return width

  }
}
