import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-catlouge-apartment',
  templateUrl: './catlouge-apartment.component.html',
  styleUrls: ['./catlouge-apartment.component.scss','../marathon-nexajone.component.scss']
})
export class CatlougeApartmentComponent implements OnInit {

  constructor(
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
  }
  roletoSet() {
    var width = Math.max(window.screen.width, window.innerWidth)
    return width
  }
  
  successmessage(file:any){
    if(this.roletoSet() < 600){
      this.toastr.success('Pdf File is Downloading ...')
  
      setTimeout(() => {
        window.open(file,'_blank')
      }, 2000);
  
     
     
    } else{
      window.open(file,'_blank')
    }
  }
}
