import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-design-themes',
  templateUrl: './design-themes.component.html',
  styleUrls: ['./design-themes.component.scss']
})
export class DesignThemesComponent implements OnInit {

  constructor(private router:Router) { }
  currentUrl : any
  ngOnInit(): void {
    this.currentUrl = window.location.href.split('/')[3]
    this.newLandingPage()
  }

  navigateTo(data:any){

    const host: string =  location.origin;
    const url: string = host  + String(this.router.createUrlTree(['/spaces'], { queryParams: {
      theme_name: data
    } }));
    window.open(url, '_blank');
  }
  newLandingPage(){
    if(this.currentUrl == 'new-landing'){
      return true
    }else{
      return false
    }
  }
  

}
