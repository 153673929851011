import { Component, OnInit ,Input } from '@angular/core';


@Component({
  selector: 'app-visitus-slider',
  templateUrl: './visitus-slider.component.html',
  styleUrls: ['./visitus-slider.component.scss']
})
export class VisitusSliderComponent implements OnInit {
 

 
  constructor() { }
   
  ngOnInit(): void {
    this.loadScript()
  }
  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
   


 

}