import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { Slick } from 'ngx-slickjs';
declare var Instafeed :any;

@Component({
  selector: 'app-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.scss']
})
export class InstagramComponent implements OnInit {

  constructor(private service :AuthenticationService) { }

  InstaData:any =[]

  ngOnInit(): void {


    this.GetInsta()


  }

  config: Slick.Config = {
    centerMode : true,
    centerPadding : '60px',
    infinite: true,
    slidesToShow: 4,
    // slidesToScroll: 1,
    // dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    draggable: true,
    mouseWheelMove: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding : '40px',
          arrows : false
        },
      },
    ],
  };

GetInsta(){
  this.service.getInsta().subscribe(rws=>{
    console.log(rws,"hgfd")

    this.InstaData = rws;


    this.InstaData = this.InstaData.data;
  })

}

roletoSet(){
  var width = Math.max(window.screen.width, window.innerWidth)
  
   return width
}


}
