
<!-- <div style="display: flex; justify-content: center; align-items: center; height: 100vh;">
    <div>
        <p>pavan</p>
    </div>
</div> -->



<div class="d-flex apply-margin" style="height: 100vh;">
    <div class="m-auto">


      <div  class="text-center error_404">
          <p class="mb-0">ERROR 404</p>
          <hr>
      </div>

      <div  class="text-center page_does_not_exit">
          <p class="mb-2">THIS PAGE DOES NOT EXIST BUT,</p>
          <p>YOUR DREAM HOME DOES</p>
      </div>

      <div class="px-lg-0 px-4">
          <img src="../../../assets/images/error.png" class="img-fluid">
      </div>

      <div class="text-center mt-5 d-flex justify-content-around applying_flex_column">
          <a routerLink = "/">GET STARTED</a>
          <span></span>
          <a routerLink= "/spaces">BROWSE DESIGN IDEAS</a>
          <span></span>
          <a  routerLink = "/">BACK TO HOME</a>
      </div>



    </div>
</div> 