<div class="container-fluid profile-about " >
<div class="row justify-content-center">
    <div class="col-md-10 mt-5 col-12" style="font-size: 22px;">
        <div class="row">
     <div class="col-md-4">   
    <img src="../../../assets/images/Arrivae-Logo-Footer .png" style="height:44px;" alt="Logo" />      
    </div> 
    </div>
  </div>  
</div>
    <div class="row mt-5 justify-content-center">
      <div class="col-12 col-md-10">
        <div class="row">
          <div class="col-md-4 col-xs-12">
            <div class="quick-links mb-3">
              <div class="text-style-1">QUICK LINKS</div>
              <div><a class="quick-links-style" target="_blank" routerLink="/spaces">Interior Design Spaces</a></div>
              <div><a class="quick-links-style" target="_blank" href="../../../assets/images/arrivae_kitchen_buying_guide.pdf">Kitchen Buying Guide</a></div>

              <!-- <div><a class="quick-links-style" target="_blank" href="/kitchen-guide">Wardrobe Buying Guide</a></div> -->
              <div><a class="quick-links-style" target="_blank" href="https://arrivae.com/become_a_design_partner">Become A Design Partner</a></div>
              <div><a class="quick-links-style" target="_blank" href="https://arrivae.com/become_a_manufacturer">Become A Vendor Partner</a></div>
              <!-- <div>Become A Design Partner</div> -->
              <!-- <div>Work With Us</div> -->
              <div><a class="quick-links-style" href="../../../assets/images/Customisation_booklet_A4.pdf" target="_blank">Design Inspiration</a></div>
              <!-- <div><a class="quick-links-style" target="_blank" href="https://arrivae.com/login">Login</a></div> -->
            </div>
          </div>
          <div class="col-md-4 col-xs-12">
            <div class="quick-links">
              <div class="text-style-1">ARRIVAE</div>
              <div><a class="quick-links-style" target="_blank" href="https://arrivae.com/arrivae_stories">About Us</a></div>
              <div><a class="quick-links-style" target="_blank" href="https://arrivae.com/media_engagements">Arrivae In Media</a></div>
              <div><a class="quick-links-style" target="_blank" href="https://arrivae.com/made_by_you_stories">Made By You Stories</a></div>
              <div>
                <a
                  style="color: #000000; cursor: pointer; text-decoration: none;"
                  target="_blank"
                  routerLink="/terms-and-condition"
                  >Terms And Conditions</a
                >
              </div>
              <div>
                <a
                  style="color: #000000; cursor: pointer; text-decoration: none;"
                  target="_blank"
                  routerLink="/terms-and-conditions"
                  >Terms And Conditions</a
                >
              </div>
              
              <div>
                <a
                  style="color: #000000; cursor: pointer; text-decoration: none;"
                  target="_blank"
                  routerLink="/privacy-policy"
                  >Privacy Policy</a
                >
              </div>
              <div class="mb-2"><a class="quick-links-style" target="_blank" href="https://web.arrivae.com/visit-us">Visit Our EC</a></div>
            </div>
          </div>
          <div class="col-md-4 col-xs-12 mb-4">
            <div class="quick-links">
              <div class="text-style-1">CONTACT INFO</div>
              <span>
                B 501/502, Everest House, Suren Road, Gundavali, Andheri East,
                Mumbai, Maharashtra 400093
                <br />
                <a class="href1_text" href="tel:022-48900151">022-48900151</a>&nbsp;<a class="href1_text" href="mailto:wecare@arrivae.com">wecare@arrivae.com</a>
              </span><br />
              <span>
                For any Compliments or Complaints: <a class="href1_text" href="tel:18001230680">18001230680</a>
                <br />
                Timings: 09:00 AM to 09:00 PM
              </span>
              <div class="text-style-1">FOLLOW US ON SOCIAL MEDIA</div>
              <div>
                <a href="https://www.facebook.com/Arrivae/" target="_blank" (click)="fbClick()">
                <span class="ml-1">
                  <img class="social_icons" src="../../../assets/images/fb.svg"
                /></span></a>
                <a href="https://www.instagram.com/arrivae/?hl=en" target="_blank" (click)="igClick()">
                <span class="ml-1"
                  ><img class="social_icons" src="../../../assets/images/instagram.svg"
                /></span></a>
                <a href="https://www.linkedin.com/company/13439634/" target="_blank" (click)="liClick()">
                <span class="ml-1"
                  ><img class="social_icons" src="../../../assets/images/Linkedin.svg"
                /></span></a>
                <a href="https://www.pinterest.com/arrivaecom/" target="_blank" (click)="piClick()">
                  <span class="ml-1"
                    ><img class="social_icons" src="../../../assets/images/Pinterest_Logo.svg"
                  /></span></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

