<div class="nav-wrapper sticky-top" id="nav-wrapper">
    <div class="row navbar-wrapper py-md-2 d-flex justify-content-center lightHeader" >
        <div class="logo ">
            <img class="img-fluid" src="../../../assets/images/logoarrivae logo.svg" alt="logo">
        </div>
    </div>
    <div id="mobile-header" class="mobile-header lightHeader" >
        <div  class="d-flex justify-content-center w-100">
            <img class="img-fluid logoMobile" src="../../../assets/images/logoarrivae logo.svg" alt="logo">
        </div>
    </div>
</div>
