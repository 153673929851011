<app-new-header-for-home ></app-new-header-for-home>
<div class="root mt-5">
    <div class="container-fluid">
        <div class="row rootdiv">
            <div class="col-lg-10">
                <div class="row">
                    <div class="col-md-5 px-lg-5">
                        <img src="../../../assets/images/AboutUs-Main.png" class="imagestyle">
                    </div>
                    <div class="paddingstyle col-md-7">
                        <h3 class="aboutus marginbottom mt-4 mt-lg-0 px-4 px-lg-0">About Us</h3>
                        <p class="building px-4 px-lg-0">
                            Building endless possibilities,<br /> for you and your home.
                        </p>
                        <div class="px-4 px-lg-0">
                            <p class="aboutus1">
                                Arrivae was founded in 2018 to bring about a revolution in the residential interior design process. Reducing the dependency of customers on different contractors, carpenters, products, and other service providers, Arrivae by itself functions as a single
                                source of home interior design needs. From designing, styling, and product sourcing, to project management and aftersales services, Arrivae handles everything for your dream home interiors.
                            </p>
                            <p class="aboutus2">
                                Our brand tagline ‘MADE BY YOU’ reflects our customer-centric design approach and the importance we give to ‘customized spaces.’ We know the value a dream home holds in your life. To ascertain that your dream home is realised the way you want, Arrivae
                                offers you absolute freedom of choice in every aspect of your dream home. Our design expert’s assistance offers you detailed information and addresses all your queries and concerns for your dream home interiors.
                                <span class="responsivepara"> Arrivae believes home designing is for all. It
                                    must be
                                    an exciting, memorable and stress-free process. Our systematic and planned
                                    approach ensures you timely delivery, hassle-free project management, and
                                    regular customer
                                    updates. This lets you focus on your dream home design needs.</span>
                            </p>
                            <p class="aboutus2 d-md-block d-none">
                                Arrivae believes home designing is for all. It must be an exciting, memorable and stress-free process. Our systematic and planned approach ensures you timely delivery, hassle-free project management, and regular customer updates. This lets you focus on
                                your dream home design needs.
                            </p>
                            <p class="aboutus2">
                                Arrivae takes pride in being India’s 1st Made-To-Order furniture manufacturing company. Our factories operate on the Intelligent and Integrated Manufacturing 4.0 process. Bringing to you factory finish furniture with QC tested products which is a value
                                for money and a long term investment.<span class="responsivepara"> Arrivae is an
                                    end-to-end home interior solutions provider and is personal and every
                                    homeowner
                                    is first a homemaker, he or she has dreams, ideas, thoughts, likes and
                                    quirks
                                    that
                                    make every house different from the other, we at Arrivae understand this and
                                    acknowledge it. This is why Arrivae walks the talk by listening to the
                                    thoughts
                                    and ideas of every homeowner and curating and nurturing them
                                    with our expertise and knowledge so every home is perfect and unique.</span>
                            </p>
                            <p class="aboutus2 d-md-block d-none">
                                Arrivae is an end-to-end home interior solutions provider and is personal and every homeowner is first a homemaker, he or she has dreams, ideas, thoughts, likes and quirks that make every house different from the other, we at Arrivae understand this and
                                acknowledge it. This is why Arrivae walks the talk by listening to the thoughts and ideas of every homeowner and curating and nurturing them with our expertise and knowledge so every home is perfect and unique.
                            </p>
                        </div>
                        <hr class="col-md-12 d-sm-block d-md-none customhr">
                        <div class="row mb-3">
                            <div class="col-md-12">
                                <div class="responsive d-sm-block d-md-none">
                                    <p class="visionhead mt-2 px-4">The Arrivae Vision:</p>
                                    <p class="arrivaevision px-4">To be the most desired home design improvement and solutions company.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mb-4 px-0">
                            <div class="stories-root">
                                <iframe class="img-fluid video w-100" src="https://www.youtube.com/embed/FJq7ZZSEERU" frameborder="0"></iframe>
                            </div>
                        </div>
                        <div class="bckground mb-lg-5">
                            <div class="desktop d-md-block d-none">
                                <p class="foundermsg mt-4">
                                    <span>
                                        <img src="../../../assets/images/left-quotes.png"
                                            class="mr-xl-n3 mr-n3 left-quotes" />
                                    </span>The Arrivae Vision:
                                </p>
                                <p class="foundermessage px-lg-5">To be the most desired home design improvement and solutions company.
                                </p>
                            </div>
                            <div class="py-5 py-lg-0">
                                <p class="mission px-4">The Arrivae Mission:</p>
                                <p class="arrivaemission px-4 px-lg-5 mb-lg-2"><span>
                                        <img src="../../../assets/images/left-quotes.png"
                                            class="mr-xl-n3 mr-n3 resp-leftQuotes mb-n4" />
                                    </span>To empower people through HOME designs that make lives easier and better.
                                </p>
                                <p class="arrivaemission px-4 px-lg-5">To democratise designs by making them accessible, affordable and making them feel a part of their ideas and dreams.
                                    <span>
                                        <img src="../../../assets/images/right-quotes.png" class="right-quotes" />
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="third w-100">
            <p class="text-center journey py-4 px-5">
                Our Values
            </p>
            <div class="how-it-works-wrapper">
                <div class="carousel-container">
                    <div class="carousel" id="main-carousel" data-ride="carousel" data-interval="3000">
                        <ol class="carousel-indicators justify-content-around d-flex">
                            <li data-target="#main-carousel" data-slide-to="0" class="active carouselfirst">
                                <p class="valuesone">Keep it simple</p>
                                <img src="../../../assets/images/Polygon 1.png" class="imgFirst" />
                                <hr class="firsthr" />
                            </li>
                            <li data-target="#main-carousel" data-slide-to="1" class="carouselsecond">
                                <p class="titletwo">Innovation and Excellence</p>
                                <img src="../../../assets/images/Polygon 1.png" class="imgSecond" />
                                <hr class="secondhr" />
                            </li>
                            <li data-target="#main-carousel" data-slide-to="2" class="carouselthird">
                                <p class="titlethree">Openness and Transparency</p>
                                <img src="../../../assets/images/Polygon 1.png" class="imgThird" />
                                <hr class="thirdhr" />
                            </li>
                            <li data-target="#main-carousel" data-slide-to="3" class="carouselfourth">
                                <p class="titlefour">Samman: Respect</p>
                                <img src="../../../assets/images/Polygon 1.png" class="imgFourth" />
                                <hr class="fourhr" />
                            </li>
                            <li data-target="#main-carousel" data-slide-to="4" class="carouselfive">
                                <p class="titlefive">Indianness</p>
                                <img src="../../../assets/images/Polygon 1.png" class="imgFive" />
                                <hr class="fivehr" />
                            </li>
                        </ol>

                        <div class="carousel-inner px-0">
                            <div class="carousel-item active" data-interval="3000">
                                <div class="carousel-content">
                                    <div class="content">
                                        <div class="col-12 alignpara">
                                            <div class="row px-1">
                                                <p class="firstoffice">
                                                    Delivering simple and sensible solutions
                                                </p>
                                                <div>
                                                    <p class="firstoffpara col-md-9">
                                                        Home interior design is looked upon as a lengthy and cumbersome process. Arrivae’s predefined processed and methods make your experience not just easy, but exciting too. With our work history, we’ve proved our solutions are sensible, simple yet stylish.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="image">
                                        <img class="img-fluid office-img" src="../../../assets/images/About-us/Keep It Simple - 1.png">
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-interval="3000">
                                <div class="carousel-content">
                                    <div class="content">
                                        <div class="col-12 alignpara">
                                            <div class="row px-1">
                                                <p class="firstoffice">Thinking ahead of time to meet evolving needs
                                                </p>
                                                <div>
                                                    <p class="firstoffpara col-md-9">
                                                        With evolving times and generations, our needs too evolve. Anticipating future trends and best design practices, Arrivae constantly works in the direction to invent and innovate new design solutions for a better lifestyle. Excellence is therefore reflected
                                                        in Arrivae’s every design.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="image">
                                        <img class="img-fluid office-img" src="../../../assets/images/About-us/Innovation and Excellence.png">
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-interval="3000">
                                <div class="carousel-content">
                                    <div class="content">
                                        <div class="col-12 alignpara">
                                            <div class="row px-1">
                                                <p class="firstoffice">Building and strengthening relationships with honesty
                                                    <br /> and sincerity
                                                </p>
                                                <div>
                                                    <p class="firstoffpara col-md-9">
                                                        Sapno Ka Ghar holds a special value in every individual’s heart. We understand what it means and hence we ensure to keep all our crystal clear to make this a seamless and memorable journey for you and your family
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="image">
                                        <img class="img-fluid office-img" src="../../../assets/images/About-us/Openness and Transparency.png">
                                    </div>
                                </div>
                            </div>
                            <div class="carousel-item" data-interval="3000">
                                <div class="carousel-content">
                                    <div class="content">
                                        <div class="col-12 alignpara">
                                            <div class="row px-1">
                                                <p class="firstoffice">Respect in dealing with everyone within and outside
                                                    <br /> the organization
                                                </p>
                                                <div>
                                                    <p class="firstoffpara col-md-9">
                                                        Arrivae is MADE BY YOU – our esteemed customers as well as our highly experienced and talented workforce that is layered across our different organizational levels. We believe in and respect the diverse ideologies of our customers as well as our team
                                                        members.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="image">
                                        <img class="img-fluid office-img" src="../../../assets/images/About-us/Samman - Respect.png">
                                    </div>
                                </div>
                            </div>
                            <div id="last-carousel-item" class="carousel-item" data-interval="3000">
                                <div class="carousel-content">
                                    <div class="content">
                                        <div class="col-12 alignpara">
                                            <div class="row px-1">
                                                <p class="firstoffice">Believing in the Indian way and in oneself
                                                </p>
                                                <div>
                                                    <p class="firstoffpara col-md-9">
                                                        Arrivae is MADE BY YOU – our esteemed customers as well as our highly experienced and talented workforce that is layered across our different organizational levels. We believe in and respect the diverse ideologies of our customers as well as our team
                                                        members.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="image">
                                        <img class="img-fluid office-img" src="../../../assets/images/About-us/Indianness.png">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="our-values d-sm-block d-md-none">
                <div class="slider-nav justify-content-around">
                    <div class="active">
                        <div class="head">
                            <p class="valuesone ml-n3">Keep It Simple</p>
                        </div>
                        <div class="body d-flex ml-n3">
                            <img src="../../../assets/images/Polygon 1.png" class="imgFirst" />
                            <hr class="hrone" />
                        </div>
                    </div>
                    <div class="carouselsecond">
                        <div class="head">
                            <p class="titletwo">Innovation and Excellence</p>
                        </div>
                        <div class="body d-flex">
                            <img src="../../../assets/images/Polygon 1.png" class="imgSecond" />
                            <hr class="hrtwo" />
                        </div>
                    </div>
                    <div class="carouselthird">
                        <div class="head">
                            <p class="titlethree">Openness and Transparency</p>
                        </div>
                        <div class="body d-flex">
                            <img src="../../../assets/images/Polygon 1.png" class="imgThird" />
                            <hr class="hrthree" />
                        </div>
                    </div>
                    <div class="carouselfourth">
                        <div class="head">
                            <p class="titlefour">Samman: Respect</p>
                        </div>
                        <div class="body d-flex">
                            <img src="../../../assets/images/Polygon 1.png" class="imgFourth" />
                            <hr class="hrfour" />
                        </div>
                    </div>
                    <div class="carouselfive">
                        <div class="head">
                            <p class="titlefour">Indianness</p>
                        </div>
                        <div class="body d-flex">
                            <img src="../../../assets/images/Polygon 1.png" class="imgFive" />
                            <hr class="hrfive" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="slider-for">
                <div style="width: 100%;">
                    <div class="image">
                        <img class="img-fluid px-3 newsimage" src="../../../assets/images/About-us/Keep It Simple - 1.png">
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <p class="firsthead">Delivering simple and sensible solutions</p>
                            <p class="col-md-9 firstpara">
                                Home interior design is looked upon as a lengthy and cumbersome process. Arrivae’s predefined processed and methods make your experience not just easy, but exciting too. With our work history, we’ve proved our solutions are sensible, simple yet stylish.
                            </p>
                        </div>
                    </div>
                </div>
                <div style="width: 100%;">
                    <div class="image">
                        <img class="img-fluid px-3 newsimage" src="../../../assets/images/About-us/Innovation and Excellence.png">
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <p class="firsthead ">Thinking ahead of time to meet evolving needs</p>
                            <p class="col-md-9 firstpara mb-3">
                                With evolving times and generations, our needs too evolve. Anticipating future trends and best design practices, Arrivae constantly works in the direction to invent and innovate new design solutions for a better lifestyle. Excellence is therefore reflected
                                in Arrivae’s every design.
                            </p>
                        </div>
                    </div>
                </div>
                <div style="width: 100%;">
                    <div class="image">
                        <img class="img-fluid px-3 newsimage" src="../../../assets/images/About-us/Openness and Transparency.png">
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <p class="firsthead">Building and strengthening relationships with honesty and sincerity
                            </p>
                            <p class="col-md-9 firstpara">
                                Sapno Ka Ghar holds a special value in every individual’s heart. We understand what it means and hence we ensure to keep all our crystal clear to make this a seamless and memorable journey for you and your family
                            </p>
                        </div>
                    </div>
                </div>
                <div style="width: 100%;">
                    <div class="image">
                        <img class="img-fluid px-3 newsimage" src="../../../assets/images/About-us/Samman - Respect.png">
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <p class="firsthead">Respect in dealing with everyone within and outside the organization
                            </p>
                            <p class="col-md-9 firstpara">
                                Arrivae is MADE BY YOU – our esteemed customers as well as our highly experienced and talented workforce that is layered across our different organizational levels. We believe in and respect the diverse ideologies of our customers as well as our team
                                members.
                            </p>
                        </div>
                    </div>
                </div>
                <div style="width: 100%;">
                    <div class="image">
                        <img class="img-fluid px-3 newsimage" src="../../../assets/images/About-us/Indianness.png">
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <p class="firsthead">Believing in the Indian way and in oneself</p>
                            <p class="col-md-9 firstpara">
                                Arrivae is MADE BY YOU – our esteemed customers as well as our highly experienced and talented workforce that is layered across our different organizational levels. We believe in and respect the diverse ideologies of our customers as well as our team
                                members.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="row justify-content-center">
                    <div class="col-10">
                        <p class="news">In The News</p>
                    </div>
                </div>
                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                        <div class="cards-wrapper">
                            <div class="hearFromClients w-100 px-2">
                                <div class="card cardstyles col-lg-4 col-12">
                                    <a href="https://www.hindustantimes.com/lifestyle/fashion/taking-the-sustainability-route-to-spruce-up-your-space-101635238034117.html" target="_blanks">
                                        <div class="py-3">
                                            <img src="../../../assets/images/news-articles/Hindustan _Times_logo.png" class="mt-3 imageone" />
                                        </div>
                                        <div class="body marginTop">
                                            <h5 class="card-title mt-4">Taking the sustainability route to spruce up your space
                                            </h5>
                                            <p class="card-text">Festive season is upon us - amid attending cards parties, puja functions, food comas, poker nights and making that customary trip to gift goodies to our loved ones, it is that time of the year when we all start...
                                            </p>
                                        </div>
                                    </a>
                                </div>
                                <div class="card cardstyles col-lg-4 col-12">
                                    <a href="https://yourstory.com/2022/02/funding-alert-startup-arrivae-raises-10-million-think-havells/amp" target="_blanks">
                                        <div class="my-2">
                                            <img src="../../../assets/images/news-articles/logo_yourstory.svg" class="mt-3 imageone" style="width: 50%;" />
                                        </div>
                                        <div class="body py-3">
                                            <h5 class="card-title mt-1">Arrivae secures Rs75 Cr in Series B from Think Investments, Havells
                                            </h5>
                                            <p class="card-text">Arrivae, an interior designing startup, raised Rs 75 crore ($10 mn) in Series B round from Think Investments, a private investment firm and Havells Group. The startup was founded in 2017 by Yash Kela.
                                            </p>
                                        </div>
                                    </a>
                                </div>
                                <div class="card cardstyles col-lg-4 col-12">
                                    <a href="https://realty.economictimes.indiatimes.com/news/allied-industries/rakesh-jhunjhunwala-backed-arrivae-to-hire-500-studio-designers/88063824" target="_blanks">
                                        <div class="my-2">
                                            <img src="../../../assets/images/news-articles/Resized - Post 2.png" class="mt-3 imagetwo" />
                                        </div>
                                        <div class="body py-4">
                                            <h5 class="card-title mt-1">Rakesh Jhunjhunwala backed Arrivae to hire 500 studio designers</h5>
                                            <p class="card-text">Rakesh Jhunjhunwala backed furniture start-up Arrivae is planning to hire 500 studio designers as it looks to increase the store count by three-fold across the country.</p>
                                        </div>
                                    </a>
                                </div>
                                <div class="card cardstyles col-lg-4 col-12">
                                    <a href="https://realty.economictimes.indiatimes.com/news/allied-industries/arrivae-raises-rs-75-crore-from-think-investments-havells-group/89817160 " target="_blanks">
                                        <div class="my-2">
                                            <img src="../../../assets/images/news-articles/Resized - Post 2.png" class="mt-3 imagetwo" />
                                        </div>
                                        <div class="body py-3">
                                            <h5 class="card-title topmargin">Arrivae raises Rs 75 crore from Think Investments & Havells Group
                                            </h5>
                                            <p class="card-text">Arrivae, a made-to-order furniture manufacturer & home improvement company, has closed its Series B funding round with a 75 crore investment from Think Investments & Havells Group.</p>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <a class="prev carousel-control-prev">
                                <img src="left icon.png">
                            </a>
                            <a class="next carousel-control-next">
                                <img src="right icon.png">
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
</div>