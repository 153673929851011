import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { SharedService } from 'src/app/shared/shared.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;
@Component({
  selector: 'app-space-cards',
  templateUrl: './space-cards.component.html',
  styleUrls: ['./space-cards.component.scss']
})
export class SpaceCardsComponent implements OnInit {
  @Input() data: any;
  @Output() refresh = new EventEmitter<string>();

  uid: any;
  access_token: any;
  client: any;
  lead_id: any;
  spaces: any = [];

  constructor(private router: Router, private sharedService: SharedService, public http: HttpClient, private toastr: ToastrService) { }
  ngOnInit(): void {
    this.uid = localStorage.getItem("UID");
    this.access_token = localStorage.getItem("access-token");
    this.client = localStorage.getItem("client") ? localStorage.getItem("client") : '';
    this.lead_id = localStorage.getItem("lead_id") ? localStorage.getItem("lead_id") : '';
    this.loadScript()
  }

  viewSpaceHandler(id: any) {
    window.open(
      ('/space-inclusion/' + id),'_blank' );
  }

  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }


  spaceTabHandler(id: any) {
    this.sharedService.GetThisDesignHandler(this.uid, this.client, this.access_token, this.lead_id, id).subscribe(data => {
      this.dataUpdateHandler();
      this.toastr.success(
        'Thanks for sharing your preferences. Our designers will get in touch with you in 24 hours'
      );
    })
  }

  dataUpdateHandler() {
    this.refresh.emit();
  }

}
