import { Component, OnInit } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-how-we-work',
  templateUrl: './how-we-work.component.html',
  styleUrls: ['./how-we-work.component.scss']
})
export class HowWeWorkComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('#main-carousel').on('slide.bs.carousel', '', function () {
      if ($("#last-carousel-item").hasClass("active")) {
        $('#main-carousel').on('slid.bs.carousel', function() {
          $(".carousel-indicators li:not(:first)").removeClass("activeEle");
        })
      } else {
        $( "#main-carousel .active" ).addClass( "activeEle" );
        $('#main-carousel').off('slid.bs.carousel');
      }
    })
  }

}
