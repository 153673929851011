import { Component, OnInit, ViewChild ,ElementRef,HostListener } from '@angular/core';
import { Chart } from 'chart.js';
import { Slick } from 'ngx-slickjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { LandingService } from '../landing/landing.service';
import { FormBuilder ,FormGroup, Validators  } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {  HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

declare var $: any;

@Component({
  selector: 'app-magic-link',
  templateUrl: './magic-link.component.html',
  styleUrls: ['./magic-link.component.scss'],
})
export class MagicLinkComponent implements OnInit {
  
  TokenFormagic: any;
  urlMagic: any;
  successalert: any;
  erroralert: any;
  successMessage: any;
  errorMessage: any;
  showMsg: any;
  res: any;
  bookingformId: any;
  loader: any;
  otpRecievedMobile: any;
  isotpApproved: any;
  fileControl: any;
  role: any;
  fileuploadform: any;
  customer_name: any;
  days_to_complete: any;
  PojectCurrent_state: any;
  Element: any;
  todayDate = new Date().toISOString().substring(0, 16);
  editable: any;
  roles: any = ['designer', 'community_manager', 'city_gm', 'business_head'];
  customerPaymentForm :any

  constructor(
    private router: Router,
    private landingservice: AuthenticationService,
    private authservice: LandingService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private toastr: ToastrService,
  ) {}

  @ViewChild("paymentSeection", { static: true }) dfdsf!:ElementRef
  someinput: any;
  system_user: any = '';
  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      this.system_user = params['system_user'];
    });
    if (this.system_user == undefined) {
      localStorage.setItem('system_user_id', '');
    } else {
      localStorage.setItem('system_user_id', this.system_user);
    }
    this.urlMagic = localStorage.getItem('loginuser');
    let tokenmagic = this.urlMagic.split('=');

    this.TokenFormagic =
      tokenmagic.length == 3 ? tokenmagic[1].slice(0, -5) : tokenmagic[1];
    this.role = tokenmagic[2] ? tokenmagic[2] : null;

    localStorage.setItem('role', this.role);
    if (this.roles.includes(localStorage.getItem('role'))) {
      this.editable = false;
    } else {
      this.editable = true;
    }
    // this.getCustomerDashboardTimeline();

    this.customerPaymentForm = this.fb.group({
      amount : ['', 
      [
        Validators.required,
        // Validators.min(this.minVal),
        Validators.max(this.maxVal)
      ]
      ],
      email: ['', [Validators.required, Validators.email]],
      mobile_number :  ['',  [Validators.required , Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$") , Validators.maxLength(10) ]  ],
      lead_id : [''],
      web_boq : true
    })

    this.fileuploadform = this.fb.group({
      profile: [''],
    });
    this.loader = true;
    setTimeout(() => {
      this, (this.loader = false);
    }, 2000);
    // this.spaceTypeWise('spaceWise')
    this.magiclogin();
    this.getcustomerDetails();
    // this.costBreakUp('spaceWise')
    this.looseFurniture('furniture');

   

    // this.paymentDetails()
    // this.spaceString = 'spaceWise';
    
    this.costumername2 = localStorage.getItem('ccname');
    var element = document.getElementById(`1act`);
    this.Element = document.getElementById(`1act`);
    // this.Element.classList.add('active');
    // element1.innerHTML = '<img src="../../../../assets/images/rightTick.svg" />'
    $("#termstop").on("contextmenu", function(e:any) {
      return false;
    });
    // this.loadScript();
  }

  config: Slick.Config = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    autoplay: false,
    autoplaySpeed: 2000,
    draggable: false,
    mouseWheelMove: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  clickOnActiveClass : boolean = false
  paymentSection :any
  imgSrc : boolean = false
  AllBoqs : any
  // paymentDetails(){
  //   this.landingservice.PaymentDetails().subscribe((res) => {
  //     this.paymentSection = res
  //     this.AllBoqs = this.paymentSection.all_boqs
  //     this.customerPaymentForm.controls["email"].setValue(this.paymentSection.lead_payment_request.email);
  //     this.customerPaymentForm.controls["mobile_number"].setValue(this.paymentSection.lead_payment_request.phone_number);
  //     this.customerPaymentForm.controls["lead_id"].setValue(this.paymentSection.lead_payment_request.lead_id);
  //     this.customerPaymentForm.controls["web_boq"].setValue("true");
  //   })
  // }


  level1_data: any;
  level2_data: any;
  level3_data_nonservice: any;
  level3_data_service: any;
  boq_status_level: any;
  check_1: boolean = false;
  check_2: boolean = false;
  check_3: boolean = false;
  async getCustomerDashboardTimeline() {
    this.loader = true
    return (await this.landingservice.getCustomerDashTimeline()).subscribe(
      (res: any) => {
        this.paymentSection = res
        this.AllBoqs = this.paymentSection.all_boqs
        this.customerPaymentForm.controls["email"].setValue(this.paymentSection.lead_payment_request.email);
        this.customerPaymentForm.controls["mobile_number"].setValue(this.paymentSection.lead_payment_request.phone_number);
        this.customerPaymentForm.controls["lead_id"].setValue(this.paymentSection.lead_payment_request.lead_id);
        this.customerPaymentForm.controls["web_boq"].setValue("true");
        this.boq_status_level = res.time_line.data[0];
        this.level1_data = res.time_line.level_1;
        this.level2_data = res.time_line.level_2;
        this.level3_data_nonservice = res.time_line.level_3_non_service;
        this.level3_data_service = res.time_line.level_3_service;
        if (this.boq_status_level.boq_timeline_level === 'level_1') {
           this.check_1 = true;
           this.check_2 = false;
           this.check_3 = false;
           this.check1();
          $('#vert-1').attr('checked', 'checked');
        } 
         if (this.boq_status_level.boq_timeline_level === 'level_2' &&  this.boq_status_level.boq_status_service !== 'Sent to Production') {
          this.check_1 = true;
          this.check_2 = true;
          this.check_3 = false;
          this.check2();
          $('#vert-2').attr('checked', 'checked');
        } 
        if ((this.boq_status_level.boq_timeline_level === 'level_2' &&  this.boq_status_level.boq_status_service === 'Sent to Production')  ||   (this.boq_status_level.boq_timeline_level === 'level_3')  ) {
          this.check_1 = true;
          this.check_2 = true;
          this.check_3 = true;
          this.check3();
          $('#vert-3').attr('checked', 'checked');
        }
        setTimeout(() => {
          this, (this.loader = false);
        }, 2000);
      }
    );
  }

  checked1: boolean = true;
  checked2: boolean = false;
  checked3: boolean = false;

  check1() {
    this.checked1 = true;
    this.checked3 = false;
    this.checked2 = false;
  }
  check2() {
    this.checked2 = true;
    this.checked1 = false;
    this.checked3 = false;
  }
  check3() {
    this.checked3 = true;
    this.checked2 = false;
    this.checked1 = false;
  }
  customerInfo: any;
  response: any;
  customerTimeline: any;
  mobileNumber: any;
  projectDirectory: any;
  isfilledBookigform: any;
  urlforBookingForm: any;
  islogined: any;
  paymentStatusDetails: any;
  dateDetails: any;
  materialFinalisation: any;
  leadId: any;
  orderBookingDetails: any;
  orderConfirmationDetails: any;
  orderExecutionDetails: any;

  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  mkw_full_home : any
  async getcustomerDetails() {
    this.loader = true
   return(await this.landingservice.GetcustomerDetails()).subscribe(
      (res) => {
        this.customerTimeline = res;
        this.leadId = this.customerTimeline.timeline.lead_id;
        this.projectId = this.customerTimeline.timeline.project_id;
        this.bookingformId =
          this.customerTimeline.timeline.project_booking_form_id;
        this.projectDirectory =
        this.customerTimeline.timeline['Project Record'];
        this.mkw_full_home = this.projectDirectory.mkw_full_home
        this.paymentStatusDetails =
          this.customerTimeline.timeline['Payment Status Details'];
        this.materialFinalisation =
          this.customerTimeline.timeline['Material Finalisation Meeting'];
        this.orderBookingDetails =
          this.customerTimeline.timeline['Order Booking Details'];
        this.orderConfirmationDetails =
          this.customerTimeline.timeline['Order Confirmation Details'];
        this.orderExecutionDetails =
          this.customerTimeline.timeline['Order Execution Details'];

        this.dateDetails = this.customerTimeline.timeline['Date Details'];
        this.isotpApproved =
          this.customerTimeline.timeline.has_approved_booking_form;
        // this.isotpApproved = false;
        this.isfilledBookigform =
          this.customerTimeline.timeline.has_filled_booking_form;
        this.otpRecievedMobile = this.projectDirectory.customer_mobile_number;
        this.days_to_complete = this.projectDirectory.remaining_days_to_complete
          ? this.projectDirectory.remaining_days_to_complete
          : 'Few';
        if (!this.days_to_complete || this.days_to_complete == 'NA') {
          this.days_to_complete = 'few';
        }
        this.customer_name = this.projectDirectory.customer_name;
        this.PojectCurrent_state = this.projectDirectory.project_current_status
          ? this.projectDirectory.project_current_status
          : null;
        if (this.customerTimeline.timeline.signed_booking_form_details) {
          this.urlforBookingForm =
            this.customerTimeline.timeline.signed_booking_form_details.url;
        }
        this.landingservice.eventEmitter(this.leadId);
        this.getdesigns('initial_design', this.projectId);
        this.upcomingevents();
        this.costBreakUp('spaceWise')
        this.getInspirations();
        this.getCustomerDashboardTimeline();
        // this.router.navigateByUrl(
        //   '/customer-dashboard?token=' +
        //     this.TokenFormagic +
        //     (this.role ? '&role=' + this.role : '')
        // );
        setTimeout(() => {
          this.loader = false;
        }, 1500);
      },
      (err:any) => {
        // if (
        //   err.error.errors[0] ==
        //   'You need to sign in or sign up before continuing.'
        // ) {
        //   setTimeout(() => {
        //     this, alert('Your Session Expired please Login Again');
        //     this.logout2();
        //   }, 4000);
        // } else {
        //   this.erroralert = true;
        //   this.errorMessage = 'Something Error Accured';

        //   setTimeout(() => {
        //     this, (this.erroralert = false);
        //   }, 4000);
        // }
      }
    );
  }

  costBreakUpData :any
  spaceWiseData :any
  typeWiseData :any
  chartSpaceData :any = []
  chartSpaceNames :any = []
  chartSpaceColors : any = []
  uniqSpaceData :any
  uniqSpaceNames :any
  uniqSpaceColors :any
  spaceString: any 
    costBreakUp(e:any){
    this.spaceString = e
    this.loader = true
    this.landingservice.costBreakUpCustomer().subscribe((res) => {
      this.costBreakUpData = res
      if(this.spaceString === 'spaceWise'){
        this.chartSpaceData = []
        this.chartSpaceNames = []
        this.chartSpaceColors  = []
        $('#four').hide();
        $('#two').show();
        this.spaceWiseData = this.costBreakUpData.space_wise_breakup.space_uniq
        this.spaceWiseData.map((e :any) => {
          this.chartSpaceData.push( Math.round(e.amount)  )
          this.chartSpaceNames.push(e.space)
          this.chartSpaceColors.push(e.color)
        })
        setTimeout(() => {
          new Chart( 'myChart', {
            type: 'doughnut',
            data: {
              labels:  this.chartSpaceNames,
                datasets: [{
                    label: 'Current Vallue',
                    data:  this.chartSpaceData,
                    backgroundColor: this.chartSpaceColors
                }]
            },
            options: {
              legend: {
                display: false,
              },
              tooltips: {
                callbacks: {
                  label: function (tooltipItem: any, data: any) {
                    const sum = data.datasets[0].data.reduce(
                      (accumulator: any, curValue: any) => {
                        return accumulator + curValue;
                      }
                    );
                    return (
                      data['labels'][tooltipItem['index']] +
                      ': ' +
                      '₹' +
                      new Intl.NumberFormat('en-IN').format(
                        data['datasets'][0]['data'][tooltipItem['index']]
                      ) +
                      ' (' +
                      Number(
                        (data['datasets'][0]['data'][tooltipItem['index']] / sum) *
                          100
                      ).toFixed(0) +
                      '%' +
                      ')'
                    );
                  },
                },
              }
            }
          });
        }, 2000);
      }
      if(this.spaceString === 'typeWise'){
        this.typeChartData = []
        this.typeChartName = []
        this.typeChartColor = []
        $('#two').hide();
        $('#four').show();
        this.typeWiseData = this.costBreakUpData.type_wise_breakup
        this.typeWiseData.map((e :any) => {
          this.typeChartData.push( Math.round(e.amount))
          this.typeChartName.push(e.capitalized_quote_type)
          this.typeChartColor.push(e.color)
        })
        new Chart( 'typeChart', {
          type: 'doughnut',
          data: {
            labels:  this.typeChartName,
              datasets: [{
                  label: 'Current Vallue',
                  data:  this.typeChartData,
                  backgroundColor: this.typeChartColor
              }]
          },
          options: {
            legend: {
              display: false,
            },
            tooltips: {
              callbacks: {
                label: function (tooltipItem: any, data: any) {
                  const sum = data.datasets[0].data.reduce(
                    (accumulator: any, curValue: any) => {
                      return accumulator + curValue;
                    }
                  );
                  return (
                    data['labels'][tooltipItem['index']] +
                    ': ' +
                    '₹' +
                    new Intl.NumberFormat('en-IN').format(
                      data['datasets'][0]['data'][tooltipItem['index']]
                    ) +
                    ' (' +
                    Number(
                      (data['datasets'][0]['data'][tooltipItem['index']] / sum) *
                        100
                    ).toFixed(0) +
                    '%' +
                    ')'
                  );
                },
              },
            }
          }
        });
      }
      setTimeout(() => {
        this.loader = false;
      }, 1500);
    })
  }


  checkforOther(){
    let index = this.spaceWiseData.findIndex(function(el:any){
      return el.space == 'Other'
    })
    
    // console.log(index);
    if(index == -1){
      return true
    }else{
      return false
    }
  }
  typeChartData :any = []
  typeChartName :any = []
  typeChartColor :any = []
  paymentUrl :any


  minVal = 1000;
  maxVal = 1000000
  submitform : any

  OpenpaymentModal(modal:any){
    this.customerPaymentForm.controls["amount"].setValue( this.totalAmount );
    this.modalService.open(modal, {
      centered : true,
      backdrop: 'static'
    })
    if(this.tenPercentageLength.length > 0){
      this.payment_stage = "pre_10_percent"
    }else if( this.fortPercentageLength.length > 0 ){
      this.payment_stage = "10_50_percent"
    }else{
      this.payment_stage = "final_payment"
    }

    console.log(this.payment_stage);
    
    
  }
  messageForthreekTenK : boolean = false
  DisplayErrormsg :any

  checkThreepercenttenkValidation(){
    console.log(this.payment_stage);
    let x = this.addingFinalBoqValue * 0.1
    if(this.customerPaymentForm.value.amount < 1000000){
      if(x > 10000){
        if( this.customerPaymentForm.value.amount >=10000 ){
          this.messageForthreekTenK = false
          this.paymentSubmit()
        }else{
          this.messageForthreekTenK = true
          this.DisplayErrormsg = "Amount should be greater than ₹10,000"
        }
      }else if( x <10000 && this.customerPaymentForm.value.amount > this.addingFinalBoqValue * 0.03){
        this.messageForthreekTenK = false
        this.paymentSubmit()
      }else{
        this.messageForthreekTenK = true
        this.DisplayErrormsg = "Amount should be greater than 3% of of BOQ value"
      }
    }else{
      this.messageForthreekTenK = true
      this.DisplayErrormsg = "10 lakh condition"
    }
  }
  clearMessages(){
    this.messageForthreekTenK = false
  }
  paymentSubmit(){ // for payment Details submit
    var email = /^[(A-Z)(a-z)0-9._%+-]+@[(A-Z)(a-z)0-9.-]+\.[(a-z)(A-Z)]{2,4}$/;
    this.submitform  =true

    if(this.tenPercentageLength.length > 0){
      this.payment_stage = "pre_10_percent"
    }else if( this.fortPercentageLength.length > 0 ){
      this.payment_stage = "10_50_percent"
    }else{
      this.payment_stage = "final_payment"
    }

    if(this.customerPaymentForm.invalid){
      return
    }
      if(this.customerPaymentForm.value.email && this.customerPaymentForm.value.email.match(email)){
        // this.displayemailError = false
        this.landingservice.paymentforCustomer(this.customerPaymentForm.value , this.quotations_ids , this.payment_stage).subscribe((res)=>{
          this.paymentUrl = res
          this.modalService.dismissAll();
          window.open(this.paymentUrl.payment_url, '_blank');
        },
        err => {
          this.erroralert = true;
          this.errorMessage = err.error.message;
          this.modalService.dismissAll();

          setTimeout(() => {
            this, (this.erroralert = false);
          }, 4000)
        })
      }else{
        console.log('else block');
        //  this.displayemailError = true
       }
  }

  addingDynamicImg : any = '../../assets/images/carosel-icons/checkmark-inactive.png'
  addAllAmount :any = []

  takeAllBoqValues :any = []  // considering the same for all the three

  totalAmount :any
  sumOfAllAmounts(){
    this.totalAmount =  this.addAllAmount.reduce((x:any, y:any) => x+y, 0 )
    this.customerPaymentForm.controls["amount"].setValue( this.totalAmount );

    console.log(this.totalAmount, 'tottal ' );

  }
  addingFinalBoqValue :any
  sumOfAllFinalBoqValues(){
    this.addingFinalBoqValue = this.takeAllBoqValues.reduce((x:any , y:any) => x+y , 0)
    console.log(this.addingFinalBoqValue , 'final')
  }
  selectedItem :any
  clickonFortyBoq(boq:any, index:any , boolvalue:any, amount:any , e:any , id:any , finalBoqValue :any ){
    if(this.validatorForcheckingForty()){
      $("#fortyCheckbox" + index).prop("checked", false);
      this.toastr.error('10% or 50% BOQ Has Been Selected');

    }else{
      if(boolvalue){
        if( this.tenPercentageLength.length === 0 && this.fiftyPercentageLength.length === 0  ){
           if(e.target.checked){
          if(!this.addAllAmount.includes(amount)){
            this.addAllAmount.push(Math.round(amount))
            this.quotations_ids.push(id)
            this.fortPercentageLength.push(id)
            this.takeAllBoqValues.push(finalBoqValue)  // all boq final values for calculating 3% and 10k validation
             $('#fortypercentBoq'+index).addClass("activeClass");
             $('#fortyImg'+index).attr('src' , '../../assets/images/carosel-icons/checkmark-active.png')
             this.sumOfAllAmounts()
             this.sumOfAllFinalBoqValues()
          }
        }else {
           $('#fortypercentBoq'+index).removeClass("activeClass");
           $('#fortyImg'+index).attr('src' , '../../assets/images/carosel-icons/checkmark-inactive.png')
          const removeindex = this.addAllAmount.indexOf(Math.round(amount));
          this.quotations_ids.splice(removeindex, 1);
          this.fortPercentageLength.splice(removeindex, 1);
          this.addAllAmount.splice(removeindex, 1);
          this.takeAllBoqValues.splice(removeindex , 1 )  // all boq final values for calculating 3% and 10k validation
          this.sumOfAllAmounts()
          this.sumOfAllFinalBoqValues()
        }
        }else{
          this.toastr.error('you are not abel to select');
        }
      }
    }
  }
  clickonFiftyBOq( boq:any, index:any , boolvalue:any, amount:any , e:any , id:any ){
    if(this.validatorForcheckingFifty()){
            $("#fiftyCheckbox" + index).prop("checked", false);
      this.toastr.error('10% or 40% BOQ Has Been Selected');

    }else{
      if(boolvalue){
        if(e.target.checked){
          if(!this.addAllAmount.includes(amount)){
            this.addAllAmount.push(Math.round(amount))
            this.quotations_ids.push(id)
            this.fiftyPercentageLength.push(id)
             $('#fiftypercentBoq'+index).addClass("activeClass");
             $('#firtyImg'+index).attr('src' , '../../assets/images/carosel-icons/checkmark-active.png')
             this.sumOfAllAmounts()
          }
        }else {
           $('#fiftypercentBoq'+index).removeClass("activeClass");
           $('#firtyImg'+index).attr('src' , '../../assets/images/carosel-icons/checkmark-inactive.png')
          const removeindex = this.addAllAmount.indexOf(Math.round(amount));
          this.addAllAmount.splice(removeindex, 1);
          this.quotations_ids.splice(removeindex, 1);
          this.fiftyPercentageLength.splice(removeindex, 1);
          this.sumOfAllAmounts()
        }
  
    }
    }
  }
  // selectPaymentStage : boolean = false
  //  take the length of each stage 
  tenPercentageLength : any = []

  fortPercentageLength : any = []

  fiftyPercentageLength : any = []

  payment_stage :any = ""
  quotations_ids : any = []
 
  clickOnfirstBoq( boq:any, index:any , boolvalue:any, amount:any , e:any , id:any , finalBoqValue :any){
    if(this.validatorForcheckingTen()){
      $("#tenCheckbox" + index).prop("checked", false);
      this.toastr.error('40% or 50% BOQ Has Been Selected');
    } else{
      if(boolvalue){
        // if(this.fortPercentageLength.length === 0  &&  this.fiftyPercentageLength.length === 0 ){
          if(e.target.checked){
            if(!this.addAllAmount.includes(amount)){
  
              this.addAllAmount.push(Math.round(amount))  /// adding amount and displaying in form
  
              this.quotations_ids.push(id)     /// send quotations IDS to backend 
  
              this.tenPercentageLength.push(id) // to calculate length and make others not clickable
  
  
              this.takeAllBoqValues.push(finalBoqValue)  // all boq final values for calculating 3% and 10k validation
  
  
  
               $('#tenPercentBoq'+index).addClass("activeClass");
               $('#tenImg'+index).attr('src' , '../../assets/images/carosel-icons/checkmark-active.png')
               this.sumOfAllAmounts()
               this.sumOfAllFinalBoqValues()
            }
          }else {
             $('#tenPercentBoq'+index).removeClass("activeClass");
             $('#tenImg'+index).attr('src' , '../../assets/images/carosel-icons/checkmark-inactive.png')
            const removeindex = this.addAllAmount.indexOf( Math.round(amount) );
            this.addAllAmount.splice(removeindex, 1);   // adding amount and displaying in form
            this.quotations_ids.splice(removeindex, 1); // send quotations IDS to backend
            this.tenPercentageLength.splice(removeindex, 1); // to calculate length and make others not clickable
            this.takeAllBoqValues.splice(removeindex,1)  // all boq final values for calculating 3% and 10k validation
            this.sumOfAllAmounts()
            this.sumOfAllFinalBoqValues()
          }
      }

    }
    
  }

  validatorForcheckingTen(){
    if(this.fortPercentageLength.length >= 1  ||  this.fiftyPercentageLength.length >= 1){
      return  true
    }else{
      return false
    }
  }

  validatorForcheckingForty(){
    if(this.tenPercentageLength.length >=1 ||  this.fiftyPercentageLength.length >=1  ){
      return true
    }else{
      return false
    }
  }

  validatorForcheckingFifty(){
    if(this.tenPercentageLength.length >= 1 || this.fortPercentageLength.length >= 1 ){
      return true
    }else{
      return false
    }
  }


  paymentValue :any
  summaryValue :any
  fullBoq :any
  itemsScroll :any
  moreInspiration :any
  TopTerms :any
  costValue:any
  @HostListener('window:scroll', ['$event'])
  scrollHandler() {
    this.summaryValue = $('#projsummary').offset().top
    this.costValue = $('#valueCostBreak').offset().top
    this.paymentValue = $('#paymentSection22').offset().top



    this.fullBoq = $('#fullBoq').offset().top
    this.itemsScroll = $('#scrollItems').offset().top
    this.moreInspiration = $('#rwerwe').offset().top
    this.TopTerms = $('#termstop').offset().top
    if(window.scrollY  >= this.paymentValue  && window.scrollY <= this.fullBoq ){
      this.linksActive = 'paymentDetail'
    }
    if(window.scrollY  >= this.costValue  && window.scrollY <= this.paymentValue ){
      this.linksActive = 'costBreak'
    }
    if(window.scrollY >= this.fullBoq && window.scrollY <=  this.itemsScroll ){
      this.linksActive = 'otherActiveBoqs'
    }

    if(window.scrollY >= this.itemsScroll && window.scrollY <=  this.moreInspiration ){
      this.linksActive = 'mooreItems'
    }

    if(window.scrollY >= this.moreInspiration  &&  window.scrollY <=  this.TopTerms ){
      this.linksActive = 'nexxtSteps'
    }

    if(window.scrollY >= this.TopTerms-20){
      this.linksActive = 'termsconditions'
    }

    if(window.scrollY >= 0 && window.scrollY  <= this.costValue-20 ){
      this.linksActive = 'summary'
    }
  }

  paymentoffset :any
  linksActive :any = 'summary'
  ClickableLinks :any = 'summary'
  scrollToSummary(e:any) {
    $(window).scrollTop($(e).offset().top);

  }
  scrollToCostBreakup(c:any){
    $(window).scrollTop($(c).offset().top);
  }
  scrollToPayment(p:any){
    $(window).scrollTop($(p).offset().top);
  }
  scrollToAllBoqs(b:any){
    $(window).scrollTop($(b).offset().top);
  }
  extraItems(items:any){
    $(window).scrollTop($(items).offset().top);

  }
  boardInspiration(i:any){
    $(window).scrollTop($(i).offset().top);
  }
  termsConditions(e:any){
    $(window).scrollTop($(e).offset().top);
  }
  customerLink(data: any) {
    if(data){
      window.open(data, '_blank');
    }
  }
  canvas: any;
  ctx: any;
  @ViewChild('chartone') chartone: any;

  headers_res: any;
  conent_type: any;
  access_token: any;
  client: any;
  uid: any;
  costumerName: any;
  costumername2: any;
  eventId: any;
  projectId: any;
  errorissue: any;
  locationData: any;
  contactType: any;
  emailsData: any;
  descriptionData: any;

  magiclogin() {
    this.landingservice.Magiclogin(this.TokenFormagic).subscribe(
      (res) => {
        if (localStorage.getItem('client') == null) {
          this.access_token = res.headers.get('access-token');
          this.uid = res.headers.get('uid');
          this.client = res.headers.get('client');
          localStorage.setItem('uid', this.uid);
          localStorage.setItem('access_token', this.access_token);
          localStorage.setItem('client', this.client);
          this.costumerName = res.body;
          this.costumername2 = this.costumerName.user.name;

          localStorage.setItem('ccname', this.costumername2);
          this.getcustomerDetails();

          this.router.navigate(['/customer-dashboard']);
        } else if (
          localStorage.getItem('client') !== res.headers.get('client')
        ) {
          this.access_token = res.headers.get('access-token');
          this.uid = res.headers.get('uid');
          this.client = res.headers.get('client');
          localStorage.setItem('uid', this.uid);
          localStorage.setItem('access_token', this.access_token);
          localStorage.setItem('client', this.client);
          this.costumerName = res.body;
          this.costumername2 = this.costumerName.user.name;

          localStorage.setItem('ccname', this.costumername2);
          this.getcustomerDetails();

          this.router.navigate(['/customer-dashboard']);
        }
      },
      (err) => {
        if (localStorage.getItem('client') == null) {
          this.errorissue = true;
        }
      }
    );
  }


  b64toBlob(b64Data: any, contentType: any, sliceSize: any) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  fileContent: any;
  downloadWellcomeKit() {
    this.loader = true;
    this.landingservice.downloadpdf(this.projectId).subscribe(
      (res) => {
        this.fileContent = res;
        if (!res) {
          this.loader = false;
          this.erroralert = true;
          this.errorMessage = 'some thing error accured';
          this.loader = false;
          setTimeout(() => {
            this, (this.erroralert = false);
          }, 4000);
        }
        this.loader = false;
        var contentType = 'application/pdf';
        var b64Data = this.fileContent['welcome_kit_pdf_base_64'];
        var name = this.fileContent['file_name'];
        var blob = this.b64toBlob(b64Data, contentType, 512);
        var blobUrl = URL.createObjectURL(blob);
        let dwldLink = document.createElement('a');
        dwldLink.setAttribute('href', blobUrl);
        dwldLink.setAttribute('download', name);
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
        this.successalert = true;
        this.successMessage = 'Your welcome kit is downloaded successfully';
        setTimeout(() => {
          this, (this.successalert = false);
        }, 4000);
      },
      (err) => {
        this.erroralert = true;
        this.errorMessage = err.error.message;
        this.loader = false;
        setTimeout(() => {
          this, (this.erroralert = false);
        }, 4000);
      }
    );
  }

  gettingProposal: any;
  proposals: any;
  getdesigns(type: any, id: any) {
    this.landingservice.PropasalGetter(this.projectId, type).subscribe(
      (res) => {
        this.gettingProposal = res;
        this.proposals = this.gettingProposal.proposals;
        console.log(res);
      },
      (err) => {
        this.erroralert = true;
        this.errorMessage = err.error.message;

        setTimeout(() => {
          this, (this.erroralert = false);
        }, 4000);
      }
    );
  }
  slectedFormat: string[] = [];

  selectPdfFormat(event: any) {
    var index = this.slectedFormat.indexOf(event.target.value);

    if (event.target.checked) {
      this.slectedFormat.push(event.target.value);
    } else {
      this.slectedFormat.splice(index, 1);
    }
  }
  isChecked1: any;
  isChecked2: any;
  isChecked3: any;
  clearCheckBox() {
    this.isChecked1 = false;
    this.isChecked2 = false;
    this.isChecked3 = false;
  }
  boqid: any;
  boqcheck(e: any) {
    this.boqid = e;
  }
  pdfContent: any;
  downloadbooking() {
    $('#bookingFormotp').modal('hide');
  }
  downloadbooking2() {
    // $('#bookingFormdownload').modal('hide');
    this.modalService.dismissAll();
    this.successalert = true;
    this.successMessage = 'Your booking form is downloaded successfully';
    setTimeout(() => {
      this, (this.successalert = false);
      window.open(this.urlforBookingForm, '_blank');
    }, 2000);
  }

  downloadCustomerBoqPdf(boq: any) {
    this.boqid = boq;
    this.slectedFormat = ['boq', 'annexure', 'summary'];
    this.loader = true;
    this.landingservice
      .viewboq(this.slectedFormat, this.boqid, this.projectId)
      .subscribe(
        (res) => {
          if (!res) {
            this.loader = false;
            this.erroralert = true;
            this.errorMessage = 'some thing error accured';
            this.loader = false;
            setTimeout(() => {
              this, (this.erroralert = false);
            }, 4000);
          }

          this.pdfContent = res;
          this.loader = false;
          $('#boqCheckModal').modal('hide');
          this.clearCheckBox();
          var contentType = 'application/pdf';
          var b64Data = this.pdfContent['quotation_base_64'];
          var name = this.pdfContent['boq_name'];
          var blob = this.b64toBlob(b64Data, contentType, 512);
          var blobUrl = URL.createObjectURL(blob);
          let dwldLink = document.createElement('a');
          dwldLink.setAttribute('href', blobUrl);
          dwldLink.setAttribute('download', name);
          dwldLink.style.visibility = 'hidden';
          document.body.appendChild(dwldLink);
          dwldLink.click();
          document.body.removeChild(dwldLink);
          this.successalert = true;
          this.successMessage = 'Your BOQ is downloaded successfully';
          setTimeout(() => {
            this, (this.successalert = false);
          }, 4000);
        },
        (err) => {
          this.loader = false;
          setTimeout(() => {
            this, (this.erroralert = false);
          }, 4000);
        }
      );
  }

  rejectBoq(id: any) {
    var obj = {
      proposal_doc_id: id,
      is_approved: false,
    };
    this.loader = true;
    this.landingservice.rejectboq(obj).subscribe(
      (res) => {
        this.successalert = true;
        this.successMessage = 'Your BOQ rejection is successfully';
        setTimeout(() => {
          this, (this.successalert = false);
        }, 4000);
        this.getdesigns('initial_design', this.projectId);
        this.loader = false;
      },
      (err) => {
        this.erroralert = true;
        this.errorMessage = err.error.message;

        setTimeout(() => {
          this, (this.erroralert = false);
        }, 4000);
      }
    );
    this.loader = false;
  }
  aproveboq(id: any) {
    var obj = {
      proposal_doc_id: id,
      is_approved: true,
    };
    this.loader = true;
    this.landingservice.rejectboq(obj).subscribe(
      (res) => {
        this.getdesigns('initial_design', this.projectId);
        this.loader = false;
      },
      (err) => {
        this.erroralert = true;
        this.errorMessage = err.error.message;

        setTimeout(() => {
          this, (this.erroralert = false);
        }, 4000);
      }
    );
    this.loader = false;
  }

  otpError: any;
  getOtp(modalForm :any , otpbooking:any) {
    if (this.isotpApproved) {
      // $('#bookingFormdownload').modal('show');
      this.modalService.open(modalForm,{
        centered: true,
        backdrop: 'static'
      });
    } else {
      if (this.isfilledBookigform == true) {
        // $('#bookingFormotp').modal('show');
        this.modalService.open(otpbooking,{
          centered: true,
          backdrop: 'static'
        });

      } else {
        this.erroralert = true;
        this.errorMessage =
          'Booking form must be filled before OTP approval can be initiated.';

        setTimeout(() => {
          this, (this.erroralert = false);
        }, 4000);
      }
    }
  }
  inspiration_file: any;
  basefile: any;
  base64: any;
  fileuploadForm: any;
  fileName: any;
  fb2: any;

  onChange(event: any) {
    this.inspiration_file = event.target.files[0];
    this.loader = true;
    this.landingservice
      .postInspirations(this.projectId, this.inspiration_file)
      .subscribe((res) => {
        if (!res) {
          this.loader = false;
          this.erroralert = true;
          this.errorMessage = 'some thing error accured';
          this.loader = false;
          setTimeout(() => {
            this, (this.erroralert = false);
          }, 4000);
        }
        this.successalert = true;
        this.successMessage = 'File uploaded successfully';
        setTimeout(() => {
          this, (this.successalert = false);
          this.getInspirations();
        }, 2000);
        this.loader = false;
      });
  }

  // this.modalService.open(modalForm,{
  //   centered: true,
  //   backdrop: 'static'
  // });


  getotverification(bookform:any) {
    var obj = {
      mobilenumber: this.mobileNumber,
    };
    this.landingservice.sendotp(obj, this.bookingformId).subscribe(
      (res: any) => {
        console.log(res);
        // $('#bookingFormotp').modal('hide');
        // this.modalService.open()
        this.modalService.dismissAll();
        // $('#bookingFormverification').modal('show');
        this.modalService.open(bookform, {
          centered: true,
          backdrop: 'static'
        })

        this.successalert = true;
        this.successMessage = res.message;
        setTimeout(() => {
          this, (this.successalert = false);
        }, 4000);
        this.mobileNumber = '';
      },
      (err) => {
        this.erroralert = true;
        this.errorMessage = err.error.message;
        // $('#bookingFormotp').modal('hide');
        this.modalService.dismissAll();
        this.mobileNumber = '';
        setTimeout(() => {
          this, (this.erroralert = false);
        }, 4000);
      }
    );
  }
  otp: any;
  getverification(formmodal:any) {
    var obj = {
      otp: this.otp,
    };
    this.landingservice.verifyotp(obj, this.bookingformId).subscribe(
      (res) => {
        if (!res) {
          this.loader = false;
          this.erroralert = true;
          this.errorMessage = 'some thing error accured';
          this.loader = false;
          setTimeout(() => {
            this, (this.erroralert = false);
          }, 4000);
        }
        this.otp = '';
        // $('#bookingFormverification').modal('hide');
        this.modalService.dismissAll();
        this.modalService.open(formmodal, {
          centered: true,
          backdrop: 'static'
        })
        // $('#bookingFormdownload').modal('show');

        this.successalert = true;
        this.successMessage = 'OTP verification is successfully';
        this.getcustomerDetails();
        setTimeout(() => {
          this, (this.successalert = false);
        }, 4000);
      },
      (err) => {
        this.erroralert = true;
        this.errorMessage = err.error.message;

        setTimeout(() => {
          this, (this.erroralert = false);
        }, 4000);
      }
    );
  }
  bookingformdown: any;
  getDownloadBookingform() {
    this.loader = true;
    this.landingservice.downloadBookingForm(this.bookingformId).subscribe(
      (res) => {
        if (!res) {
          this.loader = false;
          this.erroralert = true;
          this.errorMessage = 'some thing error accured';
          this.loader = false;
          setTimeout(() => {
            this, (this.erroralert = false);
          }, 4000);
        }
        this.loader = false;
        // $('#bookingFormdownload').modal('hide');
        this.bookingformdown = res;
        var contentType = 'application/pdf';
        var b64Data = this.bookingformdown['project_booking_form_base_64'];
        var name = this.bookingformdown['file_name'];
        var blob = this.b64toBlob(b64Data, contentType, 512);
        var blobUrl = URL.createObjectURL(blob);
        let dwldLink = document.createElement('a');
        dwldLink.setAttribute('href', blobUrl);
        dwldLink.setAttribute('download', name);
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
        this.successalert = true;
        this.successMessage = 'Your booking form has downloaded successfully';
        setTimeout(() => {
          this, (this.successalert = false);
        }, 4000);
      },
      (err) => {
        this.erroralert = true;
        this.errorMessage = err.message;
        this.loader = false;
        setTimeout(() => {
          this, (this.erroralert = false);
        }, 4000);
      }
    );
  }
  otpresend() {
    var obj = {
      mobilenumber: this.mobileNumber,
    };
    this.landingservice.sendotp(obj, this.bookingformId).subscribe(
      (res: any) => {
        // $('#bookingFormverification').modal('show');
        // $('#bookingFormotp').modal('hide');
        this.successalert = true;
        this.successMessage = res.message;
        setTimeout(() => {
          this, (this.successalert = false);
        }, 4000);

        this.mobileNumber = '';
      },
      (err) => {
        this.erroralert = true;
        this.errorMessage = err.error.message;

        setTimeout(() => {
          this, (this.erroralert = false);
        }, 4000);
      }
    );
  }
  upcoming_events: any;
  detailsForTheEvents: any;
  user_events: any;
  past_events: any;
  upcomingevents() {
    this.landingservice.getUpcomingevents(this.projectId).subscribe((res) => {
      this.user_events = res;
      this.detailsForTheEvents = this.user_events.date_details;
      this.upcoming_events = this.user_events.upcoming_events;
      this.past_events = this.user_events.past_events;
    });
  }
  myInspirations: any;
  Inspirations: any;
  getInspirations() {
    this.landingservice.getInspirations(this.projectId).subscribe((res) => {
      this.myInspirations = res;
      this.Inspirations = this.myInspirations.customer_inspirations;
    });
  }
  imgfile: any;
  fileId: any;
  getURL: any;

  getImage(data: any) {
    this.imgfile = data.file_info.url;
    this.fileId = data.id;
  }
  deletefile() {
    this.landingservice
      .DeleteInspirations(this.projectId, this.fileId)
      .subscribe((res) => {
        $('#exampleModalLong2').modal('hide');
        $('#exampleModalLong3').modal('hide');
        this.successalert = true;
        this.successMessage = 'Your Inspiration File Deleted SuccessFully';
        setTimeout(() => {
          this, (this.successalert = false);
          this.getInspirations();
        }, 2000);
      });
  }

  logout() {
    if (confirm('Are You Sure to Logout') == true) {
      localStorage.removeItem('loginuser');
      localStorage.removeItem('logintoken');
      localStorage.removeItem('cname');
      localStorage.removeItem('client');
      localStorage.removeItem('acces_token');
      localStorage.removeItem('ccname');
      this.successalert = true;
      this.successMessage = 'Your Logout Is Sucessfull';
      setTimeout(() => {
        this, (this.successalert = false);
      }, 4000);

      this.router.navigate(['/']);
    }
  }
  warrantydownload() {
    this.successalert = true;
    this.successMessage = ' your Warrant Document is Downloaded  Sucessfully';
    setTimeout(() => {
      this, (this.successalert = false);
      window.open('../../../assets/images/ArrivaeWarrantyCard.pdf', '_blank');
    }, 2000);
  }
  logout2() {
    localStorage.removeItem('loginuser');
    localStorage.removeItem('logintoken');
    localStorage.removeItem('cname');
    localStorage.removeItem('client');
    localStorage.removeItem('acces_token');
    localStorage.removeItem('ccname');
    this.successalert = true;
    this.successMessage = 'Your Logout Is Sucessfull';
    setTimeout(() => {
      this, (this.successalert = false);
    }, 4000);

    this.router.navigate(['/']);
  }

  modalTypeData: any;
  modalDateData: any;
  openUpdateModal(
    modalForm:any,
    type: any,
    date: any,
    eventId: any,
    locationData: any,
    contactType: any,
    emailsData: any,
    descriptionData: any,
    eventRemark: any
  ) {
    this.modalService.open(modalForm,{
      centered: true,
      backdrop: 'static'
    });
    this.modalTypeData = type;
    this.modalDateData = date;
    this.eventId = eventId;
    this.locationData = locationData;
    this.contactType = contactType;
    this.emailsData =
      emailsData && emailsData.length > 0
        ? emailsData
            .map(function (elem: any) {
              return elem.email;
            })
            .join(';')
        : '';
    this.descriptionData = descriptionData;
    this.notesVal = '';

  }

  closeUpdateModal() {
    $('#closeUpdateModal').modal('hide');
  }
  closePaymentModal(){
    $('#paymentModal').modal('hide');
  }

  newDate: any;
  reasonNewDateHandler(e: any) {
    this.newDate = e.target.value;
  }

  selectReason: any;
  reasonDropDownHandler(e: any) {
    this.selectReason = e.target.value;
  }

  notesVal: any;
  reasonNotesHandler(e: any) {
    this.notesVal = e.target.value;
  }

  newDateErr: any = false;
  notesValErr: any = false;
  rescheduleHandlerHandler() {
    this.newDate ? (this.newDateErr = false) : (this.newDateErr = true);
    this.notesVal ? (this.notesValErr = false) : (this.notesValErr = true);
    if (this.newDate && this.notesVal) {
      this.rescheduleHandler();
      this.modalService.dismissAll();
    }
  }


  rescheduleHandler() {
    this.landingservice
      .rescheduleService(
        this.eventId,
        this.projectId,
        this.leadId,
        this.modalTypeData,
        this.newDate,
        this.notesVal,
        this.locationData,
        this.contactType,
        this.emailsData,
        this.descriptionData
      )
      .subscribe(
        (res) => {
          this.getcustomerDetails();
          // this.closeUpdateModal();
          (this.newDate = ''), (this.notesVal = ''), (this.successalert = true);
          this.successMessage = 'Date details updated Successfully';
          this.notesVal  = ''
          setTimeout(() => {
            this, (this.successalert = false);
          }, 4000);
        },
        (err) => {
          this.erroralert = true;
          this.errorMessage = err.error.message;
          setTimeout(() => {
            this, (this.erroralert = false);
          }, 4000);
        }
      );
  }
  getReport() {
    this.landingservice.getreport(this.projectId).subscribe(
      (res) => {
        this.fileContent = res;
        this.loader = false;
        var contentType = 'xlsx';
        var b64Data = this.fileContent['file'];
        var name = this.fileContent['file_name'];
        var blob = this.b64toBlob(b64Data, contentType, 512);
        var blobUrl = URL.createObjectURL(blob);
        let dwldLink = document.createElement('a');
        dwldLink.setAttribute('href', blobUrl);
        dwldLink.setAttribute('download', name);
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
        this.successalert = true;
        this.successMessage = 'Your report is  downloaded successfully';
        setTimeout(() => {
          this, (this.successalert = false);
        }, 4000);
      },
      (err) => {
        this.erroralert = true;
        this.errorMessage = err.error.message;
        setTimeout(() => {
          this, (this.erroralert = false);
        }, 4000);
      }
    );
  }

  eventString: any = 'upcoming';
  eventTypes(e: any) {
    this.eventString = e;
  }

  loader_explore: boolean = false;
  nextItemPage: any = 1;
  TotalItems: any;
  previousValue: any;
  exploreMoreItems: any;

  initialLosseValue: any;


  looseFurniture(e: any) {
    this.nextItemPage = 1;
    this.initialLosseValue = e;
    this.loader_explore = true;
    if (this.initialLosseValue === 'appliances') {
      this.landingservice
        .moreItemsToExploreForCustomer('1', '6')
        .subscribe((res: HttpResponse<any>) => {
          this.exploreMoreItems = JSON.parse(res.body);
          this.TotalItems = res.headers.get('x-total');
          this.TotalItems = Math.round(this.TotalItems / 6);
          setTimeout(() => {
            this.loader_explore = false;
          }, 2000);
        });
    }
    if (this.initialLosseValue === 'furniture') {
      this.landingservice
        .customMoreItemsForCustomer('1', '6')
        .subscribe((res: HttpResponse<any>) => {
          this.exploreMoreItems = JSON.parse(res.body);
          this.TotalItems = res.headers.get('x-total');
          this.TotalItems = Math.round(this.TotalItems / 6);
          setTimeout(() => {
            this.loader_explore = false;
          }, 2000);
        },
        err=>{
        });
    }
  }





  previousButton(e: any) {
    this.loader_explore = true;
    if (this.nextItemPage === 1) {
      this.TotalItems--;
      this.previousValue = this.TotalItems;
      this.nextItemPage = this.previousValue;
    }

    if (this.nextItemPage > 1) {
      this.previousValue = this.nextItemPage - 1;
      this.nextItemPage = this.previousValue;
    }
    if (e === 'furniture') {
      this.landingservice
        .customMoreItemsForCustomer(this.previousValue, '6')
        .subscribe((res: HttpResponse<any>) => {
          this.exploreMoreItems = JSON.parse(res.body);
          setTimeout(() => {
            this.loader_explore = false;
          }, 2000);
        });
    }
    if (e === 'appliances') {
      this.landingservice
        .moreItemsToExploreForCustomer(this.previousValue, '6')
        .subscribe((res: HttpResponse<any>) => {
          this.exploreMoreItems = JSON.parse(res.body);
          setTimeout(() => {
            this.loader_explore = false;
          }, 2000);
        });
    }
  }
  nextButton(e: any) {
    this.loader_explore = true;
    this.nextItemPage++;

    if (this.nextItemPage > this.TotalItems) {
      this.nextItemPage = 1;
    }
    if (e === 'furniture') {
      this.landingservice
        .customMoreItemsForCustomer(this.nextItemPage, '6')
        .subscribe((res: HttpResponse<any>) => {
          this.exploreMoreItems = JSON.parse(res.body);
          setTimeout(() => {
            this.loader_explore = false;
          }, 2000);
        });
    }
    if (e === 'appliances') {
      this.landingservice
        .moreItemsToExploreForCustomer(this.nextItemPage, '6')
        .subscribe((res: HttpResponse<any>) => {
          this.exploreMoreItems = JSON.parse(res.body);
          setTimeout(() => {
            this.loader_explore = false;
          }, 2000);
        });
    }
  }
}