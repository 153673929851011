import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { LandingService } from '../landing.service';
import { ToastrService } from 'ngx-toastr';
import { Meta } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  MadeByStoryViewData,
  MadeByYouStoryData,
  MadeByStoriesFirst,
  MadeByYouStories,
} from '../data';
declare var $: any;
var currentIndex: any;
@Component({
  selector: 'app-made-by-you-stories',
  templateUrl: './made-by-you-stories.component.html',
  styleUrls: ['./made-by-you-stories.component.scss'],
})
export class MadeByYouStoriesComponent implements OnInit {
  selectedCity: any;
  cityData: any;
  citiesData: any;
  city_id: any;
  points: any;
  experience: any;
  linksActive: any;
  multiCenters: any;
  singleCenters: any;
  tokenForMagic: any;
  loader: boolean = false;
  accessToken: any;
  url: any;
  closeAndBack: boolean = false;
  @Input() verifyUser: any;
  mobileNumber: any;
  leadInvitationId: any;
  enteredOptNumber: any;
  data: any;
  id: any;
  activTab: any = 0;

  nextFirst(quote: any, submit: any) {
    $('#' + quote).show();
    $('#' + submit).hide();
  }

  constructor(
    private landingService: LandingService,
    private router: Router,
    private toastr: ToastrService,
    private metaService: Meta,
    private cdRef: ChangeDetectorRef,
    private modalService: NgbModal
  ) {}
  carouselImages: any = [
    {
      url: './assets/images/Landing_page_updated/Arrivae_Banner1-min.png',
      alt: 'Arrivae Banner 01',
    },
    {
      url: './assets/images/Landing_page_updated/Arrivae_Banner2-min.png',
      alt: 'Arriave Banner 02',
    },
    {
      url: './assets/images/Landing_page_updated/Arrivae_Banner3-min.png',
      alt: 'Arrivae Banner 03',
    },
    {
      url: './assets/images/Landing_page_updated/Arrivae_Banner4-min.png',
      alt: 'Arrivae Banner 04',
    },
  ];

  madeByYouStory(id: any) {
    this.router.navigate(['/made-by-you-detail'], {
      queryParams: { id: this.id },
    });
  }
  verifyOtp() {
    this.landingService
      .optVerification(this.enteredOptNumber, this.leadInvitationId)
      .subscribe(
        (res: any) => {
          if (res) {
            this.boolvalue = true;
            this.l_lead_id = res.data.lead_id;
            $('#getQuoteModal').modal('hide');
            this.toastr.success(res.data.message);
            this.enteredOptNumber = '';
          }
        },
        (err: any) => {
          this.toastr.error(err.error.data.message);
          this.enteredOptNumber = '';
        }
      );
  }

  madebyyouModal() {
    if (
      $('#made-by-you-container').hasClass('made-by-you-container-header-show')
    ) {
      this.ViewNextContentIndex = 0;
      $('#madebyyouModal').modal('hide');
      $('#made-by-you-container').removeClass(
        'made-by-you-container-header-show'
      );
    } else {
      $('#madebyyouModal').modal('show');
      $('#made-by-you-container').addClass('made-by-you-container-header-show');
    }
    this.setSecondNumber = this.data;
    this.closeAndBack = true;
    this.image_container =
    this.ViewNextContent[this.ViewNextContentIndex].images;
    this.loader=true;
    setTimeout(() => {
      this.loader = false;
    }, 2000);
  }
  setSecondNumber: any;

  closesecondReset() {
    this.verifyUser = !this.verifyUser;
    $('#madebyyouHeader').modal('hide');
    this.nextFirst('GetQuote', 'querySubmit');
    this.mobileNumber = '';
  }

  ngOnInit(): void {
    console.log('Images to check', this.next_images);
    this.initSlickSlide();
    this.loadScript();
    $('.numberCountUp').counterUp({ delay: 10, time: 1000 });
    this.url = localStorage.getItem('loginuser');
    this.accessToken = localStorage.getItem('access-token')
      ? localStorage.getItem('access-token')
      : '';
    localStorage.setItem('logintoken', this.url);
    let tokenMagic = this.url.split('=');
    this.tokenForMagic = tokenMagic[1];
    this.fetchPoints();
    this.magicLogin();
    this.animateIntroText();
    this.animateStatsPanel();

    $('#getQuoteModal').on('hide.bs.modal', function () {
      if ($('#made-by-you-container').hasClass('made-by-you-container-show')) {
        $('#made-by-you-container').removeClass('made-by-you-container-show');
        $('#made-by-you-container').modal('hide');
      }
    });
    this.metaService.addTag({
      name: 'description',
      content:
        'End-to-end Full Home Interior Solutions & India’s first Made-To-Order Furniture Manufacturing Company. PAN India Presence in 21 Cities | 5 Experience Centers | 3 Manufacturing Units | 35 Experience Points | Robust Aftersales Support',
    });
    this.loadScript();
    currentIndex = $('div.active').index();
    this.activTab = currentIndex;
    let that = this;
    $("#carouselExampleControl1").on('slide.bs.carousel', function () {
    currentIndex = $('div.active').index();
    that.activTab = currentIndex;
      // if(that.activTab != currentIndex){
      //   that.gotoSlide(currentIndex)
      // }

    });
  }
  cname: any;

  ngOnDestroy() {
    this.metaService.removeTag("name='description'");
  }

  magicLogin() {
    this.landingService.Magiclogin(this.tokenForMagic).subscribe((res) => {
      this.cname = res;
      this.loader = true;
      setTimeout(() => {
        this, (this.loader = false);
        this.router.navigate(['/customer-dashboard']);
      }, 4000);

      localStorage.setItem('cname', this.cname.user.name);
    });
  }
  routeToDetialsPageHandle(items: any) {
    window.open(`/made-by-you-detail?id=${items?.id}`, '_blank');
  }

  checkVideo() {
    var iframes = document.querySelectorAll('iframe');
    iframes[0].src = iframes[0].src;
    iframes[1].src = iframes[1].src;
    iframes[2].src = iframes[2].src;
    iframes[3].src = iframes[3].src;
    iframes[4].src = iframes[4].src;
  }

  fetchPoints() {
    if (localStorage.getItem('selectedCity') == null) {
      this.selectedCity = 'mumbai';
    } else {
      this.selectedCity = localStorage.getItem('selectedCity');
    }
    this.landingService.getCities().subscribe((data) => {
      this.cityData = data;
      this.citiesData = this.cityData.data;
      for (let ct of this.citiesData) {
        if (ct.attributes.name == this.selectedCity) {
          this.city_id = ct.id;
        }
      }

      this.landingService.getpoints(this.city_id).subscribe((res) => {
        if (res) {
          this.points = res;
          this.experience = this.points.data;
          if (this.experience.length > 1) {
            this.multiCenters = true;
            this.singleCenters = false;
          }
          if (this.experience.length == 1) {
            this.singleCenters = true;
            this.multiCenters = false;
          }
        }
      });
    });
  }

  customFunc(data: any) {
    this.selectedCity = data.selectedCity;
    this.fetchPoints();
  }

  animateIntroText() {
    setTimeout(() => {
      $('#intro-text').addClass('intro-text-show');
    }, 500);
  }

  animateStatsPanel() {
    setTimeout(() => {
      $('#stats-panel').addClass('stats-panel-show');
    }, 500);
  }

  quoteModalShowHide() {
    if ($('#made-by-you-container').hasClass('made-by-you-container-show')) {
      $('#madebyyouModal').modal('hide');
      $('#made-by-you-container').removeClass('made-by-you-container-show');
    } else {
      $('#madebyyouModal').modal('show');
      $('#made-by-you-container').addClass('made-by-you-container-show');
    }
  }

  getquoteModal() {
    if ($('#get-quote-container').hasClass('get-quote-container-show')) {
      $('#getQuoteModal').modal('hide');
      $('#get-quote-container').removeClass('get-quote-container-show');
    } else {
      $('#getQuoteModal').modal('show');
      $('#get-quote-container').addClass('get-quote-container-show');
    }
  }

  boolvalue: boolean = false;
  l_lead_id: any;
  namephn: any;
  imageObject: any = MadeByYouStoryData;
  ViewNextContent: any = MadeByStoryViewData;
  ViewContent: any = MadeByStoriesFirst;
  custData: any = MadeByYouStories;
  images: any = MadeByStoriesFirst;
  imageUrl: any = MadeByYouStoryData;
  ViewNextContentIndex: any = 0;
  ViewNextContentNextName: any = this.ViewNextContent[1].heading;
  ViewNextContentPreviousName: any =
    this.ViewNextContent[this.ViewNextContent.length - 1].heading;

  landing_images: any = [
    {
      image:
        '../../../assets/images/Landing_page_updated/project_gallery/Varinder_bansal/image (5).png',
    },
    {
      image:
        '../../../assets/images/Landing_page_updated/project_gallery/Varinder_bansal/image (6).png',
    },
    {
      image:
        '.../../../assets/images/Landing_page_updated/project_gallery/Varinder_bansal/image (7).png',
    },
    {
      image:
        '../../../assets/images/Landing_page_updated/project_gallery/Varinder_bansal/image (8).png',
    },
  ];

  active_slide: any = 0;

  initSlickSlide() {
    $(document).ready(function () {
      $('.your-class').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: $('#next-gallery-button'),
        prevArrow: $('#previous-gallery-button'),
        autoplay: true,
      });
    });
    $('.your-class').on(
      'afterChange',
      (event: any, slick: any, currentSlide: any, nextSlide: any) => {
        var current = currentSlide;
        this.active_slide = current;
        this.cdRef.detectChanges();
      }
    );
    $(document).ready(function () {
      $('.carousel-class').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: true,
        nextArrow: $('#next-carousel-button'),
        prevArrow: $('#previous-carousel-button'),
      });
    });
    $('.carousel-class').on(
      'afterChange',
      (event: any, slick: any, currentSlide: any, nextSlide: any) => {
        var current = currentSlide;
        this.active_slide = current;
        this.cdRef.detectChanges();
      }
    );
    $(document).ready(function () {
      $('.images-class').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: $('#next-images-button'),
        prevArrow: $('#previous-images-button'),
        autoplay: true,
      });
    });
    $('.images-class').on(
      'afterChange',
      (event: any, slick: any, currentSlide: any, nextSlide: any) => {
        var current = currentSlide;
        this.active_slide = current;
        this.cdRef.detectChanges();
      }
    );
    $(document).ready(function () {
      $('.right-class').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: $('#next-right-button'),
        prevArrow: $('#previous-right-button'),
        autoplay: true,
      });
    });
    $('.right-class').on(
      'afterChange',
      (event: any, slick: any, currentSlide: any, nextSlide: any) => {
        var current = currentSlide;
        this.active_slide = current;
        this.cdRef.detectChanges();
      }
    );
  }
  gotoSlide(number: any) {
    $('.carousel').carousel(number);
    this.activTab = number
  }

  scrollToSummary(e: HTMLElement) {
    e.scrollIntoView();
    this.linksActive = 'cardContainer';
  }

  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  otpVerification() {
    var regexPhoneNo = /^\+?([0-9]{2})?[0-9]{10}$/;
    if (this.mobileNumber && this.mobileNumber.match(regexPhoneNo)) {
      // get last 10 character before sending
      this.landingService.optGeneration(this.mobileNumber.slice(-10)).subscribe(
        (res: any) => {
          if (res) {
            this.namephn = this.mobileNumber;
            this.leadInvitationId = res.data.lead_invitation_id;
            this.toastr.success(res.data.message);
            this.mobileNumber = '';
            // this.getquoteModal();
            $('#getQuoteModal').modal('show');
            $('#get-quote-container').addClass('get-quote-container-show');
          }
        },
        (err) => {
          this.toastr.error(err.error.data.message);
        }
      );
    } else {
      this.toastr.error('Please Enter valid mobile number.');
    }
  }
  prevSlider() {
    // this.ViewNextContentIndex = this.ViewNextContentIndex - 1;
    // document.getElementById("madebyyouModal")?.scrollIntoView();
    // $("#made-by-you-container").scrollTop(0);
    if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/)) {
      document.getElementById("made-by-you-container")?.scrollIntoView();
      $("#made-by-you-container").scrollTop(0);
    }else{
      $("#made-by-you-container").scrollTop(0);
    }
    if (this.ViewNextContentIndex == 1) {
      this.ViewNextContentIndex--;
      // this.ViewNextContentIndex = this.ViewNextContent.length - 1;
      // -------
      this.ViewNextContentNextName = this.ViewNextContent[1].heading;
      this.ViewNextContentPreviousName =
        this.ViewNextContent[this.ViewNextContent.length - 1].heading;
    } else {
      this.ViewNextContentIndex--;
      // -------
      this.ViewNextContentPreviousName =
        this.ViewNextContent[this.ViewNextContentIndex - 1].heading;
      this.ViewNextContentNextName =
        this.ViewNextContent[
          this.ViewNextContentIndex + 1 == 8 ? 0 : this.ViewNextContentIndex + 1
        ].heading;
      this.image_container =
        this.ViewNextContent[this.ViewNextContentIndex].images;
    }
  }

  image_container: any = [];
  nextSlider() {
    // this.ViewNextContentIndex = this.ViewNextContentIndex + 1;
    // $("#made-by-you-container").scrollTop(0);
    if (navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/)) {
      document.getElementById("made-by-you-container")?.scrollIntoView();
      $("#made-by-you-container").scrollTop(0);
    }else{
      $("#made-by-you-container").scrollTop(0);
    }
    if (this.ViewNextContentIndex === this.ViewNextContent.length - 1) {
      this.ViewNextContentIndex = 0;
      // -------
      this.ViewNextContentNextName = this.ViewNextContent[0].heading;
      this.ViewNextContentPreviousName =
        this.ViewNextContent[this.ViewNextContent.length - 1].heading;
    } else {
      this.ViewNextContentIndex++;
      // -------
      this.ViewNextContentNextName =
        this.ViewNextContent[
          this.ViewNextContentIndex + 1 == 8 ? 0 : this.ViewNextContentIndex + 1
        ].heading;
      this.ViewNextContentPreviousName =
        this.ViewNextContent[this.ViewNextContentIndex - 1].heading;
      this.image_container =
        this.ViewNextContent[this.ViewNextContentIndex].images;
    }
    
  }

  next_images: any = MadeByStoriesFirst;

  // next_images: any = [];
  viewNextImage() {
    this.ViewNextContentIndex = this.ViewNextContentIndex + 1;
    if (this.ViewNextContentIndex === this.ViewNextContent.length - 1) {
      this.ViewNextContentIndex = 0;
    } else {
      this.ViewNextContentIndex++;
      this.next_images = this.ViewNextContent[this.ViewNextContentIndex].images;
    }
  }
}
