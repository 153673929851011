<div class="container-fluid1" >
  <video #videoPlayer autoplay loop id="background-video" preload="auto" class="d-block d-md-none">
    <source src="/assets/videos/30 Sec- The Botanika Arrivae .mp4" type="video/mp4">
    Your browser does not support the video tag.
  </video>
  <div class=" d-flex flex-column d-md-none justify-content-center align-items-center p-5">
    <h1 class="uppercase text-center">Dream Home Interiors Made Easy</h1>
    <img class="py-4" src="assets/shortform/images/Ho jayega logo 1.webp" alt="" style="width: 200px; margin: auto;">
  </div>
</div>


<div class="container-fluid p-md-5" >
    <video #videoPlayer2 autoplay loop id="background-video" preload="auto" class="d-none d-md-block">
      <source src="/assets/videos/30 Sec- The Botanika Arrivae .mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
    <div class="row pb-md-4 m-md-5 d-flex justify-content-around m-0">
        <div class="col-md-5 d-none d-md-flex flex-column mt-md-4 justify-content-center">
            <h1 class="uppercase">Dream Home Interiors Made Easy</h1>
            <img class="py-4" src="assets/shortform/images/Ho jayega logo 1.webp" alt="" style="width: 300px;">

        </div>
        <div class="col-md-7 pr-lg-5 col-12 mt-md-4 d-flex flex-column flex-md-row justify-content-md-end align-items-center p-0 ">

            <form action="" class="p-4 m-3 w-100"  [formGroup]= "GetQuoteForm" >
                <h3 class="d-none d-md-block pb-4 uppercase">
                    Set up your FREE consultation today!
                </h3>
                <!-- Full Name -->
                <div class="my-3 form-group">
                    <label for="fullName" class="form-label">Full Name*</label>
                    <input id="fullName" type="text" formControlName="fullName" class="form-control"
                        placeholder="Enter your name">
                    <div *ngIf="GetQuoteForm.get('fullName')?.invalid && GetQuoteForm.get('fullName')?.touched"
                        class="text-danger error">
                        Full name is required.
                    </div>
                </div>

                <!-- Mobile Number and WhatsApp Updates -->
                <div class="mb-3 form-group">
                    <label for="mobileNumber" class="form-label">Mobile Number*</label>
                    <input id="mobileNumber" type="text" formControlName="mobileNumber" 
                        class="form-control numbers" placeholder="Enter your mobile number" 
                        oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);">
                    <div *ngIf="GetQuoteForm.get('mobileNumber')?.invalid && GetQuoteForm.get('mobileNumber')?.touched"
                        class="text-danger error">
                        A valid mobile number is required.
                    </div>
                </div>

                <div class="mb-3 form-group">
                    <label for="email" class="form-label">Email*</label>
                    <input id="email" type="email" formControlName="email" class="form-control"
                        placeholder="Enter your email">
                    <div *ngIf="GetQuoteForm.get('email')?.invalid && GetQuoteForm.get('email')?.touched"
                        class="text-danger error">
                        A valid email is required.
                    </div>
                </div>

                

                <!-- Society Name -->
                <div class="mb-3 form-group">
                    <label for="society" class="form-label">Society Name*</label>                   

                    <div class="w-100" (mouseleave)="hideOptions()" (mouseenter)="showOptions()">
                        <input type="text" placeholder="Property Name" formControlName="society"
                            class="form-control text_feild mt-1 w-100" (keyup)="searchSocietyApi($event)">
                        <ul class="adressSelect" *ngIf="isApifired && !isSelected && this.GetQuoteForm.controls['society'].value != ''">
                            <li *ngFor="let option of filteredOptions " (click)="selectOption(option)"><span class="d-inline-block h-100"><i
                                class="fa fa-map-marker pr-1" aria-hidden="true" style="
                                font-size: 22px;vertical-align: middle; "></i></span> 
                                <span class="d-inline-block ml-1">
                                    {{option.apartment_name }} {{ option.address }}
                                </span>
                            </li>
                            <li *ngIf="!isSelected">
                                <span class="d-inline-block h-100">
                                    <i class="fa fa-map-marker pr-1" aria-hidden="true" style="
                                                 font-size: 22px;vertical-align: middle; "></i></span> <span class="d-inline-block ml-1">
                                    Could not find your property?
                                    <button class="submit_btn w-100 mt-3" (click)="selectOption('other')">Add Property
                                        Details</button></span>
                            </li>
                        </ul>
                    </div>

                    <div *ngIf="GetQuoteForm.get('property_name')?.invalid && GetQuoteForm.get('society')?.touched"
                        class="text-danger error">
                        Society name is required.
                    </div>
                </div>

                <!-- Row for Budget and Pincode -->

                <div class=" mb-3 form-group">
                  <label for="pincode" class="form-label">Property Pincode*</label>
                  <input id="pincode" type="text" formControlName="pincode" class="form-control numbers"
                      readonly placeholder="Pincode">
              </div>

                <button 
                    class="reqestbtn btn btn-pink rounded-pill d-none d-md-flex justify-content-center uppercase w-100 mb-2" 
                    (click)="DetailsSubmit()" >
                    Request a call back
                </button>
                <!-- [disabled]="!this.GetQuoteForm.valid" -->



            </form>

            <button 
                class="reqestbtn btn btn-pink py-2 my-3 w-100 d-md-none uppercase " 
                (click)="DetailsSubmit()" >
                Request a call back
            </button>
<!-- [disabled]="!this.GetQuoteForm.valid" -->

        </div>
    </div>
</div>

<div class="loading d-flex justify-content-center" *ngIf="loader">
  <div class="spinner-border" style="color: #8d0528" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>


<div id="OtherprojectName2" class="modal" role="dialog" data-backdrop="static">
    <div class="modal-dialog modal-dialog-centered"  style="max-width: 50rem !important;"  >
      <!-- Modal content-->
      <div class="modal-content p-1">
        <div class="modal-header">
          <h6 class="modal-title w-100 text-center"> Add Society/Building/Project </h6>
          <button (click)="closeaddApartmentrplanModal()" type="button" class="close"><i class="fa fa-close"></i></button>
  
        </div>
        <div class="modal-body " >
          <div class="col-12 row">
            <div  class="form-check d-flex col-12 "  >
              <label   class="customLabel mt-2 col-6">Society/Building/Project Name :<span
                class="text-danger hideAsteriskIcon">*</span></label>
              <input  type="text"  class="form-control col-6" [(ngModel)]="projectName2"
                placeholder="Enter the Project name">
            </div>
            <div  class="form-check  d-flex col-12"  >
              <label   class="customLabel mt-2 col-6 ">Developer Name :<span
                class="text-danger hideAsteriskIcon">*</span></label>
              <input type="text"   class="form-control col-6"
                placeholder="Enter the Developer name" [(ngModel)]="DeveloperName2">
            </div>
            <div  class="form-check d-flex col-12"  >
              <label   class="customLabel mt-2  col-6">Full Address :<span
                class="text-danger hideAsteriskIcon">*</span></label>
                <input  [(ngModel)]="FullAdress2"  type="text"  class="form-control"   ngx-google-places-autocomplete #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"
                placeholder="Enter the Full Address">
  
            </div>
            <div  class="form-check  d-flex col-12"  >
              <label   class="customLabel mt-2 col-6">City :<span
                class="text-danger ">*</span></label>
              <input [(ngModel)] = "citynamePro2"  readonly type="text"  class="form-control col-6" >
            </div>
  
            <div  class="form-check d-flex col-12"  >
              <label   class="customLabel mt-2 col-6">Pincode :<span
                class="text-danger ">*</span></label>
              <input [(ngModel)] = "pincodePro2"  type="text"   class="form-control col-6"
                 >
                
            </div>
          </div>
          <div class="col-12 mt-2 d-flex justify-content-center">
  
            <button class="btn btn-pink px-3 py-2" [disabled]="projectName2 == '' || DeveloperName2 == '' ||  FullAdress2 == '' || pincodePro2 == ''   " (click)="Submitapart()">Submit</button>
  
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="loading d-flex justify-content-center" *ngIf="showLoader">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>