<div class="instagram ">
   <div class="d-flex flex-column justify-content-start mt-5 mb-2 mx-5 main-heading">
      <p class="mb-1 font-weight-bold"><span class="pb-1">CONNECT WITH US</span></p>
      <span class="keep-insta" >Keep up with all things Arrivae on Instagram</span>
      <a href="https://www.instagram.com/arrivae/?hl=en"  target="_blank" >
         <button *ngIf="roletoSet() >= 650" type="button"  class="mt-2 button d-flex align-items-center follow_now">
            FOLLOW NOW&nbsp;
           <span>
              <img src="../../../assets/images/Arrow 1.png"/>
           </span>
         </button>
      </a>
   </div>
    <!-- <div class="Buildings_container justify-content-between row mx-0 mt-5">
       <ng-container  *ngFor="let pic of InstaData ; let i =index " >


         <div *ngIf="i<5" class="col-lg-2 col-md-2 col-sm-6 pl-0 cursor-pointer">
            <a href="{{pic.permalink}}" target="_blank" >
               <div  class="image_carrier d-flex align-items-end" >
                  <img class="w-100 h-100" src={{pic.media_url}}>
               </div>
            </a>
         </div>
         

       </ng-container>
      </div> -->

      <div  *ngIf="roletoSet() >= 650" class="mt-md-5" style="margin:0px 54px;">
         <div class="row m-0 insta-post">
            <a href="https://www.instagram.com/arrivae/?hl=en"  target="_blank" class="col-sm-3 post px-1 cursorPointer ">
               <img  src="../../../assets/images/newInsta/21.png"/>
            </a>
            <a href="https://www.instagram.com/arrivae/?hl=en"  target="_blank" class="col-sm-3 post px-1 cursorPointer">
               <img  src="../../../assets/images/newInsta/22.png"/>
            </a>
            <a href="https://www.instagram.com/arrivae/?hl=en"  target="_blank" class="col-sm-3 post px-1 cursorPointer">
               <img  src="../../../assets/images/newInsta/23.png"/>
            </a>
            <a href="https://www.instagram.com/arrivae/?hl=en"  target="_blank" class="col-sm-3 post px-1 cursorPointer">
               <img  src="../../../assets/images/newInsta/24.png"/>
            </a>
      </div>
      </div>

      <div *ngIf="roletoSet() < 650" class="row mt-3 mt-md-5 insta-post w-100" slickContainer #slickController="slick" [slickConfig]="config">
            <div class="w-100" *ngIf="slickController.initialize">
               <a slickItem href="https://www.instagram.com/arrivae/?hl=en"  target="_blank" class="col-lg-3 post px-1 ">
                  <img  src="../../../assets/images/newInsta/21.png"/>
               </a>
               <a slickItem href="https://www.instagram.com/arrivae/?hl=en"  target="_blank" class="col-lg-3 post px-1">
                  <img  src="../../../assets/images/newInsta/22.png"/>
               </a>
               <a slickItem href="https://www.instagram.com/arrivae/?hl=en"  target="_blank" class="col-lg-3 post px-1">
                  <img  src="../../../assets/images/newInsta/23.png"/>
               </a>
               <a slickItem href="https://www.instagram.com/arrivae/?hl=en"  target="_blank" class="col-lg-3 post px-1">
                  <img  src="../../../assets/images/newInsta/24.png"/>
               </a>
            </div>
      </div>
      <div class="d-flex  justify-content-center mt-5 main-heading ">
         <a href="https://www.instagram.com/arrivae/?hl=en"  target="_blank " >
         <button *ngIf="roletoSet() < 650" type="button"  class=" button d-flex align-items-center justify-content-center follow_now">
            FOLLOW NOW&nbsp;
           <span>
              <img src="../../../assets/images/Arrow 1.png"/>
           </span>
         </button>
      </a>

      </div>
</div>
 