import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpacesComponent } from './spaces/spaces.component';
import { SpaceInclusionComponent } from './space-inclusion/space-inclusion.component';
import { HeaderComponent } from '../shared/header/header.component';
import { SharedModule } from '../shared/shared.module';
import { SpacesLandingComponent } from './spaces-landing/spaces-landing.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpacesGetThisDesignFormComponent } from './spaces-get-this-design-form/spaces-get-this-design-form.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import {NgxPaginationModule} from 'ngx-pagination';
import { LandingModule } from '../landing/landing.module';
import { UpdatedSpacesPageComponent } from './updated-spaces-page/updated-spaces-page.component';
import { UnderscorePipe } from '../underscore.pipe';
import { SpacesListingComponent } from './spaces-listing/spaces-listing.component';

@NgModule({
  declarations: [SpacesComponent, UnderscorePipe, SpaceInclusionComponent, SpacesLandingComponent, SpacesGetThisDesignFormComponent, UpdatedSpacesPageComponent, SpacesListingComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    LandingModule,
    RouterModule,
    NgxPaginationModule
  ],
  exports: [SpacesComponent,SpaceInclusionComponent]
})
export class SpacesModule { }
