import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectGalleryCarouselModalComponent } from '../project-gallery-carousel-modal/project-gallery-carousel-modal.component';

@Component({
  selector: 'app-project-gallery',
  templateUrl: './project-gallery.component.html',
  styleUrls: ['./project-gallery.component.scss'],
})
export class ProjectGalleryComponent implements OnInit {
  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}
  images = [
    '../../../assets/images/Project gallery/Abhijit Benerji/Section-4_Abhijit-Benerji_01.png',
    '../../../assets/images/Project gallery/Amar & Swati/Section-4_Amar-Swati_01.png',
    '../../../assets/images/Project gallery/Prathamesh Prabhu/Section-4_PrathameshPrabhu_01.png',
    '../../../assets/images/Project gallery/Rodrigues/Section-4_Rodrigues_01.png',
    '../../../assets/images/Project gallery/Roy/Section-4_Roy_01.png',
  ];

  projectGalleryModelComponent(position: string) {
    const modal = this.modalService.open(ProjectGalleryCarouselModalComponent, {
      size: 'lg',
      centered: true
    });

    modal.componentInstance.position = position;
    modal.componentInstance.modal = modal;
  }
}
