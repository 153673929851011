<app-new-header-for-home (sendData)="customFunc($event)" ></app-new-header-for-home>
<div class="rootdiv">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-11 text-justify root mb-5 px-lg-0">
        <h2 class="brand-color">Terms And Conditions</h2>
        <p class="title">
          Arrivae reserves the right, at its sole discretion, to change, modify, add or remove portions of these Terms
          of Use, at any time. It is your responsibility to check these Terms of Use periodically for changes. Your
          continued use of the Site following the posting of changes will mean that you accept and agree to the changes.
          As long as you comply with these Terms of Use, Arrivae grants you a personal, non-exclusive, non-transferable,
          limited privilege to enter and use the Site.
        </p>
        <p class="paragraph">
          These Terms and Conditions of Use (the “Terms of Use”) apply to the company – Singularity Furniture Private
          Limited (Arrivae) web site located at <a routerLink="/">www.arrivae.com</a>, and all associated sites linked to <a routerLink="/">www.arrivae.com </a>by
          Arrivae, its subsidiaries and affiliates, including Arrivae sites around the world (collectively, the “Site”).
          The Site is the property of Arrivae Inc. (“Arrivae”) and its licensors. BY USING THE SITE, YOU AGREE TO THESE
          TERMS OF USE; IF YOU DO NOT AGREE, DO NOT USE THE SITE
        </p>
        <p class="heading">Content</p>
        <p>
          All text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, artwork
          and computer code (collectively, “Content”), including but not limited to the design, structure, selection,
          coordination, expression, “look and feel” and arrangement of such Content, contained on the Site is owned,
          controlled or licensed by or to Arrivae, and is protected by trade dress, copyright, patent and trademark
          laws, and various other intellectual property rights and unfair competition laws.
        </p>
        <p>
          Except as expressly provided in these Terms of Use, no part of the Site and no Content may be copied,
          reproduced, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted or distributed
          in any way (including “mirroring”) to any other computer, server, Web site or other medium for publication or
          distribution or for any commercial enterprise, without Arrivae’s express prior written consent.
        </p>
        <p class="paragraph">
          You may use information on Arrivae products and services (such as data sheets, knowledge base articles, and
          similar materials) purposely made available by Arrivae for downloading from the Site, provided that you (1)
          not remove any proprietary notice language in all copies of such documents, (2) use such information only for
          your personal, non-commercial informational purpose and do not copy or post such information on any networked
          computer or broadcast it in any media, (3) make no modifications to any such information, and (4) not make any
          additional representations or warranties relating to such documents.
        </p>
        <p class="heading">Your Use of the Site</p>
        <p>
          You may not attempt to gain unauthorized access to any portion or feature of the Site, or any other systems or
          networks connected to the Site or to any Arrivae server, or to any of the services offered on or through the
          Site, by hacking, password “mining” or any other illegitimate means.
        </p>
        <p>
          You may not probe, scan or test the vulnerability of the Site or any network connected to the Site, nor breach
          the security or authentication measures on the Site or any network connected to the Site. You may not reverse
          look-up, trace or seek to trace any information on any other user of or visitor to the Site, or any other
          customer of Arrivae, including any Arrivae account not owned by you, to its source, or exploit the Site or any
          service or information made available or offered by or through the Site, in any way where the purpose is to
          reveal any information, including but not limited to personal identification or information, other than your
          own information, as provided for by the Site.
        </p>
        <p>
          You agree that you will not take any action that imposes an unreasonable or disproportionately large load on
          the infrastructure of the Site or Arrivae’s systems or networks, or any systems or networks connected to the
          Site or to Arrivae.
        </p>
        <p>
          You agree not to use any device, software or routine to interfere or attempt to interfere with the proper
          working of the Site or any transaction being conducted on the Site, or with any other person’s use of the
          Site.
        </p>
        <p class="paragraph">
          You may not use the Site or any Content for any purpose that is unlawful or prohibited by these Terms of Use,
          or to solicit the performance of any illegal activity or other activity which infringes the rights of Arrivae
          or others.
        </p>
        <p class="heading">Accounts, Passwords and Security</p>
        <p>
          Certain features or services offered on or through the Site may require you to open an account (including
          setting up an account and password). You are entirely responsible for maintaining the confidentiality of the
          information you hold for your account, including your password, and for any and all activity that occurs under
          your account as a result of your failing to keep this information secure and confidential. You agree to notify
          Arrivae immediately of any unauthorized use of your account or password, or any other breach of security. You
          may be held liable for losses incurred by Arrivae or any other user of or visitor to the Site due to someone
          else using your username, password or account as a result of your failing to keep your account information
          secure and confidential.
        </p>
        <p class="paragraph">
          You may not use anyone else’s username, password or account at any time without the express permission and
          consent of the holder of that username password or account. Arrivae cannot and will not be liable for any loss
          or damage arising from your failure to comply with these obligations.
        </p>
        <p class="heading">Violation of These Terms of Use</p>
        <p>
          You agree that Arrivae may, in its sole discretion and without prior notice, terminate your access to the Site
          and/or block your future access to the Site if we determine that you have violated these Terms of Use or other
          agreements or guidelines which may be associated with your use of the Site. You also agree that any violation
          by you of these Terms of Use will constitute an unlawful and unfair business practice, and will cause
          irreparable harm to Arrivae, for which monetary damages would be inadequate, and you consent to Arrivae
          obtaining any injunctive or equitable relief that Arrivae deems necessary or appropriate in such
          circumstances. These remedies are in addition to any other remedies Arrivae may have at law or in equity.
        </p>
        <p>
          If Arrivae does take any legal action against you as a result of your violation of these Terms of Use, Arrivae
          will be entitled to recover from you, and you agree to pay, all reasonable attorneys’ fees and costs of such
          action, in addition to any other relief granted to Arrivae. You agree that Arrivae will not be liable to you
          or to any third party for termination of your access to the Site as a result of any violation of these Terms
          of Use.
        </p>
      </div>
    </div>
  </div>
<app-footer></app-footer>
</div>