import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {LandingService} from '../landing.service'
import { ApartmentService } from 'src/app/apartment/apartment.service';
declare var $: any;

@Component({
  selector: 'app-sign-up-to-service',
  templateUrl: './sign-up-to-service.component.html',
  styleUrls: ['./sign-up-to-service.component.scss'],
})
export class SignUpToServiceComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private landingService: LandingService,
    private apartmentservice: ApartmentService
  ) {}

  ngOnInit(): void {
    this.createFirstForm();
    // this.cr
    this.createFourthForm();

    this.createSecondForm();
    this.loadScript()
  }
  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  signUpForm1: any = FormGroup;
  signUpForm2: any = FormGroup;
  signUpForm4: any = FormGroup;
  email_validation: boolean = false;
  bookdesignForm: any = FormGroup;

  to_show_form = true;
  status: boolean = false;
  selectedOptionItem: any;
  defaultOption: boolean = true;

  show_field: boolean = false;

  imageStyle_1: boolean = false;
  imageStyle_2: boolean = false;
  imageStyle_3: boolean = false;
  imageStyle_4: boolean = false;

  imageError: any;

  mobilenumber: any;
  email: any;
  errorEmi: any;
  loader: boolean = false;

  pincodedata: any;

  error: boolean = false;

  CityData: any = [
    {
      id: 0,
      cityname: 'Ahmedabad',
      path: '../../assets/images/All_city_icons/Ahmedabad/Cities@3x.png',
    },
    {
      id: 1,
      cityname: 'Bangalore',
      path: '../../assets/images/All_city_icons/Bngl/Cities@3x.png',
    },
    {
      id: 2,
      cityname: 'Chandigarh',
      path: '../../assets/images/All_city_icons/Chandighar/Cities@3x.png',
    },
    {
      id: 3,
      cityname: 'Delhi',
      path: '../../../assets/images/All_city_icons/Delhi/Cities@3x.png',
    },
    {
      id: 4,
      cityname: 'Hyderabad',
      path: '../../../assets/images/All_city_icons/Hyderabad/Cities@3x.png',
    },
    {
      id: 5,
      cityname: 'Kochi',
      path: '../../../assets/images/All_city_icons/Kochi/Cities@3x.png',
    },
    {
      id: 6,
      cityname: 'Kolkata',
      path: '../../../assets/images/All_city_icons/kolkata/Cities@3x.png',
    },
    {
      id: 7,
      cityname: 'Mumbai',
      path: '../../../assets/images/All_city_icons/Mumbai/Cities@3x.png',
    },
    {
      id: 8,
      cityname: 'Pune',
      path: '../../../assets/images/All_city_icons/Pune/Cities@3x.png',
    },
  ];

  BudgeValueData: any = [
    { id: 0, budgevalue: 'Less than 1.5 Lacs' },
    { id: 0, budgevalue: '1.5 - 3 Lacs' },
    { id: 0, budgevalue: '3 - 5 Lacs' },
    { id: 0, budgevalue: '5 - 8 Lacs' },
    { id: 0, budgevalue: '8 - 12 Lacs' },
    { id: 0, budgevalue: 'Above 12 Lacs' },
    { id: 0, budgevalue: 'Not Disclosed' },
  ];

  EmiPlan: any = [
    { id: 0, budgevalue: 'Zero Plan' },
    { id: 0, budgevalue: 'Rapid 12' },
    { id: 0, budgevalue: 'Rapid 24' },
    { id: 0, budgevalue: 'Personal Loan' },
    { id: 0, budgevalue: 'Home Improvement Plan' },
    { id: 0, budgevalue: 'Home Top-up Plan' },
  ];

  next() {
    this.to_show_form = false;
  }

  goback() {
    this.to_show_form = true;
  }

  selectStatus() {
    this.status = !this.status;
  }

  imagesrc: any;

  selctCity: any;
  selectOptions(item:any , src:any) {


    if (item.length) {
      this.selectedOptionItem = item;
      this.imagesrc = src;
      this.status = false;
      this.defaultOption = false;
      this.selctCity = item;
      this.apartmentservice.getPincode(this.selctCity).subscribe((res) => {
        this.pincodedata = res;
      });
    } else {
      return;
    }
  }

  // onclickpincode(){
  //   console.log(this.selctCity)
  //   this.apartmentservice.getPincode(this.selctCity).subscribe( (res) =>{
  //     this.pincodedata = res
  //   } )
  // }

  errorMsgFullName: boolean = false;
  errormsgname: boolean = false;
  onBlurname(e: any) {
    if (e.target.value !== '') {
      this.errorMsgFullName = false;
    } else {
      this.errorMsgFullName = true;
    }
  }

  errorMsgPhoneNumber: boolean = false;
  onBlurPhoneNumber(e: any) {
    if (e.target.value !== '') {
      this.errorMsgPhoneNumber = false;
    } else {
      this.errorMsgPhoneNumber = true;
    }
  }

  pinCode: any;
  errorMsgPinCodeNumber: boolean = false;
  onBlurPinCodeNumber(e: any) {
    if (e.target.value !== '') {
      this.errorMsgPinCodeNumber = false;
      this.pinCode = e.target.value;
    } else {
      this.errorMsgPinCodeNumber = true;
    }
  }

  mymodelpincode: any;
  hidesearch: boolean = false;

  valuechange(newValue: any) {
    this.mymodelpincode = newValue;
    if (!this.mymodelpincode) {
      this.hidesearch = false;
    } else {
      this.hidesearch = true;
    }
  }

  createFirstForm() {
    this.signUpForm1 = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: [
        '',
        [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')],
      ],
    });
  }

  createSecondForm() {
    this.signUpForm2 = this.fb.group({
      selctCity: [''],
      pinCodeNumber: [''],
    });
  }

  firstvalidation() {
    var email = /^[(A-Z)(a-z)0-9._%+-]+@[(A-Z)(a-z)0-9.-]+\.[(a-z)(A-Z)]{2,4}$/;

    if (this.signUpForm1.valid) {
      if (this.signUpForm1.value.email) {
        if (this.signUpForm1.value.email.match(email)) {
          this.error = false;
          $('#border-2').addClass('changes-2');
        }
      }
      if (this.error == false) {
        this.onSubmit();
        this.nextone('pills-1', 'pills-2', 'pills-2-tab', '', '');
      }
    } else {
      this.error = true;
    }
  }

  secondValidation() {
    $('#border-3').addClass('changes-2');
    this.nexttwo('pills-3', 'pills-2', 'pills-3-tab', '');
  }

  thirdValidation() {
    $('#border-4').addClass('changes-2');
    this.nextthree('pills-4', 'pills-3', 'pills-4-tab', '');
  }

  showValue(e: any) {}

  createFourthForm() {
    this.signUpForm4 = this.fb.group({
      budget: [''],
      emiPlan: [''],
    });
  }

  budget: any;
  emiPlan: any;
  budget_validation: boolean = false;
  emiPlan_validation: boolean = false;

  // fourthValidation() {
  //   if (this.signUpForm4.valid) {
  //     this.submit_pincode_space_budget();
  //     this.error = false;
  //     this.nextfour('pills-5', 'pills-4');
  //   } else {
  //     this.error = true;
  //   }
  // }

  fourthValidation() {
     this.submit_pincode_space_budget();
     this.nextfour('pills-5', 'pills-4');
  }

  


  nextone(
    form1: any,
    form2: any,
    activetab: any,
    deactivetab: any,
    borderactive: any
  ) {
    $('#' + form2).show();
    $('#' + form1).hide();
    $('#' + activetab).addClass('active');
    $('#' + borderactive).addClass('active');
    $('#' + deactivetab).removeClass('active');
  }

  nexttwo(form3: any, form2: any, activetab: any, deactivetab: any) {
    $(`#${form3}`).show();
    $(`#${form2}`).hide();
    $('#' + activetab).addClass('active');
    $('#' + deactivetab).removeClass('active');
  }

  nextthree(form4: any, form3: any, activetab: any, deactivetab: any) {
    $(`#${form4}`).show();
    $(`#${form3}`).hide();
    $('#' + activetab).addClass('active');
    $('#' + deactivetab).removeClass('active');
  }

  nextfour(form5: any, form4: any) {
    $(`#${form5}`).show();
    $(`#${form4}`).hide();
  }

  nextOne1(next: any, prev: any, activetab: any, deactivetab: any) {
    $('#' + next).show();
    $('#' + prev).hide();
    $('#' + activetab).addClass('active');
    $('#' + deactivetab).removeClass('active');
    $('#border-2').removeClass('changes-2');
  }

  nextOne2(next: any, prev: any, activetab: any, deactivetab: any) {
    $('#border-3').removeClass('changes-2');
    $('#' + next).show();
    $('#' + prev).hide();
    $('#' + activetab).addClass('active');
    $('#' + deactivetab).removeClass('active');
  }

  nextOne3(next: any, prev: any, activetab: any, deactivetab: any) {
    $('#border-4').removeClass('changes-2');
    $('#' + next).show();
    $('#' + prev).hide();
    $('#' + activetab).addClass('active');
    $('#' + deactivetab).removeClass('active');
  }

  nextOne4(next: any, prev: any, activetab: any, deactivetab: any) {
    $('#' + next).show();
    $('#' + prev).hide();
    $('#' + activetab).addClass('active');
    $('#' + deactivetab).removeClass('active');
  }

  financial_solution_required: any;
  checkbox(event: any) {
    if (event.target.checked === true) {
      this.show_field = true;
      this.financial_solution_required = 'Yes';
    } else {
      this.show_field = false;
      this.financial_solution_required = 'No';
    }
  }

  full_home: any;
  modular_kitchen: any;
  modular_wardrobe: any;
  kitchen_wardrobe: any;

  imageSpace_1(e: any, space: any) {
    if (e == 'image_one') {
      this.full_home = space;
      this.imageStyle_1 = true;
      this.imageStyle_2 = false;
      this.imageStyle_3 = false;
      this.imageStyle_4 = false;
    } else {
      this.imageStyle_1 = false;
      this.full_home = '';
    }
  }

  imageSpace_2(e: any, space: any) {
    if (e == 'image_two') {
      this.modular_kitchen = space;
      this.imageStyle_2 = true;
      this.imageStyle_1 = false;
      this.imageStyle_3 = false;
      this.imageStyle_4 = false;
    } else {
      this.imageStyle_2 = false;
      this.modular_kitchen = '';
    }
  }

  imageSpace_3(e: any, space: any) {
    if (e == 'image_three') {
      this.modular_wardrobe = space;
      this.imageStyle_3 = true;
      this.imageStyle_1 = false;
      this.imageStyle_2 = false;
      this.imageStyle_4 = false;
    } else {
      this.imageStyle_3 = false;
      this.modular_wardrobe = '';
    }
  }

  imageSpace_4(e: any, space: any) {
    if (e == 'image_four') {
      this.kitchen_wardrobe = space;
      this.imageStyle_4 = true;
      this.imageStyle_1 = false;
      this.imageStyle_2 = false;
      this.imageStyle_3 = false;
    } else {
      this.imageStyle_4 = false;
      this.kitchen_wardrobe = '';
    }
  }

  select_space: any;

  selectSpace(e: any) {
    this.select_space = e;
  }

  saved_info: any;
  onSubmit() {
    this.loader = true;
    const data = {
      lead: {
        name: this.signUpForm1.value.name,
        contact: this.signUpForm1.value.phoneNumber,
        // "pincode" : this.signUpForm2.value.pinCodeNumber,
        lead_source: 'website',
      },
      email: this.signUpForm1.value.email,
      // "city" :this.selctCity,
    };
    this.landingService.submitForm(data).subscribe((res) => {
      this.saved_info = res;
      localStorage.setItem('loginuserid', this.saved_info.id);
      this.loader = false;
    });
  }

  selected_emi_plan: any;
  selectedValue(event: any){
    this.selected_emi_plan = event.target.value;
  }

  selected_id: any;
  submit_pincode_space_budget() {
    this.loader = true;
    this.selected_id = localStorage.getItem('loginuserid');
    const data = {
      id: parseInt(this.selected_id),

      pincode: this.signUpForm2.value.pinCodeNumber,
      lead_source: 'website',
      lead_status: '',

      city: this.selctCity,
      customer_budget: this.signUpForm4.value.budget,
      scope_of_work: this.select_space,
      financial_solution_required: this.financial_solution_required,
      // additional_comments: this.selected_emi_plan

      // additional_comments:
      //   'Avail the EMI options :yes  Emi Plan :Rapid24'

      // additional_comments :'Avail the EMI options:'+this.financial_solution_required+'EMI Plan:'+this.selected_emi_plan 


      additional_comments :`Avail the Emi options - ${this.financial_solution_required}, Emi Plan - ${this.selected_emi_plan}`

    
      
        
    } 
     
  


    this.landingService.submitPincodecity(data).subscribe(
      (res) => {
        setTimeout(  () => {
          this.loader =false
        },1500)
      }  ,
      (err)=>{
        this.loader =false
      }
    );
  }
}


// city , picode , space budget
