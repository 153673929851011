import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-in-the-news-updated',
  templateUrl: './in-the-news-updated.component.html',
  styleUrls: ['./in-the-news-updated.component.scss']
})
export class InTheNewsUpdatedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.loadScript()
  }

  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
