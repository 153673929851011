<app-new-header-for-home   ></app-new-header-for-home>
<div class="root">

    <div *ngIf="roletoSet() > 600"  id="banner-image"   class="banner-image mt-md-5  m-md-0 mx-2">
        <div class="w-100 banner-imagew  pb-0 d-flex justify-content-lg-between align-items-center" >
           <div class="tagline mt-5 w-100  ">
              <p class="ml-5 Head_P pl-3">
                 Design Your<br> 
                 Dream Home  <br>       
                
              </p>
              <p  class="ml-5 small_P pl-3">
                1 Million+ options <br>
                for every style <br>
              </p>
              <button class="get-price button_book  d-md-block mt-5" (click)="bookConsultationLandingPage()" >GET THE BEST PRICE</button>
              <div class="jayaho_logo mb-4   ml-md-5 d-block mt-5"> 
              </div>

              <div *ngIf="roletoSet() > 600" class="container-fluid mr-filter d-none ">
                <div class="row justify-content-center ">
                    <div class="col-md-12 divcontainer ">
                        <div class="row my-4 align-items-center">
                            <div class="col-md-12 px-filters">
                                <div class="row">
                                    <div class="col-md-3 brdrbtm">
                                        <div class="form-group border-0">
                                            <label class="  Filter-label ">Space Type</label>
                                            <select class="form-control border-0 text-capitalize spacetype"
                                                (change)="spaceFilter($event)">
                                                <option *ngIf="this.space_name2 === ''" selected disabled>Select</option>
                                                <ng-container *ngFor="let item of update_space">
                                                    <option [selected]="item == this.space_name2">{{ transform(item) }}</option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3 brdrbtm">
                                        <div class="form-group border-0">
                                            <label class=" Filter-label ">Life Stage </label>
                                            <select class="form-control border-0 text-capitalize lifestage"
                                                (change)="lifeStageFilter($event)">
                                                <option selected disabled>Select</option>
                                                <ng-container *ngFor="let item of lifestage">
                                                    <option [selected]="item == this.lifeStage_filter_value1">{{ transform(item) }}
                                                    </option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group border-0">
                                            <label class=" Filter-label ">Theme</label>
                                            <select class="form-control border-0 text-capitalize theme"
                                                (change)="themeFilter($event)">
                                                <option selected disabled>Select</option>
                                                <ng-container *ngFor="let item of update_theme">
                                                    <option [selected]="item == this.theme_filter_value" *ngIf="item !== ''">{{
                                                        transform(item) }}</option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group border-0">
                                            <label class=" Filter-label ">Price Range</label>
                                            <select class="form-control border-0 text-capitalize pricerange"
                                                (change)="priceFilter($event)">
                                                <option selected disabled>Select</option>
                                                <ng-container *ngFor="let item of pricerange">
                                                    <option *ngIf="item !== ''">{{ item.name }}</option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>      
           </div> 
          
        </div>
       
      
       
     </div>
     <div *ngIf="roletoSet() < 600"  id="banner-image"   class="banner-image mt-md-5  m-md-0 ">
        <div class="w-100 banner-imagew  pb-0 d-flex justify-content-lg-between align-items-end" >
           <div class="tagline w-100  ">
              <p class=" Head_P pl-3">
                 Design Your<br> 
                 Dream Home  <br>       
                
              </p>
              <p  class=" small_P pl-3">
                1 Million+ options <br>
                for every style <br>
              </p>
              <div class="jayaho_logo mb-4   ml-md-5 d-block "> 
              </div>

   
           </div> 
          
        </div>
       
      
       
     </div>

     <div *ngIf="roletoSet() < 600" class="container-fluid d-none">
        <div class="row justify-content-center ">
            <div class="col-md-12 divcontainer ">
                <div class="row my-4 align-items-center">
                    <div class="col-md-12 ">
                        <div class="row">
                            <div class="col-md-3 brdrbtm">
                                <div class="form-group border-0">
                                    <label class="  Filter-label ">Space Type</label>
                                    <select class="form-control border-0 text-capitalize spacetype"
                                        (change)="spaceFilter($event)">
                                        <option *ngIf="this.space_name2 === ''" selected disabled>Select</option>
                                        <ng-container *ngFor="let item of update_space">
                                            <option [selected]="item == this.space_name2">{{ transform(item) }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3 brdrbtm">
                                <div class="form-group border-0">
                                    <label class=" Filter-label ">Life Stage </label>
                                    <select class="form-control border-0 text-capitalize lifestage"
                                        (change)="lifeStageFilter($event)">
                                        <option selected disabled>Select</option>
                                        <ng-container *ngFor="let item of lifestage">
                                            <option [selected]="item == this.lifeStage_filter_value1">{{ transform(item) }}
                                            </option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group border-0">
                                    <label class=" Filter-label ">Theme</label>
                                    <select class="form-control border-0 text-capitalize theme"
                                        (change)="themeFilter($event)">
                                        <option selected disabled>Select</option>
                                        <ng-container *ngFor="let item of update_theme">
                                            <option [selected]="item == this.theme_filter_value" *ngIf="item !== ''">{{
                                                transform(item) }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group border-0">
                                    <label class=" Filter-label ">Price Range</label>
                                    <select class="form-control border-0 text-capitalize pricerange"
                                        (change)="priceFilter($event)">
                                        <option selected disabled>Select</option>
                                        <ng-container *ngFor="let item of pricerange">
                                            <option *ngIf="item !== ''">{{ item.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>      

    <!-- <div *ngIf="roletoSet() < 1200"  class="container-fluid d-flex justify-content-center Spaces-container">
        <div class="row col-md-12">
      
          <div class="col-md-9">
      
      
              <div class="row">
      
                  <div (click)="SpaceFilter('Living')" class="col-md-4">
      
                      <img src="{{roletoSet() > 600?'../../../assets/images/arrivaeHomePageImages/spaceliving.png':'../../../assets/images/arrivaeHomePageImages/spaceLivingm.png'}}">

                      <p class="space_head"><span  class="space_bor">Liv</span>ing</p>
                      <p class="space_Details">View Details</p>
      
                  </div>
                  <div (click)="SpaceFilter('Master Bedroom')" class="col-md-8">
      
                      <img src="{{roletoSet() > 600?'../../../assets/images/arrivaeHomePageImages/mBedroom.png':'../../../assets/images/arrivaeHomePageImages/mbedroomm.png'}}">

                      <p class="space_head"><span  class="space_bor">Mas</span>ter bedroom</p>
                      <p class="space_Details">View Details</p>
      
                  </div>
                  <div (click)="SpaceFilter('Kids Room')" class="col-md-4">
      
                      <img src="{{roletoSet() > 600?'../../../assets/images/arrivaeHomePageImages/kidsroom.png':'../../../assets/images/arrivaeHomePageImages/kroomm.png'}}">

                      <p class="space_head"><span  class="space_bor">Kid</span>s Room</p>
                      <p class="space_Details">View Details</p>
      
                  </div>
                  <div (click)="SpaceFilter('Bedroom')" class="col-md-4">
                      <img src="../../../assets/images/arrivaeHomePageImages/bedroom.png">

                      <p class="space_head"><span  class="space_bor">Bed</span>room</p>
                      <p class="space_Details">View Details</p>
      
                  </div>
                  <div *ngIf="roletoSet() > 600" (click)="SpaceFilter('Living')" class="col-md-4">
      
                      <img src="../../../assets/images/arrivaeHomePageImages/living.png">
                      <p class="space_head"><span  class="space_bor">Liv</span>ing</p>
                    
                      <p class="space_Details">View Details</p>
      
      
                  </div>
                 
      
              </div>
      
          </div>
          <div (click)="SpaceFilter('Kitchen')" class="col-md-3 ">
      
              <img class="h-unset" src="../../../assets/images/arrivaeHomePageImages/kitchenSpace.png">
              <p class="space_head"><span  class="space_bor">Kit</span>chen</p>
              <p class="space_Details">View Details</p>
      
          </div>
         <div class="w-100 d-flex viewmoreContainer">
            <button (click)="SpaceFilter('')"  type="button " class=" ml-2 viewmore d-flex align-items-center mt-2">VIEW MORE &nbsp;
                <span>
                   <img src="../../../assets/images/Arrow 1.png"/>
                </span>
              </button>

         </div>
          
      
        </div>
      </div>
      <div *ngIf="roletoSet() > 1200"  class="container-fluid d-flex justify-content-center Spaces-container">
        <div class="row col-md-12 pl-5 pr-5">
      
          <div class="col-md-9">
      
      
              <div class="row">
      
                  <div (click)="SpaceFilter('Living')" class="col-md-4">
      
                      <img  src="{{roletoSet() > 600?'../../../assets/images/arrivaeHomePageImages/spaceliving.png':'../../../assets/images/arrivaeHomePageImages/spaceLivingm.png'}}">

                      <p class="space_head"><span  class="space_bor">Liv</span>ing</p>
                      <p class="space_Details">View Details</p>
      
                  </div>
                  <div (click)="SpaceFilter('Master Bedroom')" class="col-md-8 pl-0">
      
                      <img  src="{{roletoSet() > 600?'../../../assets/images/arrivaeHomePageImages/mBedroom.png':'../../../assets/images/arrivaeHomePageImages/mbedroomm.png'}}">

                      <p class="space_head"><span  class="space_bor">Mas</span>ter bedroom</p>
                      <p class="space_Details">View Details</p>
      
                  </div>
                  <div (click)="SpaceFilter('Kids Room')" class="col-md-4">
      
                      <img  src="{{roletoSet() > 600?'../../../assets/images/arrivaeHomePageImages/kidsroom.png':'../../../assets/images/arrivaeHomePageImages/kroomm.png'}}">

                      <p class="space_head"><span  class="space_bor">Kid</span>s Room</p>
                      <p class="space_Details">View Details</p>
      
                  </div>
                  <div (click)="SpaceFilter('Bedroom')" class="col-md-4 pl-0">
                      <img  src="../../../assets/images/arrivaeHomePageImages/bedroom.png">

                      <p class="space_head"><span  class="space_bor">Bed</span>room</p>
                      <p class="space_Details">View Details</p>
      
                  </div>
                  <div *ngIf="roletoSet() > 600" (click)="SpaceFilter('Living')" class="col-md-4 pl-0">
      
                      <img  src="../../../assets/images/arrivaeHomePageImages/living.png">
                      <p class="space_head"><span  class="space_bor">Liv</span>ing</p>
                    
                      <p class="space_Details">View Details</p>
      
      
                  </div>
                 
      
              </div>
      
          </div>
          <div (click)="SpaceFilter('Kitchen')" class="col-md-3 pl-0 ">
      
              <img class="h-unset"  src="../../../assets/images/arrivaeHomePageImages/kitchenSpace.png">
              <p class="space_head"><span  class="space_bor">Kit</span>chen</p>
              <p class="space_Details">View Details</p>
      
          </div>
         <div class="w-100 d-flex viewmoreContainer mt-5">
            <button (click)="SpaceFilter('')"  type="button " class=" ml-2 viewmore d-flex align-items-center mt-2">VIEW MORE &nbsp;
                <span>
                   <img src="../../../assets/images/Arrow 1.png"/>
                </span>
              </button>

         </div>
          
      
        </div>
      </div> -->
     <!-- <div *ngIf="roletoSet() > 1200" class="container-fluid d-flex justify-content-center Spaces-container">
        <div class="row col-md-12">
      
          <div class="col-md-9 Width-col9">
      
      
              <div class="row">
      
                  <div (click)="SpaceFilter('Living')" class="col-md-4 living_image">
      
                      <img class="w-100" src="{{roletoSet() > 600?'../../../assets/images/arrivaeHomePageImages/spaceliving.png':'../../../assets/images/arrivaeHomePageImages/spaceLivingm.png'}}">

                      <p class="space_head"><span  class="space_bor">Liv</span>ing</p>
                      <p class="space_Details">View Details</p>
      
                  </div>
                  <div (click)="SpaceFilter('Master Bedroom')" class="col-md-8 Master_bedroom">
      
                      <img class="w-100" src="{{roletoSet() > 600?'../../../assets/images/arrivaeHomePageImages/mBedroom.png':'../../../assets/images/arrivaeHomePageImages/mbedroomm.png'}}">

                      <p class="space_head"><span  class="space_bor">Mas</span>ter bedroom</p>
                      <p class="space_Details">View Details</p>
      
                  </div>
                  <div (click)="SpaceFilter('Kids Room')" class="col-md-4 kidsroomimage">
      
                      <img class="w-100" src="{{roletoSet() > 600?'../../../assets/images/arrivaeHomePageImages/kidsroom.png':'../../../assets/images/arrivaeHomePageImages/kroomm.png'}}">

                      <p class="space_head"><span  class="space_bor">Kid</span>s Room</p>
                      <p class="space_Details">View Details</p>
      
                  </div>
                  <div (click)="SpaceFilter('Bedroom')" class="col-md-4 bedroomimage">
                      <img class="w-100" src="../../../assets/images/arrivaeHomePageImages/bedroom.png">

                      <p class="space_head"><span  class="space_bor">Bed</span>room</p>
                      <p class="space_Details">View Details</p>
      
                  </div>
                  <div *ngIf="roletoSet() > 600" (click)="SpaceFilter('Living')" class="col-md-4 living_image2">
      
                      <img class="w-100" src="../../../assets/images/arrivaeHomePageImages/living.png">
                      <p class="space_head"><span  class="space_bor">Liv</span>ing</p>
                    
                      <p class="space_Details">View Details</p>
      
      
                  </div>
                 
      
              </div>
      
          </div>
          <div (click)="SpaceFilter('Kitchen')" class="col-md-3 kitchen_image ">
      
              <img class="h-unset w-100" src="../../../assets/images/arrivaeHomePageImages/kitchenSpace.png">
              <p class="space_head"><span  class="space_bor">Kit</span>chen</p>
              <p class="space_Details">View Details</p>
      
          </div>
         <div class="w-100 d-flex viewmoreContainer">
            <button (click)="SpaceFilter('')"  type="button " class=" ml-2 viewmore d-flex align-items-center mt-2">VIEW MORE &nbsp;
                <span>
                   <img src="../../../assets/images/Arrow 1.png"/>
                </span>
              </button>

         </div>
          
      
        </div>
      </div> -->

      <div class=" arrivae-living   p-md-0 py-4 my-md-5 mt-4 mx-2 mx-sm-0">
        <div class="d-flex flex-column justify-content-start  main-heading mx-sm-5 ">
           <p class="mb-1 text-uppercase"><span class="pb-1">Arrivae’s Design Libra</span>ry</p>
           <span class="mobile_discription">Browse through 1M+ design options to find your perfect match
           </span>
           <a style="text-decoration: none;" *ngIf="roletoSet() > 650"  (click)="SpaceFilter('')" >
              <button  type="button " class="mt-2 button d-flex text-uppercase align-items-center">explore now &nbsp;
                 <span>
                    <img src="../../../assets/images/Arrow 1.png"/>
                 </span>
               </button>
           
           </a>
  
        </div>
  
     <div class="row align-items-center Slider-mobile p-0 my-md-5">
        <div class="col-1 prev">
           <div class="float-right">
              <div class="other-mobile-boqs"  >
                <button   class="mx-lg-1 button-one" (click)="slickController.prev()">
                  <img class="cursorPointer"  src="../../assets/images/sign-up-to-service/Arrow copy 2 2.png" alt="previous" >
                </button>
              </div>
          </div>   
        </div>
  
        <div class="col-10 px-0 ">
           <div class="Buildings_container row mx-0 mt-4">
              <!-- <div class="col-lg-4 col-md-6 col-sm-6 cursor-pointer mt-4" >
            <a href="https://web.arrivae.com/25-south" target="_blank">
               <div  class="image_carrier d-flex align-items-end" style="background-image:url('../../../assets/images/25south.png') ;">
                  <div class="px-5 py-3 imgHeading">
                     <span>25 South</span>
                  </div>
               </div>
            </a>
         </div>
         <div class="col-lg-4 col-md-6 col-sm-6 cursor-pointer mt-4">
            <a href="https://web.arrivae.com/140-shivaji" target="_blank">
               <div class="image_carrier d-flex align-items-end" style="background-image:url('../../../assets/images/140shivaji.png') ;">
                  <div class="px-5 py-3 imgHeading">
                     <span>140 Shivaji Park</span>
                  </div>
               </div>
            </a>
         </div> -->
              <!-- <div class="col-lg-4 col-md-6 col-sm-6 cursor-pointer mt-4">
            <a >
               <div class="image_carrier d-flex align-items-end" style="background-image:url('../../../assets/images/EmerldElse.png') ;">
                  <div class="px-5 py-3 imgHeading">
                     <span>Emerald Isle </span>
                  </div>
               </div>
            </a>
         </div> -->
  
              <div class="mobile-carosel-active-boqs w-100">
                <div slickContainer #slickController="slick" [slickConfig]="config">
                  <ng-container *ngIf="slickController.initialize">
                 
                      <div slickItem class="card ml-lg-3  cursorPointer" >
                         <a (click)="SpaceFilter('Living')">
  
  
                            <img  class="image_carrier d-flex align-items-end" src='../../../assets/images/arrivaeHomePageImages/SpaceL1.png'>
                               <!-- <div class="w-100 logo-style">
                                  <img  src="../../assets/images/25 South logo 1.png" alt="" class="">
                               </div> -->
                               <div class=" py-3 imgHeading ">
                               <p class="space_head"><span  class="space_bor">Liv</span>ing</p>
                               <p class="space_Details">View Details</p>
                               </div>
                         </a>
                      </div>
                      <div slickItem class="card ml-lg-3  cursorPointer"  >
                         <a (click)="SpaceFilter('Bedroom')">
                            <img class="image_carrier d-flex align-items-end" src='../../../assets/images/arrivaeHomePageImages/SpaceL2.png'>
                            <!-- <div class="w-100 logo-style">
                               <img  src="../../assets/images/logoshivpng.png" alt="" class="">
                            </div> -->
                            <div class=" py-3 imgHeading ">
                                <p class="space_head"><span  class="space_bor">Bed</span>room</p>
                                <p class="space_Details">View Details</p>
                            </div>
                         </a>
                      </div>
                      <div slickItem class="card ml-lg-3  cursorPointer"  >
                         <a (click)="SpaceFilter('Kitchen')" >
                            <img class="image_carrier d-flex align-items-end" src='../../../assets/images/arrivaeHomePageImages/SpaceL3.png'>
                               <div class="py-3 imgHeading">
                                <p class="space_head"><span  class="space_bor">Kit</span>chen</p>
                                <p class="space_Details">View Details</p>
                               </div>
                         </a>
                      </div>
                      <div slickItem class="card ml-lg-3  cursorPointer" >
                         <a (click)="SpaceFilter('Kids Room')">
                            <img  class="image_carrier d-flex align-items-end" src='../../../assets/images/arrivaeHomePageImages/SpaceL4.png'>
                            
                               <div class=" py-3 imgHeading">
                                <p class="space_head"><span  class="space_bor">Kid</span>s Room</p>
                                <p class="space_Details">View Details</p>
  
                               </div>
                         </a>
                      </div>
                      <div slickItem class="card ml-lg-3  cursorPointer" >
                        <a (click)="SpaceFilter('Living')">
 
 
                           <img  class="image_carrier d-flex align-items-end" src='../../../assets/images/arrivaeHomePageImages/SpaceL1.png'>
                              <!-- <div class="w-100 logo-style">
                                 <img  src="../../assets/images/25 South logo 1.png" alt="" class="">
                              </div> -->
                              <div class=" py-3 imgHeading ">
                              <p class="space_head"><span  class="space_bor">Liv</span>ing</p>
                              <p class="space_Details">View Details</p>
                              </div>
                        </a>
                     </div>
                     <div slickItem class="card ml-lg-3  cursorPointer"  >
                        <a (click)="SpaceFilter('Bedroom')">
                           <img class="image_carrier d-flex align-items-end" src='../../../assets/images/arrivaeHomePageImages/SpaceL2.png'>
                           <!-- <div class="w-100 logo-style">
                              <img  src="../../assets/images/logoshivpng.png" alt="" class="">
                           </div> -->
                           <div class=" py-3 imgHeading ">
                               <p class="space_head"><span  class="space_bor">Bed</span>room</p>
                               <p class="space_Details">View Details</p>
                           </div>
                        </a>
                     </div>
                     <div slickItem class="card ml-lg-3  cursorPointer"  >
                        <a (click)="SpaceFilter('Kitchen')" >
                           <img class="image_carrier d-flex align-items-end" src='../../../assets/images/arrivaeHomePageImages/SpaceL3.png'>
                              <div class="py-3 imgHeading">
                               <p class="space_head"><span  class="space_bor">Kit</span>chen</p>
                               <p class="space_Details">View Details</p>
                              </div>
                        </a>
                     </div>
                     <div slickItem class="card ml-lg-3  cursorPointer" >
                        <a (click)="SpaceFilter('Kids Room')">
                           <img  class="image_carrier d-flex align-items-end" src='../../../assets/images/arrivaeHomePageImages/SpaceL4.png'>
                           
                              <div class=" py-3 imgHeading">
                               <p class="space_head"><span  class="space_bor">Kid</span>s Room</p>
                               <p class="space_Details">View Details</p>
 
                              </div>
                        </a>
                     </div>
                      
                   
                   
                </ng-container>
              </div>
           
         <!-- <div class="d-lg-none d-block mt-2 text-center">
              <div class="other-mobile-boqs mobile-indicators" >
                <button   class="mx-1 button-one" (click)="slickController.prev()">
                  <img class="cursorPointer"  src="../../assets/images/sign-up-to-service/Arrow copy 2 2.png" alt="previous" >
                </button>
             <button  class="mx-1 button-two" (click)="slickController.next()">
               <img class="cursorPointer" src="../../assets/images/sign-up-to-service/Arrow copy 2 1.png" alt="next" >
             </button>
           </div>
       </div> -->
     </div>
  
     <a *ngIf="roletoSet() < 650"   target="_blank" (click)="SpaceFilter('')">
        <button  type="button " class="my-2 button d-flex align-items-center">EXPLORE NOW&nbsp;
           <span>
              <img src="../../../assets/images/Arrow 1.png"/>
           </span>
         </button>
     
     </a>
  
  
  
         
      </div>
        </div>
  
      <div class="col-1 next">
        <div class="float-right floatInMobile ">
           <div class="other-mobile-boqs"  >
             
             <button  class="mx-lg-1 button-one" (click)="slickController.next()">
               <img class="cursorPointer" src="../../assets/images/sign-up-to-service/Arrow copy 2 1.png" alt="next" >
             </button>
           </div>
        </div>
       
     </div>
  
     </div>
   </div>



<div class="modal inclusion-modal" id="spacesModal">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content modal-style">
            <div class="modal-body p-lg-4 p-0">
                <div class="row px-3 px-lg-0 my-3 my-lg-0">
                    <div class="col-lg-4 col-4 px-1 cursorPointer" (click) ="closemodal()">
                        <img src="../../../assets/images/apartment-detail-images/Icon (1).png">&nbsp;
                        <span class="d-sm-block d-md-none back">Back</span>
                    </div>
                    <div class="col-lg-8 col-8 d-flex justify-content-end cursorPointer" (click) = "spaceInclusionPage(singleId)">
                        <span class="more_info">View More Info</span>
                    </div>
                </div>
                <div class="row px-3 px-lg-0 px-0">
                    <div class="col-lg-12 py-lg-3 py-1" *ngIf="singleListItem">
                        <div class="row justify-content-between">
                            <div class="col-lg-6 col-12 px-1">
                                <div class="row">
                                    <div class="col-12">
                                        <button *ngIf ="shortcodeItem.theme" type="button" class="btn mb-1 mb-lg-0 modernVibes mr-1 mr-lg-2 px-2">{{shortcodeItem.theme}}</button>
                                        <button *ngIf = "shortcodeItem.space" type="button" class="btn mb-1 mb-lg-0 modernVibes mr-1 mr-lg-2 px-2">{{shortcodeItem.space}}</button>
                                        <button *ngIf ="shortcodeItem.lifestage" type="button" class="btn modernVibes mr-1 mr-lg-2 px-2 mb-1">{{shortcodeItem.lifestage | spacePipe }}</button>
                                        <button *ngIf ="shortcodeItem.shortcode" type="button" class="btn mb-1 modernVibes numbers mr-1 mr-lg-2 px-2">{{ shortcodeItem.shortcode }}</button>
                                    </div>
                                    <div class="col-12">
                                        <p *ngIf="topSpace?.length >0" style="font-size: 18px; font-weight: 400;"
                                            class="mb-0 mt-2">{{topSpace}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 vr-finance">
                                <div class="row mt-4 justify-content-around">
                                    <a routerLink="/get-finance"
                                        class="cursorPointer  d-md-block d-none vr-finance mt-2">Get Finance</a>
                                    <!-- <a class="cursorPointer d-sm-block d-md-none finance mt-2">Get Finance</a> -->
                                    <ng-container *ngIf="fullData">
                                        <a (click)="bookConsultationLandingPage()"
                                            class="getTheDesign px-4 py-2 cursorPointer d-md-block d-none">Get This
                                            Design</a>
                                    </ng-container>
                                    <!-- <a class="getDesign d-sm-block d-md-none px-2 py-2 cursorPointer">Get
                                        This Design</a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 px-lg-0 px-0"> 
                    <div *ngIf="fetchData" id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <ng-container *ngFor="let img of fetchData.data.attributes.images ; let j= index">
                                <div class="carousel-item active" [ngClass]="{'active':j===0}">
                                    <img [src]="img" class="d-block w-100" alt="...">
                                </div>
                            </ng-container>
                        </div>
                        <ng-container *ngIf="fetchData.data.attributes.images.length > 1">
                            <a class="carousel-control-prev" href="#carouselExampleControls" role="button"
                                data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleControls" role="button"
                                data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </ng-container>
                    </div>
                </div>
                <div class="col-lg-5 vr-finance">
                    <div class="row justify-content-center">
                        <a routerLink="/get-finance" class="cursorPointer d-sm-block d-md-none finance my-4">Get
                            Finance</a>
                        <ng-container *ngIf="fullData">
                            <a (click)="bookConsultationLandingPage()"
                                class="getDesign d-sm-block d-md-none px-2 py-2 cursorPointer">Get
                                This Design
                            </a>
                        </ng-container>
                    </div>
                </div>
                <div class="col-lg-12 pb-4 pb-lg-0 pt-4">
                    <div class="inclusion-modal-prev-next">
                        <div *ngIf="singleListItem" class="root-pre-next ml-xl-n3">
                            <div class="d-flex cursorPointer" (click)="previousSpace(previousItems.id)">
                                <ng-container *ngIf ="previousItems !== null">
                                    <div class="align-self-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                            style="color: #8d0528" class="bi bi-arrow-left-circle mr-2" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd"
                                                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                                        </svg>
                                        <img class="d-lg-inline d-none mr-2" src="../../../assets/images/Kids Room.png"
                                            width="70px" height="40px">
                                    </div>
                                    <div>
                                        <a class="mb-0 pre-icon">Previous Space</a>
                                        <a class="mb-0 apart-name d-lg-block d-none">{{previousItems.space}}</a>
                                    </div>
                                </ng-container>
                            </div>
                            <div *ngIf ="nextItems !== null" class="d-flex cursorPointer mr-xl-n3" (click)="nextSpace(nextItems.id)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                    style="color: #8d0528" fill="currentColor"
                                    class="bi bi-arrow-right-circle align-self-center  mr-2" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                </svg>
                                <div class="align-self-center">
                                    <img class="d-lg-inline d-none mr-2" src="../../../assets/images/Cozy Bedroom.png"
                                        width="70px" height="40px">
                                </div>
                                <div>
                                    <a class="mb-0 pre-icon">Next Space</a>
                                    <a class="mb-0 apart-name d-lg-block d-none">{{nextItems.space}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mbspace">
    <app-updated-blogs-design></app-updated-blogs-design>
</div>
<div *ngIf="roletoSet() > 650" class="button col-12 d-flex  mx-md-3 p-0  justify-content-center mb-5">
    <a href="https://blog.arrivae.com/" target="_blank">

     <button type="button "  class=" d-flex align-items-center ">MORE TRENDS&nbsp;
         <span>
            <img src="../../../assets/images/Arrow 1.png"/>
         </span>
     </button>
    </a>
 </div>
<app-footer></app-footer>
</div>
<div class="loading d-flex justify-content-center" *ngIf="loader">
  <div class="spinner-border" style="color: #8d0528" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>