<!-- <app-header (sendData)="customFunc($event)"></app-header> -->
<app-header-new (sendData)="customFunc($event)" [data] = "namephn" [verifyUser] = "boolvalue" [l_lead_invitation] ="leadInvitationId" [l_lead_id] = "l_lead_id" ></app-header-new>
<div id="banner-image" class="banner-image">
    
    <div id="carouselExampleIndicators" class="carousel slide carousel-container d-lg-block d-md-block d-none" data-touch="true" data-ride="carousel"
        data-interval="5000">
        <div class="carousel-inner">
            <div *ngFor="let image of carouselImages; index as i" class="carousel-item" [ngClass]="i == 0 ? 'active' : ''">
                <a [href]="image.redirectUrl" target="_blank">
                    <img class="d-block w-100" [src]="image.url" [alt]="image.alt" class="sampleImg" />
                </a>
            </div>
        </div>
        <ol class="carousel-indicators">
            <li *ngFor="let image of carouselImages; index as i" [attr.data-slide-to]="i" [ngClass]="i == 0 ? 'active' : ''"
            data-target="#carouselExampleIndicators"></li>
        </ol>
    </div>

<div id="carouselExampleIndicators" class="carousel slide carousel-container d-lg-none d-md-none d-block" data-touch="true" data-ride="carousel"
    data-interval="5000">
    <div class="carousel-inner">
        <div *ngFor="let image of mobilecaroselImages; index as i" class="carousel-item" [ngClass]="i == 0 ? 'active' : ''">
            <a [href]="image.redirectUrl" target="_blank">
                <img class="d-block w-100" [src]="image.url" [alt]="image.alt" class="sampleImg" />
            </a>
        </div>
    </div>
    <ol class="carousel-indicators">
        <li *ngFor="let image of carouselImages; index as i" [attr.data-slide-to]="i" [ngClass]="i == 0 ? 'active' : ''"
        data-target="#carouselExampleIndicators"></li>
    </ol>
</div>
    <div class="stats-panel" id="stats-panel">
        <!-- <div class="container"> -->
            <div class="row">
                <div class="col-4 col-lg-2 stats">
                    <div class="icon">
                        <img src="../../../assets/images/Landing_page_updated/stats_icons/calender.svg" alt="calender">
                    </div>
                    <div class="stats-text">
                        <p class="bold-heading"><span class="numberCountUp">45</span> Days</p>
                        <p>Delivery*<sup>working days</sup></p>
                    </div>
                </div>
                <div class="col-5 col-lg-2 stats">
                    <div class="icon">
                        <img src="../../../assets/images/Landing_page_updated/stats_icons/home.svg" alt="home">
                    </div>
                    <div class="stats-text">
                        <p class="bold-heading"><span class="numberCountUp">13,500</span> +</p>
                        <p>Spaces Delivered</p>
                    </div>
                </div>
                <div class="col-3 col-lg-2 stats">
                    <div class="icon">
                        <img src="../../../assets/images/Landing_page_updated/stats_icons/notepad.svg" alt="notepad">
                    </div>
                    <div class="stats-text">
                        <p class="bold-heading"><span class="numberCountUp">10</span> Lac+ </p>
                        <p>Design Options</p>
                    </div>
                </div>
                <div class="col-4 col-lg-2 stats">
                    <div class="icon">
                        <img src="../../../assets/images/Landing_page_updated/stats_icons/glob.svg" alt="globe">
                    </div>
                    <div class="stats-text">
                        <p class="bold-heading"><span class="numberCountUp">15</span></p>
                        <p>Cities</p>
                    </div>
                </div>
                <div class="col-5 col-lg-2 stats">
                    <div class="icon">
                        <img src="../../../assets/images/Landing_page_updated/stats_icons/badge.svg" alt="badge">
                    </div>
                    <div class="stats-text">
                        <p class="bold-heading"><span class="numberCountUp">10</span> Years </p>
                        <p>Warranty</p>
                    </div>
                </div>
                <div class="col-4 col-lg-2 scroll">
                    <a href="#work-container" class="d-flex scroll-text">
                        <img src="../../../assets/images/icons/scroll.svg" alt="scroll">
                        <p class="m-0 ml-1">Scroll</p>
                    </a>
                </div>
            </div>
        <!-- </div> -->
    </div>

    <div class="modal modal-get-quote" id="getQuoteModal" tabindex="-1" aria-labelledby="getQuoteModalLabel" aria-hidden="true">
        <div class="modal-dialog get-quote-wrapper">
          <div id="get-quote-container" class="modal-content get-quote-container">
            <div class="contents">
                <div class="get-quote-head">
                    <img class="arrow-left-icon" src="../../../assets/images/icons/arrow left.svg" alt="arrow left">
                </div>
                <div class="get-quote-body">
                    <div class="text-wrapper">
                        <div class="text-container">
                            <p class="text-head">Enter OTP</p>
                            <p class="text">We have sent you an OTP to your phone number.</p>
                        </div>
                        <div class="icon">
                            <img class="img-fluid" src="../../../assets/images/Landing_page_updated/shield-icon.png" alt="shield-icon">
                        </div>
                    </div>
                    <div class="input-field">
                        <input 
                            type="number" 
                            placeholder="OTP" 
                            maxlength="6" 
                            [(ngModel)]="enteredOptNumber"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        >
                        <!-- <p class="time-remaining">Time Remaining : <span class="light-green">2:00</span></p> -->
                        <button class="button-verify" (click)="verifyOtp()">Verify</button>
                    </div>
                </div>
            </div>
            <div class="get-quote-footer">
                <p class="text-footer m-0">By verifying, you are allowing us to reach out to you on the number you have providied. You can also view our Terms Of Use & Privacy Policy.</p>
            </div>
          </div>
        </div>
    </div>
  
</div>
<div id="work-container">
<app-how-it-works></app-how-it-works>
</div>
<app-our-project-gallery-updatedd></app-our-project-gallery-updatedd>
<app-hear-from-clients></app-hear-from-clients>
<app-stories-updated></app-stories-updated>
<app-in-the-news-updated></app-in-the-news-updated>
<app-updated-blogs-design></app-updated-blogs-design>
<app-footer-update></app-footer-update>

<div class="loading d-flex justify-content-center" *ngIf="loader">
  <div class="spinner-border" style="color: #8d0528;" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
