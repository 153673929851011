import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Slick } from 'ngx-slickjs';
import { BookConsultationFormComponent } from 'src/app/shared/book-consultation-form/book-consultation-form.component';

@Component({
  selector: 'app-how-it-works-new',
  templateUrl: './how-it-works-new.component.html',
  styleUrls: ['./how-it-works-new.component.scss']
})
export class HowItWorksNewComponent implements OnInit {

  constructor(
    private modalService: NgbModal

  ) { }
    currentUrl :any
  ngOnInit(): void {
    this.currentUrl = window.location.href.split('/')[3]
    this.newLandingPage()
    this.roletoSet()
  }
  Logos:any=[
    "../../../assets/images/logs/bkc.png",
    "../../../assets/images/logs/lodha-amara.png",
    "../../../assets/images/logs/west-country.png",
    "../../../assets/images/logs/indiabulls.png",
    "../../../assets/images/logs/seasons.png",
    "../../../assets/images/logs/marathon.png",
    "../../../assets/images/logs/harmony.png",
    "../../../assets/images/logs/urbania.png",



  ]


  roletoSet(){
    var width = Math.max(window.screen.width, window.innerWidth)
    this.checkactive();
     return width
  }
  config: Slick.Config = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    autoplay: false,
    autoplaySpeed: 2000,
    draggable: true,
    mouseWheelMove: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {

        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll:2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  config2: Slick.Config = {
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    draggable: true,
    mouseWheelMove: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {

        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll:2,
          infinite: true,
        },
      },
      {
      breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll:2,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  Elements:any;
  slickIndex:any
  checkactive(){
   this.Elements = document.getElementsByClassName("mobile_image");
    let Indexofitem;
 
     for (var i = 0; i < this.Elements.length; i++) {
       
        let classes = this.Elements[i].className.split(' ')
        if(classes.includes('slick-active')) {
             Indexofitem = i;
             this.slickIndex = Indexofitem;
        }
        
   }
    
   
  

  }
  bookConsultationLandingPage() {
    console.log("hii")
    const modal = this.modalService.open(BookConsultationFormComponent, {
      size: 'md',
    });
  }

  newLandingPage(){
    if(this.currentUrl == 'new-landing'){
      return true
    }else{
      return false
    }
  }

}
