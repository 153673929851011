<app-header></app-header>
<div class="container-fluid profile">
  <div class="mob-none row justify-content-center my-lg-n5">
    <div class="col-md-10 col-12 breadcrumb-style" style="top: 439px;">
     <a href="/landing" class="text-decoration-none"><span class="home-sec">HOME >> </span></a> 
     <a><span class="space-sec">DESIGN VENDOR</span></a>
        </div>
    </div>  
</div>

<div class="container-fluid items-carous">
        <div class="d-flex justify-content-center pt-4 pb-4">
            <span class="titleTriangle"
              >JOIN THE SUCCESS
              <div class="mask"></div
            ></span>
            <img
               src="../assets/images/Fill-117.svg"
               class="smallLogoIcon zI1"
               alt="vwuy"
            />
        </div>
        <div class="row justify-content-center">
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-12">
                <p class="text-center"
              >Arrivae Design Partner Program is designed to help you leap in your growth journey. Our ecosystem supports and nourishes this partnership with constant innovation and learning.
              </p>
              </div>
              </div>
          </div>
          </div>

        <div class="row justify-content-center alignColumn">
            <div class="col-md-10">
                <div class="row">
                    <div class=" col-md-4">
                        <img src="../../../assets/images/Group 589.png" class="ImageSize"/>
                        <div class="TextLength">
                            <h4 class="font-style">Customer Leads</h4>
                            <p class="P1Text">Grow your business with stream of 100s of leads every month.</p>
                        </div>
                        
                    </div>
                    <div class=" col-md-4">
                        <img src="../../../assets/images/Group.png" class="ImageSize"/>
                        <div class="TextLength">
                            <h4 class="font-style">Powerful Tools</h4>
                            <p class="P1Text">Amazing tools to help you track, manage, execute high volume of orders.</p>
                        </div>
                </div>
                <div class=" col-md-4 ">
                    <img src="../../../assets/images/Group_2.png" class="ImageSize"/>
                    <div class="TextLength">
                        <h4 class="font-style">Training And Support</h4>
                        <p class="P1Text">Regular training and support from Arrivae ecosystem to help your team excel.</p>
                    </div>
                    
                </div>
            </div>
           
            
              
            </div>
           
    </div>
    <div class="row justify-content-center alignColumn p-4">
    <div class=" col-md-5 ">
        <button class="button2">Enroll Now</button>          
    </div>
</div>
    </div>
<div class="container-fluid items-carous-1">
    <div class="d-flex justify-content-center pt-4 pb-4">
        <span class="titleTriangle"
          >TRANSPARENT PROCESS
          <div class="mask2"></div
        ></span>
        <img
           src="../assets/images/Fill-117.svg"
           class="smallLogoIcon zI1"
           alt="vwuy"
        />
    </div>
        <div class="row justify-content-center alignColumn">
            <div class="col-md-10">
                <div class="row">
            <div class=" col-md-4 ">
                <div class="d-flex">
                    <img src="../../../assets/images/Group_3.png" class="img-1">
                    <h4 class="font-style">Unlimited Qualified Leads</h4>
                </div>
                <p class="p-style">Our teams speak to customer and their focus is to provide you leads which converts well, belong to your region of influence.</p>
            </div>
            <div class=" col-md-4 ">
                <div class="d-flex">
                    <img src="../../../assets/images/Group_3.png"  class="img-1">
                    <h4 class="font-style">Manufacturing Support</h4>
                </div>
                <p class="p-style">Your order files are pushed to the central repository fully automated production line in few clicks your orders are placed to production.</p>
            </div>
            <div class=" col-md-4 ">
                <div class="d-flex">
                    <img src="../../../assets/images/Group_3.png"  class="img-1">
                    <h4 class="font-style">Project Execution</h4>
                </div>
                <p class="p-style">Each Designer partner are provided with excellent project execution team specialized in all aspect of interior services.</p>
            </div>
        </div>
        </div>
        </div>
        <div class="row justify-content-center alignColumn">
            <div class="col-md-10">
                <div class="row">
            <div class=" col-md-4 ">
                <div class="d-flex">
                    <img src="../../../assets/images/Group_3.png"  class="img-1">
                    <h4 class="font-style mr-n5">Commissions On Every Conversion</h4>
                </div>
                <p class="p-style">Get lucrative commissions on every conversion you make on provide lead.</p>
            </div>
            <div class=" col-md-4 ">
                <div class="d-flex">
                    <img src="../../../assets/images/Group_3.png"  class="img-1">
                    <h4 class="font-style">Track Order Journey</h4>
                </div>
                <p class="p-style">Our tools will help you get real time update on customer order and payment journey.</p>
            </div>
            <div class=" col-md-4 ">
                <div class="d-flex">
                    <img src="../../../assets/images/Group_3.png"  class="img-1">
                    <h4 class="font-style">Auto Payment</h4>
                </div>
                <p class="p-style">Linked to the order stage get automatic payment done on reaching each milestone.</p>
            </div>
        </div>
        </div>
        </div>


</div>
<div class="container-fluid items-carous">
    <div class="d-flex justify-content-center pt-4 pb-4">
        <span class="titleTriangle"
          >SIMPLE YET POWERFUL
          <div class="mask"></div
        ></span>
        <img
           src="../assets/images/Fill-117.svg"
           class="smallLogoIcon zI1"
           alt="vwuy"
        />
    </div>
    <div class="row justify-content-center alignColumn">
        <div class="col-md-10">
            <div class="row justify-content-around mt-4">
        <div class=" col-md-6 d-flex" >
            <div class="row">
                <div class="col-md-4">
                     <img src="../../../assets/images/monitor.png"/>
                </div>
                 <div class="col-md-8 text-left">
                    <div>
                        <h4>Design Tools</h4>
                        <p>Fully integrated to design-production-installation, our tools will help you save many hours of painful task.</p>
                    </div>   
                 </div>
            </div>
                    

        </div>

        <div class=" col-md-6  d-flex" >
            <div class="row">
                <div class="col-md-4">
                   <img src="../../../assets/images/promotion.png"/>
                </div>
                <div class="col-md-8 text-left">
                    <div>
                        <h4>Promotional Tools</h4>
                        <p>Our referral tools will help you add more values to your work and business.</p>
                         </div>
                    </div>
                </div>
            </div>
            
    </div>
    </div>
    </div>
    <div class="row justify-content-center alignColumn">
        <div class="col-md-10">
            <div class="row justify-content-around mt-4">
        <div class=" col-md-6 d-flex" >
            <div class="row">
                <div class="col-md-4">
                     <img src="../../../assets/images/performance.png"/>
                </div>
                 <div class="col-md-8 text-left">
                    <div>
                        <h4>Performance Tools</h4>
                        <p> Track customer, order, your teams, project execution through our set of intelligent tools designed for your success.</p>
                            
                    </div>   
                 </div>
            </div>
                    

        </div>

        <div class=" col-md-6  d-flex" >
            <div class="row">
                <div class="col-md-4">
                   <img src="../../../assets/images/mobility.png"/>
                </div>
                <div class="col-md-8 text-left">
                    <div>
                        <h4>Mobility Tools</h4>
                        <p> On the go manage client request, onsite tools to update customer requirements.</p>
                         </div>
                    </div>
                </div>
            </div>
            
    </div>
    </div>
    </div>
    

</div>
<div class="container-fluid items-carous-1">
    <div class="d-flex justify-content-center pt-4 pb-4">
        <span class="titleTriangle"
          >WE ARE WITH YOU
          <div class="mask2"></div
        ></span>
        <img
           src="../assets/images/Fill-117.svg"
           class="smallLogoIcon zI1"
           alt="vwuy"
        />
    </div>
    <div class="row justify-content-center alignColumn">
        <div class="col-md-10">
            <div class="row">
                <div class=" col-md-3">
                    <img src="../../../assets/images/Group_8.png" />
                     <h4 class="htext">Talk to us</h4>
                     <p class="text-center"> Reach our support staff by email or request a call whenever you need us.</p>
                </div>
                <div class=" col-md-3">
                    <img src="../../../assets/images/Group_9.png"/>
                   <h4 class="htext">Tips for success</h4> 
                    <p class="text-center"> Learn best practice for your business by our always updated seller hanbook.</p>
                </div>
                <div class=" col-md-3">
                    <img src="../../../assets/images/Group 596.png"/>
                      <h4 class="htext"> Seller newsletter</h4>
                       <p class="text-center"> the Etsy newsletter to improve your shop delivered to inbox.</p>
                </div>
                <div class=" col-md-3">
                    <img src="../../../assets/images/Group 597.png"/>
                    <h4 class="htext"> Get advice</h4>
                     <p class="text-center"> Ask questions and find a community of sellers like you in Etsy’s forums.</p>
                </div>

            </div>
        </div>
    </div>


</div>
<div class="container-fluid items-carous">
    <div class="d-flex justify-content-center pt-4 pb-4">
        <span class="titleTriangle"
          >SELLER'S STORIES
          <div class="mask"></div
        ></span>
        <img
           src="../assets/images/Fill-117.svg"
           class="smallLogoIcon zI1"
           alt="vwuy"
        />
    </div>
    <div class="row justify-content-center alignColumn">
        <div class="col-md-10">
            <div class="row p-4">
                <div class="col-md-6">
                 <img src="../../../assets/images/Mask Group.png" class="w-100"/>
                </div>
                <div class="col-md-6 mt-5 t-rerin" >
                    <div class="row">
                        <div class="col-md-12">
                            <p class="ptext">Easy has been an integral part of my growth from hobbyist to full-time screen printer and business owner. My designs would not have fallen in so many hands without Etsy’s wide reach.</p> 
                        </div>
                     </div>
                     <div class="row">
                     <div class="col-md-12">
                        <h4 class="rerin">Rerin</h4>
                        <p class="ptext">Yellow House Handmade</p>
                    </div>
                </div>
                     
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-2 col-12 t-dots">
                    
                       
                            <span class="dots">
                                ........
                            </span>
                        </div>
                          </div>
        </div>
    </div>
    
    <div class="row justify-content-center alignColumn">
        <div class="col-md-10">
            <div class="row p-4">
                <div class="col-md-6 mt-5 t-rerin">
                   <p class="ptext"> Easyiodghiowhdio has been an integral part of my growth from hobbyist to full-time screen printer and business owner. My designs would not have fallen in so many hands without Etsy’s wide reach.</p>
                   <h4 class="rerin"> Rerin</h4>
                    <p class="ptext">Yellow House Handmade</p>
                </div>
                <div class="col-md-6">
                 <img src="../../../assets/images/Mask Group_3.png" class="w-100"/>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-2 col-12 t-dots">
                    
                       
                            <span class="dots">
                                ........
                            </span>
                        </div>
                         </div>
        </div>
    </div>
    <!-- <div class="row justify-content-center">
        <div class="col-md-2 col-12 t-dots">
            
               
                    <span class="dots">
                        ........
                    </span>
                </div>
                  
            
       
    </div> -->
    <div class="row justify-content-center alignColumn">
        <div class="col-md-10">
            <div class="row p-4">
                <div class="col-md-6">
                 <img src="../../../assets/images/Mask Group_2.png" class="w-100"/>
                </div>
                <div class="col-md-6 mt-5 t-rerin" >
                   <p class="ptext">Easy has been an integral part of my growth from hobbyist to full-time screen printer and business owner. My designs would not have fallen in so many hands without Etsy’s wide reach.</p> 
                    <h4 class="rerin">Rerin</h4>
                   <p class="ptext">Yellow House Handmade</p> 
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-center alignColumn p-4">
    <div class=" col-md-5 ">
        <button class="button2">Enroll Now</button>          
    </div>
</div>
</div>

<app-aboutusone></app-aboutusone>
<app-aboutustwo></app-aboutustwo>
<app-footer></app-footer>