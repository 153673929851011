<!-- For Summary Escalation -->
<div *ngIf="!type || type == 'summary'" class="tableStyle">
  <div class="row alignItem mt-5">
    <div class="col">
      <h5 class="heading">NOC Summary</h5>
    </div>
  </div>

  <!-- Table -->
  
  <div class="row mt-3">
    <div class="col customoverflow">
      <table class="tableColumn">
        <thead>
          <th scope="col">Store Name</th>
          <th scope="col">No of Projects in Execution</th>
          <th scope="col">Avg Ageing</th>
          <th scope="col" style="width: 300px;">Expected handover for this month-value and volume</th>
          <th scope="col">On TAT</th>
          <th scope="col">Off TAT</th>
          <th scope="col" style="width: 200px;">Delayed by 1 day</th>
          <th scope="col" style="width: 200px;">Delayed by 2 to 5 days</th>
          <th scope="col" style="width: 200px;">Delayed by more than 5 days</th>
        </thead>
        <tbody>
          <tr *ngFor="let value of NOCListData?.stores; let i=index">
            <td>{{value.name}}</td>
            <td>{{value.projects_in_execution}}</td>
            <td>{{dateFormate(value.avg_time_since_creation, 'summary')}}</td>
            <td>{{(value.expected_hondover_boq_value_volume[0].value > 0 ? value.expected_hondover_boq_value_volume[0].value.toFixed(2) : value.expected_hondover_boq_value_volume[0].value) +'('+value.expected_hondover_boq_value_volume[0].volume+')'}}</td>
            <td>{{value.on_tat}}</td>
            <td>{{value.off_tat}}</td>
            <td>{{value.delayed_0_1_day}}</td>
            <td>{{value.delayed_2_5_days}}</td>
            <td>{{value.delayed_greater_than_5_days}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<!-- For Summary Escalation -->


<!-- For Older Escalation -->
<div *ngIf="type == 'top_ten'" class="tableStyle">
  <div class="row alignItem mt-5">
    <div class="col">
      <h5 class="heading">Top 10 oldest noc</h5>
    </div>
  </div>

  <!-- Table -->
  
  <div class="row mt-3">
    <div class="col customoverflow">
      <table>
        <thead class="tableColumn">
          <th scope="col">Store Name</th>
          <th scope="col">Lead id</th>
          <th scope="col">Customer Name</th>
          <th scope="col">Current State</th>
          <th scope="col">Ageing</th>
          <th scope="col">Expected handover date</th>
          <th scope="col">Ideal handover Date</th>
        </thead>
        <tbody>
          <tr *ngFor="let value of NOCListOldestData.lead_noc_data; let i=index">
            <td>{{value.store_name}}</td>
            <td>{{value.lead_id}}</td>
            <td>{{value.user_name}}</td>
            <td>{{value.current_stage}}</td>
            <td>{{dateFormate(value.ageing, 'summary')}}</td>
            <td>{{value.expected_handover_date}}</td>
            <td>{{value.ideal_handover_date}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<!-- For Older Escalation -->

<!-- loader -->
<div class="loading d-flex justify-content-center" *ngIf="loader">
  <div class="spinner-border" style="color: #8d0528;" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>
<!-- loader -->