import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PlayVideoModelComponent } from '../play-video-model/play-video-model.component';

declare var $: any;
@Component({
  selector: 'app-video-gallery',
  templateUrl: './video-gallery.component.html',
  styleUrls: ['./video-gallery.component.scss']
})
export class VideoGalleryComponent implements OnInit {

  // url1: any = ;
  // url2: any = ;
  // videoThumbnail1: any = 'https://img.youtube.com/vi/'+ '21KjRjtrbJM' +'/0.jpg';
  // videoThumbnail2: any = 'https://img.youtube.com/vi/'+ 'IKgtsi78Pz0' +'/0.jpg';

  responses: any = [
    {
      id: 1,
      url: 'https://www.youtube.com/embed/21KjRjtrbJM',
      videoThumbnail: 'https://img.youtube.com/vi/'+ '21KjRjtrbJM' +'/0.jpg',
      content: 'We feel blessed that we choose Arrivae whose expert designer make our dream come true with our newly bought 2 BHK with his out of box design suggestions.',
      name: 'Suman Gupta'
    },
    {
      id: 2,
      url: 'https://www.youtube.com/embed/IKgtsi78Pz0',
      videoThumbnail: 'https://img.youtube.com/vi/'+ 'IKgtsi78Pz0' +'/0.jpg',
      content: 'We feel blessed that we choose Arrivae whose expert designer make our dream come true with our newly bought 2 BHK with his out of box design suggestions.',
      name: 'Swathy Sajjalgud'
    },
    {
      id: 3,
      url: 'https://www.youtube.com/embed/IKgtsi78Pz0',
      videoThumbnail: 'https://img.youtube.com/vi/'+ 'IKgtsi78Pz0' +'/0.jpg',
      content: 'We feel blessed that we choose Arrivae whose expert designer make our dream come true with our newly bought 2 BHK with his out of box design suggestions.',
      name: 'Swathy Sajjalgud'
    },
    {
      id: 4,
      url: 'https://www.youtube.com/embed/21KjRjtrbJM',
      videoThumbnail: 'https://img.youtube.com/vi/'+ '21KjRjtrbJM' +'/0.jpg',
      content: 'We feel blessed that we choose Arrivae whose expert designer make our dream come true with our newly bought 2 BHK with his out of box design suggestions.',
      name: 'Suman Gupta'
    },
  ]

  constructor(private modalService: NgbModal) { 
    
  }

  ngOnInit(): void {
    $('.numberCountUp').counterUp({ delay: 10, time: 1000 });
  }


  playVideoModelComponent(url: string) {
    const modal = this.modalService.open(PlayVideoModelComponent, {
      size: 'lg',
      centered: true
    });

    modal.componentInstance.url = url;
    modal.componentInstance.modal = modal;
  }
}
