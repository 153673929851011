import { Component, OnInit, Input } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-project-gallery-carousel-modal',
  templateUrl: './project-gallery-carousel-modal.component.html',
  styleUrls: ['./project-gallery-carousel-modal.component.scss']
})
export class ProjectGalleryCarouselModalComponent implements OnInit {

  @Input() public modal: any;
  @Input() public position: any;

  // images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);

  images = [
    [
      '../../../assets/images/Project gallery/Abhijit Benerji/Section-4_Abhijit-Benerji_01.png',
      '../../../assets/images/Project gallery/Abhijit Benerji/Section-4_Abhijit-Benerji_02.png',
    ],
    [
      '../../../assets/images/Project gallery/Amar & Swati/Section-4_Amar-Swati_01.png',
      '../../../assets/images/Project gallery/Amar & Swati/Section-4_Amar-Swati_02.png',
      '../../../assets/images/Project gallery/Amar & Swati/Section-4_Amar-Swati_03.png',
      '../../../assets/images/Project gallery/Amar & Swati/Section-4_Amar-Swati_04.png',
      '../../../assets/images/Project gallery/Amar & Swati/Section-4_Amar-Swati_05.png',
      '../../../assets/images/Project gallery/Amar & Swati/Section-4_Amar-Swati_06.png',
    ],
    [
      '../../../assets/images/Project gallery/Prathamesh Prabhu/Section-4_PrathameshPrabhu_01.png',
      '../../../assets/images/Project gallery/Prathamesh Prabhu/Section-4_PrathameshPrabhu_02.png',
      '../../../assets/images/Project gallery/Prathamesh Prabhu/Section-4_PrathameshPrabhu_03.png',
      '../../../assets/images/Project gallery/Prathamesh Prabhu/Section-4_PrathameshPrabhu_04.png',
      '../../../assets/images/Project gallery/Prathamesh Prabhu/Section-4_PrathameshPrabhu_05.png',
      '../../../assets/images/Project gallery/Prathamesh Prabhu/Section-4_PrathameshPrabhu_06.png',
    ],
    [
      '../../../assets/images/Project gallery/Rodrigues/Section-4_Rodrigues_01.png',
      '../../../assets/images/Project gallery/Rodrigues/Section-4_Rodrigues_02.png',
      '../../../assets/images/Project gallery/Rodrigues/Section-4_Rodrigues_03.png',
      '../../../assets/images/Project gallery/Rodrigues/Section-4_Rodrigues_04.png',
      '../../../assets/images/Project gallery/Rodrigues/Section-4_Rodrigues_05.png',
      '../../../assets/images/Project gallery/Rodrigues/Section-4_Rodrigues_06.png',
    ],
    [
      '../../../assets/images/Project gallery/Roy/Section-4_Roy_01.png',
      '../../../assets/images/Project gallery/Roy/Section-4_Roy_02.png',
      '../../../assets/images/Project gallery/Roy/Section-4_Roy_03.png',
      '../../../assets/images/Project gallery/Roy/Section-4_Roy_04.png',
      '../../../assets/images/Project gallery/Roy/Section-4_Roy_05.png',
      '../../../assets/images/Project gallery/Roy/Section-4_Roy_06.png',
    ],
  ];

  constructor() {}

  ngOnInit(): void {
    // $('#carouselProjectGalleryControls').carousel(parseInt(this.position));
    $('#carouselProjectGalleryControls').on('slid', '', checkitem);  // on caroussel move
    $('#carouselProjectGalleryControls').on('slid.bs.carousel', '', checkitem); // on carousel move
    
    // on document ready
    $(document).ready(function(){ 
      checkitem();
    });

    function checkitem() { 
      // check function
      var $this = $('#carouselProjectGalleryControls');
      
      if($('.carouselFirst').hasClass('active')) {
        $this.children('.carousel-control-prev').hide();
        $this.children('.carousel-control-next').show();
      } else if($('#carouselLast').hasClass('active')) {
        $this.children('.carousel-control-prev').show();
        $this.children('.carousel-control-next').hide();
      } else {
        $this.children('.carousel-control-prev').show();
        $this.children('.carousel-control-next').show();
      }
    }
    
  }

  closeModel() {
    this.modal.close()
  }

}
