<app-header></app-header>
<div class="container-fluid profile">
  <div class="mob-none row justify-content-center">
    <div class="col-md-10 col-12 breadcrumb-style">
      <div class="row">
        <div class="col-md-6">
          <a href="/" class="text-decoration-none"
            ><span class="home-sec">HOME >> </span></a
          >
          <a><span class="space-sec">GET YOUR ESTIMATE</span></a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid p-0">
  <!--For Web View-->

  <div class="items-carous pt-4">
    <div class="d-flex justify-content-center">
      <span class="titleTriangle"
        >WHY CHOOSE US?
        <div class="mask"></div
      ></span>
      <img
        src="../assets/images/Fill-117.svg"
        class="smallLogoIcon zI1"
        alt="vwuy"
      />
    </div>
    <div class="container-fluid">
      <div class="row py-3 items-carous justify-content-center">
        <div class="col-6 col-md-2">
          <div class="d-flex justify-content-center">
            <div class="footer1">
              <img src="../../../assets/images/home-svg.svg" />
            </div>
            <div>
              <div class="text12500"><span class="num">13500</span></div>
              <div class="textProDel">Spaces Delivered</div>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-2">
          <div class="d-flex justify-content-center">
            <div class="footer1">
              <img src="../../../assets/images/location-svg.svg" />
            </div>
            <div>
              <div class="text12500"><span class="num">19</span><span> Cities</span></div>
              <div class="textProDel">and counting</div>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-2">
          <div class="d-flex justify-content-center">
            <div class="footer1">
              <img src="../../../assets/images/designs-svg.svg" />
            </div>
            <div>
              <div class="text12500"><span class="num">10</span> Lakh</div>
              <div class="textProDel">Designs</div>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-2">
          <div class="d-flex justify-content-center">
            <div class="footer1">
              <img src="../../../assets/images/delivery-svg.svg" />
            </div>
            <div>
              <div class="text12500"><span class="num">45</span> Days</div>
              <div class="textProDel">Delivery</div>
            </div>
          </div>
        </div>
        <div class="col-6 col-md-2">
          <div class="d-flex justify-content-center">
            <div class="footer1">
              <img src="../../../assets/images/warranty-svg.svg" />
            </div>
            <div>
              <div class="text12500"><span class="num">10</span> Years</div>
              <div class="textProDel">Warranty</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--For Mobile View-->

  <!-- <div class="row d-block d-md-none d-flex items-carous">
    <div class="col d-flex col-6" style="justify-content: center">
      <div class="footer1">
        <img src="../../../assets/images/home-svg.svg" />
      </div>
      <div class="">
        <div class="text12500">12500</div>
        <div class="textProDel">Project Delivery</div>
      </div>
    </div>
    <div class="col d-flex col-6">
      <div class="footer1">
        <img src="../../../assets/images/location-svg.svg" />
      </div>
      <div class="">
        <div class="text12500">19 Cities</div>
        <div class="textProDel">and counting</div>
      </div>
    </div>
    <div class="col d-flex col-6" style="justify-content: center">
      <div class="footer1">
        <img src="../../../assets/images/designs-svg.svg" />
      </div>
      <div class="">
        <div class="text12500">1 Million</div>
        <div class="textProDel">Designs</div>
      </div>
    </div>
    <div class="col col-md-2 d-flex col-6">
      <div class="footer1">
        <img src="../../../assets/images/delivery-svg.svg" />
      </div>
      <div class="">
        <div class="text12500">45 Days</div>
        <div class="textProDel">Delivery</div>
      </div>
    </div>

    <div class="col col-md-2 d-flex col-12 mqclass">
      <div class="footer1">
        <img src="../../../assets/images/warranty-svg.svg" />
      </div>
      <div class="">
        <div class="text12500">10 Years</div>
        <div class="textProDel">Warranty</div>
      </div>
    </div>
  </div> 
</div> -->

  <div class="container">
    <div class="d-flex justify-content-center pt-4">
      <span class="titleTriangle"
        >START ESTIMATION
        <div class="mask2"></div
      ></span>
      <img
        src="../assets/images/Fill-117.svg"
        class="smallLogoIcon zI1"
        alt="vwuy"
      />
    </div>

    <div class="row justify-content-center pt-3 tabsdisplay">
      <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <p
            class="nav-item nav-link"
            id="pointer"
            [ngClass]="{ rectangle: 'your space' === activetab }"
          >
            DEFINE YOUR SPACE
          </p>

          <p
            class="nav-item nav-link"
            id="pointer"
            [ngClass]="{ rectangle: 'lifestage' === activetab }"
          >
            PERSONALIZE DESIGN
          </p>

          <p
            class="nav-item nav-link"
            id="pointers"
            [ngClass]="{ rectangles: 'enter details' === activetab }"
          >
            ENTER DETAILS
          </p>
        </div>
      </nav>
    </div>
    <div class="container pt-3">
      <div class="row" *ngIf="activetab == 'your space'">
        <div class="col-md-6 textview">
          <div class="row">
            <div class="col-md-6 col-12">
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="customRadioInline1"
                  name="customRadioInline1"
                  class="custom-control-input"
                  [checked]="defaultApartment"
                  (change)="template('searchedApartment')"
                />
                <!-- <label class="d-block d-md-none">DEFINE YOUR SPACE</label> -->
                <label
                  class="custom-control-label red p-label"
                  for="customRadioInline1"
                  [ngClass]="{ rectan: defaultApartment }"
                  >Search Your Apartment</label
                >
              </div>
            </div>
            <div class="col-md-6 col-12">
              <div class="custom-control custom-radio custom-control-inline">
                <input
                  type="radio"
                  id="customRadioInline2"
                  name="customRadioInline1"
                  class="custom-control-input"
                  [checked]="defaultbuild"
                  (change)="template('build')"
                />
                <label
                  class="custom-control-label grey p-label"
                  for="customRadioInline2"
                  [ngClass]="{ rectan: defaultbuild }"
                  >Build Your Space</label
                >
              </div>
            </div>
          </div>
          <div
            class="row mt-5 justify-content-center"
            *ngIf="templatevalue == 'searchedApartment'"
          >
            <div class="pr-0 _87738 mb-2">
              <select
                class="select-option _56273 text-capitalize"
                (change)="onOptionsSelected($event)"
                style="-webkit-appearance: none"
              >
                <option
                  class="option"
                  *ngFor="let option of citydata?.data"
                  [value]="option.attributes.name"
                  [selected]="selectedCity == option.attributes.name"
                >
                  {{ option.attributes.name }}
                </option>
              </select>
            </div>

            <!-- <div class="col-md-8 pl-0 spacebuilding">
              <ng-select placeholder="Select Building" class="custom" (change)='getselectedbuilding($event)' [ngModel]="selectedBuilding" (keyup)="searchSociety($event)">
                <ng-option *ngFor='let build of building' [value]="build.id">{{build.building_name}}</ng-option>
             </ng-select>
            </div> -->

            <div class="col-md-8 viewonlyweb spacebuilding">
              <ng-select
                placeholder="Select Building*"
                class="custom mobselect"
                (change)="getselectedbuilding($event)"
                [ngModel]="selectedBuilding"
                (keyup)="searchSociety($event)"
              >
                <ng-option *ngFor="let build of building" [value]="build.id">{{
                  build.building_name
                }}</ng-option>
              </ng-select>
            </div>
            <div class="viewonlymob spacebuilding">
              <ng-select
                placeholder="Select Building*"
                class="custom mobselect"
                (change)="getselectedbuilding($event)"
                [ngModel]="selectedBuilding"
                (keyup)="searchSociety($event)"
              >
                <ng-option *ngFor="let build of building" [value]="build.id">{{
                  build.building_name
                }}</ng-option>
              </ng-select>
            </div>

            <div
              class="row mt-3 ml-5 mr-5"
              *ngIf="building && building.length === 0"
            >
              <div class="col-md-12 not-found text-center">
                <p style="font-size: 16px; text-align: center; color: #8d0528">
                  Oops building is not present here. Select build your space
                </p>
              </div>
            </div>
            <ng-container
              *ngIf="flatsize && citydata && building && building.length > 0"
            >
              <div class="col-sm-12 viewwebtype col-md-12">
                <div class="form-group pt-4">
                  <label for="exampleFormControlSelect1"
                    ><b>Select Flat Type/ Size*</b></label
                  >

                  <select
                    class="form-control myformflat dropdown select_category"
                    id="exampleFormControlSelect1"
                    (change)="getselectedbuildingtypes($event)"
                  >
                    <option selected>Select Type*</option>
                    <option
                      *ngFor="let types of flatsize"
                      [value]="types.type"
                      [selected]="selectedBuildingtype == types.type"
                    >
                      {{ types.type }} | {{ types.area }}Sq.Ft.
                    </option>
                  </select>
                </div>
              </div>
            </ng-container>
            <ng-container
              *ngIf="flatsize && citydata && building && building.length > 0"
            >
              <div class= "d-md-none d-sm-block">
                <div class="form-group pt-4">
                  <label for="exampleFormControlSelect1"
                    ><b>Select Flat Type/ Size*</b></label
                  >

                  <select
                    class="form-control myformflat dropdown select_category"
                    id="exampleFormControlSelect1"
                    (change)="getselectedbuildingtypes($event)"
                  >
                    <option selected>Select Type*</option>
                    <option
                      *ngFor="let types of flatsize"
                      [value]="types.type"
                      [selected]="selectedBuildingtype == types.type"
                    >
                      {{ types.type }} | {{ types.area }}Sq.Ft.
                    </option>
                  </select>
                </div>
              </div>
            </ng-container>
          </div>
          <!-- <div
        style="text-align: center; font-size: 16px; color: #8d0528"
        *ngIf="!citydata?.length" >
        No Data Available
      </div> -->
          <ng-container *ngIf="templatevalue == 'searchedApartment'">
            <div class="row">
              <div class="col-md-12">
                <div class="nextBtnPosition">
                  <button
                    type="button"
                    class="btn next"
                    (click)="next('lifestage')"
                    [disabled]="!selectedBuilding || !selectedBuildingtype"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </ng-container>

          <div class="row" *ngIf="templatevalue == 'build'">
            <div class="mobview" *ngIf="templatevalue == 'build'">
              <div class="row">
                <div class="col-12 bselect">
                  <b>Select Your Space*</b>
                </div>
              </div>
              <div class="row">
                <div
                  class="col-md-4 col-6 text-center mt-3"
                  *ngFor="let space of spaceListArr"
                >
                  <div class="wrap">
                    <div class="parents mr-4" *ngIf="space.name == 'kitchen'">
                      <img
                        style="cursor: pointer"
                        id=""
                        src="../../../assets/images/kitchen (1) 1.svg"
                      />
                      <div class="overlay"></div>
                      <div class="btnpar">
                        <span
                          class="space-label"
                          style="color: #8d0528"
                          id=""
                          (click)="handleSpaceClone(space)"
                        >
                          <i class="fa fa-plus" aria-hidden="true"></i>Add</span
                        >
                      </div>
                    </div>

                    <div class="parents" *ngIf="space.name == 'living_room'">
                      <img
                        id="cover-img"
                        src="../../../assets/images/living-room 1.svg"
                      />
                      <div class="overlay"></div>
                      <div class="btnpar">
                        <span
                          class="space-label"
                          style="color: #8d0528"
                          id=""
                          (click)="handleSpaceClone(space)"
                        >
                          <i class="fa fa-plus" aria-hidden="true"></i>Add</span
                        >
                      </div>
                    </div>

                    <div class="parents" *ngIf="space.name == 'dining_room'">
                      <img
                        id="cover-img"
                        src="../../../assets/images/dining-room.svg"
                      />
                      <div class="overlay"></div>
                      <div class="btnpar">
                        <span
                          class="space-label"
                          style="color: #8d0528"
                          id=""
                          (click)="handleSpaceClone(space)"
                        >
                          <i class="fa fa-plus" aria-hidden="true"></i>Add</span
                        >
                      </div>
                    </div>
                    <div class="parents mr-2" *ngIf="space.name == 'bedroom'">
                      <img
                        id="cover-img"
                        src="../../../assets/images/Group 593.svg"
                      />
                      <div class="overlay"></div>
                      <div class="btnpar">
                        <span
                          class="space-label"
                          style="color: #8d0528"
                          id=""
                          (click)="handleSpaceClone(space)"
                        >
                          <i class="fa fa-plus" aria-hidden="true"></i>Add</span
                        >
                      </div>
                    </div>
                    <div class="col-form-label-sm">
                      <div class="checkText content">
                        <p>
                          <input
                            type="checkbox"
                            id="ch-{{ space.id }}"
                            name="ch-{{ space.id }}"
                            [(ngModel)]="space.id === spacebuild"
                            (change)="custumselect($event, space)"
                          />
                          <label for="ch-{{ space.id }}" class="space-class">{{
                            getReplcaeChar(space.name)
                          }}</label>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="form-group">
                  <label
                    for="formGroupExampleInput"
                    class="ml-2 font-weight-bold"
                    >Carpet Area*</label
                  >
                  <input
                    type="number"
                    class="form-control _yus"
                    id="customespacesize"
                    [(ngModel)]="spaceSize"
                    placeholder="Enter Area in Sq. Ft."
                  />
                </div>
                <div class="form-group">
                  <label
                    for="formGroupExampleInput"
                    class="ml-2 font-weight-bold"
                    >Apartment Name*</label
                  >
                  <input
                    type="text"
                    class="form-control _yus"
                    id="customespacesize"
                    [(ngModel)]="apartmentName"
                    placeholder="Enter Apartment Name*."
                  />
                </div>
                <div class="form-group">
                  <label
                    for="formGroupExampleInput"
                    class="ml-2 font-weight-bold"
                    >Pin Code*</label
                  >
                  <input
                    type="number"
                    class="form-control _yus"
                    id="customespacesize"
                    [(ngModel)]="pincode"
                    onkeydown="return event.keyCode !== 69"
                    placeholder="Enter Pin Code"
                    onKeyPress="if(this.value.length==6) return false;"
                    pattern="[0-9]{6}"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="templatevalue == 'build'">
            <div class="col-md-12">
              <div class="nextBtnPosition">
                <button
                  type="button"
                  class="btn next"
                  (click)="next('lifestage'); buildEngage(spaceSize,apartmentName,pincode)"
                  [disabled]="
                    !spacebuild || !spaceSize || !apartmentName || !pincode
                  "
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 imageview">
          <div class="row">
            <div class="col-12 mb-3 d-block d-md-none mobstylyeheading">
              DEFINE YOUR SPACE
            </div>
          </div>
          <img
            src="../../../assets/images/Apartment-Icons.svg"
            class="imgWidth mb-4"
          />
        </div>
      </div>

      <div class="row" *ngIf="activetab == 'lifestage'">
        <div class="col-md-6 textview">
          <div class="row">
            <div class="col-12 blife">
              <b>Select Your Lifestage(s)*</b>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-4 col-6 text-center">
              <div>
                <img src="../../../assets/images/Group 64.svg" />
                <div class="col-form-label-sm">
                  <div class="checkText justify-content-center">
                    <div
                      class="ractangle"
                      [ngClass]="{
                        rectanglecheck: 'young vibes' === customSpacelifestage
                      }"
                      (click)="custumselectlifestage('young vibes')"
                    ></div>
                    <!-- <input
                      type="checkbox"
                      aria-label="Checkbox for following text input"
                      class="mr-1 checkboxWidth"
                      (change)="custumselectlifestage($event,'young vibes')"/> -->
                    <div class="pRooms">
                      <p style="color: #000000">Young Vibes</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-6 text-center">
              <div>
                <img
                  src="../../../assets/images/LIfestage vibe display Final.svg"
                />
                <div class="col-form-label-sm">
                  <div class="checkText justify-content-center">
                    <div
                      class="ractangle"
                      [ngClass]="{
                        rectanglecheck: 'love vibes' === customSpacelifestage
                      }"
                      (click)="custumselectlifestage('love vibes')"
                    ></div>
                    <!-- <input
                      type="checkbox"
                      aria-label="Checkbox for following text input"
                      class="mr-1 checkboxWidth"
                     (change)="custumselectlifestage($event,'love vibes')"/>
                    <div class="pRooms"> -->
                    <p style="color: #000000">Love Vibes</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-6 text-center">
              <div>
                <img src="../../../assets/images/Page 3 (1).svg" />
                <div class="col-form-label-sm">
                  <div class="checkText justify-content-center">
                    <div
                      class="ractangle"
                      [ngClass]="{
                        rectanglecheck: 'baby vibes' === customSpacelifestage
                      }"
                      (click)="custumselectlifestage('baby vibes')"
                    ></div>
                    <!-- <input
                      type="checkbox"
                      aria-label="Checkbox for following text input"
                      class="mr-1 checkboxWidth"
                      (change)="custumselectlifestage($event,'baby vibes')"/> -->
                    <div class="pRooms">
                      <p style="color: #000000">Baby Vibes</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-6 text-center">
              <div>
                <img src="../../../assets/images/Page 4 (1).svg" />
                <div class="col-form-label-sm">
                  <div class="checkText justify-content-center">
                    <div
                      class="ractangle"
                      [ngClass]="{
                        rectanglecheck: 'lively vibes' === customSpacelifestage
                      }"
                      (click)="custumselectlifestage('lively vibes')"
                    ></div>
                    <!-- <input
                      type="checkbox"
                      aria-label="Checkbox for following text input"
                      class="mr-1 checkboxWidth"
                      (change)="custumselectlifestage($event,'lively vibes')"/> -->
                    <div class="pRooms">
                      <p style="color: #000000">Lively Vibes</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-6 text-center">
              <div>
                <img src="../../../assets/images/Page 5 (1).svg" />
                <div class="col-form-label-sm">
                  <div class="checkText justify-content-center">
                    <div
                      class="ractangle"
                      [ngClass]="{
                        rectanglecheck: 'happy vibes' === customSpacelifestage
                      }"
                      (click)="custumselectlifestage('happy vibes')"
                    ></div>
                    <div class="pRooms">
                      <p style="color: #000000">Happy Vibes</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- For Button Know Your Lifesatge-->
            <div class="col-md-4 col-6 webview" style="margin-top: 2.3rem">
              <div>
                <a href="/lifestage" target="_blank" class="lifestage-link"
                  >Know Your Lifestage</a
                >
              </div>
            </div>
          </div>

          <div class="row">
            <!-- Ends here-->
          </div>
          <div class="row justify-content-center">
            <div
              class="col-12 text-center mobviewlifestage"
              style="margin-bottom: 2.3rem"
            >
              <!-- <div> -->
              <a href="/lifestage" target="_blank" class="lifestage-link"
                >Know Your Lifestage</a
              >

              <!-- </div> -->
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="formGroupExampleInput"><b>Select Theme*</b></label
                ><br />
                <select
                  class="form-control myform dropdown select_category"
                  (change)="selecttheme($event)"
                >
                  <option
                    class="option"
                    selected
                    disabled
                    *ngIf="!selectedtheme"
                  >
                    Select Theme*
                  </option>
                  <option class="option" value="contemporary">
                    CONTEMPORARY
                  </option>
                  <option class="option" value="eclectic">ECLECTIC</option>
                  <option class="option" value="indian ethenic">
                    INDIAN ETHNIC
                  </option>
                  <option class="option" value="minimilistic">
                    MINIMILISTIC
                  </option>
                  <option class="option" value="modern">MODERN</option>
                  <option class="option" value="rustic industrial">
                    RUSTIC INDUSTRIAL
                  </option>
                  <option class="option" value="transitional">
                    TRANSITIONAL
                  </option>
                </select>
              </div>
              <div class="webview">
                <a href="/themes" target="_blank" class="lifestage-link"
                  >Check Themes</a
                >
              </div>

              <!--For Mobile -->
              <div class="mobviewlifestage text-center">
                <a href="/themes" target="_blank" class="lifestage-link"
                  >Check Themes</a
                >
              </div>
              <!---->
            </div>
          </div>
          <div class="nextBtnPosition">
            <button
              type="button"
              class="btn next"
              (click)="next('enter details'); personDesign(customSpacelifestage,selectedtheme)"
              [disabled]="!customSpacelifestage || !selectedtheme"
            >
              Next
            </button>
          </div>
          <div class="nextBtnPosition">
            <button
              type="button"
              class="btn nextTab"
              (click)="
                next('your space'); checkActiveTemplate(); setCheckboxVal()
              "
            >
              Back
            </button>
          </div>
        </div>

        <div class="col-md-6 imageview">
          <div class="row">
            <div class="col-12 mb-3 d-block d-md-none mobstylyeheading">
              SELECT LIFESTAGE
            </div>
          </div>
          <img src="../../../assets/images/Family-Icon.svg" class="imgWidth" />
        </div>
      </div>

      <div class="row" *ngIf="activetab == 'enter details'">
        <div class="col-md-6 text-center textview">
          <form [formGroup]="signupForum">
            <label class="get-estimate-label"
              >Enter details to see the estimation</label
            >
            <div class="form-group text-left">
              <label class="inputName" for="inputName">Your Name*</label>
              <input
                type="text"
                class="form-control myform"
                placeholder="Full Name*"
                formControlName="name"
                id="name"
              />
              <span
                class="text-danger fontSize12"
                *ngIf="
                  signupForum.controls['name'].hasError('required') &&
                  (signupForum.controls['name'].dirty ||
                    signupForum.controls['name'].touched)
                "
              >
                Please provide full name</span
              >
            </div>
            <div class="form-group text-left">
              <label class="inputMobile" for="inputMobile">Mobile Number*</label>
              <input
                type="number"
                class="form-control myform"
                formControlName="mobile"
                placeholder="Enter Mobile Number*"
                id="mobile"
                onkeydown="return event.keyCode !== 69"
                onKeyPress="if(this.value.length==10) return false;"
                required
                pattern="[1-9]{1}[0-9]{9}"
              />
              <span
                class="text-danger fontSize12"
                *ngIf="
                  signupForum.controls['mobile'].hasError('pattern') &&
                  (signupForum.controls['mobile'].dirty ||
                    signupForum.controls['mobile'].touched)
                "
              >
                Please provide valid contact number</span
              >
            </div>

            <div class="form-group text-left">
              <label class="inputMobile">Purpose Of Property*</label>
              <select
                class="myform form-control dropdown select_category"
                formControlName="purposeProperty"
                (change)="selectpurpose($event)"
              >
                <!-- <option selected value="" disabled>Select Your Purpose</option> -->
                <!-- <option disabled value="" selected>Select Type</option> -->
                <!-- <option selected value="" disabled>Select Your Purpose</option> -->
                  <!-- <option class="option" selected disabled>Select Theme</option> -->
                  <option disabled value="" selected>Purpose Of Property*</option>
                <option class="option" value="Own Stay">Own Stay</option>
                <option class="option" value="Rental">Rental</option>
                <option class="option" value="Not Disclosed">
                  Not Disclosed
                </option>
                <!-- <option
                      *ngFor="let types of flatsize"
                      [value]="types.area"
                      [selected]="selectedBuildingtype == types.area"
                    >
                      {{ types.type }} | {{ types.area }}Sq.Ft.
                    </option> -->
              </select>
            </div>

            <!-- <div class="form-group">
              <label for="formGroupExampleInput"><b>Select Theme</b></label><br>
              <select class='select-option _562637' (change)='selecttheme($event)'>
                <option class='option' [selected]="selectedtheme"  *ngIf="selectedtheme">{{selectedtheme.toUpperCase()}}</option>
                <option class='option'selected disabled *ngIf="!selectedtheme">Select Theme</option>
                <option class='option' value="contemporary">CONTEMPORARY</option>
                <option class='option' value="eclectic">ECLECTIC</option>
                <option class='option' value="indian ethenic">INDIAN ETHNIC</option>
                <option class='option' value="minimilistic">MINIMILISTIC</option>
                <option class='option' value="modern">MODERN</option>
                <option class='option' value="rustic industrial">RUSTIC INDUSTRIAL</option>
                <option class='option' value="transitional">TRANSITIONAL</option>
              </select>
            </div> -->
            <div class="form-group text-left">
              <label class="inputEmail4" for="inputEmail4">Enter Email*</label>
              <input
                type="email"
                class="form-control myform"
                id="inputEmail4"
                formControlName="email"
                placeholder="Enter Your Email*"
                id="email"
              />
              <span
                class="text-danger fontSize12"
                *ngIf="
                  (signupForum.controls['email'].hasError('required') ||
                    signupForum.controls['email'].hasError('pattern')) &&
                  (signupForum.controls['email'].dirty ||
                    signupForum.controls['email'].touched)
                "
              >
                Please provide Valid Email Address</span
              >
              <div class="nextBtnPosition">
                <button
                  type="button"
                  class="btn next"
                  [disabled]="!signupForum.valid"
                  (click)="signupForm()"
                >
                  Sign Up
                </button>
              </div>
              <div class="nextBtnPosition">
                <button
                  type="button"
                  class="btn nextTab"
                  (click)="next('lifestage')"
                >
                  Back
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-6 imageview">
          <div class="row">
            <div class="col-12 mb-3 d-block d-md-none mobstylyeheading">
              ENTER DETAILS
            </div>
          </div>
          <img src="../../../assets/images/Theme-icon.svg" class="imgWidth" />
        </div>
      </div>
    </div>
  </div>

  <app-aboutusone></app-aboutusone>
  <app-aboutustwo></app-aboutustwo>
  <app-footer></app-footer>
  <div class="loading d-flex justify-content-center" *ngIf="loader">
    <div class="spinner-border" style="color: #8d0528" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
