<app-header ></app-header>
<!-- <div class="container-fluid profile">
<div class="mob-none row justify-content-center">
  <div class="col-md-10 col-12 breadcrumb-style">
   <div class="row"> 
     <div class="col-md-6">
   <a href="/landing" class="text-decoration-none"><span class="home-sec">HOME >>  </span></a> 
   <a><span class="space-sec">SPACES</span></a>
    </div>
    </div>
    </div>
  </div>  
</div> -->
<div class="">
  <!-- <div class="row"> -->
  <img data-src="../../../assets/images/SPACES.png" class="lazyload profile" />
  <div class="mob-none">
    <div class="row justify-content-center">
      <div class="col-md-10 col-12 breadcrumb-style">
        <a href="/landing" class="text-decoration-none"><span class="home-sec">HOME >> </span></a>
        <a><span class="space-sec">SPACES</span></a>
      </div>
    </div>
  </div>
  <!-- </div> -->
</div>
<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="row border my-4 align-items-center brdradius">
        <div class="col-md-3 border-left brdrbtm">
          <div class="form-group mb-0">
            <label class="ml-3 mb-0 fs14">Select Space Type</label>
            <select class="form-control border-0 text-capitalize" (change)="spaceFilter($event)"
              style="color: #000000; font-size: 17px;">
              <option *ngIf="this.space_name2 === ''" selected disabled>All</option>
              <ng-container *ngFor="let item of update_space">
                <option [selected]="item == this.space_name2">{{ transform(item) }}</option>
              </ng-container>
            </select>
          </div>
        </div>
        <div class="col-md-3 border-left brdrbtm">
          <div class="form-group mb-0">
            <label class="ml-3 mb-0 fs14">Select Your LifeStage </label>
            <select class="form-control border-0 text-capitalize" (change)="lifeStageFilter($event)"
              style="color: #000000; font-size: 17px;">
              <option selected disabled>Select</option>
              <ng-container *ngFor="let item of lifestage">
                <option [selected]="item == this.lifeStage_filter_value1">{{ transform(item) }}</option>
              </ng-container>
            </select>
          </div>
        </div>
        <div class="col-md-3 border-left">
          <div class="form-group mb-0">
            <label class="ml-3 mb-0 fs14">Select Theme</label>
            <select class="form-control border-0 text-capitalize" (change)="themeFilter($event)"
              style="color: #000000; font-size: 17px;">
              <option selected disabled>Select</option>
              <ng-container *ngFor="let item of update_theme">
                <option [selected]="item == this.theme_filter_value" *ngIf="item !== ''">{{ transform(item) }}</option>
              </ng-container>
            </select>
          </div>
        </div>
        <div class="col-md-3 border-left">
          <div class="form-group mb-0">
            <label class="ml-3 mb-0 fs14">Select Price Range</label>
            <select class="form-control border-0 text-capitalize" (change)="priceFilter($event)"
              style="color: #000000; font-size: 17px;">
              <option selected disabled>Select</option>
              <ng-container *ngFor="let item of pricerange">
                <option *ngIf="item !== ''">{{ item.name }}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid mb-5">
  <div class="row mt-4 justify-content-center">
    <div class="col-md-10 col-lg-10 col-sm-12">
      <div class="row">
        <ng-container *ngFor="
            let portfolio of list?.data
              | paginate
                : {
                    itemsPerPage: per_page,
                    currentPage: current_page,
                    totalItems: total_page
                  };
            let i = index
          ">
          <div class="col-md-4 col-lg-4 col-sm-12 mb-4">
            <a (click)="btnClick(portfolio.attributes.id)" class="img-point" target="_blank">
            <!-- <img data-src="{{portfolio.attributes.images[0]}}" alt="image not" class="imageone img-fluid w-100 lazyload"
            onerror="src='../../../assets/images/default-apartment.png'" /> -->
            <ng-container *ngIf="portfolio.attributes.attachment_file != '/images/original/missing.png' ; else other_content">
              <img data-src="{{portfolio.attributes.attachment_file}}" alt="image not" class="imageone img-fluid w-100 lazyload"
              onerror="src='../../../assets/images/default-apartment.png'" />
            </ng-container>

            <ng-container>
                <ng-template #other_content>
                  <img data-src="{{portfolio.attributes.images[portfolio.attributes.images.length-1]}}" alt="image not" class="imageone img-fluid w-100 lazyload"
                  onerror="src='../../../assets/images/default-apartment.png'" />
                </ng-template>
            </ng-container>
              </a>
            <div [ngClass]="{'no-arrow': portfolio.attributes.lifestage === ''}" class="d-flex justify-content-center titlediv">
              <span  class="titleTriangle2 text-capitalize align-items-center d-flex">
          <img *ngIf="portfolio.attributes.lifestage ==='young_vibes'" src="../../../assets/images/YOUNG VIBES.svg" />
          <img *ngIf="portfolio.attributes.lifestage ==='love_vibes'" src="../../../assets/images/Page 2.svg" />
          <img src="../../../assets/images/Page 3.svg" *ngIf="portfolio.attributes.lifestage==='Working Couple'"/>
          <img src="../../../assets/images/Page 4.svg" *ngIf="portfolio.attributes.lifestage==='Old Age'"/>
          <img src="../../../assets/images/Page 2.svg" *ngIf="portfolio.attributes.lifestage==='Bachelor'"/>
          <img src="../../../assets/images/YOUNG VIBES.svg" *ngIf="portfolio.attributes.lifestage==='Large Families'"/>
          <img src="../../../assets/images/Page 4.svg" *ngIf="portfolio.attributes.lifestage==='lively_vibes'"/>
          <img src="../../../assets/images/Page 2.svg" *ngIf="portfolio.attributes.lifestage==='happy_vibes'"/>
          <img src="../../../assets/images/Page 3.svg" *ngIf="portfolio.attributes.lifestage==='baby_vibes'">
          <img src="" *ngIf="portfolio.attributes.lifestage===''"/>
                <span class="ml-2">{{
                  transform(portfolio.attributes.lifestage)

                  }}</span>
                <div [ngClass]="{'no-arrow':portfolio.attributes.lifestage === ''}" class="arrow-down"></div>
              </span>
            </div>
            <div class="enter">{{portfolio.attributes.space}}&nbsp;-&nbsp;<span>{{portfolio.attributes.id}}</span></div>
            <div class="unit">
              EMI Starts From: <i class="fa fa-inr"></i> {{ portfolio.attributes.price_cents }}/-
            </div>
            <div class="mrginT text-right">
              <button type="button" (click)="btnClick(portfolio.attributes.id)" class="btn btn-sm consultbtn-1 mr-3">
                <span class="text-btn">View Detailssss</span>
              </button>
              <button type="button" class="btn btn-sm consultbtn-2">
                <span class="text-btn-2" (click)="getThisDesignSpaces(portfolio.attributes)">Get this Design
                </span>
              </button>
            </div>

          </div>

        </ng-container>
        <div class="text-center w-100 mt-5 mb-3 cusPagiWeb" *ngIf="list && list.data.length > 0">
          <pagination-controls (pageChange)="current_page = getPortfolioListHandler($event)" previousLabel="PREV"
            nextLabel="NEXT">
          </pagination-controls>
        </div>
        <div class="text-center w-100 mt-5 mb-3 cusPagiMob" *ngIf="list && list.data.length > 0">
          <pagination-controls (pageChange)="current_page = getPortfolioListHandler($event)" [maxSize]=maxSize
            previousLabel="PREV" nextLabel="NEXT">
          </pagination-controls>
        </div>
      </div>

      <div style="text-align: center; font-size: 16px; color: #8d0528" *ngIf="list && list.data.length === 0">
        No Data Available
      </div>
    </div>
  </div>
</div>

<!-- <div class="container-fluid">
  <div class="row mt50px justify-content-center">
    <div class="col-12 col-md-10">
      <div class="d-flex">
        <span class="titleTriangle"
          >LATEST DESIGN SPACES
          <div class="mask"></div
        ></span>
        <img
          src="../assets/images/Fill-117.svg"
          class="smallLogoIcon zI1"
          alt="vwuy"
        />
      </div>
    </div>
    <div class="col-sm-12 col-md-10 mt50px">
      <img
        src="../../../assets/images/Fill-117.svg"
        class="Fill-117-3 rotateLogo ltsRotateLogo1"
        alt="vwuy"
      />

      <div
        id="LDS1CarouselControls"
        class="carousel slide ml15per"
        data-ride="carousel"
        data-pause="false"
      >
        <div class="carousel-inner">
          <ng-container *ngFor="let space of demoResponse?.data; let i = index">
            <div class="carousel-item" [ngClass]="{ active: i === 0 }">
              <img
                [src]="space.attributes.space_image"
                class="img-fluid w-100 spaceImg"
                alt="latest-space-img"
              />
            </div>
          </ng-container>
        </div>
        <a
          class="carousel-control-prev d-none"
          id="LDS1CarouselPrev"
          href="#LDS1CarouselControls"
          role="button"
          data-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="carousel-control-next d-none"
          id="LDS1CarouselNext"
          href="#LDS1CarouselControls"
          role="button"
          data-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
      <div class="LDSCarouselDisc p-4">
        <div
          id="LDS2CarouselControls"
          class="carousel slide"
          data-ride="carousel"
          data-pause="false"
        >
          <div class="carousel-inner">
            <ng-container
              *ngFor="let space of demoResponse?.data; let i = index"
            >
              <div class="carousel-item" [ngClass]="{ active: i === 0 }">
                <div class="LDSCarouselItem">
                  <h6>{{ space.type.replace("_", " ") }}</h6>
                  <h4>{{ space.attributes.title }}</h4>
                  <p>{{ space.attributes.description }}</p>
                  <div class="d-flex align-items-center">
                    <div class="mr-4 profileSection">
                      <h6 class="mb-0">{{ space.attributes.customer_name }}</h6>
                      <p>{{ space.attributes.customer_location }}</p>
                    </div>
                    <img
                      [src]="space.attributes.customer_image"
                      alt="customer_image"
                      class="cus_img"
                    />
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
          <a
            class="carousel-control-prev d-none"
            id="LDS2CarouselPrev"
            href="#LDS2CarouselControls"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next d-none"
            id="LDS2CarouselNext"
            href="#LDS2CarouselControls"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <div class="d-flex mt-4 position-relative">
          <img
            src="../../../assets/images/Fill-117.svg"
            class="Fill-117-3 rotateLogo ltsRotateLogo2"
            alt="vwuy"
          />
          <button
            class="prevButton cusBtn position-relative"
            (click)="carouserHandler('prev')"
          >
            <span><i class="fa fa-arrow-left prevButtonIcon"></i></span> Prev
            <div class="mask1"></div>
            <div class="mask2"></div>
            <div class="mask3"></div>
          </button>
          <button
            class="nextButton cusBtn position-relative"
            (click)="carouserHandler('next')"
          >
            <span>Next <i class="fa fa-arrow-right nextButtonIcon"></i></span>
            <div class="mask1"></div>
            <div class="mask2"></div>
            <div class="mask3"></div>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4 justify-content-center mb-5">
    <div class="rectangular4">
      <div class="row">
        <div class="col-3">
          <img src="../assets/images/consulation.png" class="consulation" />
        </div>
        <div class="col-8 free">
          Free Consultation Easy Booking
          <div class="know">Know More</div>
        </div>
      </div>
    </div>
    <div class="rectangular5">
      <div class="row">
        <div class="col-3">
          <img src="../assets/images/houseprice.png" class="consulation" />
        </div>
        <div class="col-8 free">
          emi option on complete project
          <div class="know">Know More</div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<app-latestdesign></app-latestdesign>
<app-aboutusone></app-aboutusone>
<app-aboutustwo></app-aboutustwo>
<app-footer></app-footer>

<div class="loading d-flex justify-content-center" *ngIf="loader">
  <div class="spinner-border" style="color: #8d0528;" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>