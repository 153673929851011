import { Component, OnInit } from '@angular/core';
import { LandingService } from '../landing.service';
import { Slick } from 'ngx-slickjs';


@Component({
  selector: 'app-updated-blogs-design',
  templateUrl: './updated-blogs-design.component.html',
  styleUrls: ['./updated-blogs-design.component.scss'],
})
export class UpdatedBlogsDesignComponent implements OnInit {
  [x: string]: any;
  blogs: any;
  imageSrc: any;
  desc: any;
  cont: any;
  cont1: any;
  cont2: any;

  constructor(public landingService: LandingService) {}

  ngOnInit(): void {
    this.step = 'step2';
    this.imageSrc = '../../../assets/images/Design-with-us/design-with-us.png';
    this.getBlogs();
    // this.getDescription('');

    // this.getDescription1('');
    // this.getDescription2('');
    // this.first();
    this.loadScript()
  }
  config: Slick.Config = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    draggable: true,
    mouseWheelMove: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
 
  getBlogs() {
    this.landingService.loadPosts().subscribe((data) => {
      this.blogs = data;
    });
  }
  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  step: any;
  over() {
    this.step = 'step1';
    this.imageSrc =
      '../../../assets/images/Landing_page_updated/Resized  -Build With Us - Temp-827x504.jpg';
  }
  first() {
    this.step = 'step2';
    this.imageSrc =
      '../../../assets/images/Landing_page_updated/Resized Design With Us - Temp-827x504 (1).jpg';
  }

  getDescription(s: any) {
    var span = document.createElement('span');
    this.landingService.loadPosts().subscribe((data) => {
      this.desc = data;
      span.innerHTML = this.desc[2].content.rendered;
      this.cont = span.textContent || span.innerText;
    });
  }

  getDescription1(s: any) {
    var span = document.createElement('span');
    this.landingService.loadPosts().subscribe((data) => {
      this.desc = data;
      span.innerHTML = this.desc[0].content.rendered;
      this.cont1 = span.textContent || span.innerText;
    });
  }

  getDescription2(s: any) {
    var span = document.createElement('span');
    this.landingService.loadPosts().subscribe((data) => {
      this.desc = data;
      span.innerHTML = this.desc[1].content.rendered;
      this.cont2 = span.textContent || span.innerText;
    });
  }
  roletoSet() {
    var width = Math.max(window.screen.width, window.innerWidth)
    return width

  }

  Elements:any;
  slickIndex:any
  checkactive(){
   this.Elements = document.getElementsByClassName("mobile_image");
    let Indexofitem;
 
     for (var i = 0; i < this.Elements.length; i++) {
       
        let classes = this.Elements[i].className.split(' ')
        if(classes.includes('slick-active')) {
             Indexofitem = i;
             this.slickIndex = Indexofitem;
        }
       
        this.ElementsDetals = document.getElementsByClassName("details");

        for (var i = 0; i < this.ElementsDetails.length; i++) {
       
          let classes = this.Elements[i].className.split(' ')
            console.log(this.ElementsDetails[i])



        }
      }


    }


}
