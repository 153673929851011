import { HttpClient } from '@angular/common/http';
import { Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';
import { SpaceService } from '../space.service';
import { LandingService } from '../../landing/landing.service';    // only for demo
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SpacesGetThisDesignFormComponent } from '../spaces-get-this-design-form/spaces-get-this-design-form.component';
import { Location } from '@angular/common';
declare function filterSpace(
  param1: any,
  param2: any,
  param3: any,
  param4: any
): any;
@Component({
  selector: 'app-spaces',
  templateUrl: './spaces.component.html',
  styleUrls: ['./spaces.component.scss']
})
export class SpacesComponent implements OnInit {

  receivedChildMessage: string = '';

  constructor(private router: Router, public spaceService: SpaceService,public landingService: LandingService,
    http: HttpClient, private modalService: NgbModal, private toastr: ToastrService,
    public route: ActivatedRoute, private location: Location) { }
  demoResponse: any; 
  response: any;
  list: any;
  space: any;
  spaceDropdown: any = [];
  api_data: any;
  space_name: any;
  lifestage: any;
  theme: any;
  selectedValue: any;
  per_page: any = 12;
  total_page: any;
  current_page: any = '1';
  story: string = '';
  space_name2: any = '';
  space_name3: any;
  page_name_query: any = 1;
  loader: boolean = false;
  pagelimit: string = '3';
  public maxSize: number = 3;
  serachedspace: any = '';
  finalspace: any = '';
  lifeStage_filter_value1: any;
  ngOnInit(): void {
    this.getDropdownSpace();
    this.getStory('');
    this.route.queryParams.subscribe(params => {

      this.space_name2 = params['space_name'];
      this.serachedspace = params['search'];
      this.lifeStage_filter_value = params['lifestage_name'];
      this.theme_filter_value = params['theme_name'];
      if (params['space_name'] === undefined) {
        this.space_name2 = ''
      }
      if (params['search'] === undefined) {
        this.serachedspace = ''
      }
      if (params['lifestage_name'] === undefined) {
        this.lifeStage_filter_value = ''
      }
      if (params['theme_name'] === undefined) {
        this.theme_filter_value = ''
      }
      if (params['lifestage_name'] === 'lively_vibes') {
        this.lifeStage_filter_value1 = 'lively vibes';
      }
      if (params['lifestage_name'] === 'baby_vibes') {
        this.lifeStage_filter_value1 = 'baby vibes';
      }
      if (params['lifestage_name'] === 'happy_vibes') {
        this.lifeStage_filter_value1 = 'happy vibes';
      }
      if (params['lifestage_name'] === 'young_vibes') {
        this.lifeStage_filter_value1 = 'young vibes';
      }
      if (params['lifestage_name'] === 'love_vibes') {
        this.lifeStage_filter_value1 = 'love vibes';
      }

      this.page_name_query = params['page'];
      this.getPortfolioList(this.space_name2, this.lifeStage_filter_value, this.theme_filter_value,'', this.page_name_query, this.per_page, this.serachedspace);
    });
    this.getpricerange();
    this.loadScript()
  }

  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

 

  carouserHandler(data: any) {
    if (data === 'next') {
      (<HTMLInputElement>document.getElementById('LDS1CarouselNext')).click();
      (<HTMLInputElement>document.getElementById('LDS2CarouselNext')).click();
    } else {
      (<HTMLInputElement>document.getElementById('LDS1CarouselPrev')).click();
      (<HTMLInputElement>document.getElementById('LDS2CarouselPrev')).click();
    }
  }

  btnClick(id: any) {
    this.router.navigate(['/space-inclusion/' + id]);
  }

  update_space: any;
  update_theme: any;
  space_name_value: any;
  getDropdownSpace() {
    this.spaceService.getDropdownspace().subscribe(data => {
      this.spaceDropdown = data;
      this.api_data = Object.values(this.spaceDropdown);
      this.space_name = this.api_data[0];
      var spaceList = this.space_name;
      var index1 = spaceList.indexOf("Bedroom");
      var index2 = spaceList.indexOf("Kids Room");
      var index3 = spaceList.indexOf("Kitchen");
      var index4 = spaceList.indexOf("Living");
      var space1 = this.space_name[index1];
      var space2 = this.space_name[index2];
      var space3 = this.space_name[index3];
      var space4 = this.space_name[index4];
      this.update_space = [space1, space2, space3, space4];
      this.lifestage = this.api_data[1]; 
      this.theme = this.api_data[2];
      var themelist = this.theme;
      var index11 = themelist.indexOf("Contemporary");
      var index12 = themelist.indexOf("Indian Ethnic");
      var index13 = themelist.indexOf("Lifestage Kitchen");
      var index14 = themelist.indexOf("Minimalist");
      var index15 = themelist.indexOf("Modern");
      var index16 = themelist.indexOf("Rustic Industrial");
      var index17 = themelist.indexOf("Transitional");
      var theme11 = this.theme[index11];
      var theme12 = this.theme[index12];
      var theme13 = this.theme[index13];
      var theme14 = this.theme[index14];
      var theme15 = this.theme[index15];
      var theme16 = this.theme[index16];
      var theme17 = this.theme[index17];
      this.update_theme = [theme11, theme12, theme13, theme14, theme15, theme16, theme17];
      if (this.space_name2 !== undefined) {
        this.space_name3 = this.space_name2;
      }
      else {
        this.space_name3 = ''
      }
    })
  }

  space_filter_value: any = '';
  spaceFilter(event: any) {
    if (this.page_name_query === undefined) {
      this.page_name_query = 1;
    }
    this.space_filter_value = event.target.value;
    if (this.space_filter_value !== undefined) {
      this.space_name3 = this.space_filter_value;
    }
    else {
      this.space_name3 = ''
    }
    filterSpace(
      this.space_name3,
      this.lifeStage_filter_value,
      this.theme_filter_value,
      this.price_filter_value
    );
    this.router.navigate(['/spaces'],
    { queryParams: {space_name: this.space_name3, page: this.page_name_query}})  
    this.getPortfolioList(this.space_name3, this.lifeStage_filter_value, this.theme_filter_value, this.final_price, this.current_page, this.per_page, this.serachedspace);
  }

  lifeStage_filter_value: any = '';
  lifeStageFilter(event: any) {
    this.lifeStage_filter_value = event.target.value;
    if(this.lifeStage_filter_value === "young vibes"){
      this.lifeStage_filter_value = "young_vibes";
    }
    if(this.lifeStage_filter_value === "love vibes"){
      this.lifeStage_filter_value = "love_vibes";
    }
    if(this.lifeStage_filter_value === "baby vibes"){
      this.lifeStage_filter_value = "baby_vibes";
    }
    if(this.lifeStage_filter_value === "happy vibes"){
      this.lifeStage_filter_value = "happy_vibes";
    }
    if(this.lifeStage_filter_value === "lively vibes"){
      this.lifeStage_filter_value = "lively_vibes";
    }
    filterSpace(
      this.space_name3,
      this.lifeStage_filter_value,
      this.theme_filter_value,
      this.price_filter_value
    );
    this.getPortfolioList(this.space_filter_value, this.lifeStage_filter_value, this.theme_filter_value,this.final_price, 1, this.per_page, this.serachedspace);
  }

  theme_filter_value: any = '';
  themeFilter(event: any) {
    this.theme_filter_value = event.target.value;
    filterSpace(
      this.space_name3,
      this.lifeStage_filter_value,
      this.theme_filter_value,
      this.price_filter_value
    );
    this.getPortfolioList(this.space_filter_value, this.lifeStage_filter_value, this.theme_filter_value, this.final_price, 1, this.per_page, this.serachedspace);
  }

  price_filter_value: any = '';
  final_price: any = [];
  priceFilter(event: any) {
    this.price_filter_value = event.target.value;
    if (this.price_filter_value ==='Under ₹ 1 Lakh') {
      this.final_price = JSON.stringify([[0, 10000], [10000, 100000]]);
    }
    if (this.price_filter_value === '₹ 1 Lakh to ₹ 2 Lakh') {
      this.final_price = JSON.stringify([[100000, 200000], [100000, 200000]]);
    }
    if (this.price_filter_value === '₹ 2 Lakh to ₹ 3 Lakh') {
      this.final_price = JSON.stringify([[200000,300000],[200000,300000]]);
    }
    if (this.price_filter_value === '₹ 3 Lakh to ₹ 4 Lakh') {
      this.final_price = JSON.stringify([[300000,400000],[300000,400000]]);
    }
    if (this.price_filter_value === 'Over ₹ 4 Lakh') {
      this.final_price = JSON.stringify([[400000,1000000],[1000000,130000000]]);
    }
    filterSpace(
      this.space_name3,
      this.lifeStage_filter_value,
      this.theme_filter_value,
      this.price_filter_value
    );

    this.getPortfolioList(this.space_filter_value, this.lifeStage_filter_value, this.theme_filter_value, this.final_price, this.current_page, this.per_page, this.serachedspace);
  }

  getPortfolioList(space: any, lifeStage: any, theme: any, range:any, curr_page: any, per_page: any, search_string: any) {
    this.loader = true;
    this.finalspace = this.serachedspace
    this.spaceService.getPortfolioList(space, lifeStage, theme,range, curr_page, per_page, this.finalspace).subscribe(data => {
      this.list = data;
      this.total_page = this.list.total_records;
      this.loader = false;
      if (curr_page === undefined) {
        curr_page = 1;
      }
      this.getPortfolioListHandler(curr_page);
    })
  }

  getPortfolioListHandler(curr_page: any) {
    this.loader = true;
    this.current_page = curr_page;
    this.location.go(`spaces?space_name=${this.space_name3}&lifestage_name=${this.lifeStage_filter_value}&theme_name=${this.theme_filter_value}&page=${this.current_page}`);
   
    if (this.space_filter_value !== undefined) {
      this.space_filter_value = this.space_name3;
    }
    this.spaceService.getPortfolioList(this.space_filter_value, this.lifeStage_filter_value, this.theme_filter_value, this.final_price, this.current_page, this.per_page, this.serachedspace).subscribe(data => {
  
      if (this.space_filter_value === undefined) {
        this.space_filter_value = ''
      }
      setTimeout(() => {
        this.loader=false;
      }, 2000);
      this.list = data;
       this.total_page = this.list.total_records;
      this.current_page = curr_page;
       this.per_page = '12';
    })
    
      window.scrollTo(0, 0);

  }
  

  transform(value: string): string {
    return value.replace(/_/g, ' ');
  }


getThisDesignSpaces(data:any) {
  const modal = this.modalService.open(SpacesGetThisDesignFormComponent, { size: 'md' });
  modal.componentInstance.data = data;
  modal.componentInstance.num = 1;
    modal.result.then((res) => {
    });
}
  
getStory(message: string) {
  this.receivedChildMessage = message;
}
  
  pricerange: any;
getpricerange() {
  this.pricerange = [{
    name: 'Under ₹ 1 Lakh'},
  { name: '₹ 1 Lakh to ₹ 2 Lakh' },
    { name: '₹ 2 Lakh to ₹ 3 Lakh' },
    { name: '₹ 3 Lakh to ₹ 4 Lakh' },
  {name: 'Over ₹ 4 Lakh'}
  ]
}
  
}
