<app-header></app-header>
<div class="container-fluid profile">
  <div class="mob-none row justify-content-center">
    <div class="col-md-10 col-12 breadcrumb-style">
     <div class="row"> 
       <div class="col-md-6">
   <a href="/" class="text-decoration-none"><span class="home-sec">HOME >>  </span></a> 
     <a><span class="space-sec">GET YOUR ESTIMATE</span></a>
      </div>
      </div>
      </div>
    </div>  
  </div>
<div class="container-fluid profile-2">
  <div class="row">
    <div class="col-12 mt50px">
      <div class="d-flex justify-content-center">
        <div class="titleTriangle"
      >CUSTOM PACKAGES
  </div>
      <span class="arrow-down"></span>
    <img
      src="../assets/images/Fill-117.svg"
      class="smallLogoIcon zI1"
      alt="vwuy"
    />
      </div>
    </div>
  </div> 
  <div class="row justify-content-center">
    <div class="col-md-4 col-12">
      <div class="row">
        <div class="col-md-12">
        <p class="row-text-style"
      >(To get a more clear estimation price closer, select options from custom package or book consultation)
      </p>
      </div>
      </div>
  </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-10 col-12">
      <div class="row justify-content-end">
        <div class="col-md-8">
        <!-- <ng-container *ngFor="let apartmentdata of customPackagedetail"> -->
          <span *ngIf="customPackagedetail && customPackagedetail.apartment"><span style="color: #8d0528;">Apartment Name</span>:{{customPackagedetail?.apartment}},  </span>
          <span *ngIf="customPackagedetail && customPackagedetail.accomodation_type"><span style="color: #8d0528;">Accomodation Type</span>: {{customPackagedetail?.accomodation_type}}</span>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-md-8">
          <span *ngIf="customPackagedetail && customPackagedetail.area"><span style="color: #8d0528;">Area</span>: {{customPackagedetail?.area}} Sq. Ft., </span>
          <span *ngIf="customPackagedetail && customPackagedetail.lifestage"><span style="color: #8d0528;">Lifestage</span>: {{customPackagedetail?.lifestage | spacePipe}}, </span>
          <span *ngIf="customPackagedetail && customPackagedetail.theme"><span style="color: #8d0528;">Theme</span>: {{customPackagedetail?.theme | titlecase}}</span>
          </div>
          </div>
          <!-- </ng-container>   -->
          
      </div>
  </div>
  <div class="row justify-content-center mt-3">
  <div class="col-md-8 col-12">  
    <div class="row justify-content-around">
  <div class="card" style="width: 28rem;" *ngFor="let item of numberofPackages">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-8 mt-2 mb-2" style="font-weight: bold;">
      {{item | spacePipe}}
    <div>Your Dream Home Made By You</div> 
  </div>
    <div style="text-align: end; font-weight: bold;" class="col-md-4 mt-3" >
       <span style="color: #8d0528;">{{getRupeeConverter(packages[item].price)}}</span> 
      </div>
    
    </div>
    </div>
    </div>
    </div>
    <ul class="list-group list-group-flush">
    <ng-container *ngFor="let data of packages[item].space ">
      <li class="list-group-item">
        <div class="row">
          <div class="col-md-12">
            <div class="row" >
              <div class="col-md-2">
                <img *ngIf="data.space ==='livingroom'" src="../../../assets/images/est-liv-room.png" />
                <img *ngIf="data.space ==='diningroom'" src="../../../assets/images/dining-room-estimate.png" />
                <img *ngIf="data.space ==='bedroom'" src="../../../assets/images/master-bedroom-estimate.png" />
                <img *ngIf="data.space ==='studyroom'" src="../../../assets/images/master-bedroom-estimate.png" />
                <img *ngIf="data.space ==='kitchen'" src="../../../assets/images/Kitchen.png" />
              </div>
              <div class="col-md-6">
        <span style="color: #999999;">{{data.name}}</span>
        </div>
        <div style="text-align: end;" class="col-md-4">
      <span style="color: #8d0528;">{{getRupeeConverter(data.price ) }}</span>
      </div>
      </div>
      </div>
      </div>
</li>
    </ng-container>

    <li class="list-group-item p-0" ><button id={{item}} class="btn btn-details  rounded-0 text-white w-100 p-3" 
  (click)="handleViewDetails(item); getdesign(item);" [disabled]="item === packages.selected_package">
  Get This Design</button></li>
  </ul>
  </div>
  </div>
  </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-6 col-12">  
      <div class="row justify-content-center">
      <div class="mt-3">
        <a class="lifestage-link" href="../../../assets/images/Brands&timeline.pdf" target="_blank">Check Delivery Timeline and Material</a>
        </div>
        </div>
        </div>
        </div>
  </div>
  <app-preset-packages></app-preset-packages>
<app-aboutusone></app-aboutusone>
<app-aboutustwo></app-aboutustwo>
<app-footer></app-footer>
<div class="loading d-flex justify-content-center" *ngIf="loader">
  <div class="spinner-border" style="color: #8d0528;" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
