
<div class="stories-root">
     <div id="stories-updatedd" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner stories-updated">
            <ol class="carousel-indicators" id="stopvideo">
                <li data-target="#stories-updatedd" data-slide-to="0" class="active" (click)="checkVideo()"></li>
                <li data-target="#stories-updatedd" data-slide-to="1" (click)="checkVideo()"></li>
                <li data-target="#stories-updatedd" data-slide-to="2" (click)="checkVideo()"></li>
                <li data-target="#stories-updatedd" data-slide-to="3" (click)="checkVideo()"></li>
            </ol> 

            <div class="carousel-item active">
                <div class="row">
                    <div class="col-lg-7">
                      <div style="position: relative;">
                        <!-- <img src="../../../assets/images/your-stories-thumbnail/post-one.png" class="w-100 img-fluid" style=" width: 610px;
                        height: 404px;
                        object-fit: fill;"> -->
                        <iframe class="video-player" src="https://www.youtube.com/embed/xmqEsT2fXIw" class="w-100 img-fluid"  frameborder="0"></iframe>
                      </div>
                    <!-- <div style="position: absolute; top: 45%; left: 45%;">
                        <img src="../../../assets/images/carosel-icons/Group 166.svg" class="img-fluid" style="cursor: pointer;">
                    </div> -->
                    </div>
                    <div class="col-lg-5" style="position: relative;">
                        <div class="row flex-column justify-content-center mobile-space">
                            <div class="col-12 d-lg-block d-none">
                                <p class="made-by-you">Made-By-You Stories</p>
                            </div>
                            <div class="col-12">
                                <p class="user-name mb-0">Naveen Mohan</p>
                                <p class="user-city">Trivandrum, Kerala</p>
                            </div>
                            <div class="col-12">
                                <p class="user-content">We go 100% design support from the team right from the wardrobes to the shelves.
                                    I was facing an issue on the day of the house warming. 
                                    So I called up the Project Manager at 12.30am. 
                                    He came early morning, resolved the issue before the house warming and I was overwhelmed with this gesture.
                                </p>
                            </div>
                            <div class="col-lg-5 px-0  stories-all">
                                <a href="https://arrivae.com/made_by_you_stories" target="_blank" class="all-stories story-padd">View All Stories</a>
                            </div>
                        </div>
                    </div>   
                </div>
            </div>
            <div class="carousel-item">
            <div class="row">
                <div class="col-lg-7">
                  <div>
                    <iframe class="video-player" src="https://www.youtube.com/embed/xi8vumcyf_I" class="w-100 img-fluid"  frameborder="0"></iframe>
                  </div>
              
                </div>
                <div class="col-lg-5">

                    <div class="row flex-column justify-content-center mobile-space">
                        <div class="col-12 d-lg-block d-none">
                            <p class="made-by-you">Made-By-You Stories</p>
                        </div>
                        <div class="col-12">
                            <p class="user-name mb-0">Keval Desai</p>
                            <p class="user-city">Mumbai, Maharashtra</p>
                        </div>
                        <div class="col-12">
                            <p class="user-content"> While interacting we understood that we have a good connect with the designers as they understood our requirements and taste very very clearly.
                                The design process started with by sharing the ideas and reference pictures we had with the designer.
                                Based on it, the designer suggested contemporary design.The entire process was smooth as we were aware of every step coming one after the other.
                            </p>
                        </div>
                        <div class="col-lg-5 px-0  stories-all">
                            <a href="https://arrivae.com/made_by_you_stories" target="_blank" class="all-stories story-padd">View All Stories</a>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div class="carousel-item">
                <div class="row">
                    <div class="col-lg-7">
                      <div>
                        <iframe class="video-player" src="https://www.youtube.com/embed/ARARrCEzQ50" class="w-100 img-fluid" frameborder="0"></iframe>
                      </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="row flex-column justify-content-center mobile-space">
                            <div class="col-12 d-lg-block d-none">
                                <p class="made-by-you">Made-By-You Stories</p>
                            </div>
                            <div class="col-12">
                                <p class="user-name mb-0">Sanjesh & Pooja Thakur</p>
                                <p class="user-city">Gurgram, Haryana</p>
                            </div>
                            <div class="col-12">
                                <p class="user-content"> So this was a Spanish architecture villa, we were looking for a theme which was Victorian. We finalized Arrivae for two main reasons:
                                    1: Their level of commitment was very very high as compared to the others. 
                                    2: The sense that we got after meeting 3-4 companies was that Arrivae was not in a hurry to close the deal. They spent time with us to understand our requirements. It was a very insightful program, we got a lot of insights from them on design aesthetics, Victorian designs.   
                                </p>
                            </div>
                            <div class="col-lg-5 px-0  stories-all">
                                <a href="https://arrivae.com/made_by_you_stories" target="_blank" class="all-stories story-padd">View All Stories</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="carousel-item">
                <div class="row">
                    <div class="col-lg-7">
                      <div>
                        <iframe class="video-player" src="https://www.youtube.com/embed/Y_WhST9wzqw" class="w-100 img-fluid" frameborder="0"></iframe>
                      </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="row flex-column justify-content-center mobile-space">
                            <div class="col-12 d-lg-block d-none">
                                <p class="made-by-you">Made-By-You Stories</p>
                            </div>
                            <div class="col-12">
                                <p class="user-name mb-0">Vishal Joshi</p>
                                <p class="user-city">Mumbai, Maharashtra</p>
                            </div>
                            <div class="col-12">
                                <p class="user-content">
                                    We had our budget in our head. And that is the reason it becomes even more challenging for the designer. If you have unlimited money you can beautify anything, but when there is a budget constraint it really requires a lot of creativity to make sure the house looks beautiful. That is where Arrivae designers played an important role to make sure our imagination gets converted into reality.  
                                </p>
                            </div>
                            <div class="col-lg-5 px-0  stories-all">
                                <a href="https://arrivae.com/made_by_you_stories" target="_blank" class="all-stories story-padd">View All Stories</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    </div> 
</div>



