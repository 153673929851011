<div class="updated-blogs mx-5 mt-4 mt-md-0">
    <div class="row mobile-padding  ">
        <div class="main-heading col-12 mb-2  ">
            <p class="mb-1"><span>TRENDING BLOGS</span></p> 
            <span>Design tips and tricks from our experts to inspire you</span>  
        </div>
        <div *ngIf="roletoSet() > 650" class="button col-12 d-flex  mx-md-3 p-0 ">
           <a href="https://blog.arrivae.com/" target="_blank">

            <button type="button "  class=" d-flex align-items-center ">GET INSPIRED&nbsp;
                <span>
                   <img src="../../../assets/images/Arrow 1.png"/>
                </span>
            </button>
           </a>
        </div>

       
            
        <div  class="blogs col-12  mt-md-4" >    
            <div class="row row-blog m-4 m-md-5  align-items-center ">
                <div class="col-1 ">
                    <div class=" float-right">
                       <div class="other-blogs"  >
                         <button   class="mx-1 button-one" (click)="slickController.prev()">
                           <img class="cursorPointer"  src="../../assets/images/sign-up-to-service/Arrow copy 2 2.png" alt="previous" >
                         </button>
                       </div>
                   </div>   
                </div>

                <div class="col-10">
                    <div class="row">
                        <div *ngIf="!blogs"  class="mobile-carosel-active-blogs  align-items-center d-flex w-100">
                            <img  src="../../../assets/images/arrivaeHomePageImages/blogsLoader.gif" class=" w-100 ">

                        </div>
                    <div  class="mobile-carosel-active-blogs w-100">
                        <div slickContainer #slickController="slick" [slickConfig]="config">
                            <ng-container *ngIf="slickController.initialize">
                                <ng-container *ngFor="let blog of blogs ; let i = index ">
                                    <div slickItem class="px-1">
                                        <div class="card h-100 "> 
                                            <a [href]="blog.link" target="_blank" > 
                                                <img  class="blog-image" [src]="[blog._embedded['wp:featuredmedia'][0].source_url]" alt="">
                                            </a>  
                                            <div class="details px-2 text-align-start">
                                            <!-- <div class="col-12" *ngIf ="i ==0">
                                               <div class="mt-3">
                                                    <a class="home-btn px-3 py-1">Home</a>
                                               </div>
                                            </div>
                                            <div class="col-12" *ngIf = "i ==1">
                                                <div class="mt-3">
                                                     <a class="home-btn px-3 py-1">HOME</a>
                                                     <a class="home-btn ml-3  px-3 py-1">KITCHEN</a>
                                                </div>
                                             </div>
                                             <div class="col-12" *ngIf = "i ==2">
                                                <div class="mt-3">
                                                     <a class="home-btn px-3 py-1">HOME</a>
                                                     <a class="home-btn ml-3  px-3 py-1">TRENDING</a>
                                                </div>
                                             </div>-->
                                            <div class="col-lg-12 col-12 my-2 p-0 blog-title">
                                               <a [href]="blog.link" target="_blank" class="text-dark-cus"> <p class="m-0 text-dark">{{blog.title.rendered }} </p></a>
                                            </div>
                                            <!--<div class="col-lg-5 col-8 d-flex justify-content-between blog-date mb-3">
                                                <p class="mb-0">{{blog.yoast_head_json.description}}</p>
                                                <span class="align-self-center circle"></span>
                                                <span>Arrivae</span>
                                            </div> -->
                                            <div class=" blog-description mb-3">
                                                <a [href]="blog.link" target="_blank" class="text-dark-cus">

                                                    <p *ngIf="blog.yoast_head_json.description" class="mb-0 text-dark-cus">{{blog.yoast_head_json.description  }}</p>

                                                </a>
                                               
                                            </div>    
                                            <!-- <div class="col-12 mt-3 mb-lg-5 mb-3">
                                                <a class="reading" [href]="blog.link" target="_blank">Continue Reading</a>
                                            </div> -->
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="col-1 ">
                    <div class="">
                       <div class="other-blogs"  >
                         <button   class="mx-1 button-one" (click)="slickController.next()">
                           <img class="cursorPointer"  src="../../assets/images/sign-up-to-service/Arrow copy 2 1.png" alt="previous" >
                         </button>
                       </div>
                    </div>   
                </div>

            </div>
        </div>
        <div *ngIf="roletoSet() < 650"  class="col-12 d-flex justify-content-center button mt-2">
            <a href="https://blog.arrivae.com/" target="_blank">

                <button type="button "  class=" d-flex align-items-center ">GET INSPIRED&nbsp;
                    <span>
                       <img src="../../../assets/images/Arrow 1.png"/>
                    </span>
                </button>
               </a>

        </div>
        
    </div>
</div>

<!-- design-with-us and build with us blog -->
<!-- <div class="row flex-lg-row-reverse flex-row mx-0 mt-lg-0  design-build-blog">
    <div class="col-lg-4 root-col-for-design-blog p-0">
        <div class="firstt-row">
            <div (mouseover)='first()' class="hover-onbutton" [ngClass]="{'my-class1': step=='step2'}" 
            class="root-with">
                <div class="design">
                    <p class="heading">Design With Us</p>
                    <p class="join">Become A Design Partner | Get 360⁰ Support from Arrivae For Your Projects​</p>
                </div>
                <a  class="partner" href="https://arrivae.com/become_a_design_partner" target="_blank">Apply Now</a>
            </div>
            <div (mouseover)='over()'  class="hover-onbutton" [ngClass]="{'my-class1': step=='step1'}" 
            class="root-build">
                <div class="build-us">
                    <p class="heading">Build With Us</p>
                    <p class="vendor">Register as a Vendor Partner | Scale Your Business | Earnings Made Easy​</p>
                </div>
                <a class="partner" href="https://arrivae.com/become_a_manufacturer" target="_blank">Apply Now</a>
            </div>
        </div>
    </div>
    <div class="col-lg-8 col-12 px-lg-0  px-0 design-image">
        <div class="mr-lg-5 app-height">
            <img class="w-100" src="{{imageSrc}}">
        </div>
    </div>
</div> -->

<!-- clients and partners -->

<!-- <div class="mb-5">
    <div class="partners">
        <p class="py-4 text-center">Our Partners</p>
    </div>
    <div class="text-center container-mobile">
        <img src="../../../assets/images/partners-icons/indiabulls.png" class="mx-lg-5 mx-4">
        <img src="../../../assets/images/partners-icons/shriram.png" class="mx-lg-5 mx-4">
        <img src="../../../assets/images/partners-icons/prism.png" class="mx-lg-5 mx-4">
        <img src="../../../assets/images/partners-icons/woodcompany.png" class="mx-lg-5 mx-4">
    </div>
</div> -->
