import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LandingService {
  private boardURL = environment.apiBaseUrl;
  private deltaURL = environment.deltaBaseUrl;
  posts: string = '';
  constructor(public http: HttpClient) {}

  public carouselImages() {
    return this.http.get(
      `${this.boardURL}/v1/carousels?type_of_carousel=landing&page&limit&location`
    );
  }
  public getCities() {    
    return this.http.get(`${this.boardURL}/v1/cities`);
  }
   
  public promoter(formdata: any) {
    console.log(formdata)
    let url=`${this.deltaURL}/v1/promoters`
    console.log(url)
    return this.http.post(url,formdata);  
  }

  public getpoints(city_id: any) {
    return this.http.get(
      `${this.boardURL}/v1/experience_centers?` + 'city_id=' + city_id
    );
  }

  public latestDesignSpaces() {
    return this.http.get(`${this.boardURL}/v1/design_spaces`);
  }
  public GetcustomerDetails() {
    return this.http.get(
      'https://newuatapi.arrivae.com' + '/v1/users/customer_dashboard_timeline'
    );
  }
  Magiclogin(token: any) {
    let url =
      'https://newuatapi.arrivae.com' +
      '/v1/users/customer_magic_login?token=' +
      token;

    return this.http.post(url, []);
  }
  public optGeneration(number: any) {
    return this.http.post(
      `${this.deltaURL}/v1/lead_invitations/lead_send_otp/?phone_number=` +
        number,
      []
    );
  }

  public optVerification(otp: any, leadInvitationId: any) {
    return this.http.post(
      `${this.deltaURL}/v1/lead_invitations/verify_otp/?lead_otp=` +
        otp +
        '&lead_invatation_id=' +
        leadInvitationId,
      []
    );
  }

  public infiniteDesigncontent() {
    return this.http.get(`${this.boardURL}/v1/designers?featured=true`);
  }

  // https://qa-marketing-api.arrivae.com
  public submitForm(formdata: any) {
    // https://qa-marketing-api.arrivae.com/v1/leads/
    return this.http.post(`${this.boardURL}/v1/leads`, formdata);
  }

  public submitPincodecity(formdata: any) {
    return this.http.post(
      `${this.boardURL}/v1/leads/update_lead_status2`,
      formdata
    );
  }

  public loadPosts() {
    let url = 'https://blog.arrivae.com/wp-json/wp/v2/posts?per_page=6&_embed';
    return this.http.get(url);
  }


  createreferal(email: any){
    let url = ' https://ref-api.arrivae.com/api/v1/create_user_referral'
    return this.http.post(url,{"email_id" : email});

  }
  GetReview(id:any){
   
    return this.http.get(`${this.boardURL}/v1/customer_reviews/${id}`);

  }
  CreateLead(data:any){
    let url = ' https://ref-api.arrivae.com/api/v1/create_lead'
    return this.http.post(url,data);

  }

  createdesignerlead(formdata: any){
    console.log(formdata)
    let url=`${this.deltaURL}/v1/leads/`
    console.log(url)
    return this.http.post(url,formdata);  

  }

  getreviews(city: any,apartment: any){
    city = city == undefined?'':city
    apartment = apartment == undefined?'':apartment

    console.log()
    let url =`${this.boardURL}/v1/customer_reviews?city=${city}&apartment=${apartment}`
    return this.http.get(url);
  }
}
