import { Component, OnInit, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { DomSanitizer } from "@angular/platform-browser";

declare var $: any;
@Component({
  selector: 'app-our-stores',
  templateUrl: './our-stores.component.html',
  styleUrls: ['./our-stores.component.scss']
})
export class OurStoresComponent implements OnInit {
  showVideo: boolean | undefined;

  constructor( private _DomSanitizationService: DomSanitizer,
    private renderer: Renderer2,) { }

    playerVars = {
      cc_lang_pref: 'en',
    };

  ngOnInit(): void {

  }

  id1='vOSeT07sixE';
  id2='hbevvQ6FQVw'

  url: any =''


  current_url :any = "https://www.youtube.com/embed/vOSeT07sixE"
  transform(url: any) {

    return this._DomSanitizationService.bypassSecurityTrustResourceUrl(url);

  }

  videoPlayIn(url :any){
    console.log('clicking');
    this.allVideos = true
    this.current_url=this._DomSanitizationService.bypassSecurityTrustResourceUrl(url)
  }

  allVideos :any = false
 

modaltitle:any
  openModal(url: any) {
    $('#myModal').modal('show');
    this.url=url
    if(this.url=='https://www.youtube.com/embed/vOSeT07sixE?autoplay=1&loop=1'){
      this.modaltitle="VR Mall, Bengaluru"
    }
    else{
      this.modaltitle="Trillium Avenue, Gurugram"
    }
    this.videoPlayIn(url)

  }

  closeModal() {
    this.allVideos=false
    $('#myModal').modal('hide');
  }


  roletoSet() {
    var width = Math.max(window.screen.width, window.innerWidth)
    return width
  }

  openInNewTab() {
    window.open('/visit-us', '_blank');
  }
}


