import { Component, OnInit, Input, SecurityContext } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-play-video-model',
  templateUrl: './play-video-model.component.html',
  styleUrls: ['./play-video-model.component.scss']
})
export class PlayVideoModelComponent implements OnInit {

  @Input() public url:any;
  @Input() public modal: any;

  constructor() { }

  ngOnInit(): void {
  }

  closeModel() {
    this.modal.close()
  }

}

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url: any) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
} 
