<app-header></app-header>
<div class="container-fluid profile">
   
  </div>


    <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
 
      <ng-container *ngFor="let lifes of lifestages; let i =index">
      <li class="nav-item" (click)="lifeStageFilter(lifes)" >
        
        <a
          class="nav-link textColor text-capitalize"
          id="love-vibes"
          data-toggle="tab"
          href="#happy"
          role="tab"
          aria-controls="happy"
          aria-selected="false"
          [ngClass]="{'active':i===0}"
          style="text-align: -webkit-right"
          >
          <img *ngIf="lifeStage_filter_value.name ==='Young Vibes'" src="../../../assets/images/YOUNG VIBES.svg" />
          <img *ngIf="lifeStage_filter_value.name ==='Love Vibes'" src="../../../assets/images/Page 2.svg" />
          <img src="../../../assets/images/Page 3.svg" *ngIf="lifeStage_filter_value.name ==='Baby Vibes'"/>
          <img src="../../../assets/images/YOUNG VIBES.svg" *ngIf="lifeStage_filter_value.name ==='Lively Vibes'"/>
          <img src="../../../assets/images/Page 2.svg" *ngIf="lifeStage_filter_value.name ==='Happy Vibes'"/>
          {{lifes.name}}</a
        >
      </li>
     
      </ng-container>
      
    </ul>
    <div class="container-fluid">
    <div *ngIf="lifeStage_filter_value.name ==='Young Vibes'">
      <div
        class="tab-pane fade show active"
        id="young"
        role="tabpanel"
        aria-labelledby="young-tab"
      >
      <div class="row imgTexts justify-content-center">
        <div class="col-md-10">
          <div class="row">
        <div class="col-md-5 col-sm-6">
          <img
            src="../../../assets/images/Lifestage- Young Vibes.svg"
            class="img590"
          />
        </div>
        <div class="col-md-7 col-sm-6 textpara">
          <!-- <div style="font-weight: bold;">Ideal for: Young couple or a newly married pair.</div> -->
<div>
          When we say the word young, what is the first thing that comes to your mind? You may relate this term to someone who’s a junior and in their adolescence. But for us at Arrivae, the word young holds a deeper meaning than just an age span. We truly believe that a person’s age has got nothing to do when it comes to being young. For us, a young person is someone full of life & cheerful no matter at what stage of live they are on. They radiate positivity and fresh outlook towards life. We at Arrivae love to capture the essence of such youthful individuals and channel their vibes while designing their homes. 
        </div>
        </div>
      </div>
      </div>
    </div>
    </div>
  </div>
    </div>
<div class="container-fluid">
    <div *ngIf="lifeStage_filter_value.name==='Love Vibes'">
      <div
        class="tab-pane fade show"
        id="young"
        role="tabpanel"
        aria-labelledby="young-tab"
      >
      <div class="row imgTexts justify-content-center">
        <div class="col-md-10">
          <div class="row">
        <div class="col-md-5 col-sm-6">
          <img
            src="../../../assets/images/Lifestage- Love Vibes 1LL.svg"
            class="img590"
          />
        </div>
        <div class="col-md-7 col-sm-6 textpara">
          <div style="font-weight: bold;">Ideal for: Young couple or a newly married pair.</div>
        
<div>
Starting a new phase of life and moving in together can be exciting & challenging. Your everyday life and schedules collide, and you'll find you need to get used to sharing a lot more with your partner - including your home. While it’s a fresh beginning for both of you, there can be certain challenges involved when it comes to tastes and preferences and a certain way of living for you both as individuals. This can reflect when you do up your home décor. It becomes important to find a common ground, and design your home in such a way that your respective tastes actually complement each other. We at Arrivae understand this precisely and help you to design your home that captures both your personal styles and personalities and also reflect the warmth and bond you share.
</div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
</div>


<div class="container-fluid">
    <div *ngIf="lifeStage_filter_value.name==='Baby Vibes'">
      <div
        class="tab-pane fade show"
        id="young"
        role="tabpanel"
        aria-labelledby="young-tab"
      >
      <div class="row imgTexts justify-content-center">
        <div class="col-md-10">
          <div class="row">
        <div class="col-md-5 col-sm-6">
          <img
            src="../../../assets/images/Baby VIBES 01.svg"
            class="img590"
          />
        </div>
        <div class="col-md-7 col-sm-6 textpara">
          <!-- <div style="font-weight: bold;">Ideal for: Young couple or a newly married pair.</div> -->
<div>
          Arrival of a new bundle of joy in the family is exciting & overwhelming. As new parents, it is a natural instinct to provide your new-born with all the finest things life has to offer. What’s better than welcoming them into your world with positive vibes, warmth, love, and a nurturing environment?
        </div>
        </div>
      </div>
      </div>
      </div>
    </div>
    </div>
  </div>
  <div class="container-fluid">
    <div *ngIf="lifeStage_filter_value.name==='Happy Vibes'">
      <div
        class="tab-pane fade show"
        id="young"
        role="tabpanel"
        aria-labelledby="young-tab"
      >
      <div class="row imgTexts justify-content-center">
        <div class="col-md-10">
          <div class="row">
        <div class="col-md-5 col-sm-6">
          <img
            src="../../../assets/images/Lifestage- Happy Vibes.svg"
            class="img590"
          />
        </div>
        <div class="col-md-7 col-sm-6 textpara">
          <!-- <div style="font-weight: bold;">Ideal for: Young couple or a newly married pair.</div> -->
<div>
  ‘The more the merrier’ is always the mantra in traditional Indian families & so the concept of a joint household is still very much in vogue. Such homes have a different level of vibrancy. They have an aura of happiness around them. Right from love, responsibilities, and food, everything is shared & multiplied including happiness. In such homes every taste, every preference differs. What remains constant is the vibe of happiness they emit. We at Arrivae try to bring out the best for such larger than life happy families by helping them design their homes according to their choice and preferences.
        </div>
        </div>
        </div>
      </div>
      </div>
    </div>
    </div>
  </div>
<div class="container-fluid">
    <div *ngIf="lifeStage_filter_value.name==='Lively Vibes'">
      <div
        class="tab-pane fade show"
        id="young"
        role="tabpanel"
        aria-labelledby="young-tab"
      >
      <div class="row imgTexts justify-content-center">
        <div class="col-md-10">
          <div class="row">
        <div class="col-md-5 col-sm-6">
          <img
            src="../../../assets/images/Lifestage- Lively Vibes.svg"
            class="img590"
          />
        </div>
        <div class="col-md-7 col-sm-6 textpara">
          <!-- <div style="font-weight: bold;">Ideal for: Young couple or a newly married pair.</div> -->
<div>
        If you are a nuclear family with a young kid, then we are sure your house would always be energetic. Such homes emit a lively atmosphere and constant flow of enthusiasm. 
        </div>
        </div>
      </div>
      </div>
      </div>
    </div>
    </div>
    </div>

   
<ng-container>
   
    </ng-container>
    
     
     
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-md-10 col-12">
              <!-- <div class="row"> -->
            <ng-container *ngIf="lifeStage_filter_value.name ==='Young Vibes'">
              <div class="d-flex mt-4 centermob">
                <div class="titleTriangle1"
              >OUR WORK FOR YOUNG VIBES
          </div>
              <span class="arrow-down"></span>
            <img
              src="../assets/images/Fill-117.svg"
              class="smallLogoIcon zI1"
              alt="vwuy"
            />
              </div>
            </ng-container>

            <ng-container *ngIf="lifeStage_filter_value.name ==='Love Vibes'">
              <div class="d-flex mt-4 centermob">
                <div class="titleTriangle1"
              >OUR WORK FOR LOVE VIBES
          </div>
              <span class="arrow-down"></span>
            <img
              src="../assets/images/Fill-117.svg"
              class="smallLogoIcon zI1"
              alt="vwuy"
            />
              </div>
            </ng-container>
            <ng-container *ngIf="lifeStage_filter_value.name ==='Baby Vibes'">
              <div class="d-flex mt-4 centermob">
                <div class="titleTriangle1"
              >OUR WORK FOR BABY VIBES
          </div>
              <span class="arrow-down"></span>
            <img
              src="../assets/images/Fill-117.svg"
              class="smallLogoIcon zI1"
              alt="vwuy"
            />
              </div>
            </ng-container>
            <ng-container *ngIf="lifeStage_filter_value.name ==='Lively Vibes'">
              <div class="d-flex mt-4 centermob">
                <div class="titleTriangle1"
              >OUR WORK FOR LIVELY VIBES
          </div>
              <span class="arrow-down"></span>
            <img
              src="../assets/images/Fill-117.svg"
              class="smallLogoIcon zI1"
              alt="vwuy"
            />
              </div>
            </ng-container>
            <ng-container *ngIf="lifeStage_filter_value.name ==='Happy Vibes'">
              <div class="d-flex mt-4 centermob">
                <div class="titleTriangle1"
              >OUR WORK FOR HAPPY VIBES
          </div>
              <span class="arrow-down"></span>
            <img
              src="../assets/images/Fill-117.svg"
              class="smallLogoIcon zI1"
              alt="vwuy"
            />
              </div>
            </ng-container>

            <div
              class="d-flex p-1 w-100"
              style="justify-content: flex-end"
            >
              <ul class="nav nav-tabs secondSection" id="myTab" role="tablist" >
                <ng-container *ngFor="let space of abc; let i=index" >
                <li class="nav-item" (click)="spaceFilter(space)" >
                  <a
                    class="nav-link"
                    
                    id="contact-tab"
                    data-toggle="tab"
                    href="#contact"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                    [ngClass]="{'active':space_filter_value===space}"
                    >{{space}}</a
                  >
                </li>  
                </ng-container>
              </ul>

            </div>
            </div>
          </div>
        </div>
        <div class="d-none d-sm-none d-md-block">
        <div class="container-fluid">
          <div class="row justify-content-center"> 
          <div class="col-md-10 col-12"> 
          <div class="row">
                <ng-container *ngFor="let images of list?.data let i=index ">

                    <div class="col-md-3 col-sm-12 mt-3 w-100" *ngIf="i < 4">

                      <img (click)="btnClick(images.attributes.id)" [src]="images.attributes.attachment_file" alt="vwuy"
                      style="height: 15rem; width: 273px; cursor: pointer; border-radius: 1rem; object-fit: cover;"
                      onerror="src='../../../assets/images/default-apartment.png'"
                      />
                  </div>
                </ng-container>
                </div>
                </div>
                </div>
                <div class="row  mt-3 ml-5 mr-5" *ngIf="list && list?.data.length === 0">
                  <div class="col-md-12 not-found text-center" >
                    <p style="font-size: 16px;text-align: center;color: #8d0528">Oops No Data To Show</p>
                  </div>
                </div>
                </div>
          
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-10 col-12">
        <p (click)="viewAlllifestage()" class="viewAll justify-content-flex-end cursorPointer">View All</p>     
      </div>
    </div>
  </div>

</div>



      <!-- For Mobile Screen-->
      <!-- <div class="container-fluid">
      <div class="row justify-content-end d-block d-md-none d-flex" style="margin-top: 10px">
        <div class="col-12 d-flex" style="overflow-x: scroll">
          <ng-container *ngFor="let images of list?.data let i=index ">      
            <div class="col-md-3 col-sm-12 mt-3 w-100" *ngIf="i < 4">
              <img (click)="btnClick(images.attributes.id)" [src]="images.attributes.attachment_file" alt="vwuy" 
              style="height: 273px; width: 273px; cursor: pointer;"
              onerror="src='../../../assets/images/default-apartment.png'"
              />
          </div>
        </ng-container>
        </div>
        <div class="row">
          <div class="col-sm-12">
          <div class="container">
            <div class="row">
              <div class="col">
                <p (click)="viewAlllifestage()" class="viewAll justify-content-flex-end">View All</p>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      
      </div> -->
      <div class="container-fluid">
        <div class="row d-block d-md-none d-flex" style="margin-top: 10px">
          <div class="col-12 d-flex" style="overflow-x: scroll">
            <ng-container *ngFor="let images of list?.data let i=index ">
              <div class="col-md-3 col-sm-12 mt-3 w-100" *ngIf="i < 4">
                <img (click)="btnClick(images.attributes.id)" [src]="images.attributes.attachment_file" alt="vwuy" 
                style="height: 273px; width: 273px;"
                onerror="src='../../../assets/images/default-apartment.png'"
                />
              </div>
            </ng-container>
          </div>
                <div class="container">
                  <div class="row">
                    <div class="col-12">
                      <p (click)="viewAlllifestage()" class="viewAll justify-content-flex-end">View All</p>
                    </div>
                  </div>
                </div>
        </div>
      </div>

<div class="container-fluid">
  <div class="row mt-4 justify-content-center mb-5">
    <div class="rectangular4">
      <div class="row">
        <div class="col-3">
          <img src="../assets/images/consulation.png" class="consulation" />
        </div>
        <div class="col-8 free">
          <span>FREE CONSULTATION</span><br>
           <span>Easy Booking</span>
           <a (click)="bookConsultationLandingPage()"><div class="know">KNOW MORE</div></a>
        </div>
      </div>
    </div>
    <div class="rectangular5">
      <div class="row">
        <div class="col-3">
          <img src="../assets/images/houseprice.png" class="consulation" />
        </div>
        <div class="col-8 free">
          <span>EMI OPTION ON</span><br>
           <span>COMPLETE PROJECT</span>
           <a style="text-decoration: none;" href="/get-finance" target="_blank"><div class="know">KNOW MORE</div></a>
        </div>
      </div>
    </div>
  </div>
  </div>
  <app-aboutusone></app-aboutusone>
  <app-aboutustwo></app-aboutustwo>
  <app-footer></app-footer>