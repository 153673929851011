import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-new',
  templateUrl: './footer-new.component.html',
  styleUrls: ['./footer-new.component.scss']
})
export class FooterNewComponent implements OnInit {
  selectedCity:any
  constructor() { }

  ngOnInit(): void {
    
  }

  centers(city:any){
    this.selectedCity = city;
    localStorage.setItem('selectedCity',this.selectedCity);
  }
}
