import { Component, OnInit } from '@angular/core';
import {Routes, RouterModule , Router,ActivatedRoute, Params} from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApartmentService } from '../apartment/apartment.service';
import { LoaderService } from '../loader.service';
@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],


})
export class EmailVerificationComponent implements OnInit {

  constructor(  
      private route:ActivatedRoute,
      private apartmentservice:ApartmentService,
      private fb: FormBuilder,
    
    ) { }

  lead_id:any
  currentYear :any
  ExistingEmail:any;
  emailverified:any
  email = new FormControl('', Validators.compose([Validators.required, Validators.email, Validators.pattern('^[A-Za-z0-9\._%+-]+@[A-Za-z0-9\.-]+[.]+[A-Za-z]{2,}$')]));
    thankyou:any=false;
    Validation:any=false;
    expired:any=false;
  successalert: any=false;
  erroralert: any;
  successMessage: any;
  errorMessage: any;
  externalUrl = `https://web.arrivae.com/`;
  lead_email:any
  timerValue: number = 0;
  private timerInterval: any;
  loader:boolean = false;




  ngOnInit(): void {
    this.currentYear = new Date().getFullYear()
    this.route.queryParams.subscribe(params => {
      if(params['lead_id']){
        this.lead_id = params['lead_id'];
      }
      if(params['lead_email']){
        this.lead_email = params['lead_email'];
        console.log(this.lead_email)
      }

    });
    this.getexistingemail(this.lead_id)
  }

  ngOnDestroy(): void {
    this.stopTimer();
  }

  startTimer(): void {
    this.timerInterval = setInterval(() => {
      this.timerValue++;
      if (this.timerValue >= 5) {
        this.stopTimer();
      }
    }, 1000); // Update every second
  }

  stopTimer(): void {
    clearInterval(this.timerInterval);
  }

  getexistingemail(id:any){
    this.loader=true    
    this.apartmentservice.getLeadLogs(this.lead_id).subscribe(
      (res: any)=>{
        this.loader=false    
        this.ExistingEmail=res['email'];
     
        this.emailverified=res['email_verified']
        console.log(this.lead_email)
        console.log(this.ExistingEmail)
        console.log(this.ExistingEmail==this.lead_email)
        if(this.ExistingEmail!=this.lead_email){
          this.expired=true
          this.startTimer();
          setTimeout(()=> {
            window.open(this.externalUrl,'_self');  
          }, 5000);
        }
        else{
          if(this.emailverified==true){
            this.thankyou=true
            this.startTimer();
            setTimeout(()=> {
              window.open(this.externalUrl,'_self');  
            }, 5000);
          }
          else{
            this.Validation=true
          }
      }
     
      },
      (err: any)=>{
        this.loader=false    
        console.log("hello")
    });
  }


  verifyemail(flag:any){    
    this.apartmentservice.verifyemail(this.lead_id,flag).subscribe(
      (res:any)=>{
        this.successalert = true;
        this.successMessage = res.message;
        this.startTimer();
        setTimeout(()=> {
          this.successalert = false;
          window.open(this.externalUrl,'_self');  
        }, 5000);
      },
      err=>{
        this.erroralert = true;
        this.errorMessage = err.error.message;
      });
  }
 
}





