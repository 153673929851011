import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApartmentService } from 'src/app/apartment/apartment.service';
declare var $: any;
declare function packageSelected(param: any): any;
@Component({
  selector: 'app-get-estimate-package',
  templateUrl: './get-estimate-package.component.html',
  styleUrls: ['./get-estimate-package.component.scss'],
})
export class GetEstimatePackageComponent implements OnInit {
  uid: any;
  access_token: any;
  client: any;
  packages: any;
  packages2: any;
  numberofPackages: any;
  loader: boolean = false;
  disabling: boolean = false;
  packageName: any;

  constructor(
    private apartmentService: ApartmentService,
    public http: HttpClient,
    private toastr: ToastrService,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.packageoffered();
    this.customPackage();
    var a = document.getElementById('sign-in-style');
    a?.classList.add('sign-in-style');
    this.getPackageName();
    this.handleViewDetails('');
    this.loadScript()
  }

  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  packageoffered() {
    this.uid = localStorage.getItem('UID');
    this.access_token = localStorage.getItem('access-token');
    this.client = localStorage.getItem('client')
      ? localStorage.getItem('client')
      : '';
    if (
      this.uid === null ||
      this.access_token === null ||
      this.client === null
    ) {
      this.router.navigateByUrl('/get-your-estimate');
    } else {
      this.apartmentService
        .customPackage(this.uid, this.client, this.access_token)
        .subscribe((user) => {
          this.packages = user;
          this.selectedPackagecall(this.packages.selected_package);
          this.numberofPackages = Object.keys(this.packages);
          this.numberofPackages.shift();
        });
    }
  }

  getPackageName() {
    this.packageName = [
      {
        name: 'Basic',
      },
      { name: 'Plus' },
    ];
  }
  getReplcaeChar(str: any) {
    return str.replace(/_/g, ' ');
  }
  getRupeeConverter(rupees: number) {
    let val = Math.abs(rupees);
    let tranformVal;
    if (val >= 10000000) {
      tranformVal = (val / 10000000).toFixed(2) + ' Cr';
    } else if (val >= 100000) {
      tranformVal = (val / 100000).toFixed(2) + ' Lacs';
    } else if (val >= 1000) {
      tranformVal = (val / 1000).toFixed(2) + ' K';
    } else {
      tranformVal = val;
    }
    return tranformVal;
  }

  selectedPackagecall(space: any) {
    if (space !== null) {
      if (space === 'luxury_interiors') {
        $('#essential_interiors').html('Get This Design');
        $('#luxury_interiors').html(
          '<i class="fa fa-check" aria-hidden="true"></i> Selected Package'
        );
      } else {
        $('#luxury_interiors').html('Get This Design');
        $('#essential_interiors').html(
          '<i class="fa fa-check" aria-hidden="true"></i> Selected Package'
        );
      }
    }
  }

  message: any;
  handleViewDetails(space: any) {
    this.loader = true;
    this.selectedPackagecall(space);
    this.apartmentService
      .submitViewDetail(
        this.uid,
        this.client,
        this.access_token,
        localStorage.getItem('lead_id'),
        space
      )
      .subscribe((user) => {
        this.message = user;
        this.loader = false;

        if (this.message.message === 'Custom Package Updated') {
          this.toastr.success(
            'Thanks for sharing your preferences. Our designers will get in touch with you in 24 hours'
          );
        }
        this.packageoffered();
      });
  }

  getdesign(param:any){
    packageSelected(param);
  }

  customPackagedetail: any;
  customPackage() {
    this.uid = localStorage.getItem('UID');
    this.access_token = localStorage.getItem('access-token');
    this.client = localStorage.getItem('client')
      ? localStorage.getItem('client')
      : '';
    this.apartmentService
      .customPackageDetails(this.uid, this.client, this.access_token)
      .subscribe((data) => {
        this.customPackagedetail = data;
      });
  }
}
