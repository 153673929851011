<!-- 
<div class="rk-ui-notification err" *ngIf="erroralert" >
  <div class="alert alert-danger" role="alert">
    This Boq is Cancelled
  </div>
</div> -->
<div  [ngClass] = "{'d-none' : !erroralert , 'd-block' : erroralert }" >
  <div class="position-sticky  navbar-start">
      <nav id="navbar" class="navbar navbar-expand-lg navbar-light bg-light px-lg-5 px-3">
          <a class="navbar-brand" href="">
              <img
              src="../assets/images/logocb.png"
              alt="image"
              class="Group-573"
            />
          </a>
          <button class="navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <img
            src="../../assets/images/carosel-icons/navBarToggle.png"
            class=""
          />
          </button>
          <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul class="navbar-nav">
              <li class="nav-item ">
                <a class="nav-link active cursorPointer">Dashboard <span class="sr-only">(current)</span></a>
              </li>
              <li class="nav-item">
                <a class="nav-link cursorPointer"  (click) = "DownloadFile()">Warranty Documents</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" target="_blank" href="https://web.arrivae.com/terms-and-conditions">Terms and Condition</a>
              </li>
              <li class="nav-item">
                <a class="nav-link " target="_blank"  href="https://arrivae.com/arrivae_stories">About Arrivae</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link cursorPointer" (click) = "logout()" >Logout</a>
              </li>
            </ul>
          </div>
      </nav>
  </div>
  
  
  <div class="summary-links pt-4 px-4 sticky-top">
      <a class="mx-lg-3  mx-4" (click) ="scrollToSummary('#summaryOfProject')" [ngClass] = "{'summary-active' : linksActive == 'summary'}" >Summary</a>
      <a class="mx-lg-3  mx-4" (click) = "scrollToCostBreak('#costBreak')"  [ngClass] = "{'summary-active' : linksActive == 'costBreak'}" >Cost Breakup</a>
      <a class="mx-lg-3  mx-4" (click) ="GoToPayment('#paymentSection')" *ngIf ="leadPayementDetails !== null"  [ngClass] = "{'summary-active' : linksActive == 'paymentDetail'}"  >Payment Details</a>
      <a class="mx-lg-3  mx-4" (click) ="nextSteps('#gdfgfdg')"  [ngClass] = "{'summary-active' : linksActive == 'nexxtSteps'}" >Next Steps</a>
      <a class="mx-lg-3  mx-4" (click) ="moreItems('#moreItemsToExplore')" [ngClass] = "{'summary-active' : linksActive == 'mooreItems'}">Explore Items</a>
      <ng-container *ngIf ="otherActiveBoqs.length > 0">
        <a class="mx-lg-3  mx-4" (click) ="moreBoqs('#numberofBoqs')" [ngClass] = "{'summary-active' : linksActive == 'otherActiveBoqs'}"  >Other Active BOQs</a>
      </ng-container>
      <a class="mx-lg-3  mx-4" (click) = "terms('#aggrementPoints')" [ngClass] = "{'summary-active' : linksActive == 'termsconditions'}" >Terms & Conditions</a>
  </div>
  

  <div id='summaryOfProject'>

   <div class="progress-timeline d-sm-none d-md-block d-none">
      <div class="px-lg-5 px-3 my-3 hello-name py-lg-4 py-0">
        <p  class="mb-0 customer-name">Hello <span style="color: #8D0528;">{{projectSummaryDetails?.lead_name}}</span></p>
        <p class="status-remaining-days d-none" [ngClass] ="{'d-block' : boq_status_data.data[0].boq_timeline_level === 'level_1' }" >
          You are just <span class="numbers">{{boq_status_data.data[0].remaining_days == 'NA' ? 'few' : boq_status_data.data[0].remaining_days}}</span> days away from your dream home! Your Project is in <span style="color: #8D0528;">{{boq_status_data.status}}</span> Stage.
        </p>
      </div>
  
      
      <div id="timeline-accordian" class="accordion1 horizontal  justify-content-center"  [ngClass] = "{'d-none' :  boq_status_data.data[0].boq_timeline_level === 'level_1' ,  'd-flex' : boq_status_data.data[0].boq_timeline_level !== 'level_1'  }"  >
      <ul>
          <li [ngClass]="{'d-none': !check_1, '': check_1}">
          <input type="radio" id="vert-1" name="vert-accordion" (click)="check1()"/>
          <label for="vert-1" style="position: relative;">
            <span class="mt-4" style="writing-mode: vertical-lr; transform: rotate(-180deg);">&nbsp;&nbsp; Order&nbsp;Booking</span>
          <span class="staic_number">1</span>
        </label>
            
          <span [ngClass]="{ 'arrow_right': checked1, '': !checked1}"></span>
          <div class="content m-3">
            <div id="applying_styles_steps">
           <ul class="nav nav-pills mt-4"  id="pills-tab-one" role="tablist">
             <li id="border-1" class="nav-item active_before" style="width: 79px;">
               <a
                  class="nav-link active mb-3"
                  id="pills-1-tab"
                  data-toggle="pill"
                  href="#pills-1"
                  role="tab"
                  aria-controls="pills-1"
                  aria-selected="true"
                  style="width: 40px; height: 41px;"
                  >
                  <span>
                    <!-- <i class="fa fa-check" aria-hidden="true"></i> -->
                    <img src="../../assets/images/Vector.png" style="margin-left: -8px;
                    margin-top: -1px;">
                  </span>
                  <!-- <ng-template #elseBlock>
                    <span>1</span>
                  </ng-template> -->
                  </a
                >
                <a  class="date_name_style text-nowrap">Estimate Shared</a>
                  <div class="date_name_style numbers" *ngIf="boq_status_data.level_1.shared_date !== null">({{boq_status_data.level_1.shared_date | date: "dd-MM-YYYY"}})</div>
                  <div *ngIf="boq_status_data.level_1.shared_date === null"></div>
                
             </li>
             <li id="border-2" class="nav-item" style="width: 79px;" [ngClass]="{
                'changes2': boq_status_data?.data[0]?.boq_status !== 'Shared'
              }">
                <a
                  class="nav-link mb-3"
                  [ngClass]="{
                active: boq_status_data?.data[0]?.boq_status !== 'Shared'
              }"
                  id="pills-2-tab"
                  data-toggle="pill"
                  href="#pills-2"
                  role="tab"
                  aria-controls="pills-2"
                  aria-selected="false"
                  style="width: 40px; height: 41px;"
                  >
                  <span *ngIf="boq_status_data?.data[0]?.boq_status !== 'Shared'; else elseBlock"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                    margin-top: -1px;"></span>
                  <ng-template #elseBlock>
                    <span>2</span>
                  </ng-template></a
                >
                <a class="date_name_style text-nowrap">Estimate Approved</a>
                <div class="date_name_style numbers" *ngIf="boq_status_data.level_1.approved_date !== null">({{boq_status_data.level_1.approved_date | date: "dd-MM-YYYY"}})</div>
                <div *ngIf="boq_status_data.level_1.approved_date === null"></div>
              </li>
              <li id="border-3" class="nav-item" style="width: 79px;" [ngClass]="{
                'changes2': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected')
              }">
                <a
                  class="nav-link mb-3"
                  id="pills-3-tab"
                  [ngClass]="{
                active: (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected')
              }"
                  data-toggle="pill"
                  href="#pills-3"
                  role="tab"
                  aria-controls="pills-3"
                  aria-selected="false"
                  style="width: 40px; height: 41px;"
                  ><span *ngIf="(boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected'); else elseBlock111"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                    margin-top: -1px;"></span>
                  <ng-template #elseBlock111>
                    <span>3</span>
                  </ng-template></a
                >
                <a class="date_name_style"><span class="numbers">10%</span> Paid</a>
                <div class="date_name_style numbers" *ngIf="boq_status_data.level_1['10_per_paid_date'] !== null">({{boq_status_data.level_1['10_per_paid_date'] | date: "dd-MM-YYYY"}})</div>
                <div *ngIf="boq_status_data.level_1['10_per_paid_date'] === null"></div>
              </li>
              
           </ul> 
           </div>  
          </div>
          </li>
          <li [ngClass]="{'d-none': !check_2, '': check_2}">
              <input type="radio" id="vert-2" name="vert-accordion" (click)="check2()"/>
              <label for="vert-2" style="position: relative;">
               <span class="mt-4" style="writing-mode: vertical-lr; transform: rotate(-180deg);">&nbsp;&nbsp; Order&nbsp;Confirmation</span>
               <span class="staic_number">2</span>
              </label>
              <span [ngClass]="{'arrow_right': checked2, '': !checked2}"></span>
              <div class="content2 m-3">
                <div id="applying_styles_steps2">
               <ul class="nav nav-pills mt-4"  id="pills-tab" role="tablist">
                 <li id="border-1" class="nav-item" style="width: 91px;" [ngClass]="{
                    'changes2': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid')
                  }">
                   <a
                      class="nav-link mb-3"
                      [ngClass]="{
                    'active': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid')
                  }"
                      id="pills-1-tab"
                      data-toggle="pill"
                      href="#pills-1"
                      role="tab"
                      aria-controls="pills-1"
                      aria-selected="true"
                      style="width: 40px; height: 41px;"
                      ><span *ngIf="(boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid'); else elseBlock10"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                        margin-top: -1px;"></span>
                      <ng-template #elseBlock10>
                        <span>1</span>
                      </ng-template></a
                    >
                    <a class="date_name_style">Site Measurement</a>
                    <div class="date_name_style numbers" *ngIf="boq_status_data.level_2['site_measurement_date'] !== null">({{boq_status_data.level_2['site_measurement_date'] | date: "dd-MM-YYYY"}})</div>
                    <div *ngIf="boq_status_data.level_2['site_measurement_date'] === null"></div>
                 </li>
                 <li id="border-2" class="nav-item" style="width: 91px;"
                 [ngClass]="{
                    'changes2': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement')
                  }">
                    <a
                      class="nav-link mb-3"
                      [ngClass]="{
                    'active': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement')
                  }"
                      id="pills-2-tab"
                      data-toggle="pill"
                      href="#pills-2"
                      role="tab"
                      aria-controls="pills-2"
                      aria-selected="false"
                      style="width: 40px; height: 41px;"
                      ><span *ngIf="(boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement'); else elseBlock2"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                        margin-top: -1px;"></span>
                      <ng-template #elseBlock2>
                        <span>2</span>
                      </ng-template></a
                    >
                    <a class="date_name_style">Material Selection</a>
                    <div class="date_name_style numbers" *ngIf="boq_status_data.level_2['material_selection_date'] !== null">({{boq_status_data.level_2['material_selection_date'] | date: "dd-MM-YYYY"}})</div>
                    <div *ngIf="boq_status_data.level_2['material_selection_date'] === null"></div>
                  </li>
                  <li id="border-3" class="nav-item" style="width: 91px;" [ngClass]="{
                    'changes2': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection')
                  }">
                    <a
                      class="nav-link mb-3"
                      [ngClass]="{
                    'active': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection')
                  }"
                      id="pills-3-tab"
                      data-toggle="pill"
                      href="#pills-3"
                      role="tab"
                      aria-controls="pills-3"
                      aria-selected="false"
                      style="width: 40px; height: 41px;"
                      ><span *ngIf="(boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection'); else elseBlock3"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                        margin-top: -1px;"></span>
                      <ng-template #elseBlock3>
                        <span>3</span>
                      </ng-template></a
                    >
                    <a class="date_name_style">Site Validation</a>
                    <div class="date_name_style numbers" *ngIf="boq_status_data.level_2['Site_validation_date'] !== null">({{boq_status_data.level_2['Site_validation_date'] | date: "dd-MM-YYYY"}})</div>
                    <div *ngIf="boq_status_data.level_2['Site_validation_date'] !== null"></div>
                  </li>
                  <li id="border-6" class="nav-item" style="width: 91px;" [ngClass]="{
                    'changes2': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation')
                  }">
                    <a
                      class="nav-link mb-3"
                      [ngClass]="{
                    'active': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation')
                  }"
                      id="pills-4-tab"
                      data-toggle="pill"
                      href="#pills-4"
                      role="tab"
                      aria-controls="pills-4"
                      aria-selected="false"
                      style="width: 40px; height: 41px;"
                      ><span *ngIf="(boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation'); else elseBlock5"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                        margin-top: -1px;"></span>
                      <ng-template #elseBlock5>
                        <span>4</span>
                      </ng-template></a
                    >
                    <a class="date_name_style">QC Approved</a>
                    <div class="date_name_style numbers" *ngIf="boq_status_data.level_2['qc_approval_date'] !== null">({{boq_status_data.level_2['qc_approval_date'] | date: "dd-MM-YYYY"}})</div>
                    <div *ngIf="boq_status_data.level_2['qc_approval_date'] === null"></div>
                  </li>
                  <li id="border-7" class="nav-item" style="width: 91px;" [ngClass]="{
                    'changes2': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval')
                  }">
                    <a
                      class="nav-link mb-3"
                      [ngClass]="{
                    'active': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval')
                  }"
                      id="pills-4-tab"
                      data-toggle="pill"
                      href="#pills-4"
                      role="tab"
                      aria-controls="pills-4"
                      aria-selected="false"
                      style="width: 40px; height: 41px;"
                      ><span *ngIf="(boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval'); else elseBlock6"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                        margin-top: -1px;"></span>
                      <ng-template #elseBlock6>
                        <span>5</span>
                      </ng-template></a
                    >
                    <a class="date_name_style">BOQ Shared</a>
                    <div class="date_name_style numbers" *ngIf="boq_status_data.level_2['final_boq_shared_date'] !== null">({{boq_status_data.level_2['final_boq_shared_date'] | date: "dd-MM-YYYY"}})</div>
                    <div *ngIf="boq_status_data.level_2['final_boq_shared_date'] === null"></div>
                  </li>
                  <li id="border-4" class="nav-item" style="width: 91px;" [ngClass]="{
                    'changes2': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared')
                  }">
                    <a
                      class="nav-link mb-3"
                      [ngClass]="{
                    'active': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared')
                  }"
                      id="pills-4-tab"
                      data-toggle="pill"
                      href="#pills-4"
                      role="tab"
                      aria-controls="pills-4"
                      aria-selected="false"
                      style="width: 40px; height: 41px;"
                      ><span *ngIf="(boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared'); else elseBlock7"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                        margin-top: -1px;"></span>
                      <ng-template #elseBlock7>
                        <span>6</span>
                      </ng-template></a
                    >
                    <a class="date_name_style">BOQ Approved</a>
                    <div class="date_name_style numbers" *ngIf="boq_status_data.level_2['final_boq_approved_date'] !== null">({{boq_status_data.level_2['final_boq_approved_date'] | date: "dd-MM-YYYY"}})</div>
                    <div *ngIf="boq_status_data.level_2['final_boq_approved_date'] === null"></div>
                  </li>
                  <li id="border-4" class="nav-item" style="width: 91px;" [ngClass]="{
                    'changes2': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') 
                  }">
                    <a
                      class="nav-link mb-3"
                      [ngClass]="{
                    'active': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') 
                  }"
                      id="pills-4-tab"
                      data-toggle="pill"
                      href="#pills-4"
                      role="tab"
                      aria-controls="pills-4"
                      aria-selected="false"
                      style="width: 40px; height: 41px;"
                      ><span *ngIf="(boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') ; else elseBlock8"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                        margin-top: -1px;"></span>
                      <ng-template #elseBlock8>
                        <span>7</span>
                      </ng-template></a
                    >
                    <a class="date_name_style">Sent to Production</a>
                    <div class="date_name_style numbers" *ngIf="boq_status_data.level_2['sent_to_production_date'] !== null">({{boq_status_data.level_2['sent_to_production_date'] | date: "dd-MM-YYYY"}})</div>
                    <div *ngIf="boq_status_data.level_2['sent_to_production_date'] === null"></div>
                  </li>
               </ul> 
               </div>  
              </div>
          </li>
          <li [ngClass]="{'d-none': !check_3, '': check_3}">
              <input type="radio" id="vert-3" name="vert-accordion" (click)="check3()"/>
              <label for="vert-3" style="position: relative;">
                <span class="mt-4" style="writing-mode: vertical-lr; transform: rotate(-180deg);">&nbsp;&nbsp; Order&nbsp;Execution</span>
               <span class="staic_number">3</span>
              </label>
              <span [ngClass]="{'arrow_right': checked3, '': !checked3}"></span>
              <div class="content3 m-3">
                <ng-container *ngIf="boq_status_data.quotation_type == 'non-service'">
                <div id="applying_styles_steps3">
               <ul class="nav nav-pills mt-4"  id="pills-tab" role="tablist">
                 
                 <li id="border-1" class="nav-item" style="width: 91px;" [ngClass]="{
                    'changes2': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') 
                  }">
                   <a
                      class="nav-link mb-3"
                      [ngClass]="{
                    'active': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') 
                  }"
                      id="pills-1-tab"
                      data-toggle="pill"
                      href="#pills-1"
                      role="tab"
                      aria-controls="pills-1"
                      aria-selected="true"
                      style="width: 40px; height: 41px;"
                      ><span *ngIf="(boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production'); else elseBlock"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                        margin-top: -1px;"></span>
                      <ng-template #elseBlock>
                        <span>1</span>
                      </ng-template></a
                    >
                    <a class="date_name_style" style="margin-left: -38px !important;">Raw Material Received</a>
                    <div class="date_name_style numbers" *ngIf="boq_status_data.level_3['raw_material_received_date'] !== null">({{boq_status_data.level_3['raw_material_received_date'] | date: "dd-MM-YYYY"}})</div>
                    <div *ngIf="boq_status_data.level_3['raw_material_received_date'] === null"></div>
                 </li>
                 <li id="border-2" class="nav-item" style="width: 91px;" [ngClass]="{
                    'changes2': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Raw Material Recieved') 
                  }">
                    <a
                      class="nav-link mb-3"
                      [ngClass]="{
                    'active': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Raw Material Recieved') 
                  }"
                      id="pills-2-tab"
                      data-toggle="pill"
                      href="#pills-2"
                      role="tab"
                      aria-controls="pills-2"
                      aria-selected="false"
                      style="width: 40px; height: 41px;"
                      ><span *ngIf="(boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Raw Material Recieved'); else elseBlock2"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                        margin-top: -1px;"></span>
                      <ng-template #elseBlock2>
                        <span>2</span>
                      </ng-template></a
                    >
                    <a class="date_name_style" style="margin-left: -38px !important;">Furniture Production Start</a>
                    <div class="date_name_style numbers" *ngIf="boq_status_data.level_3['furniture_production_start_date'] !== null">({{boq_status_data.level_3['furniture_production_start_date'] | date: "dd-MM-YYYY"}})</div>
                    <div *ngIf="boq_status_data.level_3['furniture_production_start_date'] === null"></div>
                  </li>
                  
                  <li id="border-4" class="nav-item" style="width: 91px;" [ngClass]="{
                    'changes2': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Raw Material Recieved') && (boq_status_data.data[0].boq_status !=='Furniture Production Start') && (boq_status_data.data[0].boq_status !=='Furniture Production complete') 
                  }">
                    <a
                      class="nav-link mb-3"
                      [ngClass]="{
                    'active': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Raw Material Recieved') && (boq_status_data.data[0].boq_status !=='Furniture Production Start') && (boq_status_data.data[0].boq_status !=='Furniture Production complete')  
                  }"
                      id="pills-4-tab"
                      data-toggle="pill"
                      href="#pills-4"
                      role="tab"
                      aria-controls="pills-4"
                      aria-selected="false"
                      style="width: 40px; height: 41px;"
                      ><span *ngIf="(boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Raw Material Recieved') && (boq_status_data.data[0].boq_status !=='Furniture Production Start') && (boq_status_data.data[0].boq_status !=='Furniture Production complete'); else elseBlock4"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                        margin-top: -1px;"></span>
                      <ng-template #elseBlock4>
                        <span>3</span>
                      </ng-template></a
                    >
                    <a class="date_name_style">Furniture Dispatch</a>
                    <div class="date_name_style numbers" *ngIf="boq_status_data.level_3['furniture_dispatch_date'] !== null">({{boq_status_data.level_3['furniture_dispatch_date'] | date: "dd-MM-YYYY"}})</div>
                    <div *ngIf="boq_status_data.level_3['furniture_dispatch_date'] === null"></div>
                  </li>
                  <li id="border-5" class="nav-item" style="width: 91px;" [ngClass]="{
                    'changes2': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Raw Material Recieved') && (boq_status_data.data[0].boq_status !=='Furniture Production Start') && (boq_status_data.data[0].boq_status !=='Furniture Production complete') && (boq_status_data.data[0].boq_status !=='Furniture Dispatch') 
                  }">
                    <a
                      class="nav-link mb-3"
                      [ngClass]="{
                    'active': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Raw Material Recieved') && (boq_status_data.data[0].boq_status !=='Furniture Production Start') && (boq_status_data.data[0].boq_status !=='Furniture Production complete') && (boq_status_data.data[0].boq_status !=='Furniture Dispatch')  
                  }"
                      id="pills-4-tab"
                      data-toggle="pill"
                      href="#pills-4"
                      role="tab"
                      aria-controls="pills-4"
                      aria-selected="false"
                      style="width: 40px; height: 41px;"
                      ><span *ngIf="(boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Raw Material Recieved') && (boq_status_data.data[0].boq_status !=='Furniture Production Start') && (boq_status_data.data[0].boq_status !=='Furniture Production complete') && (boq_status_data.data[0].boq_status !=='Furniture Dispatch'); else elseBlock5"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                        margin-top: -1px;"></span>
                      <ng-template #elseBlock5>
                        <span>4</span>
                      </ng-template></a
                    >
                    <a class="date_name_style">Installation</a>
                    <div class="date_name_style numbers" *ngIf="boq_status_data.level_3['installation_date'] !== null">({{boq_status_data.level_3['installation_date'] | date: "dd-MM-YYYY"}})</div>
                    <div *ngIf="boq_status_data.level_3['installation_date'] === null"></div>
                  </li>
                  <li id="border-6" class="nav-item" style="width: 91px;" [ngClass]="{
                    'changes2': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Raw Material Recieved') && (boq_status_data.data[0].boq_status !=='Furniture Production Start') && (boq_status_data.data[0].boq_status !=='Furniture Production complete') && (boq_status_data.data[0].boq_status !=='Furniture Dispatch') && (boq_status_data.data[0].boq_status !=='Installation') 
                  }">
                    <a
                      class="nav-link mb-3"
                      [ngClass]="{
                    'active': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Raw Material Recieved') && (boq_status_data.data[0].boq_status !=='Furniture Production Start') && (boq_status_data.data[0].boq_status !=='Furniture Production complete') && (boq_status_data.data[0].boq_status !=='Furniture Dispatch') && (boq_status_data.data[0].boq_status !=='Installation')  
                  }"
                      id="pills-4-tab"
                      data-toggle="pill"
                      href="#pills-4"
                      role="tab"
                      aria-controls="pills-4"
                      aria-selected="false"
                      style="width: 40px; height: 41px;"
                      ><span *ngIf="(boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Raw Material Recieved') && (boq_status_data.data[0].boq_status !=='Furniture Production Start') && (boq_status_data.data[0].boq_status !=='Furniture Production complete') && (boq_status_data.data[0].boq_status !=='Furniture Dispatch') && (boq_status_data.data[0].boq_status !=='Installation'); else elseBlock6"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                        margin-top: -1px;"></span>
                      <ng-template #elseBlock6>
                        <span>5</span>
                      </ng-template></a
                    >
                    <a class="date_name_style">Handover</a>
                    <div class="date_name_style numbers" *ngIf="boq_status_data.level_3['handover_date'] !== null">({{boq_status_data.level_3['handover_date'] | date: "dd-MM-YYYY"}})</div>
                    <div *ngIf="boq_status_data.level_3['handover_date'] === null"></div>
                  </li>
                  
  </ul> 
               </div> 
               </ng-container>
               <ng-container *ngIf="boq_status_data.quotation_type == 'service'">
               <div id="applying_styles_steps4">
               <ul class="nav nav-pills mt-4"  id="pills-tab" role="tablist">
                 <li id="border-1" class="nav-item" style="width: 91px;" [ngClass]="{
                    'changes2': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') 
                  }">
                   <a
                      class="nav-link mb-3"
                      [ngClass]="{
                    'active': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') 
                  }"
                      id="pills-1-tab"
                      data-toggle="pill"
                      href="#pills-1"
                      role="tab"
                      aria-controls="pills-1"
                      aria-selected="true"
                      style="width: 40px; height: 41px;"
                      ><span *ngIf="(boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production'); else elseBlock"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                        margin-top: -1px;"></span>
                      <ng-template #elseBlock>
                        <span>1</span>
                      </ng-template></a
                    >
                    <a class="date_name_style">Service Start</a>
                    <div class="date_name_style numbers" *ngIf="boq_status_data.level_3['service_start_date'] !== null">({{boq_status_data.level_3['service_start_date'] | date: "dd-MM-YYYY"}})</div>
                    <div *ngIf="boq_status_data.level_3['service_start_date'] === null"></div>
                 </li>
                 <li id="border-2" class="nav-item" style="width: 91px;" [ngClass]="{
                    'changes2': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Service Start') 
                  }">
                    <a
                      class="nav-link mb-3"
                      [ngClass]="{
                    'active': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Service Start') 
                  }"
                      id="pills-2-tab"
                      data-toggle="pill"
                      href="#pills-2"
                      role="tab"
                      aria-controls="pills-2"
                      aria-selected="false"
                      style="width: 40px; height: 41px;"
                      ><span *ngIf="(boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Service Start'); else elseBlock2"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                        margin-top: -1px;"></span>
                      <ng-template #elseBlock2>
                        <span>2</span>
                      </ng-template></a
                    >
                    <a class="date_name_style">Service 50%</a>
                    <div class="date_name_style numbers" *ngIf="boq_status_data.level_3['service_50_per_date'] !== null">({{boq_status_data.level_3['service_50_per_date'] | date: "dd-MM-YYYY"}})</div>
                    <div *ngIf="boq_status_data.level_3['service_50_per_date'] === null"></div>
                  </li>
                  <li id="border-3" class="nav-item" style="width: 91px;" [ngClass]="{
                    'changes2': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Service Start') && (boq_status_data.data[0].boq_status !=='Service 50%') 
                  }">
                    <a
                      class="nav-link mb-3"
                      [ngClass]="{
                    'active': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Service Start') && (boq_status_data.data[0].boq_status !=='Service 50%') 
                  }"
                      id="pills-3-tab"
                      data-toggle="pill"
                      href="#pills-3"
                      role="tab"
                      aria-controls="pills-3"
                      aria-selected="false"
                      style="width: 40px; height: 41px;"
                      ><span *ngIf="(boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Service Start') && (boq_status_data.data[0].boq_status !=='Service 50%'); else elseBlock3"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                        margin-top: -1px;"></span>
                      <ng-template #elseBlock3>
                        <span>3</span>
                      </ng-template></a
                    >
                    <a class="date_name_style">Site Readiness</a>
                    <div class="date_name_style numbers" *ngIf="boq_status_data.level_3['site_readiness_date'] !== null">({{boq_status_data.level_3['site_readiness_date'] | date: "dd-MM-YYYY"}})</div>
                    <div *ngIf="boq_status_data.level_3['site_readiness_date'] === null"></div>
                  </li>
                  <li id="border-4" class="nav-item" style="width: 91px;" [ngClass]="{
                    'changes2': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Service Start') && (boq_status_data.data[0].boq_status !=='Service 50%') && (boq_status_data.data[0].boq_status !=='Site Readiness') 
                  }">
                    <a
                      class="nav-link mb-3"
                      [ngClass]="{
                    'active': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Service Start') && (boq_status_data.data[0].boq_status !=='Service 50%') && (boq_status_data.data[0].boq_status !=='Site Readiness') 
                  }"
                      id="pills-4-tab"
                      data-toggle="pill"
                      href="#pills-4"
                      role="tab"
                      aria-controls="pills-4"
                      aria-selected="false"
                      style="width: 40px; height: 41px;"
                      ><span *ngIf="(boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Service Start') && (boq_status_data.data[0].boq_status !=='Service 50%') && (boq_status_data.data[0].boq_status !=='Site Readiness'); else elseBlock4"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                        margin-top: -1px;"></span>
                      <ng-template #elseBlock4>
                        <span>4</span>
                      </ng-template></a
                    >
                    <a class="date_name_style">Handover</a>
                    <div class="date_name_style numbers" *ngIf="boq_status_data.level_3['handover_date'] !== null">({{boq_status_data.level_3['handover_date'] | date: "dd-MM-YYYY"}})</div>
                    <div *ngIf="boq_status_data.level_3['handover_date'] === null"></div>
                  </li>
                  
                  
                </ul> 
               </div> 
               </ng-container>
              </div>
          </li>
      </ul>
      </div>
  
  <div class="row mt-4 bg-white border-outer align-items-center" *ngIf="loader_for_timeline" style="height: 22rem;">
    <div class="col-12 d-flex justify-content-center">
      <div class="ss-spinner ss-spinner--orange mt-0">
        <div class="ss-spinner__item1"></div>
        <div class="ss-spinner__item2"></div>
        <div class="ss-spinner__item3"></div>
        <div class="ss-spinner__item4"></div>
      </div>
    </div>
    </div>
  
  <div class="px-lg-5 text-center py-lg-4 py-0" [ngClass] ="{'d-none' : boq_status_data.data[0].boq_timeline_level === 'level_1' }"  >
    <p *ngIf = "boq_status_data.status !== 'Handover' ; else handoverBlock " class="mb-0">You are just {{boq_status_data.data[0].remaining_days == 'NA' ? 'few' : boq_status_data.data[0].remaining_days}} days away from your dream home! Your Project is in <span style="color: #8D0528;">{{boq_status_data.status}}</span> Stage.</p>
    <ng-template #handoverBlock>
      <p>Congratulations!! Your Dream Home has been handed over!</p>
    </ng-template>
  </div>
  </div> 
  
  
  <!-- mobile view code -->
  <div class="my-4 d-md-none d-sm-block container">
    <p class="mb-0">Hello <span style="color: #8D0528;">{{projectSummaryDetails.lead_name}}</span></p>
    <p [ngClass] = "{'d-block' :  boq_status_data.data[0].boq_timeline_level === 'level_1' ,  'd-none' : boq_status_data.data[0].boq_timeline_level !== 'level_1'}" class="mb-0">You are just {{boq_status_data.data[0].remaining_days == 'NA' ? 'few' : boq_status_data.data[0].remaining_days}} days away from your dream home! Your Project is in <span style="color: #8D0528;">{{boq_status_data.status}}</span> Stage.</p>
  </div>

  <div class="d-md-none d-sm-block container" [ngClass] = "{'d-none' :  boq_status_data.data[0].boq_timeline_level === 'level_1' ,  'd-block' : boq_status_data.data[0].boq_timeline_level !== 'level_1'}" >
    <div class="accordion" id="accordionSection" [ngClass]="{'d-none': !check_1, '': check_1}">
      <div class="row mx-0 py-2 br_5 order-component">
                  <div
                    data-toggle="collapse"
                    href="#orderBooking"
                    role="button"
                    aria-expanded="false"
                    aria-controls="orderBooking"
                    class="col-lg-12 d-flex justify-content-center py-1"
                    data-toggle="collapse"
                  >
                    <h4 class="mb-0">Order Booking</h4>
                    <button class="accordionArrow">
                      <img src="../../assets/images/icons/down-arrow-order.png" />
                    </button>
                  </div>
                </div>
                <div
                  class="collapse"
                  id="orderBooking"
                  data-parent="#accordionSection"
                  
                >
                  <div
                    class="row justify-content-center align-items-center flex-column"
                  >
                    <div class="col-12 col-md-6">
                      <div class="root-navmenu" style="position: relative;">
                        <div class="navmenu">
                          <ul>
                            <li class="active1">
                              <div
                                class="mb-4 show_dot"
                              >
                                <p class="navmenu-detail mb-0 ">
                                  Estimate Shared
                                </p>
                                <p style="font-size: 12px;" *ngIf="boq_status_data.level_1['shared_date'] !== null">({{(boq_status_data.level_1['shared_date'] | date: "dd-MM-YYYY")}})</p>
                                <p *ngIf="boq_status_data.level_1['shared_date'] === null"></p>
                              </div>
                            </li>
                            <ng-container *ngIf="boq_status_data.data[0].boq_timeline_level === 'level_1'">
                              <div [ngClass]="{'greylineapprove1': (boq_status_data.data[0].boq_status == 'Shared')}"></div>
                              </ng-container>
                            <li class="active1">
                              <div
                                [ngClass]="{
                    'show_dot': boq_status_data.data[0].boq_status !== 'Shared', 'mb-5': boq_status_data.level_1['approved_date'] == null, 'mb-4':boq_status_data.level_1['approved_date'] !== null
                  }"
                              >
                                <p class="navmenu-detail mb-0">
                                  Estimate Approved
                                </p>
                                <p style="font-size: 12px;" *ngIf="boq_status_data.level_1['approved_date'] !== null">({{(boq_status_data.level_1['approved_date'] | date: "dd-MM-YYYY")}})</p>
                                <p *ngIf="boq_status_data.level_1['approved_date'] === null"></p>
                              </div>
                            </li>
                            <li>
                              <div [ngClass]="{
                    'show_dot': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected'), 'mb-5': boq_status_data.level_1['10_per_paid_date'] == null, 'mb-4':boq_status_data.level_1['10_per_paid_date'] !== null
                  }"
                              >
                                <p class="navmenu-detail mb-0">
                                  <span class="numbers">10%</span> Payment
                                </p>
                                <p style="font-size: 12px;" *ngIf="boq_status_data.level_1['10_per_paid_date'] !== null">({{ (boq_status_data.level_1['10_per_paid_date'] | date: "dd-MM-YYYY")}})</p>
                                <p *ngIf="boq_status_data.level_1['10_per_paid_date'] === null"></p>
                              </div>
                            </li>
                            <div
                              [ngClass]="{'orderBookingFullLine2': (boq_status_data.data[0].boq_timeline_level === 'level_3') || (boq_status_data.data[0].boq_timeline_level === 'level_2'),  'orderBookingActiveLine': (boq_status_data.data[0].boq_timeline_level === 'level_1'), 'full_approvereject': (boq_status_data.data[0].boq_status == 'Approved/Rejected'), 'full_orderbook': boq_status_data.data[0].boq_status !== 'Shared'}"
                            ></div>
                            <ng-container *ngIf="boq_status_data.data[0].boq_timeline_level === 'level_1'">
                              <div [ngClass]="{'greylineapprove': (boq_status_data.data[0].boq_status == 'Approved/Rejected')}"></div>
                              </ng-container>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    </div>
  
  
  
  
      <div class="accordion" id="accordionSection" [ngClass]="{'d-none': !check_2, '': check_2}">
      <div class="row mx-0 py-2 br_5 order-component">
                  <div
                    data-toggle="collapse"
                    href="#orderConfirmation"
                    role="button"
                    aria-expanded="false"
                    aria-controls="orderBooking"
                    class="col-lg-12 d-flex justify-content-center py-1"
                    data-toggle="collapse"
                  >
                    <h4 class="mb-0">Order Confirmation</h4>
                    <button class="accordionArrow">
                      <img src="../../assets/images/icons/down-arrow-order.png" />
                    </button>
                  </div>
                </div>
                <div
                  class="collapse"
                  id="orderConfirmation"
                  data-parent="#accordionSection"
                  
                >
                  <div
                    class="row justify-content-center align-items-center flex-column"
                  >
                    <div class="col-12 col-md-6">
                      <div class="root-navmenu" style="position: relative;">
                        <div class="navmenu">
                          <ul>
                            <li class="active1">
                              <div
                                [ngClass]="{
                    'show_dot': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid'), 'mb-5': boq_status_data.level_2['site_measurement_date'] == null, 'mb-4':boq_status_data.level_2['site_measurement_date'] !== null
                  }"
                              >
                                <p class="navmenu-detail mb-0 ">
                                  Site Measurement
                                </p>
                                <p style="font-size: 12px;" *ngIf="boq_status_data.level_2['site_measurement_date'] !== null ">({{(boq_status_data.level_2['site_measurement_date'] | date: "dd-MM-YYYY")}})</p>
                                <p *ngIf="boq_status_data.level_2['site_measurement_date'] === null "></p>
                              </div>
                            </li>
                            <li class="active1">
                              <div
                                [ngClass]="{
                    'show_dot': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement'), 'mb-5': boq_status_data.level_2['material_selection_date'] == null, 'mb-4':boq_status_data.level_2['material_selection_date'] !== null
                  }"
                              >
                                <p class="navmenu-detail mb-0">
                                  Material Selection
                                </p>
                                <p style="font-size: 12px;" *ngIf="boq_status_data.level_2['material_selection_date'] !== null ">({{(boq_status_data.level_2['material_selection_date'] | date: "dd-MM-YYYY")}})</p>
                                <p *ngIf="boq_status_data.level_2['material_selection_date'] === null "></p>
                              </div>
                            </li>
                            <li>
                              <div [ngClass]="{
                    'show_dot': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection'), 'mb-5': boq_status_data.level_2['Site_validation_date'] == null, 'mb-4':boq_status_data.level_2['Site_validation_date'] !== null
                  }" 
                              >
                                <p class="navmenu-detail mb-0">
                                  Site Validation
                                </p>
                                <p style="font-size: 12px;" *ngIf="boq_status_data.level_2['Site_validation_date'] !== null">({{(boq_status_data.level_2['Site_validation_date'] | date: "dd-MM-YYYY") }})</p>
                                <p *ngIf="boq_status_data.level_2['Site_validation_date'] === null"></p>
                              </div>
                            </li>
                            <li>
                              <div
                                [ngClass]="{
                    'show_dot': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation'), 'mb-5': boq_status_data.level_2['qc_approval_date'] == null, 'mb-4':boq_status_data.level_2['qc_approval_date'] !== null
                  }" 
                              >
                                <p class="navmenu-detail mb-0">
                                  QC Approved
                                </p>
                                <p style="font-size: 12px;" *ngIf="boq_status_data.level_2['qc_approval_date'] !== null">({{(boq_status_data.level_2['qc_approval_date'] | date: "dd-MM-YYYY")}})</p>
                                <p  *ngIf="boq_status_data.level_2['qc_approval_date'] === null"></p>
                              </div>
                            </li>
                            <li>
                              <div
                                [ngClass]="{
                    'show_dot': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval'), 'mb-5': boq_status_data.level_2['final_boq_shared_date'] == null, 'mb-4':boq_status_data.level_2['final_boq_shared_date'] !== null
                  }"
                              >
                                <p class="navmenu-detail mb-0">
                                   BOQ Shared
                                </p>
                                <p style="font-size: 12px;" *ngIf="boq_status_data.level_2['final_boq_shared_date'] !== null">({{(boq_status_data.level_2['final_boq_shared_date'] | date: "dd-MM-YYYY")}})</p>
                                <p *ngIf="boq_status_data.level_2['final_boq_shared_date'] === null"></p>
                              </div>
                            </li>
                            <li>
                              <div
                                [ngClass]="{
                    'show_dot': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared'), 'mb-5': boq_status_data.level_2['final_boq_approved_date'] == null, 'mb-4':boq_status_data.level_2['final_boq_approved_date'] !== null
                  }"
                              >
                                <p class="navmenu-detail mb-0">
                                   BOQ Approved
                                </p>
                                <p style="font-size: 12px;" *ngIf="boq_status_data.level_2['final_boq_approved_date'] !== null ">({{(boq_status_data.level_2['final_boq_approved_date'] | date: "dd-MM-YYYY")}})</p>
                                <p *ngIf="boq_status_data.level_2['final_boq_approved_date'] === null "></p>
                              </div>
                            </li>
                            <li>
                              <div
                                [ngClass]="{
                    'show_dot': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved'), 'mb-5': boq_status_data.level_2['sent_to_production_date'] == null, 'mb-4':boq_status_data.level_2['sent_to_production_date'] !== null
                  }" 
                              >
                                <p class="navmenu-detail mb-0">
                                  Sent to Production
                                </p>
                                <p style="font-size: 12px;" *ngIf="boq_status_data.level_2['sent_to_production_date'] !== null">({{ (boq_status_data.level_2['sent_to_production_date'] | date: "dd-MM-YYYY")}})</p>
                                <p *ngIf="boq_status_data.level_2['sent_to_production_date'] === null"></p>
                              </div>
                            </li>
                            <div
                              [ngClass]="{'orderBookingFullLine': (boq_status_data.data[0].boq_timeline_level === 'level_3'),  'orderBookingGreyeLine': (boq_status_data.data[0].boq_timeline_level === 'level_1'), 'orderBookingActiveLine': (boq_status_data.data[0].boq_timeline_level === 'level_2'), 'full_orderConfirm': (boq_status_data.data[0].boq_status == 'Sent to Production'), 'full_orderboqshared': (boq_status_data.data[0].boq_status == 'Final BOQ Shared'), 'full_siteValidation': (boq_status_data.data[0].boq_status == 'Site Validation'),'full_mateiralSeclection': (boq_status_data.data[0].boq_status == 'Material Selection'), 'full_qcApproval': (boq_status_data.data[0].boq_status == 'QC Approval'),'full_finalboqapp': (boq_status_data.data[0].boq_status == 'Final BOQ Approved')}"
                            ></div>
                            <ng-container *ngIf="boq_status_data.data[0].boq_timeline_level === 'level_2'">
                            <div [ngClass]="{'final_boq_sharedline': (boq_status_data.data[0].boq_status == 'Final BOQ Shared'),'final_boq_siteMeasurement': (boq_status_data.data[0].boq_status == 'Site Measurement'),'final_boq_siteValidation': (boq_status_data.data[0].boq_status == 'Site Validation'), 'final_materialSecl': (boq_status_data.data[0].boq_status == 'Material Selection'), 'final_qcApproval': (boq_status_data.data[0].boq_status == 'QC Approval'), 'final_finalboqapprove': (boq_status_data.data[0].boq_status == 'Final BOQ Approved') }">
                              </div>
                              </ng-container>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
      </div>
  
      <div class="accordion" id="accordionSection" [ngClass]="{'d-none': !check_3, '': check_3}">
      <div class="row mx-0 py-2 br_5 order-component">
                  <div
                    data-toggle="collapse"
                    href="#orderExecution"
                    role="button"
                    aria-expanded="false"
                    aria-controls="orderBooking"
                    class="col-lg-12 d-flex justify-content-center py-1"
                    data-toggle="collapse"
                  >
                    <h4 class="mb-0">Order Execution</h4>
                    <button class="accordionArrow">
                      <img src="../../assets/images/icons/down-arrow-order.png" />
                    </button>
                  </div>
                </div>
                <div
                  class="collapse"
                  id="orderExecution"
                  data-parent="#accordionSection"
                  
                >
                  <div
                    class="row justify-content-center align-items-center flex-column"
                  >
                    <div class="col-12 col-md-6">
                      <div class="root-navmenu" style="position: relative;">
                        <div class="navmenu">
                          <ng-container *ngIf="boq_status_data.quotation_type == 'non-service'">
                          <ul>
                            <li>
                              <div
                                [ngClass]="{
                    'show_dot': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production'), 'mb-5': boq_status_data.level_3['raw_material_received_date'] == null, 'mb-4':boq_status_data.level_3['raw_material_received_date'] !== null
                  }"
                              >
                                <p class="navmenu-detail mb-0 ">
                                  Raw Material Received
                                </p>
                                <p style="font-size: 12px;"  *ngIf="boq_status_data.level_3['raw_material_received_date'] !== null">({{(boq_status_data.level_3['raw_material_received_date'] | date: "dd-MM-YYYY")}})</p>
                                <p *ngIf="boq_status_data.level_3['raw_material_received_date'] === null"></p>
                              </div>
                            </li>
                            <li class="">
                              <div
                                [ngClass]="{
                    'show_dot': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Raw Material Recieved'), 'mb-5': boq_status_data.level_3['furniture_production_start_date'] == null, 'mb-4':boq_status_data.level_3['furniture_production_start_date'] !== null  
                  }"
                              >
                                <p class="navmenu-detail mb-0">
                                  Furniture Production Start
                                </p>
                                <p style="font-size: 12px;" *ngIf="boq_status_data.level_3['furniture_production_start_date'] !== null">({{(boq_status_data.level_3['furniture_production_start_date'] | date: "dd-MM-YYYY")}})</p>
                                <p *ngIf="boq_status_data.level_3['furniture_production_start_date'] === null"></p>
                              </div>
                            </li>
                            
                            <li>
                              <div
                                [ngClass]="{
                    'show_dot': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Raw Material Recieved') && (boq_status_data.data[0].boq_status !=='Furniture Production Start') && (boq_status_data.data[0].boq_status !=='Furniture Production complete'), 'mb-5': boq_status_data.level_3['furniture_dispatch_date'] == null, 'mb-4':boq_status_data.level_3['furniture_dispatch_date'] !== null 
                  }"
                              >
                                <p class="navmenu-detail mb-0">
                                  Furniture Dispatch
                                </p>
                                <p style="font-size: 12px;" *ngIf="boq_status_data.level_3['furniture_dispatch_date'] !== null">({{(boq_status_data.level_3['furniture_dispatch_date'] | date: "dd-MM-YYYY")}})</p>
                                <p *ngIf="boq_status_data.level_3['furniture_dispatch_date'] === null"></p>
                              </div>
                            </li>
                            <li>
                              <div
                                [ngClass]="{
                    'show_dot': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Raw Material Recieved') && (boq_status_data.data[0].boq_status !=='Furniture Production Start') && (boq_status_data.data[0].boq_status !=='Furniture Production complete') && (boq_status_data.data[0].boq_status !=='Furniture Dispatch'), 'mb-5': boq_status_data.level_3['installation_date'] == null, 'mb-4':boq_status_data.level_3['installation_date'] !== null 
                  }"
                              >
                                <p class="navmenu-detail mb-0">
                                  Installation
                                </p>
                                <p style="font-size: 12px;" *ngIf="boq_status_data.level_3['installation_date'] !== null">({{ (boq_status_data.level_3['installation_date'] | date: "dd-MM-YYYY")}})</p>
                                <p *ngIf="boq_status_data.level_3['installation_date'] === null"></p>
                              </div>
                            </li>
                            <li>
                              <div
                                [ngClass]="{
                    'show_dot': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Raw Material Recieved') && (boq_status_data.data[0].boq_status !=='Furniture Production Start') && (boq_status_data.data[0].boq_status !=='Furniture Production complete') && (boq_status_data.data[0].boq_status !=='Furniture Dispatch') && (boq_status_data.data[0].boq_status !=='Installation'), 'mb-5': boq_status_data.level_3['handover_date'] == null, 'mb-4':boq_status_data.level_3['handover_date'] !== null 
                  }"
                              >
                                <p class="navmenu-detail mb-0">
                                  Handover
                                </p>
                                <p style="font-size: 12px;" *ngIf="boq_status_data.level_3['handover_date'] !== null">({{(boq_status_data.level_3['handover_date'] | date: "dd-MM-YYYY")}})</p>
                                <p *ngIf="boq_status_data.level_3['handover_date'] === null"></p>
                              </div>
                            </li>
                            <!-- 'orderBookingFullLine': (boq_status_data.data[0].boq_timeline_level === 'level_3') , -->
                            <div
                              [ngClass]="{'orderBookingGreyeLine2':
                              (boq_status_data.data[0].boq_status === 'Raw Material Recieved') || (boq_status_data.data[0].boq_timeline_level === 'level_1') || (boq_status_data.data[0].boq_timeline_level === 'level_2') , 'orderBookingActiveLine': (boq_status_data.data[0].boq_timeline_level === 'level_3'), 'final_boq_dispatchLine': (boq_status_data.data[0].boq_status == 'Furniture Dispatch'),'final_boq_installine': (boq_status_data.data[0].boq_status == 'Installation'),'final_boq_handover': (boq_status_data.data[0].boq_status == 'Handover'),'final_boq_furniprostart': (boq_status_data.data[0].boq_status == 'Furniture Production Start')}"
                            ></div>
                            <ng-container *ngIf="boq_status_data.data[0].boq_timeline_level === 'level_3'">
                            <div [ngClass]="{'final_finalboqdispatch': (boq_status_data.data[0].boq_status == 'Furniture Dispatch'), 'final_finalboqinstall':(boq_status_data.data[0].boq_status == 'Installation'),'final_finaldurnprostart':(boq_status_data.data[0].boq_status == 'Furniture Production Start') }">
                              </div>
                              </ng-container>
                          </ul>
                          </ng-container>
                          <ng-container *ngIf="boq_status_data.quotation_type == 'service'">
                          <ul>
                            <li class="active1">
                              <div
                                [ngClass]="{
                    'show_dot': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production'), 'mb-5': boq_status_data.level_3['service_start_date'] == null, 'mb-4':boq_status_data.level_3['service_start_date'] !== null
                  }"
                              >
                                <p class="navmenu-detail mb-0 ">
                                  Service Start
                                </p>
                                <p style="font-size: 12px;" *ngIf="boq_status_data.level_3['service_start_date'] !== null">({{(boq_status_data.level_3['service_start_date'] | date: "dd-MM-YYYY")}})</p>
                                <p *ngIf="boq_status_data.level_3['service_start_date'] === null"></p>
                              </div>
                            </li>
                            <li class="active1">
                              <div
                                [ngClass]="{
                    'show_dot': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Service Start'), 'mb-5': boq_status_data.level_3['service_50_per_date'] == null, 'mb-4':boq_status_data.level_3['service_50_per_date'] !== null
                  }"
                              >
                                <p class="navmenu-detail mb-0">
                                  Service <span class="numbers">50%</span>
                                </p>
                                <p style="font-size: 12px;" *ngIf="boq_status_data.level_3['service_50_per_date'] !== null">({{(boq_status_data.level_3['service_50_per_date'] | date: "dd-MM-YYYY")}})</p>
                                <p *ngIf="boq_status_data.level_3['service_50_per_date'] === null"></p>
                              </div>
                            </li>
                            <li>
                              <div
                                [ngClass]="{
                    'show_dot': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Service Start') && (boq_status_data.data[0].boq_status !=='Furniture Service 50%'), 'mb-5': boq_status_data.level_3['site_readiness_date'] == null, 'mb-4':boq_status_data.level_3['site_readiness_date'] !== null
                  }"
                              >
                                <p class="navmenu-detail mb-0">
                                  Site Readiness
                                </p>
                                <p style="font-size: 12px;" *ngIf="boq_status_data.level_3['site_readiness_date'] !== null">({{(boq_status_data.level_3['site_readiness_date'] | date: "dd-MM-YYYY")}})</p>
                                <p *ngIf="boq_status_data.level_3['site_readiness_date'] === null"></p>
                              </div>
                            </li>
                            <li>
                              <div
                                [ngClass]="{
                    'show_dot': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected') && (boq_status_data.data[0].boq_status !=='10% Paid') && (boq_status_data.data[0].boq_status !=='Site Measurement') && (boq_status_data.data[0].boq_status !=='Material Selection') && (boq_status_data.data[0].boq_status !=='Site Validation') && (boq_status_data.data[0].boq_status !=='QC Approval') && (boq_status_data.data[0].boq_status !=='Final BOQ Shared') && (boq_status_data.data[0].boq_status !=='Final BOQ Approved') && (boq_status_data.data[0].boq_status !=='Sent to Production') && (boq_status_data.data[0].boq_status !=='Service Start') && (boq_status_data.data[0].boq_status !=='Furniture Service 50%') && (boq_status_data.data[0].boq_status !=='Site Readiness'), 'mb-5': boq_status_data.level_3['handover_date'] == null, 'mb-4':boq_status_data.level_3['handover_date'] !== null
                  }" 
                              >
                                <p class="navmenu-detail mb-0">
                                  Handover
                                </p>
                                <p style="font-size: 12px;" *ngIf="boq_status_data.level_3['handover_date'] !== null">({{boq_status_data.level_3['handover_date'] | date: "dd-MM-YYYY"}})</p>
                                <p *ngIf="boq_status_data.level_3['handover_date'] === null"></p>
                              </div>
                            </li>
                            
                            <div
                              [ngClass]="{'orderBookingFullLine': (boq_status_data.data[0].boq_timeline_level === 'level_3'),  'orderBookingGreyeLine22': (boq_status_data.data[0].boq_timeline_level === 'level_1') || (boq_status_data.data[0].boq_timeline_level === 'level_2')}"
                            ></div>
                          </ul>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
      </div>
      <div class="px-lg-5 text-center py-lg-4 py-0">
        <p *ngIf = "boq_status_data.status !== 'Handover' ; else MobilehandoverBlock " >You are just {{boq_status_data.data[0].remaining_days == 'NA' ? 'few' : boq_status_data.data[0].remaining_days}} days away from your dream home! Your Project is in <span style="color: #8D0528;">{{boq_status_data.status}}</span> Stage.</p>
        <ng-template #MobilehandoverBlock>
          <p>Congratulations!! Your Dream Home has been handed over!</p>
        </ng-template>
      </div>
  </div>
  
  <!-- mobile ended  -->
  
  
  <div  class=" mx-lg-4 mx-3 my-4">
    <div  class="row">
     <div *ngIf="projectSummaryDetails" class="col-lg-7 mb-lg-0 mb-4 pr-lg-0 ">
        <div class="containerDefault h-100">
          
          <div style="border-bottom: 2px solid #8D0528;" class="d-inline-block my-3">
            <p style="margin-bottom : 0.5rem ; font-size: 16px; color: #8D0528; font-weight: 700;" class="mb-0 mx-3">Project Summary
            </p>
          </div>
          <div class="row justify-content-center">
            <div class="col-11 lead-col mb-lg-4 mb-0">
              <div class="row">
                <div class="col-lg-4 col-6 d-flex justify-content-between">
                    <div>
                      <p class="mb-0 id-lead">Lead ID</p>
                      <p class="lead-num numbers">{{projectSummaryDetails.lead_id}}</p>
                    </div>
                    <div class="d-lg-block d-none" style="border-left: 1px solid #cccc; height: 3rem; margin-right: 2rem;"></div>
                </div>
        
                <div class="col-lg-4 col-6 d-flex justify-content-between">
                  <div>
                    <p class="mb-0 id-lead" style="white-space: nowrap;">Customer Phone Number</p>
                    <p class="lead-num numbers">{{projectSummaryDetails.customer_phone_number}}</p>
                  </div>
                  <div class="d-lg-block d-none" style="border-left: 1px solid #cccc; height: 3rem"></div>
                </div>
                <div class="col-lg-4 col-6">
                  <p class="mb-0 id-lead">Customer E-Mail</p>
                  <p class="lead-num">{{projectSummaryDetails.customer_email}}</p>
                </div>
              </div>
            </div>
        
            <div class="col-11 lead-col mb-4">
              <div class="row">
                <div class="col-lg-4 col-6 d-flex justify-content-between mb-lg-4 mb-0">
                    <div>
                      <p class="mb-0 id-lead">Project Type</p>
                      <p class="lead-num">{{projectSummaryDetails.mkw_full_home}}</p>
                    </div>
                    <div class="d-lg-block d-none" style="border-left: 1px solid #cccc; height: 3rem; margin-right: 2rem;"></div>
                </div>
        
                <div class="col-lg-4 col-6 d-flex justify-content-between mb-lg-4 mb-0">
                  <div>
                    <p class="mb-0 id-lead">Area</p>
                    <p class="lead-num numbers">{{projectSummaryDetails.area}} Sq. Ft.</p>
                  </div>
                  <div class="d-lg-block d-none" style="border-left: 1px solid #cccc; height: 3rem; margin-right: 0rem;"></div>
                </div>
                <div class="col-lg-4 col-6">
                  <p class="mb-0 id-lead">Number Of Rooms</p>
                  <p class="lead-num numbers">{{projectSummaryDetails.number_of_rooms}}</p>
                </div>
        
        
                  <div class="col-lg-4 col-6 d-flex justify-content-between">
                    <div>
                      <p class="mb-0 id-lead">Order Book Date</p>
                      <p class="lead-num">{{projectSummaryDetails.order_book_date  ? (projectSummaryDetails.order_book_date  | date: "dd MMMM yyyy") : "---" }}</p>
                    </div>
                    <div class="d-lg-block d-none" style="border-left: 1px solid #cccc; height: 3rem; margin-right: 2rem;"></div>
                </div>
        
                <div class="col-lg-4 col-6 d-flex justify-content-between">
                  <div>
                    <p class="mb-0 id-lead">Order Confirmation Date</p>
                    <p class="lead-num">{{projectSummaryDetails.order_confirmation_date ? (projectSummaryDetails.order_confirmation_date | date: "dd MMMM yyyy")  : "---"     }}</p>
                  </div>
                  <div class="d-lg-block d-none" style="border-left: 1px solid #cccc; height: 3rem; margin-right: 0rem;"></div>
                </div>
                <div class="col-lg-4 col-6">
                  <p class="mb-0 id-lead">Total Price</p>
                  <p class="lead-num numbers">{{projectSummaryDetails.total_price | currency :'INR':'symbol':'1.0-0'}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
     </div>
     <div class="col-lg-5">
        <div class="containerDefault h-100">
          <div class="row  h-100">
            <div *ngIf="boqSummary" class="boq-summary col-lg-12">
              <div class="d-flex justify-content-between pt-3">
                <div style="border-bottom: 2px solid #8D0528 ;">
                  <p style="margin-bottom : 0.5rem ; font-size: 16px; color: #8D0528; font-weight: 700;" class="mb-0 mx-3">Estimate Summary</p>
                </div>
                <div class="app-rej" *ngIf = "boqSummary.boq_summary_data[0].is_approved  == null && boqSummary.boq_summary_data[0].proposal_doc_id  != null && !deltauser ">
                  <button (click) = "approveBoq(true, boqSummary.boq_summary_data[0].proposal_doc_id )" class="mr-lg-3 mr-1 px-lg-3">Approve</button>
                  <button (click) = "approveBoq(false , boqSummary.boq_summary_data[0].proposal_doc_id)" class="mr-lg-3 mr-1 px-lg-3">Reject</button>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="d-flex flex-column justify-content-between h-100">
              <div class="row mx-2">
                <div class="col-lg-6 mb-lg-0 mb-3 apply-pl-mobile col-7 justify-content-between d-flex">
                  <div class="mb-lg-2">
                    <p class="mb-lg-3 mb-0 type-of-quote">Quote Type</p>
                    <span class="type-of-fur mb-0 text-capitalize"  *ngFor="let type of boqSummary.boq_summary_data[0].quote_type ;  last as isLast">
                      {{type.replace('_', ' ')}}{{isLast?'':', '}}
                    </span>
                  </div>
                  <div class="d-lg-block d-none" style="border-left: 1px solid #cccc; height: 3rem; margin-right:1rem;"></div>
                </div>
        
                <div class="col-lg-3 col-5 justify-content-between d-flex">
                  <div>
                    <p class="mb-lg-3 mb-0 type-of-quote">Total Price</p>
                    <p class="type-of-fur numbers">{{boqSummary.boq_summary_data[0].total_amount |  currency :'INR':'symbol':'1.0-0' }}</p>
                  </div>
                  <div class="d-lg-block d-none" style="border-left: 1px solid #cccc; height: 3rem; margin-right: 1rem;"></div>
                </div>
                <div class="col-lg-3 col-6 apply-pl-mobile justify-content-between d-lg-flex d-none">
                  <div>
                    <p class="mb-lg-3 mb-0 type-of-quote">Quote Status</p>
                    <div *ngIf="boqSummary.boq_summary_data[0].is_approved ===  false">
                      <p class="type-of-fur mb-0">Rejected</p>
                      <p>({{boqSummary.boq_summary_data[0].status_date  | date: "dd-MM-YYYY"}})</p>
                    </div>
                    <div *ngIf="boqSummary.boq_summary_data[0].is_approved ===  true">
                      <p class="type-of-fur mb-0">Approved</p>
                      <p style="font-size: 15px;">({{boqSummary.boq_summary_data[0].status_date  | date: "dd-MM-YYYY" }})</p>
                    </div>
                    <p *ngIf="boqSummary.boq_summary_data[0].is_approved ===  null"  class="type-of-fur text-nowrap">Awaiting approval</p>
                  </div>
                </div>
                <div class="col-lg-6 col-12 apply-pl-mobile justify-content-between d-flex">
                <div>
                  <p class="type-of-quote  mb-lg-2 mb-0">Discount Applied</p>
                  <ng-container *ngIf="boqSummary.boq_summary_data[0].promo_discount_percent !== 0 && boqSummary.boq_summary_data[0].promo_discount_percent !== null ; else promo">
                    <p class="type-of-fur  mb-0"><span class="numbers">{{boqSummary.boq_summary_data[0].promo_discount_percent}}%</span> discount through offer</p>
                    <span class="font-weight-bold">({{ tableBreakUp.quote_type_caluclation[0].current_promo_applied.promo_value  | currency :'INR':'symbol':'1.0-0' }})</span>
                  </ng-container>
                  <ng-container *ngIf = "tableBreakUp.quote_type_caluclation[0].current_promo_applied !== null  && (boq_status_data.data[0].boq_timeline_level === 'level_1'  || boq_status_data.data[0].boq_status === 'Final BOQ Approved'   ||   boq_status_data.data[0].boq_status === 'Site Measurement'  || boq_status_data.data[0].boq_status === 'Material Selection'  || boq_status_data.data[0].boq_status === 'Site Validation' || boq_status_data.data[0].boq_status === 'QC Approval' || boq_status_data.data[0].boq_status === 'Final BOQ Shared')">
                    <p  class="font-weight-bold mb-0" style="color: #8D0528 ;">Expires on  <span class="numbers">{{ tableBreakUp.quote_type_caluclation[0].current_promo_applied.expiry_date  | date: "dd MMMM yyyy" }} </span> </p>
                    <span  class="font-weight-bold numbers" style="color: #8D0528 ;">({{ tableBreakUp.quote_type_caluclation[0].current_promo_applied.remaining_days}})</span>  
                  </ng-container>
                  <ng-template #promo>
                    <span>----</span>
                  </ng-template>
                </div>
                <div class="d-lg-block d-none" style="border-left: 1px solid #cccc; height: 3rem; margin-right: 3rem;"></div>
              </div>
              <div class="col-lg-6 apply-pl-mobile mt-3 mt-lg-0 justify-content-between d-flex">
                <div>
                  <!-- <p class=" mb-0 mb-lg-2 type-of-quote">Validity</p> -->
                  <p *ngIf ="boq_status_data.data[0].boq_timeline_level === 'level_3' || boq_status_data.data[0].boq_status === 'Sent to Production' " class=" mb-0 mb-lg-2 type-of-quote">Expected Handover Date</p>
                  <p *ngIf ="boq_status_data.data[0].boq_timeline_level === 'level_1' || boq_status_data.data[0].boq_status === 'Site Measurement' || boq_status_data.data[0].boq_status === 'Material Selection' || boq_status_data.data[0].boq_status === 'Site Validation' ||  boq_status_data.data[0].boq_status === 'QC Approval' || boq_status_data.data[0].boq_status === 'Final BOQ Shared' ||  boq_status_data.data[0].boq_status === 'Final BOQ Approved'" class=" mb-0 mb-lg-2 type-of-quote">Validity</p>
                  <p class="type-of-fur">Expires on <span class="numbers">{{boqSummary.boq_summary_data[0].expiration_date | date: "dd MMMM yyyy" }}</span> <br class="d-lg-block d-none">
                  <span class="font-weight-bold numbers">({{boqSummary.boq_summary_caluclation[0].expiration_date}})</span>
                  </p>
                </div>
              </div>
  
              <div class="col-lg-4 col-12 apply-pl-mobile justify-content-between d-flex d-lg-none">
                <div>
                  <p class="mb-lg-3 mb-0 type-of-quote">Quote Status</p>
                  <p *ngIf="boqSummary.boq_summary_data[0].is_approved ===  false "  class="type-of-fur">Rejected ({{boqSummary.boq_summary_data[0].status_date  | date: "dd-MM-YYYY"  }})</p>
                  <p *ngIf="boqSummary.boq_summary_data[0].is_approved ===  true "  class="type-of-fur">Approved ({{boqSummary.boq_summary_data[0].status_date  | date: "dd-MM-YYYY"  }})</p>
                  <p *ngIf="boqSummary.boq_summary_data[0].is_approved ===  null"  class="type-of-fur">Awaiting approval</p>
                </div>
              </div>
  
              </div>
  
              <div class="row my-2 mx-2">
                <div class="col-lg-12 mb-lg-2 mb-0">
                  <div class="row justify-content-between">
                    <div class="col-lg-4 col-4">
                      <p *ngIf = "tableBreakUp.quote_type_caluclation[0].promo_applied.length > 1" type="button" data-toggle="modal" data-target="#ViewMoreModal"   style="color : #8D0528"  class="font-weight-bold mb-0">View More</p>
                    </div>
                    <div class="col-lg-4 col-8 d-flex justify-content-end">
                      <!-- <p  *ngIf ="boq_status_data.data[0].boq_timeline_level === 'level_1'  || boq_status_data.data[0].boq_status === 'Final BOQ Approved'   ||   boq_status_data.data[0].boq_status === 'Site Measurement'  || boq_status_data.data[0].boq_status === 'Material Selection'  || boq_status_data.data[0].boq_status === 'Site Validation' || boq_status_data.data[0].boq_status === 'QC Approval' || boq_status_data.data[0].boq_status === 'Final BOQ Shared'" 
                      type="button" data-toggle="modal" data-target="#promoModal" style="color: #8D0528;" (click) = "promoCodeApis()" class="cursorPointer font-weight-bold mb-0">Available Offers<img class=" mx-2" src="../../assets/images/carosel-icons/wright-arrow.png"></p> -->
                    </div>
                  </div>
                </div>
              </div>
  
  
              </div>
            </div>
          </div>
        </div>
     </div>
    </div>
  </div>

  </div>
  
  
  
  <div  id='costBreak' class="costBreakup mx-lg-4 mx-3 my-4">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-6 col-12 h-100">
            <div style="border-bottom: 2px solid #8D0528;" class="d-inline-block my-3">
              <p style="margin-bottom : 0.5rem ; font-size: 16px; color: #8D0528; font-weight: 700;" class="mb-0 mx-3">Cost Breakup</p>
            </div>
            <div class="text-center mt-4">
              <button (click)="spaceTypeWise('spaceWise')" [ngClass] = "{'costBreakActiveClass' : spaceString == 'spaceWise'  }" class="py-lg-3 py-1 cost-space-button">Cost breakup space-wise</button>
              <button (click) = "spaceTypeWise('typeWise')"  [ngClass] = "{'costBreakActiveClass' : spaceString == 'typeWise'}" class="py-lg-3 py-1 cost-break-button">Cost breakup type-wise</button>
            </div>
            <div id="mychart" class="my-5">
              <ng-container *ngIf ="costBreakUpSpaceWise">
                <div class="row justify-content-center mobile-view-mx-auto">
                  <div class="col-lg-11">
                    <div class="row mobile-view-mx-auto">
                      <div class="col-lg-4" *ngFor ="let item of costBreakUpSpaceWise" >
                       <div class="d-flex align-items-baseline">
                        <span  style="height: 10px; width: 10px; display: inline-block; height: 11px; width: 45px; " [ngStyle]="{'background-color':item.color}"  ></span>
                        <p class="px-1" style="font-size:14px;">{{item.space}}:<span class="numbers">({{ item.amount / tableBreakUp.quote_type_caluclation[0].net_amount * 100  | number: '1.0-0'}}%)</span></p>                      
                       </div>
                      </div>
                    </div>
                  </div>
              </div>  
              </ng-container>
              <canvas #mychart></canvas>
            </div> 
            <div id="typeWise" class="my-5 chartTypeWise">
                    <ng-container *ngIf="tableBreakUp">
                      <div class="row justify-content-center mobile-view-mx-auto">
                          <div class="col-lg-11">
                            <div class="row mobile-view-mx-auto">
                              <div class="col-lg-4" *ngFor ="let item of tableBreakUp.quote_types ; let z= index" >
                               <div class="d-flex align-items-baseline">
                                <span  style="height: 10px; width: 10px; display: inline-block; height: 11px; width: 45px; " [ngStyle]="{'background-color':item.color}"  ></span>
                                <p class="px-1" style="font-size:14px;">{{item.capitalized_quote_type}}:<span class="numbers">({{ item.amount / tableBreakUp.quote_type_caluclation[0].net_amount * 100  | number: '1.0-0'}}%)</span></p>                      
                               </div>
                              </div>
                            </div>
                          </div>
                      </div>  
                    </ng-container>
              <canvas  #typeWise></canvas>
            </div>
          </div>
          <div class="col-lg-6 col-12" [ngClass]="{'class50': costBreakUpSpaceWise.length >10  , 'class38': costBreakUpSpaceWise.length < 10}">
            <table *ngIf="tableBreakUp">
              <ng-container *ngIf ="spaceString === 'typeWise'" >
                <tr *ngFor="let break of tableDataForTypeWise.quote_types" >
                  <th  [ngClass]="{'text-uppercase':  break.quote_type === 'mto'  ,'text-capitalize': break.quote_type !== 'mto' }"
                  style="font-weight: normal" >{{break.quote_type.replace('_', ' ')}}</th>
                  <td style="cursor: pointer;" class="numbers" data-toggle="modal" data-target="#levelTwoModal"  (click) ="initialModal(  break.quote_type );" >{{break.amount  | currency :'INR':'symbol':'1.0-0'}}<img src="../../assets/images/vectordown.png" class="ml-1"></td>      
                </tr>  
              </ng-container>
  
              <ng-container *ngIf ="spaceString === 'spaceWise' ">
                <tr *ngFor="let break of costBreakUpSpaceWise">
                  <th 
                  style="font-weight: normal" >{{break.space}}</th>
                  <td style="cursor: pointer;"  class="numbers" data-toggle="modal" data-target="#spaceModal"  (click) ="sidePanelSpaceApi(break.space);" >{{break.amount  | currency :'INR':'symbol':'1.0-0'}}<img src="../../assets/images/vectordown.png" class="ml-1"></td>      
                </tr>  
              </ng-container>

              <tr *ngIf ="tableBreakUp.quote_type_caluclation[0].cleaning_fee &&  spaceString === 'typeWise' && tableBreakUp.quote_type_caluclation[0].cleaning_fee > 0  ">
                <th style="font-weight: normal">Others</th>  
                <td>{{tableBreakUp.quote_type_caluclation[0].cleaning_fee  | currency :'INR':'symbol':'1.0-0'}}</td>      
              </tr> 
  

              <tr>
                <th>Net Amount</th>  
                <td class="numbers">{{tableBreakUp.quote_type_caluclation[0].net_amount | currency :'INR':'symbol':'1.0-0'  }}</td>      
              </tr>  
            
              <ng-container *ngIf = "tableBreakUp.quote_type_caluclation[0].promo_applied.length > 0" >
                <tr>
                  <th>
                    <p>Promotions Applied</p> 
                    <ng-container *ngFor="let promo of tableBreakUp.quote_type_caluclation[0].promo_applied">
                      <p style="font-weight: normal; color: #8D0528;" class="mb-2">{{promo.code}}
                        <span style="font-weight: 400; color: black;" *ngIf ="promo.expiry_date">(Valid Till {{promo.expiry_date}})</span>
                      </p>
                    </ng-container>
                  </th>
                  <td> 
                    <p class="mb-0 numbers">{{tableBreakUp.quote_type_caluclation[0].total_promo_discount}}%</p>
                    <p>(-{{tableBreakUp.quote_type_caluclation[0].total_amount_discount_applied | currency :'INR':'symbol':'1.0-0'  }})</p>
                  </td>
                </tr>
              </ng-container>

           
              <tr>
                <th>Project Management Fee</th>  
                <td class="numbers">{{tableBreakUp.quote_type_caluclation[0].project_management_fee  | currency :'INR':'symbol':'1.0-0'}}</td>      
              </tr> 
              
              <tr>
                <th>Total (Incl of all Taxes & Freight)</th>  
                <td class="numbers">{{tableBreakUp.quote_type_caluclation[0].grand_total_amout  | currency :'INR':'symbol':'1.0-0'}}</td>      
              </tr>  
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
    <div id='paymentSection' class="">
      <div *ngIf ="leadPayementDetails !== null  && leadPayementDetails.amount_to_be_paid !== null" class="containerDefault mx-lg-4 mx-3 my-4 paymentection">
        <div class="row">
          <div class="col-lg-12">
            <div class="payment-border">
              <p>Payment Details</p>
            </div>
            <div class="row justify-content-center">
             
              <div class="col-lg-4">
                <div class="DivForForm mx-5 my-4">
                  <form  [formGroup]="paymentForm" (ngSubmit)="checkThreepercenttenkValidation()">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Amount</label>
                      <input   [max] = 'maxVal'  type="number" class="form-control" formControlName="amount" id="Amount" aria-describedby="emailHelp" placeholder="Enter Amount">
                      <!-- <ng-container *ngIf="cashFreeSubmit">
                        <span *ngIf=" paymentForm.controls.amount.errors ||  paymentForm.controls.amount.hasError('max') ">
                          <small class="text-danger">Please enter a amount greater than 1000 or less than 10 lakh</small>
                        </span>
                      </ng-container> -->
                        <!-- <span *ngIf="paymentForm.value.amount === null">
                          <small class="text-danger">Please Enter Amount</small>
                        </span> -->
                        <ng-container *ngIf ="messageForthreekTenK">
                          <small style="font-family: sans-serif;" class="text-danger">{{DisplayErrormsg}}</small>
                        </ng-container>
                    </div>
                    
                    <div class="form-group">
                      <label for="exampleInputPassword1">Email</label>
                      <input type="email" class="form-control" formControlName="email"  id="exampleInputPassword1" placeholder="Enter email">
                      <ng-container *ngIf="cashFreeSubmit">
                        <small class="text-danger"  *ngIf="paymentForm.controls.email.errors ">Email must be a valid email address</small>
                      </ng-container>
                    </div>
                
                    <div class="form-group">
                      <label for="exampleInputPassword1">Phone Number</label>
                      <input onKeyPress="if(this.value.length==10) return false;" type="number" class="form-control numbers" formControlName="mobile_number" id="exampleInputPassword1" placeholder="Enter Phone Number">
                      <ng-container *ngIf="cashFreeSubmit">
                        <small class="text-danger" *ngIf="paymentForm.controls.mobile_number.status === 'INVALID' ">Please enter valid phone number</small>
                      </ng-container>
                    </div>
                    <button type="submit" [disabled]="!paymentForm.valid"  class="btn btn-primary mb-3 w-50">Pay Now</button>
                  </form>
                </div>
              </div>
    
              <!-- EMI Tabel -->
              <!-- <div class="col-lg-7">
    
                <div class="payment-border">
                  <p class="px-3">EMI Option</p>
                </div>
    
                <div class="mr-lg-5 mx-3 mx-lg-0">
                  <p style="text-align: justify;">Arrivae ecosystem supports customer to easily opt for easy loan options to get your dream home. 
                    You can opt for direct payment options or choose one of the suitable loan plans which fits your requirements. 
                    You won't have to compromise on your home interior. Get the best design with easy payments.</p>
                </div>
              
    
                <div class="row">
                  <div class="col-lg-6">
                    <div class="px-3 px-lg-0 emi-plan-table">
                      <table class="table mb-0">
                        <thead>
                          <tr>
                            <th style="background-color: #8D0528;"></th>
                            <th class="text-center" style="color: white; background-color: #8D0528;">Bajaj Finserv</th>
                            <th style="background-color: #8D0528;"></th>
                          </tr>
                        </thead>
                        <thead>
                          <tr class="headings">
                            <th scope="col" class="py-2">No. of EMIs</th>
                            <th scope="col" class="py-2" style="min-width:6rem ;">Downpayment</th>
                            <th scope="col" class="py-2" style="min-width: 6rem ;">Interest</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>10</td>
                            <td>10%</td>
                            <td>0%</td>
                          </tr>
                          <tr>
                            <td>20</td>
                            <td>20%</td>
                            <td>0%</td>
                          </tr>
      
                          <tr>
                            <td>48<sup style="top: -0.3em;">*</sup></td>
                            <td>33%</td>
                            <td>0%</td>
                          </tr>
                         
                        </tbody>
                      </table>
                      <p style="font-size: 14px;"><sup style="top: -0.3em;">*</sup>No discount on 48 months EMI scheme.</p>
                    </div>
      
                  </div>
                  <div class="col-lg-6">
                    <div class=" px-3 emi-plan-table-second">
                      <table class="table">
                        <thead>
                          <tr>
                            <th style="background-color: #8D0528;"></th>
                            <th class="text-center" style="color: white; background-color: #8D0528;">Credit Fair</th>
                            <th style="background-color: #8D0528;"></th>
                          </tr>
                        </thead>
                        <thead>
                          <tr class="headings">
                            <th scope="col" class="py-2">No. of EMIs</th>
                            <th scope="col" class="py-2" style="min-width:6rem ;">Downpayment</th>
                            <th scope="col" class="py-2" style="min-width: 6rem ;">Interest</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="second-table-padd">6</td>
                            <td class="second-table-padd">10%</td>
                            <td class="second-table-padd">0%</td>
                          </tr>
                          <tr>
                            <td class="second-table-padd">9</td>
                            <td class="second-table-padd">10%</td>
                            <td class="second-table-padd">0%</td>
                          </tr>
      
                          <tr>
                            <td class="second-table-padd">12</td>
                            <td class="second-table-padd">10%</td>
                            <td class="second-table-padd">0%</td>
                          </tr>
  
                          <tr>
                            <td class="second-table-padd">18</td>
                            <td class="second-table-padd">10%</td>
                            <td class="second-table-padd">0%</td>
                          </tr>
  
                          <tr>
                            <td class="second-table-padd">60</td>
                            <td class="second-table-padd">10%</td>
                            <td class="second-table-padd">10.50%</td>
                          </tr>
                         
                         
                        </tbody>
                      </table>
                    </div>
      
                  </div>
                </div>
  
                
              </div> -->
            </div>
          </div>
      </div>
      </div>
    </div>
  
    <div id="timeline-accordian" class="accordion1 horizontal d-lg-flex  d-none  justify-content-center box-shadow-timeline mx-lg-4 py-5" *ngIf ="boq_status_data.data[0].boq_timeline_level === 'level_1'">
      <ul>
       <li [ngClass]="{'d-none': !check_1, '': check_1}">
         <input type="radio" id="vert-12" name="vert-accordion" checked ="checked" />
         <label for="vert-12" style="position: relative;">
           <span class="mt-4" style="writing-mode: vertical-lr; transform: rotate(-180deg);">&nbsp;&nbsp; Order&nbsp;Booking</span>
         <span class="staic_number">1</span>
         <span [ngClass]="{ 'arrow_right_level_one': checked1, '': !checked1}"></span>
       </label>
           
         <div class="content m-3">
           <div id="applying_styles_steps">
          <ul class="nav nav-pills mt-4"  id="pills-tab-one" role="tablist">
            <li id="border-1" class="nav-item active_before" style="width: 79px;">
              <a
                 class="nav-link active mb-3"
                 id="pills-1-tab"
                 data-toggle="pill"
                 href="#pills-1"
                 role="tab"
                 aria-controls="pills-1"
                 aria-selected="true"
                 style="width: 40px; height: 41px;"
                 >
                 <span>
                   <!-- <i class="fa fa-check" aria-hidden="true"></i> -->
                   <img src="../../assets/images/Vector.png" style="margin-left: -8px;
                   margin-top: -1px;">
                 </span>
                 <!-- <ng-template #elseBlock>
                   <span>1</span>
                 </ng-template> -->
                 </a
               >
               <a class="date_name_style text-nowrap">Estimate Shared</a>
                 <div class="date_name_style numbers" *ngIf="boq_status_data.level_1.shared_date !== null">({{boq_status_data.level_1.shared_date | date: "dd-MM-YYYY"}})</div>
                 <div *ngIf="boq_status_data.level_1.shared_date === null"></div>
               
            </li>
            <li id="border-2" class="nav-item" style="width: 79px;" [ngClass]="{
               'changes2': boq_status_data?.data[0]?.boq_status !== 'Shared'
             }">
               <a
                 class="nav-link mb-3"
                 [ngClass]="{
               active: boq_status_data?.data[0]?.boq_status !== 'Shared'
             }"
                 id="pills-2-tab"
                 data-toggle="pill"
                 href="#pills-2"
                 role="tab"
                 aria-controls="pills-2"
                 aria-selected="false"
                 style="width: 40px; height: 41px;"
                 >
                 <span *ngIf="boq_status_data?.data[0]?.boq_status !== 'Shared'; else elseBlock"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                   margin-top: -1px;"></span>
                 <ng-template #elseBlock>
                   <span>2</span>
                 </ng-template></a
               >
               <a class="date_name_style text-nowrap">Estimate Approved</a>
               <div class="date_name_style numbers" *ngIf="boq_status_data.level_1.approved_date !== null">({{boq_status_data.level_1.approved_date | date: "dd-MM-YYYY"}})</div>
               <div *ngIf="boq_status_data.level_1.approved_date === null"></div>
             </li>
             <li id="border-3" class="nav-item" style="width: 79px;" [ngClass]="{
               'changes2': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected')
             }">
               <a
                 class="nav-link mb-3"
                 id="pills-3-tab"
                 [ngClass]="{
               active: (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected')
             }"
                 data-toggle="pill"
                 href="#pills-3"
                 role="tab"
                 aria-controls="pills-3"
                 aria-selected="false"
                 style="width: 40px; height: 41px;"
                 ><span *ngIf="(boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected'); else elseBlock111"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                   margin-top: -1px;"></span>
                 <ng-template #elseBlock111>
                   <span>3</span>
                 </ng-template></a
               >
               <a class="date_name_style">10% Paid</a>
               <div class="date_name_style numbers" *ngIf="boq_status_data.level_1['10_per_paid_date'] !== null">({{boq_status_data.level_1['10_per_paid_date'] | date: "dd-MM-YYYY"}})</div>
               <div *ngIf="boq_status_data.level_1['10_per_paid_date'] === null"></div>
             </li>
             
          </ul> 
          </div>  
         </div>
     </li>
      </ul>
     </div>
  
  
   <div id="gdfgfdg" class="mx-lg-4 mx-3 my-4 next-team">
      <div class="row">
            <div class="col-lg-6 col-12 pr-lg-0 mb-lg-0 mb-4">
              <div class="left-steps h-100">
                <div>
                  <p  class="px-4 next-step-heading">Next steps</p>
                </div>
                <div class="mt-3 pr-lg-0 pr-3">
                  <div class="ml-4" *ngIf = "nextStepByArrivae !== null">
                    <p class="font-weight-bold">Next steps by Arrivae:</p>
                  </div>
                  <ul class="mx-lg-5 mx-0 my-3">
                    <li class="mb-4 text-justify" *ngIf ="nextStepByArrivae && nextStepByArrivae.point_1 !== null " >{{nextStepByArrivae.point_1}}</li>
                    <li class="mb-4 text-justify" *ngIf ="nextStepByArrivae && nextStepByArrivae.point_2 !== null " >{{nextStepByArrivae.point_2}}</li>
                    <li class="mb-4 text-justify" *ngIf ="nextStepByArrivae && nextStepByArrivae.point_3 !== null " >{{nextStepByArrivae.point_3}}</li>
                  </ul>
                  <div class="ml-4" *ngIf = "nextStepByYou !== null">
                    <p class="font-weight-bold">Next steps by you:</p>
                  </div>
                  <ul class="mx-lg-5 mx-0 my-3">
                    <li class="mb-4 text-justify" *ngIf ="nextStepByYou && nextStepByYou.point_1 !== null " >{{nextStepByYou.point_1}}</li>
                    <li class="mb-4 text-justify" *ngIf ="nextStepByYou && nextStepByYou.point_2 !== null" >{{nextStepByYou.point_2}}</li>
                    <li class="mb-4 text-justify" *ngIf ="nextStepByYou && nextStepByYou.point_3 !== null" >{{nextStepByYou.point_3}}</li>
                  </ul>
                </div>
              </div>
          </div>
          <div class="col-lg-6 col-12">
            <div *ngIf = "teamDetails" class="right-details h-100">
              <p class="heading-team px-4">Team Details</p>
              <div class="mx-lg-5 my-lg-4 mt-3 mt-lg-0 pl-lg-0 pl-4">
                  <div class="row">
                    <div class="col-lg-6 col-12 mb-3">
                      <p class="mb-0">Designer Name</p>
                      <div *ngIf="teamDetails.deginer_name !== null && teamDetails.deginer_email !== null ; else designer_name">
                        <p class="font-weight-bold mb-0">{{teamDetails.deginer_name}}
                        </p>
                        (<a class="text-dark font-weight-bold" href="mailTo:{{teamDetails.deginer_email}}">{{teamDetails.deginer_email}}</a>)
  
                      </div>
                      <ng-template #designer_name>
                        <p class="font-weight-bold">To be assigned</p>
                      </ng-template>  
                    </div>
  
  
                    <div class="col-lg-6 col-12">
                      <p class="mb-0">Project Coordinator</p>
                        <div *ngIf="teamDetails.assigned_site_supervisor_name !== null && teamDetails.assigned_site_supervisor_email !== null ; else other_content">
                          <p class="font-weight-bold text-nowrap mb-0">{{teamDetails.assigned_site_supervisor_name}}
                          </p>
                          (<a class="text-dark font-weight-bold" href="mailTo:{{teamDetails.assigned_site_supervisor_email}}">{{teamDetails.assigned_site_supervisor_email}}</a>)
                        </div>
                        <ng-template #other_content>
                          <p class="font-weight-bold">To be assigned</p>
                        </ng-template>  
                    </div>
  
  
                    <div class="col-lg-6 col-12 mb-3">
                      <p class="mb-0">GM Name</p>
                      <div *ngIf="teamDetails.assigned_gm_name !== null && teamDetails.assigned_gm_email !== null ; else other_content">
                        <p class="font-weight-bold mb-0">{{teamDetails.assigned_gm_name}}
                        </p>
                        (<a class="text-dark font-weight-bold" href="mailTo:{{teamDetails.assigned_gm_email}}">{{teamDetails.assigned_gm_email}}</a>)
                      </div>
                      <ng-template #other_content>
                        <p class="font-weight-bold">To be assigned</p>
                      </ng-template>  
                    </div>
  
  
                    <div class="col-lg-6 col-12">
                      <p class="mb-0 text-nowrap">Alternate Customer POC</p>
                      <div *ngIf="teamDetails.alternate_customer_poc_name !== null  ;else poc_name">
                        <p class="font-weight-bold mb-0"> {{teamDetails.alternate_customer_poc_name}}
                        </p>
                        <span *ngIf ="teamDetails.alternate_customer_poc_email !== null" >(<a class="text-dark font-weight-bold" href="mailTo:{{teamDetails.alternate_customer_poc_email}}">{{teamDetails.alternate_customer_poc_email}}</a>)</span>
                      </div>
                      <ng-template #poc_name>
                        <p class="font-weight-bold">To be added</p>
                      </ng-template>  
                    </div>
  
  
                    <div class="col-lg-6 col-12 mb-lg-3 mb-0">
                      <p class="mb-0">Project Manager</p>
                      <div *ngIf="teamDetails.assigned_project_manager_name !== null && teamDetails.assigned_project_manager_email !== null ; else project_manager">
                        <p class="font-weight-bold mb-0"> {{teamDetails.assigned_project_manager_name}}
                        </p>
                        (<a class="text-dark font-weight-bold" href="mailTo:{{teamDetails.assigned_project_manager_email}}">{{teamDetails.assigned_project_manager_email}}</a>)
                      </div>
                      <ng-template #project_manager>
                        <p class="font-weight-bold">To be assigned</p>
                      </ng-template>  
                    </div>
  
  
                    <div class="col-lg-6 col-12 mb-3">
                      <p class="mb-0">CM Name</p>
                      <div *ngIf="teamDetails.assigned_cm_name !== null && teamDetails.assigned_cm_email !== null ; else cm_name">
                        <p class="font-weight-bold text-nowrap mb-0"> {{teamDetails.assigned_cm_name}}
                        </p>
                        (<a class="text-dark font-weight-bold" href="mailTo:{{teamDetails.assigned_cm_email}}">{{teamDetails.assigned_cm_email}}</a>)
                      </div>
                      <ng-template #cm_name>
                        <p class="font-weight-bold">To be assigned</p>
                      </ng-template>
                    </div>

                    <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                      <p class="mb-0">GM Operations</p>
                      <div *ngIf="teamDetails.assigned_operational_managers_name !== null && teamDetails.assigned_operational_managers_email !== null ; else gm_ops">
                        <p class="font-weight-bold mb-0"> {{teamDetails.assigned_operational_managers_name}}
                        </p>
                        (<a class="text-dark font-weight-bold" href="mailTo:{{teamDetails.assigned_operational_managers_email}}">{{teamDetails.assigned_operational_managers_email}}</a>)
                      </div>
                      <ng-template #gm_ops>
                        <p class="font-weight-bold">To be assigned</p>
                      </ng-template>  
                    </div>

                    <div class="col-lg-6 col-12 mb-lg-0 mb-3">
                      <p class="mb-0">NOC Number: 18001230680</p>
                      (<a class="text-dark font-weight-bold" href="mailTo:noc@arrivae.com">noc@arrivae.com</a>)
                    </div>

                  </div>
              </div>
            </div>
          </div>
      </div>
   </div>
  
  
   <!-- more items to explore -->
  <div id='moreItemsToExplore' class="more-items-explore  mx-lg-4 mx-3 my-4 pb-4">
    <div class="items-tag py-3 d-flex justify-content-between align-items-center">
      <div>
        <p class="px-4 mt-3  d-inline-block">More Items To Explore</p>
      </div>
        <div class="d-lg-block d-none">
          <div *ngIf = "initialLosseValue === 'furniture' ">
            <img class="cursorPointer" (click) = "previousButton('furniture')"  src="../../assets/images/carosel-icons/previous-customer.png" alt="previous" >
            <img class="cursorPointer" (click) = "nextButton('furniture')" src="../../assets/images/carosel-icons/next-customer.png" alt="next" >
          </div>
          <div *ngIf = "initialLosseValue === 'appliances' ">
            <img class="cursorPointer" (click) = "previousButton('appliances')"  src="../../assets/images/carosel-icons/previous-customer.png" alt="previous" >
            <img class="cursorPointer" (click) = "nextButton('appliances')" src="../../assets/images/carosel-icons/next-customer.png" alt="next" >
          </div>
        </div>
    </div>
    
    <div class="pl-4 mb-4">
      <span (click) ="looseFurniture('furniture')" [ngClass] = "{'activeMore' : initialLosseValue == 'furniture'  }" class="cursorPointer initial-class">Loose Furniture</span>
      <span (click) ="looseFurniture('appliances')" [ngClass] = "{'activeMore' : initialLosseValue == 'appliances'  }" class="pl-4 cursorPointer initial-class">Appliances</span>
    </div>
  
    <div class="row justify-content-center mx-lg-2">
        <ng-container *ngFor ="let data of exploreMoreItems">
        <div class="col-lg-2 col-5 mb-lg-0 mb-3 px-lg-3 px-1">
            <div class="card h-100">
            <img class="card-img-top" src="{{data.image}}" onerror="src='../../assets/images/carosel-icons/no-image-with-out-border.png'" alt="Card image cap">
            <div class="card-body" style="background-color: #f4f5f7;">
              <p ngbTooltip="{{data.name}}" placement="bottom" style="font-size: 14px;" class="card-title font-weight-bold elipsis_text_more_items">{{data.name}}</p>
              <p class="numbers">{{data.price  | currency :'INR':'symbol':'1.0-0'}}</p>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="d-lg-none d-block">
        <div *ngIf = "initialLosseValue === 'furniture' ">
          <img class="cursorPointer" (click) = "previousButton('furniture')"  src="../../assets/images/carosel-icons/previous-customer.png" alt="previous" >
          <img class="cursorPointer" (click) = "nextButton('furniture')" src="../../assets/images/carosel-icons/next-customer.png" alt="next" >
        </div>
        <div *ngIf = "initialLosseValue === 'appliances' ">
          <img class="cursorPointer" (click) = "previousButton('appliances')"  src="../../assets/images/carosel-icons/previous-customer.png" alt="previous" >
          <img class="cursorPointer" (click) = "nextButton('appliances')" src="../../assets/images/carosel-icons/next-customer.png" alt="next" >
        </div>
      </div>
    </div>
    <div *ngIf ="loader_explore" style="position: absolute;" class="loader-progrees">
      <div class="row mt-4 loader bg-white border-outer align-items-center" >
        <div class="col-12 d-flex justify-content-center">
          <div class="ss-spinner ss-spinner--orange mt-0">
            <div class="ss-spinner__item1"></div>
            <div class="ss-spinner__item2"></div>
            <div class="ss-spinner__item3"></div>
            <div class="ss-spinner__item4"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  
  
  
  <div *ngIf ="boq_status_data.data[0].boq_timeline_level === 'level_1'" class="d-md-none d-sm-block container">
    <div class="accordion" id="accordionSection" [ngClass]="{'d-none': !check_1, '': check_1}">
      <div class="row mx-0 py-2 br_5 order-component">
                  <div
                    data-toggle="collapse"
                    href="#orderBooking"
                    role="button"
                    aria-expanded="false"
                    aria-controls="orderBooking"
                    class="col-lg-12 d-flex justify-content-center py-1"
                    data-toggle="collapse"
                  >
                    <h4 class="mb-0">Order Booking</h4>
                    <button class="accordionArrow">
                      <img src="../../assets/images/icons/down-arrow-order.png" />
                    </button>
                  </div>
                </div>
                <div
                  class="collapse"
                  id="orderBooking"
                  data-parent="#accordionSection"
                  
                >
                  <div
                    class="row justify-content-center align-items-center flex-column"
                  >
                    <div class="col-12 col-md-6">
                      <div class="root-navmenu" style="position: relative;">
                        <div class="navmenu">
                          <ul>
                            <li class="active1">
                              <div
                                class="mb-4 show_dot"
                              >
                                <p class="navmenu-detail mb-0 ">
                                  Estimate Shared
                                </p>
                                <p style="font-size: 12px;" class="numbers"  *ngIf="boq_status_data.level_1['shared_date'] !== null">({{(boq_status_data.level_1['shared_date'] | date: "dd-MM-YYYY")}})</p>
                                <p *ngIf="boq_status_data.level_1['shared_date'] === null"></p>
                              </div>
                            </li>
                            <ng-container *ngIf="boq_status_data.data[0].boq_timeline_level === 'level_1'">
                              <div [ngClass]="{'greylineapprove1': (boq_status_data.data[0].boq_status == 'Shared')}"></div>
                              </ng-container>
                            <li class="active1">
                              <div
                                [ngClass]="{
                    'show_dot': boq_status_data.data[0].boq_status !== 'Shared', 'mb-5': boq_status_data.level_1['approved_date'] == null, 'mb-4':boq_status_data.level_1['approved_date'] !== null
                  }"
                              >
                                <p class="navmenu-detail mb-0">
                                  Estimate Approved
                                </p>
                                <p style="font-size: 12px;" class="numbers" *ngIf="boq_status_data.level_1['approved_date'] !== null">({{(boq_status_data.level_1['approved_date'] | date: "dd-MM-YYYY")}})</p>
                                <p *ngIf="boq_status_data.level_1['approved_date'] === null"></p>
                              </div>
                            </li>
                            <li>
                              <div [ngClass]="{
                    'show_dot': (boq_status_data.data[0].boq_status !== 'Shared') && (boq_status_data.data[0].boq_status !=='Approved/Rejected'), 'mb-5': boq_status_data.level_1['10_per_paid_date'] == null, 'mb-4':boq_status_data.level_1['10_per_paid_date'] !== null
                  }"
                              >
                                <p class="navmenu-detail mb-0">
                                  <span class=numbers>10%</span> Payment
                                </p>
                                <p style="font-size: 12px;" class="numbers" *ngIf="boq_status_data.level_1['10_per_paid_date'] !== null">({{ (boq_status_data.level_1['10_per_paid_date'] | date: "dd-MM-YYYY")}})</p>
                                <p *ngIf="boq_status_data.level_1['10_per_paid_date'] === null"></p>
                              </div>
                            </li>
                            <div
                              [ngClass]="{'orderBookingFullLine2': (boq_status_data.data[0].boq_timeline_level === 'level_3') || (boq_status_data.data[0].boq_timeline_level === 'level_2'),  'orderBookingActiveLine': (boq_status_data.data[0].boq_timeline_level === 'level_1'), 'full_approvereject': (boq_status_data.data[0].boq_status == 'Approved/Rejected'), 'full_orderbook': boq_status_data.data[0].boq_status !== 'Shared'}"
                            ></div>
                            <ng-container *ngIf="boq_status_data.data[0].boq_timeline_level === 'level_1'">
                              <div [ngClass]="{'greylineapprove': (boq_status_data.data[0].boq_status == 'Approved/Rejected')}"></div>
                              </ng-container>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
  </div>
  
    <div id='numberofBoqs'>
        <div *ngIf ="otherActiveBoqs && otherActiveBoqs.length > 0"  class="other-active-boqs mx-lg-4 mx-3 my-4 pb-4">
          <div class="active-boqs py-3">
            <p class="px-4 d-inline-block">Other Active BOQs</p>
          </div>
        <div class="mobile-carosel-active-boqs">
              <div slickContainer #slickController="slick" [slickConfig]="config">
                <ng-container *ngIf="slickController.initialize">
                <div *ngFor = "let data of otherActiveBoqs ; let q = index">
                    <div slickItem class="card ml-lg-5 mx-4 cursorPointer" style="border: 0.2px solid #F0F1F5;" (click)="redirectToBoqLink(data.magic_link)">
                      <div  class="card-body p-0">
                        <div style="background-color: #FFEAEF;" class="py-5">
                          <p class="text-center mb-0 font-weight-bold numbers">{{data.boq_number}}</p>
                        </div>
                        <div class="py-4 ml-3">
                          <p>BOQ Status</p>
                          <p class="mb-0 font-weight-bold" style="color: #8D0528;">{{data.boq_status}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="d-lg-block d-none">
                <div class="other-mobile-boqs"  [ngClass] = "{'d-none' : otherActiveBoqs.length <= 4  }">
                  <button   class="mx-1 button-one" (click)="slickController.prev()">
                    <img class="cursorPointer"  src="../../assets/images/carosel-icons/previous-customer.png" alt="previous" >
                  </button>
                  <button  class="mx-1 button-two" (click)="slickController.next()">
                    <img class="cursorPointer" src="../../assets/images/carosel-icons/next-customer.png" alt="next" >
                  </button>
                </div>
            </div>
            <div class="d-lg-none d-block">
              <div class="other-mobile-boqs" [ngClass] = "{'d-none' : otherActiveBoqs.length === 1  }">
                <button   class="mx-1 button-one" (click)="slickController.prev()">
                  <img class="cursorPointer"  src="../../assets/images/carosel-icons/previous-customer.png" alt="previous" >
                </button>
                <button  class="mx-1 button-two" (click)="slickController.next()">
                  <img class="cursorPointer" src="../../assets/images/carosel-icons/next-customer.png" alt="next" >
                </button>
              </div>
          </div>
        </div>
      </div> 
    </div>
  
   <!-- terms and conditions -->
  <div class="project-directory my-4 mx-0 mx-md-4 d-none">
              <h4 class="my-2">Site Video</h4>
              <div class="card-upload ifram-img mt-4" >
              <iframe src="https://sandbox.arrivae.com/stream/index.html" class="iframe-int" title="Site Video">
              </iframe>
              </div>
  </div> 
    <div  id="aggrementPoints">
        <img src="../../assets/images/termsandconditions/T_and_C-0006.jpg" class="img-fluid w-100">
        <img src="../../assets/images/termsandconditions/T_and_C-0007.jpg" class="img-fluid w-100">
        <img src="../../assets/images/termsandconditions/T_and_C-0008.jpg" class="img-fluid w-100">
        <img src="../../assets/images/termsandconditions/T_and_C-0010.jpg" class="img-fluid w-100">
        <img src="../../assets/images/termsandconditions/T_and_C-0009.jpg" class="img-fluid w-100">
        <img src="../../assets/images/termsandconditions/T_and_C-0011.jpg" class="img-fluid w-100">
        <img src="../../assets/images/termsandconditions/T_and_C-0012.jpg" class="img-fluid w-100">
        <img src="../../assets/images/termsandconditions/T_and_C-0013.jpg" class="img-fluid w-100">
        <img src="../../assets/images/termsandconditions/T_and_C-0014.jpg" class="img-fluid w-100">
      </div>
  </div>
  
  
  <!-- leveltwomodal for typewise -->
  
  <div class="modal fade get-level-wrapper " id="levelTwoModal" tabindex="-1" aria-labelledby="levelTwoModalLabel" aria-hidden="true">
    <div class="modal-dialog get-level-wrapper">
      <div  style="overflow-y: scroll; height: 100%;" class="modal-content"  >
        <div class="contents mx-2 my-2 d-flex">
            <button  type="button" class="close" data-dismiss="modal" aria-label="Close m-0 p-0">
              <img class="arrow-left-icon" src="../../../assets/images/icons/arrow left.svg" alt="arrow left">
            </button>
        </div>
        <div class="mx-2" *ngIf=" catDetails &&  catDetails.length > 0">
        <ng-container *ngFor="let del of catDetails ; let d= index">
            <div class="container apply-box-level py-4 mt-3">
              <div  class="row justify-content-between">
                <div class="col-8">
                <p class="px-4 mb-0" style="font-size: 16px; color: #8D0528; font-weight: 700; ">{{del.space}}</p>
                <div style="border-bottom: 2px solid; color: #8D0528; width: 13rem;"></div>
                </div>
                <div class="col-4" style="text-align: end;">
                <p class="font-weight-bold numbers" style="color: #8D0528;">{{del.amount_tot_each_cat  | currency :'INR':'symbol':'1.0-0'  }}</p>
                </div>
            </div>
    
            <ng-container *ngIf = "del.uniq_space_name.length > 0">
              <div *ngFor ="let le of del.uniq_space_name"  class="row justify-content-between mt-2">
                <div class="col-8">
                <p  class="px-4 mb-0 text-capitalize">{{le.name}}({{le.cat_count}})</p>
                </div>
              <div class="col-4" style="text-align: end;">
                <p class="mb-0 numbers">{{le.amount | currency :'INR':'symbol':'1.0-0' }}</p>
              </div>
            </div>
            </ng-container>
            </div>
        </ng-container>
        </div>
        <div class="mx-2">
          <div class="apply-box-level container" *ngFor ="let list of catList; let j =index">
            <div class="row justify-content-between pt-3 mt-2">
              <div class="col-8">
                <p style=" font-size: 16px; color: #8D0528; font-weight: 700; text-transform:capitalize;"  class="px-4 mb-0">{{list.uniq_cat_name}}</p>
                <div style="border-bottom: 2px solid; color: #8D0528; width: 13rem;"></div>
              </div>
              <div class="col-4" style="text-align: end;">
                <p class="font-weight-bold numbers" style="color : #8D0528">{{list.amount_total  | currency :'INR':'symbol':'1.0-0'}}</p>
              </div>
            </div>
  
            <div class="pb-3" *ngFor="let allList of list.all_product ; let a = index">
                    <div class="row">
                    <div class="col-5" style="border: 1px solid #ccc;">
                      <img src="{{allList.image}}" onerror="src='../../assets/images/carosel-icons/no-image-with-out-border.png'" class="img-fluid" style="height:10rem; object-fit: contain; display: flex; justify-content: center; margin: 0 auto;">
                    </div>
                    <div class="col-7">
                      <div class="d-flex justify-content-between">
                        <p ngbTooltip="{{allList.name}}" placement="bottom">{{allList.name  | slice:0:13}}
                          <span *ngIf ="allList.name && allList.name.length >= 13">...</span>
                        </p>
                        <p class="numbers">{{allList.total_price  | currency :'INR':'symbol':'1.0-0'}}</p>
                      </div>
                      <p>Material: {{allList.core_material}}</p>
                      <p>Qty:{{allList.quantity}}</p>
                      <p class="numbers">Unit Price: {{allList.unit_price  | currency :'INR':'symbol':'1.0-0' }}</p>
                      <!-- <p *ngIf="!show_moreDetail">Material: {{allList.core_material}}</p> -->
                     <ng-container *ngIf ="typeForLevelOne=== 'mto'">
                        <p>Model Number: {{allList.model_number}}</p>
                        <p>Actual Size: {{allList.actual_size}}</p>
                     </ng-container>
  
                     <ng-container *ngIf ="typeForLevelOne=== 'panel' &&  (catList[j].uniq_cat_name === 'kitchen' ||  catList[j].uniq_cat_name === 'wardrobe'  ) ">
                      <p>Category Name: {{allList.kitchen_category_name}}</p>
                      <p>Actual Size: {{allList.actual_size}}</p>
                    </ng-container>
                      <p data-toggle="modal" data-target="#levelThreeModal"  style=" color: #8D0528; border-bottom: 1px solid;" (click) = "levelThreeModal(list.uniq_cat_name ,allList.id)" class="d-inline-block cursorPointer" *ngIf="show_moreDetail && catList[j].uniq_cat_name === 'Loose Furniture'  ">More Details</p>
                    </div>
                    </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> 


  <!-- space modal for space wise -->
  
  <div class="modal fade get-level-wrapper" id="spaceModal" tabindex="-1" aria-labelledby="spaceModal" aria-hidden="true">
    <div class="modal-dialog get-level-wrapper">
      <div style="overflow-y: scroll; height: 100%;" class="modal-content">
        <div class="contents mx-2 my-2 d-flex">
          <button  type="button" class="close" data-dismiss="modal" aria-label="Close m-0 p-0">
            <img class="arrow-left-icon" src="../../../assets/images/icons/arrow left.svg" alt="arrow left">
          </button>
        </div>
  
        <div class="mx-2" *ngIf ="spaceHeadingOne && spaceHeadingOne.length > 0">
          <ng-container *ngFor ="let data of spaceHeadingOne ; let s = index">
              <div class="apply-box-level py-4 mt-3">
                <div  class="d-flex justify-content-between">
                  <p  class="px-4 text-capitalize" style="font-size: 16px; color: #8D0528; font-weight: 700; border-bottom: 2px solid;">{{data.uniq_cat_name}}</p>
                <div class="mr-2">
                  <p class="font-weight-bold numbers" style="color: #8D0528;">{{data.total_amount  | currency :'INR':'symbol':'1.0-0'}}</p>
                </div>
              </div>
              <ng-container>
                <div class="d-flex justify-content-between mt-2">
                  <p  class="px-4 mb-0">{{data.single_list.space}} ({{data.single_list.category_count}})</p>
                <div class="mr-2">
                  <p class="mb-0 numbers">{{data.single_list.unit_price  | currency :'INR':'symbol':'1.0-0' }}</p>
                </div>
              </div>
              </ng-container>
              </div>
              <div class="apply-box-level py-4 mt-3" *ngIf ="data.single_list.space === 'Other' && s == 0 ">
                <div  class="d-flex justify-content-between">
                  <p  class="px-4 text-capitalize" style="font-size: 16px; color: #8D0528; font-weight: 700; border-bottom: 2px solid;">Cleaning Charges</p>
                <div class="mr-2">
                  <p class="font-weight-bold" style="color: #8D0528;">{{data.cleaning_fee  | currency :'INR':'symbol':'1.0-0' }}</p>
                </div>
              </div>
              <ng-container>
                <div class="d-flex justify-content-between mt-2">
                  <p  class="px-4 mb-0">{{typeofboq == 'service' ? 'Deep Cleaning Charges'  : 'Product Cleaning Charges'}}(1)</p>
                <div class="mr-2">
                  <p class="mb-0">{{data.cleaning_fee  | currency :'INR':'symbol':'1.0-0' }}</p>
                </div>
              </div>
              </ng-container>
              </div>
          </ng-container>
        </div>
  
        <div class="apply-box-level mx-2 my-2" *ngIf ="lisitingWithImage">
          <div  class="d-flex justify-content-between py-4">
              <p  class="px-4 text-capitalize" style="font-size: 16px; color: #8D0528; font-weight: 700; border-bottom: 2px solid;">{{spaceModalData.data.category_data.space}}</p>
            <div class="mr-2">
              <p class="font-weight-bold numbers" style="color: #8D0528;">{{spaceModalData.data.category_data.total_price  | currency :'INR':'symbol':'1.0-0' }}</p>
            </div>
          </div>
  
          <ng-container *ngFor ="let data of lisitingWithImage ; let l = index">
            <div class="row w-100 mb-3">
                  <div class="col-lg-5 col-5">
                    <div style="border: 1px solid #ccc;" class="h-100">
                      <img src="{{data.image}}" onerror="src='../../assets/images/carosel-icons/no-image-with-out-border.png'" class="img-fluid" style="height:10rem; object-fit: contain; display: flex; justify-content: center; margin: 0 auto;">
                    </div>
                  </div>
                  <div class="col-lg-7 col-7 px-0">
                    <div>
                      <div class="d-flex justify-content-between">
                        <p  ngbTooltip="{{data.name}}" placement="bottom"  >{{data.name | slice:0:18}}
                          <span *ngIf =" data.name && data.name.length >= 18">...</span>
                        </p>
                        <p class="numbers">{{data.total_price | currency :'INR':'symbol':'1.0-0'}}</p>
                      </div>
                      <p>Material:{{data.core_material}}</p>
                      <p>Qty:{{data.quantity}}</p>
                      <p>Unit Price:<span class="numbers">{{data.unit_price | currency :'INR':'symbol':'1.0-0'}}</span></p>
                      <p data-toggle="modal" data-target="#levelThreeModal" *ngIf =" data.category === 'Loose Furniture'"   (click) = "levelThreeModal(data.category ,data.id)" style=" color: #8D0528; border-bottom: 1px solid;" class="d-inline-block cursorPointer">More Details</p>
                    </div>
                  </div>
            </div>
            <div class="row w-100 mb-3" *ngIf ="spaceModalData.data.category_data.space == 'Other' && l == 0 ">
              <div class="col-lg-5 col-5">
                <div style="border: 1px solid #ccc;" class="h-100">
                  <img src='../../assets/images/carosel-icons/no-image-with-out-border.png' class="img-fluid" style="height:10rem; object-fit: contain; display: flex; justify-content: center; margin: 0 auto;">
                </div>
              </div>
              <div class="col-lg-7 col-7 px-0">
                <div>
                  <div class="d-flex justify-content-between">
                    <p>{{typeofboq == 'service' ? 'Deep Cleaning Charges'  : 'Product Cleaning Charges'}}</p>
                    <p>{{displayCleaningFee | currency :'INR':'symbol':'1.0-0' }}</p>
                  </div>
                  <p>Qty:1</p>
                  <p>Unit Price:{{displayCleaningFee | currency :'INR':'symbol':'1.0-0' }}</p>
                </div>
              </div>
        </div>
          </ng-container>       
        </div>
      </div>
    </div>
  </div>




  <div class="modal fade get-level-wrapper" id="levelThreeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog get-level-wrapper" role="document">
      <div class="modal-content" style="height: 100%; overflow-y: scroll;">
        <div class=" border-bottom-0 pb-0 d-flex mx-3 mt-3">
          <button  type="button" class="close" data-dismiss="modal" aria-label="Close m-0 p-0">
            <img class="arrow-left-icon" src="../../../assets/images/icons/arrow left.svg" alt="arrow left">
          </button>
        </div>
        <div class="modal-body" *ngIf="levelThreeData">
            <div class="apply-box-level pt-3">
              <div class="">
                <p style="margin-bottom: 0.5rem;
                font-size: 16px;
                color: #8D0528;
                font-weight: 700;
                border-bottom: 2px solid;" class="px-4 d-inline-block">
                {{levelThreeData.data.name}}
                </p>
              </div>
  
              <div class="w-100 mt-4">
                <img class="px-3 img-fluid" src="{{levelThreeData.data.image}}" onerror="src='../../assets/images/carosel-icons/no-image.png'">
              </div>
  
                 <p style="color: #8D0528 ;" class="my-3 mx-3 font-weight-bold">Product Information</p>
                <table class="mx-3">
                  <tr>
                    <th style="width: 0.5%;">
                      <p>Dimension:</p>
                    </th>
                    <td style="width: 1%;">
                    <p>{{levelThreeData.data.Dimension}}</p>
                    </td>
                  </tr>
  
                  <tr>
                    <th style="width: 0.5%;">
                      <p>SKU Code</p>
                    </th>
                    <td style="width: 1%;">
                    <p class="numbers">{{levelThreeData.data.sku_code}}</p>
                    </td>
                  </tr>
  
                  <tr>
                      <th style="width: 0.5%;">
                      <p>Material:</p>
                      </th>
                    <td style="width: 1%;">
                      <p>{{levelThreeData.data.meterial}}</p>
                    </td>
                  </tr>
  
                  <tr>
                    <th  style="width: 0.5%;"> <p>Finish:</p>  </th>
                    <td style="width: 1%;"><p>{{levelThreeData.data.finish}}</p></td>
                  </tr>
  
                  <tr>
                    <th  style="width: 0.5%;"> <p>Bed Fittings:</p>  </th>
                    <td style="width: 1%;"><p>{{levelThreeData.data.bed_fitting}}</p></td>
                  </tr>
  
                  <tr>
                    <th  style="width: 0.5%;"> <p>Colour:</p>  </th>
                    <td style="width: 1%;"><p>{{levelThreeData.data.color}}</p></td>
                  </tr>
  
                  <tr>
                    <th  style="width: 0.5%;"> <p>Measurment Unit:</p>  </th>
                    <td style="width: 1%;"><p>{{levelThreeData.data.measurement_unit}}</p></td>
                  </tr>
  
                  <tr>
                    <th  style="width: 0.5%;"> <p>Configuration:</p>  </th>
                    <td style="width: 1%;"><p>{{levelThreeData.data.product_configuration}}</p></td>
                  </tr>
  
  
                  <tr>
                    <th  style="width: 0.5%;"> <p>Fabric:</p>  </th>
                    <td style="width: 1%;"><p>{{levelThreeData.data.fabrics}}</p></td>
                  </tr>
  
                  <tr>
                    <th  style="width: 0.5%;"> <p>Quantity:</p>  </th>
                    <td style="width: 1%;"><p>{{levelThreeData.data.quantity}}</p></td>
                  </tr>
  
                  <tr>
                    <th  style="width: 0.5%;"> <p>Warranty:</p>  </th>
                    <td style="width: 1%;"><p>{{levelThreeData.data.warranty}}</p></td>
                  </tr>
  
                  <tr>
                    <th  style="width: 0.5%;"> <p>Total Price:</p>  </th>
                    <td style="width: 1%;"><p class="numbers">{{levelThreeData.data.total_price  | currency :'INR':'symbol':'1.0-0'}}</p></td>
                  </tr>
                </table>
            </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade pr-0" id="promoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title" id="exampleModalLabel">Available Offers</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
                <ng-container>
                  <div class="row mb-lg-2">
                    <div class="col-lg-4 col-12 mb-lg-2 mb-2" *ngFor="let promo of promoId ; let i = index">
                      <div class="card h-100" >
                        <div class="card-body">                  
                         
                          <div class="row justify-content-between">
                            <p  class=" col-5 mb-0 headings">Promo Code:</p>
                            <p ngbTooltip="{{promo.code}}" placement ="bottom" class="col-5 d-flex justify-content-end font-weight-bold">{{promo.code | slice:0:13}}
                              <span *ngIf ="promo.code && promo.code.length >= 13">...</span>
                            </p>
                          </div>
              
                          <div class="d-flex justify-content-between">
                            <p class="headings">Promo Value:</p>
                            <p class="card-text">{{promo.promo_display_value}}</p>
                          </div>
      
                          <div class="row">
                            <p class=" col-6 headings">Promo Expiry Date:</p>
                            <div class="col-6">
                              <div class="row justify-content-end">
                                <p class=" col-12 card-text d-flex justify-content-end mb-0 numbers">{{ promo.expiry_date === null ? '---' :  promo.expiry_date | date: "dd-MM-YYYY" }} <br>
                                </p>
                                <!-- <p *ngIf ="promo.expiry_date" style="white-space:nowrap; font-size: 14px;" class="col-12 d-flex justify-content-end mb-0 text-capitalize">({{promo.remaining_days}})</p> -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>              
  
                
                <div *ngIf = "promoId.length === 0" class="d-flex justify-content-center">
                  <!-- <p class="text-center">No Promo Codes Available</p> -->
                  <img class="img-fluid" src="../../assets/images/desktop.png">
                </div>
        </div>
  
      </div>
    </div>
  </div>
  
  <div class="modal fade pr-0" id="ViewMoreModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title" id="exampleModalLabel">Discount Applied</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <ng-container>
              <div class="row mb-lg-2">
                <div class="col-lg-4 col-12 mb-lg-0 mb-2" *ngFor="let promo of tableBreakUp.quote_type_caluclation[0].promo_applied ; let i = index">
                  <div class="card h-100" >
                    <div class="card-body">                  
                     
                      <div class="row justify-content-between">
                        <p  class=" col-5 mb-0 headings">Promo Code:</p>
                        <p ngbTooltip="{{promo.code}}" placement ="bottom" class="col-5 d-flex justify-content-end font-weight-bold">{{promo.code | slice:0:13}}
                          <span *ngIf ="promo.code && promo.code.length >= 13">...</span>
                        </p>
                      </div>
          
                      <div class="d-flex justify-content-between">
                        <p class="headings">Promo Value:</p>
                        <p class="card-text">{{promo.promo_value}}</p>
                      </div>
  
                      <div class="row">
                        <p class=" col-6 headings">Promo Expiry Date:</p>
                        <div class="col-6">
                          <div class="row justify-content-end">
                            <p class=" col-12 card-text d-flex justify-content-end mb-0 numbers">{{ promo.expiry_date === null ? '---' :  promo.expiry_date}} <br>
                            </p>
                            <p *ngIf ="promo.expiry_date" style="white-space:nowrap; font-size: 14px;" class="col-12 d-flex justify-content-end mb-0 text-capitalize numbers">({{promo.remaining_days}})</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
        </div>
  
      </div>
    </div>
  </div>
  
  <div class="loading d-flex justify-content-center" *ngIf="loader">
    <div class="spinner-border" style="color: #8d0528" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>