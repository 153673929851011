<div class="row alerts">
    <div  class="rk-ui-notification"  style="height: 20px !important"  *ngIf="successalert"  style="z-index: 1000000 !important">
      <span id="notificationMessageText" style="margin: auto">{{   successMessage }}</span>
       <a class="close rk-linknotification" style="color: white" (click)="successalert = false" >×</a>
    </div>
    <div class="rk-ui-notification err" *ngIf="erroralert" style="background-color:#8d0527">
      <span id="notificationMessageText">{{ errorMessage }}</span>
      <a class="close rk-linknotification" style="color: white" (click)="erroralert = false" >×</a>
    </div>
</div>
<div class="" style="margin-top:4%;">
    <div class="header row justify-content-center mx-0">
        <img class="cursorPointer" routerLink="/" src="../../../assets/images/logoarrivae logo.svg" alt="">
    </div>
    <div class="banner">
        <img class=" d-none d-md-block" src="../../assets/images/promoterBanner.png" alt="">
        <img class="d-md-none" src="../../assets/images/promoter-mobile.png" alt="">

    </div>
    <div class="detailtable  pt-3  m-4 m-md-0 ">
        <div class="row  justify-content-center py-md-5 mt-2 pb-3 pt-0 mx-0"  >
            <h2 class="text-center">JOIN THE ARRIVAE PROMOTER PROGRAM</h2>
        </div>
        <div class="table d-none d-md-block">
            <table>
                <thead>
                    <tr>
                        <th>TASKS</th>
                        <th>FREQUENCY</th>
                        <th style="width:440px">PROCESS</th>
                        <th>REWARD (IN INR)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Society Group WhatsApp Message</td>
                        <td>Once a Week</td>
                        <td>
                            <ul>
                                <li>1) Post request to be shared with Arrivae</li>
                                <li>2) Arrivae to share the content and creative</li>
                                <li>3) Evangelist to post on group and share screenshot with Project Head</li>
                            </ul>
                        </td>                        
                        <td class="numbers">₹ 500</td>
                    </tr>
                    <tr class="even">
                        <td>Testimonial Video</td>
                        <td>One time only</td>
                        <td> 
                            <ul>
                                <li>1) Evangelist to inform Project head on date and time</li>
                                <li>2) Project head to align shoot with marketing team</li>
                                <li>3) Marketing team to share flow of questions and shoot with Evangelist</li>
                            </ul>
                        </td>
                        <td class="numbers">₹ 2000</td>
                    </tr>
                    <tr>
                        <td>Home visits for Potential 
                            Arrivae Customers</td>
                        <td>NA</td>
                        <td>
                            <ul>
                                <li>1) Project head to ask Evangelist for Availability</li>
                                <li>2) Project head to align client visits as per availability</li>
                                <li>3) Evangelist to give a walkthrough of house and share his / her experience with Arrivae</li>
                            </ul>    
                        </td>                        
                        <td class="numbers">₹ 1500</td>
                    </tr>
                    <tr class="even">
                        <td>Google Review</td>
                        <td>Once</td>
                        <td>
                            <ul>
                                <li>1) Project head to share the link for reviewing Arrivae</li>
                                <li>2) Evangelist to post his/her experience on Google and share the screenshot with the project head</li>
                            </ul>    
                        </td>
                        
                        <td class="numbers">₹ 500</td>
                    </tr>
                    <tr>
                        <td>For Every Referral Lead Meeting done</td>
                        <td>NA</td>
                        <td>
                            <ul>
                                <li>1) Evangelist to share details of referral visiting the showflat</li>
                                <li>2) Project head to capture details and tag against Evangelist</li>
                            </ul>
                        </td>
                        <td class="numbers">₹ 1000</td>
                    </tr>
                    <tr class="even">
                        <td>Every Successful referral lead conversion</td>
                        <td>NA</td>
                        <td>
                            <ul>
                                <li style="word-wrap: break-word;">1) Evangelist to share the details of the referral through this link - <a href="https://web.arrivae.com/refer">https://web.arrivae.com/refer</a></li>
                                <li>2) 2% Reward to be paid to Evangelist once the referral pays 50% amount of the project value</li>
                            </ul>
                        </td>
                        <td>2% of Net Project Value</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="table  d-md-none ">
            <h3 class="py-3 text-center">TASKS</h3>

            <div class="tasks p-0 mx-0" id="society" (click)="toggledetails('society')">
                <h2 class="px-3 py-2 d-flex justify-content-between align-items-center row m-0 " style="border-radius: 20px;">
                    <span class="col-11 text-center ">Society Group WhatsApp Message</span>
                    <i class="fa" [ngClass]="id=='society'?'fa-angle-up':'fa-angle-down'" style="font-size: 24px;"></i>
                </h2>
                <div class="details px-3" [ngClass]="id=='society'?'d-block':'d-none'">
                    <div class="row px-0 mx-1 pt-2" style="border-bottom: 1px solid black;">
                        <p class="col-4  label">Frequency</p>
                        <p class="col-8 content">Once a Week</p>
                    </div>
                    <div class="row px-0 mx-1 pt-2" style="border-bottom: 1px solid black;">    
                        <p class="col-4  label">Process</p>
                        <ul class="col-8 content" style="list-style: none;">
                            <li>1. Post request to be shared with Arrivae</li>
                            <li>2. Arrivae to share the content and creative</li>
                            <li>3. Evangelist to post on group and share screenshot with Project Head</li>
                        </ul>
                    </div>
                    <div class="row px-0 mx-1 pt-2" >
                        <p class="col-4  label">Reward (In INR)</p>
                        <p class="col-8  numbers">₹ 500</p>
                    </div>
                </div>

            </div>
            <div class="tasks p-0 mx-0" id="testimonial" (click)="toggledetails('testimonial')">
                <h2 class="px-3 py-2 d-flex justify-content-between align-items-center row m-0 " style="border-radius: 20px;">
                    <span class="col-11 p-0  text-center ">Testimonial Video</span>
                    <i class="fa col-1 p-0" [ngClass]="id=='testimonial'?'fa-angle-up':'fa-angle-down'" style="font-size: 24px;"></i></h2>
                <div class="details px-3" [ngClass]="id=='testimonial'?'d-block':'d-none'">
                    <div class="row px-0 mx-1 pt-2" style="border-bottom: 1px solid black;">
                        <p class="col-4  label">Frequency</p>
                        <p class="col-8 content">One time only</p>
                    </div>
                    <div class="row px-0 mx-1 pt-2" style="border-bottom: 1px solid black;">    
                        <p class="col-4  label">Process</p>
                        <ul class="col-8 content" style="list-style: none;">
                            <li>1. Evangelist to inform Project head on date and time</li>
                            <li>2. Project head to align shoot with marketing team</li>
                            <li>3. Marketing team to share flow of questions and shoot with Evangelist</li>
                        </ul>
                    </div>
                    <div class="row px-0 mx-1 pt-2" >
                        <p class="col-4  label">Reward (In INR)</p>
                        <p class="col-8  numbers">₹ 2000</p>
                    </div>
                </div>

            </div>
            <div class="tasks p-0 mx-0" id="visits" (click)="toggledetails('visits')">
                <h2 class="px-3 py-2 d-flex justify-content-between align-items-center row m-0 " style="border-radius: 20px;">
                    <span class="col-11 p-0 text-center ">Home visits for Potential Arrivae Customers</span>
                    <i class="fa col-1 p-0" [ngClass]="id=='visits'?'fa-angle-up':'fa-angle-down'" style="font-size: 24px;"></i></h2>
                <div class="details px-3" [ngClass]="id=='visits'?'d-block':'d-none'">
                    <div class="row px-0 mx-1 pt-2" style="border-bottom: 1px solid black;">
                        <p class="col-4   label">Frequency</p>
                        <p class="col-8 content">NA</p>
                    </div>
                    <div class="row px-0 mx-1 pt-2" style="border-bottom: 1px solid black;">    
                        <p class="col-4  label">Process</p>
                        <ul class="col-8 content" style="list-style: none;">
                            <li>1. Project head to ask Evangelist for Availability</li>
                            <li>2. Project head to align client visits as per availability</li>
                            <li>3. Evangelist to give a walkthrough of house and share his / her experience with Arrivae</li>
                        </ul>
                    </div>
                    <div class="row px-0 mx-1 pt-2" >
                        <p class="col-4  label">Reward (In INR)</p>
                        <p class="col-8  numbers">₹ 1500</p>
                    </div>
                </div>

            </div>
            <div class="tasks p-0 mx-0" id="Review" (click)="toggledetails('Review')">
                <h2 class="px-3 py-2 d-flex justify-content-between align-items-center row m-0 " style="border-radius: 20px;">
                    <span class="col-11 p-0  text-center ">Google Review</span>
                    <i class="fa col-1 p-0" [ngClass]="id=='Review'?'fa-angle-up':'fa-angle-down'" style="font-size: 24px;"></i></h2>
                <div class="details px-3" [ngClass]="id=='Review'?'d-block':'d-none'">
                    <div class="row px-0 mx-1 pt-2" style="border-bottom: 1px solid black;">
                        <p class="col-4 label">Frequency</p>
                        <p class="col-8 content">Once</p>
                    </div>
                    <div class="row px-0 mx-1 pt-2" style="border-bottom: 1px solid black;">    
                        <p class="col-4 label">Process</p>
                        <ul class="col-8 content" style="list-style: none;">
                            <li>1. Project head to share the link for reviewing Arrivae</li>
                            <li>2. Evangelist to post his/her experience on Google and share the screenshot with the project head</li>
                        </ul>
                    </div>
                    <div class="row px-0 mx-1 pt-2" >
                        <p class="col-4 label">Reward (In INR)</p>
                        <p class="col-8  numbers">₹ 500</p>
                    </div>
                </div>

            </div>
            <div class="tasks p-0 mx-0" id="Meeting" (click)="toggledetails('Meeting')">
                <h2 class="px-3 py-2 d-flex justify-content-between align-items-center row m-0 " style="border-radius: 20px;">
                    <span class="col-11 text-center ">For Every Referral Lead Meeting done</span>
                    <i class="fa col-1 p-0" [ngClass]="id=='Meeting'?'fa-angle-up':'fa-angle-down'" style="font-size: 24px;"></i></h2>
                <div class="details px-3" [ngClass]="id=='Meeting'?'d-block':'d-none'">
                    <div class="row px-0 mx-1 pt-2" style="border-bottom: 1px solid black;">
                        <p class="col-4 label ">Frequency</p>
                        <p class="col-8 content">NA</p>
                    </div>
                    <div class="row px-0 mx-1 pt-2" style="border-bottom: 1px solid black;">    
                        <p class="col-4 label">Process</p>
                        <ul class="col-8 content" style="list-style: none;">
                            <li>1. Evangelist to share details of referral visiting the showflat</li>
                            <li>2. Project head to capture details and tag against Evangelist</li>
                        </ul>
                    </div>
                    <div class="row px-0 mx-1 pt-2" >
                        <p class="col-4 label">Reward (In INR)</p>
                        <p class="col-8  numbers">₹ 1000</p>
                    </div>
                </div>

            </div>
            <div class="tasks p-0 mx-0" id="referal" (click)="toggledetails('referal')">
                <h2 class="px-3 py-2 d-flex justify-content-between align-items-center row m-0 " style="border-radius: 20px;">
                    <span class="col-11 p-0  text-center ">For Every Referral Lead Meeting done</span>
                    <i class="fa col-1 p-0" [ngClass]="id=='referal'?'fa-angle-up':'fa-angle-down'" style="font-size: 24px;"></i></h2>
                <div class="details px-3" [ngClass]="id=='referal'?'d-block':'d-none'">
                    <div class="row px-0 mx-1 pt-2" style="border-bottom: 1px solid black;">
                        <p class="col-4 label">Frequency</p>
                        <p class="col-8 content">NA</p>
                    </div>
                    <div class="row px-0 mx-1 pt-2" style="border-bottom: 1px solid black;">    
                        <p class="col-4 label">Process</p>
                        <ul class="col-8 content content" style="list-style: none;">
                            <li style="word-wrap: break-word;">1. Evangelist to share the details of the referral through this link - <a href="https://web.arrivae.com/refer">https://web.arrivae.com/refer</a></li>
                            <li>2. 2% Reward to be paid to Evangelist once the referral pays 50% amount of the project value</li>
                        </ul>
                    </div>
                    <div class="row px-0 mx-1 pt-2" >
                        <p class="col-4 label">Reward (In INR)</p>
                        <p class="col-8 content "> 2% of Net Project Value</p>
                    </div>
                </div>

            </div>


        </div>
    </div>
    
    <div class="promoterform p-md-5 ">
        <div class="m-md-4">
        <div class="row p-md-5  p-3 m-0" style="width: 100%;">
            <div class="row p-0 shadowbox" style="margin: auto;">
            <div class="col-6 d-none d-md-block p-0" style="width: 100%; height:100%; " >
                <img src="../../assets/images/Rectangle 197.png" alt="" style="width: 100%;">
            </div>
            <div class="col-md-6 col-12 d-flex flex-column form justify-content-center p-md-5 " >
                <img class="align-self-center d-none d-md-block" src="../../assets/images/DELTA ICON 1.png" alt="" >
                <div class=" d-flex flex-column mx-md-5" style="">
                    <form [formGroup]="registrationForm" >
                        <div class="d-flex flex-column form2">
                          <input type="text" placeholder="Promoter Name" formControlName="name">
                          <div *ngIf="registrationForm.get('name')?.invalid && registrationForm.get('name')?.touched" class="text-danger pl-3">
                            Promoter Name is required.
                          </div>
                      
                          <input type="email" placeholder="Email ID" formControlName="email">
                          <div *ngIf="registrationForm.get('email')?.invalid && registrationForm.get('email')?.touched" class="text-danger pl-3">
                            <div *ngIf="registrationForm.get('email')?.errors?.required">Email is required.</div>
                            <div *ngIf="registrationForm.get('email')?.errors?.email">Please enter a valid email address.</div>
                          </div>
                      
                          <input type="number" oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" placeholder="Phone Number" formControlName="contact" >
                          <span *ngIf="registrationForm.get('contact')?.invalid && (registrationForm.get('contact')?.dirty || registrationForm.get('contact')?.touched)" class="text-danger pl-3">Please enter a valid 10-digit phone number.</span>


                          <div class="select p-1 pr-2">
                          <select name="cities" formControlName="city_id" class="m-0" id="city" style="border:none;height: 45px;" [ngStyle]="{'color': registrationForm.get('city_id')?.value === '' ? 'grey' : 'black'}">
                            <option value="" disabled >Select City</option>
                            <option value="{{city.attributes.id }}" *ngFor="let city of cities">{{city.attributes.name | replaceChar }}</option>
                          </select>
                          </div>
                          <div *ngIf="registrationForm.get('city_id')?.invalid && registrationForm.get('city_id')?.touched" class="text-danger pl-3"></div>


                      
                          <button type="submit" class="btn btn-pink mt-3" (click)="submitform()"[disabled]="registrationForm.invalid" >Register Now</button>
                        </div>
                      </form>
  

                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    <div class="footer py-5 mt-md-0 mt-3 text-center">
        <div class="py-3">
            <a href="https://www.facebook.com/Arrivae/" target="_blank">
                <img class="mr-2" src="../../../assets/images/arrivaeHomePageImages/fb.png"/>
            </a>
            <a target="_blank" href="https://in.linkedin.com/company/arrivae">
                <img class="mr-2" src="../../../assets/images/arrivaeHomePageImages/Linkedin.png"/>
            </a>
            <a target="_blank" href="https://www.instagram.com/arrivae/?hl=en" >
                <img class="mr-2" src="../../../assets/images/arrivaeHomePageImages/Ijnsta.png"/>
            </a>
            <a target="_blank" href="https://twitter.com/ModSpaceIN" >
                <img class="mr-2" src="../../../assets/images/arrivaeHomePageImages/Twitter.png"/>
            </a>
        </div>  
        <p>©Copyright. All right reserved</p>

    </div>
</div>