<app-new-header-for-home (sendData)="customFunc($event)" [data]="namephn" [verifyUser]="boolvalue"
  [l_lead_invitation]="leadInvitationId" [l_lead_id]="l_lead_id"></app-new-header-for-home>
<div class="root mt-5">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="row rootdiv">
          <div class="col-lg-5">
            <div id="banner-image" class="banner-image">
              <div class="intro-container h-100 d-flex px-lg-0 py-lg-0 px-4 py-4">
                <div id="intro-text" class="intro-text">
                  <p class="heading-black">Made-By-You Stories</p>
                  <p class="heading-red">
                    How we created homes by personalising dreams.
                  </p>
                  <div class="each-clients d-md-block d-none">
                    <div class="client-img-re">
                      <div class="ab-img">
                        <img src="../../../assets/images/Landing_page_updated/Design-with-us/Rectangle 54.png" />
                      </div>
                    </div>
                  </div>
                  <div class="text-container d-md-block d-none" *ngFor="let dat of custData">
                    <p class="user-name">{{ dat.heading }}</p>
                    <p class="usercity">{{ dat.city }}</p>
                    <p class="reviews">{{ dat.content }}</p>
                    <!-- <button type="button" (click)="madebyyouModal()" data-bs-toggle="modal" data-bs-target="#madebyyouModal">View
                                            Story</button> -->
                    <button type="button" (click)="madebyyouModal()" data-bs-toggle="modal"
                      data-bs-target="#madebyyouModal">
                      View Story
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="gallery-container col-lg-7 px-0">
            <div class="your-class">
              <ng-container *ngFor="let item of landing_images">
                <div>
                  <img [src]="item.image" class="d-block w-100 main-images" />
                </div>
              </ng-container>
            </div>
            <button id="previous-gallery-button" class="previous-gallery-button">
              <img class="img-fluid" src="../../../assets/images/Landing_page_updated/left_arrow.svg" />
            </button>
            <button id="next-gallery-button" class="next-gallery-button">
              <img class="img-fluid" src="../../../assets/images/Landing_page_updated/right_arrow.svg" />
            </button>
          </div>
        </div>
        <div class="row">
          <div class="h-100 d-flex d-sm-block d-md-none">
            <div id="intro-text" class="intro-text">
              <div class="each-clients">
                <div class="client-img-re">
                  <div class="ab-img">
                    <img src="../../../assets/images/Landing_page_updated/Design-with-us/Rectangle 54.png" />
                  </div>
                </div>
              </div>
              <div class="text-container" *ngFor="let dat of custData">
                <p class="username-resp">{{ dat.heading }}</p>
                <p class="usercity-resp">{{ dat.city }}</p>
                <p class="reviews-resp">{{ dat.content }}</p>
                <button class="mt-3" (click)="madebyyouModal()">
                  View Story
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Made By You Modal Starts -->
        <div class="modal modal-made-by-you" id="madebyyouModal" aria-labelledby="madebyyouModalLabel" tabindex="-1"
          aria-hidden="true">
          <div class="modal-dialog made-by-you-wrapper">
            <div id="made-by-you-container" class="modal-content made-by-you-container">
              <div class="col-md-12 px-0">
                <div id="scrollId" class="contents">
                  <div
                    class="made-by-you-header my-4 px-lg-2 d-md-block d-none"
                  >
                    <img
                      class="arrow-left-icon arrow-left-icon_cursor px-3"
                      src="../../../assets/images/icons/arrow left.svg"
                      data-dismiss="modal"
                      aria-label="Close"
                      alt="arrow left"
                      (click)="madebyyouModal()"
                    />
                    <span
                      class="button backtoall arrow-left-icon_cursor"
                      data-dismiss="modal"
                      aria-label="Close" (click)="madebyyouModal()"
                      >Back To All Stories</span
                    >
                  </div>
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="heading d-md-block px-lg-2 d-none">
                          <p class="mt-4 modaluserName">
                            {{ ViewNextContent[ViewNextContentIndex].heading }}
                          </p>
                          <p class="modaluserCity">
                            {{ ViewNextContent[ViewNextContentIndex].city }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="d-sm-block d-md-none userdetailsModal">
                      <div class="row">
                        <div class="col-4 made-by-you-header" style="margin: 1.2rem 0 0rem 0rem">
                          <img class="arrow-left-icon arrow-left-icon_cursor px-2" data-dismiss="modal" aria-label="Close"
                            src="../../../assets/images/icons/arrow left.svg" alt="arrow left" (click)="madebyyouModal()" />
                          <span class="backtoall button arrow-left-icon_cursor" data-dismiss="modal" aria-label="Close" (click)="madebyyouModal()">Back</span>
                        </div>
                        <div *ngFor="let dat of custData">
                          <p class="username-modal">
                            {{ ViewNextContent[ViewNextContentIndex].heading }}
                          </p>
                          <p class="usercity-modal">
                            {{ ViewNextContent[ViewNextContentIndex].city }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="row p-lg-3">
                      <div class="carousel-container col-lg-8 px-lg-2 px-0">
                        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel"
                          style="overflow: hidden">
                          <div *ngFor="let image of image_container; index as i" class="carousel-item"
                            [ngClass]="i == 0 ? 'active' : ''">
                            <img [src]="image" [alt]="image.alt" class="d-block w-100 carousel-images" />
                          </div>
                          <a class="carousel-images-prev" href="#carouselExampleControls" role="button"
                            data-slide="prev" style="width: 7%; opacity: 1.5">
                            <button id="previous-carousel-button" class="previous-carousel-button">
                              <img class="img-fluid" src="../../../assets/images/Landing_page_updated/left_arrow.svg" />
                            </button>
                            <span class="" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                          </a>
                          <a class="carousel-images-next" href="#carouselExampleControls" role="button"
                            data-slide="next" style="width: 9%; opacity: 1.5">
                            <button id="next-carousel-button" class="next-carousel-button">
                              <img class="img-fluid"
                                src="../../../assets/images/Landing_page_updated/right_arrow.svg" />
                            </button>
                            <span class="" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                          </a>
                        </div>
                      </div>

                      <div class="col-lg-4 reviewscontainer">
                        <div class="col-lg-11 px-0">
                          <p class="px-lg-2 mt-4 mt-lg-0 userreviews">
                            <span>
                              <img src="../../../assets/images/Landing_page_updated/right quotes.png"
                                class="right-quotes" /> </span>{{ ViewNextContent[ViewNextContentIndex].title }}
                            <span>
                              <img src="../../../assets/images/Landing_page_updated/left quotes.png"
                                class="left-quotes" />
                            </span>
                          </p>
                        </div>
                        <hr class="col-3 ml-2" />
                        <p class="username px-2">
                          {{ ViewNextContent[ViewNextContentIndex].header }}
                        </p>
                        <div class="col-lg-11 px-0">
                          <p class="userintro px-2">
                            {{ ViewNextContent[ViewNextContentIndex].content }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="row flexDisplay mt-lg-5 mt-3 mb-5">
                      <div class="col-lg-4 somethingNew">
                        <p class="startnew px-2">
                          {{
                          ViewNextContent[ViewNextContentIndex].somethingNew
                          }}
                        </p>
                        <div class="px-2">
                          <p class="startNewpara">
                            {{
                            ViewNextContent[ViewNextContentIndex].contentNew1
                            }}
                          </p>
                          <p class="startNewpara">
                            {{
                            ViewNextContent[ViewNextContentIndex].contentNew2
                            }}
                          </p>
                        </div>
                      </div>
                      <div class="images-container col-lg-7 px-lg-0 px-0 mb-4 mr-n2">
                        <div id="carouselExampleControl" class="carousel slide" data-ride="carousel"
                          style="overflow: hidden">
                          <div *ngFor="let image of image_container; index as i" class="carousel-item"
                            [ngClass]="i == 0 ? 'active' : ''">
                            <img [src]="image" [alt]="image.alt" style="width: 100%; height: 435px" />
                          </div>
                          <a class="carousel-images-prev" href="#carouselExampleControl" role="button" data-slide="prev"
                            style="width: 7%; opacity: 1.5">
                            <button id="previous-images-button" class="previous-images-button">
                              <img class="img-fluid" src="../../../assets/images/Landing_page_updated/left_arrow.svg" />
                            </button>
                            <span class="" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                          </a>
                          <a class="carousel-images-next" href="#carouselExampleControl" role="button" data-slide="next"
                            style="width: 9%; opacity: 1.5">
                            <button id="next-images-button" class="next-images-button">
                              <img class="img-fluid"
                                src="../../../assets/images/Landing_page_updated/right_arrow.svg" />
                            </button>
                            <span class="" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="how-it-works-wrapper">
                      <div class="carousel-container">
                        <div class="carousel" id="main-carousel" data-ride="carousel" data-interval="3000">
                          <ol class="carousel-indicators ">
                            <li data-target="#main-carousel" data-slide-to="0" class="active button sort firstWidth mb-0 ml-n3">
                              <p class="title2">Kitchen</p>
                            </li>
                            <li data-target="#main-carousel" data-slide-to="1" class="button sort secondWidth mb-0">
                              <p class="title2">Master Bedroom</p>
                            </li>
                            <li data-target="#main-carousel" data-slide-to="2" class="button sort thirdWidth mb-0">
                              <p class="title2">Living Room</p>
                            </li>
                          </ol>
                    
                          <div class="carousel-inner px-0">
                            <div *ngFor="let image of image_container; index as i" class="carousel-item" [ngClass]="i==0?'active' : ''" data-interval="3000">
                              <div class="carousel-content">
                                <img [src]="image" [alt]="image.alt" class="img-fluid" style="width: 100%" />
                              </div>
                              <a class="carousel-control-prev-img" href="#main-carousel" role="button"
                              data-slide="prev">
                              <button id="previous-right-button" class="previous-right-button">
                                <img class="img-fluid"
                                  src="../../../assets/images/Landing_page_updated/left_arrow.svg" />
                              </button>
                              <span class="" aria-hidden="true"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next-img" href="#main-carousel" role="button"
                              data-slide="next">
                              <button id="next-right-button" class="next-right-button">
                                <img class="img-fluid"
                                  src="../../../assets/images/Landing_page_updated/right_arrow.svg" />
                              </button>
                              <span class="" aria-hidden="true"></span>
                              <span class="sr-only">Next</span>
                            </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 px-lg-4">
                        <hr class="hrtag" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="pt-lg-4 mb-3 mt-3 mt-lg-0 px-1 pb-4 pb-lg-0 px-lg-4">
                      <!--pt-4 -->
                      <div class="inclusion-modal-prev-next">
                        <div class="root-pre-next ml-xl-n3">
                          <div id="prevNextBtn" class="d-flex cursorPointer" (click)="prevSlider()">
                            <ng-container *ngIf="ViewNextContentIndex > 0">
                              <div class="align-self-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                  style="color: #8d0528" class="bi bi-arrow-left-circle mr-2" viewBox="0 0 16 16">
                                  <path fill-rule="evenodd"
                                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                                </svg>
                                <img class="d-lg-inline d-none mr-2 d-md-block d-none"
                                  src="../../../assets/images/Kids Room.png" width="70px" height="40px" />
                              </div>
                              <div>
                                <a class="mb-0 pre-icon">Previous Story</a>
                                <a class="mb-0 apart-name d-md-block d-none">
                                  {{ ViewNextContentPreviousName }}
                                </a>
                              </div>
                            </ng-container>
                          </div>
                          <div id="prevNextBtn" *ngIf="(ViewNextContent.length-1) > ViewNextContentIndex" class="d-flex cursorPointer mr-xl-n3" (click)="nextSlider()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                              style="color: #8d0528" fill="currentColor"
                              class="bi bi-arrow-right-circle align-self-center mr-2 d-md-block d-none"
                              viewBox="0 0 16 16">
                              <path fill-rule="evenodd"
                                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                            </svg>
                            <div class="align-self-center">
                              <img class="d-lg-inline d-none mr-2 d-md-block d-none"
                                src="../../../assets/images/Cozy Bedroom.png" width="70px" height="40px" />
                            </div>
                            <div>
                              <a class="mb-0 pre-icon mr-2">Next Story</a>
                              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                style="color: #8d0528" fill="currentColor"
                                class="bi bi-arrow-right-circle align-self-center mr-2 d-sm-block d-md-none"
                                viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                              </svg>
                              <a class="mb-0 apart-name d-md-block d-none">
                                {{ ViewNextContentNextName }}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--Made By You Modal Ends-->

        <div id="banner-image" class="banner-image">
          <div class="stats-panel" id="stats-panel">
            <div class="row">
              <div class="col-7 col-lg-3 stats">
                <div class="icon">
                  <img src="../../../assets/images/Landing_page_updated/stats_icons/calender.svg" alt="calender" />
                </div>
                <div class="stats-text">
                  <p class="bold-heading">
                    <span class="numberCountUp">309</span>
                  </p>
                  <p>
                    Completed <br class="d-sm-block d-md-none" />
                    Last month
                  </p>
                </div>
              </div>
              <div class="col-5 col-lg-2 stats">
                <div class="icon">
                  <img src="../../../assets/images/Landing_page_updated/stats_icons/home.svg" alt="home" />
                </div>
                <div class="stats-text">
                  <p class="bold-heading">
                    <span class="numberCountUp">13,500</span> +
                  </p>
                  <p>Spaces Delivered</p>
                </div>
              </div>
              <div class="col-7 col-lg-3 stats">
                <div class="icon">
                  <img src="../../../assets/images/Landing_page_updated/stats_icons/notepad.svg" alt="notepad" />
                </div>
                <div class="stats-text">
                  <p class="bold-heading">
                    <span class="numberCountUp">10</span> L+
                  </p>
                  <p>Design</p>
                </div>
              </div>
              <div class="col-5 col-lg-2 stats">
                <div class="icon">
                  <img src="../../../assets/images/Landing_page_updated/stats_icons/glob.svg" alt="globe" />
                </div>
                <div class="stats-text">
                  <p class="bold-heading">
                    <span class="numberCountUp">15</span>
                  </p>
                  <p>Cities</p>
                </div>
              </div>
              <div class="col-4 col-lg-2 scroll">
                <a (click)="scrollToSummary(cardContainer)" class="d-flex scroll-text">
                  <img src="../../../assets/images/icons/scroll.svg" alt="scroll" />
                  <p class="m-0 ml-1">Scroll</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 cardContainer" #cardContainer>
          <div class="row">
            <div class="col-md-12 mt-1">
              <div class="row">
                <div class="customPointer col-md-6 col-lg-6 col-sm-12 mt-0 mb-0 px-0 px-lg-3"
                  *ngFor="let items of ViewContent; let i = index">
                    <div id="{{ 'carouselExampleIndicators' + i }}" class="carousel slide" data-ride="carousel"
                      data-touch="true" data-interval="3000">
                      <ol class="carousel-indicators carouselimages">
                        <li [attr.data-target]="'#carouselExampleIndicators' + i" class="indicator active"
                          data-slide-to="0"></li>
                        <li [attr.data-target]="'#carouselExampleIndicators' + i" class="indicator" data-slide-to="1">
                        </li>
                        <li [attr.data-target]="'#carouselExampleIndicators' + i" class="indicator" data-slide-to="2">
                        </li>
                      </ol>
                      <a (click)="routeToDetialsPageHandle(items)" routerLinkActive="active">
                      <div class="carousel-inner">
                        <div *ngFor="let image of items.images; let j = index" class="carousel-item"
                          [ngClass]="j == 0 ? 'active' : ''">
                          <img class="d-block w-100" [src]="image" />
                        </div>
                      </div>
                      </a>
                    </div>
                    <a (click)="routeToDetialsPageHandle(items)" routerLinkActive="active">
                    <div class="body cardbody py-3 px-4 px-lg-0">
                      <h5 class="title">{{ items.heading }}</h5>
                      <h6 class="card-city">{{ items.city }}</h6>
                      <p class="text">{{ items.content }}</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-3">
          <div class="stories-root">
            <div id="stories-updatedd" class="carousel slide" data-ride="carousel">
              <div class="carousel-inner stories-updated">
                <ol class="carousel-indicators" id="stopvideo">
                  <li data-target="#stories-updatedd" data-slide-to="0" class="active" (click)="checkVideo()"></li>
                  <li data-target="#stories-updatedd" data-slide-to="1" (click)="checkVideo()"></li>
                  <li data-target="#stories-updatedd" data-slide-to="2" (click)="checkVideo()"></li>
                </ol>

                <div class="carousel-item active">
                  <div class="row">
                    <div class="col-lg-7">
                      <div style="position: relative">
                        <iframe class="video-player" src="https://www.youtube.com/embed/xmqEsT2fXIw"
                          class="w-100 img-fluid" frameborder="0"></iframe>
                      </div>
                    </div>
                    <div class="col-lg-5" style="position: relative">
                      <div class="row flex-column justify-content-center mobile-space">
                        <div class="col-12 d-lg-block d-none">
                          <p class="made-by-you">Made-By-You Stories</p>
                        </div>
                        <div class="col-12">
                          <p class="user-name mb-0">Naveen Mohan</p>
                          <p class="user-city">Trivandrum, Kerala</p>
                        </div>
                        <div class="col-12 mb-3 mb-lg-0">
                          <p class="user-content">
                            We go 100% design support from the team right from
                            the wardrobes to the shelves. I was facing an issue
                            on the day of the house warming. So I called up the
                            Project Manager at 12.30am. He came early morning,
                            resolved the issue before the house warming and I
                            was overwhelmed with this gesture.
                          </p>
                        </div>
                        <div class="col-lg-5 px-0 stories-all">
                          <a href="https://arrivae.com/made_by_you_stories" target="_blank"
                            class="all-stories story-padd">View All Stories</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row">
                    <div class="col-lg-7">
                      <div>
                        <iframe class="video-player" src="https://www.youtube.com/embed/xi8vumcyf_I"
                          class="w-100 img-fluid" frameborder="0"></iframe>
                      </div>
                    </div>
                    <div class="col-lg-5">
                      <div class="row flex-column justify-content-center mobile-space">
                        <div class="col-12 d-lg-block d-none">
                          <p class="made-by-you">Made-By-You Stories</p>
                        </div>
                        <div class="col-12">
                          <p class="user-name mb-0">Keval Desai</p>
                          <p class="user-city">Mumbai, Maharashtra</p>
                        </div>
                        <div class="col-12 mb-3 mb-lg-0">
                          <p class="user-content">
                            While interacting we understood that we have a good
                            connect with the designers as they understood our
                            requirements and taste very very clearly. The design
                            process started with by sharing the ideas and
                            reference pictures we had with the designer. Based
                            on it, the designer suggested contemporary
                            design.The entire process was smooth as we were
                            aware of every step coming one after the other.
                          </p>
                        </div>
                        <div class="col-lg-5 px-0 stories-all">
                          <a href="https://arrivae.com/made_by_you_stories" target="_blank"
                            class="all-stories story-padd">View All Stories</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="row">
                    <div class="col-lg-7">
                      <div>
                        <iframe class="video-player" src="https://www.youtube.com/embed/ARARrCEzQ50"
                          class="w-100 img-fluid" frameborder="0"></iframe>
                      </div>
                    </div>
                    <div class="col-lg-5">
                      <div class="row flex-column justify-content-center mobile-space">
                        <div class="col-12 d-lg-block d-none">
                          <p class="made-by-you">Made-By-You Stories</p>
                        </div>
                        <div class="col-12">
                          <p class="user-name mb-0">Sanjesh & Pooja Thakur</p>
                          <p class="user-city">Gurgram, Haryana</p>
                        </div>
                        <div class="col-12 mb-3 mb-lg-0">
                          <p class="user-content">
                            So this was a Spanish architecture villa, we were
                            looking for a theme which was Victorian. We
                            finalized Arrivae for two main reasons: 1: Their
                            level of commitment was very very high as compared
                            to the others. 2: The sense that we got after
                            meeting 3-4 companies was that Arrivae was not in a
                            hurry to close the deal. They spent time with us to
                            understand our requirements. It was a very
                            insightful program, we got a lot of insights from
                            them on design aesthetics, Victorian designs.
                          </p>
                        </div>
                        <div class="col-lg-5 px-0 stories-all">
                          <a href="https://arrivae.com/made_by_you_stories" target="_blank"
                            class="all-stories story-padd">View All Stories</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row cardContainertwo mb-3">
          <div class="col-md-12 mt-1">
            <div class="row">
              <div class="customPointer col-md-6 col-lg-6 col-sm-12 mt-0 mb-0 px-0 px-lg-3"
                *ngFor="let items of imageObject; let k = index">
                  <div id="{{ 'carouselExampleIndicators1' + k }}" class="carousel slide" data-ride="carousel"
                    data-interval="2000">
                    <ol class="carousel-indicators carouselimages">
                      <li [attr.data-target]="'#carouselExampleIndicators1' + k" class="indicator" data-slide-to="0">
                      </li>
                      <li [attr.data-target]="'#carouselExampleIndicators1' + k" class="indicator" data-slide-to="1">
                      </li>
                      <li [attr.data-target]="'#carouselExampleIndicators1' + k" class="indicator" data-slide-to="2">
                      </li>
                    </ol>
                    <a (click)="routeToDetialsPageHandle(items)" routerLinkActive="active">
                    <div class="carousel-inner">
                      <div *ngFor="let image of items.imageUrl; let j = index" class="carousel-item"
                        [ngClass]="j == 0 ? 'active' : ''">
                        <img class="d-block w-100" [src]="image" />
                      </div>
                    </div>
                    </a>
                  </div>
                  <a (click)="routeToDetialsPageHandle(items)" routerLinkActive="active">
                  <div class="body cardbody py-3 px-4 px-lg-0">
                    <h5 class="title">{{ items.heading }}</h5>
                    <h6 class="card-city">{{ items.city }}</h6>
                    <p class="text">{{ items.content }}</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="yourdreamhome text-center py-5">
          <p class="yourdream mt-2">YOUR DREAM HOME,</p>
          <p class="madebyyou">MADE BY YOU.</p>
          <div class="justify-content-center d-flex py-3">
            <p class="col-md-3 col-10">
              We at Arrivae strive to make your dreams for your home come true!
              Enter your email to book a freen consultation with us and get a
              quote.
            </p>
          </div>
          <div class="intro-container h-100 d-flex">
            <div class="logo d-none">
              <img class="img-fluid" src="../../../assets/images/Arrivae-Logo-Footer .png" alt="logo" />
            </div>
            <div id="intro-text" class="intro-text col-md-4 col-11">
              <div class="input-group mb-3 input-group-get-quote">
                <div class="input-group-prepend">
                  <span class="input-group-text"><img src="../../../assets/images/icons/phone_icon.svg"
                      alt="phone icon" /></span>
                </div>
                <input type="text" class="form-control get-quote-input" aria-label="" placeholder="Your Phone Number"
                  maxlength="10" [(ngModel)]="mobileNumber"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" />
                <div class="input-group-append">
                  <button type="button" class="madebyyouBtn" (click)="otpVerification()">
                    Get A Quote
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal modal-get-quote" id="getQuoteModal" tabindex="-1" aria-labelledby="getQuoteModalLabel"
          aria-hidden="true">
          <div class="modal-dialog get-quote-wrapper">
            <div id="get-quote-container" class="modal-content get-quote-container">
              <div class="contents">
                <div class="get-quote-head" (click)="getquoteModal()">
                  <img class="arrow-left-icon" src="../../../assets/images/icons/arrow left.svg" alt="arrow left" />
                </div>
                <div class="get-quote-body">
                  <div class="text-wrapper">
                    <div class="text-contain">
                      <p class="text-head">Enter OTP</p>
                      <p class="text">
                        We have sent you an OTP to your phone number.
                      </p>
                    </div>
                    <div class="icon">
                      <img class="img-fluid" src="../../../assets/images/Landing_page_updated/shield-icon.png"
                        alt="shield-icon" />
                    </div>
                  </div>
                  <div class="input-field">
                    <input type="number" placeholder="OTP" maxlength="6" [(ngModel)]="enteredOptNumber"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" />
                    <!-- <p class="time-remaining">Time Remaining : <span class="light-green">2:00</span></p> -->
                    <button class="button-verify" (click)="verifyOtp()">
                      Verify
                    </button>
                  </div>
                </div>
              </div>
              <div class="get-quote-footer">
                <p class="text-footer m-0">
                  By verifying, you are allowing us to reach out to you on the
                  number you have providied. You can also view our Terms Of Use
                  & Privacy Policy.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="footer">
          <app-footer></app-footer>
        </div>
        <div class="loading d-flex justify-content-center" *ngIf="loader">
          <div class="spinner-border" style="color: #8d0528" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>