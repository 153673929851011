import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-footer-update',
  templateUrl: './footer-update.component.html',
  styleUrls: ['./footer-update.component.scss']
})
export class FooterUpdateComponent implements OnInit {
  selectedCity:any
  activFlag: any = false;
  year: any = new Date().getFullYear();
  idForEachInclusion : any
  constructor(
    private route: Router
  ) { }

  ngOnInit(): void {
    const index1 = this.route.url.indexOf('made-by-you-story');
    const index2 = this.route.url.indexOf('made-by-you-detail');
    
    if(index1 > -1 || index2 > -1){
      this.activFlag = true
    }
    this.loadScript()
    const url2 = parseInt(window.location.href.split('/')[4])
    this.idForEachInclusion = url2
  }

  centers(city:any){
    this.selectedCity = city;
    localStorage.setItem('selectedCity',this.selectedCity);
    window.open('https://web.arrivae.com/visit-us?selector='+this.selectedCity)
  }



  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

}
