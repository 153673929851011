import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private boardURL = environment.apiBaseUrl;
  public data = new Subject<any>();
  currentData = this.data.asObservable();
  constructor(public http: HttpClient) { }

  public getCities() {
    return this.http.get(`${this.boardURL}/v1/cities`);
  }
  public getpoints(city_id?:any , searchValue?:any){
    return this.http.get(`${this.boardURL}/v1/experience_centers?city_id=${city_id}&search=${searchValue}`);
  }
  
  public getDropdownspace() {
    return this.http.get(`${this.boardURL}/v1/portfolios/filter_datas`);
  }

  public optGeneration(number: any) {
    let url = environment.deltaBaseUrl
    return this.http.post(
      `${url}/v1/lead_invitations/lead_send_otp/?phone_number=` +
        number,
      []
    );
  }
  public additionalDetails(data :any){
    let url = environment.deltaBaseUrl
    return this.http.post(`${url}/v1/lead_invitations/get_your_estimate`, data)
  }
  public BookingDetails(id:any,data :any){
    let url = environment.deltaBaseUrl
    return this.http.post(`${url}/v1/lead_invitations/store_visit?lead_id=${id}`, data)
  }
  public GetECDetails(id :any){
    let url = environment.deltaBaseUrl
    return this.http.get(`${url}/v1/lead_invitations/cm_mapping?lead_id=${id}`)
  }


  public optVerification(b:any,otp: any, leadInvitationId: any) {
    let url = environment.deltaBaseUrl
    return this.http.post(
      `${url}/v1/lead_invitations/verify_otp?get_your_estimate=${b}&lead_otp=${otp}&lead_invatation_id=${leadInvitationId}`, []
    );
  }
  public getEscalationSummary() {
    let url = environment.deltaBaseUrl
    return this.http.get(`${url}/v1/manual_escalations/summary`);
  }

  public getEscalationtopOldest() {
    let url = environment.deltaBaseUrl
    return this.http.get(`${url}/v1/manual_escalations/top_ten_oldest`);
  }

  public getNOCData() {
    let url = environment.deltaBaseUrl
    return this.http.get(`${url}/v1/lead_nocs/store_noc_summary`);
  }

  public getNOCDataOldest() {
    let url = environment.deltaBaseUrl
    return this.http.get(`${url}/v1/lead_nocs/top_ten_ageing`);
  }
  public getlocationsForMarketing() {
    let url = environment.deltaBaseUrl
    return this.http.get(`${url}/v1/scheduler_appointments/meeting_locations`);
  }

  public CreateShedular(obj:any) {
    let url = environment.deltaBaseUrl
    return this.http.post(`${url}/v1/scheduler_appointments`,obj);
  }



  public CreateMailer(data:any){
    let url = environment.deltaBaseUrl
    return this.http.post(`${url}/v1/optin_mailers` , data)
  }

  spacesHandler(uid: any, client: any, accessToken: any) {
    let url = environment.apiBaseUrl + "/v1/portfolios/preset_packages_spaces";
    const headerDict = {
      'access-token': accessToken,
      'uid': uid,
      'client': client
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.get(url, requestOptions)
  }

  spaceDataHandler(uid: any, client: any, accessToken: any, space: any, range: any) {
    let url = environment.apiBaseUrl + `/v1/portfolios/preset_packages?space=${space}&range=${range === 0 ? null : range}&limit=4&page=1`;
    const headerDict = {
      'access-token': accessToken,
      'uid': uid,
      'client': client
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.get(url, requestOptions)
  }

  GetThisDesignHandler(uid: any, client: any, accessToken: any, lead_id:any, id: any ) {
    let url = environment.apiBaseUrl + `/v1/leads/${lead_id}/choose_presets`;
    const headerDict = {
      'access-token': accessToken,
      'uid': uid,
      'client': client
    }
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    var obj = {
      lead_presets: [id]
    }

    return this.http.patch(url, obj, requestOptions)
  }

  redirectUrl(postObj:any) {
    let URL = environment.deltaBaseUrl + '/v1/credit_fair_requests';
    return this.http.post(URL , postObj)
  }

}
