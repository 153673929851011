<app-header (sendData)="customFunc($event)"></app-header>

<div class="banner-image">

  <div id="carouselExampleIndicators" class="carousel slide carousel-container" data-touch="true" data-ride="carousel"
    data-interval="5000">
    <div class="carousel-inner">
      <div *ngFor="let image of carouselImages; index as i" class="carousel-item" [ngClass]="i == 0 ? 'active' : ''">
        <img class="d-block w-100" [src]="image.url" [alt]="image.alt" class="sampleImg" />
      </div>
    </div>
    <ol class="carousel-indicators">
      <li *ngFor="let image of carouselImages; index as i" [attr.data-slide-to]="i" [ngClass]="i == 0 ? 'active' : ''"
        data-target="#carouselExampleIndicators"></li>
    </ol>
  </div>

  <div class="banner-estimate-button">
    <a *ngIf="accessToken; else elseBlock" class="nav-link estimate-text" href="/get-estimate-package">Get your
      estimate</a>
    <ng-template #elseBlock>
      <a class="nav-link estimate-text" href="/get-your-estimate">Get your estimate</a>
    </ng-template>
  </div>

</div>

<app-what-we-cover></app-what-we-cover>
<app-how-we-work></app-how-we-work>
<app-project-gallery></app-project-gallery>
<app-customer-testimonials></app-customer-testimonials>
<app-video-gallery></app-video-gallery>
<app-blogs></app-blogs>
<app-work-with-us></app-work-with-us>
<app-footer-new></app-footer-new>

<app-footer></app-footer>
<div class="loading d-flex justify-content-center" *ngIf="loader">
  <div class="spinner-border" style="color: #8d0528;" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>