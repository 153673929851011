import { Injectable } from '@angular/core';
import {
  Http,
  Response,
  Headers,
  RequestOptions,
  URLSearchParams,
} from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/toPromise';
import { catchError, retry, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { throwError } from 'rxjs';
declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  options: any;
  deltaUrl: any = environment.deltaBaseUrl + '/';

  constructor(
    private http: HttpClient // private tokenService: AngularTokenService //public jwtHelper: JwtHelperService
  ) {}

  private extractData(res: Response) {
    let body = res.json();
    return body;
  }
  private extractDataPage(res: Response) {
    return res;
  }

  private handleErrorObservable(error: Response | any) {
    console.error(error.message || error);
    return Observable.throw(error.message || error);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error}`
      );
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
  getHeaders(): RequestOptions {
    this.headers = new Headers({ enctype: 'multipart/form-data' });
    this.headers.append('content-type: application/json');
    this.headers.append('uid', localStorage.getItem('uid'));
    this.headers.append('client', localStorage.getItem('access_token'));
    this.headers.append('access-token', localStorage.getItem('client'));
    this.options = new RequestOptions({ headers: this.headers });
    return this.options;
  }
  headers: any;
  uid: any;
  client: any;
  acces_token: any;
  public GetcustomerDetails() {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');
    console.log(this.uid);

    return this.http.get(
      this.deltaUrl + '/v1/users/customer_dashboard_timeline',
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    );
  }

  public PaymentDetails(){
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');

    return this.http.get(
      this.deltaUrl + 'v1/users/customer_dashboard_project_level',
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    );
  }


  public costBreakUpCustomer(){
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');

    return this.http.get(
      this.deltaUrl + '/v1/users/customer_dashboard_price_breakup_data',
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    );
  }

  public downloadpdf(id: any) {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');
    console.log(this.uid);

    return this.http.get(
      this.deltaUrl + 'v1/projects/' + id + '/download_kit',
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    );
  }
  public PropasalGetter(id: any, type: any) {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');
    console.log(this.uid);

    return this.http.get(
      this.deltaUrl +
        'v1/proposals?proposal_type=' +
        type +
        '&project_id=' +
        id,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    );
  }
  public viewboq(data: any, boqId: any, projectId: any) {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');
    var params: URLSearchParams = new URLSearchParams();
    params.set('download_type', JSON.stringify(data));
    return this.http.get(
      this.deltaUrl +
        'v1/projects/' +
        projectId +
        '/quotations/' +
        boqId +
        '/download_v2_pdf?' +
        params,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    );
  }
  public rejectboq(obj: any) {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');

    return this.http.post(
      this.deltaUrl + '/v1/proposals/approve_or_reject_the_boq',
      obj,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    );
  }
  public sendotp(obj: any, id: any) {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');

    return this.http.post(
      this.deltaUrl + '/v1/project_booking_forms/' + id + '/generate_otp',
      obj,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    );
  }
  public verifyotp(obj: any, id: any) {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');

    return this.http.post(
      this.deltaUrl + '/v1/project_booking_forms/' + id + '/otp_approval',
      obj,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    );
  }
  public downloadBookingForm(id: any) {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');
    console.log(this.uid);

    return this.http.get(
      this.deltaUrl + 'v1/project_booking_forms/' + id + '/download_pdf',
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    );
  }
  public getUpcomingevents(id: any) {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');
    console.log(this.uid);

    return this.http.get(
      this.deltaUrl + 'v1/events/customer_upcoming_events?ownerable_id=' + id,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    );
  }
  public getInspirations(id: any) {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');
    console.log(this.uid);

    return this.http.get(
      this.deltaUrl + 'v1/projects/' + id + '/customer_inspirations',
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    );
  }
  public DeleteInspirations(id: any, id2: any) {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');
    console.log(this.uid);

    return this.http.delete(
      this.deltaUrl + 'v1/projects/' + id + '/customer_inspirations/' + id2,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    );
  }
  public postInspirations(id: any, file: any) {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');
    let formdata = new FormData();
    formdata.append('content[document]', file);

    return this.http.post(
      this.deltaUrl + 'v1/projects/' + id + '/customer_inspirations',
      formdata,
      {
        headers: new HttpHeaders({
          Accept: 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    );
  }

  Magiclogin(token: any) {
    let url = this.deltaUrl + '/v1/users/customer_magic_login?token=' + token;

    return this.http.post(url, [], { observe: 'response' });
  }

  // {{url}}/v1/users/web_boq_magic_login?token=yd5J6zenCW1tXrt1cJs6CRMc584GCv2b

  // http://localhost:4200/customer_magic_login_web_boq?token=SoW5ky9WkkG3LSb5asnWA2J24yqknhDg&boq=BOQ/2021/S/44313

  boqMagicLogin(token: any, boqNum: any) {
    console.log(token, boqNum);

    let url =
      this.deltaUrl +
      'v1/users/web_boq_magic_login?token=' +
      token +
      '&boq=' +
      boqNum;
    return this.http.post(url, [], { observe: 'response' });
  }

  public BOqUserNameDetails(id: any, boqId: any) {
    // console.log( id, boqId);
    // id = localStorage.getItem('projectId');
    // boqId = localStorage.getItem('Boqid')
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');

    return this.http.get(
      this.deltaUrl + 'v1/users/web_boq?project_id=' + id + '&boq_no=' + boqId,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    );
  }

  public spaceWiseApi(id:any , spaceValue:any){
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');
    return this.http.get(
      this.deltaUrl + 'v1/users/all_space_products?quotation_number=' + id + '&space=' + spaceValue,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    )
  }

  // {{url}}/v1/users/web_boq_time_line?project_id=33932&boq_no=BOQ/2021/N/43759

  public webBoqTimeLine(id: any, boqId: any) {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');

    return this.http.get(
      this.deltaUrl +
        'v1/users/web_boq_time_line?project_id=' +
        id +
        '&boq_no=' +
        boqId,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    );
  }
 

  public sideBarLevelTwo(id: any, quoteType: any) {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');
    return this.http.get(
      this.deltaUrl +
        'v1/users/quotation_type_more_details?quotation_number=' +
        id +
        '&quote_type=' +
        quoteType,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    );
  }
  public approveRejectBoq(app: any, proposalId: any) {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');
    return this.http.post(
      this.deltaUrl +
        'v1/proposals/approve_or_reject_the_boq',
        {
          'is_approved'  : app ,
          'proposal_doc_id' : proposalId

        },
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    );
  }

  public submitDetailsForPayment(dataForPayment:any , ids:any , stage :any){
    const data = {
      amount: dataForPayment.amount,
      email:  dataForPayment.email,
      lead_id: dataForPayment.lead_id,
      mobile_number: dataForPayment.mobile_number,
      web_boq: dataForPayment.web_boq,
      quotations_ids : ids,
      stage : stage
    }

    return this.http.post(
      this.deltaUrl  +  'v1/lead_payment_requests/make_payment_from_customer_dashboard',  data,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    )
  }

  public promoApi(id:any){
    return this.http.get(
      this.deltaUrl + 'v1/users/available_promo?quotation_id=' + id,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    )
  }

  apiUrl: any;
  reqData: any;

  public rescheduleService(
    eventId: any,
    projectId: any,
    leadId: any,
    modalTypeData: any,
    newDate: any,
    notesVal: any,
    locationData: any,
    contactType: any,
    emailsData: any,
    descriptionData: any
  ) {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');

    if (modalTypeData == 'First Meeting') {
      (this.apiUrl =
        this.deltaUrl +
        'v1/events/' +
        eventId +
        '/reschedule_event?ownerable_type=Project&ownerable_id=' +
        projectId),
        (this.reqData = {
          event: {
            ownerable_id: projectId,
            ownerable_type: 'Project',
            contact_type: contactType,
            agenda: 'first_meeting',
            description: descriptionData,
            emails: emailsData,
            location: locationData,
            remark: notesVal,
            scheduled_at: newDate,
            status: 'rescheduled',
          },
        });
    }
    if (modalTypeData == 'Design & BOQ Presentation Meeting') {
      (this.apiUrl =
        this.deltaUrl +
        'v1/events/' +
        eventId +
        '/reschedule_event?ownerable_type=Project&ownerable_id=' +
        projectId),
        (this.reqData = {
          event: {
            ownerable_id: projectId,
            ownerable_type: 'Project',
            contact_type: contactType,
            agenda: 'design_and_boq_presentation',
            description: descriptionData,
            emails: emailsData,
            location: locationData,
            remark: notesVal,
            scheduled_at: newDate,
            status: 'rescheduled',
          },
        });
    }
    if(modalTypeData == 'Closure Meeting'){
      (this.apiUrl =
        this.deltaUrl +
        'v1/events/' +
        eventId +
        '/reschedule_event?ownerable_type=Project&ownerable_id=' +
        projectId),
        (this.reqData = {
          event: {
            ownerable_id: projectId,
            ownerable_type: 'Project',
            contact_type: contactType,
            agenda: 'closure',
            description: descriptionData,
            emails: emailsData,
            location: locationData,
            remark: notesVal,
            scheduled_at: newDate,
            status: 'rescheduled',
          },
        });
    }
    if(modalTypeData == 'Follow Up Meeting'){
      (this.apiUrl =
        this.deltaUrl +
        'v1/events/' +
        eventId +
        '/reschedule_event?ownerable_type=Project&ownerable_id=' +
        projectId),
        (this.reqData = {
          event: {
            ownerable_id: projectId,
            ownerable_type: 'Project',
            contact_type: contactType,
            agenda: 'follow_up_meeting',
            description: descriptionData,
            emails: emailsData,
            location: locationData,
            remark: notesVal,
            scheduled_at: newDate,
            status: 'rescheduled',
          },
        });
    }
    if(modalTypeData == 'Follow Up Call'){
      (this.apiUrl =
        this.deltaUrl +
        'v1/events/' +
        eventId +
        '/reschedule_event?ownerable_type=Project&ownerable_id=' +
        projectId),
        (this.reqData = {
          event: {
            ownerable_id: projectId,
            ownerable_type: 'Project',
            contact_type: contactType,
            agenda: 'follow_up_call',
            description: descriptionData,
            emails: emailsData,
            location: locationData,
            remark: notesVal,
            scheduled_at: newDate,
            status: 'rescheduled',
          },
        });
    }
    if (modalTypeData == 'Site Measurement Request') {
      (this.apiUrl = this.deltaUrl + 'v1/lead_nocs/add_custom_date'),
        (this.reqData = {
          ownerable_type: 'Lead',
          ownerable_id: leadId,
          date_name: 'Initial Site Measurement Request',
          date_type: 'expected',
          manual_date: newDate,
          notes: notesVal,
          reason: 'Customer Delay',
        });
    }
    if (modalTypeData == 'Site Validation Request') {
      (this.apiUrl = this.deltaUrl + 'v1/lead_nocs/add_custom_date'),
        (this.reqData = {
          ownerable_type: 'Lead',
          ownerable_id: leadId,
          date_name: 'Site Validation Request',
          date_type: 'expected',
          manual_date: newDate,
          notes: notesVal,
          reason: 'Customer Delay',
        });
    }
    if (modalTypeData == 'Material Finalisation Meeting') {
      (this.apiUrl = this.deltaUrl + 'v1/lead_nocs/add_custom_date'),
        (this.reqData = {
          ownerable_type: 'Lead',
          ownerable_id: leadId,
          date_name: 'Material Finalisation Meeting',
          date_type: 'expected',
          manual_date: newDate,
          notes: notesVal,
          reason: 'Customer Delay',
        });
    }
    if (
      modalTypeData == 'First Meeting' ||
      modalTypeData == 'Design & BOQ Presentation Meeting'
    ) {
      return this.http.post(this.apiUrl, this.reqData, {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      });
    } else {
      return this.http.post(this.apiUrl, this.reqData, {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      });
    }
  }
  public getreport(id: any) {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');
    console.log(this.uid);

    return this.http.get(
      this.deltaUrl +
        'v1/users/services_installation_tasks_list?project_id=' +
        id,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    );
  }

  public eventEmitter(lead_id: any) {
    gtag('event', 'customer_portal_page_load', {
      lead_id: lead_id,
      system_user_id: localStorage.getItem('system_user_id'),
    });
  }

  public getCustomerDashTimeline() {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');
    return this.http.get(
      this.deltaUrl + 'v1/users/customer_dashboard_project_level',
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    );
  }

    // public submitDetailsForPayment(dataForPayment:any){
  //   return this.http.post(
  //     this.deltaUrl  +  'v1/lead_payment_requests/send_cashfree_payment_request',  dataForPayment,
  //     {
  //       headers: new HttpHeaders({
  //         'content-type': 'application/json',
  //         uid: this.uid,
  //         client: this.client,
  //         'access-token': this.acces_token,
  //       }),
  //     }
  //   )
  // }

  public paymentforCustomer(dataCustomer:any , idArray:any , stage :any){
    const data = {
      amount: dataCustomer.amount,
      email: dataCustomer.email,
      lead_id: dataCustomer.lead_id,
      mobile_number: dataCustomer.mobile_number,
      web_boq: "true",
      quotations_ids : idArray,
      stage : stage
    }
    return this.http.post(
      this.deltaUrl  +  'v1/lead_payment_requests/make_payment_from_customer_dashboard',  data,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    )
  }

  customMoreItemsForCustomer(page: any, perPage: any) :Observable<any> {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');
    return this.http.get(
      this.deltaUrl +
        'v1/users/more_to_explore_custom?page=' +
        page +
        '&per_page=' +
        perPage,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
        responseType: 'text',
        observe: 'response',
      }
    );
  }

  moreItemsToExploreForCustomer(page: any, perPage: any) :Observable<any> {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');
    return this.http.get(
      this.deltaUrl +
        'v1/users/more_to_explore_kitchen?page=' +
        page +
        '&per_page=' +
        perPage,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
        responseType: 'text',
        observe: 'response',
      }
    );
  }

  public levelThreeModal(name: any, jobId: any) {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');
    return this.http.get(
      this.deltaUrl +
        'v1/users/single_product_details?ctagory_name=' +
        name +
        '&job_id=' +
        jobId,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
      }
    );
  }

  moreItemsToExplore(page: any, perPage: any) :Observable<any> {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');
    return this.http.get(
      this.deltaUrl +
        'v1/users/more_to_explore_kitchen?page=' +
        page +
        '&per_page=' +
        perPage,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
        responseType: 'text',
        observe: 'response',
      }
    );
  }
  customMoreItems(page: any, perPage: any) :Observable<any> {
    this.uid = localStorage.getItem('uid');
    this.client = localStorage.getItem('client');
    this.acces_token = localStorage.getItem('access_token');
    return this.http.get(
      this.deltaUrl +
        'v1/users/more_to_explore_custom?page=' +
        page +
        '&per_page=' +
        perPage,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json',
          uid: this.uid,
          client: this.client,
          'access-token': this.acces_token,
        }),
        responseType: 'text',
        observe: 'response',
      }
    );
  }
  chequepaymentSubmit(data:any){
    return this.http.post(
      this.deltaUrl + 'v1/lead_payment_requests/ten_per_cheque_payment', data
    );
  }
  getAmountpaymentStatus(data:any){
    return this.http.get(
      this.deltaUrl + 'v1/lead_payment_requests/fetch_amount?token=' + data
    );
  }
resendPaymentLink(data :any){
  let obj 
  if(data){
     obj = {
      token : data
    }
  }
  return this.http.post(
    this.deltaUrl + 'v1/lead_payment_requests/regenerate_cheq_link', obj
  )
}

  getInsta(){
    return this.http.get(
     'https://graph.instagram.com/me/media?fields=caption,id,media_type,media_url,permalink,thumbnail_url,timestamp,username&access_token=IGQVJXcTMwelB4WDJBSmpET1ZAGTnF3NXNJbjhSQzF3V003ei0ya2o3RWpNRk5DOUtSeFBVWERLOVpHa3pWSmd0ZAjdvZAnBtUFBnMThNTDl3bVVQc2REc0RDUU1LbmJNWEdrUmZAwTlJaREhGNWtSRW9YUQZDZD'
      
    );

  }

 


}
