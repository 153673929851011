import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BookConsultationFormComponent } from 'src/app/shared/book-consultation-form/book-consultation-form.component';
import { SpaceService } from 'src/app/spaces/space.service';

@Component({
  selector: 'app-lifestage',
  templateUrl: './lifestage.component.html',
  styleUrls: ['./lifestage.component.scss']
})
export class LifestageComponent implements OnInit {

  storeValue :any='YOUNG VIBES';
  spaceDropdown: any=[];
  api_data: any;
  space_name: any;
  lifestage: any='';
  theme: any;
  list: any;
  space:any= '';
  ayz: any;
  abc: any;
  fl_list: any;
  lifeStage_filter_value: any = 'young_vibes';
  space_filter_value: any = '';
  showDiv = {
    text1: true,
    text2: false,
    text3: false,
    text4: false,
    text5: false,
  }
  lifestages: any;
  
  constructor(public spaceService: SpaceService, private router: Router, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.clickable('YOUNG VIBES')
    this.getDropdownSpace();
    this.getLifestyle();
    this.lifeStageFilter({ name: "Young Vibes" });
    if (this.initialspace === undefined) {
      this.initialspace = "Kitchen"
    }
    this.spaceFilter(this.initialspace);
    this.loadScript()
  }

  clickable(val:any){
    this.storeValue=val;
    this.getDescriptionData();
  }
  getDescriptionData(){
  }

  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  getDropdownSpace(){
    this.spaceService.getDropdownspace().subscribe(data=>{
      this.spaceDropdown = data; 
      this.api_data = Object.values(this.spaceDropdown);
      this.space_name = this.api_data[0];
      this.lifestage = this.api_data[1];
      this.theme = this.api_data[2];
     this.getPortfolioList("", "young_vibes");
    })
  }

  
  filteresValue: any='';
  space_filter1: any = '';
  young_vibes_lifes: any = 'young_vibes';
  lifeStageFilter(data: any) {
     this.lifeStage_filter_value = data;
    if (this.lifeStage_filter_value.name === "Young Vibes") {
      this.young_vibes_lifes = 'young_vibes';
    }
    if (this.lifeStage_filter_value.name === "Love Vibes") {
      this.young_vibes_lifes = 'love_vibes';
    } 
    if (this.lifeStage_filter_value.name === "Baby Vibes") {
      this.young_vibes_lifes = 'baby_vibes';
    }
    if (this.lifeStage_filter_value.name === "Lively Vibes") {
      this.young_vibes_lifes = 'lively_vibes';
    }
    if (this.lifeStage_filter_value.name === "Happy Vibes") {
      this.young_vibes_lifes = 'happy_vibes';
    }

 
    this.getPortfolioList(this.space_filter1, this.young_vibes_lifes);
    this.spaceFilter(this.initialspace);
    this.showDiv.text1 = true;
    this.showDiv.text1 = false;
    this.showDiv.text1 = false;
    this.showDiv.text1 = false;
    this.showDiv.text1 = false;
  }

  
  
  spaceFilter(data:any) {
    this.space_filter_value = data;
    this.getTotalList(this.space_filter_value,this.young_vibes_lifes);
  }


  list1: any;
  initialspace: any;
  getPortfolioList(space: any, lifeStage: any) {
    lifeStage = this.young_vibes_lifes;
    this.spaceService.getPortfolioList(space,lifeStage,'','','','','').subscribe(data=>{
      this.list1 = data;
      this.ayz = [...new Set(this.list1.data.map((i: any) => i.attributes.space))];
      this.abc = this.ayz;
      this.space_filter_value = this.abc[0];
      if(this.list.data.length !== 0){
      }
      this.getTotalList(this.space_filter_value,this.young_vibes_lifes);
    })
  }

  getTotalList(space:any,lifeStage:any){
    this.spaceService.getPortfolioList(space,lifeStage,'','','','','').subscribe(data=>{
      this.list = data; 
    })
  }

  getLifestyle() {
    this.lifestages = [{
      name: 'Young Vibes'
    },
      { name: 'Love Vibes' },{name: 'Baby Vibes'},{name: 'Lively Vibes'}, {name: 'Happy Vibes'}
    ]
  }

  btnClick(id: any) {
    window.open(
    ('/space-inclusion/' + id),'_blank' );
  }
  bookConsultationLandingPage() {
    const modal = this.modalService.open(BookConsultationFormComponent, {
      size: 'md',
    });
  }

  viewAlllifestage() {
    this.router.navigate(['/spaces'],
    { queryParams: {space_name: this.space_filter_value, lifestage_name: this.young_vibes_lifes}})
  }

}
