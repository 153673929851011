<div class="row alerts">
  <div class="rk-ui-notification" style="height: 20px !important" *ngIf="successalert" style="z-index: 1000000 !important">
    <span id="notificationMessageText" style="margin: auto">{{ successMessage }}</span>
    <a class="close rk-linknotification" style="color: white" (click)="successalert = false">×</a>
  </div>
  <div class="rk-ui-notification err" *ngIf="erroralert">
    <span id="notificationMessageText">{{ errorMessage }}</span>
    <a class="close rk-linknotification" style="color: white" (click)="erroralert = false">×</a>
  </div>
</div>
<div class="root">
  <app-new-header-for-home></app-new-header-for-home>
  <div class="row m-2 my-4 my-md-5 pl-md-4 section1">
    <div class="col-md-6 col-12">
      <img src="../../../assets/images/stock-photo-greetings-you-are-hired-happy-young-indian-female-successful-job-seeker-shake-hand-of-male-hr-1906255501-transformed.jpeg" alt="">
    </div>
    <div class="col-md-6 col-12 pl-md-5 mt-4 mt-md-0">
      <div class="header">
        <h2 class="m-0">JOIN OUR DYNAMIC AND PASSIONATE INTERIOR DESIGN TEAM</h2>
      </div>
      <div class="form">
        <form [formGroup]="createdesigner" (ngSubmit)="createdesignerlead()">
          <div class="form-row mt-3 mt-md-5">
            <div class="form-group col-12">
              <input type="text" class="form-control" placeholder="Your name*" formControlName="name" required />
              <div *ngIf="formControls.name.touched && formControls.name.errors?.required" class="text-danger">Name is required</div>
            </div>
            <div class="form-group col-md-12">
              <input type="number" class="form-control number" placeholder="Your phone number*" formControlName="contact" required pattern="[1-9]{1}[0-9]{9}" onkeydown="return event.keyCode !== 69" onKeyPress="if(this.value.length==10) return false;" required pattern="[1-9]{1}[0-9]{9}" />
              <div *ngIf="formControls.contact.touched && formControls.contact.errors?.required" class="text-danger">Phone number is required</div>
              <div *ngIf="formControls.contact.touched && formControls.contact.errors?.pattern" class="text-danger">Invalid phone number format</div>
            </div>
            <div class="form-group col-md-12 mb-0">
              <input type="text" class="form-control" placeholder="Enter your email*" formControlName="email" required />
              <div *ngIf="formControls.email.touched && formControls.email.errors?.required" class="text-danger">Email is required</div>
              <div *ngIf="formControls.email.touched && formControls.email.errors?.email" class="text-danger">Invalid email format</div>
            </div>
            <span class="note pl-2">We'll never share your email with anyone else.</span>
            <div class="form-group col-md-12 mt-3 d-flex align-items-baseline reumesection">
              <label for="resume" class="resume">Upload Resume <img src="../../../assets/images/UploadSimple.svg" class="pl-1" style="width: 25px;height: 25px;"></label>
              <input type="file" id='resume' class="form-control-file d-none" formControlName="lead_cv" (change)="onFileSelected($event)" accept=".pdf,.docs,.docx,.jpeg,.png" />
              <span *ngIf="files" class="cv ml-md-4 justify-content-center align-items-baseline d-flex">{{this.files?.name}}<span (click)="remove()" class="pl-2">x</span></span>
            </div>
          </div>
          <div class="d-flex flex-column ">
            <button type="submit" class="submit mt-2 " >SUBMIT </button>
            <span class="note mt-1">By submitting you agree to the <a routerLink="/privacy-policy" target="_blank">privacy policy</a></span>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="row m-2 my-4 mx-md-5 section2">
    <h2 class="head pb-1">WHY ARRIVAE</h2>
    <div class="row mt-3 mt-md-1 px-1">
      <div class="col-md-2 col-6">
        <img src="../../../assets/images/img/1.png" alt="">
      </div>
      <div class="col-md-2 col-6">
        <img src="../../../assets/images/img/2.png" alt="">
      </div>
      <div class="col-md-2 col-6">
        <img src="../../../assets/images/img/3.png" alt="">
      </div>
      <div class="col-md-2 col-6">
        <img src="../../../assets/images/img/4.png" alt="">
      </div>
      <div class="col-md-2 col-6">
        <img src="../../../assets/images/img/5.png" alt="">
      </div>
      <div class="col-md-2 col-6">
        <img src="../../../assets/images/img/6.png" alt="">
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>


<div class="loading d-flex justify-content-center" *ngIf="loader">
  <div class="spinner-border" style="color: #8d0528" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
