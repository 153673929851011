import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-choose-arrivae',
  templateUrl: './choose-arrivae.component.html',
  styleUrls: ['./choose-arrivae.component.scss']
})
export class ChooseArrivaeComponent implements OnInit {

  constructor() { }

  data=[
    {
      image:"../../../assets/Longform/icons/vehicle.svg",
      title:"End-to-end Solutions",
      content:"Everything Home Interiors Under One Roof"
    },
    {
      image:"../../../assets/Longform/icons/furbishing 1.svg",
      title:"Personalisation",
      content:"Your Dream Home, Your Way"
    },
    {
      image:"../../../assets/Longform/icons/loan.svg",
      title:"Affordability",
      content:"No-cost, Easy EMIs"
    },
    {
      image:"../../../assets/Longform/icons/matrial-png.svg",
      title:"Best-in-class Materials",
      content:"Curated Selections by Experts"
    },
    {
      image:"../../../assets/Longform/icons/3159205.svg",
      title:"International Themes",
      content:"At Indian Prices"
    },
    {
      image:"../../../assets/Longform/icons/shipped.svg",
      title:"Timely Delivery",
      content:" With In-house Manufacturing"
    },
    {
      image:"../../../assets/Longform/icons/4400863.svg",
      title:"International Grade Furniture",
      content:"With 10-Year BHAARI Warranty"
    },

   ]

  ngOnInit(): void {
  }



}
