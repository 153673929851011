<div class=" Made-By-You pt-3 ">
   <div class="d-flex flex-column justify-content-start main-heading  p-md-5 p-2 mb-3 mb-md-0">
      <p class="mb-1 "><span class="pb-1">MADE-BY-YOU</span> STORIES</p>
      <span>Our happy customers tell you why to choose Arrivae</span>
      <button (click)="IntialvideoPlayIn('https://www.youtube.com/embed/HZMaErn5sjM?autoplay=1&loop=1')" type="button " class="mt-2 button  align-items-center d-lg-flex d-none">WATCH NOW &nbsp;
        <span>
           <img src="../../../assets/images/Arrow 1.png"/>
        </span>
      </button>
   </div>   
    <div [ngClass]="roletoSet()> 650?'':'d-none'"  class="stories_container">
      <div >
         <div class="w-100 cursorPointer groungimage" *ngIf ="!allVideos" (click)="IntialvideoPlayIn('https://www.youtube.com/embed/HZMaErn5sjM?autoplay=1&loop=1')">
            <div class="playIconStyles">
               <img src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
            </div>
         </div>
         <div class="w-100" *ngIf = "allVideos">
            <iframe allow="autoplay" class="w-100" width="420" height="500" [src]="current_url" frameborder="0" allowfullscreen>
            </iframe>
         </div>
      </div>


      <div class="row mx-1 videoThumbnails  mt-3">
       <div class="col-lg-3 col-md-6 col-sm-6" >
          <a (click)="videoPlayIn('https://www.youtube.com/embed/Sqr8WhpZSmM?autoplay=1&loop=1')" class="cursorPointer">
             <div  class="image_carrier  align-items-end cursorPointer " >
                  <img class="w-100" src="../../../assets/images/youtubeVideos/14.png"/>
             </div>
          </a>
          <div class="thumb-content">
               <p class="thumb-heading my-2">The Pandurangis Family</p>
               <p class="thumb-para">“We are very, very happy the way Arrivae has done the job, the timely delivery and as well as the cooperation throughout the project! It was entirely a beautiful journey for us. We never felt that we have been doing such a great change in the house.”</p>
          </div>
       </div>
       <!-- Sabus family -->
       <div class="col-lg-3 col-md-6 col-sm-6 h-100  ">
          <a (click)="videoPlayIn('https://www.youtube.com/embed/ljhxUFH01Uw?autoplay=1&loop=1')" class="cursorPointer ">
             <div class="image_carrier  align-items-end cursorPointer " >
               <img class="w-100" src="../../../assets/images/youtubeVideos/image 23.png"/>
             </div>
          </a>
          <div class="thumb-content">
            <p class="thumb-heading my-2">The Sabus Family</p>
            <p class="thumb-para">
               “I'm so happy! What I saw in 3D is exactly what I got. So that is the best part! I got regular mails from them.They were connected with me. When there was a small concern, they attended it. Immediately I got a call from the Mumbai office. So I'm very happy with their service. What they promised they'd delivered.”
            </p>
       </div>
       </div>
       <div class="col-lg-3 col-md-6 col-sm-6 h-100 ">
          <a (click)="videoPlayIn('https://www.youtube.com/embed/fzd7taoEDL8?autoplay=1&loop=1')" class="cursorPointer ">
             <div class="image_carrier  align-items-end" >
               <img class="w-100" src="../../../assets/images/youtubeVideos/16.png"/>
             </div>
          </a>
          <div class="thumb-content">
            <p class="thumb-heading my-2">The Subramanian Family</p>
            <p class="thumb-para">
               “When I had this house interior project, I did extensive research on multiple agencies, multiple companies. They have a personal interaction with the client, so that's why I chose Arrivae. What I liked about Arrivae was their openness to my ideas and my suggestions and my thoughts, and then they took it from me and then they transformed into something that really worked for us.”
            </p>
         </div>
       </div>
       <div class="col-lg-3 col-md-6 col-sm-6  h-100 ">
          <a (click)="videoPlayIn('https://www.youtube.com/embed/UJeGixHIWRk?autoplay=1&loop=1')" class="cursorPointer ">
             <div class="image_carrier  align-items-end cursorPointer " >
               <img class="w-100" src="../../../assets/images/youtubeVideos/17.png"/>
             </div>
          </a>
          <div class="thumb-content">
            <p class="thumb-heading my-2">The Rishabh Family</p>
            <p class="thumb-para">
               “My experience with Arrivae was fabulous— perfectly blending my ideas, my imagery with their expertise and experience in this particular field to bring out a beautifully decorated home. Throughout the design and development process, Arrivae's attention to detail was impeccable and second to none.”
            </p>
         </div>
       </div>
       
      </div>
    </div>
   <div [ngClass]="roletoSet()< 650?'d-block':'d-none'" >
       <div  style="background-image: url('../../../assets/images/youtubeVideos/MicrosoftTeams-image.png');    background-repeat: no-repeat;
       background-size: 100% 100%;
       height: 200px;
       background-position: center;"  [ngClass]="!allVideos ? 'd-block' : 'd-none'"
       class="w-100 cursorPointer"  (click)="MobileIntialvideoPlayIn('ljhxUFH01Uw')" >
       <div class="d-flex justify-content-center mobile_video align-items-center flex-column h-100">
         <img class="img-fluid" style=" height: 20%;" src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
      </div>
      </div>


      <div id="you" class="w-100" [ngClass]="allVideos ? 'd-block' : 'd-none'">
         <youtube-player
         [videoId]="id"
         [width]="videoWidth"
         (ready)="savePlayer($event)"
         (change)="onStateChange($event)"
         [playerVars]="playerVars"
       ></youtube-player>
      </div>
      <div  class="videoThumbnails pt-2">
         <div class="row w-100" slickContainer #slickController="slick" [slickConfig]="config">
            <div slickItem class="col-3" (click)="mobilevideoPlayIn('Sqr8WhpZSmM' , 1)">
                       <img class="img-width" src="../../../assets/images/youtubeVideos/14.png"/>
               <div class="thumb-content">
                    <p class="thumb-heading my-2">The Pandurangis Family</p>
                    <p class="thumb-para">“We are very, very happy the way Arrivae has done the job, the timely delivery and as well as the cooperation throughout the project! It was entirely a beautiful journey for us. We never felt that we have been doing such a great change in the house.”</p>
               </div>
            </div>

            <!-- Sabus family -->
            <div slickItem class="col-3"  (click)="mobilevideoPlayIn('ljhxUFH01Uw' , 2)" >
                    <img class="img-width" src="../../../assets/images/youtubeVideos/image 23.png"/>
               <div class="thumb-content">
                 <p class="thumb-heading my-2">The Sabus Family</p>
                 <p class="thumb-para">
                    “I'm so happy! What I saw in 3D is exactly what I got. So that is the best part! I got regular mails from them.They were connected with me. When there was a small concern, they attended it. Immediately I got a call from Mumbai office. So I'm very happy with their service. What they promised they'd delivered.”
                 </p>
            </div>
            </div>
            <div slickItem class="col-3"  (click)="mobilevideoPlayIn('fzd7taoEDL8' , 3)" >
                    <img class="img-width" src="../../../assets/images/youtubeVideos/16.png"/>
               <div class="thumb-content">
                 <p class="thumb-heading my-2">The Subramanian Family</p>
                 <p class="thumb-para">
                    “When I had this house interior project, I did extensive research on multiple agencies, multiple companies. They have a personal interaction with the client, so that's why I chose Arrivae. What I liked about Arrivae was their openness to my ideas and my suggestions and my thoughts, and then they took it from me and then they transformed into something that really worked for us.”
                 </p>
              </div>
            </div>
            <div slickItem class="col-3" (click)="mobilevideoPlayIn('UJeGixHIWRk',4)" >
                    <img class="img-width" src="../../../assets/images/youtubeVideos/17.png"/>
               <div class="thumb-content">
                 <p class="thumb-heading my-2">The Rishabh Family</p>
                 <p class="thumb-para">
                    “My experience with Arrivae was fabulous— perfectly blending my ideas, my imagery with their expertise and experience in this particular field to bring out a beautifully decorated home. 
                    Throughout the design and development process, Arrivae's attention to detail was impeccable and second to none.”
     
                 </p>
              </div>
            </div>
         </div> 
         <div class="d-lg-none d-flex justify-content-center pb-3">
            <button (click)="MobileIntialvideoPlayIn('HZMaErn5sjM')" type="button " class="z-index-n3 button align-items-center d-flex">WATCH NOW &nbsp;
               <span>
                  <img src="../../../assets/images/Arrow 1.png"/>
               </span>
             </button>
         </div>
      </div>
   </div>
 </div>