<app-header></app-header>

<div class="container-fluid profile">
 
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-3">
      <div id="accordion">
        <div class="card">
          <div id="headingOne">
            <h5 class="mb-0">
              <button
                class="btn KitchenIconText"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <img
                  src="../../../assets/images/Group-574.png"
                  class="kitchenIcon"
                />
                <p
                  class="kitchenBG"
                  (click)="clickOnHeading('Kitchen Buying Guide')"
                  [ngClass]="{
                    activeHeader: clickHead == 'Kitchen Buying Guide'
                  }"
                >
                  Kitchen Buying Guide
                </p>
              </button>
            </h5>
          </div>

          <div
            id="collapseOne"
            class="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            <div>
              <ul class="list-group kitchenLi">
                <li
                  class="list-group-item item-align"
                  (click)="clickable('Know Your Kitchen')"
                  [ngClass]="{
                    active: storeValue == 'Know Your Kitchen'
                  }"
                >
                  KNOW YOUR KITCHEN
                </li>
                <li
                  class="list-group-item item-align"
                  (click)="clickable('Kitchen Shape')"
                  [ngClass]="{
                    active: storeValue == 'Kitchen Shape'
                  }"
                >
                  KITCHEN SHAPE
                </li>
                <li
                  class="list-group-item item-align"
                  (click)="clickable('Kitchen Size')"
                  [ngClass]="{
                    active: storeValue == 'Kitchen Size'
                  }"
                >
                  KITCHEN SIZE
                </li>
                <li
                  class="list-group-item item-align"
                  (click)="clickable('Base Unit')"
                  [ngClass]="{
                    active: storeValue == 'Base Unit'
                  }"
                >
                  BASE UNIT
                </li>
                <li
                  class="list-group-item item-align"
                  (click)="clickable('Wall Unit')"
                  [ngClass]="{
                    active: storeValue == 'Wall Unit'
                  }"
                >
                  WALL UNIT
                </li>
                <li
                  class="list-group-item item-align"
                  (click)="clickable('Kitchen Top')"
                  [ngClass]="{
                    active: storeValue == 'Kitchen Top'
                  }"
                >
                  KITCHEN TOP
                </li>
                <li
                  class="list-group-item item-align"
                  (click)="clickable('Core Material')"
                  [ngClass]="{
                    active: storeValue == 'Core Material'
                  }"
                >
                  CORE MATERIAL
                </li>
                <li
                  class="list-group-item item-align"
                  (click)="clickable('Finish')"
                  [ngClass]="{
                    active: storeValue == 'Finish'
                  }"
                >
                  FINISH
                </li>
                <li
                  class="list-group-item item-align"
                  (click)="clickable('Accessories')"
                  [ngClass]="{
                    active: storeValue == 'Accessories'
                  }"
                >
                  ACCESSORIES
                </li>
                <li
                  class="list-group-item item-align"
                  (click)="clickable('Hardware')"
                  [ngClass]="{
                    active: storeValue == 'Hardware'
                  }"
                >
                  HARDWARE
                </li>
                <li
                  class="list-group-item item-align"
                  (click)="clickable('Appliance')"
                  [ngClass]="{
                    active: storeValue == 'Appliance'
                  }"
                >
                  APPLIANCE
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- <div class="card">
          <div id="headingTwo">
            <h5 class="mb-0">
              <button
                class="btn collapsed KitchenIconText"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <img
                  src="../../../assets/images/Group-573.png"
                  class="kitchenIcon"
                />
                <p
                  class="kitchenBG"
                  (click)="clickOnHeading('Wardrobe Buying Guide')"
                  [ngClass]="{
                    activeHeader: clickHead == 'Wardrobe Buying Guide'
                  }"
                >
                  Wardrobe Buying Guide
                </p>
              </button>
            </h5>
          </div>

          <div
            id="collapseTwo"
            class="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordion"
          >
            <ul class="list-group kitchenLi">
              <li
                class="list-group-item kitchenitem item-align"
                (click)="clickable('Know Your Kitchen')"
                [ngClass]="{
                  active: storeValue == 'Know Your Kitchen'
                }"
              >
                KNOW YOUR KITCHEN
              </li>
              <li
                class="list-group-item item-align"
                (click)="clickable('Kitchen Shape')"
                [ngClass]="{
                  active: storeValue == 'Kitchen Shape'
                }"
              >
                KITCHEN SHAPE
              </li>
              <li
                class="list-group-item item-align"
                (click)="clickable('Kitchen Size')"
                [ngClass]="{
                  active: storeValue == 'Kitchen Size'
                }"
              >
                KITCHEN SIZE
              </li>
              <li
                class="list-group-item item-align"
                (click)="clickable('Base Unit')"
                [ngClass]="{
                  active: storeValue == 'Base Unit'
                }"
              >
                BASE UNIT
              </li>
              <li
                class="list-group-item item-align"
                (click)="clickable('Wall Unit')"
                [ngClass]="{
                  active: storeValue == 'Wall Unit'
                }"
              >
                WALL UNIT
              </li>
              <li
                class="list-group-item item-align"
                (click)="clickable('Kitchen Top')"
                [ngClass]="{
                  active: storeValue == 'Kitchen Top'
                }"
              >
                KITCHEN TOP
              </li>
              <li
                class="list-group-item item-align"
                (click)="clickable('Core Material')"
                [ngClass]="{
                  active: storeValue == 'Core Material'
                }"
              >
                CORE MATERIAL
              </li>
              <li
                class="list-group-item item-align"
                (click)="clickable(' Finish')"
                [ngClass]="{
                  active: storeValue == ' Finish'
                }"
              >
                FINISH
              </li>
              <li
                class="list-group-item item-align"
                (click)="clickable('Accessories')"
                [ngClass]="{
                  active: storeValue == 'Accessories'
                }"
              >
                ACCESSORIES
              </li>
              <li
                class="list-group-item item-align"
                (click)="clickable('Hardware')"
                [ngClass]="{
                  active: storeValue == 'Hardware'
                }"
              >
                HARDWARE
              </li>
              <li
                class="list-group-item item-align"
                (click)="clickable('Appliance')"
                [ngClass]="{
                  active: storeValue == 'Appliance'
                }"
              >
                APPLIANCE
              </li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>
    <div class="col-md-9 col-sm-12">
      <div>
        <div
          class="tab-pane"
          id="list-home"
          role="tabpanel"
          aria-labelledby="list-home-list"
        >
          <div class="card-body">
            <h2 class="headText">{{ storeValue }}</h2>
            <div class="lineText">
              <p class="line2"></p>
              <p class="textp">
                The kitchen is one of the most important and busiest parts of
                the house
              </p>
            </div>
            <div class="paragraph">
              <p>
                The kitchen is one of the most important and busiest parts of
                the house. Cooking in the kitchen can be fun, but if things
                aren’t organized and well-maintained, managing the kitchen can
                become quite hectic.
              </p>
              <p>
                However, there are some simple adjustments that you can make to
                make your kitchen a lot more efficient and easier to use.
                Therefore, check out the six tips to take your kitchen workflow
                to the next level.
              </p>
              <p>
                Generally, the stove, sink, and refrigerator are the three
                busiest stations in a kitchen; they form a work triangle, which
                you need to optimize. Your work triangle can be different
                depending on what you are doing; for instance, if you are
                baking, it could be between the counter, refrigerator, and oven.
              </p>
            </div>
            <div>
              <img
                src="../../../assets/images/kitchen1.png"
                class="img-fluid"
              />
            </div>
            <div class="paragraph">
              <p>
                The main idea is to identify the three busiest parts of your
                kitchen and create a flow between them.
              </p>
              <p>
                You should aim to reduce the clutter at these spots and minimize
                the obstacles between them. For instance, the refrigerator and
                stove shouldn’t be on opposite sides of the center island so
                that you can access frequently used parts of the kitchen easily.
              </p>
            </div>

            <h2 class="h2text">Invest in new kitchen appliances</h2>
            <div class="paragraph">
              <p>
                When it comes to the overall efficiency of your kitchen, your
                appliances play a key role. You should consider upgrading your
                kitchen appliances to make things easier.
              </p>
              <p>
                For instance, you can add a dishwasher to your kitchen and maybe
                opt for a multi-burner stove so that you can multitask. Check
                out Blue Rhino griddle vs Blackstone griddle reviews to get a
                nice griddle for your kitchen.
              </p>
              <p>
                You should also consider investing in a smart refrigerator that
                can also act as a digital kitchen assistant.
              </p>
            </div>
            <div>
              <img
                src="../../../assets/images/kitchen2.png"
                class="img-fluid"
              />
            </div>
            <div class="paragraph">
              <p>
                The main idea is to identify the three busiest parts of your
                kitchen and create a flow between them. You should aim to reduce
                the clutter at these spots and minimize the obstacles between
                them.
              </p>
            </div>
          </div>
        </div>

        <div
          class="tab-pane"
          id="list-home"
          role="tabpanel"
          aria-labelledby="list-home-list"
        >
          <div class="card-body">
            <h2 class="headText">Kitchen Shape</h2>
            <div>
              <img
                src="../../../assets/images/kitchen1.png"
                class="img-fluid"
              />
            </div>
            <div class="paragraph">
              <p>
                The main idea is to identify the three busiest parts of your
                kitchen and create a flow between them.
              </p>
              <p>
                You should aim to reduce the clutter at these spots and minimize
                the obstacles between them. For instance, the refrigerator and
                stove shouldn’t be on opposite sides of the center island so
                that you can access frequently used parts of the kitchen easily.
              </p>
            </div>
          </div>
        </div>

        <div
          id="collapseThree"
          class="collapse"
          aria-labelledby="headingThree"
          data-parent="#accordion"
        >
          <div class="card-body">
            <h3 class="headText">Kitchen Size</h3>
            <div>
              <img
                src="../../../assets/images/kitchen2.png"
                class="img-fluid"
              />
            </div>
            <div class="paragraph">
              <p>
                The main idea is to identify the three busiest parts of your
                kitchen and create a flow between them. You should aim to reduce
                the clutter at these spots and minimize the obstacles between
                them.
              </p>
            </div>
            <div class="inputRectangle">
              <h3 class="h3text">
                Enjoyed our Modular Kitchen Guides? Get the full PDF on Email or
                WhatsApp
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-aboutusone></app-aboutusone>
<app-aboutustwo></app-aboutustwo>
<app-footer></app-footer>
