import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BookConsultationFormComponent } from 'src/app/shared/book-consultation-form/book-consultation-form.component';
import { SpaceService } from 'src/app/spaces/space.service';

@Component({
  selector: 'app-theme',
  templateUrl: './theme.component.html',
  styleUrls: ['./theme.component.scss']
})
export class ThemeComponent implements OnInit {

  spaceDropdown: any;
  api_data: any;
  space_name: any;
  lifestage: any;
  theme: any;
  theme_filter_value: any = 'Contemporary';
  space: any;
  space_filter_value: any = 'Kitchen';
  ayz: any;
  abc: any;
  list: any;
  themes: any;
  constructor(public spaceService: SpaceService,private router: Router, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getDropdownSpace();
    this.getThemes();
    this.themeFilter({ name: "Contemporary" })

    if (this.initialspace === undefined) {
      this.initialspace = "Living"
    }
    this.spaceFilter(this.initialspace);
  }
  
  getDropdownSpace(){
    this.spaceService.getDropdownspace().subscribe(data=>{
      this.spaceDropdown = data; 
      this.api_data = Object.values(this.spaceDropdown);
      this.space_name = this.api_data[0];

      this.lifestage = this.api_data[1];
      this.theme = this.api_data[2];

   this.getthemeList("", "Minimalist");
    })
  }


  filteresValue: any='';
  space_filter1: any = '';
  young_vibes_lifes: any = 'Contemporary';
  themeFilter(data:any) {
    this.theme_filter_value = data;
    if (this.theme_filter_value.name === "Contemporary") {
      this.young_vibes_lifes = "Contemporary"
    }
    if (this.theme_filter_value.name === "Eclectic") {
      this.young_vibes_lifes = "Eclectic"
    }
    if (this.theme_filter_value.name === "Indian Ethnic") {
      this.young_vibes_lifes = "Indian Ethnic"
    }
    if (this.theme_filter_value.name === "Minimalistic") {
      this.young_vibes_lifes = "Minimalist"
    }
    if (this.theme_filter_value.name === "Modern") {
      this.young_vibes_lifes = "Modern"
    }
    if (this.theme_filter_value.name === "Rustic Industrial") {
      this.young_vibes_lifes = "Rustic Industrial"
    }
    if(this.theme_filter_value.name === "Transitional") {
      this.young_vibes_lifes = "Transitional"
    }

    this.getthemeList(this.space_filter1,this.young_vibes_lifes);
  }

  spaceFilter(data:any) {
    this.space_filter_value = data; 
    this.getTotalList(this.space_filter_value,this.young_vibes_lifes);
  }

  list1: any;
  initialspace: any;
  getthemeList(space:any,theme:any){
    this.spaceService.getPortfolioList(space,'',theme,'','','','').subscribe(data=>{
      this.list1 = data;
      this.ayz = [...new Set(this.list1.data.map((i:any) => i.attributes.space))];
      this.abc = this.ayz;
      this.space_filter_value = this.abc[0];
      if(this.list.data.length !== 0){
      }
      this.getTotalList(this.space_filter_value,this.young_vibes_lifes);
    })
  }

  getTotalList(space:any,theme:any){
    this.spaceService.getPortfolioList(space,'',theme,'','','','').subscribe(data=>{
      this.list = data; 
    })
  } 

  getThemes() {
    this.themes = [{
      name: 'Contemporary'
    },
      { name: 'Eclectic' }, { name: 'Indian Ethnic' }, { name: 'Minimalistic' }, { name: 'Modern' },
      {name: 'Rustic Industrial'}, {name: 'Transitional'}
    ]
  }
  btnClick(id: any) {
    window.open(
    ('/space-inclusion/' + id),'_blank' );
  }

  bookConsultationLandingPage() {
    const modal = this.modalService.open(BookConsultationFormComponent, {
      size: 'md',
    });
  }
  viewAllthemes() {
    this.router.navigate(['/spaces'],
    { queryParams: {space_name: this.space_filter_value, theme_name: this.young_vibes_lifes}})
  }
}
