<div class="reviewDeatil w-100">
  <app-new-header-for-home></app-new-header-for-home>

  <div class="w-100 Reviewbg container pt-5 col-12 pl-zero  mt-5 row ">
      <div class="col-md-7 pl-zero left-side " #leftside (scroll)="onLeftScroll($event)">
        <div class="row w-100 pl-zero row mt-3 mx-md-4 justify-content-center">
          <div class="col-1 d-md-none"></div>
          
          <div *ngIf="iframeon" class=" col-10 p-0 d-flex justify-content-center">
            <iframe  class="mainimage  w-100" allow="autoplay" [src]="video" frameborder="0" allowfullscreen></iframe>
          </div>
          <img *ngIf="!iframeon" class="mainimage col-10 p-0 " id="img-lg-zoom" src="{{ medium_image }}" onError="this.src = 'assets/v3/img/noImg.png' " />

        </div>
       
        <div class="w-100 pl-zero cursddd row mt-3 mx-md-4 justify-content-center" style="position: relative;">
          <div *ngIf="ReviewDetail?.images.length > 2" class="col-1 prev  d-flex align-items-center pb-3">
            <div class="float-right">
              <div class="other-mobile-boqs"  >
                <img class="cursorPointer"  (click)="slickController3.prev()"  src="../../../assets/images/arrivaeHomePageImages/previcon.png" alt="previous" >
              </div>
            </div>   
          </div>
          <div class="col-md-10 col-8 pl-233 p-0 mb-md-5">
            <div class="Buildings_container row mx-0 mt-4">
              <div class="mobile-carosel-active-boqs w-100">
                <div slickContainer #slickController3="slick" [slickConfig]="config3" class="minicursoel">
                  <ng-container *ngIf="slickController.initialize">
                    <div  slickItem class="cursorPointer px-2 px-md-1 d-flex justify-content-center"  *ngIf="video" >
                      <img class="w-100" (click)="playvideo()" src="../../../assets/images/Banner 1 (2).png" [alt]="'Slide'">
                     </div> 
                    <div *ngFor="let item of images  " slickItem class="cursorPointer px-2 px-md-1 d-flex justify-content-center" >
                      
                     <img (click)="Setmedium(item)" class="w-100" src="{{item}}" >
                    
                    </div>  
                   
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="ReviewDetail?.images.length > 2"  class="col-1 next nextl  d-flex align-items-center pb-3">
            <div class="float-right floatInMobile ">
              <div class="other-mobile-boqs"  >
                <img class="cursorPointer" (click)="slickController3.next()" src="../../../assets/images/arrivaeHomePageImages/nexticon.png" alt="next" >
              </div>
            </div>
          </div> 
        </div>
      </div>
      <div class="col-md-5 right-side " #rightside>
          <div class="w-100 DetailsContainer pl-0  p-4">
            <h3>{{ReviewDetail?.customer_name}}</h3>
            <div class="mt-2 starContainer">
                <span *ngFor="let star of [1, 2, 3, 4, 5]" class="star pr-2"[class.active]="star <= ReviewDetail?.rating">&#9733;</span>
            </div>
            <p class="desReview mt-2">{{ReviewDetail?.review}}</p>
            <h3 class="mt-5 pb-2 border-bottom" (click)="ShowProjectDetails()">
                Project Details 
                <i class="fa fa-icon fa-angle-down" aria-hidden="true"></i>
            </h3>
            <div class="my-3" >
              <p class="desReview m-0 pb-2" > <span class="font-weight-bold"> Apartment :</span> {{ReviewDetail?.apartment_name}}</p>
              <p class="desReview m-0" *ngIf="ReviewDetail?.project_scopes"> <span class="font-weight-bold">Project Scope:</span> {{ReviewDetail?.project_scopes}}</p>
              <!-- <button (click)="bookConsultationLandingPage()" class="btn px-3 py-2 mt-3 btn-Design ">Get this design</button> -->
            </div>
          </div>
      </div>
  </div>

  <div class="mt-3 w-100 pt-2 pb-md-5 mb-4 homes-delivered">
    <div class=" align-items-center Slider-mobile">
      <h3 class=" mt-md-3 mb-md-3 headingHomesAtlocation">homes delivered at this location</h3>
        <div class="col-12 mb-md-5 p-0">
          <div class="Buildings_container row mx-0 mt-4">
            <div class="mobile-carosel-active-boqs w-100">
              <div slickContainer #slickController="slick" [slickConfig]="config">
                <ng-container *ngIf="slickController.initialize">
                  <div *ngFor="let item of ReviewDetail?.more_reviews " slickItem class="card ml-lg-3  cursorPointer" style="width: 800px!important;">
                    <div (click)="OpenInDeatil(item.id)" class="w-100 d-flex justify-content-center align-items-center  ReviewCarier"  [ngStyle]="{ 'background-image': 'url(' + item.feature_image + ')' }" >
                      <div class="w-100 h-100 d-flex bgBlur justify-content-center align-items-center ">
                        <div class="w-75 DetailsOFslide">
                          <h3 class="mt-3 mb-3 headingHomesAtlocation">{{item.customer_name}}</h3>
                          <p class="truncate">{{item.review	}}</p>
                        </div>
                      </div>
                    </div>
                  </div>     
                </ng-container>
              </div>
            </div>
          </div>
        </div>

        <!-- <div *ngIf="ReviewDetail?.more_reviews.length > 1" class="d-flex w-100 mt-2 icons_moile_padd justify-content-between"> -->
          <div class="col-1 prev">
            <div class="float-right">
              <div class="other-mobile-boqs"  >
                <img class="cursorPointer"  (click)="slickController.prev()"  src="../../../assets/images/arrivaeHomePageImages/previcon.png" alt="previous" >
              </div>
            </div>   
          </div>
            <div class="col-1 next pr-5">
              <div class="float-right floatInMobile ">
                <div class="other-mobile-boqs"  >
                  <img class="cursorPointer" (click)="slickController.next()" src="../../../assets/images/arrivaeHomePageImages/nexticon.png" alt="next" >
                </div>
              </div>
            </div>  
        <!-- </div> -->
      
      <div class="d-flex w-100 mt-4 justify-content-center" >
        <button (click)="navigateAll()" class="btn-All px-5 py-2">
            VIEW ALL 
        </button>
      </div>
      
    
     </div>
  </div>



  <div class="mt-3 w-100 pt-2 pb-5 mb-4 explore">
    <div class=" align-items-center Slider-mobile ">
      <h3 class=" mt-md-3  headingHomesAtlocation px-2 px-md-0">EXPLORE OUR DESIGN LIBRARY</h3>
        <div class="col-12 p-0">
           <div class="Buildings_container row mx-0 mt-4">
              <img *ngIf="ReviewDetail?.preset_details.length > 1" class="cursorPointer prev-space"  (click)="slickController2.prev()"  src="../../../assets/images/arrivaeHomePageImages/previcon.png" alt="previous" >
              <img *ngIf="ReviewDetail?.preset_details.length > 1"  class="cursorPointer prev-next" (click)="slickController2.next()" src="../../../assets/images/arrivaeHomePageImages/nexticon.png" alt="next" >
              <div class="mobile-carosel-active-boqs w-100">
                <div slickContainer #slickController2="slick" [slickConfig]="config">
                  <ng-container *ngIf="slickController.initialize">
                      <div *ngFor="let item of ReviewDetail?.preset_details" slickItem class="card ml-lg-3  cursorPointer" >
                        <div (click)="ClickOnSpace(item.id)" class="w-100 d-flex justify-content-center align-items-center  ReviewCarier"  [ngStyle]="{ 'background-image': 'url(' + item.attachment_file[0] + ')' }" >
                        </div>
                      </div>       
                  </ng-container>
                </div>
              </div>
            </div>
        </div>
        <div class="d-flex w-100 mt-4 justify-content-center" >
          <button (click)="navigateAllspace()" class="btn-All px-5 py-2">
              VIEW ALL 
          </button>
        </div>
    </div>
  </div>

  <app-frequent-questions></app-frequent-questions>
  <app-footer></app-footer>

</div>

<div class="loading d-flex justify-content-center" *ngIf="loader">
    <div class="spinner-border" style="color: #8d0528;" role="status">
       <span class="sr-only">Loading...</span>
    </div>
 </div>
