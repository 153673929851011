import { Component, OnInit } from '@angular/core';
import { ApartmentService } from 'src/app/apartment/apartment.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {

  selectedCity: any;
  searchcity: any = '';
  serachedapart: any = '';
  totalApartmentdata: any;
  finalcity: any = '';
  loader: boolean = false;
  per_page: any = 6;
  cityLoader :any;
  vrLink: boolean = false;
  show_viewMore: boolean = false;
  gallerydata: any;
  unid: any;
  search_string: any = '';
  apartment_no: any = '';
  gallerydataApartment: any;
  theme_filter_value: any = '';
  lifeStage_filter_value: any = '';
  mini_loader : boolean = false
  moreLoader :any
  constructor( private apartmentservice: ApartmentService) { }

  ngOnInit(): void {
    this.loadScript()
  }

  customFunc(data: any) {
    this.per_page = 6;
    this.selectedCity = data.selectedCity;
    this.apartmentList(data.selectedCity, '');
  }

  apartmentList(city: any, search: any) {
    this.searchcity = city;
    if (localStorage.getItem('selectedCity') == null) {
      this.selectedCity = 'mumbai';
    } else {
      this.selectedCity = localStorage.getItem('selectedCity');
    }
    this.finalcity = this.searchcity;
    this.search_string = this.serachedapart;

    if (this.searchcity === '') {
      this.finalcity = this.selectedCity;
    }
    if (this.serachedapart === undefined) {
      this.search_string = search;
    }
    this.apartmentservice
      .getApartmentlist(
        this.finalcity,
        this.search_string,
        this.per_page,
        this.lifeStage_filter_value,
        this.theme_filter_value,
        this.vrLink
      )
      .subscribe((data) => {
        this.gallerydata = data;
        this.apartment_no = this.gallerydata['apartment count'];
        this.unid = this.gallerydata.unid;
        this.gallerydataApartment = this.gallerydata.data.data;
        this.totalApartmentdata = this.gallerydata['apartment count'];
        if (this.per_page >= this.totalApartmentdata) {
          this.show_viewMore = false;
        } else {
          this.show_viewMore = true;
        }
        // this.placeholdervalue="Search your apartment from"+ ' ' +this.gallerydataApartment.length+' ' + "apartments we designed in"+' '   +city+' ' + "by Apartment Name, Builder,Sector or Pin Code";
        if( this.cityLoader &&  !this.moreLoader){
          this.loader = true;
          setTimeout(() => {
            this.loader = false;
          }, 2000);
        }
        if(this.moreLoader){
          this.mini_loader = true
          setTimeout(() => {
            this.mini_loader = false;
          }, 500);
        }
      });
  }

  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
