import { Component, OnInit, Renderer2 } from '@angular/core';
import { SharedService } from '../shared.service';
import {  Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from "@angular/platform-browser";

declare var $: any;
@Component({
  selector: 'app-visit-us-new',
  templateUrl: './visit-us-new.component.html',
  styleUrls: ['./visit-us-new.component.scss']
})
export class VisitUsNewComponent implements OnInit {

  per_page: any = 6;
  selectedCity: any;
  search_string: any = '';
  city_value: any = '';
  searchcity: any = '';
  loader: boolean = false;
  finalcity: any = '';
  searchApartment = '';
  city_filter:any;
  citydata1: any;
  serachedspace: any = '';
  serachedapart: any = '';
  unid: any;
  list: any;
  citydata: any;
  apartment_no: any = '';
  show_viewMore: boolean = false;
  propValue :any
  choose_city:any=''
  selector:any;
  url: any;
  constructor(
    private sharedService: SharedService ,
    private metaService: Meta,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _DomSanitizationService: DomSanitizer,
    private renderer: Renderer2,
    ) {
      this.activatedRoute.queryParamMap.subscribe((params: any) => {
        this.selector = params.params.selector;
      });
    }
  ngOnInit(): void {
    this.choose_city=''
    window.scrollTo(0, 0);
    this.getCities();
    this.metaService.addTag(
      {name: 'description', content: 'Visit Your Nearest Experience Centre to see home interior mock-ups, check out the material gallery, meet our designer and more.  PAN India Presence in 21 Cities | 5 Experience Centers | 3 Manufacturing Units | 35 Experience Points | Robust Aftersales Support'}
    );
    this.loadScript()
  }

  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  getCities() {
    this.sharedService.getCities().subscribe((res) => {
      this.citydata = res;
      this.citydata1 = this.citydata.data;
      if (this.selector) {
        this.selectedCity = this.selector;
        this.propValue = this.selectedCity
         for ( let ct of this.citydata1){
          if(ct.attributes.name == this.selectedCity ){
            this.city_value = ct.id
            this.getLocationPoints(this.city_value , '')
            break
          }
        }
      } else {
        this.city_value = ''
        this.propValue = ''
        this.getLocationPoints(this.city_value , '')
      }
    });
  }

  ngOnDestroy(){
    this.metaService.removeTag("name='description'");
  }

  Center :any
  EachCenter :any
  getLocationPoints(cityValue :any , searchValue:any){
    this.loader = true;
    this.sharedService.getpoints(cityValue, searchValue).subscribe((res) => {
      setTimeout(() => {
        this.loader = false;
      }, 2000);
      this.Center = res
      this.EachCenter = this.Center.data
    })
  }
  transform(value: string): string {
    return value.replace(/_/g, ' ');
  }
  customFunc(data: any) {
    this.selectedCity = data.selectedCity;
    this.propValue = data.selectedCity
    for(let d of this.citydata1){
      if(d.attributes.name == this.selectedCity){
        this.city_value = d.id
        this.getLocationPoints(this.city_value , '')
        break
      }
    }
    this.search_string = ''
    this.displayValue = ''
  }
  GoButtonClick(value :any){
    this.search_string = value
    this.getLocationPoints(this.city_value, this.search_string)
    this.displayValue = this.search_string
  }
  displayValue : any
  submitValue(value :any){ 
    this.search_string = value
    this.displayValue = this.search_string
    this.getLocationPoints(this.city_value, this.search_string)
  }
  cityFilter(event: any) { 
    this.city_value = event.target.value;
    this.getLocationPoints(this.city_value, '')
    if(this.city_value){
      this.citydata1.map((ittr:any , index:any) => {
        if(ittr.id === this.city_value) {
          this.propValue = ittr.attributes.name
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { selector: ittr.attributes.name },
            queryParamsHandling: 'merge',
          });
        }      
      })
    }else{
      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: {selector:''},
        queryParamsHandling: 'merge',
      });
    }
    this.search_string = ''
    this.displayValue = ''
  }
  location(id:any , name:any){
    this.propValue = name
    this.getLocationPoints(id, '')
    this.city_value = id
    this.search_string = ''
    this.displayValue = ''
  }


  current_url :any = "https://www.youtube.com/embed/vOSeT07sixE"
  // transform(url: any) {

  //   return this._DomSanitizationService.bypassSecurityTrustResourceUrl(url);

  // }
  videoPlayIn(url :any){
    console.log('clicking');
    this.allVideos = true
    this.current_url=this._DomSanitizationService.bypassSecurityTrustResourceUrl(url)
  }

  allVideos :any = false

modaltitle:any
  openModal(Location: any,Address:any) {
    $('#myModal').modal('show');
    if(Address.includes('VR Bengaluru')){
      this.url='https://www.youtube.com/embed/vOSeT07sixE?autoplay=1&loop=1'
      this.modaltitle="VR Mall, Bengaluru"
    }
    else{
      this.url='https://www.youtube.com/embed/hbevvQ6FQVw?autoplay=1&loop=1'
      this.modaltitle="Trillium Avenue, Gurugram"
    }
    this.videoPlayIn(this.url)

  }

  closeModal() {
    this.allVideos=false
    $('#myModal').modal('hide');
  }


}
