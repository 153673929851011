<div class="end-to-end">
    <div class="d-flex flex-column mb-2 mx-5 main-heading">
        <p class="mb-1"><span class="pb-1 font-weight-bold">END-TO-END</span> INTERIOR SOLUTIONS</p>
        <span class="one-stop" >A one-stop shop for all your interior requirements</span>
        <div  *ngIf="roletoSet() > 650 ">
            <button type="button"  (click)="bookConsultationLandingPage()" class="mt-2 button d-flex align-items-center Book_now">
                <a>BOOK NOW&nbsp;
                    <span><img src="../../../assets/images/Arrow 1.png"/></span>
                </a>
            </button>
        </div>
    </div>
    <div class="row justify-content-center m-0">
        <div class="col-11">
            <div class="interiors row justify-content-center d-flex">
               <ng-container *ngFor="let solution of solutions">
                    <div class="solutions align-items-center col-md-15  col-sm-15 col-xs-15 mt-4 d-flex flex-column" >
                        <img src={{solution.image}}>
                        <span class="mt-2">
                            {{solution.tag}}
                        </span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div  *ngIf="roletoSet() < 650 " class="justify-content-center d-flex my-4" >
        <button type="button"  (click)="bookConsultationLandingPage()" class=" button d-flex align-items-center Book_now">
            BOOK NOW&nbsp;
            <span><img src="../../../assets/images/Arrow 1.png"/></span>
            
        </button>
    </div>
</div>
