import { Component, OnInit, Sanitizer} from '@angular/core';
import {
  DomSanitizer, SafeResourceUrl,
} from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EmbedVideoService } from 'ngx-embed-video';
import { ToastrService } from 'ngx-toastr';
import { SpaceService } from 'src/app/spaces/space.service';
import { BookConsultationFormComponent } from '../../shared/book-consultation-form/book-consultation-form.component';
import { ApartmentGetThisDesignFormComponent } from '../apartment-get-this-design-form/apartment-get-this-design-form.component';
import { ApartmentService } from '../apartment.service';
declare var $: any;
declare function apartmentViewed(
  param1: any,
  param2: any,
  param3: any,
  param4: any
): any;
declare function getthisDesign(param1: any, param2: any, param3: any): any;

@Component({
  selector: 'app-apartment-search',
  templateUrl: './apartment-search.component.html',
  styleUrls: ['./apartment-search.component.scss'],
})
export class ApartmentSearchComponent implements OnInit {
  apartmentDetailsData: any;
  selectedLevel: any;
  selectedBHKData: any;
  selectedApartmentdetailsdata: any;
  apartmentSpacesArray: any;
  apartmentName: any;
  departmentName: any;
  apartmentSingleIndexValue: any;
  loader: boolean = false;
  emidata: any;
  sizedata: any;
  unidData: any;
  initialData: any;
  bhkData: any = '';
  bhkData2: any;
  bhkDatavarient: any;
  apartvarandspaceData: any;
  apartInitialData: any;
  finalbhk: any;
  spaceId: any;
  apartmentImageData: any;
  apartmentImages: any;
  bhkvaule: boolean = false;
  cedula: any;
  safecontent: any;
  vr_link: any;
  constructor(
    private apartmentservice: ApartmentService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private router: Router,
    private spaceService: SpaceService,
    private embedService: EmbedVideoService,
    private _DomSanitizationService: DomSanitizer,
    private sanitizer: Sanitizer
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.apartmentName = params['apartment_name'];
      this.departmentName = params['developer_name'];
      this.unidData = params['unid'];
    });
    this.getApartmentVarients();
    this.loadScript()
  }
  fetchData: any;
  ayz: any;
  final_items: any = [];
  getData(id: any) {
    this.spaceService.getPortfolios(id).subscribe((data) => {
      this.fetchData = data;
      for (
        let i = 0;
        i < this.fetchData.data.attributes.space_items.data.length;
        i++
      ) {
        this.selected_item_data.push({
          name: this.fetchData.data.attributes.space_items.data[i].attributes
            .item_name,
          price: 0,
          quantity: 1,
        });
      }
    });
  }
  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  storeditems: any;
  showModal = false;
  onClickInclusion(data: any) {
    this.apartmentservice.getApartmentSpaceItems(data).subscribe((res) => {
      console.log(res);
      this.storeditems = res;
      if (this.storeditems?.data.length !== 0) {
        this.showModal = true;
      }
    });
  }

  lifestagedata: any;
  dropdownoptions: any = [];
  getApartmentVarients() {
    this.apartmentservice.getVarients(this.unidData).subscribe((res) => {
      this.apartmentDetailsData = res;
      this.dropdownoptions = this.apartmentDetailsData.varients;
      this.lifestagedata = this.apartmentDetailsData.lifestage;
      this.bhkData = this.apartmentDetailsData.varients[0];

      this.getApartmentnamedata(this.bhkData);
    });
  }

  spaceFilter(event: any) {
    this.bhkData = event.target.value;

    this.getApartmentnamedata(this.bhkData);
  }

  cleanURL(oldURL: string): SafeResourceUrl {
    return this._DomSanitizationService.bypassSecurityTrustUrl(oldURL);
    // return this.sanitizer.bypassSecurityTrustUrl(this.mediaItems[1].url);
  }
  apartment_name: any;
  initialId: any;
  secondId: any;
  initialSpace: any;
  secondSpace: any;
  apartmentinclusion: any;
  selectapartment: any;
  selectcity: any;
  selectbhk: any;
  selectpin: any;
  condi1: boolean = false;
  condi2: boolean = false;
  condi3: boolean = false;
  video_url: any = '';
  show_testimonial: boolean = false;
  video_1: boolean = false;
  video_2: boolean = false;
  video_3: boolean = false;
  video_4: boolean = false;
  video_5: boolean = false;
  video_6: boolean = false;
  video_7: boolean = false;
  video_8: boolean = false;
  // video_9: boolean = false;
  getApartmentnamedata(bhk: any) {
    this.video_1 = false;
    this.video_2 = false;
    this.video_3 = false;
    this.video_4 = false;
    this.video_5 = false;
    this.video_6 = false;
    this.video_7 = false;
    this.video_8 = false;
    this.show_testimonial = false;
    this.apartmentservice
      .getVarientandspace(this.unidData, this.bhkData)
      .subscribe((res) => {
        this.apartvarandspaceData = res;
        console.log(this.apartvarandspaceData);

        this.selectapartment =
          this.apartvarandspaceData[0]?.attributes.apartment_name;
        if (this.selectapartment == 'Godrej Infinity') {
          this.show_testimonial = true;
          this.video_1 = true;
        }
        if (this.selectapartment == 'Lodha Upper Thane') {
          this.show_testimonial = true;
          this.video_2 = true;
        }
        if (this.selectapartment == 'Riverdale Heights') {
          this.show_testimonial = true;
          this.video_3 = true;
        }
        if (this.selectapartment == 'Kasturi Eon Homes') {
          this.show_testimonial = true;
          this.video_4 = true;
        }
        if (
          this.selectapartment == 'Lodha Belmondo' &&
          this.unidData == '410405LOD01PUN1'
        ) {
          this.show_testimonial = true;
          this.video_5 = true;
        }
        if (
          this.selectapartment == 'Oberoi Esquire' &&
          this.departmentName == 'Builder'
        ) {
          this.show_testimonial = true;
          this.video_6 = true;
        }
        if (
          this.selectapartment == 'Rustomjee Urbania' &&
          this.departmentName == 'Rustomjee'
        ) {
          this.show_testimonial = true;
          this.video_7 = true;
        }
        if (
          this.selectapartment == 'Shriram Greenfields' &&
          this.departmentName == 'Shriram Properties Ltd'
        ) {
          this.show_testimonial = true;
          this.video_8 = true;
        }
        this.selectcity = this.apartvarandspaceData[0]?.attributes.city;
        this.selectbhk = this.apartvarandspaceData[0]?.attributes.format;
        this.selectpin = this.apartvarandspaceData[0]?.attributes.pincode;
        this.initialId =
          this.apartvarandspaceData[0]?.attributes.apartment_spaces[0].id;
        this.initialSpace =
          this.apartvarandspaceData[0]?.attributes.apartment_spaces[0].name;
        apartmentViewed(
          this.apartmentName,
          this.departmentName,
          this.apartvarandspaceData[0].attributes.area.toString(),
          this.apartvarandspaceData[0].attributes.format
        );
        this.onClickSpaces(this.initialId, 0, this.initialSpace);
        this.selectedSpaces = this.initialSpace;
        if (this.apartvarandspaceData.length > 1) {
          this.secondId =
            this.apartvarandspaceData[1]?.attributes.apartment_spaces[0].id;
          this.secondSpace =
            this.apartvarandspaceData[1]?.attributes.apartment_spaces[0].name;
          apartmentViewed(
            this.apartmentName,
            this.departmentName,
            this.apartvarandspaceData[1].attributes.area.toString(),
            this.apartvarandspaceData[1].attributes.format
          );
        }

        // this.onClickSpaces(this.initialId, 0, this.initialSpace);
        this.onClickSpaces(this.secondId, 1, this.secondSpace);
        this.apartment_name =
          this.apartvarandspaceData[0]?.attributes.apartment_name;
      });
  }

  activated: any = [];
  selectedSpacesdata: any = [];
  selectedSpaces: any;
  spacesContent: any;
  spacesContentArray: any = [];
  xyz: any = [];
  storedURL: any;
  activelifes: any;
  onClickSpaces(data: any, tabIndex: any, name: any) {
    this.selectedSpaces = data;
    if (data === undefined) {
      data = this.initialId;
    }
    if (name === undefined) {
      name = this.initialSpace;
    }
    this.apartmentservice
      .getSpacecontent(this.selectedSpaces)
      .subscribe((res) => {
        this.apartmentImageData = res;
        // this.show_emi.push(this.apartmentImageData.attributes.emi);

        this.xyz[tabIndex] = this.apartmentImageData?.attributes.contents;
        this.show_lifes[tabIndex] =
          this.apartmentImageData?.attributes.lifestage;
        this.show_themes[tabIndex] = this.apartmentImageData?.attributes.theme;
        this.activated[tabIndex] = name;
        this.show_emi[tabIndex] = parseInt(
          this.apartmentImageData?.attributes.emi
        );
        this.show_space[tabIndex] = parseInt(
          this.apartmentImageData?.attributes.size
        );
        this.apartmentImages = this.apartmentImageData?.attributes.contents;
        if (
          res !== null &&
          this.apartmentImageData?.attributes.contents.length !== 0
        ) {
          this.storedURL =
            this.apartmentImageData?.attributes.contents[0].attributes.document;
        }
        if (this.apartmentImageData?.attributes.contents.length === 0) {
          this.storedURL = '';
        }
        this.onClickInclusion(data);
        if (this.selectedSpaces !== undefined) {
          this.getData(data);
        }
        if (this.apartmentImageData === null) {
          this.getData(data);
        }
      });
  }

  show_emi: any = [];
  show_lifes: any = [];
  show_themes: any = [];
  response: any;
  show_space: any = [];
  checkEmi(data: any, data1: any) {
    this.apartmentservice.getSpacecontent(data).subscribe((res) => {
      this.response = res;
      // this.show_emi = this.response.attributes.emi;
      // this.show_space = this.response.attributes.size;
    });
  }

  bookConsultation() {
    const modal = this.modalService.open(BookConsultationFormComponent, {
      size: 'md',
    });
    modal.result.then((res) => {});
  }

  selectId: any;
  selectfloorplan: string = '';
  getThisDesign(
    apartment_name: any,
    cityname: any,
    pincode: number,
    bhk: any,
    id: any,
    floorplan: any
  ) {
    this.selectapartment = apartment_name;
    this.selectcity = cityname;
    this.selectpin = pincode;
    this.selectbhk = bhk;
    this.selectId = id;

    if (floorplan === null) {
      this.selectfloorplan = '';
    } else {
      this.selectfloorplan = floorplan;
    }

    const modal = this.modalService.open(ApartmentGetThisDesignFormComponent, {
      size: 'md',
    });
    modal.componentInstance.apartment_name = apartment_name;
    modal.componentInstance.cityname = cityname;
    modal.componentInstance.pincode = pincode;
    modal.componentInstance.bhk = bhk;
    modal.componentInstance.id = id;
    modal.componentInstance.floorplan = floorplan;
    modal.componentInstance.num = 2;
    modal.componentInstance.items = this.selected_item_data;
    modal.result.then((res) => {});
  }

  getThisDesignmodal() {
    const modal = this.modalService.open(ApartmentGetThisDesignFormComponent, {
      size: 'md',
    });
    modal.componentInstance.apartment_name = this.selectapartment;
    modal.componentInstance.cityname = this.selectcity;
    modal.componentInstance.pincode = this.selectpin;
    modal.componentInstance.bhk = this.selectbhk;
    modal.componentInstance.num = 2;
    modal.componentInstance.floorplan = this.selectfloorplan;
    modal.componentInstance.items = this.selected_item_data;
    modal.result.then((res) => {});
  }

  current_id: any;
  itemIndex: any;
  _temp: any;
  _abc: any;
  _cs: any;
  activeId: any;

  OnPrivClick() {
    this._abc = '';
    this._abc = [];
    $('#holder').removeClass('spandesable');
  }

  OnNextClick() {
    this._abc = '';
    this._abc = [];
    $('#holderpre').addClass('spanenable');
    $('.targe').removeClass('prevDesable');
  }

  openimagedata: any;
  openImges(img: any) {
    this.openimagedata = img;
    $('#inclusionModal').modal('show');
  }
  closemodalimage() {
    $('#inclusionModal').modal('hide');
  }
  bookConsultationApartmentList() {
    const modal = this.modalService.open(BookConsultationFormComponent, {
      size: 'md',
    });
    modal.result.then((res) => {});
  }

  closemodal() {
    $('#apartinclusionModal').modal('hide');
  }

  // fetchData: any;
  imgresponse: any;
  imageopen() {
    $('#apartinclusionModal').modal('show');
    console.log(this.storedURL);
  }

  selected_item_data: any = [];
  getItems(event: any, id: any, item: any) {
    if (event.target.checked) {
      this.selected_item_data.push({
        name: item.item_name,
        price: 0,
        quantity: 1,
      });
    } else {
      this.selected_item_data.filter((element: any, index: any) => {
        if (element.name == item.item_name) {
          this.selected_item_data.splice(index, 1);
        }
      });
    }
  }

  vrvideospace(data: any) {
    $('#vrModal').modal('show');
    this.vr_link = data.attributes.vr_link;
    window.open(this.vr_link, '_blank');
  }

  imgSrc: string = '.../../../assets/images/360-Icon_Black.png';
  onHover() {
    this.imgSrc = '../../../assets/images/360-Icon_01.png';
  }

  onMouseOut() {
    this.imgSrc = '.../../../assets/images/360-Icon_Black.png';
  }

  transform(value: string): string {
    return value.replace(/_/g, ' ');
  }

  engageDesign(param1: any, param2: any, param3: any) {
    getthisDesign(param1, param2, param3);
  }
}
