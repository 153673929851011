<app-new-header-for-home   ></app-new-header-for-home>
<div class="root">

    <div *ngIf="roletoSet() > 600"  id="banner-image"   class="banner-image mt-md-5  m-md-0 mx-2">
        <div class="w-100 banner-imagew  pb-0 d-flex justify-content-lg-between align-items-center" >
           <div class="tagline w-100  ">
              <p class="ml-5 Head_P pl-3">
                 Design Your<br> 
                 Dream Home  <br>       
                
              </p>
              <p  class="ml-5 small_P pl-3">
                1 Million+ options <br>
                for every style <br>
              </p>
              <button class="get-price button_book  d-md-block mt-5" (click)="bookConsultationLandingPage()" >GET THE BEST PRICE</button>
              <div class="jayaho_logo mb-4   ml-md-5 d-block mt-5"> 
              </div>

              <div *ngIf="roletoSet() > 600" class="container-fluid mr-filter  ">
                <div class="row justify-content-center ">
                    <div class="col-md-12 divcontainer ">
                        <div class="row my-4 align-items-center">
                            <div class="col-md-12 px-filters">
                                <div class="row">
                                    <div class="col-md-3 brdrbtm">
                                        <div class="form-group border-0">
                                            <label class="  Filter-label ">Space Type</label>
                                            <select class="form-control border-0 text-capitalize spacetype"
                                                (change)="spaceFilter($event)">
                                                <option *ngIf="this.space_name2 === ''" selected disabled>Select</option>
                                                <option  value =''  >All</option>
                                                <ng-container *ngFor="let item of update_space">
                                                    <option [selected]="item == this.space_name2">{{ transform(item) }}</option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3 brdrbtm">
                                        <div class="form-group border-0">
                                            <label class=" Filter-label ">Life Stage </label>
                                            <select class="form-control border-0 text-capitalize lifestage"
                                                (change)="lifeStageFilter($event)">
                                                <option selected disabled>Select</option>
                                                <option value =''  >All</option>
                                                <ng-container *ngFor="let item of lifestage">
                                                    <option [selected]="item == this.lifeStage_filter_value1">{{ transform(item) }}
                                                    </option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group border-0">
                                            <label class=" Filter-label ">Theme</label>
                                            <select class="form-control border-0 text-capitalize theme"
                                                (change)="themeFilter($event)">
                                                <option selected disabled>Select</option>
                                                <option value =''  >All</option>
                                                <ng-container *ngFor="let item of update_theme">
                                                    <option [selected]="item == this.theme_filter_value" *ngIf="item !== ''">{{
                                                        transform(item) }}</option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group border-0">
                                            <label class=" Filter-label ">Price Range</label>
                                            <select class="form-control border-0 text-capitalize pricerange"
                                                (change)="priceFilter($event)">
                                                <option selected disabled>Select</option>
                                                <option value =''  >All</option>
                                                <ng-container *ngFor="let item of pricerange">
                                                    <option *ngIf="item !== ''">{{ item.name }}</option>
                                                </ng-container>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>      
           </div> 
          
        </div>
       
      
       
     </div>
     <div *ngIf="roletoSet() < 600"  id="banner-image"   class="banner-image mt-md-5  m-md-0 ">
        <div class="w-100 banner-imagew  pb-0 d-flex justify-content-lg-between align-items-end" >
           <div class="tagline w-100  ">
              <p class=" Head_P pl-3">
                 Design Your<br> 
                 Dream Home  <br>       
                
              </p>
              <p  class=" small_P pl-3">
                1 Million+ options <br>
                for every style <br>
              </p>
              <div class="jayaho_logo mb-4   ml-md-5 d-block "> 
              </div>

   
           </div> 
          
        </div>
       
      
       
     </div>

     <div *ngIf="roletoSet() < 600" class="container-fluid ">
        <div class="row justify-content-center ">
            <div class="col-md-12 divcontainer ">
                <div class="row my-4 align-items-center">
                    <div class="col-md-12 ">
                        <div class="row">
                            <div class="col-md-3 brdrbtm">
                                <div class="form-group border-0">
                                    <label class="  Filter-label ">Space Type</label>
                                    <select class="form-control border-0 text-capitalize spacetype"
                                        (change)="spaceFilter($event)">
                                        <option *ngIf="this.space_name2 === ''" selected disabled>Select</option>
                                        <option value =''  >All</option>
                                        <ng-container *ngFor="let item of update_space">
                                            <option [selected]="item == this.space_name2">{{ transform(item) }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3 brdrbtm">
                                <div class="form-group border-0">
                                    <label class=" Filter-label ">Life Stage </label>
                                    <select class="form-control border-0 text-capitalize lifestage"
                                        (change)="lifeStageFilter($event)">
                                        <option selected disabled>Select</option>
                                        <option value =''  >All</option>
                                        <ng-container *ngFor="let item of lifestage">
                                            <option [selected]="item == this.lifeStage_filter_value1">{{ transform(item) }}
                                            </option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group border-0">
                                    <label class=" Filter-label ">Theme</label>
                                    <select class="form-control border-0 text-capitalize theme"
                                        (change)="themeFilter($event)">
                                        <option selected disabled>Select</option>
                                        <option value =''  >All</option>
                                        <ng-container *ngFor="let item of update_theme">
                                            <option [selected]="item == this.theme_filter_value" *ngIf="item !== ''">{{
                                                transform(item) }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group border-0">
                                    <label class=" Filter-label ">Price Range</label>
                                    <select class="form-control border-0 text-capitalize pricerange"
                                        (change)="priceFilter($event)">
                                        <option selected disabled>Select</option>
                                        <option value =''  >All</option>
                                        <ng-container *ngFor="let item of pricerange">
                                            <option *ngIf="item !== ''">{{ item.name }}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>      


    <div class="container-fluid">
        <div class="row justify-content-center bg-spaces">
           
        <div class="col-md-11 col-lg-11 col-sm-12">
        <div class="row">
           
        <ng-container *ngFor="
        let portfolio of list?.data
        | paginate
        : {
        itemsPerPage: per_page,
        currentPage: current_page,
        totalItems: total_page
        };
        let i = index
        ">
        <a (click)="openSpaceModal(portfolio.attributes.id ,portfolio.attributes.space ,portfolio.attributes.lifestage, portfolio.attributes.theme , portfolio )" *ngIf="i<per_page" class="col-lg-6 px-0 px-lg-3 space-img" target="_blank">
        <img class="w-100 h-100 cursorPointer" src="{{portfolio.attributes.attachment_file}}" onerror="src='../../../assets/images/default-apartment.png'" >
        <div class="overlayspace mt-lg-n4 d-md-block d-none"
        style="display: flex;flex-direction: column;">
        <div>
        <span class="d-inline-block mb-2 space-name ml-n4">{{portfolio.attributes.space}}
        </span>
        </div>
        <div>
        <span class="d-inline-block float-left view-details ml-lg-n4">View Details</span>
        </div>
        </div>
        
        <div class="overlayspace justify-content-between mt-n2 d-sm-block d-md-none">
        <span class="d-inline-block space-name ml-n4">{{portfolio.attributes.space}} </span>
        <span class="d-inline-block float-right view-details mr-5">View Details</span>
        </div>
        </a>
        </ng-container>
        </div>
        <div class="row">
        <div class="col-12 d-flex justify-content-center mb-4 mb-lg-4 mt-lg-4 mt-4">
            <p *ngIf="list?.data.length == 0" class="pt-2"> No Data Found for Applied Filter </p>
        <button *ngIf="show_viewMore" type="button" style="border-radius: 2px;" class="viewmore px-4 py-2" (click)="viewMore(true)">View More</button>
        <span *ngIf="mini_loader && !show_viewMore" class="d-flex justify-content-center mx-auto mini_loader"></span>
        </div>
        </div>
        </div>
        </div>
        </div>





<div class="modal inclusion-modal" id="spacesModal">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content modal-style">
            <div class="modal-body p-lg-4 p-0">
                <div class="row px-3 px-lg-0 my-3 my-lg-0">
                    <div class="col-lg-4 col-4 px-1 cursorPointer" (click) ="closemodal()">
                        <img src="../../../assets/images/apartment-detail-images/Icon (1).png">&nbsp;
                        <span class="d-sm-block d-md-none back">Back</span>
                    </div>
                    <div class="col-lg-8 col-8 d-flex justify-content-end cursorPointer" (click) = "spaceInclusionPage(singleId)">
                        <span class="more_info">View More Info</span>
                    </div>
                </div>
                <div class="row px-3 px-lg-0 px-0">
                    <div class="col-lg-12 py-lg-3 py-1" *ngIf="singleListItem">
                        <div class="row justify-content-between">
                            <div class="col-lg-6 col-12 px-1">
                                <div class="row">
                                    <div class="col-12">
                                        <button *ngIf ="shortcodeItem.theme" type="button" class="btn mb-1 mb-lg-0 modernVibes mr-1 mr-lg-2 px-2">{{shortcodeItem.theme}}</button>
                                        <button *ngIf = "shortcodeItem.space" type="button" class="btn mb-1 mb-lg-0 modernVibes mr-1 mr-lg-2 px-2">{{shortcodeItem.space}}</button>
                                        <button *ngIf ="shortcodeItem.lifestage" type="button" class="btn modernVibes mr-1 mr-lg-2 px-2 mb-1">{{shortcodeItem.lifestage | spacePipe }}</button>
                                        <button *ngIf ="shortcodeItem.shortcode" type="button" class="btn mb-1 modernVibes mr-1 mr-lg-2 px-2">{{ shortcodeItem.shortcode }}</button>
                                    </div>
                                    <div class="col-12">
                                        <p *ngIf="topSpace?.length >0" style="font-size: 18px; font-weight: 400;"
                                            class="mb-0 mt-2">{{topSpace}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-5 vr-finance">
                                <div class="row mt-4 justify-content-around">
                                    <a routerLink="/get-finance"
                                        class="cursorPointer  d-md-block d-none vr-finance mt-2">Get Finance</a>
                                    <!-- <a class="cursorPointer d-sm-block d-md-none finance mt-2">Get Finance</a> -->
                                    <ng-container *ngIf="fullData">
                                        <a (click)="bookConsultationLandingPage()"
                                            class="getTheDesign px-4 py-2 cursorPointer d-md-block d-none">Get This
                                            Design</a>
                                    </ng-container>
                                    <!-- <a class="getDesign d-sm-block d-md-none px-2 py-2 cursorPointer">Get
                                        This Design</a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 px-lg-0 px-0">
                    <div *ngIf="fetchData" id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <ng-container *ngFor="let img of fetchData.data.attributes.images ; let j= index">
                                <div class="carousel-item active" [ngClass]="{'active':j===0}">
                                    <img [src]="img" class="d-block w-100" alt="...">
                                </div>
                            </ng-container>
                        </div>
                        <ng-container *ngIf="fetchData.data.attributes.images.length > 1">
                            <a class="carousel-control-prev" href="#carouselExampleControls" role="button"
                                data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleControls" role="button"
                                data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </ng-container>
                    </div>
                </div>
                <div class="col-lg-5 vr-finance">
                    <div class="row justify-content-center">
                        <a routerLink="/get-finance" class="cursorPointer d-sm-block d-md-none finance my-4">Get
                            Finance</a>
                        <ng-container *ngIf="fullData">
                            <a (click)="bookConsultationLandingPage()"
                                class="getDesign d-sm-block d-md-none px-2 py-2 cursorPointer">Get
                                This Design
                            </a>
                        </ng-container>
                    </div>
                </div>
                <div class="col-lg-12 pb-4 pb-lg-0 pt-4">
                    <div class="inclusion-modal-prev-next">
                        <div *ngIf="singleListItem" class="root-pre-next ml-xl-n3">
                            <div class="d-flex cursorPointer" (click)="previousSpace(previousItems.id)">
                                <ng-container *ngIf ="previousItems !== null">
                                    <div class="align-self-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                            style="color: #8d0528" class="bi bi-arrow-left-circle mr-2" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd"
                                                d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                                        </svg>
                                        <img class="d-lg-inline d-none mr-2" src="../../../assets/images/Kids Room.png"
                                            width="70px" height="40px">
                                    </div>
                                    <div>
                                        <a class="mb-0 pre-icon">Previous Space</a>
                                        <a class="mb-0 apart-name d-lg-block d-none">{{previousItems.space}}</a>
                                    </div>
                                </ng-container>
                            </div>
                            <div *ngIf ="nextItems !== null" class="d-flex cursorPointer mr-xl-n3" (click)="nextSpace(nextItems.id)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                    style="color: #8d0528" fill="currentColor"
                                    class="bi bi-arrow-right-circle align-self-center  mr-2" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                </svg>
                                <div class="align-self-center">
                                    <img class="d-lg-inline d-none mr-2" src="../../../assets/images/Cozy Bedroom.png"
                                        width="70px" height="40px">
                                </div>
                                <div>
                                    <a class="mb-0 pre-icon">Next Space</a>
                                    <a class="mb-0 apart-name d-lg-block d-none">{{nextItems.space}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mbspace">
   <app-madeby-you-stories-new> </app-madeby-you-stories-new>
</div>
<app-footer></app-footer>
</div>
<div class="loading d-flex justify-content-center" *ngIf="loader">
  <div class="spinner-border" style="color: #8d0528" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>