import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-what-we-cover',
  templateUrl: './what-we-cover.component.html',
  styleUrls: ['./what-we-cover.component.scss']
})
export class WhatWeCoverComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
