import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kitchen-guide',
  templateUrl: './kitchen-guide.component.html',
  styleUrls: ['./kitchen-guide.component.scss']
})
export class KitchenGuideComponent implements OnInit {
  
  storeValue :any='Know Your Kitchen';
  clickHead :any ='Kitchen Buying Guide';
  constructor() { }

  ngOnInit(): void {
    this.loadScript()
  }
  clickable(val:any){
    this.storeValue=val;
    this.getDescriptionData();
  }
getDescriptionData(){

}
clickOnHeading(val:any){
  this.clickHead=val;
}
loadScript() {
  let node = document.createElement('script');
  node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
  node.async = true;
  document.getElementsByTagName('head')[0].appendChild(node);
}

}
