<div class="progress-timeline">
            <div id="timeline-accordian" class="accordion1 horizontal  justify-content-center">
             <ul>
                 <li id="border-3" class="nav-item">
            <input type="radio" id="vert-3" name="vert-accordion" checked/>
            <label for="vert-3" style="position: relative;">
             <span class="mt-5" style="writing-mode: vertical-lr; transform: rotate(-180deg);">&nbsp;&nbsp; Order&nbsp;Execution</span>
             <span class="staic_number">3</span>
            </label>
            <!-- <span [ngClass]="{ 'arrow_right': checked3, '': !checked3}"></span> -->
            <!-- <div class=""> -->
            <div class="content2 m-3">
             <div style="font-size: 20px; color: #8D0528;" class="d-flex justify-content-center mb-1">Service</div>
              <div id="applying_styles_steps2">
             <ul class="nav nav-pills"  id="pills-tab" role="tablist">
               <li id="border-1" class="nav-item" style="width: 91px;" [ngClass]="{
                  'changes2': (status1 !== 'none')}">
                 <a
                    class="nav-link mb-3"
                    id="pills-1-tab"
                    [ngClass]="{
                  'active': (status1 !== 'none')
                }"
                    data-toggle="pill"
                    href="#pills-1"
                    role="tab"
                    aria-controls="pills-1"
                    aria-selected="true"
                    style="width: 40px; height: 41px;"
                    ><span *ngIf="(status1 !== 'none'); else elseBlock1000"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                      margin-top: -1px;"></span>
                    <ng-template #elseBlock1000>
                      <span>1</span>
                    </ng-template>
                    </a
                  >
                  <a class="date_name_style">Service Start</a>
                 <!-- <div class="date_name_style" *ngIf="level3_data_service['service_start_date'] !== null">({{level3_data_service['service_start_date'] | date: "dd MMM YYYY"}})</div> -->
              <!-- <div *ngIf="level3_data_service['service_start_date'] === null"></div> -->
               </li>
               <li id="border-2" class="nav-item" style="width: 91px;" [ngClass]="{
                  'changes2': (status1 !== 'Service Start') && (status1 !== 'none')
                }">
                 <a
                    class="nav-link mb-3"
                    [ngClass]="{
                  'active': (status1 !== 'Service Start') && (status1 !== 'none')
                }"
                    id="pills-1-tab"
                    data-toggle="pill"
                    href="#pills-1"
                    role="tab"
                    aria-controls="pills-1"
                    aria-selected="true"
                    style="width: 40px; height: 41px;"
                    ><span *ngIf="(status1 !== 'Service Start') && (status1 !== 'none'); else elseBlock1001"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                      margin-top: -1px;"></span>
                    <ng-template #elseBlock1001>
                      <span>2</span>
                    </ng-template></a
                  >
                  <a class="date_name_style">Service 50%</a>
                 <!-- <div class="date_name_style" *ngIf="level3_data_service['service_50_per_date'] !== null">({{level3_data_service['service_50_per_date'] | date: "dd MMM YYYY"}})</div> -->
              <!-- <div *ngIf="level3_data_service['service_50_per_date'] === null"></div> -->
               </li>
               <li id="border-2" class="nav-item" style="width: 91px;" [ngClass]="{
                  'changes2': (status1 !== 'Service Start') && (status1 !== 'Service 50per') && (status1 !== 'none')
                }">
                 <a
                    class="nav-link mb-3"
                    [ngClass]="{
                  'active': (status1 !== 'Service Start') && (status1 !== 'Service 50per') && (status1 !== 'none')
                }"
                    id="pills-1-tab"
                    data-toggle="pill"
                    href="#pills-1"
                    role="tab"
                    aria-controls="pills-1"
                    aria-selected="true"
                    style="width: 40px; height: 41px;"
                    ><span *ngIf="(status1 !== 'Service Start') && (status1 !== 'Service 50per') && (status1 !== 'none'); else elseBlock1003"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                      margin-top: -1px;"></span>
                    <ng-template #elseBlock1003>
                      <span>3</span>
                    </ng-template></a
                  >
                  <a class="date_name_style">Site Readiness</a>
                 <!-- <div class="date_name_style" *ngIf="level3_data_service['site_readiness_date'] !== null">({{level3_data_service['site_readiness_date'] | date: "dd MMM YYYY"}})</div> -->
              <!-- <div *ngIf="level3_data_service['site_readiness_date'] === null"></div> -->
               </li>

               <li id="border-2" class="nav-item" style="width: 91px;" [ngClass]="{
                  'changes2': (status1 !== 'Service Start') && (status1 !== 'Service 50per') && (status1 !== 'Site Readiness') && (status1 !== 'none')
                }">
                 <a
                    class="nav-link mb-3"
                    [ngClass]="{
                  'active': (status1 !== 'Service Start') && (status1 !== 'Service 50per') && (status1 !== 'Site Readiness') && (status1 !== 'none')
                }"
                    id="pills-1-tab"
                    data-toggle="pill"
                    href="#pills-1"
                    role="tab"
                    aria-controls="pills-1"
                    aria-selected="true"
                    style="width: 40px; height: 41px;"
                    ><span *ngIf=" (status1 !== 'Service Start') && (status1 !== 'Service 50per') && (status1 !== 'Site Readiness') && (status1 !== 'none'); else elseBlock1004"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                      margin-top: -1px;"></span>
                    <ng-template #elseBlock1004>
                      <span>4</span>
                    </ng-template></a
                  >
                  <a class="date_name_style">Handover</a>
                 <!-- <div class="date_name_style" *ngIf="level3_data_service['handover_date'] !== null">({{level3_data_service['handover_date'] | date: "dd MMM YYYY"}})</div> -->
              <!-- <div *ngIf="level3_data_service['handover_date'] === null"></div> -->
               </li>
              
             </ul> 
             </div>  
            
             <div style="font-size: 20px; color: #8D0528;" class="d-flex justify-content-center mt-2 mb-1">Furniture</div>
             <div id="applying_styles_steps26">
             <ul class="nav nav-pills"  id="pills-tab" role="tablist">
               <li id="border-1" class="nav-item" style="width: 91px;" [ngClass]="{
                  'changes2': (status2 !== 'none')}">
                 <a
                    class="nav-link mb-3"
                    [ngClass]="{
                  'active': (status2 !== 'none')
                }"
                    id="pills-1-tab"
                    data-toggle="pill"
                    href="#pills-1"
                    role="tab"
                    aria-controls="pills-1"
                    aria-selected="true"
                    style="width: 40px; height: 41px;"
                    ><span *ngIf="(status2 !== 'none'); else elseBlock1005"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                      margin-top: -1px;"></span>
                    <ng-template #elseBlock1005>
                      <span>1</span>
                    </ng-template>
                    </a
                  >
                  <a class="date_name_style">Raw Material Received</a>
                 <!-- <div class="date_name_style" *ngIf="level3_data_nonservice['raw_material_received_date'] !== null">({{level3_data_nonservice['raw_material_received_date'] | date: "dd MMM YYYY"}})</div> -->
              <!-- <div *ngIf="level3_data_nonservice['raw_material_received_date'] === null"></div> -->
               </li>
               <li id="border-1" class="nav-item" style="width: 91px;" [ngClass]="{
                  'changes2': (status2 !== 'Raw Material Received') && (status2 !== 'none')}">
                 <a
                    class="nav-link mb-3"
                    [ngClass]="{
                  'active': (status2 !== 'Raw Material Received') && (status2 !== 'none')
                }"
                    id="pills-1-tab"
                    data-toggle="pill"
                    href="#pills-1"
                    role="tab"
                    aria-controls="pills-1"
                    aria-selected="true"
                    style="width: 40px; height: 41px;"
                    ><span *ngIf="(status2 !== 'Raw Material Received') && (status2 !== 'none'); else elseBlock1006"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                      margin-top: -1px;"></span>
                    <ng-template #elseBlock1006>
                      <span>2</span>
                    </ng-template></a
                  >
                  <a class="date_name_style">Furniture Prod. Start</a>
                 <!-- <div class="date_name_style" *ngIf="level3_data_nonservice['furniture_production_start_date'] !== null">({{level3_data_nonservice['furniture_production_start_date'] | date: "dd MMM YYYY"}})</div> -->
              <!-- <div *ngIf="level3_data_nonservice['furniture_production_start_date'] === null"></div> -->
               </li>
               <li id="border-1" class="nav-item" style="width: 91px;" [ngClass]="{
                  'changes2': (status2 !== 'Raw Material Received') && (status2 !== 'Furniture Production Start') && (status2 !== 'none')}">
                 <a
                    class="nav-link mb-3"
                    [ngClass]="{
                  'active': (status2 !== 'Raw Material Received') && (status2 !== 'Furniture Production Start') && (status2 !== 'none') 
                }"
                    id="pills-1-tab"
                    data-toggle="pill"
                    href="#pills-1"
                    role="tab"
                    aria-controls="pills-1"
                    aria-selected="true"
                    style="width: 40px; height: 41px;"
                    ><span *ngIf="(status2 !== 'Raw Material Received') && (status2 !== 'Furniture Production Start') && (status2 !== 'none'); else elseBlock1007"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                      margin-top: -1px;"></span>
                    <ng-template #elseBlock1007>
                      <span>3</span>
                    </ng-template></a
                  >
                  <a class="date_name_style">Furniture Dispatch</a>
                 <!-- <div class="date_name_style" *ngIf="level3_data_nonservice['furniture_dispatch_date'] !== null">({{level3_data_nonservice['furniture_dispatch_date'] | date: "dd MMM YYYY"}})</div> -->
              <!-- <div *ngIf="level3_data_nonservice['furniture_dispatch_date'] === null"></div> -->
               </li>
               <li id="border-1" class="nav-item" style="width: 91px;" [ngClass]="{
                  'changes2': (status2 !== 'Raw Material Received') && (status2 !== 'Furniture Production Start') && (status2 !== 'Furniture Dispatch') && (status2 !== 'none')}">
                 <a
                    class="nav-link mb-3"
                    [ngClass]="{
                  'active': (status2 !== 'Raw Material Received') && (status2 !== 'Furniture Production Start') && (status2 !== 'Furniture Dispatch') && (status2 !== 'none')
                }"
                    id="pills-1-tab"
                    data-toggle="pill"
                    href="#pills-1"
                    role="tab"
                    aria-controls="pills-1"
                    aria-selected="true"
                    style="width: 40px; height: 41px;"
                    ><span *ngIf="(status2 !== 'Raw Material Received') && (status2 !== 'Furniture Production Start') && (status2 !== 'Furniture Dispatch') && (status2 !== 'none'); else elseBlock1008"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                      margin-top: -1px;"></span>
                    <ng-template #elseBlock1008>
                      <span>4</span>
                    </ng-template></a
                  >
                  <a class="date_name_style">Installation</a>
                 <!-- <div class="date_name_style" *ngIf="level3_data_nonservice['installation_date'] !== null">({{level3_data_nonservice['installation_date'] | date: "dd MMM YYYY"}})</div> -->
              <!-- <div *ngIf="level3_data_nonservice['installation_date'] === null"></div> -->
               </li>
               <li id="border-1" class="nav-item" style="width: 91px;" [ngClass]="{
                  'changes2': (status2 !== 'Raw Material Received') && (status2 !== 'Furniture Production Start') && (status2 !== 'Furniture Dispatch') && (status2 !== 'Installation') && (status2 !== 'none')}">
                 <a
                    class="nav-link mb-3"
                    [ngClass]="{
                  'active': (status2 !== 'Raw Material Received') && (status2 !== 'Furniture Production Start') && (status2 !== 'Furniture Dispatch') && (status2 !== 'Installation') && (status2 !== 'none')
                }"
                    id="pills-1-tab"
                    data-toggle="pill"
                    href="#pills-1"
                    role="tab"
                    aria-controls="pills-1"
                    aria-selected="true"
                    style="width: 40px; height: 41px;"
                    ><span *ngIf=" (status2 !== 'Raw Material Received') && (status2 !== 'Furniture Production Start') && (status2 !== 'Furniture Dispatch') && (status2 !== 'Installation') && (status2 !== 'none'); else elseBlock1009"><img src="../../assets/images/Vector.png" style="margin-left: -8px;
                      margin-top: -1px;"></span>
                    <ng-template #elseBlock1009>
                      <span>5</span>
                    </ng-template></a
                  >
                  <a class="date_name_style">Handover</a>
                 <!-- <div class="date_name_style" *ngIf="level3_data_nonservice['handover_date'] !== null">({{level3_data_nonservice['handover_date'] | date: "dd MMM YYYY"}})</div> -->
              <!-- <div *ngIf="level3_data_nonservice['handover_date'] === null"></div> -->
               </li>
             </ul> 
             </div>
            </div>
        </li>
               </ul> 
            </div>
          </div>