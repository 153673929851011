import { Component, OnInit } from '@angular/core';
declare var $: any;


@Component({
  selector: 'app-frequent-questions',
  templateUrl: './frequent-questions.component.html',
  styleUrls: ['./frequent-questions.component.scss']
})
export class FrequentQuestionsComponent implements OnInit {

  constructor() { }

   
  collapsehadle:any =[null]
  currentUrl : any 


  ngOnInit(): void {
    this.currentUrl = window.location.href.split('/')[3]
  }
  questions:any=[
    {Q:"Why do I need an interior designer?",a:"Have you been dreaming about your ideal home but don’t know how to go about bringing it to life? That’s where an interior designer comes in. An interior designer gauges your lifestyle and your taste and offers tailored design solutions that enhance your life. With their expertise, they will lead you through the entire process from design, material selection, coordinating between contractors and suppliers, to putting the final touches to your dream home. What’s more is that they can help you create a functional yet elegant home in your budget."},
    {Q:"Why is Arrivae the right choice for your home interior design?",a:"Arrivae is a one-stop interior solutions gateway that prioritises your needs and taste. We offer our clients 1 million plus customisable and innovative designs, fully integrated furniture manufacturing, hassle-free execution backed with easy no-cost EMI options. We are also known for our exclusive range of Italian designs at Indian prices and unique product technique developed for kid’s rooms interiors Wooden Fun Kids. You get all of this in your budget!"},
    {Q:"How do I make a booking for interior services with Arrivae?",a:"To make a booking for interior servicing services, you can contact us through various ways. Fill out the lead form on our website, email us at wecare@arrivae.com, or call us at 7406343311. Our team will schedule a consultation with you to discuss your requirements and provide you with an estimate for the project. Once you approve the estimate, we will start working on the project and keep you informed of the progress along the way."},
    {Q:"What will be the timelines for my project completion?",a:"The timelines for completing a project depend on various factors, such as the size of the project, scope of work, and complexity of the design. At Arrivae, we make sure to provide our clients with a clear timeline for project completion during the initial consultation phase. We aim to complete projects within a reasonable time frame without compromising on the quality of work. "},
    {Q:"Do you offer free consultations or quotes for your services?",a:"Yes, we offer free consultations and quotes for our services. You can schedule a consultation with one of our design experts who will discuss your requirements and provide you with an estimate for the project. Our team will also help you understand our design process and how we can help you achieve your desired outcome."},
    {Q:"Can you provide references or examples of past projects you have completed?",a:"Yes, we can provide references and examples of past projects we have completed. You can visit our website to view our portfolio. Head to our YouTube channel to hear from our happy clients."},
    {Q:"What materials do you use for your interior servicing projects?",a:"We use a variety of materials for our interior servicing projects, such as wood, metal, glass, fabric, and natural stones. Our designers will suggest the best materials based on your budget and design preferences. We also ensure that all materials we use are of high quality and meet our strict standards for durability and safety."},
    {Q:"Can you design only one room in my house?",a:"Yes, we can design only one room in your house. Our design services are customizable and tailored to your specific needs. You can choose the scope of services based on your requirement— from full home renovations, brand new interiors to doing up particular spaces in your home, we’ve got you covered."},
    {Q:"Do you also help with designing furniture?",a:"We also have fully integrated furniture manufacturing facilities where we produce top notch custom made loose and fixed furniture. Our catalogue includes versatile pieces to match various styles and tastes. We offer customisations in terms of upholstery, colour, size, storage and more. We use high-quality materials and ensure that our furniture designs are functional, comfortable, and aesthetically pleasing."},
    {Q:"What if my new home possession or occupation certificate is delayed?" ,a:"We ensure that the designing and material selection process and a detailed BOQ is ready before we start on-site work. If your new home possession or occupation certificate is delayed, we will work with you to reschedule the project start date for on-site work. Our team will stay in touch with you and keep you informed of any changes to the project timeline."},
    {Q:" Do you provide any warranties for your services and products?",a:"Yes, Arrivae offers a robust warranty program, Bhaari Warranty, where all our customers get FOUR FREE modular furniture alignment service for 1 YEAR, auto-scheduled every 3 months after handover.Hassle-free repair/replacement for any manufacturing defects.10 YEAR warranty on all Arrivae made modular furniture."},
    {Q:"What services are included under home interior design? ",a:"Arrivae offers end-to-end solutions which include designing, providing 3D and VR views, civil work, electrical work, wall treatments, painting, plumbing, tiling, flooring, false ceiling, modular interiors, installations and more."},


   

  ]
  // if (this.row[0] !== ven.id) {
  //   this.expansion = ven.id;
  //   this.row[0] = ven.id;
  // } else {
  //   this.row[0] = "";
  //   this.expansion = "";
  // }

  collpase(i:any){

    let previus_tab ;

    if( this.collapsehadle[0] !== i){

      $( "#collpa"+this.collapsehadle[0] ).animate({
        height: "0px", 
         }, 300 );
        
      this.collapsehadle[0] = i;
      const input:any = document.getElementById(
        'ccpa'+i ,
      ) as HTMLInputElement | null;
       console.log(input.clientHeight,"kkkkk")

      $( "#collpa"+i ).animate({
        height: input.clientHeight+20 +"px", 
         }, 300 );
       
         console.log("#collpa"+i)

    } else{
      this.collapsehadle[0] = null
      $( "#collpa"+ i ).animate({
        height: "0px", 
         }, 300 );
        
    }
    console.log(i,this.collapsehadle)
   

  }
  roletoSet(){
    var width = Math.max(window.screen.width, window.innerWidth)
     return width

  }
  showIndex=4
  showmore(){
    this.showIndex=this.showIndex+4
  }

  newLandingPage(){
    if(this.currentUrl == 'new-landing'){
      return true
    }else{
      return false
    }
  }
  


}
