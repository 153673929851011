<div class="row alerts">
    <div  class="rk-ui-notification"  style="height: 20px !important"  *ngIf="successalert"  style="z-index: 1000000 !important">
      <span id="notificationMessageText" style="margin: auto">{{   successMessage }}</span>
       <a class="close rk-linknotification" style="color: white" (click)="successalert = false" >×</a>
    </div>
    <div class="rk-ui-notification err" *ngIf="erroralert">
      <span id="notificationMessageText">{{ errorMessage }}</span>
      <a class="close rk-linknotification" style="color: white" (click)="erroralert = false" >×</a>
    </div>
</div>

<div class="loading d-flex justify-content-center" *ngIf="loader">
    <div class="spinner-border" style="color: #8d0528" role="status">
      <span class="sr-only">Loading...</span>
    </div>
</div>


<div *ngIf="expired" class="pt-md-3">
    <div class="expired pt-5 mt-5">
        <div class="logo mb-md-5 mb-2 mt-md-5 ">
            <img class="img-fluid" src="../../assets/images/logoarrivae logo.svg" alt="logo">
        </div>
        <img src="../../assets/images/expired-icon-23.jpg">
        <p style="text-align: center;">Sorry, the link you used has  <b>Expired</b>.</p>
        <div class="loader-container mt-5">
            <div class="loader">
            </div>
            <h2 class="timer">{{ timerValue }}</h2>
        </div>
    </div>
<div>
       
    </div>
   
</div>

<div style="width: 100%;display: flex;justify-content: center;align-items: center;" >
    <div class="mainblock mt-5" *ngIf="Validation">
        <div class="logo mb-md-5 mb-2">
            <img class="img-fluid" src="../../assets/images/logoarrivae logo.svg" alt="logo">
        </div>
        <div class="gratitude my-3 ">
            <p style="text-align: center;">Thank you for choosing Arrivae for Building Your Dream Home!</p>
        </div>
        <div class="form-container" >
            <p style="text-align: center;">Enter your Email ID on which you would like to receive updates</p>
            <form (ngSubmit)="verifyemail(email.value)" class="d-flex justify-content-center">
                <div class="form-group mb-0">
                    <input type="email" [formControl]="email" class="form-control mb-0" id="email" required placeholder="Enter your email" >
                </div>
              <button  class="ml-md-4 ml-2"  type="submit" [disabled]="email.invalid">Submit</button>
            </form>
            <div *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger ml-2">
                <div *ngIf="email.errors?.required">Email is required.</div>  
                <div *ngIf="email.errors?.pattern">Please enter a valid email address.</div>                          
            </div> 
        </div>
        <div class="divider my-4">

        </div>
        <button class="verifybtn " (click)="verifyemail('')">Continue using {{ExistingEmail}}</button>
        <div *ngIf="timerValue!=0" class="loader-container mt-5">
            <div class="loader">
            </div>
            <h2 class="timer">{{ timerValue }}</h2>
        </div>
    </div>
</div>

<div class="" *ngIf="thankyou">
    <div class="thank">
        <div class="logo mb-5 ">
            <img class="img-fluid" src="../../assets/images/logoarrivae logo.svg" alt="logo">
        </div>
        <img src="../../assets/images/validate.png" style="height: 70px;width: 100px;">
        <p style="text-align: center;">
        Thank you for choosing us,<br>
        Your Email is <b>verified</b> Successfully </p>
        <div class="loader-container mt-5">
            <div class="loader">
            </div>
            <h2 class="timer">{{ timerValue }}</h2>
        </div>
    </div>
</div>

<div class=" footer" >
    <p class="mb-0 " style="text-align: center; margin: auto;">All Rights Reserved &copy; {{currentYear}} | Arrivae</p>
</div>    