import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LandingService } from '../landing.service';
import { ToastrService } from 'ngx-toastr';
import {  Meta } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-landing-page-new',
  templateUrl: './landing-page-new.component.html',
  styleUrls: ['./landing-page-new.component.scss'],
})
export class LandingPageNewComponent implements OnInit {
  selectedCity: any;
  cityData: any;
  citiesData: any;
  city_id: any;
  points: any;
  experience: any;
  multiCenters: any;
  singleCenters: any;
  tokenForMagic: any;
  loader: any;
  accessToken: any;
  url: any;
  carouselImages: any = [
    {
      url: '../../../assets/images/Dark Night.jpg',
      alt: 'Arrivae Banner 01',
      redirectUrl: 'https://web.arrivae.com/space-inclusion/1248'
    },
    {
      url: '../../../assets/images/Urban Elegance.jpg',
      alt: 'Arriave Banner 02',
      redirectUrl: 'https://web.arrivae.com/space-inclusion/1251'
    },
    {
      url: '../../../assets/images/Modica.jpg',
      alt: 'Arrivae Banner 03',
      redirectUrl: 'https://web.arrivae.com/spaces'
    },
   
   
  ];
  mobilecaroselImages: any = [
    {
      url: '../../../assets/images/Dark night  -  Mobile Banner.jpg',
      alt: 'Arrivae Banner 01',
      redirectUrl: 'https://web.arrivae.com/space-inclusion/1248'
    },
    {
      url: '../../../assets/images/Urban Elegance  -  Mobile Banner.jpg',
      alt: 'Arriave Banner 02',
      redirectUrl: 'https://web.arrivae.com/space-inclusion/1251'
    },
    {
      url: '../../../assets/images/Modica -  Mobile Banner  .jpg',
      alt: 'Arrivae Banner 03',
      redirectUrl: 'https://web.arrivae.com/spaces'
    },
   
  ];

  mobileNumber: any;
  leadInvitationId: any;
  enteredOptNumber: any;

  constructor(
    private landingService: LandingService,
    private router: Router,
    private toastr: ToastrService,
    private metaService: Meta
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    $('.numberCountUp').counterUp({ delay: 10, time: 1000 });
    this.url = localStorage.getItem('loginuser');
    this.accessToken = localStorage.getItem('access-token')
      ? localStorage.getItem('access-token')
      : '';
    localStorage.setItem('logintoken', this.url);
    let tokenMagic = this.url.split('=');
    this.tokenForMagic = tokenMagic[1];
    this.fetchPoints();
    this.magicLogin();

    this.animateIntroText();
    this.animateStatsPanel();

    $('#getQuoteModal').on('hide.bs.modal', function () {
      if ($('#get-quote-container').hasClass('get-quote-container-show')) {
        $('#get-quote-container').removeClass('get-quote-container-show');
        $('#get-quote-container').modal('hide');
      }
    });
    this.metaService.addTag({
      name: 'description',
      content:
        'End-to-end Full Home Interior Solutions & India’s first Made-To-Order Furniture Manufacturing Company. PAN India Presence in 21 Cities | 5 Experience Centers | 3 Manufacturing Units | 35 Experience Points | Robust Aftersales Support',
    });
    this.loadScript();
  }
  cname: any;

  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  ngOnDestroy() {
    this.metaService.removeTag("name='description'");
  }

  magicLogin() {
    this.landingService.Magiclogin(this.tokenForMagic).subscribe((res) => {
      this.cname = res;
      this.loader = true;
      setTimeout(() => {
        this, (this.loader = false);
        this.router.navigate(['/customer-dashboard']);
      }, 4000);

      localStorage.setItem('cname', this.cname.user.name);
    });
  }

  fetchPoints() {
    if (localStorage.getItem('selectedCity') == null) {
      this.selectedCity = 'mumbai';
    } else {
      this.selectedCity = localStorage.getItem('selectedCity');
    }
    this.landingService.getCities().subscribe((data) => {
      this.cityData = data;
      this.citiesData = this.cityData.data;
      for (let ct of this.citiesData) {
        if (ct.attributes.name == this.selectedCity) {
          this.city_id = ct.id;
        }
      }

      this.landingService.getpoints(this.city_id).subscribe((res) => {
        if (res) {
          this.points = res;
          this.experience = this.points.data;
          if (this.experience.length > 1) {
            this.multiCenters = true;
            this.singleCenters = false;
          }
          if (this.experience.length == 1) {
            this.singleCenters = true;
            this.multiCenters = false;
          }
        }
      });
    });
  }

  customFunc(data: any) {
    this.selectedCity = data.selectedCity;
    this.fetchPoints();
  }

  animateIntroText() {
    setTimeout(() => {
      $('#intro-text').addClass('intro-text-show');
    }, 500);
  }

  animateStatsPanel() {
    setTimeout(() => {
      $('#stats-panel').addClass('stats-panel-show');
    }, 500);
  }

  quoteModalShowHide() {
    if ($('#get-quote-container').hasClass('get-quote-container-show')) {
      $('#getQuoteModal').modal('hide');
      $('#get-quote-container').removeClass('get-quote-container-show');
    } else {
      $('#getQuoteModal').modal('show');
      $('#get-quote-container').addClass('get-quote-container-show');
    }
  }

  namephn: any;
  otpVerification() {
    var regexPhoneNo = /^\+?([0-9]{2})?[0-9]{10}$/;
    if (this.mobileNumber && this.mobileNumber.match(regexPhoneNo)) {
      // get last 10 character before sending
      this.landingService.optGeneration(this.mobileNumber.slice(-10)).subscribe(
        (res: any) => {
          if (res) {
            this.namephn = this.mobileNumber;
            this.leadInvitationId = res.data.lead_invitation_id;
            this.toastr.success(res.data.message);
            this.mobileNumber = '';
            this.quoteModalShowHide();
          }
        },
        (err) => {
          this.toastr.error(err.error.data.message);
        }
      );
    } else {
      this.toastr.error('Please Enter valid mobile number.');
    }
  }
  boolvalue: boolean = false;
  l_lead_id: any;
  verifyOtp() {
    this.landingService
      .optVerification(this.enteredOptNumber, this.leadInvitationId)
      .subscribe(
        (res: any) => {
          if (res) {
            this.boolvalue = true;
            this.l_lead_id = res.data.lead_id;
            $('#getQuoteModal').modal('hide');
            this.toastr.success(res.data.message);
            this.enteredOptNumber = '';
          }
        },
        (err: any) => {
          this.toastr.error(err.error.data.message);
          this.enteredOptNumber = '';
        }
      );
  }
}
