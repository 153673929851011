<div style="width: 60px;">
<div id="holder" style="font-size: 12px;color: #000000;text-align: center;">

    <div id="mask2"></div>
    <div id="mask1" style="position: absolute;bottom: -1px;left:1px;width:50%;height: 1px;background-color:#ffffff">
    </div>
      <div id="mask" style="position:absolute;top:-1px;left:-1px;width:2px;height:52px;background-color:#ffffff">
      </div>
      
     <span style="color: #8d0528;"> Next</span>
     <i style="color: #8d0528;" class="fa fa-arrow-right"></i>
    </div>
    </div>