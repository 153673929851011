import { Component, OnInit } from '@angular/core';
import { SpaceService } from '../space.service'
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BookConsultationFormComponent } from 'src/app/shared/book-consultation-form/book-consultation-form.component';
import { SpacesGetThisDesignFormComponent } from '../spaces-get-this-design-form/spaces-get-this-design-form.component';


@Component({
  selector: 'app-space-inclusion',
  templateUrl: './space-inclusion.component.html',
  styleUrls: ['./space-inclusion.component.scss'],
  providers:[SpaceService]
})
export class SpaceInclusionComponent implements OnInit {

  constructor(
    private SpaceService: SpaceService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) { }

  id: any;

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.route.params.subscribe((params: Params) => {
      this.id = +params['id'];
    });
    this.getData();
    // this.getSpaceData();
    // this.getExtraspaces()
    this.getExtraspaces()

    this.loadScript()
  }

  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  loader : boolean = false

  fetchData: any;
     getData() {   
    this.selected_item_data = [];
    this.loader = true
     this.SpaceService.getPortfolios(this.id).subscribe(data => {
      setTimeout(()=> {
        this.loader = false
      }, 2000)
      this.fetchData = data;   /// carosel images   /// three names
      for(let i = 0; i<this.fetchData.data.attributes.space_items.data.length;i++){   // inclusion items for loop
        this.selected_item_data.push(
          {
            'name': this.fetchData.data.attributes.space_items.data[i].attributes.item_name,
            'price': 0,
            'quantity': 1
          }
        )
      }
    });  
  }

  aminity_data: any;
  aminity_data_to_send: any = [];
  // async getSpaceData() {
  // this.loader = true
  //  return await (this.SpaceService.getSpacesItem()).subscribe(data => {
  //   setTimeout(()=> {
  //     this.loader = false
  //   }, 2000)
  //     this.aminity_data = data;
  //     for (let i = 0; i < this.aminity_data.data.length; i++){
  //       this.aminity_data_to_send.push(this.aminity_data.data[i].id)
  //     }
  //   });  
  // }

  transform(value: string): string {
    return value.replace(/_/g, ' ');
  }

  bookConsultationSpacePage() {
    const modal = this.modalService.open(BookConsultationFormComponent, { size: 'md' });
    modal.result.then((res) => {
    });
  }

  getThisDesignSpaces() {
      const modal = this.modalService.open(SpacesGetThisDesignFormComponent, { size: 'md' });
      // modal.componentInstance.apartment_name = apartment_name;
      // modal.componentInstance.cityname = cityname;
    modal.componentInstance.items = this.selected_item_data;
    modal.componentInstance.num = 2;
      modal.result.then((res) => {
      });
  
  }

  selected_item_data:any = []
  getItems(event: any, id: any, item: any) {
    if (event.target.checked) {
      this.selected_item_data.push(
            {
              'name': item.item_name,
              'price': 0,
              'quantity': 1
            }
          )
    }
    else {
      this.selected_item_data.filter(
        (element: any, index: any) => {
          if (element.name == item.item_name) {
            this.selected_item_data.splice(index, 1);
          }
        }
        
      );
      
    }
  }
 currentNextSpace(id:any){  
    window.open('/space-inclusion/' + id, '_self');
  }
  MoreSpaceItems :any
  moreItemsPage :any
  morePerPage :any
  TotalPages :any

   getExtraspaces(){
    this.loader = true
    if(this.moreItemsPage === undefined){
      this.moreItemsPage = 1
    }
    if(this.morePerPage === undefined){
      this.morePerPage = 3
    }
    this.SpaceService.getExtraSpaceItem(this.id , this.moreItemsPage , this.morePerPage).subscribe(data => {
      setTimeout(() => {
        this.loader = false
      },2000)
      this.MoreSpaceItems = data
      this.TotalPages = Math.ceil((this.MoreSpaceItems.total_space) / 3)
      console.log(this.TotalPages);
    
    })
  }
  loaderPreviousNext : boolean = false
  nextPage(){
    this.loaderPreviousNext = true
    this.moreItemsPage++
    this.SpaceService.getExtraSpaceItem(this.id , this.moreItemsPage , this.morePerPage).subscribe(data => {
      this.MoreSpaceItems = data
      this.loaderPreviousNext = false
    })
  }

  previousPage(){
    this.loaderPreviousNext = true
    this.moreItemsPage--
    this.SpaceService.getExtraSpaceItem(this.id , this.moreItemsPage , this.morePerPage).subscribe(data => {
    this.MoreSpaceItems = data
    this.loaderPreviousNext = false
    })
  }

}
