import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { SpaceService } from '../space.service';
import { Location } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Slick } from 'ngx-slickjs';
// import { SpacesGetThisDesignFormComponent } from '../spaces-get-this-design-form/spaces-get-this-design-form.component';
import { BookConsultationFormComponent } from '../../shared/book-consultation-form/book-consultation-form.component';

import {  Meta } from '@angular/platform-browser';
declare var $: any;
declare function filterSpace(
  param1: any,
  param2: any,
  param3: any,
  param4: any
): any;

@Component({
  selector: 'app-updated-spaces-page',
  templateUrl: './updated-spaces-page.component.html',
  styleUrls: ['./updated-spaces-page.component.scss'],
})
export class UpdatedSpacesPageComponent implements OnInit {

  page_name_query: any = 1;
  list: any;
  public maxSize: number = 3;
  lifestage: any;
  lifeStage_filter_value: any = '';
  space_name3: any;
  theme_filter_value: any = '';
  final_price: any = [];
  current_page: any = '1';
  per_page: any = 12;
  serachedspace: any = '';
  loader: boolean = false;
  finalspace: any = '';
  total_page: any;
  space_name2: any = '';
  update_space: any;
  lifeStage_filter_value1: any;
  update_theme: any;
  price_filter_value: any = '';
  receivedChildMessage: string = '';
  show_viewMore: boolean = false;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    public spaceService: SpaceService,
    private location: Location,
    public route: ActivatedRoute,
    private metaService: Meta

  ) { }

  id: any;


  ngOnInit(): void {
    this.loader = true
    window.scrollTo(0, 0);
    
    this.loadScript()
    setTimeout(() => {
      this.loader = false
    }, 1000);
   
  }

  config: Slick.Config = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    draggable: true,
    mouseWheelMove: false,
    arrows: false,
    responsive: [

      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
    
  }

  // swiper api

  fetchData: any;
  getData(id:any) {
    // this.selected_item_data = [];
    this.loader = true
    this.spaceService.getPortfolios(id).subscribe(res => {
      this.loader = false
      this.fetchData = res;
    });
  }

  aminity_data: any;
  aminity_data_to_send: any = [];
  getSpaceData() {
    this.spaceService.getSpacesItem().subscribe(data => {
      this.aminity_data = data;
      for (let i = 0; i < this.aminity_data.data.length; i++) {
        this.aminity_data_to_send.push(this.aminity_data.data[i].id)
      }
    });
  }

  selected_item_data: any = []
  getItems(event: any, id: any, item: any) {
    if (event.target.checked) {
      this.selected_item_data.push(
        {
          'name': item.item_name,
          'price': 0,
          'quantity': 1
        }
      )
    }
    else {
      this.selected_item_data.filter(
        (element: any, index: any) => {
          if (element.name == item.item_name) {
            this.selected_item_data.splice(index, 1);
          }
        }
      );
    }
  }

  getStory(message: string) {
    this.receivedChildMessage = message;
  }
  spaceDropdown: any = [];
  space_name_value: any;
  api_data: any;
  space_name: any;
  theme: any;

  getDropdownSpace() {
    this.loader = true;
    this.spaceService.getDropdownspace().subscribe((data) => {
      this.spaceDropdown = data;
      this.loader = false;
      this.api_data = Object.values(this.spaceDropdown);
      this.space_name = this.api_data[0];
      var spaceList = this.space_name;
      var index1 = spaceList.indexOf('Bedroom');
      var index2 = spaceList.indexOf('Kids Room');
      var index3 = spaceList.indexOf('Kitchen');
      var index4 = spaceList.indexOf('Living');
      var space1 = this.space_name[index1];
      var space2 = this.space_name[index2];
      var space3 = this.space_name[index3];
      var space4 = this.space_name[index4];
      this.update_space = [space1, space2, space3, space4];
      this.lifestage = this.api_data[1];
      this.theme = this.api_data[2];
      var themelist = this.theme;
      var index11 = themelist.indexOf('Contemporary');
      var index12 = themelist.indexOf('Indian Ethnic');
      var index13 = themelist.indexOf('Lifestage Kitchen');
      var index14 = themelist.indexOf('Minimalist');
      var index15 = themelist.indexOf('Modern');
      var index16 = themelist.indexOf('Rustic Industrial');
      var index17 = themelist.indexOf('Transitional');
      var theme11 = this.theme[index11];
      var theme12 = this.theme[index12];
      var theme13 = this.theme[index13];
      var theme14 = this.theme[index14];
      var theme15 = this.theme[index15];
      var theme16 = this.theme[index16];
      var theme17 = this.theme[index17];
      this.update_theme = [
        theme11,
        theme12,
        theme13,
        theme14,
        theme15,
        theme16,
        theme17,
        'Dark Knight',
        'Dream Ballet',
        'Modica',
        'Morandi',
        'Urban Elegance',
        'Modern Chic',
        'Golden Murano',
        'Macaron',
        
      ];
      if (this.space_name2 !== undefined) {
        this.space_name3 = this.space_name2;
      } else {
        this.space_name3 = '';
      }
    });
  }

  priceFilter(event: any) {
    this.per_page =4
    this.price_filter_value = event.target.value;
    if (this.price_filter_value === 'Under ₹ 1 Lakh') {
      this.final_price = JSON.stringify([
        [0, 10000],
        [10000, 100000],
      ]);
    }
    if (this.price_filter_value === '₹ 1 Lakh to ₹ 2 Lakh') {
      this.final_price = JSON.stringify([
        [100000, 200000],
        [100000, 200000],
      ]);
    }
    if (this.price_filter_value === '₹ 2 Lakh to ₹ 3 Lakh') {
      this.final_price = JSON.stringify([
        [200000, 300000],
        [200000, 300000],
      ]);
    }
    if (this.price_filter_value === '₹ 3 Lakh to ₹ 4 Lakh') {
      this.final_price = JSON.stringify([
        [300000, 400000],
        [300000, 400000],
      ]);
    }
    if (this.price_filter_value === 'Over ₹ 4 Lakh') {
      this.final_price = JSON.stringify([
        [400000, 1000000],
        [1000000, 130000000],
      ]);
    }
    filterSpace(
      this.space_name3,
      this.lifeStage_filter_value,
      this.theme_filter_value,
      this.price_filter_value
    );

    this.getPortfolioList(
      this.space_filter_value,
      this.lifeStage_filter_value,
      this.theme_filter_value,
      this.final_price,
      this.current_page,
      this.per_page,
      this.serachedspace
    );
  }

  space_filter_value: any = '';
  spaceFilter(event: any) {
    this.per_page = 4
    if (this.page_name_query === undefined) {
      this.page_name_query = 1;
    }
    this.space_filter_value = event.target.value;
    if (this.space_filter_value !== undefined) {
      this.space_name3 = this.space_filter_value;
    } else {
      this.space_name3 = '';
    }
    filterSpace(
      this.space_name3,
      this.lifeStage_filter_value,
      this.theme_filter_value,
      this.price_filter_value
    );

    this.getPortfolioList(
      this.space_name3,
      this.lifeStage_filter_value,
      this.theme_filter_value,
      this.final_price,
      this.current_page,
      this.per_page,
      this.serachedspace
    );
  }
  getPortfolioList(
    space: any,
    lifeStage: any,
    theme: any,
    range: any,
    curr_page: any,
    per_page: any,
    search_string: any
  ) {
    // this.loader = true;
    this.finalspace = this.serachedspace;
    this.spaceService
      .getPortfolioList(
        space,
        lifeStage,
        theme,
        range,
        curr_page,
        per_page,
        this.finalspace
      )
      .subscribe((data) => {
        this.list = data;
        this.total_page = this.list.total_records;
        if (curr_page === undefined) {
          curr_page = 1;
        }
        if (this.per_page >= this.total_page) {
          this.show_viewMore = false;
        } else {
          this.show_viewMore = true;
        }

        if(!this.mini_loader){
            this.loader = true;
            setTimeout(() => {
              this.loader = false;
            }, 2000);
        }

        if(this.mini_loader){
          this.mini_loader = true
          setTimeout(() => {
            this.mini_loader = false;
          }, 800);
        }

      });
  }

  getPortfolioListHandler(curr_page: any) {
    this.loader = true;
    this.current_page = curr_page;
    this.location.go(
      `spaces?space_name=${this.space_name3}&lifestage_name=${this.lifeStage_filter_value}&theme_name=${this.theme_filter_value}&page=${this.current_page}`
    );

    if (this.space_filter_value !== undefined) {
      this.space_filter_value = this.space_name3;
    }
    this.spaceService
      .getPortfolioList(
        this.space_filter_value,
        this.lifeStage_filter_value,
        this.theme_filter_value,
        this.final_price,
        this.current_page,
        this.per_page,
        this.serachedspace
      )
      .subscribe((data) => {
        if (this.space_filter_value === undefined) {
          this.space_filter_value = '';
        }
        setTimeout(() => {
          this.loader = false;
        }, 2000);
        this.list = data;
        this.total_page = this.list.total_records;
        this.current_page = curr_page;
        this.per_page = '12';
      });

    window.scrollTo(0, 0);
  }

  transform(value: string): string {
    return value.replace(/_/g, ' ');
  }

  lifeStageFilter(event: any) {
    this.per_page = 4
    this.lifeStage_filter_value = event.target.value;
    if (this.lifeStage_filter_value === 'young vibes') {
      this.lifeStage_filter_value = 'young_vibes';
    }
    if (this.lifeStage_filter_value === 'love vibes') {
      this.lifeStage_filter_value = 'love_vibes';
    }
    if (this.lifeStage_filter_value === 'baby vibes') {
      this.lifeStage_filter_value = 'baby_vibes';
    }
    if (this.lifeStage_filter_value === 'happy vibes') {
      this.lifeStage_filter_value = 'happy_vibes';
    }
    if (this.lifeStage_filter_value === 'lively vibes') {
      this.lifeStage_filter_value = 'lively_vibes';
    }
    filterSpace(
      this.space_name3,
      this.lifeStage_filter_value,
      this.theme_filter_value,
      this.price_filter_value
    );
    this.getPortfolioList(
      this.space_filter_value,
      this.lifeStage_filter_value,
      this.theme_filter_value,
      this.final_price,
      1,
      this.per_page,
      this.serachedspace
    );
  }

  mini_loader : boolean = false

  viewMore(val: any) {
    this.show_viewMore = false
    this.mini_loader = true
    if (val){
      this.per_page = this.per_page + 4;
      this.getPortfolioList(
        this.space_name3,
        this.lifeStage_filter_value,
        this.theme_filter_value,
        this.final_price,
        1,
        this.per_page,
        this.serachedspace
      );
    }
  }

  // moreLoader :any
  // viewMore(val: any) {
  //   this.show_viewMore = false
  //   this.mini_loader = true
  //   this.moreLoader = val
  //   if (this.moreLoader) {
  //     this.per_page = this.per_page + 6;
  //     this.apartmentList(this.finalcity, '');
  //   }
  // }

  themeFilter(event: any) {
    this.per_page = 4
    this.theme_filter_value = event.target.value;
    filterSpace(
      this.space_name3,
      this.lifeStage_filter_value,
      this.theme_filter_value,
      this.price_filter_value
    );
    this.getPortfolioList(
      this.space_filter_value,
      this.lifeStage_filter_value,
      this.theme_filter_value,
      this.final_price,
      1,
      this.per_page,
      this.serachedspace
    );
  }

  pricerange: any;
  getpricerange() {
    this.pricerange = [
      {
        name: 'Under ₹ 1 Lakh',
      },
      { name: '₹ 1 Lakh to ₹ 2 Lakh' },
      { name: '₹ 2 Lakh to ₹ 3 Lakh' },
      { name: '₹ 3 Lakh to ₹ 4 Lakh' },
      { name: 'Over ₹ 4 Lakh' },
    ];
  }


  nextItems :any
  previousItems :any
  singleListItem : any
  shortcodeItem :any
  singlePortFoilo( id:any , space:any , lifestage:any, theme:any , range :any){
    this.loader = true
    this.spaceService.getSinglePortfolioList(id , space , lifestage , theme , range).subscribe((res) => {
      this.loader = false
      this.singleListItem = res
      this.shortcodeItem = this.singleListItem.data.attributes
      console.log(this.shortcodeItem);
      $('#spacesModal').modal('show');

      this.topTheme = this.singleListItem.data.attributes.theme
      this.topSpace = this.singleListItem.data.attributes.space
      this.topLifeStage = this.singleListItem.data.attributes.lifestage
      this.nextItems = this.singleListItem.next
      this.previousItems = this.singleListItem.previous
    })
  }
  singleId : any
  singleLifeStage :any
  singleTheme :any
  singlePrice :any 
  topSpace : any
  topLifeStage :any
  topTheme : any
  fullData :any
   spaceInclusionPage(id: any) {
    window.open('/space-inclusion/' + id, '_blank');
   }

   openSpaceModal(id: any , space:any , lifeStage:any , theme :any , entire :any ) {
    this.singleId = id
    this.topSpace = space
    this.topLifeStage = lifeStage
    this.topTheme = theme
    this.fullData = entire
    if (this.space_filter_value !== undefined) {
      this.space_name3 = this.space_filter_value;
    } else {
      this.space_name3 = '';
    }
    if(this.lifeStage_filter_value !== undefined) {
      this.singleLifeStage = this.lifeStage_filter_value
    } else {
      this.singleLifeStage = ''
    }
    if(this.theme_filter_value !== undefined) {
      this.singleTheme = this.theme_filter_value
    } else {
      this.singleTheme = ''
    }
    if(this.price_filter_value !== undefined) {
      this.singlePrice = this.price_filter_value
    } else {
      this.singlePrice = ''
    }
    this.getData(id);
    this.singlePortFoilo(  this.singleId , this.space_name3,  this.singleLifeStage, this.singleTheme, this.singlePrice )
  }

  closemodal() {
    $("#spacesModal").modal("hide");
  }


  previousSpace(id:any){
    if (this.space_filter_value !== undefined) {
      this.space_name3 = this.space_filter_value;
    } else {
      this.space_name3 = '';
    }
    if(this.lifeStage_filter_value !== undefined) {
      this.singleLifeStage = this.lifeStage_filter_value
    } else {
      this.singleLifeStage = ''
    }
    if(this.theme_filter_value !== undefined) {
      this.singleTheme = this.theme_filter_value
    } else {
      this.singleTheme = ''
    }
    if(this.price_filter_value !== undefined) {
      this.singlePrice = this.price_filter_value
    } else {
      this.singlePrice = ''
    }
    this.singlePortFoilo(id , this.space_name3,  this.singleLifeStage, this.singleTheme, this.singlePrice)
    this.getData(id)
  }
  nextSpace(id :any){
    if (this.space_filter_value !== undefined) {
      this.space_name3 = this.space_filter_value;
    } else {
      this.space_name3 = '';
    }
    if(this.lifeStage_filter_value !== undefined) {
      this.singleLifeStage = this.lifeStage_filter_value
    } else {
      this.singleLifeStage = ''
    }
    if(this.theme_filter_value !== undefined) {
      this.singleTheme = this.theme_filter_value
    } else {
      this.singleTheme = ''
    }
    if(this.price_filter_value !== undefined) {
      this.singlePrice = this.price_filter_value
    } else {
      this.singlePrice = ''
    }
    this.singlePortFoilo(id , this.space_name3,  this.singleLifeStage, this.singleTheme, this.singlePrice)
    this.getData(id)
  }
  // getThisDesignSpaces(data :any) {
  //   const modal = this.modalService.open(SpacesGetThisDesignFormComponent, {
  //     size: 'md',
  //   });
  //   modal.componentInstance.data = data;
  //   modal.componentInstance.num = 1;
  //   modal.result.then((res) => {
  //     $("#spacesModal").modal("hide");
  //   });
  // }

  bookConsultationLandingPage() {
    const modal = this.modalService.open(BookConsultationFormComponent, {
      size: 'md',
    });
    modal.result.then((res) => {
      $("#spacesModal").modal("hide");
    });
  }

  ngOnDestroy(){
    $("#spacesModal").modal("hide");
    this.metaService.removeTag("name='description'");
  }
  SpaceFilter(space:any){
    

    const host: string =  location.origin;
    const url: string = host  + String(this.router.createUrlTree(['/spaces/listing'], { queryParams: {
      space_name:space
    } }));
    window.open(url, '_self');

  }
  roletoSet(){
    var width = Math.max(window.screen.width, window.innerWidth)
     return width

  }
}

