import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { LandingModule } from './landing/landing.module';
import { SpacesModule } from './spaces/spaces.module';
import { ApartmentModule } from './apartment/apartment.module';
import { LifestageModule } from './lifestage/lifestage.module';
import { ThemesModule } from './themes/themes.module';

import {ToastrModule} from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthenticationService } from './authentication/authentication.service';
import { ApartmentService } from './apartment/apartment.service';
import { MagicLinkComponent } from './magic-link/magic-link.component';
import { ReplaceChar } from './pipes/customstring.pipe';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { ErrorpageComponent } from './errorpage/errorpage.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CustomerBoqComponent } from './customer-boq/customer-boq.component';
import { NgxSlickJsModule } from 'ngx-slickjs'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CustomerDashbWhatsappComponent } from './customer-dashb-whatsapp/customer-dashb-whatsapp.component';
import { ChequePaymentComponent } from './cheque-payment/cheque-payment.component';

import { DatePipe } from '@angular/common';
import { GetQuoteLandingPageComponent } from './get-quote-landing-page/get-quote-landing-page.component';


import { MarathonNexajoneComponent } from './marathon-nexajone/marathon-nexajone.component';
import { GtagModule } from 'angular-gtag';
import {NgxYoutubePlayerModule} from 'ngx-youtube-player';
import { CatlougeApartmentComponent } from './marathon-nexajone/catlouge-apartment/catlouge-apartment.component';
import { VRlinksComponent } from './vrlinks/vrlinks.component';
import { EmailVerificationComponent } from './email-verification/email-verification.component'
import {LoaderService} from './loader.service'
import { PromoterProgramComponent } from './promoter-program/promoter-program.component';
import { NewLandingPageComponent } from './new-landing-page/new-landing-page.component';
import { ChooseArrivaeComponent } from './LandingPage/choose-arrivae/choose-arrivae.component';
import { FindUsComponent } from './LandingPage/find-us/find-us.component';
import { CustomerReviewComponent } from './LandingPage/customer-review/customer-review.component';
import { QuoteFormComponent } from './LandingPage/quote-form/quote-form.component';
import { LandingPageComponent } from './ShortfromLandingPage/landing-page/landing-page.component';
import { ExpertSectionComponent } from './ShortfromLandingPage/expert-section/expert-section.component';
import { ConsultationFormComponent } from './ShortfromLandingPage/consultation-form/consultation-form.component';
import { HappycustomersComponent } from './ShortfromLandingPage/happycustomers/happycustomers.component';
import { HeaderBlankComponent2 } from './ShortfromLandingPage/header-blank/header-blank.component';
import { HeaderBlankComponent } from './LandingPage/header-blank/header-blank.component';

@NgModule({
  declarations: [
    AppComponent,
    MagicLinkComponent,
    ReplaceChar,
    ErrorpageComponent,
    CustomerBoqComponent,
    CustomerDashbWhatsappComponent,
    ChequePaymentComponent,
    GetQuoteLandingPageComponent,
    MarathonNexajoneComponent,
    CatlougeApartmentComponent,
    VRlinksComponent,
    EmailVerificationComponent,
    PromoterProgramComponent,
    NewLandingPageComponent,
    QuoteFormComponent,
    ChooseArrivaeComponent,
    FindUsComponent,
    CustomerReviewComponent,
    LandingPageComponent,
    ExpertSectionComponent,
    ConsultationFormComponent,
    HappycustomersComponent,
    HeaderBlankComponent2,
    HeaderBlankComponent
  ],
  imports: [
    // ImgixModule.forRoot({
    //   domain: '<your source url, e.g. assets.imgix.net>',
    //   defaultParams: {
    //     // This enables the auto format and compress imgix parameters by default for all images, which we recommend to reduce image size, but you might choose to turn this off.
    //     auto: 'format,compress',
    //   },
    //   /* Add more imgix config here, see the API section for a full list of options */
    // }),
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    LandingModule,
    SpacesModule,
    ApartmentModule,
    LifestageModule,
    ThemesModule,
    NgSelectModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('service-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgbModule,
    GtagModule.forRoot({ trackingId: 'UA-YOUR_TRACKING_ID', trackPageviews: true }),
    NgxSlickJsModule.forRoot({
      links: {
        jquery: '',
        slickJs:
          'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js',
        slickCss:
          'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css',
        slickThemeCss:
          'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css',
      },
    }),
    NgxYoutubePlayerModule.forRoot()
  ],
  providers: [AuthenticationService, ApartmentService,DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
