<div class="footer-new w-100">
    <div class="border-footer"  ></div>
    <div class="mx-lg-5 pb-5 pt-5 px-4 mx-0 row applyWidth">
        <div class="col-lg-12">
            <div class="row justify-content-center my-lg-5 root-footer-content">
                <div class="col-lg-4 d-flex flex-column justify-content-between">
                    <div class="mb-5">
                        <p class="mb-0 adress-content text-lg-left text-center" >B <span class="numbers"> 501/502</span>, Everest House, Suren Road, Gundavali,</p>
                        <p class="mb-0 adress-content text-lg-left text-center">Andheri East, Mumbai, Maharashtra <span class="numbers">400093</span></p>
                    </div>
                    <div class="mb-5">
                        <a class="adress-content mb-2 text-lg-left text-center d-block numbers" href="tel:7406343311" >t: 917406343311 </a>
                        <a class="adress-content mb-2 text-lg-left text-center d-block" href="mailto:wecare@arrivae.com">e: wecare@arrivae.com</a>
                        <p class="adress-content mb-2 text-lg-left text-center">Timings: <span class="numbers">09:00</span> AM to <span class="numbers"> 09:00</span> PM</p>
                    </div>
                    <div class="logoStyles d-lg-flex  d-none">
                        <img class="img-fluid" src="../../../assets/images/logoarrivae logo.svg" />
                    </div>
                </div>
                <div class="col-lg-7">
                   <div class="row">
                        <div class="col-lg-3 mb-5 mb-lg-0">
                            <div>
                                <p class="footer-headings text-lg-left text-center" >EXPERIENCE CENTERS</p>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-lg-12 col-5">
                                    <a (click) ="centers('mumbai')" class=" text-lg-left mb-2 text-center adress-content d-block cursorPointer" >Mumbai</a>
                                    <a (click) ="centers('Thane')"  class=" text-lg-left mb-2 text-center adress-content d-block cursorPointer">Thane</a>
                                    <a (click) ="centers('navi mumbai')"   class=" text-lg-left mb-2 text-center adress-content d-block cursorPointer">Navi Mumbai</a>
                                    <a (click)="centers('bangalore')"  class=" text-lg-left mb-2 text-center adress-content d-block cursorPointer">Bengaluru</a>
                                </div>
                                <div class="col-lg-12 col-5">
                                    <a (click)="centers('pune')"  class=" text-lg-left mb-2 text-center adress-content d-block cursorPointer">Pune</a>
                                    <!-- <a (click)="centers('chandigarh')"  class=" text-lg-left mb-2 text-center adress-content d-block cursorPointer">Chandigarh</a> -->
                                    <a (click)="centers('Hyderabad')"  class=" text-lg-left mb-2 text-center adress-content d-block cursorPointer">Hyderabad</a>
                                    <!-- <a (click)="centers('trivandrum')"   class=" text-lg-left mb-2 text-center adress-content d-block cursorPointer">Trivandrum</a> -->
                                    <a (click)="centers('vijayawada')"  class=" text-lg-left mb-2 text-center adress-content d-block cursorPointer">Vijayawada</a>
                                    <a (click)="centers('gurgaon')"   class=" text-lg-left mb-2 text-center adress-content d-block cursorPointer">Gurugram</a>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-3 text-center text-lg-left mb-lg-0 mb-5">
                            <div>
                                <p class="footer-headings">COMPANY</p>
                            </div>
                            <div>
                                <a routerLink="/about-us" target="_blank" [routerLinkActiveOptions]="{exact:true}"  routerLinkActive="highlight" class="mb-2 d-block adress-content" >About Us</a>
                                <a routerLink="/terms-and-condition" target="_blank" [routerLinkActiveOptions]="{exact:true}"  routerLinkActive="highlight" class="mb-2 d-block adress-content">Terms And Conditions</a>
                                <a routerLink="/privacy-policy" target="_blank" [routerLinkActiveOptions]="{exact:true}"  routerLinkActive="highlight" class="mb-2 d-block adress-content">Privacy Policy</a>
                                <a *ngIf="false" href="https://arrivae.com/media_engagements" target="_blank"  class="mb-2 d-block adress-content">In The Media</a>
                              
                            </div>
                        </div>

                        <div class="col-lg-3 text-center text-lg-left">
                            <div class="mb-lg-5 mb-5">
                                <div>
                                    <p class="footer-headings">CONTACT</p>
                                </div>
                                <div>
                                    <a routerLink="/visit-us" target="_blank" [routerLinkActiveOptions]="{exact:true}"  routerLinkActive="highlight" class="mb-2 d-block adress-content" >Contact Us</a>
                                    <a routerLink="/design-with-us" target="_blank" [routerLinkActiveOptions]="{exact:true}"  routerLinkActive="highlight" class="mb-2 d-block adress-content" >Design With Us</a>
                                    <a *ngIf="false" href="https://arrivae.com/become_a_manufacturer" target="_blank" class="mb-2 d-block adress-content">Build With Us</a>
                                    <a routerLink="/evangelists" target="_blank" [routerLinkActiveOptions]="{exact:true}"  routerLinkActive="highlight" class="mb-2 d-block adress-content">Arrivae Promoter Program</a>
                                </div>
                            </div>
                            <div class="d-lg-block d-none">
                                <div>
                                    <p class="footer-headings">FOLLOW US</p>
                                </div>
                                <div>
                                    <a href="https://www.facebook.com/Arrivae/" target="_blank">
                                        <img class="mr-2" src="../../../assets/images/arrivaeHomePageImages/fb.png"/>
                                    </a>
                                    <a target="_blank" href="https://in.linkedin.com/company/arrivae">
                                        <img class="mr-2" src="../../../assets/images/arrivaeHomePageImages/Linkedin.png"/>
                                    </a>
                                    <a target="_blank" href="https://www.instagram.com/arrivae/?hl=en" >
                                        <img class="mr-2" src="../../../assets/images/arrivaeHomePageImages/Ijnsta.png"/>
                                    </a>
                                    <a target="_blank" href="https://twitter.com/ModSpaceIN" >
                                        <img class="mr-2" src="../../../assets/images/arrivaeHomePageImages/Twitter.png"/>
                                    </a>
                                </div>  
                            </div>
                        </div>
                        <div class="col-lg-3 text-center text-lg-left">
                            <div>
                                <p class="footer-headings">MORE</p>
                            </div>
                            <div>
                                <a [class]="activFlag ? 'highlight' : ''" routerLink="/made-by-you-story" [routerLinkActiveOptions]="{exact:true}" routerLinkActive="highlight" target="_blank" class="mb-2 adress-content d-block" >Made-By-You Stories</a>
                                <a href="https://blog.arrivae.com/" target="_blank" class="mb-2 adress-content d-block">Blogs</a>
                                <a href="https://blog.arrivae.com/" routerLink="/Reviews" routerLinkActive="highlight" target="_blank" class="mb-2 adress-content d-block" >Customer Reviews</a>
                            </div>
                            <div class="logoStyles d-flex  d-lg-none my-5 justify-content-center">
                                <img class="img-fluid" src="../../../assets/images/logoarrivae logo.svg" />
                            </div>
                        </div>
                   </div>
                </div>
            </div>
        </div>
    </div>
</div>