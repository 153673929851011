<app-header (sendData)="customFunc($event)"></app-header>
<div class="">
  <img
    data-src="../../assets/images/APARTMENT-Interior-Design-For-Your-Appartment.png"
    class="lazyload profile"
  />
  <div class="mob-none">
    <div class="row justify-content-center">
      <div class="col-md-10 col-12 breadcrumb-style">
        <a href="/landing" class="text-decoration-none"
          ><span class="home-sec">HOME >> </span></a
        >
        <a><span class="space-sec">APARTMENT</span></a>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid d-md-block">
  <!-- search row -->
  <div class="row mt-4 mb-4">
    <div class="col-md-12 d-flex">
      <button class="cityBtn text-left ml-auto">
        {{ selectedCity | titlecase }}
      </button>
      <input
        class="mr-auto searchFiled w-75 pl-3"
        type="text"
        name="search"
        [(ngModel)]="searchApartment"
        autocomplete="off"
        placeholder="{{ placeholdervalue | titlecase }}"
        (keydown.enter)="searchTextFilter($event)"
      />
    </div>
  </div>

  <!-- List div -->
  <div class="mb-3 mt-4 row mobnone">
    <div class="col-md-12 d-flex">
      <div
        class="tabsize"
        (click)="slelecttab('All APARTMENTS')"
        [ngClass]="{ someClass: tabvalues == 'All APARTMENTS' }"
      >
        All APARTMENTS
        <span class="countapartmnet" *ngIf="gallerydataApartment">{{
          gallerydataApartment?.length
        }}</span>
      </div>
      <!-- <div class="pl-4 tabsize" (click)="slelecttab('ECENTLY SEARCHED');"> RECENTLY SEARCHED </div>
      <div class="mr-auto pl-4 tabsize" (click)="slelecttab('ECENTLY SEARCHED');"> LATEST MODELLED  </div> -->
    </div>
  </div>

  <div class="row mt-2 webnone">
    <div class="col-12">
      <div class="row">
        <div class="col-12">
          <p
            class="mobilelistTab"
            (click)="slelecttab('All APARTMENTS')"
            [ngClass]="{ someClass: tabvalues == 'All APARTMENTS' }"
          >
            All APARTMENTS<span
              class="countapartmnet"
              *ngIf="gallerydataApartment"
              >{{ gallerydataApartment?.length }}</span
            >
          </p>
        </div>
        <div class="col-6">
          <p
            class="mobilelistTab d-none"
            (click)="slelecttab('ECENTLY SEARCHED')"
          >
            RECENTLY SEARCHED
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p
            class="mobilelistTab d-none"
            (click)="slelecttab('ECENTLY SEARCHED')"
          >
            LATEST MODELLED
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-12 col-md-12">
      <div
        class="row"
        *ngIf="gallerydataApartment && gallerydataApartment.length > 0"
      >
        <div class="col-md-4 mb-3" *ngFor="let item of gallerydataApartment">
          <div class="parents">
            <img
              data-src="{{
                item.attributes.primary_image
              }} || ../../../assets/images/shutterstock.png"
              class="img-fluid w-100 picstyle lazyload"
              onerror="src='../../../assets/images/default-apartment.png'"
            />
            <div class="overlay"></div>
            <div class="btnPar" *ngIf="item.attributes.primary_image">
              <p class="emi">
                Design EMI Starts From: Rs
                {{ item.attributes.boq_emi | number: "1.0-0" }}/-
              </p>
              <span>
                <button
                  class="bookConsBtn"
                  (click)="bookConsultationApartmentList()"
                >
                  Book Consultation
                </button>
                <button
                  class="viewDesignBtn"
                  [routerLink]="['/apartment-search']"
                  [queryParams]="{
                    apartment_name: item.attributes.apartment_name,
                    developer_name: item.attributes.developer_name,
                    unid: item.attributes.unid
                  }"
                  routerLinkActive="active"
                  (click)="apartmentClicked(item.attributes.apartment_name,item.attributes.theme[0],item.attributes.lifestage[0])"
                >
                  View Designs
                </button>
              </span>
            </div>
            <div class="nameDivParent" *ngIf="item.attributes.primary_image">
              <div class="nameDiv">
                <p class="apName">{{ item.attributes.apartment_name }}</p>
                <p class="location">{{ item.attributes.city }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="row mt-3 ml-5 mr-5"
        *ngIf="gallerydataApartment && gallerydataApartment.length == 0"
      >
        <div class="col-md-12 not-found text-center">
          <p
            class="pb-4"
            style="font-size: 21px; text-align: center; color: #8d0528"
          >
            No Apartment Data Found
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
      <div class="container-fluid">
        <div class="row mt-4 justify-content-center mb-5">
          <div class="rectangular4">
            <div class="row">
              <div class="col-3">
                <img
                  src="../assets/images/consulation.png"
                  class="consulation"
                />
              </div>
              <div class="col-8 free">
                <span>FREE CONSULTATION</span><br />
                <span>Easy Booking</span>
                <a
                  style="text-decoration: none"
                  (click)="bookConsultationApartmentList()"
                  ><div class="know cursorPointer">KNOW MORE</div></a
                >
              </div>
            </div>
          </div>
          <div class="rectangular5">
            <div class="row">
              <div class="col-3">
                <img
                  src="../assets/images/houseprice.png"
                  class="consulation"
                />
              </div>
              <div class="col-8 free">
                <span>EMI OPTION ON</span><br />
                <span>COMPLETE PROJECT</span>
                <a
                  style="text-decoration: none"
                  href="/get-finance"
                  target="_blank"
                  ><div class="know">KNOW MORE</div></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    

 
    <app-aboutusone></app-aboutusone>
    <app-aboutustwo></app-aboutustwo>
    <app-footer></app-footer>
    <div class="loading d-flex justify-content-center" *ngIf="loader">
      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-secondary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-success" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-danger" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-warning" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-info" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div class="spinner-grow text-light" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>