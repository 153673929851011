<div class="container-fluid about-us-profile pb-3">
  <div class="row justify-content-center">
    <div class="col-md-10 col-12">
      <div class="row">
        <div class="col-md-3 col-sm-12 mt-4">
          <div class="experience-center">
            <div class="text-style-1">VISIT OUR EXPERIENCE CENTRE</div>

            <div><a class="quick-links-style" target="_blank" href="https://web.arrivae.com/visit-us" (click)="centers('mumbai')">Mumbai</a></div>
            <div><a class="quick-links-style" target="_blank" href="https://web.arrivae.com/visit-us" (click)="centers('delhi')">Delhi</a></div>
            <div><a class="quick-links-style" target="_blank" href="https://web.arrivae.com/visit-us" (click)="centers('pune')">Pune</a></div>
            <div><a class="quick-links-style" target="_blank" href="https://web.arrivae.com/visit-us" (click)="centers('bangalore')">Bangalore</a></div>
            <div><a class="quick-links-style" target="_blank" href="https://web.arrivae.com/visit-us" (click)="centers('chandigarh')">Chandigarh</a></div>
            <div><a class="quick-links-style" target="_blank" href="https://web.arrivae.com/visit-us" (click)="centers('mumbai')">Thane</a></div>
            <div><a class="quick-links-style" target="_blank" href="https://web.arrivae.com/visit-us"(click)="centers('delhi')">Gurgaon</a></div>
            <div><a class="quick-links-style" target="_blank" href="https://web.arrivae.com/visit-us" (click)="centers('noida')">Noida</a></div>
            <div><a class="quick-links-style" target="_blank" href="https://web.arrivae.com/visit-us" (click)="centers('jaipur')">Jaipur</a></div>
            <div><a class="quick-links-style" target="_blank" href="https://web.arrivae.com/visit-us" (click)="centers('cochin')">Kochi</a></div>
            <div><a class="quick-links-style" target="_blank"href="https://web.arrivae.com/visit-us" (click)="centers('trivandrum')">Trivandrum</a></div>
            <div><a class="quick-links-style" target="_blank"href="https://web.arrivae.com/visit-us" (click)="centers('hyderabad')">Hyderabad</a></div>

          </div>
        </div>
        <!-- <div class="col-md-2 col-sm-12 mt-4 ">
          <div class="experience-center">
            <div class="text-style-1">VISIT OUR EXPERIENCE CENTRE</div>
            <div>Mumbai</div>
            <div>Delhi</div>
            <div>Pune</div>
            <div>Bangalore</div>
            <div>Chandigarh</div>
            <div>Thane</div>
            <div>Gurgaon</div>
            <div>Noida</div>
            <div>Nashik</div>
            <div>Jaipur</div>
            <div>Cochin</div>
            <div>Trivendrum</div>
          </div>
        </div> -->
        <div class="col-md-3 col-sm-12 mt-4">
          <div class="experience-center">
            <div class="text-style-1">MODULAR KITCHEN</div>
            <div>
              <a class="quick-links-style" target="_blank" [routerLink]="['/spaces']" 
              [queryParams]="{space_name:'Kitchen', page:'1'}"
                >Modular Kitchen in Bengaluru</a
              >
            </div>
            <div>
              <a class="quick-links-style"target="_blank" [routerLink]="['/spaces']" 
              [queryParams]="{space_name:'Kitchen', page:'1'}"
                >Modular Kitchen in Coimbatore</a
              >
            </div>
            <div>
              <a class="quick-links-style"target="_blank" [routerLink]="['/spaces']" 
              [queryParams]="{space_name:'Kitchen', page:'1'}"
                >Modular Kitchen in Delhi</a
              >
            </div>
            <div>
              <a class="quick-links-style"target="_blank" [routerLink]="['/spaces']" 
              [queryParams]="{space_name:'Kitchen', page:'1'}"
                >Modular Kitchen in Pune</a
              >
            </div>
            <div>
              <a class="quick-links-style"target="_blank" [routerLink]="['/spaces']" 
              [queryParams]="{space_name:'Kitchen', page:'1'}"
                >Modular Kitchen in Mumbai</a
              >
            </div>
            <div>
              <a class="quick-links-style"target="_blank" [routerLink]="['/spaces']" 
              [queryParams]="{space_name:'Kitchen', page:'1'}"
                >Modular Kitchen in Kolkata</a
              >
            </div>
            <div>
              <a class="quick-links-style"target="_blank" [routerLink]="['/spaces']" 
              [queryParams]="{space_name:'Kitchen', page:'1'}"
                >Modular Kitchen in Hyderabad</a
              >
            </div>
            <div>
              <a class="quick-links-style"target="_blank" [routerLink]="['/spaces']" 
              [queryParams]="{space_name:'Kitchen', page:'1'}"
                >Modular Kitchen in Chennai</a
              >
            </div>
            <div>
              <a class="quick-links-style"target="_blank" [routerLink]="['/spaces']" 
              [queryParams]="{space_name:'Kitchen', page:'1'}"
                >Modular Kitchen in Vijayawada</a
              >
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-12 mt-4">
          <div class="experience-center">
            <div class="text-style-1">WARDROBE DESIGN</div>
            <div>
              <a class="quick-links-style"target="_blank" routerLink="/spaces"
                >Wardrobe Design in Delhi</a
              >
            </div>
            <div>
              <a class="quick-links-style"target="_blank" routerLink="/spaces"
                >Wardrobe Design in Coimbatore</a
              >
            </div>
            <div>
              <a class="quick-links-style"target="_blank" routerLink="/spaces"
                >Wardrobe Design in Bengaluru</a
              >
            </div>
            <div>
              <a class="quick-links-style"target="_blank" routerLink="/spaces"
                >Wardrobe Design in Hyderabad</a
              >
            </div>
            <div>
              <a class="quick-links-style"target="_blank" routerLink="/spaces"
                >Wardrobe Design in Pune</a
              >
            </div>
            <div>
              <a class="quick-links-style"target="_blank" routerLink="/spaces"
                >Wardrobe Design in Chennai</a
              >
            </div>
            <div>
              <a class="quick-links-style"target="_blank" routerLink="/spaces"
                >Wardrobe Design in Mumbai</a
              >
            </div>
            <div>
              <a class="quick-links-style"target="_blank" routerLink="/spaces"
                >Wardrobe Design in Kolkata</a
              >
            </div>
            <div>
              <a class="quick-links-style"target="_blank" routerLink="/spaces"
                >Wardrobe Design in Vijayawada</a
              >
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-12 mt-4">
          <div class="experience-center">
            <div class="text-style-1">APARTMENTS WE COVER</div>
            <div>
              <a class="quick-links-style"target="_blank" routerLink="/apartment"
                >Apartments in Delhi</a
              >
            </div>
            <div>
              <a class="quick-links-style"target="_blank" routerLink="/apartment"
                >Apartments in Thane</a
              >
            </div>
            <div>
              <a class="quick-links-style"target="_blank" routerLink="/apartment"
                >Apartments in Mumbai</a
              >
            </div>
            <div>
              <a class="quick-links-style"target="_blank" routerLink="/apartment"
                >Apartments in Noida</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>