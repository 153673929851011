import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-customer-dashb-whatsapp',
  templateUrl: './customer-dashb-whatsapp.component.html',
  styleUrls: ['./customer-dashb-whatsapp.component.scss']
})
export class CustomerDashbWhatsappComponent implements OnInit {

  status1:any;
  status2:any;
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      var data = params.status.split(',')
      this.status1 = data[0];
      this.status2 = data[1];
    });
  }

}
