import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-stories-updated',
  templateUrl: './stories-updated.component.html',
  styleUrls: ['./stories-updated.component.scss']
})
export class StoriesUpdatedComponent implements OnInit {
  constructor() { }
  ngOnInit(): void {  
    this.loadScript()  
  }

  checkVideo() {
    var iframes = document.querySelectorAll('iframe');
    iframes[0].src = iframes[0].src;
    iframes[1].src = iframes[1].src;
    iframes[2].src = iframes[2].src;
    iframes[3].src = iframes[3].src;
    iframes[4].src = iframes[4].src;
  }
  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
