
<div class="root-div">
    <img class="backGroundImage" src="../../../assets/images/Landing_page_updated/Design-with-us/your-stories.png">
    <div class="row root-clients mx-lg-0 mx-4">
        <div class="root-space w-100">
            <div class="row justify-content-around">
                <div class="col-lg-12">
                    <p class="our-client">Let's Hear From Our Clients</p>
                </div>
            </div>
             <div class="hearFromClients w-100" >
                    <div class="mr-lg-5 apply-bg">
                        <div class="each-clients">
                            <div class="client-img-re">
                                <div class="ab-img">
                                    <img src="../../../assets/images/Landing_page_updated/Design-with-us/Santok Siggh Rathod Temp-80x80 (1).jpg">
                                </div>
                            </div>
                        </div>
                        <div class="content-for-clients">
                            <p class="name-of-client mb-0 mt-4">Santok Singh Rathod</p>
                            <p class="client-city">Mumbai, Maharashtra</p>
                            <p class="client-content mb-0">“We are very satisfied with the wardrobe, beds and the other furniture products designed by Arrivae.”</p>
                        </div>
                    </div>
                    <div class="mr-lg-5 apply-bg">
                        <div class="each-clients" >
                            <div class="client-img-re">
                                <div class="ab-img">
                                    <img src="../../../assets/images/Landing_page_updated/Design-with-us/VT Rema 01 Temp-80x80 (3).jpg">
                                </div>
                            </div>
                        </div>
                        <div class="content-for-clients">
                            <p class="name-of-client mt-4 mb-0">VT Rema</p>
                            <p class="client-city">Trivandrum, Kerala</p>
                            <p class="client-content mb-0">“Arrivae Team provided us with the 3D image of the space, designed exactly the same and installed it
                                perfectly.”</p>
                        </div>
                    </div>
                    <div class="mr-lg-5 apply-bg">
                        <div class="each-clients">
                            <div class="client-img-re">
                                <div class="ab-img">
                                    <img src="../../../assets/images/Landing_page_updated/Design-with-us/George & Sunny Varkey Temp-80x80 (2).jpg">
                                </div>
                            </div>
                        </div>
                        <div class="content-for-clients">
                            <p class="name-of-client mt-4 mb-0">George &amp; Sunny Varkey</p>
                            <p class="client-city">Kochi, Kerala</p>
                            <p class="client-content mb-0">
                                “Arrivae’s speed of execution, the rates and the designs were all upto the mark.”
                            </p>
                        </div>
                    </div>
                    <div class="mr-lg-5 apply-bg">
                        <div class="each-clients">
                            <div class="client-img-re">
                                <div class="ab-img">
                                    <img src="../../../assets/images/Landing_page_updated/Design-with-us/Mihir.jpg">
                                </div>
                            </div>
                        </div>
                        <div class="content-for-clients">
                            <p class="name-of-client mt-4 mb-0">Mihir Soyam</p>
                            <p class="client-city">Pune, Maharashtra</p>
                            <p class="client-content mb-0">“Arrivae’s design team is very open to the ideas. They listened to what we wanted and designed the
                                space according to our budget and requirements.”</p>
                        </div>
                    </div>
			</div> 

                <button class="next d-lg-block d-none">
                    <div class="d-flex justify-content-center ml-4 align-items-center">
                        <img class="img-fluid" src="../../../assets/images/carosel-icons/Group 170.png">
                        <p class="ml-2 mb-0">More</p>
                    </div>
                </button>
        </div>
    </div>
</div>
