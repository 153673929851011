<div class="wrapper">
    <div class="heading">
        <h1>Our Process</h1>
        <!-- <a href="#">See all</a> -->
    </div>

    <div class="carousel-container">
        <div class="carousel slide" id="main-carousel" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#main-carousel" data-slide-to="0" class="active">
                    <p>01</p>
                    <hr>
                    <p class="text">Meet the Designer</p>
                </li>
                <li data-target="#main-carousel" data-slide-to="1">
                    <p>02</p>
                    <hr>
                    <p class="text">Quotation &amp; Design Finalization</p>
                </li>
                <li data-target="#main-carousel" data-slide-to="2">
                    <p>03</p>
                    <hr>
                    <p class="text">Site Execution &amp; Intelligent Manufacturing</p>
                </li>
                <li data-target="#main-carousel" data-slide-to="3">
                    <p>04</p>
                    <hr>
                    <p class="text">Installation</p>
                </li>
                <li data-target="#main-carousel" data-slide-to="4">
                    <p>05</p>
                    <hr>
                    <p class="text">Project Handover</p>
                </li>
            </ol><!-- /.carousel-indicators -->
            
            <div class="carousel-inner">
                <div class="carousel-item active" data-interval="3000">
                    <div class="carousel-content">
                        <div class="image">
                            <img class="d-block img-fluid" src="../../../assets/images/How_we_work/Section-3_Our-Process_Step1.png" alt="">
                        </div>
                        <div class="content">
                            <h1>01</h1>
                            <h1>Meet the Designer</h1>
                            <p>Begin your dream home project by meeting our interior expert. Share your design brief and
                                requirements. Schedule an on-site measurement to kick-start the 3D designing.</p>
                        </div>
                    </div>
                </div>
                <div class="carousel-item" data-interval="3000">
                    <div class="carousel-content">
                        <div class="image">
                            <img class="d-block img-fluid" src="../../../assets/images/How_we_work/Section-3_Our-Process_Step2.png" alt="">
                        </div>
                        <div class="content">
                            <h1>02</h1>
                            <h1>Quotation &amp; Design Finalization</h1>
                            <p>Visualize your home with the 3D Renders prepared by the design experts. Confirm the project
                                design and quotation by paying 10% of the order value.</p>
                        </div>
                    </div>
                </div>
                <div class="carousel-item" data-interval="3000">
                    <div class="carousel-content">
                        <div class="image">
                            <img class="d-block img-fluid" src="../../../assets/images/How_we_work/Section-3_Our-Process_Step3.png" alt="">
                        </div>
                        <div class="content">
                            <h1>03</h1>
                            <h1>Site Execution &amp; Intelligent Manufacturing</h1>
                            <p>Our on-site teams start the interior work at your home. Your customized Made-To-Order
                                furniture goes under the manufacturing at this stage and you pay 40% of the balance.</p>
                        </div>
                    </div>
                </div>
                <div class="carousel-item" data-interval="3000">
                    <div class="carousel-content">
                        <div class="image">
                            <img class="d-block img-fluid" src="../../../assets/images/How_we_work/Section-3_Our-Process_Step4.png" alt="" >
                        </div>
                        <div class="content">
                            <h1>04</h1>
                            <h1>Installation</h1>
                            <p>The customized Made-To-Order furniture is delivered and installed at your home while you
                                pay 50% of the balance order value.</p>
                        </div>
                    </div>
                </div>
                <div id="last-carousel-item" class="carousel-item" data-interval="3000">
                    <div class="carousel-content">
                        <div class="image">
                            <img class="d-block img-fluid" src="../../../assets/images/How_we_work/Section-3_Our-Process_Step5.png" alt="">
                        </div>
                        <div class="content">
                            <h1>05</h1>
                            <h1>Project Handover</h1>
                            <p>Your dream home with your customized interior and furniture is ready to move in.
                                Start Making Memories!</p>
                        </div>
                    </div>
                </div>
                <!-- <button class="carousel-control-prev carousel-button" type="button" data-target="#main-carousel" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </button>
                <button class="carousel-control-next carousel-button" type="button" data-target="#main-carousel" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </button> -->
            </div><!-- /.carousel-inner -->
            
        </div><!-- /.carousel -->
    </div>
    
</div><!-- /.container -->
