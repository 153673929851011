import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-new-landing-page',
  templateUrl: './new-landing-page.component.html',
  styleUrls: ['./new-landing-page.component.scss']
})
export class NewLandingPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
