import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BookConsultationFormComponent } from 'src/app/shared/book-consultation-form/book-consultation-form.component';

@Component({
  selector: 'app-end-to-end',
  templateUrl: './end-to-end.component.html',
  styleUrls: ['./end-to-end.component.scss']
})
export class EndToEndComponent implements OnInit {

  constructor(
    private modalService: NgbModal

  ) { }

  ngOnInit(): void {
    this.roletoSet()

  }

  solutions:any=[
    {image:"../../../assets/images/end-to-end/1.png",tag:"Modular Kitchen"},
    {image:"../../../assets/images/end-to-end/2.png",tag:"Wardrobe"},
    {image:"../../../assets/images/end-to-end/3.png",tag:"Crockery Unit"},
    {image:"../../../assets/images/end-to-end/4.png",tag:"Space Saving Furniture"},
    {image:"../../../assets/images/end-to-end/5.png",tag:"TV Unit"},
    {image:"../../../assets/images/end-to-end/6.png",tag:"Study Tables"},
    {image:"../../../assets/images/end-to-end/7.png",tag:"False Ceiling"},
    {image:"../../../assets/images/end-to-end/8.png",tag:"Foyer Design"},
    {image:"../../../assets/images/end-to-end/9.png",tag:"Wallpapers"},
    {image:"../../../assets/images/end-to-end/10.png",tag:"Wall Paint"},
    {image:"../../../assets/images/end-to-end/11.png",tag:"Bathroom"},
    {image:"../../../assets/images/end-to-end/12.png",tag:"Pooja Unit"},
    {image:"../../../assets/images/end-to-end/13.png",tag:"Lights"},
    {image:"../../../assets/images/end-to-end/14.png",tag:"Moveable Furniture"},
    {image:"../../../assets/images/end-to-end/15.png",tag:"Kids Bedroom"},



  ]

  roletoSet(){
    var width = Math.max(window.screen.width, window.innerWidth)
     return width
  }

  
   bookConsultationLandingPage() {
     console.log("hii")
     const modal = this.modalService.open(BookConsultationFormComponent, {
       size: 'md',
     });
   }

}
