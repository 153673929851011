<app-new-header-for-home (sendData)="customFunc($event)" ></app-new-header-for-home>
<div class="rootdiv mt-5">
  <div class="container-fluid">
    <div class="row d-flex justify-content-center overflow-hidden margin-bottom  px-3 px-lg-0">
      <div class="col-md-11 text-justify root">
        <div class="ml-n3">
          <h2 class="brand">Privacy Policy</h2>

          <p class="brand-para">
            Arrivae takes your privacy very seriously. This privacy policy (“Privacy Policy”) is intended to inform you
            about the information that is collected from you when you visit website and applications or mobile sites
            (the “Platform”) owned and operated by Singularity Furniture Pvt Ltd and its affiliates (“Arrivae”); and how
            this information may be used and disclosed; and how your information is protected.
          </p>

          <p class="acceptance">Acceptance of terms</p>
          <p>PLEASE READ THE PRIVACY POLICY THOROUGHLY AND CAREFULLY.</p>
          <p>
            The terms and conditions set forth below through the Privacy Policy constitute a legally-binding agreement
            between Arrivae and you. This policy contains provisions that define your limits, legal rights and
            obligations with respect to your use of and participation on the Platform, including the classified
            advertisements, forums, various email functions and Internet links, and all content and Arrivae services
            available on the Platform. The Privacy Policy is applicable to all users of the Platform, including users
            who have access and/or register on the Platform, and users who are also contributors of content,
            information, private and public messages, advertisements, and other materials or services on the Platform.
          </p>
          <p class="secondpara">By visiting our Platform or by providing your information, you consent to the
            collection, use, storage and
            otherwise processing of your information (including sensitive personal information) on the Platform in
            accordance with this Privacy Policy. If you disclose to us any personal information relating to other
            people, you represent that you have the authority to do so and to permit Arrivae to use the information in
            accordance with this Privacy Policy.</p>

          <p class="acceptance">Information we collect</p>

          <p class="subtitle">Personal information</p>
          <p>
            “Personal information” is defined to include information that may be used to readily identify or contact you
            such as: name, address, PIN code, email address, phone number, order details, user records etc. that is
            otherwise not publicly available.
          </p>
          <p>
            When you sign up on the Platform we collect personal information from (i) users who are interested in
            availing our services, (ii) Design Studio Partners/studios who offer their design services to Arrivae
            customers (“Design Studio Partners”), and (iii) Service providers offering their services.
          </p>
          <p class="newparagraph">
            All information disclosed by you shall be deemed to be disclosed willingly and without any coercion. No
            liability pertaining to the authenticity/ genuineness/ misrepresentation/ fraud/ negligence, etc. of the
            information disclosed shall lie on Arrivae nor will Arrivae in any way be responsible to verify any
            information obtained from you.
          </p>

          <p class="subtitle">Personal information from social networking sites</p>
          <p>
            You may log into our Platform through your Facebook or Google account. If you do so, you must enter the
            email address and password that you use for your account. We will ask that you grant us permission to access
            and collect your Facebook basic information (this includes your name, profile picture, gender, networks,
            user IDs, list of friends, date of birth, email address, and any other information you have set to public on
            your Facebook account). If you allow us to have access to this information, then we will have access to this
            information even if you have chosen not to make that information public.
          </p>
          <p class="newparagraph">
            We store the information that we receive from Facebook with other information that we collect from you or
            receive about you. Facebook controls the information it collects from you. For information about how
            Facebook may use and disclose your information, including any information you make public, please consult
            Facebook's privacy policy. We have no control over how any third party site uses or discloses the personal
            information it collects about you.
          </p>

          <p class="subtitle">Calls and text messages</p>
          <p class="newparagraph">
            Arrivae enables users to call and chat with the Design Studio Partners, Service providers through the
            Platform. As otherwise described in this policy, your contact information may be accessible to a Design
            Partner or Service Partner for the sole purpose of providing services. In addition, regarding the calls or
            chats, we collect information including the date and time of the call/chat message(s), and the content of
            the calls and chat messages in order to improve our customer service and quality of services. Arrivae may
            also use this information for resolving disputes between you and Design Studio Partners or Service providers
            or other users, or for safety and security purposes, and for analytics.
          </p>

          <p class="subtitle">Age</p>
          <p class="newparagraph">
            We respect children's privacy. We do not knowingly or intentionally collect personal information from
            children under age 13. By accessing and/or registering on the Platform, you have represented and warranted
            that you are either 18 years of age or using the Platform with the supervision of a parent or guardian. If
            you are under the age of 13, please do not submit any personal information to us, and rely on a parent or
            guardian to assist you.
          </p>

          <p class="subtitle">Referrals</p>
          <p class="newparagraph">
            Arrivae offers the opportunity to any user to share details of anyone who they think may be interested in
            Arrivae or our services. This information is recorded and used only to communicate with the referee using
            mediums including phone calls and email, based on the details provided to Arrivae. We believe that it is
            within the purview of the referrer to seek authorization to share the personal information of the referee.
            When the information is shared with Arrivae, it is deemed that the authorization has already been obtained
            by the referrer.
          </p>

          <p class="subtitle">Communications</p>
          <p class="newparagraph">
            During your interaction with Arrivae, you may also participate in polls, surveys and feedback and personal
            information including call logs during such interactions will be collected. Further, when you communicate
            with us (via email, phone, texts, through the Platform or otherwise), we may maintain a record of your
            communication. When you use the Platform to communicate with others, we may collect and maintain a copy of
            those communications, in accordance with applicable law. Such information obtained from a user is gathered
            and utilized to ensure a greater experience for users and partners.
          </p>

          <p class="subtitle">Payment, billing and financial information</p>
          <p>
            When you create an account with Arrivae and start (i) availing services from us, our Design Studio Partners
            and or Service providers (ii) offer your services as a Design Partner or Service Partner, you may be
            required to disclose information such as bank details, PAN card, Aadhaar card, credit card details, etc. in
            order for us to process your request.
          </p>
          <p class="newparagraph">
            Additionally, Arrivae may also collect information related to the manner and mode of payments usually
            adopted by you on the Platform, including details of your bank account, credit card and debit card. The
            financial information collected may vary in accordance to your interaction with the Platform and our
            Partners.
          </p>
          

         

          <p class="subtitle">Device and location information</p>
          <p class="newparagraph">
            We collect information from your devices (computers, mobile phones, tablets, etc.), including information
            about how you interact with our services and information that allows us to recognize and associate your
            activity across devices and services. This information includes device specific identifiers and information
            such as location, IP address, cookie information, mobile device and advertising identifiers, browser
            version, operating system type and version, mobile network information, device settings, and software data.
            We may recognize your devices to provide you with personalized experiences and advertising across the
            devices you use. In addition, the information may also include your location.
          </p>

          <p class="subtitle">Information from cookies and other tracking methods</p>
          <p>
            This Platform employs cookies, web beacons and other tracking technologies to speed your navigation of the
            Platform, recognize you and your access privileges, and track your Platform usage. These help us in Platform
            analytics to give you a better experience. The same may be used to evaluate the success of our advertising
            campaigns. This includes information, but is not limited to, tracking how frequently you visit our Platform
            and use our services, time spent on the Platform, pages visited, links clicked, etc.
          </p>
          <p class="lastpara">
            We may use third-party advertising companies to help us tailor site content to users or to serve ads on our
            behalf. These companies may employ cookies and web beacons to measure advertising effectiveness (such as
            which web pages are visited or what products are purchased and in what amount). Any information that these
            third parties collect via cookies and web beacons is not linked to any personal information collected by us.
          </p>

          <p class="acceptance">Information from other sources</p>

          <p class="subtitle">These may include:</p>
          <p class="subpara">Feedback including surveys and ratings</p>
          <p>
            Arrivae business partners through which you create or access your Arrivae account, such as payment
            providers, social media services, or apps or websites who use Arrivae's APIs or whose API Arrivae uses
          </p>

          <ul class="ml-n4">
            <li>
              <p>Financial services providers.</p>
            </li>
            <li>
              <p>Marketing service providers.</p>
            </li>
          </ul>
          <p>Arrivae may combine the information collected from these sources with other information in its
            possession.</p>
          <p class="allpara">
            By choosing to provide any and all of the aforesaid information to Us, you are giving Arrivae permission to
            use and/or store such information consistent with this Privacy Policy. Further, Arrivae shall be entitled to
            retain your Personal information for such duration as may be required for the purposes specified hereunder
            and shall be used us only in accordance with this Privacy Policy.
          </p>

          <p class="subtitle">How do we use this information</p>

          <p class="subpara">Internal use</p>
          <p class="allparagraph">
            Arrivae uses the information we collect to provide, personalize, maintain and improve our products and
            services, including processing your order and providing you with end-to-end interior design and execution
            services. Additionally, the information may be used to improve our Platform s content and layout, to improve
            our outreach and for our own marketing efforts (including marketing our services and products to you), and
            to determine general marketplace information about visitors to this Platform. The information may be used
            for product development, testing and improvement of existing services and products. This allows us to
            develop new product features and also facilitate financial solutions and insurance that our connected to
            Arrivae services.
          </p>

          <p class="subpara">Providing services and features</p>
          <p>
            Arrivae uses the information we collect to provide, personalize, maintain and improve our products and
            services. This includes
          </p>
          <ul class="ml-n4">
            <li>
              <p>Matching you with a suitable Design and Service partner to offer our design and execution services.</p>
            </li>
            <li>
              <p>Facilitating in-account activities during your project such as in-website chat.</p>
            </li>
            <li>
              <p>Processing and placing orders for your project.</p>
            </li>
            <li>
              <p>Process or facilitate payment for your orders.</p>
            </li>
            <li>
              <p>Offer, obtain, provide or facilitate insurance or financing solutions in connection with our services
              </p>
            </li>
          </ul>
          <p>
            Perform internal operations necessary to provide our services, including to troubleshoot software bugs and
            operational problems, to conduct data analysis, testing, and research, and to monitor and analyze usage and
            activity trends
          </p>
          <p class="allparagraph">
            We confirm that the information that we collect from you may be used in aggregate form in various ways to
            optimize and improve Arrivae’s services, understand usage trends, analyse behavioral patterns, determine the
            effectiveness and usefulness of services and/or communications shared with you. While such information may
            be based on information about you, it will not identify you personally.
          </p>

          <p class="subpara">Customer support</p>
          <p>
            Arrivae uses information collected about you (including recordings of customer support calls after notice to
            you and with your consent) to assist you when you contact our customer support services, including to:
          </p>
          <ul class="ml-n4">
            <li>
              <p>Direct your questions to the appropriate customer support person</p>
            </li>
            <li>
              <p>Investigate and address your concerns</p>
            </li>
            <li>
              <p>Monitor and improve our customer support response</p>
            </li>
            <li>
              <p>Communications with You</p>
            </li>
          </ul>
          <p class="allparagraph">
            Arrivae uses the information shared by you to communicate with you, including for the purpose of project
            updates, customer experience surveys, promotional offers and account information. The mode of communication
            includes emails, electronic newsletters, invitations, polls and surveys.
          </p>

          <p class="subpara">Communications among users and with Arrivae partners</p>
          <p class="allparagraph">
            To offer our services, or to facilitate Arrivae Partners to offer their services, personal information,
            partially or fully, is made available to users of Arrivae. As an example, when you sign up to receive
            services from Arrivae, your contact information including phone number and email is shared with a Design
            Partner and/or Service Partner to offer his/her service for your project. Sometimes, the users are allowed
            to contact each other for marketing purposes as well.
          </p>

          <p class="subpara">How we share this information</p>
          <p class="allparagraph">Arrivae does not sell or transfer your Personal information (including your phone number,
            email address) to
            third-parties for the use of their own marketing.
          </p>

          <p class="subpara">Third party ad networks</p>
          <p class="allparagraph">
            Third party ad networks display advertisements on other websites based on your visits to the Platform as
            well as other websites. These ad networks, sponsors, advertisers and traffic measurement services, employ
            technologies such as cookies, JavaScript, web beacons, and others to capture information governed by their
            own specific privacy policy(ies). We participate in these networks to enable us and the third parties to
            display advertisement for products and services that might interest you.
          </p>

          <p class="subpara">Analytics and reports</p>
          <p class="allparagraph">
            The information collected is used to create analytic reports for both internal and external partners,
            including business partners and the public regarding the use of and trends within our services and ads. For
            the purpose of studying and sharing trends, we may share aggregated or pseudonymous information (including
            demographic information), that is limited to non-personally identifiable information, with partners,
            measurement analytics, apps, third party ad networks, or media.
          </p>

          <p class="subpara">Public Posts</p>
          <p class="allparagraph">
            You (including Design Studio Partners and Service providers) may provide your feedback, reviews, comments,
            content, other information etc. on the Platform (“Posts”). Your Posts shall have to comply with the
            conditions relating to Posts as mentioned in the Terms of Use. Arrivae retains an unconditional right to
            remove and delete any Post or such part of the Post that, in the opinion of Arrivae, does not comply with
            the conditions in the Terms of Use. [All Posts shall be publicly accessible and visible to all Users of the
            Platform.] Arrivae reserves the right to use, reproduce and share Your Posts for any purpose. If you delete
            Your Posts from the Platform, copies of such Posts may remain viewable in archived pages, or such Posts may
            have been copied or stored by other users.
          </p>

          <p class="subpara">Information disclosed at your request</p>
          <p class="allparagraph">
            To avail Arrivae service(s), you may give us consent to share such personal information, as may be required,
            with our Design Studio Partners/ Service providers offering those services. In the event of such a request,
            Arrivae shares only the information necessary for them to offer the specific service. All Design Studio
            Partners and Service providers are required to follow appropriate confidentiality and safety measures to
            protect your data.
          </p>

          <p class="subpara">New ownership or bankruptcy</p>
          <p>
            If the ownership or control of all or part of Arrivae changes as a result of a merger, acquisition or sale
            of assets, we may transfer your information to the new owner, a third party entity (a "Successor")
          </p>
          <p class="allparagraph">
            In addition, in the event of Arrivae's bankruptcy, reorganization, receivership, or assignment for the
            benefit of creditors, or the application or laws or equitable principles affecting creditors' rights
            generally, Arrivae may not be able to control how your information is transferred, used, or treated and
            reserves the right to transfer the information we have collected from the users of the Platform and/or
            services to non-affiliated third parties in such event.
          </p>

          <p class="subpara">Legal investigations</p>
          <p class="allparagraph">
            We may investigate and disclose information from or about you, as permitted by applicable law, if we believe
            that such investigation or disclosure is (a) necessary to comply with legal process and law enforcement
            instructions and orders, such as a search warrant, order, statute, judicial proceeding, or other legal
            process served on us; (b) helpful to prevent, investigate, or identify possible wrongdoing in connection
            with the Platform; or (c) helpful to protect our rights, reputation, property, or that of our users,
            subsidiaries, or the public. In such an event Arrivae shall be under no obligation to inform you or seek
            your approval or consent.
          </p>

          <p class="subpara">Data security</p>
          <p>Online and during your interactions with Arrivae</p>
          <p>
            Arrivae shall take reasonable steps to help protect your rights of privacy and your information (personal or
            otherwise) in an effort to prevent loss, misuse, unauthorized access, disclosure, alteration, or destruction
            of such information, in compliance with the applicable laws. This Platform uses physical, electronic, and
            administrative procedures to safeguard the confidentiality of your information. SSL encryption is used to
            protect your personal information online, and we also take several steps to protect your personal
            information in our facilities. Access to your personal information is restricted. Only employees and
            Partners (Design & Service) who need access to your personal information to perform a specific job or
            provide a service are granted access to your personal information. Finally, we rely on third-party service
            providers for the physical security of some of our computer hardware. We believe that their security
            procedures are adequate. For example, when you visit our Platform you access servers that are kept in a
            secure physical environment, behind a locked cage and an electronic firewall. Arrivae assumes no liability
            or responsibility for disclosure of your information due to errors in transmission, unauthorized third party
            access, or other causes beyond its control.
          </p>
          <p>
            At times we may retain third parties to analyze data we collect on our Platform, and to administer the
            services provided (such as shipping, web hosting, etc. as described above), solely to help us provide and
            improve our products, services and Platform. These suppliers and other third parties are not authorized by
            us to use your personal information in any other way. We may have to use personal information to investigate
            complaints and protect Arrivae and its members, in compliance with the law, your Membership Agreement and
            Arrivae policies.
          </p>
          <p>
            While we use industry-standard precautions to safeguard your personal information, we cannot guarantee
            complete security. 100% complete security does not presently exist anywhere online or offline.
          </p>
          <p class="newpara1">
            In the unlikely event that we believe that the security of your personal information in our possession or
            control may have been compromised, we may seek to notify you of that development. If a notification is
            appropriate, we would endeavor to do so as promptly as possible under the circumstances, and, to the extent
            we have your email address, we may notify you by email.
          </p>

          <p class="acceptance">Links to external websites</p>
          <p class="subtitle">Information not controlled by Arrivae</p>
          <p class="allparagraph">
            This Platform may contain links to other websites or resources over which Arrivae does not have any control.
            Such links do not constitute an endorsement by Arrivae of those external websites. You acknowledge that
            Arrivae is providing these links to you only as a convenience, and further agree that Arrivae is not
            responsible for the content of such external websites. We are not responsible for the protection and privacy
            of any information which you provide while visiting such external websites and such sites are not governed
            by this Policy. Your use of any external website is subject to the terms of use and privacy policy located
            on the linked to external website.
          </p>

          <p class="subtitle">Choice and transparency</p>
          <p class="subpara">Accessing and Correcting Your Information</p>
          <p class="allparagraph">
            You can update the details associated with your account by visiting My account and accessing the account
            settings on the Platform. You can also look up your payment information, details about your project(s),
            services, etc. You may also update the information yourself, if allowed on the Platform or request updation
            of your information by contacting Arrivae at <a href="mailTo:wecare@arrivae.com">wecare@Arrivae.com</a></p>

          <p class="subtitle">Marketing Opt-Outs</p>
          <p class="allparagraph">
            We may send promotional or informational emails to you..You may opt out of receiving promotional emails from
            Arrivae the opt-out instructions contained in the email. Please note that if you opt out, we may still send
            you non-promotional messages, such as meeting invites, project progress, etc.
          </p>

          <p class="subtitle">Cookie choices</p>
          <p class="allparagraph">
            Most web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your
            browser to remove or reject cookies. If you choose to remove or reject cookies, this could affect certain
            features of the Platform and/or services.
          </p>

          <p class="subtitle">Withdrawal of consent</p>
          <p class="allparagraph">
            You may choose to withdraw your consent provided hereunder at any point in time. Such withdrawal of the
            consent must be sent in writing to <a href="mailTo:wecare@arrivae.com">wecare@Arrivae.com</a>. In case you
            do not provide your consent or later
            withdraw your consent, we request you not to access the Platform and use the services and also reserve the
            right to not provide you any services on the Platform. In such a scenario, Arrivae may delete your
            information (personal or otherwise) or de-identify it so that it is anonymous and not attributable to you.
          </p>

          <p class="subtitle">Amendments</p>
          <p class="amendments">
            Arrivae retains an unconditional right to modify or amend this Privacy Policy without any requirement to
            notify you of the same. You can determine when this Privacy Policy was last modified by referring to the
            “Last Updated” legend above. It shall be your responsibility to check this Privacy Policy periodically for
            changes. Your continued use of the Platform following the posting of changes to this Privacy Policy on the
            Platform, will constitute your consent and acceptance of those changes.
          </p>

          <p class="acceptance">Grievances and Disputes</p>
          <p class="subtitle">Grievances</p>
          <p class="allparagraph">
            In the event You have any grievances relating to the Privacy Policy, please inform Arrivae within 24 hours
            of occurrence of the instance from which the grievance has arisen, by writing an email to the Grievance
            Officer at <a href="mailTo:wecare@arrivae.com">wecare@Arrivae.com</a>. <br><br>

            Website: <a href="https://singularitycredit.com/" target="_blank">singularitycredit.com</a><br>

            Customer Care: <a href="mailto:wecare@singularitycredit.com"> wecare@singularitycredit.com</a> <br>
            Phone: <a class="numbers" href="tel:2266441886">+91-22-6644-1886</a>  <br>

            Registered Address: 16th Floor, D Wing Trade World, Kamala Mills, Senapati Bapat Road, Lower Parel, Mumbai, 400013, Maharashtra <br>

            Grievance Officer: Prem Shankar<br>
            Email ID: <a  href="mailto:grievances@singularitycredit.com">grievances@singularitycredit.com</a><br>
            Loan Products: EasyEMI, Personal Loan<br>
          </p>
          <p class="subtitle">Dispute resolution</p>
          <p>
            Any dispute, controversy or claim directly or indirectly caused by, arising out of or relating to this
            Privacy Policy will be governed by the laws of India and will be referred to confidential, mandatory and
            binding arbitration in Mumbai, India. The arbitration will be conducted on an expedited basis before a
            single arbitrator appointed by Arrivae in accordance with the provisions of the Indian Arbitration and
            Conciliation Act, 1996. The arbitrator's award shall be substantiated in writing and will be final and
            binding on you and Arrivae. Subject to the above, you agree to submit yourself to the exclusive jurisdiction
            of the Courts in Mumbai, India.
          </p>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
