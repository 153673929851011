<div class="arrivae-living   p-md-0 py-4 mx-md-5 mt-md-5" *ngIf="allreviews?.length > 1">
    <div class="d-flex flex-column justify-content-start  main-heading ">
        <p class="mb-1"><span class="pb-1">CUSTOMER</span> REVIEWS</p>
        <div *ngIf="roletoSet() > 650" class="d-flex">
            <button type="button"  (click)="navigateAll()"  class="mt-2 button  align-items-end d-md-flex d-none" style="background: #8d0528;color: #fff;">VIEW MORE &nbsp;
                <span style="margin-bottom: 1px;">
                    <img src="../../../assets/images/Arrow 1.png" />
                </span>
            </button>
            <button (click)="openInNewTab()"  class="button ml-3 mt-2" style="color: #8d0528;background: #fff; border: 2px solid #8d0528;">
                JOIN OUR PROMOTER PROGRAM &nbsp;
            </button>
        </div>
    </div>

    <div class="mt-md-3 w-100 pt-md-2 pb-3 ">
        <div class="row align-items-center Slider-mobile px-lg-1 ">
            <div class="col-12 mobile_padding_cs">
                <div *ngIf="allreviews?.length > 1 && roletoSet() < 650"
                    class="d-flex w-100 mt-2 icons_moile_padd justify-content-between">
                    <div class="col-1 prev prev_mobile">
                        <div class="float-right">
                            <div class="other-mobile-boqs">
                                <img class="cursorPointer" (click)="slickController.prev()"
                                    src="../../../assets/images/arrivaeHomePageImages/previcon.png" alt="previous">
                            </div>
                        </div>
                    </div>
                    <div class="col-1 next pr-5 next_mobile">
                        <div class="float-right floatInMobile ">
                            <div class="other-mobile-boqs">
                                <img class="cursorPointer" (click)="slickController.next()"
                                    src="../../../assets/images/arrivaeHomePageImages/nexticon.png" alt="next">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="Buildings_container row mx-0 mt-4">
                    <ng-container *ngIf="allreviews?.length > 1 && roletoSet() > 650" >
                        <div class="col-1 d-flex align-items-center prev justify-content-end">
                            <div class="float-right">
                                <div class="other-mobile-boqs">
                                    <img class="cursorPointer" (click)="slickController.prev()"
                                        src="../../../assets/images/arrivaeHomePageImages/previcon.png" alt="previous">
                                </div>
                            </div>
                        </div>
                        <div class="mobile-carosel-active-boqs col-10 ">
                            <div slickContainer #slickController="slick" [slickConfig]="config">
                                <ng-container *ngIf="slickController.initialize">
                                    <div *ngFor="let item of allreviews " slickItem class="card ml-lg-3  cursorPointer">    
                                        <div (click)="OpenInDeatil(item.attributes.id)" class="w-100 d-flex justify-content-center align-items-center  ReviewCarier" [ngStyle]="{ 'background-image': 'url(' + item.attributes.feature_url + ')' }">
                                            <div class="w-100 h-100 d-flex bgBlur justify-content-center align-items-center ">
                                                <div class="w-75 DetailsOFslide">
                                                    <h3 class="mt-3 mb-3">{{item.attributes.customer_name}}</h3>
                                                    <p class="truncate"> {{item.attributes.review }} <span *ngIf="item.attributes.review.length > 50">...</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>    
                                </ng-container>
                            </div>
                        </div>
                        <div class="col-1 d-flex align-items-center next pr-5">
                            <div class="float-right floatInMobile ">
                                <div class="other-mobile-boqs">
                                    <img class="cursorPointer" (click)="slickController.next()"
                                        src="../../../assets/images/arrivaeHomePageImages/nexticon.png" alt="next">
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div [ngClass]="roletoSet() < 650?'d-block':'d-none'" class="mobile-carosel-active-boqs  w-100 ">
                        <div slickContainer #slickController="slick" [slickConfig]="config">
                            <ng-container *ngIf="slickController.initialize">
                                <div *ngFor="let item of allreviews " slickItem class="card ml-lg-3  cursorPointer">
                                    <div (click)="OpenInDeatil(item.attributes.id)" class="w-100 d-flex justify-content-center align-items-center  ReviewCarier" [ngStyle]="{ 'background-image': 'url(' + item.attributes.feature_url + ')' }">
                                        <div class="w-100 h-100 d-flex bgBlur justify-content-center align-items-center ">
                                            <div class="w-75 DetailsOFslide">
                                                <h3 class="mt-3 mb-3">{{item.attributes.customer_name}}</h3>
                                                <p class="truncate"> {{item.attributes.review }} <span *ngIf="item.attributes.review.length > 50">...</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="roletoSet() < 650" class="d-flex w-100 mt-4 justify-content-center flex-column align-items-center">
                <button  (click)="navigateAll()" class=" button" style="background: #8d0528;color: #fff;">
                    VIEW ALL &nbsp;
                    <span>
                        <img src="../../../assets/images/Arrow 1.png" />
                    </span>
                </button>
                <button (click)="openInNewTab()"  class="button mt-3" style="color: #8d0528;background: #fff; border: 2px solid #8d0528;">
                    JOIN OUR PROMOTER PROGRAM &nbsp;
                </button>
            </div>

        </div>
    </div>
</div>