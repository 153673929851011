import { Component, OnInit } from '@angular/core';
declare var $: any;
@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss']
})
export class HowItWorksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.slickSlide();
    this.loadScript()
  }

  slickSlide() {
    $('#main-carousel').on('slide.bs.carousel', '', function () {
      if ($("#last-carousel-item").hasClass("active")) {
        $('#main-carousel').on('slid.bs.carousel', function() {
          $(".carousel-indicators li:not(:first)").removeClass("activeEle");
        })
      } else {
        $( "#main-carousel .active" ).addClass( "activeEle" );
        $('#main-carousel').off('slid.bs.carousel');
      }
    })
  }
  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
