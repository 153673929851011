import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-get-store',
  templateUrl: './get-store.component.html',
  styleUrls: ['./get-store.component.scss']
})
export class GetStoreComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.loadScript()
  }

  getLocation() {
    alert('duiwgduwi');
  }
  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
