<div id="top" class="body">
    <header>

        <div class="container">
            <div class="main-menu">
                <a href="#" class="logo"><img loading="lazy" src=" https://qr.arrivae.com/assets/logo1.svg"
                        alt="" /></a>
                <div class="header-right">
                    <a href="#" class="call-btn "><img loading="lazy"
                            src=" https://qr.arrivae.com/assets/call-btn-icon.svg" /></a><a
                        href="tel:+917406343311" class="pr-4 call-btn"> +91-7406343311</a>
                    <a (click)="roletoSet()" class="btn btn-primary"> Get Free Quote <i class="fa-solid fa-arrow-right-long"></i></a>
    
                </div>
    
            </div>
        </div>

    </header>
   

    <section class="masterhead">
        <div class="masterhead-slider">
            <div class="masterhead-slider-item">
                <div class="masterhead-slider-image"><img loading="lazy"
                        src=" https://qr.arrivae.com/assets/banner.jpg" alt="" /></div>
                <div class="masterhead-slider-content">
                    <div class="container">
                        <div class="masterhead-slider-content-wrapper">
                            <h3>Welcome To <span>Arrivae</span></h3>
                            <h1>Your <span>Home Interior Solutions </span>Gateway</h1>
                            <h4>Dream home interiors Made by <span>You,</span> Executed by <span>Us</span></h4>
                            <ul class="service-category">
                                <li><a >Home Interiors</a></li>
                                <li><a >Modular Kitchens</a></li>
                                <li><a >Furniture (Modular & Custom)</a></li>
                                <li><a >Civil Work & more</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="masterhead-slider-item">
                <div class="masterhead-slider-image"><img loading="lazy"
                        src=" https://qr.arrivae.com/assets/Banner%201.jpg" alt="" /></div>
                <div class="masterhead-slider-content">
                    <div class="container">
                        <div class="masterhead-slider-content-wrapper">
                            <h3>Newly Launched <span>Design Centre</span></h3>
                            <h1><span>Visit us at Trillium Avenue,Sector29, Gurgaon</span></h1>
                            <h4>Gurgaon's Largest Interior Design Centre<span></span><span></span></h4>

                        </div>
                    </div>
                </div>
            </div>
            <div class="masterhead-slider-item">
                <div class="masterhead-slider-image"><img loading="lazy"
                        src=" https://qr.arrivae.com/assets/Banner%202.jpg" alt="" /></div>
                <div class="masterhead-slider-content">
                    <div class="container">
                        <div class="masterhead-slider-content-wrapper">
                            <h3>Newly Launched <span>Design Centre</span></h3>
                            <h1><span>Visit us at VR Bengaluru, Whitefield, Bengaluru </span></h1>
                            <h4>Exclusive Interior Design Themes<span></span><span></span></h4>

                        </div>
                    </div>
                </div>
            </div>


            <div class="masterhead-slider-item">
                <div class="masterhead-slider-image"><img loading="lazy"
                        src=" https://qr.arrivae.com/assets/Banner%203.jpg" alt="" /></div>
                <div class="masterhead-slider-content">
                    <div class="container">
                        <div class="masterhead-slider-content-wrapper">
                            <h3>Newly Launched <span>Design Centre</span></h3>
                            <h1><span>Visit us at Shelton Saphire,CBD Belapur, Navi Mumbai</span></h1>
                            <h4>Interior Design Themes - Never seen before<span></span><span></span></h4>

                        </div>
                    </div>
                </div>
            </div>




            <div class="masterhead-slider-item">
                <div class="masterhead-slider-image"><img loading="lazy"
                        src=" https://qr.arrivae.com/assets/banner.jpg" alt="" /></div>
                <div class="masterhead-slider-content">
                    <div class="container">
                        <div class="masterhead-slider-content-wrapper">
                            <h3>Visit us <span>Across 15 cities</span></h3>
                            <ul class="service-category">
                                <li><a href="#">Andheri, Mumbai</a></li>
                                <li><a href="#">Rushab Mall, Thane</a></li>
                                <li><a href="#">Mulund, Mumbai</a></li>
                                <li><a href="#">Balewadi, Pune</a></li>
                                <li><a href="#">Bhandarkar Road, Pune</a></li>
                                <li><a href="#">Gachibowli, Hyderabad</a></li>
                                <li><a href="#">Sarath City Mall, Hyderabad</a></li>
                                <li><a href="#">JP Nagar, Bengaluru</a></li>
                                <li><a href="#">Trivandrum</a></li>
                                <li><a href="#">Duncan Road, Mumbai</a></li>
                                <li><a href="#">Vijayawada</a></li>
                            </ul>

                        </div>
                    </div>
                </div>
            </div>

        </div>

    </section>

    <div class="fixed-mobile-nav">
        <div class="fixed-mobile-nav-wrapper">
            <div class="fixed-mobile-nav-item">
                <a  href="https://api.whatsapp.com/send?phone=917506156985" target="_blank" ><img loading="lazy"
                        src=" https://qr.arrivae.com/assets/fixed-mobile-nav-icon-2.svg"
                        /><span>Chat</span></a>
            </div>
            <div class="fixed-mobile-nav-item">
                <a (click)="scrollto()">Get Free Quote</a>
            </div>
            <div class="fixed-mobile-nav-item">
                <a  href="tel:+917406343311"><img loading="lazy" src=" https://qr.arrivae.com/assets/call-us-icon.png"
                        alt="" /><span>Call Us</span></a>
            </div>
        </div>
    </div>



    <section class="header-contact-form-item" (mouseenter)="showgoogle()" >
        <div class="container">
            <div class="header-contact-form__wrapper">
                <h2>Get Free Design Quote</h2>
                <form class="" [formGroup]= "BookingDetails">
                    <div class="row">
                        <div class="col-md-4">
    
                            <div class="form-col">
                                <input class="text_num" type="text" formControlName="name" placeholder="Name">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-col">
                                <input class="text_num" type="text" formControlName="email" placeholder="Email ID">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-col">
                                <div class="sub-forem-started__wrapper">
                                    <input type="number" formControlName="contact"    id="mobile-number" class="pl-5 text_num" placeholder="Phone number"  oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);">
                                    <img loading="lazy" style="position: relative;bottom:40px;
                                    width: 40px;left: 5px"  src="../../../assets/images/icons/india-flag.png" alt="phone icon">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-col">
                                <div class="form-col-check-box">
                                    <input type="checkbox" id="fruit1"  value="Apple" checked>
                                    <label for="fruit1">Send me updates on WhatsApp </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 " *ngIf="Loader2">
                            <div class="form-col form-col-search">
                                <input type="text" class="text_num" placeholder="Type your apartment/property name…" formControlName="apartment"  ngx-google-places-autocomplete #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)">
                              
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-col-submit">
                                <!-- <input type="submit" value="Get Free Quote"> -->
                                <button   [disabled]="checkDisable()"   (click) ="DetailsSubmit()"  >Get Free Quote</button>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-col  privacy">
                                <span>I agree to Arrivae’s <a href="https://web.arrivae.com/privacy-policy"> Privacy Policy
                                    </a></span>
                            </div>
                        </div>
                    </div>

                </form>
                
            </div>
        </div>
    </section>

    <section class="hulf-image-text" >
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="hulf-image-text__row">
                        <!--  -->
                        <div class="hulf-image-text__hearding">
                            <h5>We cover it all</h5>
                            <h2>One-Stop Solution for your Dream Home Interiors</h2>
                        </div>
                        <!--  -->
                        <div class="hulf-image-text__wrapper">
                            <div class="hulf-image-text_image">
                                <img loading="lazy" src=" https://qr.arrivae.com/assets/Space%20Design.jpg"
                                    alt="" />
                            </div>
                            <div class="hulf-image-text_content">
                                <a href="#">Space Planning</a>
                                <p>Space planning is an essential step in the interior design process. Without proper
                                    space planning, there is a high risk creating a design that is not optimal.
                                    Arrivae's in-depth analysis together with basic design principles helps us create a
                                    well-balanced, easily navigable and beautiful space. Whether you are looking for a
                                    partial space, a room or anything, Arrivae does it all.
                                </p>
                            </div>
                        </div>
                        <div class="hulf-image-text__wrapper">
                            <div class="hulf-image-text_image">
                                <img loading="lazy" src=" https://qr.arrivae.com/assets/Furniture%20(Modular%20%26%20Custom).jpg"
                                    alt="" />
                            </div>
                            <div class="hulf-image-text_content">
                                <a href="#">Furniture (Modular & Custom)</a>
                                <p>Furniture matters the most when a design is implemented as furniture brings function
                                    to a space. It has a direct impact on the quality, aesthetics and functionality of
                                    your dream home. At Arrivae, we are sharply focused on providing furniture elements,
                                    that blend with the function of the spaces. Be it made-to-order furniture, modular,
                                    loose furniture or any other woodwork, Arrivae does it all to your aspirations.
                                </p>
                            </div>
                        </div>



                        <div class="hulf-image-text__wrapper">
                            <div class="hulf-image-text_image">
                                <img loading="lazy" src=" https://qr.arrivae.com/assets/Lighting-%26-Electricals.jpg"
                                    alt="" />
                            </div>
                            <div class="hulf-image-text_content">
                                <a href="#">Lighting & Electricals</a>
                                <p>Lighting is one of the most important components in your home. Not only do decorative
                                    fixtures make a huge impact on the design of your space (‘form’), but how much
                                    lighting and the type of lighting you have greatly impacts how your house
                                    ‘functions’. Be it home automation, choosing & placement of switches or any to other
                                    electrical components & fixtures - designers at Arrivae are experts in providing
                                    end-to-end solutions on your needs

                                </p>
                            </div>
                        </div>





                        <div class="hulf-image-text__wrapper">
                            <div class="hulf-image-text_image">
                                <img loading="lazy" src=" https://qr.arrivae.com/assets/Painting-Services.jpg"
                                    alt="" />
                            </div>
                            <div class="hulf-image-text_content">
                                <a href="#">Painting Services</a>
                                <p>Paint, Wallpaper, POP/Painting give that life to a well designed space. It is
                                    important to choose the right color, texture and quality to get that aww look for
                                    your dream space. Experts at Arrivae understand this well and curate the designs in
                                    such a way that all these elements provide balanced-theme to your dream space.</p>
                            </div>
                        </div>




                        <!--  -->
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="hulf-image-text__row add-right-item">
                        <!--  -->
                        <div class="hulf-image-text__wrapper">
                            <div class="hulf-image-text_image">
                                <img loading="lazy" src=" https://qr.arrivae.com/assets/image-text-image3.jpg"
                                    alt="" />
                            </div>
                            <div class="hulf-image-text_content">
                                <a href="#">Modular Kitchens</a>
                                <p>Modular Kitchens are the best way to convert your home into a stylish and more
                                    functional space. Modular kitchens by Arrivae are designed in such a manner that
                                    they can fit into any size or layout. Our interior design experts helps you to
                                    achieve a clutter-free design which also utilizes the given space in a smart manner
                                    to increase storage along with being aesthetically more pleasing. L-Shape,
                                    island-kitchen, straight, parallel & more - you name it, Arrivae interior designers
                                    are experts at all of them.</p>
                            </div>
                        </div>
                        <div class="hulf-image-text__wrapper">
                            <div class="hulf-image-text_image">
                                <img loading="lazy" src="https://qr.arrivae.com/assets/Decor%20%26%20Furnishing.jpg"
                                    alt="" />
                            </div>
                            <div class="hulf-image-text_content">
                                <a href="#">Décor & Furnishing</a>
                                <p>At Arrivae, our design experts curate the decor & furnishing items to help glue and
                                    bind all the elements of an interior together, creating a complete and cohesive
                                    look. Our soft funishings include fabric-based “soft” decorative pieces such as
                                    curtains, cushions, carpets, upholstery, and more. Among decor items we carefully
                                    choose artifacts, wall art, planters, lamps, paintings & more.</p>
                            </div>
                        </div>


                        <div class="hulf-image-text__wrapper">
                            <div class="hulf-image-text_image">
                                <img loading="lazy" src=" https://qr.arrivae.com/assets/Bathrooms.jpg"
                                    alt="" />
                            </div>
                            <div class="hulf-image-text_content">
                                <a href="#">Bathrooms</a>
                                <p>Most houses have one or more types of bathrooms: a full bathroom, a three-quarter
                                    bathroom, or a half-bathroom, in a variety of combinations. Whether you want to
                                    remodel, renovate or accessorize your bathrooms - we at Arrivae have covered for
                                    you. So wake upto that bespoke feeling.
                                </p>
                            </div>
                        </div>



                        <div class="hulf-image-text__wrapper">
                            <div class="hulf-image-text_image">
                                <img loading="lazy" src=" https://qr.arrivae.com/assets/Civil%20Work1.jpg"
                                    alt="" />
                            </div>
                            <div class="hulf-image-text_content">
                                <a href="#">Civil Work</a>
                                <p>Arrivae is capable of carrying out small or heavy civil work including demolition,
                                    realignment, re-building certain elements of your home or space.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="gallery-item"  (scroll)="lazyload($event)">
        <div class="hearding-content text-center">
            <h2>Inspiration Gallery</h2>
            <p>For those perfect spaces</p>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="gallery-item__wrapper">
                        <img loading="lazy" src="https://qr.arrivae.com/assets/gallery1.jpg" alt="" />
                        <a href="https://qr.arrivae.com/assets/gallery1.jpg"
                            data-fancybox="gallery">Living Rooms</a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 1.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 2.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 3.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 4.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 5.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 6.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 7.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 8.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 9.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 10.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 11.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 12.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 13.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 14.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 15.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 16.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 17.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 18.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 19.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 20.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 21.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 22.jpg"
                            data-fancybox="gallery"></a>
                            <a href="../../../assets/images/Living_Rooms/Living Room 23.jpg"
                            data-fancybox="gallery"></a>

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="gallery-item__wrapper">
                        <img loading="lazy" src=" https://qr.arrivae.com/assets/gallery2.png" alt="" />
                        <a href=" https://qr.arrivae.com/assets/gallery2.png"
                            data-fancybox="gallery2">Kidsroom</a>
                            <a href="../../../assets/images/Kid Rooms/Kid Room 1.jpg"
                            data-fancybox="gallery2"></a> 
                            <a href="../../../assets/images/Kid Rooms/Kid Room 2.jpg"
                            data-fancybox="gallery2"></a>
                            <a href="../../../assets/images/Kid Rooms/Kid Room 3.jpg"
                            data-fancybox="gallery2"></a>
                            <a href="../../../assets/images/Kid Rooms/Kid Room 4.jpg"
                            data-fancybox="gallery2"></a>
                            <a href="../../../assets/images/Kid Rooms/Kid Room 5.jpg"
                            data-fancybox="gallery2"></a>
                            <a href="../../../assets/images/Kid Rooms/Kid Room 6.jpg"
                            data-fancybox="gallery2"></a>
                            <a href="../../../assets/images/Kid Rooms/Kid Room 7.jpg"
                            data-fancybox="gallery2"></a>
                            <a href="../../../assets/images/Kid Rooms/Kid Room 8.jpg"
                            data-fancybox="gallery2"></a>
                            <a href="../../../assets/images/Kid Rooms/Kid Room 9.jpg"
                            data-fancybox="gallery2"></a>
                            <a href="../../../assets/images/Kid Rooms/Kid Room 10.jpg"
                            data-fancybox="gallery2"></a>
                            <a href="../../../assets/images/Kid Rooms/Kid Rooms 11.jpg"
                            data-fancybox="gallery2"></a>
                            <a href="../../../assets/images/Kid Rooms/Kid Rooms 12.jpg"
                            data-fancybox="gallery2"></a>
                            <a href="../../../assets/images/Kid Rooms/Kid Rooms 13.jpg"
                            data-fancybox="gallery2"></a>
                            <a href="../../../assets/images/Kid Rooms/Kid Rooms 14.jpg"
                            data-fancybox="gallery2"></a>
                            <a href="../../../assets/images/Kid Rooms/Kid Rooms 15.jpg"
                            data-fancybox="gallery2"></a> 
                            <a href="../../../assets/images/Kid Rooms/Kid Rooms 16.jpg"
                            data-fancybox="gallery2"></a>
                            <a href="../../../assets/images/Kid Rooms/Kid Rooms 17.jpg"
                            data-fancybox="gallery2"></a>
                            <a href="../../../assets/images/Kid Rooms/Kid Rooms 18.jpg"
                            data-fancybox="gallery2"></a>
                            <a href="../../../assets/images/Kid Rooms/Kid Rooms 19.jpg"
                            data-fancybox="gallery2"></a>

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="gallery-item__wrapper">
                        <img loading="lazy" src=" https://qr.arrivae.com/assets/gallery3.png" alt="" />
                        <a href=" https://qr.arrivae.com/assets/gallery3.png"
                            data-fancybox="gallery3">Bedrooms</a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 1.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 2.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 3.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 4.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 5.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 6.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 7.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 8.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 9.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 10.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 11.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 12.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 13.jpg"
                            data-fancybox="gallery3"></a> 
                            <a href="../../../assets/images/Bed Rooms/Bed Room 14.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 15.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 16.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 17.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 18.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 19.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 20.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 21.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 22.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 23.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 24.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 25.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 26.jpg"
                            data-fancybox="gallery3"></a>
                            <a href="../../../assets/images/Bed Rooms/Bed Room 27.jpg"
                            data-fancybox="gallery3"></a>

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="gallery-item__wrapper">
                        <img loading="lazy" src=" https://qr.arrivae.com/assets/gallery4.png" alt="" />
                        <a href=" https://qr.arrivae.com/assets/gallery4.png"
                            data-fancybox="gallery4">Modular Kitchens</a>
                            <a href="../../../assets/images/Kitchen/Kitchen 1.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 2.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 3.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 4.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 5.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 6.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 7.jpg"
                            data-fancybox="gallery4"></a>

                            <a href="../../../assets/images/Kitchen/Kitchen 8.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 9.jpg"
                            data-fancybox="gallery4"></a>

                            <a href="../../../assets/images/Kitchen/Kitchen 10.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 11.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 12.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 13.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 14.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 15.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 16.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 17.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 18.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 19.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 20.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 21.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 22.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 23.jpg"
                            data-fancybox="gallery4"></a> 
                            <a href="../../../assets/images/Kitchen/Kitchen 24.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 25.jpg"
                            data-fancybox="gallery4"></a>
                            <a href="../../../assets/images/Kitchen/Kitchen 26.jpg"
                            data-fancybox="gallery4"></a>

                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="arrivae-advatage-item lazy"  style="background-image: url('../../assets/images/img/arrivae-advatage-bg.jpg')" >
        <img loading="lazy" src=" https://qr.arrivae.com/assets/arrivae-advatage-overlay.png"
            class="arrivae-advatage__overlay" alt="" />
        <div class="container">
            <div class="arrivae-advatage-wrapper">
                <div class="hearding-content">
                    <h2>The Arrivae Advantage </h2>
                    <p>So many reasons to choose Arrivae</p>
                </div>
                <div class="arrivae-advatage-slider logo-slider-wrapper">
                    <div class="arrivae-advatage-box">
                        <h2>01</h2>
                        <p><span>13,500+</span> Happy Spaces delivered across 15 cities & counting</p>
                    </div>
                    <div class="arrivae-advatage-box">
                        <h2>02</h2>
                        <p><span>1+ million</span> Exclusively Curated designs in latest design themes</p>
                    </div>
                    <div class="arrivae-advatage-box">
                        <h2>03</h2>
                        <p><span>End-to-End</span> hassle free execution of your interiors project</p>
                    </div>
                    <div class="arrivae-advatage-box">
                        <h2>04</h2>
                        <p><span>10* Year</span> Bhaari Warranty covered . T&Cs apply</p>
                    </div>
                    <div class="arrivae-advatage-box">
                        <h2>05</h2>
                        <p><span>0%</span> Interest EMI schemes. Quick & hassle-free approvals</p>
                    </div>
                    <div class="arrivae-advatage-box">
                        <h2>06</h2>
                        <p><span>CCTV </span> On-Site Project monitoring on your fingertips </p>
                    </div>
                    <div class="arrivae-advatage-box">
                        <h2>07</h2>
                        <p><span>Live</span> Digital Dashboards & alerts on project status via email, Whatsapp</p>
                    </div>
                    <div class="arrivae-advatage-box">
                        <h2>08</h2>
                        <p><span>Robust</span> After-sales & service support. Periodic home visits.</p>
                    </div>
                    <div class="arrivae-advatage-box">
                        <h2>09</h2>
                        <p><span>Expert</span> on-ground project management & execution teams</p>
                    </div>

                    <div class="arrivae-advatage-box">
                        <h2>10</h2>
                        <p><span>3D Designs</span> Near real-life visualization of your designs in 3D and VR</p>
                    </div>

                    <div class="arrivae-advatage-box">
                        <h2>11</h2>
                        <p><span>Premium</span> Quality materials, hardware, fixtures for that factory finish output</p>
                    </div>


                    <div class="arrivae-advatage-box">
                        <h2>12</h2>
                        <p><span>Transparent</span> Pricing. No-hidden costs and surpise price escalations</p>
                    </div>


                </div>
            </div>
        </div>
    </section>

    <section class="approach-block">
        <div class="container">
            <div class="hearding-content text-center">
                <h2>Our Approach</h2>
                <p>Delivering Your Dream Home Interiors</p>
            </div>
            <div class="approach-listing">
                <div class="approach-listing-item">
                    <div class="approach-listing-item-top">
                        <div class="approach-listing-icon">
                            <img loading="lazy" src=" https://qr.arrivae.com/assets/approach-listing-icon-1.png"
                                alt="" />
                        </div>
                        <div class="approach-number"><span>01</span></div>
                    </div>
                    <div class="approach-listing-item-bottom">
                        <h3>Meet our Expert designer</h3>
                        <p>Our designers engage with you to understand your requirements, inspirations & ideas about
                            your interiors project.</p>
                    </div>
                </div>
                <div class="approach-listing-item">
                    <div class="approach-listing-item-top">
                        <div class="approach-listing-icon">
                            <img loading="lazy" src=" https://qr.arrivae.com/assets/approach-listing-icon-2.png"
                                alt="" />
                        </div>
                        <div class="approach-number"><span>02</span></div>
                    </div>
                    <div class="approach-listing-item-bottom">
                        <h3>Review designs & quote</h3>
                        <p>Visualize your designs & interiors with 3D renders & VR. Review the material selection,
                            timelines & quotes.</p>
                    </div>
                </div>
                <div class="approach-listing-item">
                    <div class="approach-listing-item-top">
                        <div class="approach-listing-icon">
                            <img loading="lazy" src=" https://qr.arrivae.com/assets/approach-listing-icon-3.png"
                                alt="" />
                        </div>
                        <div class="approach-number"><span>03</span></div>
                    </div>
                    <div class="approach-listing-item-bottom">
                        <h3>Place The Order</h3>
                        <p>Once the designs & quotes are approved, place your order with 10% token amount</p>
                    </div>
                </div>
                <div class="approach-listing-item">
                    <div class="approach-listing-item-top">
                        <div class="approach-listing-icon">
                            <img loading="lazy" src=" https://qr.arrivae.com/assets/approach-listing-icon-4.png"
                                alt="" />
                        </div>
                        <div class="approach-number"><span>04</span></div>
                    </div>
                    <div class="approach-listing-item-bottom">
                        <h3>Production</h3>
                        <p>Once the designs & sites validated with next payment milestone, we begin work on your
                            interior project. This includes on-site & factory elements.</p>
                    </div>
                </div>
                <div class="approach-listing-item">
                    <div class="approach-listing-item-top">
                        <div class="approach-listing-icon">
                            <img loading="lazy" src=" https://qr.arrivae.com/assets/approach-listing-icon-5.png"
                                alt="" />
                        </div>
                        <div class="approach-number"><span>05</span></div>
                    </div>
                    <div class="approach-listing-item-bottom">
                        <h3>Move-in</h3>
                        <p>We install, curate all the interior elements & services to handover within designated
                            timelines. You’re ready to move-in to your dream home.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="arrivae-advatage-item lazy based-interiors-item" style="background-image: url('../../assets/images/img/arrivae-advatage-bg.jpg')"  >
        <div class="container">
            <div class="arrivae-advatage-wrapper">
                <div class="hearding-content text-center">
                    <h2>“Theme based interiors” exclusive to Arrivae</h2>
                    <p>Visit one of our design centres to experience Never seen before themes</p>
                </div>

                <div class="w3-content w3-section mx-auto" style="max-width:500px">

                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Dream%20Ballet%201.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Dream%20Ballet%2010.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Dream%20Ballet%2011.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Dream%20Ballet%2012.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Dream%20Ballet%202.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Dream%20Ballet%203.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Dream%20Ballet%204.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Dream%20Ballet%205.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Dream%20Ballet%206.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Dream%20Ballet%207.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Dream%20Ballet%208.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Dream%20Ballet%209.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Golden%20Murano%201.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Golden%20Murano%202.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Golden%20Murano%203.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Golden%20Murano%204.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Golden%20Murano%205.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Golden%20Murano%206.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Modern%20Chic%201.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Modern%20Chic%202.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Modern%20Chic%203.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Modern%20Chic%204.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Modica%201.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Modica%202.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Modica%203.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Modica%204.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Morandi%201.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Morandi%202.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Morandi%203.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Morandi%204.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Urban%20Elegance%201.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Urban%20Elegance%202.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Urban%20Elegance%203.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Urban%20Elegance%204.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Urban%20Elegance%205.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Urban%20Elegance%206.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Urban%20Elegance%207.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Urban%20Elegance%208.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Wooden%20Fun%20Kids%201.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Wooden%20Fun%20Kids%202.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Wooden%20Fun%20Kids%203.jpg" style="width:100%" />
                    <img loading="lazy" class="mySlides w3-animate-fading" src=" https://qr.arrivae.com/assets/Wooden%20Fun%20Kids%204.jpg" style="width:100%" />
                    
                    </div>
                
            </div>
        </div>


    </section>


    <section class="customer-speak-item">
        <div class="container">
            <div class="customer-speak-item__wrapper">
                <div class="hearding-content pb-4">
                    <h2>Customer Speak</h2>
                </div>
                <!--  -->
                <div class="customer-speak-slider">
                    <div class="customer-speak-slider-box" >
                       <img loading="lazy" class="w-100 h-100"  src="../../../assets/images/Testimonials/Bengaluru 1.jpg" >
                    </div>
                    <div class="customer-speak-slider-box" >
                        <img loading="lazy" class="w-100 h-100" src="../../../assets/images/Testimonials/Bengaluru 2.jpg" >
                     </div>
                     <div class="customer-speak-slider-box" >
                        <img loading="lazy" class="w-100 h-100" src="../../../assets/images/Testimonials/Bengaluru 3.jpg" >
                     </div> 
                      <div class="customer-speak-slider-box" >
                        <img loading="lazy" class="w-100 h-100" src="../../../assets/images/Testimonials/Gurugram 1.jpg" >
                     </div>
                     <div class="customer-speak-slider-box" >
                        <img loading="lazy" class="w-100 h-100" src="../../../assets/images/Testimonials/Gurugram 2.jpg" >
                     </div>
                     <div class="customer-speak-slider-box" >
                        <img loading="lazy" class="w-100 h-100" src="../../../assets/images/Testimonials/Gurugram 3.jpg">
                     </div>
                     <div class="customer-speak-slider-box" >
                        <img loading="lazy" class="w-100 h-100" src="../../../assets/images/Testimonials/Mumbai 1.jpg" >
                     </div>
                     <div class="customer-speak-slider-box" >
                        <img loading="lazy" class="w-100 h-100" src="../../../assets/images/Testimonials/Mumbai 2.jpg" >
                     </div>
                     <div class="customer-speak-slider-box" >
                        <img loading="lazy" class="w-100 h-100" src="../../../assets/images/Testimonials/Mumbai 3.jpg" >
                     </div>
                     <div class="customer-speak-slider-box" >
                        <img loading="lazy" class="w-100 h-100" src="../../../assets/images/Testimonials/Bengaluru 3.jpg">
                     </div>
                     <div class="customer-speak-slider-box" >
                        <img loading="lazy" class="w-100 h-100" src="../../../assets/images/Testimonials/Pune 1.jpg" >
                     </div>
                     <div class="customer-speak-slider-box" >
                        <img loading="lazy" class="w-100 h-100" src="../../../assets/images/Testimonials/Pune 2.jpg" >
                     </div>
                     <div class="customer-speak-slider-box" >
                        <img loading="lazy" class="w-100 h-100" src="../../../assets/images/Testimonials/Pune 3.jpg">
                     </div>

                   
                </div>
               
            </div>
        </div>
    </section>

    <section class="sit-back-relax">
        <div class="sit-back-relax__wrapper d-flex">
            <div class="sit-back-relax__image">
                <img loading="lazy" src=" https://qr.arrivae.com/assets/Sit-Back-%26-Relax.jpg" alt="" />
            </div>
            <div class="sit-back-relax__content">
                <h3>Sit Back & Relax b’coz Dream Home Interiors Made By You, Executed by Us </h3>
                <div class="absolute-imgs">
                    <img loading="lazy" src=" https://qr.arrivae.com/assets/sit-back-relax-2.png" alt="" />
                    <img loading="lazy" src=" https://qr.arrivae.com/assets/sit-back-relax-3.png" alt="" />
                </div>
            </div>
        </div>
    </section>

    <footer>
        <img loading="lazy" src=" https://qr.arrivae.com/assets/arrivak-bg.png" alt="" class="footer-img-overlay"  />
        <div class="container">
            <div class="footer-wrapper">
                <!--  -->

                <div class="footer-sub-item w100">
                    <div class="footer-logo">
                        <a href="#"><img loading="lazy" src=" https://qr.arrivae.com/assets/logo-footer.png" alt=""  /></a>
                    </div>
                    <p>Your Home Interiors Solutions Gateway </p>
                </div>

               
                <!--  -->
                <div class="footer-sub-right">
                    <h4>Recently Executed Projects </h4>
                    <ul>
                        <li>
                            <a  ><img loading="lazy" src="../../../assets/images/Recently_Executed_Projects/1.jpg" alt="" /></a>
                        </li>
                        <li>
                            <a  ><img loading="lazy" src="../../../assets/images/Recently_Executed_Projects/2.jpg" alt="" /></a>
                        </li>
                        <li>
                            <a   ><img loading="lazy" src="../../../assets/images/Recently_Executed_Projects/3.jpg" alt="" /></a>
                        </li>
                        <li>
                            <a  ><img loading="lazy" src="../../../assets/images/Recently_Executed_Projects/4.jpg" alt="" /></a>
                        </li>
                        <li>
                            <a ><img loading="lazy" src="../../../assets/images/Recently_Executed_Projects/5.jpg" alt="" /></a>
                        </li>
                        
                        <li>
                            <a ><img loading="lazy" src="../../../assets/images/Recently_Executed_Projects/6.jpg" alt="" /></a>
                        </li>
                        
                        
                        
                        <li>
                            <a  ><img loading="lazy" src="../../../assets/images/Recently_Executed_Projects/7.jpg" alt="" /></a>
                        </li>
                        
                        
                        
                        <li>
                            <a  ><img loading="lazy" src="../../../assets/images/Recently_Executed_Projects/8.jpg" alt="" /></a>
                        </li>
                        
                        
                        
                        <li>
                            <a  ><img loading="lazy" src="../../../assets/images/Recently_Executed_Projects/9.jpg" alt="" /></a>
                        </li>
                        
                        
                        
                        <li>
                            <a  ><img loading="lazy" src="../../../assets/images/Recently_Executed_Projects/10.jpg" alt=""  /></a>
                        </li>
                        <li>
                            <a  ><img loading="lazy" src="../../../assets/images/Recently_Executed_Projects/11.jpg" alt=""  /></a>
                        </li>
                        <li>
                            <a  ><img loading="lazy" src="../../../assets/images/Recently_Executed_Projects/12.jpg" alt=""  /></a>
                        </li>
                        <li>
                            <a  ><img loading="lazy" src="../../../assets/images/Recently_Executed_Projects/12.jpg" alt=""  /></a>
                        </li>
                        <li>
                            <a  ><img loading="lazy" src="../../../assets/images/Recently_Executed_Projects/14.jpg" alt=""  /></a>
                        </li>
                        <li>
                            <a  ><img loading="lazy" src="../../../assets/images/Recently_Executed_Projects/15.jpg" alt=""  /></a>
                        </li>
                        <li>
                            <a  ><img loading="lazy" src="../../../assets/images/Recently_Executed_Projects/16.jpg" alt=""  /></a>
                        </li> <li>
                            <a  ><img loading="lazy" src="../../../assets/images/Recently_Executed_Projects/17.jpg" alt=""  /></a>
                        </li> <li>
                            <a  ><img loading="lazy" src="../../../assets/images/Recently_Executed_Projects/18.jpg" alt=""  /></a>
                        </li> <li>
                            <a  ><img loading="lazy" src="../../../assets/images/Recently_Executed_Projects/19.jpg" alt=""  /></a>
                        </li>

                    </ul>
                </div>
                <!--  -->

            </div>

            <div class="footer-titel ">
                <p>Copyright @ 2022 | All rights reserved</p>
                <ul class="d-flex">
                    <li><a href="https://www.instagram.com/arrivae/?hl=en" target="_blank"><i class="fa-brands fa-instagram"></i></a></li>
                    <li><a href="https://in.linkedin.com/company/arrivae" target="_blank"><i class="fa-brands fa-linkedin-in"></i></a></li>
                    <li><a href="https://www.facebook.com/Arrivae/" target="_blank"><i class="fa-brands fa-facebook-f"></i></a></li>
                    <li><a href="https://twitter.com/ModSpaceIN" target="_blank" ><i class="fa-brands fa-twitter"></i></a></li>
                </ul>
            </div>
        </div>
    </footer>


    <!-- Get Free Quote Modal -->
   
   



</div>

<div class="modal fade" id="Edit" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal__get-free-quote" role="document">
        <div class="modal-content center">
            <div class="modal-body">
                <button class="cross__button" data-dismiss="modal"><img loading="lazy"
                        src=" https://qr.arrivae.com/assets/cross__button.svg"
                        alt="" /></button>
                <h3 class="users_details__titel">We’ll like to know more about your interior requirements to get you
                    best quotes Select the appropriately</h3>

                    <form [formGroup]= "UpadateLead">

                        <div class="modal-form-con">

                            <div class="modal-form-con-row">
    
    
    
                                <div class="modal-form-con-col-left">Property Configuration <span>(choose any)</span></div>
                                <div class="modal-form-con-col-right">
                                    <ul>
                                        <li>
                                            <input type="radio" value="1BHK" id="1bhk" formControlName="Property">
                                            <label for="1bhk">1 BHK</label>
    
                                            <div class="check"></div>
                                        </li>
    
                                        <li>
                                            <input type="radio" value="2BHK" id="2bhk" formControlName="Property">
                                            <label for="2bhk">2 BHK</label>
    
                                            <div class="check">
                                                <div class="inside"></div>
                                            </div>
                                        </li>
    
                                        <li>
                                            <input type="radio" value="3BHK" id="3bhk" formControlName="Property">
                                            <label for="3bhk">3 BHK</label>
    
                                            <div class="check">
                                                <div class="inside"></div>
                                            </div>
                                        </li>
                                        <li>
                                            <input type="radio" value="4BHK" id="4bhk" formControlName="Property">
                                            <label for="4bhk">4 BHK</label>
    
                                            <div class="check">
                                                <div class="inside"></div>
                                            </div>
                                        </li>
                                        <li>
                                            <input type="radio" value="Villa" id="independent" formControlName="Property">
                                            <label for="independent">Independent/villa</label>
    
                                            <div class="check">
                                                <div class="inside"></div>
                                            </div>
                                        </li>
                                        <li>
                                            <input type="radio" value="Studio Apartment" id="others" formControlName="Property">
                                            <label for="others">Others</label>
    
                                            <div class="check">
                                                <div class="inside"></div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="modal-form-con-row">
                                <div class="modal-form-con-col-left">Possession Status <span>(choose any)</span></div>
                                <div class="row w-75">

                                    <div class="modal-form-con-col-right col-md-6">
                                        <ul class="ml-1">
                                            <li>
                                                <input type="radio" value="old" id="Taken" formControlName="Possession">
                                                <label for="Taken">Taken</label>
        
                                                <div class="check"></div>
                                            </li>
        
                                            <li>
                                                <input type="radio" value="new" id="Not-Taken" formControlName="Possession">
                                                <label for="Not-Taken">Not Taken</label>
        
                                                <div class="check">
                                                    <div class="inside"></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="col-md-6">
                                        <select class="" *ngIf="UpadateLead.controls['Possession'].value == 'new'"
                                    class="form-control"
                                    style="cursor: pointer"
                                    formControlName="possessionProperty_date"
                                  >
                                    <option selected value="" disabled>Possession Month</option>
                                    <option
                                      *ngFor="let possession of possession_date; let i = index"
                                      [value]="possession"
                                    >
                                      {{ possession }}
                                    </option>
                                  </select>

                                    </div>
    
                                    
                                </div>

                                </div>
                                
    
                            <div class="modal-form-con-row">
                                <div class="modal-form-con-col-left">Are you currently Staying in your Property
                                    <span>(choose any)</span></div>
                                <div class="modal-form-con-col-right">
                                    <ul>
                                        <li>
                                            <input type="radio" value="Own Stay" id="Yes" formControlName="Staying">
                                            <label for="Yes"> 
                                                Yes</label>
    
                                            <div class="check"></div>
                                        </li>
    
                                        <li>
                                            <input type="radio" id="No" value="Not Disclosed" formControlName="Staying">
                                            <label for="No">No</label>
    
                                            <div class="check">
                                                <div class="inside"></div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
    
                            <div class="modal-form-con-row">
                                <div class="modal-form-con-col-left">Do you have the floor plan <span>(choose any)</span>
                                </div>
                                <div class="row w-75">

                                    <div class="modal-form-con-col-right col-md-6">
                                        <ul class="ml-1">
                                            <li>
                                                <input type="radio" value="Yes" id="Yes2" formControlName="plan">
                                                <label for="Yes2">Yes </label>
        
                                                <div class="check"></div>
                                            </li>
        
                                            <li>
                                                <input type="radio" id="No2" value="No" formControlName="plan">
                                                <label for="No2">No</label>
        
                                                <div class="check">
                                                    <div class="inside"></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
    
    
                                    <div class="col-md-6" *ngIf="UpadateLead.controls['plan'].value == 'Yes'" >
                    <div>
                      <!-- <p class="uploadBtn"> Upload</p> -->
                      <input
                        id="uploadFile"
                        type="file"
                        class="d-block w-100 uploadBtn"
                        
                        name="profile"
                        (change)="onFileSelect($event)"
                      />
                    </div>
                  </div>

                                </div>
                    
                            </div>

                            <div class="modal-form-con-row">
                                <div class="modal-form-con-col-left">I want interiors for <span>(choose any)</span></div>
                                <div class="modal-form-con-col-right">
                                    <ul>
                                        <li>
                                            <input type="radio" value="Full Home Interiors" id="love" formControlName="typeOFHome">
                                            <label for="love">Complete Home</label>
    
                                            <div class="check"></div>
                                        </li>
    
                                        <li>
                                            <input type="radio" value="Partial Home Interiors" id="love2" formControlName="typeOFHome">
                                            <label for="love2"> 1 or more rooms</label>
    
                                            <div class="check">
                                                <div class="inside"></div>
                                            </div>
                                        </li>
                                        <li>
                                            <input type="radio" value="Modular Kitchen" id="love3" formControlName="typeOFHome">
                                            <label for="love3">Modular Kitchen</label>
    
                                            <div class="check">
                                                <div class="inside"></div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            
    
    
    
    
    
                        </div>

                    </form>

                    

               



                <div class="button-div">
                    <button (click)="Submitdetailsupdate()" class="idone">I’m done</button>
                </div>



            </div>



        </div>
    </div>
</div>

<div class="loading d-flex justify-content-center" *ngIf="loader">
    <div class="spinner-border" style="color: #8d0528;" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>