import { Component,ElementRef, OnInit ,ViewChild} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { ApartmentService } from 'src/app/apartment/apartment.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-quote-form',
  templateUrl: './quote-form.component.html',
  styleUrls: ['./quote-form.component.scss'],
})
export class QuoteFormComponent implements OnInit {
  projectName2: any;
  DeveloperName2: string | undefined;
  FullAdress2: string | undefined;
  citynamePro2: string | undefined;
  pincodePro2: string | undefined;
  othersShow: boolean | undefined;
  Typeselect: string | undefined;

  // utm_campaign:any = '';
  // utm_content : any = '';
  // utm_medium :any = '';
  // utm_term :any = '';
  // utm_source	:any = '';
  // gclid :any = '';
  // fbclid:any = '';

  utm_source:any = '';
  utm_campaign:any = '';
  utm_content:any = '';
  fbclid:any = '';
  counter=0

  @ViewChild('videoPlayer') videoPlayer!: ElementRef;

  @ViewChild('videoPlayer2') videoPlayer2!: ElementRef;



  ngOnInit(): void {
    this.initializeYears();
    this.updateMonths();
    this.updateMonthsTwo();
  }

  ngAfterViewInit(): void {
    const videoElement = this.videoPlayer.nativeElement;
    videoElement.muted = true; // Ensure the video is muted for autoplay
    videoElement.play().catch((error:any) => {
      console.log('Autoplay failed:', error);
    });
    const videoElement2 = this.videoPlayer2.nativeElement;
    videoElement2.muted = true; // Ensure the video is muted for autoplay
    videoElement2.play().catch((error:any) => {
      console.log('Autoplay failed:', error);
    });
    


  }

  GetQuoteForm: FormGroup;
  selectedform = 1;

  allMonths: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  monthsOne: string[] = [];
  monthsTwo: string[] = [];
  years: number[] = [];

  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth();



  styleOptions = [
    {
      id: 1,
      name: 'Traditional',
      imageUrl: 'assets/Longform/images/traditional.webp',
    },
    { id: 2, name: 'Japandi', imageUrl: 'assets/Longform/images/japandi.webp' },
    {
      id: 3,
      name: 'Contemporary',
      imageUrl: 'assets/Longform/images/contemporary.webp',
    },
    {
      id: 4,
      name: 'Neoclassical',
      imageUrl: 'assets/Longform/images/neoclassical.webp',
    },
    { id: 5, name: 'Glam', imageUrl: 'assets/Longform/images/glam.webp' },
    { id: 6, name: 'Coastal', imageUrl: 'assets/Longform/images/coastal.webp' },
  ];

  selectedStyle: number | null = null;

  bhkOptions: string[] = ['1BHK', '2BHK', '3BHK', '4BHK', '5BHK+'];
  showLoader:any = false;


  selectStyle(optionId: number) {
    this.selectedStyle = optionId;
  }

  constructor(
    private fb: FormBuilder,
    private apartmentservice: ApartmentService,
    private toastr: ToastrService,
    private activatedRoute:ActivatedRoute,
  ) {

    this.activatedRoute.queryParamMap.subscribe((params: any) => {
      console.log(params)
      this.utm_source =  params.params.utm_source;
      this.utm_campaign =  params.params.utm_campaign;
      this.utm_content =  params.params.utm_content;
      this.fbclid =  params.params.fbclid;
      // this.utm_campaign = params.params.utm_campaign
      // this.utm_content = params.params.utm_content
      // this.utm_medium = params.params.utm_medium
      // this.utm_term = params.params.utm_term
      // this.utm_source = params.params.utm_source
      // this.gclid = params.params.gclid
      // this.fbclid = params.params.fbclid
    });

    this.GetQuoteForm = this.fb.group({
      fullName: ['', Validators.required],
      mobileNumber: [ '', [Validators.required, Validators.pattern('^[0-9]{10}$')],],
      email: ['', [Validators.required, Validators.email]],
      // whatsappUpdates: [false],

      propertyType: ['apartment'],
      society: ['', Validators.required],
      property_name: ['', Validators.required],
      Locality: [''],
      city: [''],
      pincode: ['',Validators.required],
      
      budget: [''],

      bhk: [''],

      possession:[false],

      possessionProperty_date: [''],
      startProject_date: [''],

      interior: [''],
      style: [''],

      monthReceived: [''],
      yearReceived: [''],
      monthStart: [''],
      yearStart: [''],
    });
  }

  initializeYears() {
    this.years = Array.from({ length: 5 }, (_, i) => this.currentYear + i);
    if (this.currentMonth === 0) {
      this.years.unshift(this.currentYear - 1);
    }
  }

  updateMonths() {
    const selectedYear = this.GetQuoteForm.get('yearReceived')?.value;
    this.GetQuoteForm.controls['monthReceived'].setValue('');
    if (selectedYear == this.currentYear) {
      this.monthsOne = this.allMonths.slice(this.currentMonth - 1);
    } else if (selectedYear == this.currentYear - 1 && this.currentMonth == 0) {
      this.monthsOne = ['December'];
    } else {
      // Otherwise, show all months
      this.monthsOne = [...this.allMonths];
    }
    this.posessiondate();
  }

  updateMonthsTwo() {
    const selectedYear = this.GetQuoteForm.get('yearStart')?.value;
    this.GetQuoteForm.controls['monthStart'].setValue('');
    if (selectedYear == this.currentYear) {
      this.monthsTwo = this.allMonths.slice(this.currentMonth - 1);
    } else if (selectedYear == this.currentYear - 1 && this.currentMonth == 0) {
      this.monthsTwo = ['December'];
    } else {
      // Otherwise, show all months
      this.monthsTwo = [...this.allMonths];
    }
    this.startdate();
  }

  togglePropertyType(): void {
    this.GetQuoteForm.controls['city'].setValue('');
    this.GetQuoteForm.controls['society'].setValue('');
    this.GetQuoteForm.controls['property_name'].setValue('');
    this.GetQuoteForm.controls['pincode'].setValue('');
  }

  AidApartmentID: any = '';

  selectBhk(bhk: string) {
    this.GetQuoteForm.controls['bhk'].setValue(bhk); // Update the form control value
  }
  selectinterior(interior: string, event :any) {
    event.preventDefault()
    this.GetQuoteForm.controls['interior'].setValue(interior);
  }

  posessiondate(){
    console.log("ijiji")
    this.GetQuoteForm.controls['startProject_date'].setValue(this.GetQuoteForm.value.monthReceived+","+this.GetQuoteForm.value.yearReceived) 
   }

  startdate(){
    console.log("ijiji")
    this.GetQuoteForm.controls['startProject_date'].setValue(this.GetQuoteForm.value.monthStart+","+this.GetQuoteForm.value.yearStart) 
   }

   possessiOnDateYear() {
    this.updateMonths();
  }

  DetailsSubmit() {
    this.showLoader = true;
    const formattedData: any = {
      leads: [
        {
          Attribute: 'EmailAddress',
          Value: this.GetQuoteForm.value.email ?? '',
        },
        {
          Attribute: 'FirstName',
          Value: this.GetQuoteForm.value.fullName ?? '',
        },
        {
          Attribute: 'mx_Lead_Name',
          Value: this.GetQuoteForm.value.fullName ?? '',
        },
        {
          Attribute: 'Phone',
          Value: this.GetQuoteForm.value.mobileNumber ?? '',
        },

        {
          Attribute: 'mx_Location',
          Value: this.GetQuoteForm.value.property_name ?? '',
        },
        {
          Attribute: 'mx_Society_Name',
          Value: this.GetQuoteForm.value.society ?? '',
        },
        { Attribute: 'mx_City', Value: this.GetQuoteForm.value.city ?? '' },
        { Attribute: 'mx_Zip', Value: this.GetQuoteForm.value.pincode ?? '' },

        {
          Attribute: 'mx1_Budget_Value',
          Value: this.GetQuoteForm.value.budget ?? '',
        }, // it needs to be an number

        {
          Attribute: 'mx_Type_of_Accommodation',
          Value: this.GetQuoteForm.value.bhk ?? '',
        },

        {
          Attribute: 'mx_Scope_of_Work',
          Value: this.GetQuoteForm.value.interior ?? '',
        },

        {
          Attribute: 'mx_When_do_you_get_the_possession_of_the_property',
          Value: this.GetQuoteForm.value.possession
            ? 'Possession Taken'
            : 'Awaiting Possession',
        },
        {
          Attribute: 'mx_When_do_you_want_to_start_the_project',
          Value: this.GetQuoteForm.value.startProject_date ?? '',
        },

        { Attribute: 'Source', Value: 'website' },
        // { Attribute: 'SourceCampaign', Value: 'Homepage' },

        { Attribute: 'mx_Adset_Name', Value: this.utm_campaign ? this.utm_campaign : '' },
        { Attribute: 'SourceCampaign', Value: this.utm_source ? this.utm_source : '' },
        { Attribute: 'mx_Ad_Name', Value: this.utm_content ? this.utm_content : '' },
        // { Attribute: 'FacebookId', Value: this.fbclid ? this.fbclid : '' },
        
        

        { Attribute: 'mx_Scope_of_Work', Value: '' },
        { Attribute: 'mx_Is_MarketingPage', Value: true },
        { Attribute: 'mx_Apartment_ID', Value: this.AidApartmentID ? this.AidApartmentID : '' },
      ],
    };

    // { Attribute: 'mx_style', Value: this.GetQuoteForm.value.style ?? '' },


    this.apartmentservice.createBookConsultation(formattedData).subscribe(
      (res) => {
        this.showLoader = false;
        this.toastr.success(
          'Thanks for sharing your preferences. Our designers will get in touch with you in 24 hours'
        );

        this.startover()
      },
      (err) => {
        this.showLoader = false;
        this.toastr.error(err.error.message);
      }
    );
  }


  triggerConsultationEvent2() {
    const fbq = (window as any).fbq;  // Cast window as any to access fbq
    if (typeof fbq === 'function') {
      fbq('track', 'Lead');  // Call the fbq method
      console.log('Success: Lead event triggered.');
    }
  }

  possesionchanges(event: Event) {
    event.preventDefault(); 
    this.GetQuoteForm.controls['possession'].setValue(!this.GetQuoteForm.controls['possession'].value)
  }

  switchform(id: any) {
    this.selectedform = id;
  }
  moveforward(e:any) {
    this.counter=this.counter+1
    e.preventDefault()
    if (this.GetQuoteForm.invalid) {
      this.GetQuoteForm.markAllAsTouched();
      return; 
    }

    if(this.selectedform == 1 && this.counter == 1 && this.fbclid){
      this.triggerConsultationEvent2();
    }
    this.selectedform = this.selectedform + 1;
  }

  movebackward() {
    this.selectedform = this.selectedform - 1;
  }
  startover() {
    this.selectedform = 1;
    this.GetQuoteForm.reset()
    this.GetQuoteForm.controls['propertyType'].setValue('apartment');
  }

  isApifired: any = false;

  hideOptions() {
    this.isApifired = false;
  }
  showOptions() {
    this.isApifired = true;
  }

  filteredOptions: any;
  isSelected: any;

  searchSocietyApi($event: any) {
    this.othersShow = true;
    this.isApifired = true;
    this.Typeselect = '';
    this.isSelected = false;
    let searchWord = $event.target.value;
    this.apartmentservice.getSocietyWebapi(searchWord).subscribe(
      (res: any) => {
        this.filteredOptions = res.result;
        console.log(this.filteredOptions);
      },
      (err) => {
        this.filteredOptions = [];
      }
    );
  }

  selectOption(address: any) {
    this.filteredOptions = [];
    this.isSelected = true;
    if (address == 'other') {
      $('#OtherprojectName2').modal('show');
      this.projectName2 = this.GetQuoteForm.controls['property_name'].value;
      console.log(
        this.projectName2,
        this.GetQuoteForm.controls['property_name'].value,
        'test'
      );
      this.DeveloperName2 = '';
      this.FullAdress2 = '';
      this.citynamePro2 = '';
      this.pincodePro2 = '';
      this.AidApartmentID = '';
    } else {
      let location = address.apartment_name + ',' + address.area_name;
      this.GetQuoteForm.controls['city'].setValue(address.city);
      this.GetQuoteForm.controls['pincode'].setValue(address.pincode);
      this.GetQuoteForm.controls['society'].setValue(address.apartment_name);
      this.GetQuoteForm.controls['property_name'].setValue(location);

      this.projectName2 = this.GetQuoteForm.controls['property_name'].value;

      this.AidApartmentID = address.id;
    }

    console.log(this.GetQuoteForm.value);
  }

  onPropertyNameChange(): void {
    const propertyName = this.GetQuoteForm.get('property_name')?.value;
    this.GetQuoteForm.controls['city'].setValue('');
    this.GetQuoteForm.controls['society'].setValue(propertyName);
    this.GetQuoteForm.controls['property_name'].setValue(propertyName);
    this.projectName2 = this.GetQuoteForm.controls['property_name'].value;
  }

  areaname: any;
  Submitapart() {
    $('#OtherprojectName2').modal('hide');
    let area = this.areaname ? this.areaname : this.FullAdress2;
    let location = this.projectName2 + ',' + area;

    this.GetQuoteForm.controls['city'].setValue(this.citynamePro2);
    this.GetQuoteForm.controls['pincode'].setValue(this.pincodePro2);

    this.GetQuoteForm.controls['property_name'].setValue(this.projectName2);
    console.log(this.projectName2, this.GetQuoteForm.value);
    this.GetQuoteForm.controls['apartment'].setValue(location);
    console.log(location, 'location');
    this.CreateApartment();
  }
  CreateApartment() {
    let address = this.GetQuoteForm.controls['apartment'].value;
    const pincodeRegex = /\b\d{6}\b/;

    // Use the match method to find the first match of the regex pattern in the string
    const pincodeMatch = address.match(pincodeRegex);

    // Check if a match is found
    if (pincodeMatch) {
      // Extracted PIN code
      var pincode = pincodeMatch[0];
    } else {
      pincode = '';
    }

    let obj = {
      rera_number: '',
      project_name: this.projectName2,
      full_address: this.FullAdress2,
      project_developer: this.DeveloperName2,
      pincode: this.GetQuoteForm.controls['pincode'].value,
      area_name: this.areaname,
      city: this.GetQuoteForm.controls['city'].value,
      state: '',
    };
    this.apartmentservice.CreateApar(obj).subscribe(
      (Res: any) => {
        this.toastr.success('Apartment Created Successfully');
        this.AidApartmentID = Res.result[0].apartment_id;
      },
      (err) => {
        this.toastr.error(JSON.parse(err['_body']).message);
      }
    );
  }

  formattedaddress = ' ';
  PropertyName: any;
  Location: any;

  handleAddressChange(address: any) {
    this.PropertyName = address.name;
    this.FullAdress2 = this.PropertyName;
    this.formattedaddress = address.name;

    this.formattedaddress = address.name + ',' + address.formatted_address;
    var str = this.formattedaddress;
    var arr = [];
    arr = str.split(',');
    var unique = [];
    for (i = 0; i < arr.length; i++) {
      if (
        i == arr.indexOf(arr[i]) ||
        arr.indexOf(arr[i]) == arr.lastIndexOf(arr[i])
      )
        unique.push(arr[i]);
    }
    this.formattedaddress = unique.join(',');

    console.log(this.formattedaddress);
    console.log(address);
    for (var i = 0; i < address.address_components.length; i++) {
      for (var j = 0; j < address.address_components[i].types.length; j++) {
        if (address.address_components[i].types[j] == 'postal_code') {
          console.log(address.address_components[i].long_name);

          this.pincodePro2 = address.address_components[i].long_name;
        } else {
          this.pincodePro2 = '';
        }

        this.FullAdress2 = this.formattedaddress;
        var areaName = address.address_components.filter((component: any) =>
          component.types.includes('sublocality')
        );
        const concatenatedNames = areaName
          .map((component: any) => component.long_name)
          .join(', ');
        areaName = concatenatedNames;
        this.areaname = areaName ? areaName : this.formattedaddress;
        if (address.address_components[i].types[j] == 'locality') {
          this.GetQuoteForm.controls['city'].setValue(
            address.address_components[i].long_name
          );
          this.citynamePro2 = address.address_components[i].long_name;
          console.log(this.GetQuoteForm.controls['city'].value);
          console.log(address.address_components[i].long_name);
        }
      }
    }
  }
}