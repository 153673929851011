import { Component, OnInit } from '@angular/core';
import { LandingService } from '../landing.service';
import { DomSanitizer } from '@angular/platform-browser';
import { EmbedVideoService } from 'ngx-embed-video';
@Component({
  selector: 'app-latestdesign',
  templateUrl: './latestdesign.component.html',
  styleUrls: ['./latestdesign.component.scss']
})
export class LatestdesignComponent implements OnInit {
  response: any;
  safeURL: any;
  videoURL1: any = 'https://www.youtube.com/watch?v=21KjRjtrbJM';
  videoURL2: any = ' https://www.youtube.com/watch?v=IKgtsi78Pz0';
  yt_iframe_html: any;
  vimeo_iframe_html: any;

  constructor(public landingService: LandingService, private embedService: EmbedVideoService) {
    this.yt_iframe_html = this.embedService.embed(this.videoURL1);
    this.vimeo_iframe_html = this.embedService.embed(this.videoURL2);
   }

  ngOnInit(): void {
    this.getLatestImages();
    this.loadScript()
  }

  getLatestImages() {
    this.landingService.latestDesignSpaces().subscribe((data) => {
      this.response = data;
    })
  }
  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  carouserHandler(data:any) {
    if (data === 'next') {
      (<HTMLInputElement>document.getElementById('LDS1CarouselNext')).click();
      (<HTMLInputElement>document.getElementById('LDS2CarouselNext')).click();
    } else {
      (<HTMLInputElement>document.getElementById('LDS1CarouselPrev')).click();
      (<HTMLInputElement>document.getElementById('LDS2CarouselPrev')).click();
    }
  }

}
