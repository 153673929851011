<div class="container-fluid py-5 perfect-fit-cat Adhiarj px-0 ">
    <h3 class="pb-3 sivajiFont ">
        Catalogue
    </h3>
    <p  id="sivajipan"  class="m-auto paraSCat sivajipan pb-4">
        Looking for a Space That Feels Perfectly 'You'? Flip Through This Page Turner to Find Inspiration for Your Dream Home
    </p>
    <div class="col-md-8 Mobile_div wrarpper_bhk m-auto">
        <div class="row w-100">
            <div class="col-12 margin-mobile mt-4">
                <div class="row">
                     <div class="col-4 format_type">
                        <a   (click)="successmessage('https://arrivae-production.s3.amazonaws.com/Asta_Nesta.pdf')">  Asta & Nesta  </a>
                     </div>
                     <div class="col-4 format_type  ">
                        <a    (click)="successmessage('https://arrivae-production.s3.amazonaws.com/Luxe.pdf')">Luxe </a> 
                     </div>
                     <div class="col-4 lastFormat ">
                        <a    (click)="successmessage('https://arrivae-production.s3.amazonaws.com/Solis.pdf')">Solis</a>  
                      </div>
                </div>
            </div>
        </div>              
    </div>
</div>