<div class="wrapper">
    <div class="heading">
        <h1>Customer testimonials</h1>
        <!-- <a href="#">See all</a> -->
    </div>

    <div class="cards-carousel-container">
        <div class="carousel slide" id="carousel-project-gallery" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#carousel-project-gallery" data-slide-to="0" class="active"></li>
                <li data-target="#carousel-project-gallery" data-slide-to="1"></li>
            </ol>
            
            <div class="carousel-inner cards-container">
                <div class="carousel-item active">
                    <div class="row-custom">
                        <div *ngFor="let customerTestimonial of customerTestimonials" class="card">
                            <div class="card-body">
                                <p class="card-text">{{customerTestimonial.content}}</p>
                                </div>
                                <div class="card-footer">
                                    <img width="image-fluid" src="{{customerTestimonial.imgUrl}}" alt="user">
                                    <p>{{customerTestimonial.name}}</p>
                                </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="row-custom">
                        <div class="card">
                            <div class="card-body">
                                <p class="card-text">We feel blessed that we choose Arrivae whose expert designer make our dream come true with our newly bought 2 BHK with his out of box design suggestions.</p>
                            </div>
                            <div class="card-footer">
                                <img width="image-fluid" src="../../../assets/images/user 6.png" alt="user">
                                <p>Jenny Wilson</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>

    <!-- <div class="cards-container">
        <div *ngFor="let customerTestimonial of customerTestimonials" class="col-3 col-card">
                            <div class="card">
                                <div class="card-body">
                                  <p class="card-text">{{customerTestimonial.content}}</p>
                                </div>
                                <div class="card-footer">
                                    <img width="image-fluid" src="{{customerTestimonial.imgUrl}}" alt="user">
                                    <p>{{customerTestimonial.name}}</p>
                                </div>
                            </div>
                        </div>
        <div *ngFor="let customerTestimonial of customerTestimonials" class="card">
            <div class="card-body">
              <p class="card-text">{{customerTestimonial.content}}</p>
            </div>
            <div class="card-footer">
                <img width="image-fluid" src="{{customerTestimonial.imgUrl}}" alt="user">
                <p>{{customerTestimonial.name}}</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
              <p class="card-text">We feel blessed that we choose Arrivae whose expert designer make our dream come true with our newly bought 2 BHK with his out of box design suggestions.</p>
            </div>
            <div class="card-footer">
                <img width="image-fluid" src="../../../assets/images/user 6.png" alt="user">
                <p>Jenny Wilson</p>
            </div>
        </div>
        <div class="card">
            <div class="card-body">
              <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, sollicitudin nunc, sit at ac nulla diam. Urna, non risus pellentesque varius. Faucibus mattis non integer augue eu id vitae. Id feugiat ac in turpis tellus lacus scelerisque id. </p>
            </div>
            <div class="card-footer">
                <img width="image-fluid" src="../../../assets/images/user 6.png" alt="user">
                <p>Jenny Wilson</p>
            </div>
        </div>
    </div> -->
</div>
