<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-8 col-12">
      <div class="row justify-content-center">
        <div class="col-md-12">
      <h1>This is A testing Page</h1>
      <div>
        <input ngx-google-places-autocomplete (onAddressChange)="AddressChange($event)"/>
        {{ formattedaddress }}
        </div>
        </div>
        </div>
      </div>
    </div>
  </div>