<div class="">
	<!-- <div class="row"> -->
	<img
	data-src="../../assets/images/APARTMENT-Interior-Design-For-Your-Appartment.png"
	class="lazyload profile"/>
	<div class="mob-none">
		<div class="row justify-content-center">
		<div class="col-md-10 col-12 breadcrumb-style">
		 <a href="/landing" class="text-decoration-none"><span class="home-sec">HOME >>  </span></a> 
		 <a href="/apartment" class="text-decoration-none"><span class="home-sec">APARTMENT >>  </span></a>
		 <a><span class="space-sec">{{ apartment_name }}</span></a>
			</div>
		</div> 
		</div>
		<!-- </div> -->
</div>
<!-- <div class="container-fluid mobileHeader d-block d-md-none">
</div> -->
<div class="container">
	<div class="row justify-content-center p-2">
		<div class="col-md-12">
			<div class="row border my-4 py-1 align-items-center brdradius">
			
				<div class="col-md-4 brdrbtm">
					<div class="form-group mb-0 alignapartmentsearch" *ngIf="apartvarandspaceData">
						{{apartvarandspaceData[0]?.attributes?.apartment_name}},
						 {{apartvarandspaceData[0]?.attributes?.city | titlecase}}
					</div>
				</div>
				<div class="col-md-2 border-left brdrbtm ">
					<div class="form-group mb-0">
						<label class="ml-3 mb-0 fs14">Select Flat Size/ Type</label>
						<!-- <div style="display: flex;"> -->
						<select name="dropdown" id="inputState" class="form-control border-0" (change)="spaceFilter($event)" style="color: #000000; font-size: 18px;">
							<ng-container *ngFor="let data of apartmentDetailsData?.varients | keyvalue; let i=index;">
								<option  class="text-uppercase" [selected]="i == 0">{{data.value }}</option>
							</ng-container>	 
							
						</select>
						
						<!-- </div> -->
					</div>
				</div>
				<div class="col-md-2 border-left brdrbtm">
					<div class="form-group mb-0">
						<label class="ml-3 mb-0 fs14" style="margin-top: 10px;">Lifestage
						</label>
						<!-- <ng-container *ngFor="let data of apartmentDetailsData"> -->
						<p class="indian-ethenic">{{apartmentDetailsData?.lifestage | titlecase}}</p>
						<!-- </ng-container> -->
					</div>
				</div>
				<div class="col-md-2 border-left">
					<div class="form-group mb-0">
						<label class="ml-3 mb-0 fs14" style="margin-top: 16px;">Theme
						</label>
						<p class="indian-ethenic">{{apartmentDetailsData?.theme | titlecase}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>	

 <!-- Apartment layout and address -->

<div class="container"  *ngFor="let data of apartvarandspaceData ; let k=index;">
	<div class="row" *ngIf="k==0"> 
		<div class="col-12 col-lg-6 p-0 pr-md-3 parents">
			<div class="grid"> 
				<p class="d-none">utgyuecijdxtcgy</p>
				<figure class="effect-milo">
					<img data-src="{{data.attributes.primary_image}}" onerror="src='../../../assets/images/default-apartment.png'" class="lazyload" alt="img11"/>
					<div class="overlay"></div>
        <!-- <div class="btnPar">
          <p class="emi">
            {{data.attributes.apartment_address}}
          </p>
          <span>
            
          </span>
        
        </div> -->
					<figcaption>
						<h2 class="apartment_name" style="font-size: 26px !important;">{{apartvarandspaceData[0].attributes.apartment_name}}</h2>
						<p>{{data.attributes.apartment_address}}</p>
					</figcaption>			
				</figure>
			</div>
		</div>
		<div class="col-12 col-lg-6 pr-md-0" style="background: #f9f9f9;">
			<div class="pt-3"> 
				<div class="midas"> {{data.attributes.apartment_name}}</div> 

				<div class="CTS"><span style="font-weight: bold;"> Developed by:</span> {{data.attributes.developer_name}}</div>
				<div class="CTS"><span style="font-weight: bold;">Area:</span> {{data.attributes.area}} Sq. Ft.</div>
				<div class="CTS"><span style="font-weight: bold;"> Layout Format:</span> {{bhkData}}</div>


			</div>
			<div class="Line"></div>
			<div class="d-flex justify-content-center hover-floor">
				<img data-src="{{data.attributes.layout_image}}" onerror="src='../../../assets/images/fllor-plan-default.png'" alt="layout_image" class="layout_image lazyload" (click)="openImges(data.attributes.layout_image);">
			</div>
			<div class="bookCons">
					<button type="button" class=" btn-sm  consultbtn-1 px-3 py-2" (click)="bookConsultation()"> Book Consultation </button>
			</div>
		</div>
	</div>
</div>

 <!-- Spaces lebel start -->
	
<div class="container">
	<ng-container *ngFor="let data of apartvarandspaceData; let i=index">
	<div class="justify-content-between mt-4 row">
		<div class="col-md-4 col-sm-12 pl-0 text-center text-md-left pr-0">
			<img src="../assets/images/Fill-116.svg" class="Fill-116-2" alt="vwuy" />
			<p  class="latest-design-spaces text-capitalize">OUR PRESET FOR {{bhkData | uppercase}}</p>
			
			<img src="../assets/images/Fill-117.svg" class="Fill-117-3" alt="vwuy" />
		</div>
		<div class="col-md-8 col-sm-12 col-12 mt-3 text-md-right text-left pr-0">
			<ng-container *ngFor="let space of data.attributes.apartment_spaces; let l=index">
			<span class="Modular-Kitchen addcursor" [ngClass]="{'active':space.name===activated[i]}"> 
				<a (click)="onClickSpaces(space.id, i, space.name); checkEmi(space.id, space.lifestage[i]);">{{space.name | spacePipe}}</a>
			</span>
			</ng-container>
		</div>
	</div>

<div class="container">
	<div class="row mt-3">
		<div class="col-12 col-sm-10 col-md-3 col-lg-3 justify-content-sm-center viewmobile1">
			<div class="Rectangle12" >
				<div class="Modualr ">{{activated[i] | uppercase}}</div>
				<div class="YOUNG-VIBES-India" *ngIf="show_lifes[i] !== undefined">{{transform(show_lifes[i] | uppercase)}} &</div>
				<div class="YOUNG-VIBES-India" *ngIf="show_lifes[i] === undefined">{{data.attributes.lifestage | uppercase}} &</div>

				<div class="YOUNG-VIBES" *ngIf="show_space[i] !== undefined"> {{show_themes[i] | uppercase}}</div>
				<div class="YOUNG-VIBES" *ngIf="show_space[i] === undefined"> {{data.attributes.theme | uppercase}}</div>
				<div class="size mt-3"><b>Size: </b>
					<!-- <span style="font-size: 14px;" *ngIf="show_emi === undefined && data.attributes.apartment_spaces[i].price_cents !== 0">{{data.attributes.apartment_spaces[i].area | number:'1.0-0'}} Sq. Ft.</span>
					<span style="font-size: 14px;" *ngIf="show_space !== undefined && show_space !== 0">{{show_emi | number:'1.0-0'}} Sq. Ft.</span>
					<span style="font-size: 14px;" *ngIf="show_space === undefined && data.attributes.apartment_spaces[i].area === 0">{{data.attributes.area | number:'1.0-0'}} Sq. Ft.</span>
					<span style="font-size: 14px;" *ngIf="show_space !== undefined && show_space === 0">{{data.attributes.area | number:'1.0-0'}} Sq. Ft.</span> -->
					<span style="font-size: 14px;" *ngIf="show_space[i]">{{show_space[i]}} Sq. Ft.</span>
					<!-- <ng-template #otherbutton> -->
					<span style="font-size: 14px;" *ngIf="!show_space[i]">{{data.attributes.area}} Sq. Ft.</span>
					<!-- </ng-template> -->

					<div> <b>EMI: </b>
						<span style="font-size: 14px;" *ngIf="show_emi[i] !== undefined">INR {{show_emi[i]}}</span>
						<!-- <span style="font-size: 14px;" *ngIf="show_emi[i] === 0">INR {{data.attributes.boq_emi | number:'1.0-0'}}</span> -->
						<span style="font-size: 14px;" *ngIf="show_emi[i] === undefined">INR {{data.attributes.boq_emi | number:'1.0-0'}}</span>


						<!-- <span style="font-size: 14px;" *ngIf="show_emi === undefined && data.attributes.apartment_spaces[i].price_cents !== 0">INR {{data.attributes.apartment_spaces[i].price_cents | number:'1.0-0'}}</span>
						<span style="font-size: 14px;" *ngIf="show_emi !== undefined && show_emi !== 0">INR {{show_emi | number:'1.0-0'}}</span>
						<span style="font-size: 14px;" *ngIf="show_emi === undefined && data.attributes.apartment_spaces[i].price_cents === 0">INR {{data.attributes.boq_emi | number:'1.0-0'}}</span>
						<span style="font-size: 14px;" *ngIf="show_emi !== undefined && show_emi === 0">INR {{data.attributes.boq_emi | number:'1.0-0'}}</span>
						<span style="font-size: 14px;" *ngIf="show_emi === undefined && data.attributes.apartment_spaces.length === 0">INR {{data.attributes.boq_emi | number:'1.0-0'}}</span> -->

					</div>
						<div><a (click)="imageopen()" style="cursor: pointer; color: #8d0528;">
							View Inclusions</a></div>
						<ng-container *ngIf="data.attributes.vr_link">
						<div (click)="vrvideospace(data)" style="cursor: pointer;" class="mr-4 mt-4 d-flex parents">
							<img [src]="imgSrc" class="menuImg" src="../../../assets/images/360-Icon_Black.png" alt="logo" (mouseover)="onHover()" (mouseout)="onMouseOut()"/>
						</div>
							</ng-container>
				</div>


				<div class="d-flex justify-content-center ">
					<span (click)="OnPrivClick()" class="targe">
						
						<a [href]="'#carouselcontrol'+ apartvarandspaceData[i].id" role="button" data-slide="prev" class="removeline">
							<span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <div style="width: 60px;">
								<div id="holderpre" style="font-size: 14px; color: #000000; text-align: center;">
								  <div id="maskpre"></div>
								  <div id="maskpre"></div>
								  <div id="mask"></div>
								  <i class="fa fa-arrow-left preiocon"></i>
								  <span class="prebutton">PREV</span>
								 </div>
							</div>
						</a>			
          </span>
					<span (click)="OnNextClick()" class="addcursor">		
            <a [href]="'#carouselcontrol'+apartvarandspaceData[i].id" role="button" data-slide="next" class="removeline">
						  <span class="carousel-control-next-icon" aria-hidden="true"></span>
							<div style="width: 133px;">
								<div id="holder" style="font-size: 14px;color: #000000;text-align: center;">
                                   <div id="mask2"></div>
								    <div id="mask1" style="position: absolute;bottom: -2px;left:0px;width:50%;height: 1px;background-color:#ffffff;border: 2px solid white">
								    </div>
								 <span class="buttcolor"> NEXT</span> &nbsp;
								 <i class="fa fa-arrow-right nexticon"></i>
								</div>
              </div>
						</a>
					</span>
				</div>
			</div>
			<div class="buttonhide text-center">
				<button type="button" class="btn btn-sm getdesign" (click)="getThisDesign(data.attributes.apartment_name, data.attributes.city, data.attributes.pincode , data.attributes.format, data.attributes.id, data.attributes.layout_image_file_name); engageDesign(data.attributes.apartment_spaces[0].name,data.attributes.apartment_spaces[0].lifestage,data.attributes.apartment_spaces[0].price_cents)"> Get this design </button>
				<button type="button" class="btn btn-sm contactwithus" (click)="bookConsultation()">
					 Book Consultation</button> 
			</div>
		</div>
		<div class="col-md-9 col-sm-12 p-0 p-md-3 viewmobile2">
			<div id="carouselcontrol{{apartvarandspaceData[i].id}}" class="carousel slide">
				<div class="carousel-inner">
					<ng-container *ngFor="let space of xyz && xyz[i]; let j=index">
						<div class="carousel-item active underlinecarosel" [ngClass]="{'active':j===0}">
							
							<div class="row">
								<div class="col-md-6 col-sm-6 col-12 col-sm-12" >
									<img data-toggle="modal" style="cursor: pointer;" [src]="xyz[i][j].attributes.document" class="d-block " onerror="src='../../../assets/images/default-apartment.png'">
								</div>
							</div>
						</div>
						</ng-container>
				</div>
			</div>

		</div>
	</div>
</div>

	</ng-container>
	
</div>

<!--Customer Testimonial-->
<ng-container *ngIf="show_testimonial">
<div class="container mt-4 ">
   <div class="justify-content-between mt-4 row">
		<div class="col-md-4 col-sm-12 pl-0 text-center text-md-left pr-0">
			<img src="../assets/images/Fill-116.svg" class="Fill-116-2" alt="vwuy" />
			<p  class="latest-design-spaces text-capitalize">Customer's Testimonial</p>
			
			<img src="../assets/images/Fill-117.svg" class="Fill-117-3" alt="vwuy" />
		</div>
	</div>
  <div class="d-sm-none d-md-block d-none">
  <div class="row justify-content-center mt-4 pb26px">
  
	<ng-container *ngIf="video_1">	
	<iframe src="https://youtube.com/embed/8VAQ0gS2Luc" width="70%" height="480"></iframe>
		</ng-container>
		<ng-container *ngIf="video_2">	
	<iframe src="https://www.youtube.com/embed/IKgtsi78Pz0" width="70%" height="480"></iframe>
		</ng-container>
		<ng-container *ngIf="video_3">	
	<iframe src="https://www.youtube.com/embed/DffBgZ-wKGw" width="70%" height="480"></iframe>
		</ng-container>
		<ng-container *ngIf="video_4">
<iframe src="https://www.youtube.com/embed/Y_WhST9wzqw" width="70%" height="480"></iframe>
</ng-container>
<ng-container *ngIf="video_5">
<iframe src="https://www.youtube.com/embed/WMqOfxdA7Ak" width="70%" height="480"></iframe>
</ng-container>
<ng-container *ngIf="video_6">
<iframe src="https://www.youtube.com/embed/KwRzJyz1D4U" width="70%" height="480"></iframe>
</ng-container>
<ng-container *ngIf="video_7">
<iframe src="https://www.youtube.com/embed/UHRTLTHDRn4" width="70%" height="480"></iframe>
</ng-container>
<ng-container *ngIf="video_8">
<iframe src="https://www.youtube.com/embed/LJezp0rwRSg" width="70%" height="480"></iframe>
</ng-container>
   </div>
  
   </div>
   
   <div class="d-md-none d-sm-block">
  <div class="row justify-content-center pb26px">
    
<ng-container *ngIf="video_1">
<iframe src="https://youtube.com/embed/8VAQ0gS2Luc" width="90%" height="300"></iframe>
</ng-container>
<ng-container *ngIf="video_2">
<iframe src="https://www.youtube.com/embed/IKgtsi78Pz0" width="90%" height="300"></iframe>
</ng-container>
<ng-container *ngIf="video_3">
<iframe src="https://www.youtube.com/embed/DffBgZ-wKGw" width="90%" height="300"></iframe>
</ng-container>
<ng-container *ngIf="video_4">
<iframe src="https://www.youtube.com/embed/Y_WhST9wzqw" width="90%" height="300"></iframe>
</ng-container>
<ng-container *ngIf="video_5">
<iframe src="https://www.youtube.com/embed/WMqOfxdA7Ak" width="90%" height="300"></iframe>
</ng-container>
<ng-container *ngIf="video_6">
<iframe src="https://www.youtube.com/embed/KwRzJyz1D4U" width="90%" height="300"></iframe>
</ng-container>
<ng-container *ngIf="video_7">
<iframe src="https://www.youtube.com/embed/UHRTLTHDRn4" width="90%" height="300"></iframe>
</ng-container>
<ng-container *ngIf="video_8">
<iframe src="https://www.youtube.com/embed/LJezp0rwRSg" width="90%" height="300"></iframe>
</ng-container>
   </div>
  
   </div>
</div>
</ng-container>
 <!-- EMI and Consultaion button start -->

 <div class="container">
	<div class="row mt-5 justify-content-center mb-5 mobileemioption">
		<div class="rectangular4 mb-3">
			<div class="row">
				<div class="col-3">
					<img src="../assets/images/consulation.png" class="consulation" />
				</div>
				<div class="col-8 free">
					<span>FREE CONSULTATION</span><br>
					 <span>EASY BOOKING</span>
					<a (click)="bookConsultationApartmentList()"><div class="know">KNOW MORE</div></a>
				</div>
			</div>
		</div>
		<div class="rectangular5">
			<div class="row">
				<div class="col-3">
					<img src="../assets/images/houseprice.png" class="consulation" />
				</div>
				<div class="col-8 free">
					<span>EMI OPTION ON</span><br> 
					<span>COMPLETE PROJECT</span>
					<a style="text-decoration: none;" href="/get-finance" target="_blank"><div class="know" >KNOW MORE</div></a>
				</div>
			</div>
		</div>
	</div>
 </div>



	<!-- open image modal for apartment-->
	
<div class="modal fade" id="inclusionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<img src="../../../assets/images/close.png" (click)=closemodalimage() class="buttonclose2"/>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
		<div class="modal-content">
      <div class="modal-body city-modal">
        <div class="row">
					<div class="col-12">
   <img class="openimage" src="{{this.openimagedata}}" onerror="src='../../../assets/images/fllor-plan-default.png'">
	</div>
	</div>
	</div>
	</div>
	</div>
</div>
<!-- loader from here -->
<div class="loading d-flex justify-content-center" *ngIf="loader">
  <div class="spinner-grow text-primary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <div class="spinner-grow text-secondary" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <div class="spinner-grow text-success" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <div class="spinner-grow text-danger" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <div class="spinner-grow text-warning" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <div class="spinner-grow text-info" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  <div class="spinner-grow text-light" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>


<!--Apartment Inclusion Modal-->
<div class="modal" id="apartinclusionModal">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content serach-button-style closemobile">
      <div class="modal-body city-modal">
				<div class="row">
					<img src="../../../assets/images/close-circle-22.png" (click)=closemodal() class="buttonclose"/>
				
				</div>
        <div class="row">
					
					<!-- <div class="col-md -8 col-12">
						<img style="height: 100%;" [src]="storedURL" onerror="src='../../../assets/images/default-apartment.png'" class="d-block w-100">
				</div> -->
				<ng-container *ngIf="showModal">
					<div class="col-md -8 col-12">
						<img style="height: 100%;" [src]="storedURL" onerror="src='../../../assets/images/default-apartment.png'" class="d-block w-100">
				</div>
					<div class="col-md-4 col-12">
						<ng-container *ngFor = "let item of storeditems?.data">
									<div class="row rectangular1">
										<div class="col-8">
											<div class="oven text-capitalize"> {{item.attributes.item_name}}</div>
											<p class="include">
												Include Me:
												<label class="switch">
													<input
														type="checkbox"
														value="{{ item.id }}"
														(change)="getItems($event, item.id, item.attributes)"
														checked
													/>
													<span class="slider round"></span>
												</label>
											</p>
										</div>
										
										<div class="col-4">
											<img class="img-fluid" src={{item.attributes.image}} onerror="src='../../../assets/images/default-apartment.png'">
										</div>
									</div>


					</ng-container>
					<div class="row justify-content-center">
					<button type="button" class="btn btn-sm getdesign" (click)="getThisDesignmodal()"> Get this design </button>
				<button type="button" class="btn btn-sm contactwithusmodal" (click)="bookConsultation()">
					 Book Consultaion</button>
					 </div>
						</div>
						</ng-container>
						<ng-container *ngIf="!showModal">
							<div class="col-md -8 col-12" style="height: 520px;">
								<img style="height: 100%;" [src]="storedURL" onerror="src='../../../assets/images/default-apartment.png'" class="d-block w-100">
						</div>
							<div class="col-12">
								<div class="row justify-content-center">
									<button type="button" class="btn btn-sm getdesign" (click)="getThisDesignmodal()"> Get this design </button>
								<button type="button" class="btn btn-sm contactwithusmodal" (click)="bookConsultation()">
									 Book Consultaion</button>
									 </div>
									 </div>
								</ng-container>
							<!-- </ng-container> -->
        </div>
      
      </div>
    </div>
  </div>
</div>

<!-- Apartment Inclusion Modal ends here-->
