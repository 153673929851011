<div class="update-footer">
    <div class="row pt-5 py-lg-5 mx-lg-5 mx-0 root-div">
        <div class="col-lg-12">
            <div class="row logo ">
                <div class="col-lg-12">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-6">
                            <img src="../../../assets/images/new_arrivae_logo/image 6.png">
                        </div>
                        <div class="col-lg-8 align-self-center">
                            <div class="row main-links pt-5 pt-lg-0">
                                <div class="col-lg-4 col-md-4">
                                    <div class="row align-items-center flex-column flex-lg-row justify-content-lg-between row">
                                        <div class="col-6 px-0 pb-lg-0 pb-4">
                                            <a routerLink="/" [routerLinkActiveOptions]="{exact:true}" routerLinkActive="highlight">Home</a>
                                        </div>

                                        <div class="col-6 px-0 pb-lg-0 pb-4">
                                            <a [ngClass]="{'highlight': spaceActive.isActive}" routerLink="/spaces" routerLinkActive="highlight" >Spaces</a>
                                            <a routerLink="/space-inclusion/{{idForEachInclusion}}" routerLinkActive #spaceActive="routerLinkActive" style="display: none"></a>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-4">
                                    <div class="align-items-center flex-column flex-lg-row justify-content-lg-end row">
                                        <div class="col-5 px-0 pb-lg-0 pb-4 highlight">
                                            <a [ngClass]="{'highlight': tasksActive.isActive}" routerLink="/apartment" routerLinkActive="highlight">Apartments</a>
                                            <a routerLink="/apartment-detail" routerLinkActive #tasksActive="routerLinkActive" style="display: none"></a>
                                        </div>
                                        <div class="col-5 d-flex justify-content-lg-end justify-content-center px-0 pb-lg-0 pb-4">
                                            <a routerLink="/get-finance">Get Finance</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-3 d-flex justify-content-center pb-lg-0 pb-4">
                                    <a routerLinkActive="highlight" routerLink="/visit-us">Visit Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="my-lg-5 my-4" style="border-bottom: 1px solid;"></div>
                </div>
                <div class="col-lg-4 col-md-4 col-8">
                    <div class="text-address">
                        <div class="add">
                            <p>B <span class="numbers">501/502</span>, Everest House, Suren Road, Gundavali, Andheri East, Mumbai, Maharashtra <span class="numbers">400093</span></p>
                        </div>
                        <div class="phn-email">
                            <div class="d-flex">
                                <div>
                                    <img src="../../../assets/images/phn_email/Icon.png">
                                </div>
                                <div class="ml-lg-2 mb-lg-2">
                                    <a href="tel:022-48900151 " class="numbers">022-48900151</a>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div>
                                    <img src="../../../assets/images/phn_email/Icon (1).png">
                                </div>
                                <div class="ml-lg-2 mb-lg-2">
                                    <a class="mb-lg-1" href="mailto:wecare@arrivae.com">wecare@arrivae.com</a>
                                </div>
                            </div>
                        </div>
                        <div class="add">
                            <p>Timings: <span class="numbers">09:00</span> AM to <span class="numbers">09:00</span> PM</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 px-lg-0" style="padding-left: 5rem;">
                    <div class="row justify-content-between">
                        <div class="col-lg-4 mt-5 mt-lg-0">
                            <div class="row">
                                <div class="col-12">
                                    <p class="footer-subheading">Experience Centers</p>
                                </div>
                            </div>
                            <div class="row justify-content-between">
                                <div class=" col-6">
                                   <div class="col-links">
                                    <a (click)="centers('mumbai')" href="" target="_blank" >Mumbai</a>
                                    <a (click)="centers('Thane')" href="" target="_blank" >Thane</a>
                                    <a (click)="centers('navi mumbai')" href="" target="_blank" >Navi Mumbai</a>
                                    <a (click)="centers('gurgaon')" href="" target="_blank" >Gurugram</a>
                                    <a  (click)="centers('bangalore')" href="" target="_blank" >Bengaluru</a>
                                   </div>
                                </div>
                                <div class=" col-6">
                                    <div class="col-links">
                                        <a (click)="centers('pune')" href="" target="_blank" >Pune</a>
                                        <a (click)="centers('chandigarh')" href="" target="_blank" >Chandigarh</a>
                                        <a (click)="centers('hyderabad')" href="" target="_blank" >Hyderabad</a>
                                        <a (click)="centers('trivandrum')" href="" target="_blank" >Trivandrum</a>
                                        <a (click)="centers('vijayawada')" href="" target="_blank" >Vijayawada</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4 pt-5 pt-lg-0">
                            <div class="row justify-content-between">
                                <div class="col-5">
                                    <div class="col-links">
                                        <p>Company</p>
                                        <a routerLink="/about-us" [routerLinkActiveOptions]="{exact:true}" routerLinkActive="highlight"  target="_blank">About Us</a>
                                        <a routerLink="/terms-and-condition" [routerLinkActiveOptions]="{exact:true}" routerLinkActive="highlight">Terms Of Use</a>
                                        <a routerLink="/privacy-policy" [routerLinkActiveOptions]="{exact:true}" routerLinkActive="highlight">Privacy Policy</a>
                                        <a href="https://arrivae.com/media_engagements" target="_blank">In The Media</a>
                                    </div>
                                </div>
                                <div class=" col-lg-5 col-md-5 col-6">
                                    <div class="col-links">
                                        <p>Contact</p>
                                        <a href="https://arrivae.com/arrivae_stories" target="_blank">Contact Us</a>
                                        <a href="https://arrivae.com/become_a_design_partner" target="_blank">Design With Us</a>
                                        <a href="https://arrivae.com/become_a_manufacturer" target="_blank">Build With Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-3 pt-5 pt-lg-0 col-links">
                            <p>More</p>
                            <a [class]="activFlag ? 'highlight' : ''" routerLink="/made-by-you-story" [routerLinkActiveOptions]="{exact:true}" routerLinkActive="highlight" target="_blank">Made-By-You Stories</a>
                            <a href="https://blog.arrivae.com/" target="_blank">Blogs</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-lg-5">
                <div class="col-lg-8 ml-lg-auto px-lg-0">
                    <div class="row justify-content-between">
                        <div class="col-lg-4 col-md-4">
                            <div class="justify-content-center justify-content-lg-start row">
                                <div class="col-8 col-lg-auto pt-5 pt-lg-0">
                                    <div class="modular-heading">
                                        <p>Modular Kitchens</p>
                                    </div>
                                    <div class="city-heading">
                                        <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}" target="_blank">Modular Kitchen in Bengaluru</a>
                                        <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}" target="_blank">Modular Kitchen in Coimbatore</a>
                                        <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}" target="_blank">Modular Kitchen in Delhi</a>
                                        <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}" target="_blank">Modular Kitchen in Gurgaon</a>
                                        <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}" target="_blank">Modular Kitchen in Mumbai</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-4">
                            <div class="justify-content-center justify-content-lg-start row">
                                <div class="col-8 col-lg-auto pt-3 pt-lg-0">
                                    <div class="modular-heading">
                                        <p>Wardrobe Designs</p>
                                    </div>
                                    <div class="city-heading">
                                        <a routerLink="/spaces" target="_blank">Wardrobe Designs in Bengaluru</a>
                                        <a routerLink="/spaces" target="_blank">Wardrobe Designs in Coimbatore</a>
                                        <a routerLink="/spaces" target="_blank">Wardrobe Designs in Delhi</a>
                                        <a routerLink="/spaces" target="_blank">Wardrobe Designs in Gurgaon</a>
                                        <a routerLink="/spaces" target="_blank">Wardrobe Designs in Mumbai</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-3">
                            <div class="justify-content-center justify-content-lg-start row">
                                <div class="col-8 col-lg-auto pt-3 pt-lg-0">
                                    <div class="modular-heading">
                                        <p>Apartments</p>
                                    </div>
                                    <div class="city-heading">
                                        <a routerLink="/apartment" target="_blank">Apartments in Bengaluru</a>
                                        <a routerLink="/apartment" target="_blank">Apartments in Coimbatore</a>
                                        <a routerLink="/apartment" target="_blank">Apartments in Delhi</a>
                                        <a routerLink="/apartment" target="_blank">Apartments in Gurgaon</a>
                                        <a routerLink="/apartment" target="_blank">Apartments in Mumbai</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row copy-right mx-0">
        <div class="col-lg-4 ml-4">
            <p class="mb-0 py-2">Copyright Arrivae {{year}}</p>
        </div>
    </div>
</div>