<app-new-header-for-home (sendData)="customFunc($event)" [data]="namephn" [verifyUser]="boolvalue"
    [l_lead_invitation]="leadInvitationId" [l_lead_id]="l_lead_id"></app-new-header-for-home>
<div class="root">
    <div class="container-fluid">
        <div class="row">
            <div class="carousel-container col-lg-8 px-lg-2 px-0">
                <div id="carouselExampleControls" class="carousel slide" data-ride="carousel" style="overflow:hidden">
                    <div *ngFor="let image of image_container; index as i" class="carousel-item"
                        [ngClass]="i == 0 ? 'active' : ''">
                        <img [src]="image" [alt]="image.alt" class="d-block w-100 carousel-images" />
                    </div>
                    <a class="carousel-images-prev" href="#carouselExampleControls" role="button" data-slide="prev"
                        style="width:7%;opacity:1.5;">
                        <button id="previous-carousel-button" class="previous-carousel-button">
                            <img class="img-fluid" src="../../../assets/images/Landing_page_updated/left_arrow.svg">
                        </button>
                        <span class="" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-images-next" href="#carouselExampleControls" role="button" data-slide="next"
                        style="width:9%;opacity:1.5;">
                        <button id="next-carousel-button" class="next-carousel-button">
                            <img class="img-fluid" src="../../../assets/images/Landing_page_updated/right_arrow.svg">
                        </button>
                        <span class="" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
            <div class="col-lg-4 reviewscontainer">
                <div class="heading px-lg-2 px-4">
                    <p class="mt-4 modaluserName">
                        {{ViewNextContent[ViewNextContentIndex].heading}}</p>
                    <p class="modaluserCity">
                        {{ViewNextContent[ViewNextContentIndex].city}}</p>
                </div>
                <div class="col-lg-11 px-0">
                    <p class="px-lg-2 mt-lg-0 userreviews">
                        <span>
                            <img src="../../../assets/images/Landing_page_updated/right quotes.png" class="right-quotes">
                        </span>&nbsp;
                        {{ViewNextContent[ViewNextContentIndex].title}}
                        <span>
                            <img src="../../../assets/images/Landing_page_updated/left quotes.png" class="left-quotes">
                        </span>
                    </p>
                </div>
                <hr class="col-3 ml-4 ml-lg-2">
                <p class="px-4 px-lg-2 username">
                    {{ViewNextContent[ViewNextContentIndex].header}}</p>
                <div class="col-lg-11 px-4 px-lg-2">
                    <p class="userintro">
                        {{ViewNextContent[ViewNextContentIndex].content}}
                    </p>
                </div>
            </div>
        </div>

        <div class="flexDisplay mt-3 mt-lg-5 px-3 px-lg-0 row">
            <div class="col-lg-4 somethingNew">
                <p class="startnew px-2">
                    {{ViewNextContent[ViewNextContentIndex].somethingNew}}</p>
                <div class="px-2">
                    <p class="startNewpara">
                        {{ViewNextContent[ViewNextContentIndex].contentNew1}}</p>
                    <p class="startNewpara">
                        {{ViewNextContent[ViewNextContentIndex].contentNew2}}</p>
                </div>
            </div>
            <div class="images-container col-lg-7 px-lg-0 px-0 mb-4">
                <div id="carouselExampleControl" class="carousel slide" data-ride="carousel" style="overflow:hidden">
                    <div *ngFor="let image of image_container; index as i" class="carousel-item"
                        [ngClass]="i == 0 ? 'active' : ''">
                        <img [src]="image" [alt]="image.alt" style="width: 100%;height: 460px;" />
                    </div>
                    <a class="carousel-images-prev" href="#carouselExampleControl" role="button" data-slide="prev"
                        style="width:7%;opacity:1.5">
                        <button id="previous-images-button" class="previous-images-button">
                            <img class="img-fluid" src="../../../assets/images/Landing_page_updated/left_arrow.svg">
                        </button>
                        <span class="" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-images-next" href="#carouselExampleControl" role="button" data-slide="next"
                        style="width:9%;opacity:1.5">
                        <button id="next-images-button" class="next-images-button">
                            <img class="img-fluid" src="../../../assets/images/Landing_page_updated/right_arrow.svg">
                        </button>
                        <span class="" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 hrstyle">
                <hr class="hrtag">
            </div>
        </div>
        <div class="col-lg-12">
            <div class="pt-lg-4 mb-3 mt-3 mt-lg-0 px-1 pb-4 pb-lg-0 px-lg-4">
                <!--pt-4 -->
                <div class="inclusion-modal-prev-next">
                    <div class="root-pre-next ml-xl-n3">
                        <div class="d-flex cursorPointer" (click)="prevSlider()">
                            <ng-container *ngIf="ViewNextContentIndex > 0">
                                <div class="align-self-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                        style="color: #8d0528" class="bi bi-arrow-left-circle mr-2" viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                                    </svg>
                                    <img class="d-lg-inline d-none mr-2 d-md-block d-none"
                                        src="../../../assets/images/Kids Room.png" width="70px" height="40px">
                                </div>
                                <div>
                                    <a class="mb-0 pre-icon">Previous
                                        Story</a>
                                    <a class="mb-0 apart-name d-md-block d-none">
                                        {{ ViewNextContentPreviousName }}
                                    </a>
                                </div>
                            </ng-container>
                        </div>
                        <div class="d-flex cursorPointer mr-xl-n3" *ngIf="(ViewNextContent.length-1) > ViewNextContentIndex" (click)="nextSlider()">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                style="color: #8d0528" fill="currentColor"
                                class="bi bi-arrow-right-circle align-self-center mr-2 d-md-block d-none"
                                viewBox="0 0 16 16">
                                <path fill-rule="evenodd"
                                    d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                            </svg>
                            <div class="align-self-center">
                                <img class="d-lg-inline d-none mr-2 d-md-block d-none"
                                    src="../../../assets/images/Cozy Bedroom.png" width="70px" height="40px">
                            </div>
                            <div>
                                <a class="mb-0 pre-icon mr-2">Next
                                    Story</a>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                                    style="color: #8d0528" fill="currentColor"
                                    class="bi bi-arrow-right-circle align-self-center mr-2 d-sm-block d-md-none"
                                    viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                        d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                </svg>
                                <a class="mb-0 apart-name d-md-block d-none">
                                    {{ ViewNextContentNextName }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <app-footer-update></app-footer-update>
        </div>
        <!-- <div class="loading d-flex justify-content-center" *ngIf="loader">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div> -->
    </div>
</div>