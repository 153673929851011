import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-escalation-table',
  templateUrl: './escalation-table.component.html',
  styleUrls: ['./escalation-table.component.scss']
})
export class EscalationTableComponent implements OnInit {

  type:any;
  loader:any =false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
  ) { 
    this.activatedRoute.queryParamMap.subscribe((params: any) => {
      this.type = params.params.type;
    });
  }

  ngOnInit(): void {
    if(this.type == 'oldest'){
      this.getEscalationtopOldest();
    }else{
      this.getEscalationSummary();
    }
  }

  escalationSummaryData: any;
  getEscalationSummary() {
    this.loader = true;
    this.sharedService.getEscalationSummary().subscribe((data) => {
      this.loader = false;
      this.escalationSummaryData = data;
      console.log('this.escalationSummaryData',this.escalationSummaryData)
    });
  }

  escalationtopOldestData: any;
  getEscalationtopOldest() {
    this.loader = true;
    this.sharedService.getEscalationtopOldest().subscribe((data) => {
      this.loader = false;
      this.escalationtopOldestData = data;
      console.log('this.escalationtopOldestData',this.escalationtopOldestData)
    });
  }

   diff_minutes(dt2:any, dt1:any) {
    var diff =(dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  dateFormate(data:any, type:any) {
    var value
    if(type == 'oldest'){
     var dt1 = new Date();
     var dt2 = new Date(data);
      value = this.diff_minutes(dt2, dt1)
    }else{
      value = data
    }

    var units = {
      "year": 24*60*365,
      "month": 24*60*30,
      "week": 24*60*7,
      "day": 24*60,
      "hour": 60
    }
    if(value > units.hour && value < units.day && value < units.week && value < units.month && value < units.year){
      const time = value/units.hour
      const exactVal = parseInt(time.toString())
      return exactVal > 1 ? exactVal + ' hours' : exactVal + ' hour'
    }else if(value > units.day && value < units.week && value < units.month && value < units.year){
      const time = value/units.day
      const exactVal = parseInt(time.toString())
      return exactVal > 1 ? exactVal + ' days' : exactVal + ' day'
    }else if(value > units.week && value < units.month && value < units.year){
      const time = value/units.week
      const exactVal = parseInt(time.toString())
      return exactVal > 1 ? exactVal + ' weeks' : exactVal + ' week'
    }else if(value > units.month && value < units.year){
      const time = value/units.month
      const exactVal = parseInt(time.toString())
      return exactVal > 1 ? exactVal + ' months' : exactVal + ' month'
    }else if(value > units.year){
      const time = value/units.year
      const exactVal = parseInt(time.toString())
      return exactVal > 1 ? exactVal + ' years' : exactVal + ' year'
    }else{
      return value > 1 ? value + ' minutes' : value + ' minute'
    }
    
  }

}
