import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { AboutusoneComponent } from './aboutusone/aboutusone.component';
import { AboutustwoComponent } from './aboutustwo/aboutustwo.component';
import { FooterComponent } from './footer/footer.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from './button/button.component';
import { ButtonPrevComponent } from './button-prev/button-prev.component';
import { BookConsultationFormComponent } from './book-consultation-form/book-consultation-form.component';
import { GetYourEstimateComponent } from './get-your-estimate/get-your-estimate.component'; 
import { NgSelectModule } from '@ng-select/ng-select';
import { GetEstimatePackageComponent } from './get-estimate-package/get-estimate-package.component';
import { GetFinanceComponent } from './get-finance/get-finance.component';
import { PresetPackagesComponent } from './get-estimate-package/preset-packages/preset-packages.component';
import { SpaceCardsComponent } from './get-estimate-package/preset-packages/space-cards/space-cards.component';
import { SpacePipePipe } from './space-pipe.pipe';
import { BecomeDesignPartnerComponent } from './become-design-partner/become-design-partner.component';
import { GetStoreComponent } from './get-store/get-store.component'; 
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { HeaderNewComponent } from './header-new/header-new.component';
import { FooterUpdateComponent } from './footer-update/footer-update.component';
import { VisitUsNewComponent } from './visit-us-new/visit-us-new.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { TestingMapComponent } from './testing-map/testing-map.component';
import { EscalationTableComponent } from './escalation-table/escalation-table.component';
import { NocTableComponent } from './noc-table/noc-table.component';
import { NewHeaderForHomeComponent } from '../landing/new-header-for-home/new-header-for-home.component';
import { FooterNewHomeComponent } from '../landing/footer-new-home/footer-new-home.component';
import { LandingModule } from '../landing/landing.module';
import { MadebyYouStoriesNewComponent } from '../landing/madeby-you-stories-new/madeby-you-stories-new.component';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { NgxSlickJsModule } from 'ngx-slickjs';
import { UpdatedBlogsDesignComponent } from '../landing/updated-blogs-design/updated-blogs-design.component';




@NgModule({
  declarations: [
    HeaderComponent,
    AboutusoneComponent,
    AboutustwoComponent,
    FooterComponent,
    ButtonComponent,
    ButtonPrevComponent,
    BookConsultationFormComponent,
    GetYourEstimateComponent,
    GetEstimatePackageComponent,
    GetFinanceComponent,
    PresetPackagesComponent,
    SpaceCardsComponent,
    SpacePipePipe,
    BecomeDesignPartnerComponent,
    GetStoreComponent,
    HeaderNewComponent, 
    FooterUpdateComponent,
    VisitUsNewComponent,
    TestingMapComponent,
    EscalationTableComponent,
    NocTableComponent,
    MadebyYouStoriesNewComponent,
    NewHeaderForHomeComponent,
    FooterNewHomeComponent,
    UpdatedBlogsDesignComponent

  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    GooglePlaceModule,
    NgxSlickJsModule.forRoot({
      links: { 
        jquery: '',
        slickJs:
          'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js',
        slickCss:
          'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css',
        slickThemeCss:
          'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css',
      },
    }),

    NgxYoutubePlayerModule.forRoot() 


  ],
  exports: [
    HeaderComponent,
    HeaderNewComponent,
    FooterUpdateComponent,
    AboutusoneComponent,
    AboutustwoComponent,
    FooterComponent,
    ButtonComponent,
    ButtonPrevComponent,
    GetYourEstimateComponent,
    GetEstimatePackageComponent,
    GetFinanceComponent,
    SpacePipePipe,
    BecomeDesignPartnerComponent,
    MadebyYouStoriesNewComponent,
    NewHeaderForHomeComponent,
    FooterNewHomeComponent,
    UpdatedBlogsDesignComponent

    
  ],
})
export class SharedModule {}
