import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LandingService } from '../landing/landing.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-promoter-program',
  templateUrl: './promoter-program.component.html',
  styleUrls: ['./promoter-program.component.scss']
})
export class PromoterProgramComponent implements OnInit {

  constructor(private fb: FormBuilder,
    private landingService: LandingService,
    private location: Location,
  ) {}
  successalert: any=false;
  erroralert: any=false;
  successMessage: any;
  errorMessage: any;
  registrationForm:FormGroup = this.fb.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    contact: ['',[Validators.required, Validators.pattern(/^\d{10}$/)]],
    city_id: ['',[Validators.required]]
  });
  ngOnInit(): void {
    this.getcities()     
  }
  id:any='';
  cities:any=[]
  getcities(){
    this.landingService.getCities().subscribe((res:any)=>{
      this.cities=res.data
    })
  }

  toggledetails(id: any){
    if( this.id == id){
      this.id=''
    }
    else{
      this.id=id
    }
  }

  submitform() {
    this.landingService.promoter(this.registrationForm.value).subscribe(
      (res:any)=>{
      this.successalert = true;
      this.successMessage = res.message;
        setTimeout(()=> {
          this.successalert = false;
          window.location.reload();
        }, 2000);
        
      },
      err=>{
        this.erroralert = true;
        this.errorMessage = err.error.message;
        setTimeout(()=> {
          this.erroralert = false;
        }, 2000);
      });
  }


}
