import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BookConsultationFormComponent } from 'src/app/shared/book-consultation-form/book-consultation-form.component';
import { SharedService } from 'src/app/shared/shared.service';
import { ApartmentService } from '../apartment.service';
declare function searchApartment(param1: any, param2: any): any;
declare function searchApartment(param1: any, param2: any): any;

declare function apartmentClicked1(param1: any, param2: any, param3: any): any;
@Component({
  selector: 'app-apartment-gallery',
  templateUrl: './apartment-gallery.component.html',
  styleUrls: ['./apartment-gallery.component.scss'],
})
export class ApartmentGalleryComponent implements OnInit {
  gallerydata: any;
  gallerydataApartment: any;
  searchApartment = '';
  totalApartmentdata: any;
  placeholdervalue: any;
  selectedCity: any;
  tabvalues: any = 'All APARTMENTS';
  loader: boolean = false;
  searchcity: any = '';
  serachedapart: any = '';
  finalcity: any = '';
  unid: any;
  constructor(
    private apartmentservice: ApartmentService,
    private router: Router,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private sharedService: SharedService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.selectedCity = localStorage.getItem('selectedCity');
    this.getCities();

    this.route.queryParams.subscribe((params) => {
      (this.searchcity = params['city']),
        (this.serachedapart = params['search']);
    });
    this.loadScript();
  }

  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  citydata: any;
  getCities() {
    this.sharedService.getCities().subscribe((data) => {
      this.citydata = data;
    });
  }

  apartmentList(city: any, search: any) {
    if (localStorage.getItem('selectedCity') == null) {
      this.selectedCity = 'mumbai';
    } else {
      this.selectedCity = localStorage.getItem('selectedCity');
    }
    this.loader = false;
    this.finalcity = this.searchcity;
    this.search_string = this.serachedapart;

    if (this.searchcity === undefined) {
      this.finalcity = this.selectedCity;
    }
    if (this.serachedapart === undefined) {
      this.search_string = search;
    }
    this.apartmentservice
      .getApartmentlist2(this.unid, this.finalcity, this.search_string)
      .subscribe((data) => {
        this.gallerydata = data;
        this.unid = this.gallerydata.unid;
        this.gallerydataApartment = this.gallerydata.data.data;
        this.totalApartmentdata = this.gallerydataApartment.length;
        this.placeholdervalue =
          'Search your apartment from' +
          ' ' +
          this.gallerydataApartment.length +
          ' ' +
          'apartments we designed in' +
          ' ' +
          city +
          ' ' +
          'by Apartment Name, Builder,Sector or Pin Code';
        setTimeout(() => {
          this.loader = false;
        }, 2000);
      });
  }

  getsearch(text: any) {
    let textsearchdata = text;
    this.apartmentservice
      .getsearchApartmentlist(textsearchdata)
      .subscribe((res) => {});
  }

  search_string: any = '';
  searchTextFilter(event: any) {
    this.search_string = event.target.value;
    searchApartment(this.finalcity, this.search_string);
    this.apartmentList(this.selectedCity, this.search_string);
  }

  slelecttab(data: any) {
    this.tabvalues = data;
  }

  ngAfterViewInit() {
    this.selectedCity = localStorage.getItem('selectedCity');
  }

  bookConsultationApartmentList() {
    const modal = this.modalService.open(BookConsultationFormComponent, {
      size: 'md',
    });
    modal.result.then((res) => {
      this.toastr.success(res.message);
    });
  }

  customFunc(data: any) {
    this.selectedCity = data.selectedCity;
    this.apartmentList(data.selectedCity, '');
  }

  apartmentClicked(param1: any, param2: any, param3: any) {
    apartmentClicked1(param1, param2, param3);
  }
}
