<div class="container-fluid">
    <div class="row p-sm-5 p-4">
        <div class="col-12 d-flex flex-column justify-content-center header mb-md-5 my-4 mt-md-0">
            <h2>FIND US IN</h2>
        </div>
        <div class="col-12 px-sm-5">
            <div class="row px-md-5">
                <div class="cities col-6 col-sm-4 col-md d-flex flex-column align-items-center mb-4" *ngFor="let item of cities; let i = index">
                    <span class="city">
                        <img class=" " src={{item.image}} alt="">
                    </span>
                    <span class="py-3">{{item.title}}</span>
                </div>
            </div>
        </div>
    </div>
</div>