<div class="wrapper">
    <div class="heading">
        <h1>Blogs</h1>
        <a href="https://blog.arrivae.com/" target="_blank">See all</a>
    </div>

    <div class="cards-container">
        <ng-container *ngFor="let blog of blogs">
            <div class="card">
                <a [href]="blog.guid.rendered" target="_blank">
                    <div class="card-image">
                        <img [src]="[blog._embedded['wp:featuredmedia'][0].source_url]" alt="">
                    </div>
                    <div class="card-body">
                        <div class="time-stamp">
                            <p>{{blog.date | customDate }}</p>
                            <p> 
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dot" viewBox="0 0 16 16">
                                    <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"/>
                                </svg>
                                5 mins read</p>
                        </div>
                        <p class="card-text">{{blog.title.rendered}}</p>
                    </div>
                </a>
            </div>
        </ng-container>
    </div>
</div>