import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-payment-refresh',
  templateUrl: './payment-refresh.component.html',
  styleUrls: ['./payment-refresh.component.scss']
})
export class PaymentRefreshComponent implements OnInit {

  constructor(private route: ActivatedRoute, public sharedService: SharedService) { }
  loading: boolean = false;
  lead_id: any;
  quotation_id: any;
  space: any;
  amount: any;
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.lead_id = params['lead_id'];
      this.quotation_id = params['quotation_id'];
      this.space = params['space'];
      this.amount = params['loan_amount'];
    });
    this.getInfo();
    this.loadScript()
  }

  url: any;
  getInfo() {
    this.loading = true;
    const postObj = {
      "lead_id": this.lead_id,
      "quotation_id" : this.quotation_id,
      "space" : this.space,
      "amount" : this.amount
  }
    this.sharedService.redirectUrl(postObj).subscribe(res => {
      this.url = res;
      window.location.replace(this.url.url);
      this.loading = true;
    })
  }

  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

}
