<div class="wrapper">
	<div class="close-container">
		<span class="closeIcon" aria-hidden="true" (click)="closeModel()">
			<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
				<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
			</svg>
		  </span>
	</div>
	<div class="gallery-container">
		<div id="carouselProjectGalleryControls" class="carousel slide carousel-custom" data-ride="carousel"
			data-wrap="false" data-interval="false">
			<button class="carousel-control-prev" type="button" data-target="#carouselProjectGalleryControls"
				data-slide="prev">
				<span class="carousel-control-prev-icon" aria-hidden="true"></span>
				<span class="sr-only">Previous</span>
			</button>
			<div class="carousel-inner">
				<div *ngFor="let image of images[position]; index as i;" class="carousel-item" [ngClass]="i == 0 ? 'active carouselFirst' : ''" [id]="i == images[position].length-1 ? 'carouselLast' : ''">
					<img src={{image}} class="d-block w-100" alt="...">
				</div>
				<!-- <div class="carousel-item">
					
					<img [src]="images[1]" class="d-block w-100" alt="...">
				</div>
				<div class="carousel-item">
					<img [src]="images[2]" class="d-block w-100" alt="...">
				</div>
				<div class="carousel-item">
					<img [src]="images[0]" class="d-block w-100" alt="...">
				</div>
				<div class="carousel-item">
					<img [src]="images[1]" class="d-block w-100" alt="...">
				</div>
				<div id="carouselLast" class="carousel-item">
					<img [src]="images[2]" class="d-block w-100" alt="...">
				</div> -->
			</div>
			<button class="carousel-control-next" type="button" data-target="#carouselProjectGalleryControls"
				data-slide="next">
				<span class="carousel-control-next-icon" aria-hidden="true"></span>
				<span class="sr-only">Next</span>
			</button>
		</div>
	</div>
</div>