<div class="container-fluid">
  <div class="row">
    <div class="col-12 mt50px">
      <div class="d-flex justify-content-center">
        <div class="titleTriangle">PRESET PACKAGES</div>
        <span class="arrow-down"></span>
        <img src="../assets/images/Fill-117.svg" class="smallLogoIcon zI1" alt="vwuy" />
      </div>
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-4 col-12">
      <div class="row">
        <div class="col-md-12">
          <p class="text-center">(To get a more clear estimation price closer, select options from custom package or
            book consultation)</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-3 pr-0 overflow-auto d-flex align-items-center">
        <div *ngFor="let data of spaces; let i=index;" class="spaceTabs cursorPointer"
          [ngClass]="{ active: selectedSpace === data.space }">
          <!-- <p class="rangeTag">Rs: {{data.range}}</p> -->
          <span (click)="spaceTabHandler(data)">{{data.space | spacePipe}}</span>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-3 mt-3 mt-md-0" *ngFor="let data of spaceData; let i=index;">
        <app-space-cards [data]="data" (refresh)="afterChangeHandler();"></app-space-cards>
      </div>
    </div>
  </div>
