<!-- <app-header (sendData)="customFunc($event)"></app-header> -->
<div class="root-overflow">
   <app-new-header-for-home (sendDataClass)="fetchclass($event)"  (sendData)="customFunc($event)" [data] = "namephn" [verifyUser] = "boolvalue" [l_lead_invitation] ="leadInvitationId" [l_lead_id] = "l_lead_id" ></app-new-header-for-home>
   <div id="banner-image"  (mouseover)="lazyload()" class="banner-image mt-md-5  m-md-0 mx-2">
      <div class="w-100 banner-imagew pb-md-4 pb-0 d-flex justify-content-lg-between align-items-end" >
         <div class="tagline pl-3 mb-5 ">
            <p class="ml-5 pl-3">
               Design your<br> 
               dream home,  <br>       
               your way! Choose <br>
               from 10Lakhs+ highly <br>
               customisable themes<br>
            </p>
            <button class="get-price button_book d-none d-md-block mt-5" (click)="bookConsultationLandingPage()" >GET THE BEST PRICE</button>
            <div class="jayaho_logo  ml-md-5 d-block mt-5"> 
            </div>
         </div>   
         <div class="col-3">
         <div class="Get_quote_form pt-3 px-4  mr-5  d-lg-block d-none float-right ">
            <p style="font-weight:500!important">
               Designed by you,<br>
               Executed by us
            </p>
            <div class="form_container"  >
               <form class="" [formGroup]= "BookingDetails" (keydown.enter)="preventSubmit($event)" >
               <input  type="text" placeholder="Name" formControlName="name" class="form-control text_feild w-100" >
               <input type="text" placeholder="Email ID" formControlName="email" class="form-control text_feild w-100 mt-3" >
               <input type="number" placeholder="Phone number" formControlName="contact" oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" class="form-control text_feild mt-3 w-100" >

               <input type="checkbox" class="whatsapp-check mt-3"   > <span class="checkBoxLabel ml-2">Send me updates on WhatsApp </span>
               
              <div class="w-100"  (mouseleave)="hideOptions()"  (mouseenter)="showOptions()">
               <input  type="text" placeholder="Property Name" formControlName="property_name"  class="form-control text_feild mt-3 w-100" (keyup)="searchSocietyApi($event)"  >
               <ul  class="adressSelect" *ngIf="isApifired && !isSelected && BookingDetails.controls['property_name'].value != ''" >
                  <li *ngFor="let option of filteredOptions " (click)="selectOption(option)"><span class="d-inline-block h-100"><i class="fa fa-map-marker pr-1" aria-hidden="true" style="
                    font-size: 22px;vertical-align: middle; "></i></span> <span class="d-inline-block ml-1">{{ option.apartment_name }} {{ option.address }}</span>
                    </li>
                    <li *ngIf="!isSelected"  >
                      <span class="d-inline-block h-100">
                      <i class="fa fa-map-marker pr-1" aria-hidden="true" style="
                    font-size: 22px;vertical-align: middle; "></i></span> <span class="d-inline-block ml-1"> Could not find your property?
                      <button class="submit_btn w-100 mt-3" (click)="selectOption('other')">Add Property Details</button></span>
                    </li>
                </ul>
              </div>
              

               <button    [disabled]="checkDisable()"   (click) ="DetailsSubmit()"  class="submit_btn w-100 mt-3" > GET FREE QUOTE</button>

               <p class="termsAndCondition mt-2">
                  By submitting this form, you agree to the 
                  <span>
                     <a routerLink="/privacy-policy" target="_blank"><span>privacy policy</span></a> & 
                     <a routerLink="/terms-and-condition" target="_blank"><span>terms and conditions</span></a>
                  </span>
               </p>               
            </form>
         </div>
         </div>
         </div>
         
      </div>
      
      <div class="modal modal-get-quote" id="getQuoteModal" tabindex="-1" aria-labelledby="getQuoteModalLabel" aria-hidden="true">
          <div class="modal-dialog get-quote-wrapper">
             <div id="get-quote-container" class="modal-content get-quote-container">
                <div class="contents">
                   <div class="get-quote-head">
                      <img class="arrow-left-icon" src="../../../assets/images/icons/arrow left.svg" alt="arrow left">
                   </div>
                   <div class="get-quote-body">
                      <div class="text-wrapper">
                         <div class="text-container">
                            <p class="text-head">Enter OTP</p>
                            <p class="text">We have sent you an OTP to your phone number.</p>
                         </div>
                         <div class="icon">
                            <img class="img-fluid" src="../../../assets/images/Landing_page_updated/shield-icon.png" alt="shield-icon">
                         </div>
                      </div>
                      <div class="input-field">
                         <input 
                         type="number" 
                         placeholder="OTP" 
                         maxlength="6" 
                         [(ngModel)]="enteredOptNumber"
                         oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                         >
                         <!-- <p class="time-remaining">Time Remaining : <span class="light-green">2:00</span></p> -->
                         <button class="button-verify" (click)="verifyOtp()">Verify</button>
                      </div>
                   </div>
                </div>
                <div class="get-quote-footer">
                   <p class="text-footer m-0">By verifying, you are allowing us to reach out to you on the number you have providied. You can also view our Terms Of Use & Privacy Policy.</p>
                </div>
             </div>
          </div>
      </div>
   </div>

   <div class="consultation mx-2 m-md-0 justify-content-center">
      <div class="row mx-md-5 mx-0">
         <div class="consultations col-lg-2 col-md-4 col-6 text-center align-items-center d-flex flex-column mb-4 mb-md-3" *ngFor="let feature of consultation">
            <div class="image ">
               <img src={{feature.image}} alt="" class="pb-3"><br>
            </div>
            <span class=" font-weight-bold  pb-1">{{feature.tag}}</span>
            <span class="" [style]="feature.width ">{{feature.quote}}</span>
         </div>
      </div>
      <div class=" d-flex justify-content-center pt-3  pb-3 pb-md-5">
         <button class=" d-md-block d-none  button_book button btn-pink"  type="button" (click)="bookConsultationLandingPage()">BOOK A FREE DESIGN CONSULTATION</button>
         <button class=" d-md-none d-block  d-flex align-items-center button btn-pink"  type="button" (click)="bookConsultationLandingPage()">GET THE BEST PRICE &nbsp;
            <span>
            <img src="../../../assets/images/Arrow 1.png"/>
            </span>
         </button>
      </div>
   </div>   


    <app-design-themes></app-design-themes>
    <app-arriave-living></app-arriave-living>
    <app-homes-delivered></app-homes-delivered>
    <app-madeby-you-stories-new> </app-madeby-you-stories-new>
    <app-our-stores></app-our-stores>
    <app-how-it-works-new></app-how-it-works-new>
    <app-instagram></app-instagram>
    <app-updated-blogs-design></app-updated-blogs-design>
    <app-end-to-end></app-end-to-end>
    <app-frequent-questions></app-frequent-questions>
    <app-footer></app-footer>

   <!-- <app-latest-blogs-new></app-latest-blogs-new> -->

 </div>
 <div class="loading d-flex justify-content-center" *ngIf="loader">
    <div class="spinner-border" style="color: #8d0528;" role="status">
       <span class="sr-only">Loading...</span>
    </div>
 </div>
 <div id="OtherprojectName2" class="modal" role="dialog" data-backdrop="static">
   <div class="modal-dialog modal-dialog-centered"  style="max-width: 50rem !important;"  >
     <!-- Modal content-->
     <div style="border: 3px solid #6F0000;" class="modal-content p-1">
       <div class="modal-header">
         <h6 class="modal-title w-100 text-center"> Add Society/Building/Project </h6>
         <button (click)="closeaddApartmentrplanModal()" type="button" class="close"><i class="fa fa-close"></i></button>
 
       </div>
       <div class="modal-body " >
         <div class="col-12 row">
           <div  class="form-check d-flex col-12 "  >
             <label   class="customLabel mt-2 col-6">Society/Building/Project Name :<span
               class="text-danger hideAsteriskIcon">*</span></label>
             <input  type="text"  class="form-control col-6" [(ngModel)]="projectName2"
               placeholder="Enter the Project name">
           </div>
           <div  class="form-check  d-flex col-12"  >
             <label   class="customLabel mt-2 col-6 ">Developer Name :<span
               class="text-danger hideAsteriskIcon">*</span></label>
             <input type="text"   class="form-control col-6"
               placeholder="Enter the Developer name" [(ngModel)]="DeveloperName2">
           </div>
           <div  class="form-check d-flex col-12"  >
             <label   class="customLabel mt-2  col-6">Full Address :<span
               class="text-danger hideAsteriskIcon">*</span></label>
               <input  [(ngModel)]="FullAdress2"  type="text"  class="form-control"   ngx-google-places-autocomplete #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)"
               placeholder="Enter the Full Address">
 
           </div>
           <div  class="form-check  d-flex col-12"  >
             <label   class="customLabel mt-2 col-6">City :<span
               class="text-danger ">*</span></label>
             <input [(ngModel)] = "citynamePro2"  readonly type="text"  class="form-control col-6" >
           </div>
 
           <div  class="form-check d-flex col-12"  >
             <label   class="customLabel mt-2 col-6">Pincode :<span
               class="text-danger ">*</span></label>
             <input [(ngModel)] = "pincodePro2"  type="text"   class="form-control col-6"
                >
               
           </div>
         </div>
         <div class="col-12 mt-2 d-flex justify-content-center">
 
           <button class="btn btn-pink px-3 py-2" [disabled]="projectName2 == '' || DeveloperName2 == '' ||  FullAdress2 == '' || pincodePro2 == ''   " (click)="Submitapart()">Submit</button>
 
         </div>
       </div>
     </div>
   </div>
 </div>