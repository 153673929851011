<app-header></app-header>

<div class="container-fluid">
    <div class="row justify-content-center">
        <div class="col-md-11 text-justify">
            <div class="p-3 m-3">
                <h3 class="brand-color">TERMS AND CONDITIONS</h3>
                <ol>
                    <li>
                        In this document, the term "Agreement" means these Terms & Conditions, the Confirmed Order and the Invoices, BOQs, designs any and all documents that set forth the understanding between the Parties with respect to the provision of the Goods and / or Services
                        by us to the Client (“Transaction Documents”);
                    </li>
                    <li>
                        The Client has engaged us for rendering advice and design services for home interior and manufacture & installation of the Goods and/or provision of the Services as per agreed final design. The Company has provided a quote of the Goods and/or Services
                        to be provided by the Company to the Client, recording the description, quantity, itemized price and total price, and the same has been accepted by the Client (“Confirmed Order”), basis which we have issued the Invoice(s) upon
                        the Client. The Confirmed Order contains the scope of work for the Goods and / or Services that will be provided by us, basis the final design signed off by the Client. Our relationship with the Client is governed by the Transaction
                        Documents, which shall also apply to any and all further orders placed upon us by the Client, notwithstanding any other document executed by the Client
                    </li>
                    <li>
                        The Client shall ensure that the site of installing the Goods and / or performance of Services is prepared and ready as per specification and on or before the time informed by us.
                    </li>
                    <li>
                        The Client may, from time to time, request a quote from us for the price of any further Goods and/or Services (“Quote Request”), which may be provided by us, alongwith necessary details (“Sales Quotation”). The Sales Quotation shall be open to variation,
                        until such time it has been accepted by the Client. Upon the Client‟s acceptance of the Sales Quotation, the obligations of the Parties in respect thereof shall be governed by the Transaction Documents.
                    </li>
                    <li>
                        The Client must, within the stipulated timelines, pay all amounts in Indian Rupees, through wire transfer, demand draft or cheque payable at Mumbai, drawn in favour of Singularity Furniture Private Limited or through any other banking channel. Payments
                        through credit card will attract an additional transaction fee of 2% of the amount being so paid.
                    </li>
                    <li>
                        The price quoted by us shall include, as applicable on the date of such quotation, (a) material and labor costs (escalation free till specified delivery date). In case of any delay beyond scheduled delivery date, the prices may increase; (b) GST; (c)
                        In the event of there being imported components of Goods which attract freight, additional insurance, agency fees, wharfage fees, storage charges, customs charges or duties, primage, telegraphic transfer selling rates or additional
                        costs of whatever nature, these costs are included in the price as at the date of our quotation(s). In the event the effectual provision of the Goods and/or Services takes place over a period of time and there occur variations
                        in the applicable rates, such variations will be added to the quoted price;
                    </li>
                    <li>Continued failure by you to pay the outstanding amount plus interest (if any) may lead to cancellation of the order and forfeiture of the amount paid by you, in addition to any other rights / remedies otherwise available to us following
                        such default.</li>
                    <li>
                        Delivery / Performance timelines: Delivery of the Goods and / or performance of the Services shall be within the timelines specified in the Confirmed Order(s), subject to (i) The design and specifications of the Goods and / or Services has been duly signed
                        by both Parties; (ii) The requisite advance for the Goods and / or Services has been paid by the Client; (iii) There have been no changes to the scope of work pursuant to the design and specifications of the Goods and / or Services
                        having been duly signed off by both Parties; and (iv) Readiness of Client‟s site in all aspects to do installation of Goods and / or performance of Services as per final design. If we are unable to complete delivery of the Goods
                        and / or performance of the Services with the time period conveyed to the Client (other than on account of any reason beyond our reasonable control), a grace period of 30 (thirty) days shall be available to us to complete the delivery
                        of the Goods and / or performance of the Services. In case of any delay for any force majeure reason, the timelines shall be extended by the period for which such force majeure reason causing delay is in force. Intimation for any
                        delay in installation of Goods / performance of Services within the time period shall be communicated by us.
                    </li>
                    <li>
                        Damages: (a) Any breakage or damages before and during installation is our responsibility and such parts will be replaced free of charge. In case the damaged items are small, replacement would be made within 45 days and if the items are large it would
                        take approx. 90 days; (b) Damages caused by water seepages, normal wear and tear and colour changes due to climatic conditions are not covered under our warranty; (c) Damages caused during storage at the Client‟s site / warehouse,
                        where the material has been shifted (at the request of the Client) will not be covered by the warranty; (d) Replacement of damaged material (post installation and handover) will be chargeable to the Client.
                    </li>
                    <li>
                        If there are any attendances, tests, manufacture of items and the like required in any transaction to be done to complete the effectual supply of the Goods and/or provision of Services e.g. site installations, testing, monitoring and the like, they: (a)
                        shall be detailed upon the Tax Invoice and shall be included in the quotation; (b) the Client agrees to provide and maintain at its own cost all necessary facilities, permits, approval, licences, services and the like that may
                        be required to enable the above to take place; (c) the Client shall ensure that we have access at all reasonable and necessary times to perform the work required to complete the effectual provision of the Goods and/or Services;
                        (d) the Client hereby indemnifies and shall keep us indemnified against any claim of whatever nature that may arise because of failure to maintain any facilities, etc. by the Client; and (e) the Client shall maintain any public
                        risk and other applicable insurances to cover the attendances and shall upon request provide a copy of the applicable policy or certificate of currency.
                    </li>
                    <li>
                        We may in our unfettered discretion extend credit to the Client, in which case the amounts due and payable by the Client to us shall be due and payable within the revised timelines stipulated by us.
                    </li>
                    <li>
                        Warranty: (a) Exactness with respect to the appearance of the final installed product compared with any previous diagrams, drawings, laminate colour samples, computer or 3-D rendition, et cetera is not guaranteed; (b) The Goods delivered and / or Service
                        rendered carry a limited warranty of ___ years for its „joinery‟, effective from the date of invoice; (c) The warranty for all third-party fittings like water taps, kitchen-sink, white goods and electrical/electronic appliances,
                        etc. procured for and on behalf of the Client shall be applicable as per the norms specified by the particular brand / manufacturer. Please note that all such appliances shall be procured on your behalf and in your name and you
                        shall alone be the sole owner thereof; (d) If during the ____ years‟ warranty period the delivered product fail to operate due to defect in manufacturing, we will, at our option, either repair or replace the Goods or part therein
                        in accordance with the following terms and conditions: (i) All the accessories which are procured by us carry warranty, on a back to back basis, of the period as assured by the respective reputed accessory manufacturer; and (ii)
                        We reserve the right to charge a handling fee and/or servicing fee if a returned Good is found to be not under warranty as per the following conditions: (1) Upon the Goods being identified by the Client as failing to operate under
                        normal use and service, the Client will inform us citing the failure of the condition; (2) The warranty does not cover any failure of the Goods arising out of normal wear and tear or due to misuse, including but not limited to
                        use in other than normal manner in accordance with the instructions provided during the handover; (3) The warranty does not cover any failure due to climate change, water-seepage, accident, modification, or adjustment, or acts
                        of God or damage resulting from misuse; (4) The warranty does not cover failure caused by installation, modification or repair or opening of the product performed by personnel other than that of the Company; and (5) The warranty
                        does not apply if the Goods have been placed outdoor or in a humid environment or if the Goods have been used for non-domestic purposes. EXCEPT TO THE EXTENT OF THE WARRANTY PROVIDED ABOVE, WE SPECIFICALLY DISCLAIM ALL WARRANTIES
                        AND INDEMNITIES, EXPRESS, IMPLIED OR STATUTORY, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR ANY WARRANTY ARISING IN THE COURSE OF SERVICE OR DEALING.
                    </li>
                    <li>
                        WE EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, CONTRACTUAL, STATUTORY OR OTHERWISE, IN LAW OR FROM A COURSE OF DEALING OR USAGE OF TRADE, TO THE FULLEST EXTENT PERMITTED BY LAW, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                        PURPOSE OR SECURITY. OUR LIABILITY FOR ANY AND ALL CLAIMS ARISING HEREUNDER, WHETHER IN CONTRACT, TORT, NEGLIGENCE OR OTHERWISE, SHALL NOT EXCEED THE AGGREGATE AMOUNT OF FEES PAID BY CLIENT TO US UNDER THE APPLICABLE TRANSACTION
                        DOCUMENT DURING THE SIX (6) MONTHS PRECEDING THE CLAIM. IN NO EVENT SHALL WE BE LIABLE FOR ANY (I) LOSS OF DATA, (II) LOSS OF PROFITS, (III) LOSS OF SALES, (IV) LOSS OF OR DAMAGE TO BUSINESS, (V) LOSS OF CUSTOMERS, (VI) BUSINESS
                        INTERRUPTION, (VII) REPLACEMENT SERVICES OR (VIII) ANY OTHER SPECIAL, INCIDENTAL, CONSEQUENTIAL PUNITIVE OR INDIRECT DAMAGES, HOWSOEVER CAUSED AND REGARDLESS OF THEORY OF LIABILITY.
                    </li>
                    <li>
                        If the Client defaults in the due observance or performance of any or all of its obligations hereunder or any of the events relating to its insolvency occur, then, without prejudice to any other rights or remedies that may be available to us, we may (a)
                        terminate this Agreement by written notice to the Client, in which case this Agreement will terminate on the date specified in our written notice or, if no date is specified, immediately; (b) refuse to complete delivery of Goods
                        and/or Services and/or suspend manufacture or delivery, installation, of any Goods and/or Services then outstanding; (c) retain any security given or monies paid by the Client and apply such amounts towards the assessed loss or
                        damages incurred by us owing to the default or other act on the part of the Client; (d) enter upon the Client‟s premises or elsewhere to take possession of and/or remove any Goods and/or Services that we may have supplied; (e)
                        take such steps as we deem necessary to mitigate our damages including putting to use, hiring out, selling or disposal of any Goods and/or Services supplied or to be supplied to the Client pursuant to this Agreement.
                    </li>
                    <li>
                        Termination of Confirmed Order: (a) Confirmed Order(s) once accepted are expected to be non-cancellable. However, after the start of the work, if the Client cancels or purports to cancel his Confirmed Order for any reason whatsoever, then the monies already
                        paid by the Client shall stand forfeited by us and will not be refunded, Further, the Client shall also be liable to pay for such charges, as determined by us, for all costs borne for materials ordered and services provided under
                        the relevant Confirmed Order till the relevant date. If we cancel the Confirmed Order unilaterally for none of the fault of the Client, then our liability shall be limited to only refund the amount actually paid by the Client to
                        us, subject to deduction of the value of the Goods and / or Services already provided / rendered by us. Under no circumstance, the Company shall refund the amount of professional fee paid to the architect with respect to the order
                        of the Client. Any amendments to Confirmed Order must also be agreed by us in writing and are subject to the same conditions covering cancellation; and (b) Termination in relation to any particular Goods and/or Services shall not
                        terminate the entire Confirmed Order. Either Party may terminate a Confirmed Order if the other Party materially breaches the Transaction Documents and such breach continues un-remedied for thirty (30) days following notice for
                        cure of such breach. The Company may immediately suspend all works under the Confirmed Order, is undisputed payments are not received within thirty (30) days of payment due date, until all outstanding payments are received by the
                        Company.
                    </li>
                    <li>
                        Title to Goods under Confirmed Orders: (a) We retain title in the Goods delivered under this Agreement until the Client has paid the entire price payable to us by the Client in terms of such Confirmed Orders; (b) We may allocate payments made by the Client
                        to us under the Transaction Documents to any obligation owed by the Client to us; (c) Once the Client takes possession of the Goods (whether or not the title to the Goods has passed on to t the Client), the Client must store the
                        Goods separately from other goods of the Client, so that the Goods are not mixed with those other goods and in such a way that the Goods and/or Services are recognizable as the property of Company; (d) Risk in the Goods and/or
                        Services passes to the Client when the Goods and/or Services are delivered to the Client and the Client must adequately protect the Goods and/or Services, including by way of insurance; (e) While the Goods remain the property of
                        Company, the Client must not sell, lease, or otherwise dispose of the Goods; and (f) While the Goods and/or Services delivered under the Transaction Documents remain the property of Company, the Client must not grant or allow anyone
                        else to hold any interest in: (i) the Goods and/or Services; (ii) the proceeds of the Goods and/or Services; or (iii) any place to which the Goods and/or Services are installed or affixed.
                    </li>
                    <li>
                        It is a condition of the Transaction Documents that the Client shall comply with the conditions contained in the Transaction Documents at all times. If the Client fails to comply with any obligation under the Transaction Documents, or an insolvency event
                        occurs in relation to the Client, then without limiting the remedies available to the Company: (a) upon request by Company, the Client must immediately return the Goods and/or Services on which there are outstanding amounts owing;
                        (b) the Client authorises Company and any person authorised by Company to enter premises where the Goods and/or Services may be located to take possession of the Goods and/or Services; and (c) Company may retain, sell or otherwise
                        dispose of the Goods and/or Services.
                    </li>
                    <li>
                        Acceptance by the Client shall be conclusive evidence of the delivery of the Goods and/or Services to the Client and we shall not be called upon to prove that our Goods and/or Services were actually received by the Client.
                    </li>
                    <li>
                        After delivery and acceptance of the Goods and/or Services to the Client, we will not accept the Goods being returned for any reason, unless we give the Client prior written permission for the same, and such return is at the cost and expense of the Client.
                        Request to return Goods must be made within seven (7) days from receipt of Goods and/or Services and a re-stocking fee may apply should said request to return Goods be approved.
                    </li>
                    <li>
                        The Parties agree that any disputes arising out of or related to this Agreement, shall be governed by, construed, and enforced in all respects in accordance with the laws of India and each Party irrevocably agrees that the courts of Mumbai shall have
                        exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with this Agreement or its subject matter or formation (including non-contractual disputes and claims).
                    </li>
                    <li>
                        Notices: (a) Service of any document or notice in connection with any matter arising from the Transaction Documents shall be sufficient if sent by either Party to the other by pre-paid post to the last known address of the other party, and for the purposes
                        of noting the date of such service, such service shall be deemed to have been effected forty-eight (48) hours after such notice has been posted to the receiving party, unless the contrary is proved.
                    </li>
                    <li>
                        Force Majeure: We shall not be held in breach or liable due to any delay caused by break down or damage of our machinery, shortages or non-availability of raw materials, components or consumables etc., strikes, lockout, war, riot, civil commotion, commercial
                        disturbances or acts of God or other causes beyond our control.
                    </li>
                    <li>
                        Intellectual Property Rights: The designs, Goods manufactured and the Services provided are purely for the personal use of the Client and any reproduction of the same for commercial purposes will amount to infringement of our IP rights.
                    </li>
                    <li>
                        Waiver of our rights in a particular circumstance will not affect our full rights and remedies.
                    </li>
                    <li>
                        The Client cannot assign or novate its rights and obligations under this Agreement without our prior written consent.
                    </li>
                    <li>
                        Nothing in this Agreement shall constitute a partnership or JV between us nor constitute any party the agent of the other, or otherwise entitle any party to have authority to bind the other for any purpose.
                    </li>
                    <li>
                        We at arrivae do not process any loans ourselves. It is done through our banking partners.
                    </li>
                </ol>
            </div>
        </div>
    </div>
</div>
<app-aboutusone></app-aboutusone>
<app-aboutustwo></app-aboutustwo>
<app-footer></app-footer>