<app-header></app-header>
<div class="container-fluid profile">
    <div class="mob-none row justify-content-center my-lg-n5">
      <div class="col-md-10 col-12 breadcrumb-style" style="top: 439px;">
       <a href="/landing" class="text-decoration-none"><span class="home-sec">HOME >> </span></a> 
       <a><span class="space-sec">STORES</span></a>
          </div>
      </div>  
  </div>
<div class="container-fluid items-carous">
    <div class="d-flex justify-content-center pt-4 pb-4">
        <span class="titleTriangle"
          >OUR PRESENCE
          <div class="mask"></div
        ></span>
        <img
           src="../assets/images/Fill-117.svg"
           class="smallLogoIcon zI1"
           alt="vwuy"
        />
    </div>
</div>
<div class="container-fluid items-carous">
<div class="row justify-content-center">
    <div class="col-md-4">
      <div class="row">
        <div class="col-md-12">
        <p class="text-center"
      >Walk-In to our nearest center<br>
      Our Timings: 11AM to 9PM
      </p>
      </div>
      </div>
  </div>
  </div>
  <div class="row justify-content-center alignColumn">
      <div class="col-md-10">
          <div class="row">
              <div class="col-md-2">
                  <img src="../../../assets/images/Bangalore.png">
                  <p class="city">Bangalore</p>
              </div>
              <div class="col-md-2">
                  <img src="../../../assets/images/pune.png">
                  <p class="city">Pune</p>
              </div>
              <div class="col-md-2">
                  <img src="../../../assets/images/Mumbai.png">
                  <p class="city">Mumbai</p>
              </div>
              <div class="col-md-2">
                  <img src="../../../assets/images/Delhi.png">
                  <p class="city">Delhi</p>
              </div>
              <div class="col-md-2">
                  <img src="../../../assets/images/Chandigarh.png">
                  <p class="city">Chandigarh</p>
              </div>
              <div class="col-md-2">
                  <img src="../../../assets/images/Cochin.png">
                  <p class="city">Cochin</p>
              </div>

          </div>
      </div>
  </div>
  <div class="row justify-content-center alignColumn">
    <div class="col-md-10">
        <div class="row">
            <div class="col-md-2">
                <img src="../../../assets/images/Trivandrum.png">
                <p class="city">Trivandrum</p>
            </div>
            <div class="col-md-2">
                <img src="../../../assets/images/Ahmedabad.png">
                <p class="city">Ahmedabad</p>
            </div>
            <div class="col-md-2">
                <img src="../../../assets/images/Nashik.png">
                <p class="city">Nashik</p>
            </div>
            <div class="col-md-2">
                <img src="../../../assets/images/Jaipur.png">
                <p class="city">Jaipur</p>
            </div>
            <div class="col-md-2">
                <img src="../../../assets/images/Lucknow.png">
                <p class="city">Lucknow</p>
            </div>
        </div>
    </div>
</div>
</div>
<div class="container-fluid items-carous">
<div class="row justify-content-center alignColumn">
    <div class="col-md-10">
        <div class="row">
            <div class="col-md-4">
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Enter Your City or Pin Code">
                    <div class="input-group-append">
                      <button class="btn btn-secondary" type="button" (click)="getLocation()">
                        <!-- <i class="fa fa-search"></i> -->  
                          <img src="../../../assets/images/crosshairs-gps (1).png" />
                          
                      </button>
                    </div>
                  </div>
                  <div class="card text-left mx-0 my-2">
                    <div class="row no-gutters">
                      <div class="col-md-4">
                        <img src="" onerror="src='../../../assets/images/default-apartment.png'" class="card-img" alt="...">
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Arrivae - Kirti Nagar</h5>
                          <div class="card-text">
                            <p>  Ground floor, 412, K-1 Building Near Petrol Pmp, Kirti Nagar, New Delhi </p>
                            <p class="pcard"><span> Open Days:</span> Mon - Sun </p>
                            <p class="pcard"><span> Working Hours:</span> 11AM - 8AM </p>
                            <p class="pcard"><span>  Contact No.: </span>9582 XXX XXX</p>
                            <a href="#" class="button">Send Location</a>
                            <a href="#" class="button1">Book Appointment</a>
                        </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="card text-left mx-0 my-2">
                    <div class="row no-gutters">
                      <div class="col-md-4">
                        <img src="" onerror="src='../../../assets/images/default-apartment.png'" class="card-img" alt="...">
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Arrivae - Kirti Nagar</h5>
                          <div class="card-text">
                            <p>  Ground floor, 412, K-1 Building Near Petrol Pmp, Kirti Nagar, New Delhi </p>
                            <p class="pcard"><span> Open Days:</span> Mon - Sun </p>
                            <p class="pcard"><span> Working Hours:</span> 11AM - 8AM </p>
                            <p class="pcard"><span>  Contact No.: </span>9582 XXX XXX</p>
                            <a href="#" class="button">Send Location</a>
                            <a href="#" class="button1">Book Appointment</a>
                        </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="card text-left mx-0 my-2">
                    <div class="row no-gutters">
                      <div class="col-md-4">
                        <img src="" onerror="src='../../../assets/images/default-apartment.png'" class="card-img" alt="...">
                      </div>
                      <div class="col-md-8">
                        <div class="card-body">
                          <h5 class="card-title">Arrivae - Kirti Nagar</h5>
                          <div class="card-text">
                            <p>  Ground floor, 412, K-1 Building Near Petrol Pmp, Kirti Nagar, New Delhi </p>
                            <p class="pcard"><span> Open Days:</span> Mon - Sun </p>
                            <p class="pcard"><span> Working Hours:</span> 11AM - 8AM </p>
                            <p class="pcard"><span>  Contact No.: </span>9582 XXX XXX</p>
                            <a href="#" class="button">Send Location</a>
                            <a href="#" class="button1">Book Appointment</a>
                        </div>
                        </div>

                      </div>
                    </div>
                  </div>
                 
            </div>
            <div class="col-md-8">
               <!--- <img src="../../../assets/images/image 85.png" class="w-100"> -->
              <!---- <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d248849.88654003857!2d77.49085274762702!3d12.953959987171352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1670c9b44e6d%3A0xf8dfc3e8517e4fe0!2sArrivae
               %2C%20Karnataka!5e0!3m2!1sen!2sin!4v1612161733416!5m2!1sen!2sin"
                width="755" height="591" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0">
              </iframe>-->
              <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1x9ga9LqvffpGwvbReC6-QVBP9ZwQOolR" width="755" height="591" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0">
              </iframe>
            </div>
        </div>
    </div>
</div>
</div>
<div class="container-fluid items-carous-1">
    <div class="d-flex justify-content-center pt-4 pb-2">
        <span class="titleTriangle"
          >MEET OUR TEAM
          <div class="mask2"></div
        ></span>
        <img
           src="../assets/images/Fill-117.svg"
           class="smallLogoIcon zI1"
           alt="vwuy"
        />
    </div>
    <div class="row justify-content-center">
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-12">
            <p class="text-center">Visit our experience studio and start with your dream home. Get specialised consultancy absolutely free of cost. Our designer understand your thought process and define design elements made by you.</p>
          </div>
          </div>
      </div>
      </div>
     <div class="row justify-content-center alignColumn">
         <div class="col-md-10">
             <div class="row">
                 <div class="col-md-3">
                    <img src="../../../assets/images/shutterstock_229613626 1.png" class="border">
                    <h6 class="htext">Checkout Spaces</h6>
                    <p class="ptext">Get design inspiration for different rooms.</p>
                 </div>
                 <div class="col-md-3">
                   <img src="../../../assets/images/shutterstock_1737919856 1.png" class="border">
                   <h6  class="htext">Checkout Material</h6>
                   <p class="ptext">Get a touch and feel of fabric, finish & material.</p>
                 </div>
                 <div class="col-md-3">
                    <img src="../../../assets/images/shutterstock_589810583 1.png" class="border">
                    <h6  class="htext">Consult Designer</h6>
                    <p class="ptext">Discuss and finalise scope of work.</p>
                 </div>
                 <div class="col-md-3">
                    <img src="../../../assets/images/shutterstock_189590255 1.png" class="border">
                    <h6  class="htext">Meet Our Team</h6>
                    <p class="ptext">Have a chat with our execution team.</p>
                 </div>

             </div>
         </div>
     </div>

</div>
<div class="container-fluid items-carous">
    <div class="d-flex justify-content-center pt-4 pb-4">
        <span class="titleTriangle"
          >WHY CHOOSE US
          <div class="mask"></div
        ></span>
        <img
           src="../assets/images/Fill-117.svg"
           class="smallLogoIcon zI1"
           alt="vwuy"
        />
    </div>
    <div class="row justify-content-center alignColumn">
        <div class="col-md-10">
            <div class="row justify-content-around">
                <div class="col-md-2">
                    <img src="../../../assets/images/Group.png" class="d-flex">
                    <div class="text-left">
                        <h4 class="num">12500</h4>
                        <p class="par mt-sm-n3">Project deliver</p>
                    </div>
                     
                </div>
                <div class="col-md-2">
                    <img src="../../../assets/images/Group 289.png" class="d-flex">
                    <div class="text-left">

                    <h4 class="num">19 Cities</h4>
                        <p class="par mt-sm-n3">and counting</p>
                        </div>
                </div>
                <div class="col-md-2">
                    <img src="../../../assets/images/Group 290.png" class="d-flex">
                    <div class="text-left">

                    <h4 class="num">1 Million</h4>
                       <p class="par mt-sm-n3">Designs</p>
                       </div> 
                </div>
                <div class="col-md-2">
                    <img src="../../../assets/images/Group_2.png" class="d-flex">
                    <div class="text-left">

                    <h4 class="num">45 Days</h4>
                      <p class="par mt-sm-n3">Delivery</p>  
                    </div>
                </div>
                <div class="col-md-2">
                    <img src="../../../assets/images/Group 291.png" class="d-flex">
                    <div class="text-left">
                    
                    <h4 class="num">10 Years</h4>
                      <p class="par mt-sm-n3"> Warranty</p>
                      </div> 
                </div>

            </div>
        </div>
    </div>

</div>

<app-aboutusone></app-aboutusone>
<app-aboutustwo></app-aboutustwo>
<app-footer></app-footer>
