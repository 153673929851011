<app-new-header-for-home ></app-new-header-for-home>
<div class="root">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-auto divcontainer">
                <div class="row my-4 align-items-center">
                    <div class="col-md-12 col-sm-12">
                        <label class="ml-2 mb-0 search">Search</label>
                        <div class="input-container">
                            <i class="fa fa-map-marker icon mapmarker"></i>
                            <select [(ngModel)] = "city_value" class="border-0 text-capitalize allcities" (change)="cityFilter($event)">
                                <option [value] = "choose_city" aria-readonly="true">All</option>
                            <option *ngFor="let item of citydata1 ; let j = index"   [value]="item.id">{{transform(item.attributes.name)}}</option>
                            </select>
                            <div class="input-container">
                                <i class="fa fa-search icon border-left" aria-hidden="true"></i>
                                <input #getVaule class="form-control border-0 searchinput d-md-block d-none" type="text"
                                    placeholder="Search for a location by area or PIN"
                                      [(ngModel)] = 'search_string'  >
                                <input (click)="submitValue(getVaule.value)" type="button" value="Search"
                                    class="d-md-block d-none searchBtn">
                                <input #mobileValue class="form-control border-0 searchinput d-sm-block d-md-none"
                                    type="text" placeholder="Search" [(ngModel)] = 'search_string'>
                                <button (click)="GoButtonClick(mobileValue.value)" type="submit"
                                    class="d-lg-none d-block mobile-btn">Go</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="EachCenter && EachCenter.length > 0 " class="col-12 justify-content-center d-flex viewall font-weight-bold">
        View all {{EachCenter.length}} locations
    </div>

    <div *ngIf="EachCenter && EachCenter.length === 0 " class="col-12 justify-content-center d-flex viewall font-weight-bold">
        <p class="mb-0">0 Location in <span style="text-transform: capitalize;">{{propValue}}</span> 
            <span *ngIf="displayValue">,{{displayValue}}</span>
        </p>
    </div>
    <div class="container-fluid mb-lg-5">
        <div class="row mt-4 justify-content-center">
            <div class="col-md-10 col-sm-12 col-lg-10">
                <div class="row">
                    <div *ngFor="let item of EachCenter" class="col-md-4 col-lg-4 col-sm-12 px-0 px-lg-3">
                        <div class="card mb-5">
                            <div class="imageContainer">
                                <img *ngIf="item.attributes.image !== null && item.attributes.image !== '' " src="{{item.attributes.image}}" class="d-block w-100"
                                     style="object-fit: cover;height:100%">
                                <img *ngIf="item.attributes.image === null" src="../../../assets/images/Visit-Us-Page_logo.png" class="d-block w-100 px-lg-2 px-4 error-img"
                                style="object-fit: cover;height:100%">
                            </div>
                            <div class="mt-3 margin-left">
                                <div class="first">
                                    <div class="location">
                                     <p class="mb-0"> {{item.attributes.store_name}}</p>
                                    </div>
                                    <span class="mx-2  circle-1"></span>
                                    <!-- <div>
                                        <p class="city mb-0">{{item.attributes.store_name | slice:7}}</p>
                                    </div> -->
                                </div>
                                <div class="address">
                                    <p>{{item.attributes.store_address}}</p>
                                </div>
                                <div class="mb-3" *ngIf="item.attributes.description">      
                                    <p>{{item.attributes.description}}</p>
                                </div>
                                <div class="row mt-n2 ">
                                    <div>
                                        <span class="ml-3"><img src="../../../assets/images/VisitUs Icon.png"></span>
                                        <a [href]="item.attributes.map" target="_blank" class="getdirections ml-1">Get Directions</a>
                                    </div>
                                     <a *ngIf="item.attributes.store_address.includes('VR Bengaluru') || item.attributes.store_address.includes('Gurugram') " class="viewmore ml-5" (click)="openModal(item.attributes.store_name,item.attributes.store_address)" >View Store</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container" *ngIf=" EachCenter && EachCenter.length===0">
        <!-- <div class="col-12 justify-content-center d-flex viewLoc font-weight-bold">
            0 Locations in Bengaluru, Koramangla
        </div> -->

        <div class="col-12 justify-content-center d-flex">
            <img src="../../../assets/images/Location.png">
        </div>
        <div class="justify-content-center d-flex mt-3">
            <p class="col-md-3 para">Sorry, we have no locations in your searched area. You can try checking out our
                other
                locations in -</p>
        </div>
        <div class="justify-content-center d-flex mt-2">
            <a (click) = "location('12' , 'Hyderabad')" class="modernVibes px-2 py-1 mr-2 font-weight-bold">Hyderabad</a>
            <a (click) = "location('22' , 'Delhi')" class="modernVibes px-2 py-1 mr-2 font-weight-bold">New Delhi</a>
            <a (click) = "location('2' , 'Pune')" class="modernVibes px-2 py-1 mr-2 font-weight-bold">Pune</a>
        </div>
        <div class="justify-content-center d-flex mt-3 mb-5">
            <a (click) = "location('1' , 'Mumbai')" class="modernVibes px-2 py-1 mr-2 font-weight-bold">Mumbai</a>
        </div>
    </div>
    <!-- <div class="mb-lg-5">
    </div> -->
    <!-- <app-footer></app-footer> -->
    <app-footer></app-footer>

</div>
<div class="loading d-flex justify-content-center" *ngIf="loader">
    <div class="spinner-border" style="color: #8d0528;" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div id="myModal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{modaltitle}}</h5>

          <button type="button" (click)="closeModal()" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="w-100" *ngIf = "allVideos">
            <iframe allow="autoplay; muted" class="w-100" width="900" height="500" [src]="current_url" frameborder="0" allowfullscreen>
            </iframe>
            
         </div>
        </div>
        
      </div>
    </div>
  </div>
  