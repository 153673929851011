<div class=" how-it-works23  mx-3 mx-md-5 ">
   <div class="row  pt-lg-5 mobile-padding  ">
      <div class="main-heading col-12   ">
         <p class="mb-1 "><span class="pb-1">HOW ARRIVAE</span>  WORKS</p>
         <span>Your dream home journey in just 5 steps</span>      
      </div>

      <div  *ngIf="roletoSet() >= 650"  class="try-it col-12 d-flex  justify-content-md-start  mx-3 p-0 ">
         <button type="button" (click)="bookConsultationLandingPage() " class=" button d-flex align-items-center mt-2">TRY IT TODAY &nbsp;
           <span>
              <img src="../../../assets/images/Arrow 1.png"/>
           </span>
         </button>
      </div>

      <div class="blogs col-12  mt-4">    
         <div class="row justify-content-center p-0">
            <div class="col-12 col-md-10">
               <div *ngIf="roletoSet()>=650" class="Buildings_container justify-content-center row py-4 ">
                  <div class="cursor-pointer ">   
                     <a class="d-flex">
                        <div class=" box border1">
                           <img class=" image_carrier d-flex align-items-end" src='../../../assets/images/How-it-works/Layer 64.png'>
                        </div>
                        <div class="background align-self-center">
                           <div class="image justify-content-center align-self-center d-flex">
                              <img class=" align-self-center " src="../../../assets/images/icons/Arrow 7.png" alt="">
                           </div>
                        </div>                    
                     </a>

                     <div class="steps d-flex align-items-center mt-2 mb-md-3 mb-0">
                        <span class="index">1</span>
                        <p class="pl-2">{{ newLandingPage() ? 'Meet the Designer' : 'Meet The Designer' }}<p>
                     </div>

                  </div>

                  <div class=" cursor-pointer">  
                     <a class="d-flex">
                        <div class=" box reverse">
                           <img class=" image_carrier d-flex align-items-end" src='../../../assets/images/How-it-works/Layer 65.png'>
                        </div>
                        <div class="background align-self-center">
                           <div class="image justify-content-center align-self-center d-flex">
                              <img class=" align-self-center " src="../../../assets/images/icons/Arrow 7.png" alt="">
                           </div>
                        </div>   
                     </a>
                     <div class="steps d-flex align-items-center mt-2">
                        <span class="index">2</span>
                        <p class="pl-2">Design Finalisation<p>
                     </div>
                  </div>
                  <div class=" cursor-pointer">  
                     <a class="d-flex">
                        <div class=" box border1">
                           <img class=" image_carrier d-flex align-items-end"
                              src='../../../assets/images/How-it-works/Layer 66.png'>
                        </div>
                        <div class="background align-self-center">
                           <div class="image justify-content-center align-self-center d-flex">
                              <img class=" align-self-center " src="../../../assets/images/icons/Arrow 7.png" alt="">
                           </div>
                        </div> 

                     </a>
                     <div class="steps d-flex align-items-center mt-2">
                        <span class="index">3</span>
                        <p class="pl-2">Place the Order<p>
                     </div>

                  </div>
                  <div class=" cursor-pointer">
                     <a class="d-flex">
                        <div class=" box reverse">
                           <img class=" image_carrier d-flex align-items-end"
                              src='../../../assets/images/How-it-works/Layer 67.png'>
                          </div>
                          <div class="background align-self-center">
                           <div class="image justify-content-center align-self-center d-flex">
                              <img class=" align-self-center " src="../../../assets/images/icons/Arrow 7.png" alt="">
                           </div>
                        </div> 
                     </a>
                     <div class="steps d-flex align-items-center mt-2">
                        <span class="index">4</span>
                        <p class="pl-2">Factory Production and Installation<p>
                     </div>
                  </div>
                  <div class=" pr-0 cursor-pointer">   
                     <a class="d-flex">
                        <div class=" box border1">
                           <img class=" image_carrier d-flex align-items-end"
                              src='../../../assets/images/How-it-works/Layer 68.png'>
                          </div>

                     </a>
                     <div class="steps d-flex align-items-center mt-2">
                        <span class="index">5</span>
                        <p class="pl-2">Handover and Move-in<p>
                     </div>
                  </div>
               </div>

               <div *ngIf="roletoSet()<650" class="Buildings_container w-100">
                  <div class="row d-flex align-items-center justify-content-center">
                     <div class="col-1 navigations">
                        <div class="">
                           <div class="other-mobile-boqs"  >
                             <button [ngClass]="slickIndex == 0 ?'d-none':''"   class="mx-1 prev_icon button-one" (click)="slickController.prev()">
                              <img class=" align-self-center " src="../../../assets/icons/Group 9.png" alt="" style="rotate: 180deg;">
                             </button>
                       </div>   
                     </div>
                     </div>
                     <div class="col-10">
                        <div class="mobile-carosel-active-boqs w-100">
                           <div slickContainer #slickController="slick" [slickConfig]="config">
                             <ng-container *ngIf="slickController.initialize">
                                 <div slickItem class="align-items-center d-flex flex-column  ml-lg-12 justify-content-center mobile_image">
                                    <div class=" box border1">
                                       <img class=" image_carrier d-flex align-items-end" src='../../../assets/images/How-it-works/Layer 64.png'>
                                    </div>
                                  <!-- <div class="small_box "> MEET THE DESIGNER</div> -->
                                    <div class="steps d-flex align-items-center ">
                                      <span class="index">1</span>
                                       <p class="pl-2">{{ newLandingPage() ? 'Meet the Designer' : 'Meet The Designer' }}<p>
                                    </div>
                                 </div>
                                 <div  slickItem class="align-items-center d-flex flex-column  ml-lg-12 justify-content-center mobile_image" >
                                    <div class=" box reverse">
                                       <img class=" image_carrier d-flex align-items-end" src='../../../assets/images/How-it-works/Layer 65.png'>
                                    </div>
                                    <!-- <div class="small_box ">DESIGN FINALISATION </div> -->
                                    <div class="steps d-flex align-items-center ">
                                       <span class="index">2</span>
                                       <p class="pl-2">Design Finalisation<p>
                                    </div>
                                 
                                 </div>
                              
                                 <div slickItem class="align-items-center d-flex flex-column  justify-content-center mobile_image">
                                    <div class=" box border1">
                                       <img class=" image_carrier d-flex align-items-end" src='../../../assets/images/How-it-works/Layer 66.png'>
                                    </div>            <!-- <div class="small_box "> PLACE THE ORDER </div> -->
                                    <div class="steps d-flex align-items-center ">
                                       <span class="index">3</span>
                                       <p class="pl-2">Place the Order<p>
                                    </div>
                                 
                                 </div>
                              
                                 <div slickItem class="align-items-center d-flex flex-column justify-content-center mobile_image">
                                    <div class=" box border1">
                                       <img class=" image_carrier d-flex align-items-end" src='../../../assets/images/How-it-works/Layer 67.png'>
                                    </div>
                                    <!-- <div class="small_box "> FACTORY PRODUCTION AND INSTALLATION </div> -->
                                    <div class="steps d-flex align-items-center ">
                                       <span class="index">4</span>
                                       <p class="pl-2">Factory Production and Installation<p>
                                    </div>
                                 
                                 </div>
                              
                                 <div slickItem class="align-items-center d-flex flex-column  justify-content-center mobile_image">
                                    <div class=" box border1">
                                       <img class=" image_carrier d-flex align-items-end" src='../../../assets/images/How-it-works/Layer 68.png'>
                                    </div>            <!-- <div class="small_box pl-2 "> HANDOVER AND <br> MOVE-IN </div> -->
                                    <div class="steps d-flex align-items-center ">
                                       <span class="index">5</span>
                                       <p class="pl-2">Handover and Move-in<p>
                                    </div>
                                 
                                 </div>
                              
                              </ng-container>
                           </div>
                        </div>
                     </div>
                     <div class="col-1 navigations " >
                        <div class=" float-right">
                           <div class="other-mobile-boqs"  >  
                             <button [ngClass]="slickIndex == 4 ?'d-none':''"  class="mx-1 button-one" (click)="slickController.next()">
                                 <img class=" align-self-center " src="../../../assets/icons/Group 9.png" alt="">
                             </button>
                        </div>
                  </div>
                </div>
            </div>
         </div> 
            </div>
         </div> 
         
      
      
      </div>
      
      <div  *ngIf="roletoSet() < 650" class="try-it col-12 d-flex justify-content-sm-center justify-content-md-start  mx-md-3 p-0 ">
         <button *ngIf ="!newLandingPage()" type="button" (click)="bookConsultationLandingPage() " class=" button d-flex align-items-center">TRY IT TODAY &nbsp;
           <span>
              <img src="../../../assets/images/Arrow 1.png"/>
           </span>
         </button>
      </div>

   </div>
</div>


<div *ngIf ="!newLandingPage()" class=" Logos   mt-3 mt-md-0">
   <div class="   mobile-padding  ">
      <div class="main-heading  mx-3 mx-md-5  justify-content-sm-center ">
         <p class="mb-1 "><span class="pb-1">OUR PARTNERS</span></p>
         <span>Our esteemed partnerships</span>    
      </div>

      <a  class="view-more d-none d-md-block  mx-md-5 p-0" routerLink='/apartment' target="_blank">
         <button type="button"  class=" button d-flex align-items-center mt-2">VIEW MORE &nbsp;
           <span>
              <img src="../../../assets/images/Arrow 1.png"/>
           </span>
         </button>
      </a>  

      <div class="row align-items-center py-5 mx-0">
         <div class="col-1 prev">
            <div class="">
               <div class="other-mobile-boqs"  >
                 <button   class="mx-lg-1 button-one" (click)="slickController.prev()">
                   <img class="cursorPointer"  src="../../assets/images/sign-up-to-service/Arrow copy 2 2.png" alt="previous" >
               </button>
               </div>
           </div>   
         </div>

         <div class="logo col-10  ">
            <div  class="mx-md-5 row d-flex align-items-center justify-content-center  mx-0">
               <div slickContainer #slickController="slick" [slickConfig]="config2" class="w-100">
                  <div *ngIf="slickController.initialize">
                     <div class=" "  *ngFor="let logo of Logos">
                        <div slickItem class="col-lg-2 col-md-2 align-items-center d-flex flex-column  justify-content-center image" ><img src="{{logo}}" alt=""></div>
                     </div>

                  </div>
               </div>
            </div>
         </div>

         <div class="col-1 next">
            <div class="float-right  ">
               <div class="other-mobile-boqs"  >  
                 <button  class="mx-lg-1 button-one" (click)="slickController.next()">
                   <img class="cursorPointer" src="../../assets/images/sign-up-to-service/Arrow copy 2 1.png" alt="next" >
                  </button>                           
               </div>
            </div>         
         </div>
      </div>
      

      <a  class="view-more col-12  d-block d-md-none d-flex justify-content-center pb-3 " routerLink='/apartment' target="_blank">
         <button type="button"  class=" button d-flex align-items-center mt-4">VIEW MORE &nbsp;
           <span>
              <img src="../../../assets/images/Arrow 1.png"/>
           </span>
         </button>
      </a> 

   </div>   
</div>


 