import { Component, OnInit , ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BookConsultationFormComponent } from 'src/app/shared/book-consultation-form/book-consultation-form.component';
import { SharedService } from 'src/app/shared/shared.service';
import { SpaceService } from 'src/app/spaces/space.service';
import { ApartmentService } from '../apartment.service';
import { Location } from '@angular/common';
import {  Meta  } from '@angular/platform-browser';
import { element } from 'protractor';
import { Slick , SlickContainerDirective } from 'ngx-slickjs';
import { DomSanitizer } from "@angular/platform-browser";
import { ApartmentGetThisDesignFormComponent } from '../apartment-get-this-design-form/apartment-get-this-design-form.component';
declare var $:any;
declare function searchApartment(param1: any, param2:any): any;
declare function apartmentClicked1(
  param1: any,
  param2: any,
  param3: any
): any;
@Component({
  selector: 'app-updated-apartment-page',
  templateUrl: './updated-apartment-page.component.html',
  styleUrls: ['./updated-apartment-page.component.scss'],
})
export class UpdatedApartmentPageComponent implements OnInit {
  @ViewChild('slickModal')
  slickController!: SlickContainerDirective;

  gallerydata: any;
  gallerydataApartment: any;
  searchApartment = '';
  totalApartmentdata: any;
  placeholdervalue: any;
  per_page: any = 6;
  selectedCity: any;
  serachedspace: any = '';
  final_price: any = [];
  tabvalues: any = 'All APARTMENTS';
  loader: boolean = false;
  searchcity: any = '';
  serachedapart: any = '';
  finalcity: any = '';
  unid: any;
  lifeStage_filter_value1: any;
  current_page: any = '1';

  constructor(
    private apartmentservice: ApartmentService,
    private router: Router,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private sharedService: SharedService,
    public spaceService: SpaceService,
    private location: Location,
    private route: ActivatedRoute,
    private metaService: Meta,
    private _DomSanitizationService: DomSanitizer,
  ) {}


  listingCityFilter : any =  [ 'bangalore' ,'mumbai','navi mumbai','pune']


  ngOnInit(): void {
    this.current_url=this._DomSanitizationService.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/ljhxUFH01Uw')
    this.cityLoader = true
    this.moreLoader = false
    window.scrollTo(0, 0);
    this.selectedCity = localStorage.getItem('selectedCity');
    this.getCities();
    this.getFilterData();
    this.route.queryParams.subscribe((params) => {
      (this.searchcity = params['city']),
        (this.serachedapart = params['search']);
    });
    this.metaService.addTag({
      name: 'description',
      content:
        'Search your apartment listing on Arrivae for ready interior design solutions. Get your design and packages customized. Budget Home Interiors. Customized Home Interior Solutions | Low-cost EMIs | Flexible EMI Plans | No Cost EMIs Available* | Robust Aftersales Support',
    });
    this.getCityWiseData()
    this.loadScript();
  }


  config: Slick.Config = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 2,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2500,
    draggable: true,
    mouseWheelMove: false,
    arrows: true,
    responsive: [

      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          autoplay: true

        },
      },
      
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          autoplay: true
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          autoplay: true
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          autoplay: true

        },
      },
    ],
  };

  config2: Slick.Config = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    dots: false,
    autoplay: true,
    autoplaySpeed: 2000,
    draggable: true,
    mouseWheelMove: false,
    arrows: true,
    responsive: [

      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows : true,
          autoplay: true,
        },
      },
      
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows : true,
          autoplay: true,

        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true
        },
      },
    ],
  };
  loadScript() {
    // let node = document.createElement('script');
    // node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    // node.async = true;
    // document.getElementsByTagName('head')[0].appendChild(node);
  }

  // getCities() {
  //   this.sharedService.getCities().subscribe((data) => {
  //     this.filteredCity = data;
  //   });
  // }

  filter_data: any;
  getFilterData() {
    this.apartmentservice.getFilterData().subscribe((res) => {
      this.filter_data = res;
      this.lifestage = this.filter_data['lifestage'];
      this.update_theme = this.filter_data['theme'];
    });
  }

  citydata: any;
  citydata1: any = [];
  city_filter1: any;
  city_filter: any;
  getCities() {
    this.sharedService.getCities().subscribe((data) => {
      this.citydata = data;
      this.citydata['data'].map((element:any)=>{
        if (
          !(
            element.attributes.name == 'ahmedabad' ||
            element.attributes.name == 'chandigarh' ||
            element.attributes.name == 'hyderabad' ||
            element.attributes.name == 'jaipur' ||
            element.attributes.name == 'kochi' ||
            element.attributes.name == 'kolkata' ||
            element.attributes.name == 'lucknow' ||
            element.attributes.name == 'trivandrum' ||
            element.attributes.name == 'vijayawada' ||
            element.attributes.name == 'cochin' ||
            element.attributes.name == 'nashik'
          )
        ) {
          this.citydata1.push(element);
        }
      })
      // this.citydata1 = this.citydata['data'];
    });
  }
  moreLoader :any
  viewMore(val: any) {
    this.show_viewMore = false
    this.mini_loader = true
    this.moreLoader = val
    if (this.moreLoader) {
      this.per_page = this.per_page + 6;
      this.apartmentList(this.finalcity, '');
    }
  }
  mini_loader : boolean = false
  apartment_no: any = '';
  show_viewMore: boolean = false;
  eachApart: any;
  vrLink: boolean = false;

  apartmentList(city: any, search: any) {

    // console.log('1234532532432')

    this.searchcity = city;
   
    this.finalcity = this.searchcity;
    this.search_string = this.serachedapart;

  
    if (this.serachedapart === undefined) {
      this.search_string = search;
    }
    this.apartmentservice
      .getApartmentlist(
        this.finalcity,
        this.search_string,
        this.per_page,
        this.lifeStage_filter_value,
        this.theme_filter_value,
        this.vrLink
      )
      .subscribe((data) => {
        this.gallerydata = data;
        this.apartment_no = this.gallerydata['apartment count'];
        this.unid = this.gallerydata.unid;
        this.gallerydataApartment = this.gallerydata.data.data;
        this.totalApartmentdata = this.gallerydata['apartment count'];
        if (this.per_page >= this.totalApartmentdata) {
          this.show_viewMore = false;
        } else {
          this.show_viewMore = true;
        }
        // this.placeholdervalue="Search your apartment from"+ ' ' +this.gallerydataApartment.length+' ' + "apartments we designed in"+' '   +city+' ' + "by Apartment Name, Builder,Sector or Pin Code";
        if( this.cityLoader &&  !this.moreLoader){
          this.loader = true;
          setTimeout(() => {
            this.loader = false;
          }, 2000);
        }
        if(this.moreLoader){
          this.mini_loader = true
          setTimeout(() => {
            this.mini_loader = false;
          }, 500);
        }
      });
  }

  current_url : any = 'https://www.youtube.com/embed/HZMaErn5sjM?autoplay=1&loop=1'

  allVideos :any = false
 
  IntialvideoPlayIn(url :any){
    this.allVideos = true
    this.current_url=this._DomSanitizationService.bypassSecurityTrustResourceUrl(url)
  }


  showVr(event: any) {
    this.vrLink = event.target.checked;
    this.getCityWiseData();
  }

  vrLinkApartment(data: any) {
    window.open(data, '_blank');
  }

  getsearch(text: any) {
    let textsearchdata = text;
    this.apartmentservice
      .getsearchApartmentlist(textsearchdata)
      .subscribe((res) => {});
  }
  search_string: any = '';
  search_val: any = '';
  // searchTextFilter(event: any) {
  //   this.search_string = event.target.value;
  //   searchApartment(this.city_value, this.search_string);
  //   this.apartmentList(this.finalcity, this.search_string);
  // }

  searchTextFilter(event:any){
    this.search_string = event.target.value;
    this.getCityWiseData ()
  }

  searchTextMob() {
    this.search_string = this.search_val;
    this.getCityWiseData ()
  }

  slelecttab(data: any) {
    this.tabvalues = data;
  }

  ngAfterViewInit() {
    this.selectedCity = localStorage.getItem('selectedCity');
  }

  bookConsultationApartmentList() {
    const modal = this.modalService.open(BookConsultationFormComponent, {
      size: 'md',
    });
    modal.result.then((res) => {
      this.toastr.success(res.message);
    });
  }

  customFunc(data: any) {
    this.per_page = 6;
    this.selectedCity = data.selectedCity;
    this.apartmentList(data.selectedCity, '');
  }

  page_name_query: any = 1;
  space_name2: any = '';
  update_space: any;
  space_name3: any;
  total_page: any;
  list: any;
  pricerange: any;
  lifestage: any;
  update_theme: any;
  theme_filter_value: any = '';
  finalspace: any = '';

  themeFilter(event: any) {
    this.theme_filter_value = event.target.value;
    this.getCityWiseData()
  }

  city_value: any = '';
  cityLoader :any
  // cityFilter(event: any) {
  //   this.per_page = 6
  //   this.moreLoader = false
  //   this.cityLoader = true
  //   this.city_value = event.target.value;
  //   searchApartment(
  //     this.city_value,
  //     this.search_string
  //   );
  //   this.apartmentList(this.city_value, this.search_string);
  // }


  cityFilter(event:any){
        this.city_value = event.target.value;
        this.getCityWiseData()
  }

  transform(value: string): string {
    return value.replace(/_/g, ' ');
  }

  ngOnDestroy() {
    this.metaService.removeTag("name='description'");
  }

  space_filter_value: any = '';
  price_filter_value: any = '';

  getPortfolioList(
    space: any,
    lifeStage: any,
    theme: any,
    range: any,
    curr_page: any,
    per_page: any,
    search_string: any
  ) {
    this.loader = true;
    this.finalspace = this.serachedspace;
    this.spaceService
      .getPortfolioList(
        space,
        lifeStage,
        theme,
        range,
        curr_page,
        per_page,
        this.finalspace
      )
      .subscribe((data) => {
        this.list = data;
        this.total_page = this.list.total_records;
        this.loader = false;
        if (curr_page === undefined) {
          curr_page = 1;
        }
        this.getPortfolioListHandler(curr_page);
      });
  }

  getPortfolioListHandler(curr_page: any) {
    this.loader = true;
    this.current_page = curr_page;
    this.location.go(
      `spaces?space_name=${this.space_name3}&lifestage_name=${this.lifeStage_filter_value}&theme_name=${this.theme_filter_value}&page=${this.current_page}`
    );

    if (this.space_filter_value !== undefined) {
      this.space_filter_value = this.space_name3;
    }
    this.spaceService
      .getPortfolioList(
        this.space_filter_value,
        this.lifeStage_filter_value,
        this.theme_filter_value,
        this.final_price,
        this.current_page,
        this.per_page,
        this.serachedspace
      )
      .subscribe((data) => {
        if (this.space_filter_value === undefined) {
          this.space_filter_value = '';
        }
        setTimeout(() => {
          this.loader = false;
        }, 2000);
        this.list = data;
        this.total_page = this.list.total_records;
        this.current_page = curr_page;
        this.per_page = '12';
      });
    window.scrollTo(0, 0);
  }

  lifeStage_filter_value: any = '';
  lifeStageFilter(event: any) {
    this.lifeStage_filter_value = event.target.value;
    this.getCityWiseData();
  }

  spaceFilter(event: any) {
    if (this.page_name_query === undefined) {
      this.page_name_query = 1;
    }
    this.space_filter_value = event.target.value;
    if (this.space_filter_value !== undefined) {
      this.space_name3 = this.space_filter_value;
    } else {
      this.space_name3 = '';
    }
    this.router.navigate(['/spaces'], {
      queryParams: { space_name: this.space_name3, page: this.page_name_query },
    });
    this.getPortfolioList(
      this.space_name3,
      this.lifeStage_filter_value,
      this.theme_filter_value,
      this.final_price,
      this.current_page,
      this.per_page,
      this.serachedspace
    );
  }

  showBtn: boolean = false;

  apartmentClicked(param1:any,param2:any,param3:any){
    apartmentClicked1(param1,param2,param3);
  }

  getThisDesign(apartment_name:any , cityname:any , pincode :any  , bhk :any, id:any) {
    const modal = this.modalService.open(ApartmentGetThisDesignFormComponent, {
      size: 'md',
    });
    modal.componentInstance.apartment_name = apartment_name;
    modal.componentInstance.cityname = cityname;
    modal.componentInstance.pincode = pincode;
    modal.componentInstance.bhk = bhk;
    modal.componentInstance.id = id;
    modal.result.then((res) => {});
  }

  cityWiseData : any
  getCityWiseData () {
    this.loader = true
    if(this.city_value == undefined || this.city_value == '' ){
      this.city_value = ''
    }
    if(this.lifeStage_filter_value == undefined || this.lifeStage_filter_value == ''){
      this.lifeStage_filter_value = ''
    }
    if(this.theme_filter_value == undefined || this.theme_filter_value == ''){
      this.theme_filter_value = ''
    }
    if(this.search_string == undefined || this.search_string == ''){
      this.search_string = ''
    }
    this.apartmentservice.getCityWiseApartment(this.city_value , this.search_string ,this.lifeStage_filter_value,this.theme_filter_value,this.vrLink).subscribe((res) => {
      this.loader = false
      this.cityWiseData = res
    } , err => {
      this.loader = false
      console.log(err)
    })
  }
  nextData :any

  nextPageNo :any  = 4
  totalnoNextPage :any
  loaderPreviousNext : boolean = false
  nameforcity : any
  lastClickedButton :any


  resetPageNumber() {
    this.nextPageNo = 4;
  }

  apartmentNextData(city:any , index :any , buttonLabel:any){
    if(this.lastClickedButton === buttonLabel){
      this.nextPageNo =   this.nextPageNo+4
    }else {
      this.resetPageNumber();
    }
    this.lastClickedButton = buttonLabel;
     
    if(this.nextEnable()){

     
      this.loaderPreviousNext = true
    if(this.city_value == undefined || this.city_value == '' ){
      this.city_value = ''
    }
    if(city){
      this.city_value = city
    }
    this.nameforcity = city
    if(this.lifeStage_filter_value == undefined || this.lifeStage_filter_value == ''){
      this.lifeStage_filter_value = ''
    }
    if(this.theme_filter_value == undefined || this.theme_filter_value == ''){
      this.theme_filter_value = ''
    }
    if(this.search_string == undefined || this.search_string == ''){
      this.search_string = ''
    }
    this.apartmentservice.cityWisenextPrevious(this.city_value , this.search_string ,this.lifeStage_filter_value,this.theme_filter_value,this.vrLink, '',  this.nextPageNo).subscribe((res) => {
      this.nextData = res
      // let existingIds = new Set(this.cityWiseData[index].apartments.data.map( (e :any) => e.id ));
      // let uniqueNewObjects = this.nextData.data.data.filter((obj:any) => !existingIds.has(obj.id));
      this.cityWiseData[index].apartments.data =[];
      this.cityWiseData[index].apartments.data.push(...this.nextData.data.data)
       console.log(this.cityWiseData , 'next')
      //  setTimeout(() => {
        this.loaderPreviousNext = false;
      // }, 2000);
    } , err => {
      console.log(err)
    })

    }
    // $("#"+activetab).addClass("active");
     
  }
  apartmentPreviousData(city:any , index:any){
    console.log('previous')
  }
  nextEnable(){
    console.log(this.nextPageNo,this.nextData)
    if(this.nextPageNo == 4){
      return true
    } else{ 
      console.log(this.nextData['apartment count'])
      if(this.nextPageNo < this.nextData['apartment count']){
        return true
      } else{
        return false
      }
    }
  }
}
 