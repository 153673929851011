import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../shared/header/header.component';
import { SharedModule } from '../shared/shared.module';
import { LatestdesignComponent } from './latestdesign/latestdesign.component';
import { PackagesComponent } from './packages/packages.component';
import { RouterModule } from '@angular/router';
import { KitchenGuideComponent } from './kitchen-guide/kitchen-guide.component';
import { TermsOfUseComponent} from './terms-of-use/terms-of-use.component';
import { TermsAndConditionsComponent} from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { CustomDatePipe ,CustomDatePipeOne } from './custom-date.pipe';
import { EmbedVideo } from 'ngx-embed-video';
import { VisitusSliderComponent } from './visitus-slider/visitus-slider.component';
import { PaymentRefreshComponent } from './payment-refresh/payment-refresh.component';
import { RustomjeeComponent } from './rustomjee/rustomjee.component';
import { SignUpToServiceComponent } from './sign-up-to-service/sign-up-to-service.component';
import {  FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewLandingPageComponent } from './new-landing-page/new-landing-page.component';
import { WorkWithUsComponent } from './work-with-us/work-with-us.component';
import { WhatWeCoverComponent } from './what-we-cover/what-we-cover.component';
import { VideoGalleryComponent } from './video-gallery/video-gallery.component';
import { ProjectGalleryComponent } from './project-gallery/project-gallery.component';
import { HowWeWorkComponent } from './how-we-work/how-we-work.component';
import { BlogsComponent } from './blogs/blogs.component';
import { FooterNewComponent } from '../shared/footer-new/footer-new.component';
import { CustomerTestimonialsComponent } from './customer-testimonials/customer-testimonials.component';
import { PlayVideoModelComponent, SafePipe } from './play-video-model/play-video-model.component';
import { ProjectGalleryCarouselModalComponent } from './project-gallery-carousel-modal/project-gallery-carousel-modal.component';
import { LandingPageNewComponent } from './landing-page-new/landing-page-new.component';
import { UpdatedBlogsDesignComponent } from './updated-blogs-design/updated-blogs-design.component';
import { OurProjectGalleryUpdateddComponent } from './our-project-gallery-updatedd/our-project-gallery-updatedd.component';
import { HearFromClientsComponent } from './hear-from-clients/hear-from-clients.component';
import { StoriesUpdatedComponent } from './stories-updated/stories-updated.component';
import { InTheNewsUpdatedComponent } from './in-the-news-updated/in-the-news-updated.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AboutUsComponent } from './about-us/about-us.component';
import { MadeByYouStoriesComponent } from './made-by-you-stories/made-by-you-stories.component';
import { MadeByYouDetailComponent } from './made-by-you-detail/made-by-you-detail.component';
import { DesignThemesComponent } from './design-themes/design-themes.component';
import { ArriaveLivingComponent } from './arriave-living/arriave-living.component';
import { MadebyYouStoriesNewComponent } from './madeby-you-stories-new/madeby-you-stories-new.component';
import { LatestBlogsNewComponent } from './latest-blogs-new/latest-blogs-new.component';
import { InstagramComponent } from './instagram/instagram.component';
import { HowItWorksNewComponent } from './how-it-works-new/how-it-works-new.component';
import { FooterNewHomeComponent } from './footer-new-home/footer-new-home.component';
import { NewHeaderForHomeComponent } from './new-header-for-home/new-header-for-home.component';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ArrivaeLivingLandingComponent } from './arrivae-living-landing/arrivae-living-landing.component';
import { NgxSlickJsModule } from 'ngx-slickjs';
import { FrequentQuestionsComponent } from './frequent-questions/frequent-questions.component';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';
import { EndToEndComponent } from './end-to-end/end-to-end.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { OurStoresComponent } from './our-stores/our-stores.component';
import { ReferAFriendComponent } from './refer-a-friend/refer-a-friend.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { ReviewDetailComponent } from './review-detail/review-detail.component';
import { HomesDeliveredComponent } from './homes-delivered/homes-delivered.component';
import { DesignWithUsComponent } from './design-with-us/design-with-us.component';



@NgModule({
  declarations: [
    LatestdesignComponent,
    PackagesComponent,
    KitchenGuideComponent,
    CustomDatePipe,
    TermsOfUseComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    VisitusSliderComponent,
    PaymentRefreshComponent,
    RustomjeeComponent,
    SignUpToServiceComponent,
    NewLandingPageComponent,
    WorkWithUsComponent,
    WhatWeCoverComponent,
    VideoGalleryComponent,
    ProjectGalleryComponent,
    HowWeWorkComponent,
    BlogsComponent,
    FooterNewComponent,
    CustomerTestimonialsComponent,
    PlayVideoModelComponent,
    SafePipe,
    ProjectGalleryCarouselModalComponent,
    LandingPageNewComponent,
    OurProjectGalleryUpdateddComponent,
    HearFromClientsComponent,
    OurProjectGalleryUpdateddComponent,
    InTheNewsUpdatedComponent,
    StoriesUpdatedComponent,
    HowItWorksComponent,
    CustomDatePipeOne,
    MadeByYouStoriesComponent,
    MadeByYouDetailComponent,
    AboutUsComponent,
    DesignThemesComponent,
    ArriaveLivingComponent,
    
    LatestBlogsNewComponent,
    InstagramComponent,
    HowItWorksNewComponent,
    
  
    ArrivaeLivingLandingComponent,
    FrequentQuestionsComponent,
    EndToEndComponent,
    TestimonialComponent,
    OurStoresComponent,
    ReferAFriendComponent,
    ReviewsComponent,
    ReviewDetailComponent,
    HomesDeliveredComponent,
    DesignWithUsComponent,

  ],
  imports: [
   
    EmbedVideo.forRoot(),
    
    CommonModule,
    HttpClientModule,
    SharedModule,
    RouterModule,
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    GooglePlaceModule,
    NgxSlickJsModule.forRoot({
      links: {
        jquery: '',
        slickJs:
          'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js',
        slickCss:
          'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css',
        slickThemeCss:
          'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css',
      },
    }),

    NgxYoutubePlayerModule.forRoot()
   

  

  ],
  
  exports: [ 
   LatestdesignComponent, PackagesComponent, StoriesUpdatedComponent,
   
   NgxSlickJsModule,
   MadebyYouStoriesNewComponent
  
    
   ]
})
export class LandingModule {}


