<div class="wrapper">
    <div class="heading">
        <h1>Work with us</h1>
    </div>

    <div class="contents">
        <div class="row">
            <div class="column col-sm-12 col-md-6">
                <div class="image"  style="background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)), url('../../../assets/images/Become\ designer@3x.png');">
                    <h1>Design beautiful <br> things with us</h1>
                    <a href="https://arrivae.com/become_a_design_partner" target="_blank">Become Designers</a>
                </div>
                <!-- <img style="width: 100%;" src="../../../assets/images/image 46@3x.png" alt=""> -->
            </div>
            <div class="column col-sm-12 col-md-6">
                <div class="image"  style="background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)), url('../../../assets/images/Become\ partner@3x.png');">
                    <h1>Build with us and <br> make people smile</h1>
                    <a href="https://arrivae.com/become_a_manufacturer" target="_blank">Become Partners</a>
                </div>
                <!-- <img style="width: 100%;" src="../../../assets/images/image 57@3x.png" alt=""> -->
            </div>
          </div>
    </div>

    <div class="heading heading_our_partners">
        <h1>Our partners</h1>
    </div>

    <div class="contents">
        <div class="row">
            <div class="column-logo col-sm-4 col-md-4">
                <!-- <div class="image"  >
                    <h1>Design beautiful <br> things with us</h1>
                    <button>Become Designers</button>
                </div> -->
                <img  src="../../../assets/images/indiabull-logo 1@3x.png" alt="">
            </div>
            <div class="column-logo col-sm-4 col-md-4">
                <!-- <div class="image"  >
                    <h1>Build with us and <br> make people smile</h1>
                    <button>Become Partner</button>
                </div> -->
                <img  src="../../../assets/images/shriram-logo 1@3x.png" alt="">
            </div>
            <div class="column-logo col-sm-4 col-md-4">
                <!-- <div class="image"  >
                    <h1>Build with us and <br> make people smile</h1>
                    <button>Become Partner</button>
                </div> -->
                <img style="width: 70%;" src="../../../assets/images/pjl-logo 1@3x.png" alt="">
            </div>
        </div>
    </div>
</div>