import { Component, OnInit } from '@angular/core';
import { LandingService } from '../landing.service';
@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss'],
})
export class BlogsComponent implements OnInit {
  blogs: any;
  constructor(public landingService: LandingService) {}

  ngOnInit(): void {
    this.getBlogs();
  }

  getBlogs(){
    this.landingService.loadPosts().subscribe(data =>{
      this.blogs = data;
    })
  }
}
