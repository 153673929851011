



import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BookConsultationFormComponent } from '../../shared/book-consultation-form/book-consultation-form.component';
import { LandingService } from '../landing.service';
import { Slick } from 'ngx-slickjs';
import { Router } from '@angular/router';


@Component({
  selector: 'app-homes-delivered',
  templateUrl: './homes-delivered.component.html',
  styleUrls: ['./homes-delivered.component.scss']
})
export class HomesDeliveredComponent implements OnInit {
  

  constructor(private modalService: NgbModal,
    private landingService: LandingService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getreviews('','')
  }

  config: Slick.Config = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    centerPadding: '20%', 
    autoplaySpeed: 2000,
    draggable: true,
    mouseWheelMove: false,
    arrows: false,
    centerMode: true, 
    responsive: [
  
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0', 
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0', 
        },
      },
    ],
  };

  bookConsultationLandingPage() {
    const modal = this.modalService.open(BookConsultationFormComponent, {
      size: 'md',
    });
  }
  reviews:any
  allreviews:any
  cities:any
  apartments:any
  city:any='';
  apartment:any=''
  size=6;
  loader:any
  getreviews(city: string | undefined,apartment: string | undefined){
    this.loader = true
    this.landingService.getreviews(city,apartment).subscribe((res)=>{
      const responce: any=res;
      setTimeout(()=>{
        this.loader = false
       },2000)
      console.log(responce.all_cities)
      console.log(responce['customer_reviews'].slice(0, this.size))
      console.log(responce.all_cities)
      console.log(responce.all_apartments)

      this.allreviews=responce.customer_reviews
      this.cities=responce.all_cities
      this.apartments=responce.all_apartments

      this.reviews=this.allreviews.slice(0, 6)


      console.log(this.allreviews)
    })

  }

  cityselected(event: any){
    console.log(event.target.value)
    this.city=event.target.value
    this.getreviews(this.city,'')

  }
  apartmentfilter(event :any){
    console.log(event)
    this.apartment=event.target.value
    this.getreviews('',this.apartment)
  }

  all_apartments(){
    console.log("hello")
  }
  addreviews(){
    const length=this.allreviews.length
    let newreviews

    console.log(length)
    if(length<(this.size+3)){
      console.log("hii")
      newreviews=this.allreviews.slice(this.size,this.size+3)
      console.log(newreviews)
    }
    else{
      newreviews=this.allreviews.slice(this.size,length)
    }

    this.reviews=this.reviews.concat(newreviews)


  }

  navigate(id: any){
   
  }
  openInNewTab() {
    window.open('/evangelists', '_blank');
  }
  OpenInDeatil(id:any){
  
    window.open('/review/detail?Review_id=' + id, '_blank');
  }
  navigateAll(){
    this.router.navigate(['/Reviews'])
  }
  roletoSet(){
    var width = Math.max(window.screen.width, window.innerWidth)
     return width

  }
  words50(value: string){
    if (!value) return '';

    const words = value.split(' '); // Split the text by spaces to get words
    if (words.length <= 50) {
        // Return the original text if the number of words is less than or equal to the limit
        return value;
    }

    // Join the first 'wordCount' words and add ellipsis at the end
    return words.slice(0, 50).join(' ') + '...';
}
  
  


}
