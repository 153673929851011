<!-- For Summary Escalation -->
<div *ngIf="!type || type == 'summary'" class="tableStyle">
  <div class="row alignItem mt-5">
    <div class="col">
      <h5 class="heading">Escalation Summary</h5>
    </div>
  </div>

  <!-- Table -->
  
  <div class="row mt-3">
    <div class="col customoverflow">
      <table class="tableColumn">
        <thead>
          <th scope="col">Store Name</th>
          <th scope="col">Count of Open escalation</th>
          <th scope="col">Avg of Escalation creation date</th>
        </thead>
        <tbody>
          <tr *ngFor="let value of escalationSummaryData.stores; let i=index">
            <td>{{value.name}}</td>
            <td>{{value.open_escalation_count}}</td>
            <td>{{dateFormate(value.avg_time_since_creation, 'summary')}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<!-- For Summary Escalation -->


<!-- For Older Escalation -->
<div *ngIf="type == 'oldest'" class="tableStyle">
  <div class="row alignItem mt-5">
    <div class="col">
      <h5 class="heading">Top 10 oldest escalation</h5>
    </div>
  </div>

  <!-- Table -->
  
  <div class="row mt-3">
    <div class="col customoverflow">
      <table>
        <thead class="tableColumn2">
          <th scope="col">Store Name</th>
          <th scope="col">Lead id</th>
          <th scope="col">Lead Name</th>
          <th scope="col">Stage of escalation</th>
          <th scope="col" style="width: 210px;">Ageing Escalation Creation Date</th>
          <th scope="col">Expected resolution date</th>
          <th scope="col">Primary Assignee</th>
          <th scope="col">Secondary Assignee</th>
        </thead>
        <tbody>
          <tr *ngFor="let value of escalationtopOldestData.manual_escalations; let i=index">
            <td>{{value.store_name}}</td>
            <td>{{value.lead_id}}</td>
            <td>{{value.lead_name}}</td>
            <td>{{value.escalation_stage_readable}}</td>
            <td>{{dateFormate(value.created_at, 'oldest')}}</td>
            <td>{{value.expected_resolution_date}}</td>
            <td>{{value.primary_assignee.user_name}}</td>
            <td>{{value.secondary_assignee.user_name}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<!-- For Older Escalation -->

<!-- loader -->
<div class="loading d-flex justify-content-center" *ngIf="loader">
  <div class="spinner-border" style="color: #8d0528;" role="status">
      <span class="sr-only">Loading...</span>
  </div>
</div>
<!-- loader -->