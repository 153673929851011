<div class="wrapper">
    <div class="heading">
        <h1>Project gallery</h1>
        <!-- <a href="#">See all</a> -->
    </div>

    <div class="grid-container">
        <!-- <div class="row">
            <div class="col-sm-12 col-md-3">.col-sm-12 .col-md-4</div>
            <div class="col-sm-12 col-md-3">.col-sm-12 .col-md-4</div>
            <div class="col-sm-12 col-md-3">.col-sm-12 .col-md-4</div>
            <div class="col-sm-12 col-md-3">.col-sm-12 .col-md-4</div>
          </div> -->
        <div class="viewport">
            <div class="cards">
                <div class="card" (click)="projectGalleryModelComponent('0')">
                    <div class="card_img card1" >
                        <img [src]="images[0]" alt="">
                    </div>
                    <p class="text-uppercase">Mr Abhijit Benerji</p>
                </div>
                <div class="card" (click)="projectGalleryModelComponent('1')">
                    <div class="card_img card2" >
                        <img [src]="images[1]" alt="">
                    </div>
                    <p class="text-uppercase">Mrs Amar & Swati</p>
                </div>
                <div class="card" (click)="projectGalleryModelComponent('2')">
                    <div class="card_img card3" >
                        <img [src]="images[2]" alt="">
                    </div>
                    <p class="text-uppercase">Mr Prathamesh Prabhu</p>
                </div>
                <div class="card" (click)="projectGalleryModelComponent('3')">
                    <div class="card_img card3" >
                        <img [src]="images[3]" alt="">
                    </div>
                    
                    <p class="text-uppercase">Mr Rodrigues</p>
                </div>
                <div class="card" (click)="projectGalleryModelComponent('4')">
                    <div class="card_img card3" >
                        <img [src]="images[4]" alt="">
                    </div>
                    
                    <p class="text-uppercase">Mr Roy</p>
                </div>
                <!-- <div class="card" (click)="projectGalleryModelComponent('5')">
                    <div class="card_img card3" >
                        <img [src]="images[5]" alt="">
                    </div>
                    
                    <p class="text-uppercase">Mrs Sunita’s Modern Kitchen</p>
                </div> -->
            </div>
        </div>
    </div>
</div>
