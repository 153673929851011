
<div [ngClass]="{'margin-issue': (window.location.pathname !='/testimonial')}">

  <router-outlet></router-outlet>

</div>

<div [ngClass]="{'d-none': (window.location.pathname==='/payment-process' || window.location.pathname === '/enquire-now' || window.location.pathname === '/get-quote' )}" class="whatsapp-img react-draggable react-draggable-dragged">
  <a href="https://api.whatsapp.com/send?phone=918976817214" target="_blank">
    <img src="../assets/images/whats-app.png">
    </a>
</div>