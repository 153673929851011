<div class="container">
  <div class="row justify-content-center">
    <div class="col-md-4">
      <div class="row justify-content-center" style="margin-top: 4rem;">
        <div style="text-align: center;">Processing Your EMI</div>
        <div style="text-align: center;">Don't Refresh or Close this window</div>
        </div>
      </div>
    </div>
  </div>

  <div class="loading d-flex justify-content-center" *ngIf="loading">
    <div class="spinner-border" style="color: #8d0528;" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>