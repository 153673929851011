import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BookConsultationFormComponent } from '../book-consultation-form/book-consultation-form.component';

@Component({
  selector: 'app-get-finance',
  templateUrl: './get-finance.component.html',
  styleUrls: ['./get-finance.component.scss']
})
export class GetFinanceComponent implements OnInit {

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
      window.scrollTo(0, 0);
      this.loadScript()
  }
  bookConsultationLandingPage() {
    const modal = this.modalService.open(BookConsultationFormComponent, {
      size: 'md',
    });
  }

  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  intrest_per= 0;
  downpayment= 2.5;
  tenure="6 MONTHS";
  loandetails(e:  any){
    console.log("hii")
    console.log(e)
    if(e == "zero-tab"){
      this.intrest_per= 0;
      this.downpayment= 2.5;
      this.tenure="6 MONTHS";
      
    }
    if(e == "rapid-tab"){
      this.intrest_per= 4.4;
      this.downpayment= 2.5;
      this.tenure="12 MONTHS";
      
    }
    if(e == "rapid24-tab"){
      this.intrest_per= 12;
      this.downpayment= 2.7;
      this.tenure="24 MONTHS";
      
    }
    if(e == "home-tab"){
      this.intrest_per= 8;
      this.downpayment= 1;
      this.tenure="60 MONTHS";
      
    }
    if(e == "topup-tab"){
      this.intrest_per= 7.75;
      this.downpayment= 0;
      this.tenure="25 YEARS";
      
    }
    if(e == "personal-tab"){
      this.intrest_per= 13;
      this.downpayment= 0;
      this.tenure="5 YEARS";
      
    }
    console.log(this.intrest_per, this.downpayment, this.tenure)
  }
}
