import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Http, Response, Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';
import { environment } from "../../environments/environment";
import { AuthenticationService } from "../authentication/authentication.service";
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class ApartmentService {
  options: RequestOptions;
  headers: any;
  user1: any;
  uid: any;
  access_token: any;
  client: any;

  private boardURL = environment.apiBaseUrl;
  // apiURL_UAT = "https://newuatapi.arrivae.com";
  private uatURL = environment.deltaBaseUrl;
  //UAT

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
 ) {
    this.options = this.authService.getHeaders();
 }

  private extractData(res: any) {
    let body = res.json();
    return body;
  }
  private extractDataPage(res: Response) {
    return res;
  }

  private handleErrorObservable(error: Response | any) {
    return Observable.throw(error.message || error);
  }

  public getApartmentlist(
    city: any,
    search_string: any,
    per_page: any,
    lifeStage: any,
    theme: any,
    vrLink: any
  ) {
    let url =
      environment.apiBaseUrl +
      '/v1/apartments?page=1&city=' +
      '' +
      '&search=' +
      search_string +
      '&per_page=' +
      '' +
      '&lifestage=' +
      lifeStage +
      '&theme=' +
      theme +
      '&vr_link=' +
      vrLink;
    return this.http.get(url);
  }

  public getCityWiseApartment(
    city: any,
    search_string: any,
    lifeStage: any,
    theme: any,
    vrLink: any
  ){
    let url = environment.apiBaseUrl + '/v1/apartments/apartment_with_cities?city=' + city
    + '&search=' + search_string + 

    '&lifestage=' +  lifeStage + '&theme=' + theme + '&vr_link=' + vrLink
    return this.http.get(url)
  }

  public cityWisenextPrevious(
    city: any,
    search_string: any,
    lifeStage: any,
    theme: any,
    vrLink: any,
    page :any,
    per_page :any
  ){
    let url =  environment.apiBaseUrl  +'/v1/apartments/city_apartments?city='+ city + '&search=' + search_string +  '&lifestage=' + lifeStage + '&theme=' + theme + '&vr_link=' +  vrLink +  '&page=' + page +  '&per_page=' +  per_page
    return this.http.get(url)

  }


  public getApartmentlist2(unid: any, city: any, search_string: any) {
    let url =
      environment.apiBaseUrl +
      '/v1/apartments?city=' +
      city +
      '&search=' +
      search_string+
      '&per_page=100';
    return this.http.get(url);
  }
  public verifyemail(lead_id:any,email?:any) {
    let url =environment.deltaBaseUrl+'/v1/leads/'+lead_id+'/verify_lead_email?new_email=' + email ;
    return this.http.get(url);
  }

  getLeadLogs(leadId:any) {
    let url =environment.deltaBaseUrl+"/v1/leads/" + leadId + "/get_lead_email_verify_details";
    return this.http.get(url)

  }

  public getsearchApartmentlist(searchdata: any) {
    let aparmentlistsearchapi = '/v1/apartments?location=delhi';
    return this.http.get(
      this.boardURL + aparmentlistsearchapi + '&search=' + searchdata
    );
  }

  public getApartmentDetails(apartmnetname: any, departmentname: any) {
    let apartmentdetailsapi = '/v1/apartments/apartments_details?';
    return this.http.get(
      this.boardURL +
        apartmentdetailsapi +
        'apartment_name=' +
        apartmnetname +
        '&developer_name=' +
        departmentname
    );
  }

  public getPincode(cityname: any) {
    let pincodeapi = '/v1/leads/get_pincodes_on_city_basis';
    return this.http.get(this.uatURL + pincodeapi + '?city=' + cityname);
  }

  public createBookConsultation(postObj: any) {
    let createbookconsulapi = '/v1/leads/';
    return this.http.post(this.uatURL + createbookconsulapi, postObj);
  }

  public updateleadstatus(leadid: any, postObj: {}) {
    let url = '/v1/leads/';
    return this.http.post(
      this.uatURL + url + leadid + '/update_lead_status2',
      postObj
    );
  }

  updateItems(leadid: any, postObj: {}) {
    let url = '/v1/leads/';
    return this.http.post(
      this.uatURL + '/v1/leads/' + leadid + '/update_lead_status2',
      postObj
    );
  }
  getbuilding(city: any, search: any) {
    let url =
      environment.apiBaseUrl +
      '/v1/note_records/get_city_details?city_name=' +
      city +
      '&search=' +
      search;
    return this.http.get(url);
  }
  flatsizetype(id: any) {
    let url =
      environment.apiBaseUrl + '/v1/note_records/get_society_details?id=' + id;
    return this.http.get(url);
  }
  signup(postObj: any) {
    let url =
      environment.apiBaseUrl + '/v1/leads/create_lead_get_your_estimate';
    return this.http
      .post(url, postObj, { observe: 'response' })
      .pipe(map((user) => (this.user1 = user)));
  }

  getVarients(unid: any , city?:any , search?: any , per_page?: any , lifestage?:any , theme?:any , vr_link?: any) {
    let url = environment.apiBaseUrl + "/v1/apartments/apartment_varients?unid=" + unid + "&city=" + city + "&search=" + search + "&per_page=" + per_page + "&lifestage=" + lifestage + "&theme=" + theme + "&vr_link=" + vr_link ;
    return this.http.get(url);
  }

  getVarientandspace(unid: any, bhkdata: any) {
    let url =
      environment.apiBaseUrl +
      '/v1/apartments/apartment_spaces?unid=' +
      unid +
      '&format=' +
      bhkdata;
    return this.http.get(url);
  }
  // this.unidData , this.inclusionName , this.typeOfFormat
  getSpacecontent(space_id?: any , unid?:any , format?:any , space?:any){
    let url = environment.apiBaseUrl + "/v1/apartments/apartment_space_contents?space_id="+space_id + "&unid=" + unid + "&format=" + format + "&space=" + space
    return this.http.get(url);
  }
  getApartmentSpaceItems(id: any) {
    let url =
      environment.apiBaseUrl + '/v1/portfolios/space_items?portfolio_id=' + id;
    return this.http.get(url);
  }

  //For apartment Spaces ends here

  customPackage(uid: any, client: any, accessToken: any) {
    let url =
      environment.apiBaseUrl + '/v1/custom_packages/user_custom_packages';
    const headerDict = {
      'access-token': accessToken,
      uid: uid,
      client: client,
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.get(url, requestOptions);
  }

  submitViewDetail(
    uid: any,
    client: any,
    accessToken: any,
    lead_id: any,
    seleectedSpace: any
  ) {
    let url =
      environment.apiBaseUrl +
      '/v1/leads/' +
      lead_id +
      '/choose_custom_package';
    const headerDict = {
      'access-token': accessToken,
      uid: uid,
      client: client,
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    var obj = {
      custom_package_type: seleectedSpace,
    };
    return this.http.patch(url, obj, requestOptions);
  }

  customPackageDetails(uid: any, client: any, accessToken: any) {
    let url =
      environment.apiBaseUrl + '/v1/custom_packages/lead_apartment_details';
    const headerDict = {
      'access-token': accessToken,
      uid: uid,
      client: client,
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return this.http.get(url, requestOptions);
  }

  getSpaceItems(id: any) {
    let url =
      environment.apiBaseUrl +
      '/v1/apartments/apartment_space_items?space_id=' +
      id;
    return this.http.get(url);
  }

  getFilterData() {
    let url = environment.apiBaseUrl + '/v1/apartments/filter_datas';
    return this.http.get(url);
  }
  getSocietyWebapi(search:any) {

    let url ="https://api-apartment.arrivae.com/api/v1/auto/complete"

    let obj ={
      "keyword": search,
      "limit":10
    }
    return this.http.post(url,obj)
     
  }
  
  CreateApar(obj:any){
    
    // const headers = new HttpHeaders({
    //   'Authorization': 'e836d4c3-4bc9-11ee-a018-0210a115f206'
    // });
    const headerDict = {
      'Authorization': 'e836d4c3-4bc9-11ee-a018-0210a115f206'
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
   
    let url ="https://api-apartment.arrivae.com/api/v1/add/apartment"
    return this.http
      .post(url,obj, requestOptions)

  }
  checkprecence(apart_name:any){
    let url ="https://api-apartment.arrivae.com/api/v1/get/apartment"
    let obj ={
      "apartment_name": apart_name,
    }
    return this.http.post(url,obj)

  }
}