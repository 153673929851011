import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-find-us',
  templateUrl: './find-us.component.html',
  styleUrls: ['./find-us.component.scss']
})
export class FindUsComponent implements OnInit {

  constructor() { }

  cities=[
    {
      image:"../../../assets/Longform/icons/mumbai.svg",
      title:"Mumbai",
    },
    {
      image:"../../../assets/Longform/icons/navimumbai.svg",
      title:"Navi Mumbai",
    },
    {
      image:"../../../assets/Longform/icons/thane.svg",
      title:"Thane",
    },
    {
      image:"../../../assets/Longform/icons/gurugram.svg",
      title:"Gurugram",
    },
    {
      image:"../../../assets/Longform/icons/benguluru.svg",
      title:"Bengaluru",
    },
    {
      image:"../../../assets/Longform/icons/hydrebad.svg",
      title:"Hyderabad",
    },
    {
      image:"../../../assets/Longform/icons/vijaywada.svg",
      title:"Vijayawada",
    },

   ]

  ngOnInit(): void {
  }

}
