<div class="how-it-works-wrapper">

    <div class="carousel-container">
        <div class="carousel" id="main-carousel" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#main-carousel" data-slide-to="0" class="active">
                    <p>01</p>
                    <hr>
                    <p class="text">Meet The Designer</p>
                </li>
                <li data-target="#main-carousel" data-slide-to="1">
                    <p>02</p>
                    <hr>
                    <p class="text">Design Finalization</p>
                </li>
                <li data-target="#main-carousel" data-slide-to="2">
                    <p>03</p>
                    <hr>
                    <p class="text">Place The Order</p>
                </li>
                <li data-target="#main-carousel" data-slide-to="3">
                    <p>04</p>
                    <hr>
                    <p class="text">Installation & Factory Production</p>
                </li>
                <li data-target="#main-carousel" data-slide-to="4">
                    <p>05</p>
                    <hr>
                    <p class="text">Handover-Move-in</p>
                </li>
            </ol>
            <!-- /.carousel-indicators -->

            <div class="carousel-inner">
                <div class="carousel-item active" data-interval="3000">
                    <div class="carousel-content">
                        <div class="image">
                            <img class="d-block img-fluid" src="../../../assets/images/Landing_page_updated/how-it-works/Step-1 Meet the Designer.jpg" alt="Step 1 Meet The Designer shutterstock">
                        </div>
                        <div class="content">
                            <h1>01</h1>
                            <h1>Meet The Designer</h1>
                            <!-- <p>Meet the designer, Share ideas, inspirations and requirements. Your Journey begins!</p> -->
                            <p>Share ideas, inspirations and requirements. Your journey begins!</p>
                        </div>
                    </div>
                </div>
                <div class="carousel-item" data-interval="3000">
                    <div class="carousel-content">
                        <div class="image">
                            <img class="d-block img-fluid" src="../../../assets/images/Landing_page_updated/how-it-works/Step-2 Design Finalization.png" alt="Step 2 Design Finalization shutterstock">
                        </div>
                        <div class="content">
                            <h1>02</h1>
                            <h1>Design Finalization</h1>
                            <p>Visualize your home design with 3D and VR views. Book your dream home interior with 10% payment.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="carousel-item" data-interval="3000">
                    <div class="carousel-content">
                        <div class="image">
                            <img class="d-block img-fluid" src="../../../assets/images/Landing_page_updated/how-it-works/Step-3 Place The Order.png" alt="Step 3 Place The Order shutterstock">
                        </div>
                        <div class="content">
                            <h1>03</h1>
                            <h1>Place The Order</h1>
                            <p>Move forward in the design journey with 40% payment.</p>
                        </div>
                    </div>
                </div>
                <div class="carousel-item" data-interval="3000">
                    <div class="carousel-content">
                        <div class="image">
                            <img class="d-block img-fluid" src="../../../assets/images/Landing_page_updated/how-it-works/Step-4 Installation and Factory Production.png" alt="Step 4 Factory Prduction Installation shutterstock">
                        </div>
                        <div class="content">
                            <h1>04</h1>
                            <h1>Installation & Factory Production</h1>
                            <p>Your site work, factory furniture production and installation has begun. Pay 50% and proceed to the final step.</p>
                        </div>
                    </div>
                </div>
                <div id="last-carousel-item" class="carousel-item" data-interval="3000">
                    <div class="carousel-content">
                        <div class="image">
                            <img class="d-block img-fluid" src="../../../assets/images/Landing_page_updated/how-it-works/Step-5 Handover Move In.png" alt="Step 5 Move In shutterstock">
                        </div>
                        <div class="content">
                            <h1>05</h1>
                            <h1>Handover - Move-in</h1>
                            <p>Here it is, your dream, just the way your always wanted.</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- /.carousel-inner -->

        </div>
        <!-- /.carousel -->
    </div>


</div>