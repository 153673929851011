<app-new-header-for-home  ></app-new-header-for-home>
<div class="root-background-gray" style="overflow-x: hidden ;">
  <div class="root-apartments">
      <div class="row">
        <div class="col-lg-12">
          <div class="row" >
            <div class="col-lg-4 col-12 address-img" *ngIf ="apartvarandspaceData">
                  <div>
                      <img
                      class="img-fluid"
                      src="{{apartvarandspaceData[0].attributes.primary_image }}" class="w-100" style="height: 340px; object-fit: fill;" onerror="src='../../../assets/images/default-apartment.png'"
                    />
                  </div>  
            </div>
            <div class="col-lg-8 back-to-apartments mt-3">
              <div class="row">
                <div class="col-12 mb-2">
                  <a class="apart" routerLink = "/apartment">
                    <span class="mr-2">
                      <img
                        src="../../../assets/images/apartment-detail-images/Icon (1).png"
                      />
                    </span>
                    Back To Apartments
                  </a>
                </div>
                <div class="col-lg-12 col-12 apartment-right-part">
                  <button class="btn p-lg-0">
                    <a class="px-3 py-1">{{apartmentDetailsData?.lifestage | titlecase}}</a>
                  </button>
                  <button class="btn p-lg-0">
                    <a class="px-3 py-1">{{apartmentDetailsData?.theme | titlecase}}</a>
                  </button>
                </div>
                <ng-container *ngIf ="apartvarandspaceData">
                  <div class="col-lg-12 col-12 tata mt-2">
                    <p class="mb-0">{{apartvarandspaceData[0].attributes.apartment_name.replace('_', ' ')}}</p>
                  </div>
                  <div class="col-lg-12 col-12 d-flex" id="cityEmi">
                    <ul  class="nav">
                      <li class="nav-item">{{apartvarandspaceData[0].attributes.state}}</li>
                      <ng-container *ngIf = "apartmentDetailsData.varients.length === 1 else other_content ">
                        <li class="nav-item">Single Layout</li>
                      </ng-container>
                      <ng-template #other_content><li class="nav-item">Multiple Layouts</li></ng-template>
                      <!-- <li *ngIf ="apartvarandspaceData[0].attributes.boq_emi" class="nav-item">EMI Starts at Rs. {{apartvarandspaceData[0].attributes.boq_emi  | number : '1.0-0' }}</li> -->
                    </ul>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <div class="root-presit">
    <div class="row">
      <div class="col-12 our">
        <p>Our Preset for</p>
      </div>
      <div class="col-lg-12" id="gdfgfdgd{{i}}"  *ngFor="let dataOne of apartvarandspaceData; let i=index">
          <div class="row">
            <div class="col-lg-8">
                  <div class="row nav-elements">
                    <div class="col-lg-12">
                      <div class="row">
                        <div class="col-12">
                          <div class="row">
                            <div class="col-12 varients-overflow mb-lg-0 mb-2">
                              <ul class="nav" id="space-variants" style="border-bottom: 1px solid #898989;">
                                <select *ngIf ="i==0"  name="dropdown" id="inputState"  (change)="spaceFilter($event)">
                                  <ng-container *ngFor = "let dataVariants of apartmentDetailsData?.varients">
                                    <option [selected]="dataVariants == bhkData" class="text-uppercase">{{dataVariants | uppercase}}</option>
                                  </ng-container>
                                </select>
                                <hr *ngIf ="i==0" class="firstHr">
                                  <ng-container *ngFor="let space of dataOne.attributes.apartment_spaces; let l=index">
                                    <li class="nav-item mr-4">
                                        <a [ngClass] = "{'actived' : space.name === activated[i] }" (click)="onClickSpaces(space.id, i, space.name , dataOne.attributes.format ,dataOne.attributes.unid );" class="mb-0">{{space.name | spacePipe}}</a>
                                    </li>
                                  </ng-container>
                                </ul>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 d-lg-block d-none mt-3" *ngIf="i===0">
                          <div class=" spaces-information">
                            <ng-container *ngIf = "apartvarandspaceData">
                            <div class="row justify-content-center mx-5">
                              <div class="col-3 d-flex">
                                    <div>
                                      <img src="../../../assets/images/apartment-detail-images/first-icon.svg">
                                    </div>
                                    <div class="ml-2">
                                      <p class="mb-0">Developer</p>
                                      <p class="mb-0">{{dataOne.attributes.developer_name}}</p>
                                    </div>
                              </div>
                              <div class="col-3 d-flex">
                                <div>
                                  <img src="../../../assets/images/apartment-detail-images/second-icon.svg">
                                </div>
                                <div class="ml-2">
                                  <p class="mb-0">Area</p>
                                  <p class="mb-0">{{dataOne.attributes.area}} Sq.Ft</p>
                                </div>
                              </div>
                              <div class="col-3 d-flex">
                                  <div>
                                    <img src="../../../assets/images/apartment-detail-images/third-icon.svg">
                                  </div>
                                  <div class="ml-2">
                                    <p class="mb-0">Layout Format</p>
                                    <p class="mb-0">{{dataOne.attributes.format | uppercase}}</p>
                                  </div>
                              </div>
                            </div>
                            </ng-container>
                        </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 my-lg-0 my-4" *ngIf ="apartvarandspaceData && i === 0"  style="background-color: white;">
              <div id="layoutimages"  class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                  <ng-container *ngFor ="let layout of apartvarandspaceData; let l=index">
                    <div class="carousel-item active" [ngClass] = "{'active' : l ===0}" >
                      <img class="img-fluid layout-image w-100 cursorPointer" (click)="openImges(apartvarandspaceData);" src="{{layout.attributes.layout_image }}" onerror="src='../../../assets/images/fllor-plan-default.png'">
                    </div>
                  </ng-container>
                </div>
                <ng-container *ngIf ="apartvarandspaceData.length > 1">
                  <a class="carousel-control-prev" href="#layoutimages" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" href="#layoutimages" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="row flex-column-reverse flex-lg-row">
            <div class="col-lg-8 mt-3 mb-4">
              <div class="row vr-inclusion-design justify-content-lg-start justify-content-center">
                <a  *ngIf = "dataOne.attributes.vr_link"  (click)="vrvideospace(dataOne)" class="nav-link align-self-center cursorPointer ml-lg-0">
                  <span>
                    <img src="../../../assets/images/apartment-detail-images/VR apartment.png">
                  </span>
                  View In VR
                </a>
                <hr *ngIf = "dataOne.attributes.vr_link && storedInitialItems?.data?.length > 0" class="secondHr">
                <ng-container *ngIf = "storedInitialItems && storedInitialItems.data.length > 0">
                  <a  [ngClass] = " dataOne.attributes.vr_link === null || dataOne.attributes.vr_link.length === 0 ? 'vr-link-not-there'  : '' "  (click)="imageopen(apartvarandspaceData[i])" class="nav-link align-self-center cursorPointer mr-lg-0">View Inclusions</a>
                </ng-container>
                <a   [ngClass] = "{'mx-lg-3' : storedInitialItems?.data?.length === 0 }" class=" text-center nav-link design-button cursorPointer" (click)="getThisDesign(dataOne.attributes.apartment_name, dataOne.attributes.city, dataOne.attributes.pincode , dataOne.attributes.format, dataOne.attributes.id, dataOne.attributes.layout_image_file_name); engageDesign(dataOne.attributes.apartment_spaces[i].name,dataOne.attributes.apartment_spaces[i].lifestage,dataOne.attributes.boq_emi)">
                  Get This Design
                </a>
              </div>
            </div>
            <div class="col-lg-12 mb-lg-5 px-lg-3 px-0">
              <div id="apartment-detail{{apartvarandspaceData[i].id}}" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                  <ng-container *ngFor = "let dataCarosel of xyz && xyz[i] ; let m = index">
                    <div  class="carousel-item active" [ngClass]="{'active':m===0}">
                      <img class="d-block w-100 img-fluid image-carosels" style="object-fit: cover;"  [src]="xyz[i][m].attributes.document" alt="First slide" onerror="src='../../../assets/images/default-apartment.png'"> 
                    </div>
                  </ng-container>
                </div>
                <ng-container *ngIf="checkImageData">
                  <a class="carousel-control-prev" href="#apartment-detail{{apartvarandspaceData[i].id}}" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" href="#apartment-detail{{apartvarandspaceData[i].id}}" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </ng-container>
              </div>
            </div>
          </div> 
      </div>
    </div>
  </div>

  <div class="d-lg-block d-none root-hr-0">
    <hr class="w-100">
  </div>
  <div class="previous-and-next">
      <div class="root-pre-next">
        <div class="d-flex cursorPointer">
           <div *ngIf="previousApart" class="d-flex cursorPointer" [routerLink]="['/apartment-detail']"
        [queryParams]="{
          apartment_name: previousApart.apartment_name,
          developer_name: '',
          unid: previousApart.unid,
          city : cityFilter,
          perPage :perPageValue,
          lifestage : lifeStageFilter,
          theme : themeFilter,
          vrlink : vrlinkFilter,
          search : searchFilter
        }">
          <div class="align-self-center">
            <img src="../../../assets/images/apartment-detail-images/left-circle (1).svg" class="mr-2">
            <img class="d-lg-inline d-none mr-2" src="../../../assets/images/apartment-detail-images/left-image.svg">
          </div>
          <div>
            <a class="mb-0 pre-icon">Previous Apartment</a>
            <a class="mb-0 apart-name d-lg-block d-none">{{previousApart.apartment_name}}</a>
          </div>
            </div> 
        </div>
        <div class="d-flex cursorPointer">
          <div *ngIf="nextApart" class="d-flex cursorPointer" [routerLink]="['/apartment-detail']"
          [queryParams]="{
            apartment_name: nextApart.apartment_name,
            developer_name: '',
            unid: nextApart.unid,
            city : cityFilter,
            perPage :perPageValue,
            lifestage : lifeStageFilter,
            theme : themeFilter,
            vrlink : vrlinkFilter,
            search : searchFilter
          }">
            <div class="align-self-center">
              <img src="../../../assets/images/apartment-detail-images/left-circle (2).svg" class="mr-2">
              <img class="d-lg-inline d-none mr-2" src="../../../assets/images/apartment-detail-images/left-image.svg">
            </div>
            <div>
              <a class="mb-0 pre-icon">Next Apartment</a>
              <a class="mb-0 apart-name d-lg-block d-none">{{nextApart.apartment_name}}</a>
            </div>
          </div>
        </div>
      </div>
  </div> 

</div>

<app-madeby-you-stories-new></app-madeby-you-stories-new>

<!-- modal for layout image -->
<div class="modal fade" id="inclusionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="d-flex justify-content-end cursorPointer closeImage">
    <img src="../../../assets/images/close.png" (click)="closemodalimage()"/>
  </div>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
		<div class="modal-content">
      <div class="modal-body city-modal">
        <div class="row">
					<div class="col-12">
   <div id="modalimages" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <ng-container *ngFor ="let modal of openimagedata; let m=index">
        <div class="carousel-item active" [ngClass] = "{'active' : m ===0}">
            <img class="img-fluid layout-image-modal w-100 cursorPointer" src="{{modal.attributes.layout_image}}" onerror="src='../../../assets/images/fllor-plan-default.png'">
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf ="this.apartvarandspaceData.length > 1">
      <a class="carousel-control-prev" href="#modalimages" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#modalimages" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </ng-container>
  </div>
	</div>
	</div>
	</div>
	</div>
	</div>
</div>
<div class="modal inclusion-modal px-0"  id="apartinclusionModal">
  <div class="modal-dialog modal-dialog-centered modal-xl">
    <div class="modal-content serach-button-style closemobile">
      <div class="modal-body">
        <div class="row">
					<img src="../../../assets/images/close-circle-22.png" (click)="closemodal()" class="buttonclose"/>
				</div>
        <div class="row">
          <div class="col-lg-8">
            <div class="row flex-lg-column flex-row">
              <div class="col-lg-7 col-4" *ngIf="apartvarandspaceData">
                <p (click)="closemodal()" class="apart-modal cursorPointer">
                  <span class="mr-2">
                    <img
                      src="../../../assets/images/apartment-detail-images/Icon (1).png"
                    />
                  </span>
                  Back To  <span class="d-lg-inline d-none">{{apartvarandspaceData[0].attributes.apartment_name}}</span>
                </p>
              </div>
              <div class="col-lg-4 col">
                <p class="view-inclu">View Inclusions</p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 align-self-center media-class px-lg-3 px-0 d-lg-block d-none">
            <div class="row flex-column flex-lg-row align-items-center">
                <div class="col-5 align-self-center my-3 my-lg-0">
                  <ng-container *ngIf="viewInclusionData && viewInclusionData.attributes.vr_link">
                    <a (click)="vrvideoInclusion(viewInclusionData.attributes.vr_link)" class="align-self-center cursorPointer vr-inclusion">
                      <span>
                        <img src="../../../assets/images/apartment-detail-images/VR apartment.png">
                      </span>
                      View In VR
                    </a>
                  </ng-container>
                </div>
                <div class="col-lg-7 col-12">
                 <button (click)="getThisDesignmodal()" class="mb-0 design-button-inclusion">Get This Design</button>
                </div>
            </div>
          </div>
        </div>
        <div  class="row mt-lg-0 mt-3">
          <ng-container>
            <div class="name-of-spaces">
              <div class="col-12">
                <div class="row space-row">
                  <div class="d-flex pl-3 bhk-format" *ngIf="viewInclusionData">
                    <p class="mb-0">{{viewInclusionData.attributes.format}}</p>
                    <hr  style="border: none; border-left: 1px solid #898989; height: 12px; margin: 5px 9px;"  >
                  </div>
                  <ng-container *ngIf="viewInclusionData">
                    <ng-container *ngFor="let space of viewInclusionData.attributes.apartment_spaces; let l=index">
                      <a [ngClass] = "{'actived-inclusion' : space.id === inclusionId }"   (click)="onClickInclusionSpaces(space.id, viewInclusionData.attributes.unid, viewInclusionData.attributes.format, space.name );" class="mb-0 cursorPointer selectedSpaces mr-4"  style="color: #8d0528; font-size: 14px ; font-weight: 600; min-width: fit-content;"  >{{space.name | spacePipe}}</a>
                    </ng-container>
                  </ng-container>
                </div>
              </div>
            </div>
            <div class="w-100 mx-3">
              <hr style="border: none; border-bottom: 1px solid gray; margin: 0 auto;">
            </div>
            <div class="col-lg-12">
              <div class="row mt-4">
                <div  [ngClass] = "{'col-lg-12' : storeditems?.data?.length ===0 , 'col-lg-8' : storeditems?.data?.length >0}">
                  <div id="inclusion-carosel" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                      <ng-container *ngFor="let img of inclusionImageData ; let q = index">
                        <div class="carousel-item" [ngClass]="{'active':q===0}">
                          <img class="d-block w-100" [src]="img" alt="Second slide" onerror="src='../../../assets/images/default-apartment.png'">
                        </div>
                      </ng-container>
                    </div>
                    <a class="carousel-control-prev" href="#inclusion-carosel" role="button" data-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#inclusion-carosel" role="button" data-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only">Next</span>
                    </a>
                  </div>
                </div>
                <div class="col-lg-4 col-12"  >
                  <div class="row justify-content-center mt-lg-0 mt-3">
                    <div class="col-lg-12 col-10 presit-item mb-3" *ngFor = "let item of storeditems?.data">
                        <div class=" row">
                          <div class="col-5">
                            <img class="img-fluid" src={{item.attributes.image}} onerror="src='../../../assets/images/default-apartment.png'">
                          </div>
                          <div class="col-7">
                            <div class="title-right text-capitalize">{{item.attributes.item_name}}</div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 align-self-center media-class px-lg-3 px-0 d-lg-none d-block mb-lg-0 mb-3 text-center">
              <div class="row flex-column flex-lg-row align-items-center">
                  <div class="col-5 align-self-center my-3 my-lg-0">
                    <ng-container *ngIf="viewInclusionData && viewInclusionData.attributes.vr_link">
                      <a (click)="vrvideoInclusion(viewInclusionData.attributes.vr_link)" class="align-self-center cursorPointer vr-inclusion">
                        <span>
                          <img src="../../../assets/images/apartment-detail-images/VR apartment.png">
                        </span>
                        View In VR
                      </a>
                    </ng-container>
                  </div>
                  <div class="col-lg-7 col-12">
                    <button (click)="getThisDesignmodal()" class="mb-0 design-button-inclusion">Get This Design</button>
                  </div>
              </div>
            </div>
            <ng-container *ngIf = "viewInclusionData?.attributes?.apartment_spaces.length > 1">
            <hr style="border-top: 1px solid #898989; margin-top: 2rem; margin-bottom: 1.5rem;" class="w-100 d-lg-block d-none"> 
            <div class="col-lg-12">
              <div class="inclusion-modal-prev-next">
                <div class="root-pre-next pb-4">
                  <ng-container *ngIf="inclusionEachData">
                    <div class="d-flex cursorPointer" (click) = "previousSection(inclusionEachData.previous.id,inclusionEachData.previous.space)">
                      <div class="align-self-center">
                        <img src="../../../assets/images/apartment-detail-images/left-circle (1).svg" class="mr-2">
                        <img class="d-lg-inline d-none mr-2" src="../../../assets/images/apartment-detail-images/left-image.svg">
                      </div>
                      <div>
                        <a class="mb-0 pre-icon">Previous Section</a>
                        <a class="mb-0 apart-name d-lg-block d-none">{{previousName}}</a>
                      </div>
                    </div>
                    <div class="d-flex cursorPointer"  (click) ="nextSection(inclusionEachData.next.id,inclusionEachData.next.space)">
                      <div class="align-self-center">
                        <img src="../../../assets/images/apartment-detail-images/left-circle (2).svg" class="mr-2">
                        <img class="d-lg-inline d-none mr-2" src="../../../assets/images/apartment-detail-images/left-image.svg">
                      </div>
                      <div>
                        <a class="mb-0 pre-icon">Next Section</a>
                        <a class="mb-0 apart-name d-lg-block d-none">{{nextName}}</a>
                      </div>
                    </div>
                  </ng-container>
                </div>
            </div>  
            </div>
            </ng-container>  
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>