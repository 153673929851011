<app-new-header-for-home (sendData)="customFunc($event)"></app-new-header-for-home>
<div class="root">
    <div *ngIf ="!allVideos" class="w-100 cursorPointer groungimage" (click)="IntialvideoPlayIn('https://www.youtube.com/embed/HZMaErn5sjM?autoplay=1&loop=1')">
       <!-- <div class="playIconStyles">
          <img src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
       </div> -->
    </div>
    <div class="w-100"  *ngIf = "allVideos">
      <iframe  allow="autoplay" class="w-100" width="420" height="600" [src]="current_url" frameborder="0" allowfullscreen>
      </iframe>
   </div>
  <div class="row select-filters">
    <div class="col-lg-12">
        <div class="row mx-lg-5 my-lg-5 applybackground">
            <div class="col-lg-10">
              <div class="row ">
                  <div class="col-lg-6 my-lg-4 search align-self-center">
                      <div class="row d-lg-block d-md-block d-none">
                              <div class="col-lg-12 my-lg-1">
                                <p class="mb-lg-1 mb-0 mt-lg-0 mt-4 text-center heading">Search</p>
                              </div>
                      </div>
                      <div class="row no-gutters mx-lg-0 mx-1 d-lg-flex d-md-flex d-none  justify-content-center">
                          <div class="col-lg-3 col-4 align-self-center">
                            <label class="d-lg-block d-none" style="position: absolute; padding: 0.3rem 1rem;"><img src="../../../assets/images/finance/236-2363361_search-icon-pin-gray-location-icon-png 1.png"/></label>
                            <div class="search-root-select">
                              <select class="w-100 pl-lg-5  pl-5 cursorPointer" (change)="cityFilter($event)">
                                <option *ngIf="this.city_value === ''" selected disabled>Select</option>
                                <option  value =''  >All</option>
                                <ng-container *ngFor="let item of citydata1">
                                  <option class="text-capitalize" [value]="item.attributes.name" [selected]="item.attributes.name == this.city_value " >{{
                                    transform(item.attributes.name) }}</option>
                                </ng-container>
                            </select>
                            </div>
                          </div>
                          <div class="col-lg-1 col-1 colorforhr" style="background: white;">
                             <hr class="horizontalline" >
                          </div>
                          <div class="col-lg-8 col-7">
                            <div class="input-group group-for-input">
                              <div class="input-group-prepend d-none d-lg-flex" style="border: none;">
                                <div class="input-group-text pl-lg-0 pr-lg-4 px-0" style="border: none; background: white; border-top-left-radius: 1px; border-bottom-left-radius: 1px;">
                                  <img src="../../../assets/images/finance/585e4ae1cb11b227491c3393 1.png"/>
                                </div>
                              </div>
                              <input type="text" class="form-control px-lg-1 px-0 d-lg-block d-none" (keydown.enter)="searchTextFilter($event)" placeholder="Search by apartment name, builder, PIN code">
                              <input type="text" class="form-control px-lg-1 px-0 d-block d-lg-none"  placeholder="Search by apartment name, builder">
                              <!-- <div class="input-group-prepend d-block d-lg-none" style="border: none;">
                                <div class="input-group-text px-lg-3 px-0" style="border: none; background: white; border-top-left-radius: 1px; border-bottom-left-radius: 1px;">
                                  <img src="../../../assets/images/finance/585e4ae1cb11b227491c3393 1.png"/>
                                </div>
                              </div> -->


                              <!-- <input class="form-control border-0 searchinput d-md-block d-none" type="text"
                              placeholder="Search by apartment name, builder, PIN code"
                              (keydown.enter)="searchTextFilter($event)">
                            <input class="form-control border-0 searchinput d-sm-block d-md-none" type="text" placeholder="Search"
                              [(ngModel)]="search_val">
                            <button class="btn badge-1 d-sm-block d-md-none text-capitalize" (click)="searchTextMob()">Go</button> -->



                            </div>
                    
                          </div>
                      </div>

                      <!-- mobile -->
                      <div class="d-lg-none d-md-none d-block mx-4 mobile">
                          <div class="row">

                            <div class="col-lg-12">
                              <p class="mb-lg-1 mb-0 mt-lg-0 mt-4 text-center heading">City</p>
                            </div>

                            <div class="col-lg-12 px-2">
                              <select class="w-100  text-center cursorPointer forMobileselect" (change)="cityFilter($event)">
                                <option *ngIf="this.city_value === ''" selected disabled>Select</option>
                                <option  value =''  >All</option>
                                <ng-container *ngFor="let item of citydata1">
                                  <option class="text-capitalize" [value]="item.attributes.name" [selected]="item.attributes.name == this.city_value " >{{
                                    transform(item.attributes.name) }}</option>
                                </ng-container>
                              </select>
                              <i  class="fa fa-chevron-down mobileForCity " ></i>
                            </div>
                          </div>

                          <div class="row">
                              <div class="col-12">
                                <p class="mb-lg-1 mb-0 mt-lg-0 mt-4 text-center heading">Search</p>
                              </div>
                              <div class="col-12">
                                <div class="row">
                                  <div class="col-10 px-2">
                                    <div class="input-group">
                                      <input [(ngModel)]="search_val" style=" border-top-left-radius: 38px; border-bottom-left-radius: 38px; border-top-right-radius: 38px; border-bottom-right-radius: 38px; " type="text" class="form-control mobileSearch text-center pl-5" id="inlineFormInputGroup" placeholder="Search by apartment name">
                                     <div class="input-group-prepend" >
                                        <div class="input-group-text" 
                                        style="position: absolute;
                                        right: 1%;
                                        margin: 0.5rem 0;
                                        background: none;
                                        border: none;
                                        z-index: 999999999999999999999999;"
                                        >
                                          <img src="../../../assets/images/finance/585e4ae1cb11b227491c3393 1.png"/>
                                        </div>
                                      </div>
                                    </div>
                                </div>
    
                                <div class="col-2 align-self-center">
                                  <button style="background: #8d0528; border: #8d0528;" class="btn btn-primary" (click)="searchTextMob()">GO</button>
                                </div>
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="col-lg-3 my-lg-4 mx-lg-0 mx-3 lifestage align-self-center">
                      <div class="row">
                        <div class="col-lg-12 my-lg-1">
                          <p class="mb-lg-1 mb-0 mt-lg-0 mt-4 text-center heading">Life Stage</p>
                        </div>
                      </div>
                      <div class="row">
                          <div class="col-lg-12 px-lg-0 align-self-center">
                            <select class="w-100 px-lg-3 text-center cursorPointer" (change)="lifeStageFilter($event)">
                              <option *ngIf = "this.lifeStage_filter_value === '' " selected disabled>Select</option>
                              <option  value =''  >All</option>
                              <ng-container *ngFor="let item of lifestage">
                                <option class="text-capitalize" [selected]="item == this.lifeStage_filter_value">{{
                                  transform(item) }}</option>
                              </ng-container>
                            </select>
                            <i  class="fa fa-chevron-down applyArrowDown " ></i>
                          </div>
                      </div>
                  </div>

                  <div class="col-lg-3 theme my-lg-4 mx-lg-0 mx-3 align-self-center">
                    <div class="row">
                      <div class="col-lg-12 my-lg-1">
                        <p class="mb-lg-1 mb-0 mt-lg-0 mt-4 text-center heading">Theme</p>
                      </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 pr-lg-0 align-self-center">
                          <select class="w-100 px-lg-3 text-center cursorPointer" (change)="themeFilter($event)">
                            <option *ngIf ="this.theme_filter_value === '' " selected disabled>Select</option>
                            <option value="" >All</option>
                            <ng-container *ngFor="let item of update_theme">
                              <option class="text-capitalize" [selected]="item == this.theme_filter_value" *ngIf="item
                            !== ''">{{
                                transform(item) }}</option>
                            </ng-container>
                          </select>
                          <i  class="fa fa-chevron-down applyArrowDown " ></i>
                        </div>
                    </div>
                  </div>

              </div>
            </div>
              <div class="col-lg-2 vr-link my-lg-4 align-self-center ml-lg-0 ml-3">
                <div class="row">
                  <div class="col-lg-12 my-lg-1">
                    <p class="mb-lg-1 mb-0 mt-lg-0 mt-4 text-lg-center text-start mx-lg-0 mx-2 heading">Available in VR</p>
                  </div>
                </div>

                <div class="row pt-lg-0 pb-lg-0 pt-2 pb-3">
                  <div class="col-lg-12">
                    <label class="switch ml-lg-n3">
                      <input class="d-none" type="checkbox" (change)="showVr($event)">
                      <span class="slider round">
                        <span class="yesbtn ">{{ vrLink? 'Yes' : 'No' }}</span>
                      </span>
                    </label>
                  </div>
                </div>

              </div>
        </div>
    </div>
  </div>



  <!-- apartment listing -->

  <div class="row root-listing">
    <div class="col-lg-12">
      <div class="row mx-lg-5 my-lg-5">
          <div class="col-lg-12">
            <div class="main-heading my-lg-0 my-5">
              <p class="mb-1 text-lg-left text-center"><span class="pb-1">ARRIVAE LIVING</span></p>
              <span class="mobile_discription ">Find homes designed by Arrivae in your apartment complex
              </span>
              <a style="text-decoration: none;" class="d-lg-block d-none"  routerLink='/visit-us' target="_blank">
                 <button  type="button " class="mt-2 button d-flex align-items-center">SEARCH YOUR CITY &nbsp;
                    <span>
                       <img src="../../../assets/images/Arrow 1.png"/>
                    </span>
                  </button>
              </a>
           </div>
          </div>        
           <div class="container col-lg-12 car-column px-lg-0">
                <div class="row">
                    <div class="col-lg-12 px-lg-0">
                      <div class="eachRowForLoop" style="position: relative;" *ngFor="let data of cityWiseData ; let i = index">
                        <ng-container *ngIf = "  listingCityFilter.includes(data.city) == true ">
                            <p class="name-city d-inline-block my-lg-4">{{data.city}}</p>
                            <div id="pre{{i}}" class="previous">
                              <button class="mx-lg-1 button-one" (click)="slickController.prev() ; apartmentPreviousData(data.city , i)">
                                <img class="cursorPointer"  src="../../assets/images/sign-up-to-service/Arrow copy 2 2.png" alt="previous" >
                              </button>
                            </div>
                            <div class="mx-lg-0 mx-3 forBottomslickImage"  slickContainer #slickController="slick" [slickConfig]=" i % 2 === 0 ? config : config2">
                              <ng-container *ngIf="slickController.initialize">
                                  <div *ngFor ="let item of data.apartments.data ; let j = index">
                                    <div  slickItem class="card ml-lg-3  cursorPointer"  >
                                        <a [routerLink]="['/apartment-detail']" [queryParams]="{
                                          apartment_name: item.attributes.apartment_name,
                                          developer_name: item.attributes.developer_name,
                                          unid: item.attributes.unid,
                                          city : this.finalcity,
                                          perPage : this.per_page,
                                          lifestage : this.lifeStage_filter_value,
                                          theme : this.theme_filter_value,
                                          vrlink : this.vrLink,
                                          search : this.search_string
                                        }" routerLinkActive="active" (click)="apartmentClicked(item.attributes.apartment_name,item.attributes.theme[0],item.attributes.lifestage[0])">
                                          <img class="card-img-top img-fluid" onerror="src='../../../assets/images/Apartmentone.png'" src="{{item.attributes.primary_image}}" alt="Card image cap">
                                        </a>
                                        <div (click)="vrLinkApartment(item.attributes.vr_link)" *ngIf="item.attributes.vr_link" class="vrlink">
                                          <img src="../../../assets/images/carosel-icons/Vr-circle.png">
                                        </div>
                                        <div class="card-body">
                                          <div class="row">
                                            <div class="col-lg-12">
                                              <p class="apartName text-capitalize">{{item.attributes.apartment_name.replace('_', ' ')}}</p>
                                            </div>

                                            <!-- <div class="col-lg-12 pb-lg-3">
                                                <button class="themeLifestage badge px-3 py-1 mt-2 text-capitalize">{{item.attributes.theme[0]}}</button>&nbsp;
                                                <button class="themeLifestage badge mt-2 py-1 px-3 text-capitalize">{{item.attributes.lifestage[0]}}</button>
                                            </div> -->

                                            <div class=" col-lg-12 px-lg-0 mt-lg-0  px-0">
                                              <ul class="nav nav-pills">
                                                <li class="nav-item">
                                                  <a class="nav-link pt-lg-0 px-lg-3 px-3 pt-0 city" style="border-right: 1px solid #000; padding-bottom: 1px; line-height: 1; ">
                                                    {{item.attributes.city}}
                                                  </a>
                                                </li>
                                                <li class="nav-item">
                                                  <a class="nav-link pt-lg-0 px-lg-3 px-3 pt-0 layout" style="border-right: 1px solid #000; padding-bottom: 1px; line-height: 1;">
                                                    {{item.attributes.format.length ===1 ? 'Single Layout' : 'Multiple Layouts'}}
                                                  </a>
                                                </li>
                                                <li class="nav-item">
                                                  <a class="nav-link pt-lg-0 px-lg-3 px-2 pt-0 emi" style="line-height: 1; padding-bottom: 1px;">
                                                    EMI Starts at Rs {{ item.attributes.boq_emi | number:"1.0-0" }}
                                                  </a>
                                                </li>
                                              </ul>
                                          </div>

                                          <!-- <div class="col-lg-12 mt-lg-0 mt-2">
                                            <div class="btnPar" *ngIf="item.attributes.primary_image">
                                              <span>
                                                <button class="redirectButton px-lg-3 py-lg-1 cursorPointer my-lg-3" [routerLink]="['/apartment-detail']" [queryParams]="{
                                            apartment_name: item.attributes.apartment_name,
                                            developer_name: item.attributes.developer_name,
                                            unid: item.attributes.unid,
                                            city : this.finalcity,
                                            perPage : this.per_page,
                                            lifestage : this.lifeStage_filter_value,
                                            theme : this.theme_filter_value,
                                            vrlink : this.vrLink,
                                            search : this.search_string
                                          }" routerLinkActive="active" (click)="apartmentClicked(item.attributes.apartment_name,item.attributes.theme[0],item.attributes.lifestage[0])">
                                                  View Designs
                                                </button>
                                                <button class="btn viewDesignBtn text-center" type="button"
                                                  (click)="getThisDesign( item.attributes.apartment_name , item.attributes.city , item.attributes.pincode , item.attributes.format[0], item.attributes.id )">
                                                  Get A Quote 
                                                </button>
                                              </span>
                                            </div>
                                          </div> -->

                                          </div>
                                        </div>
                                      </div>
                                  </div>      
                            </ng-container>

                            
                          </div>

                          <div id="next{{i}}" class="next">
                            <button  class="mx-lg-1 button-one"  (click)="slickController.next() ; apartmentNextData(data.city , i , 'button'+i )">
                              <img class="cursorPointer" src="../../assets/images/sign-up-to-service/Arrow copy 2 1.png" alt="next" >
                            </button>
                          </div>
                          <div class="loader-progrees" *ngIf ="data.city === nameforcity && loaderPreviousNext">
                            <div class="row mt-4 loader bg-white border-outer align-items-center" >
                              <div class="col-12 d-flex justify-content-center">
                                <div class="ss-spinner ss-spinner--orange mt-0">
                                  <div class="ss-spinner__item1"></div>
                                  <div class="ss-spinner__item2"></div>
                                  <div class="ss-spinner__item3"></div>
                                  <div class="ss-spinner__item4"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </ng-container>

                    </div>
                    </div>
                </div>
          </div> 


      </div>
    </div>
  </div>



  <app-madeby-you-stories-new> </app-madeby-you-stories-new>

    <!-- <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-12 mt-lg-n5 divcontainer">
          <div class="row my-3 align-items-center">
            <div class="col-md-5 col-sm-12">
              <label class="ml-2 mb-0 search">Search</label>
              <div class="input-container">
                <i class="fa fa-map-marker icon mapmarker"></i>
                <select class="form-control border-0 text-capitalize allcities" (change)="cityFilter($event)">
                  <option  value ='' selected>All</option>
                  <ng-container *ngFor="let item of citydata1">
                  
                    <option [value]="item.attributes.name"   >{{
                      transform(item.attributes.name)| titlecase }}</option>
                  </ng-container>
                </select>
                <div class="input-container">
                  <i class="fa fa-search icon border-left" aria-hidden="true"></i>
                  <input class="form-control border-0 searchinput d-md-block d-none" type="text"
                    placeholder="Search by apartment name, builder, PIN code"
                    (keydown.enter)="searchTextFilter($event)">
                  <input class="form-control border-0 searchinput d-sm-block d-md-none" type="text" placeholder="Search"
                    [(ngModel)]="search_val">
                  <button class="btn badge-1 d-sm-block d-md-none text-capitalize" (click)="searchTextMob()">Go</button>
                </div>
              </div>
            </div>
            <div class="col-md-3 brdrbtm">
              <label class="mb-0 lifestages">Life Stage</label>
              <select class="form-control border-0 text-capitalize lifestagedropdown"
                (change)="lifeStageFilter($event)">
                <option selected disabled>Select</option>
                <ng-container *ngFor="let item of lifestage">
                  <option [selected]="item == this.lifeStage_filter_value1">{{
                    transform(item) }}</option>
                </ng-container>
              </select>
            </div>
            <div class="col-md-3">
              <div class="form-group mb-0">
                <label class="mb-0 theme">Theme</label>
                <select class="form-control border-0 text-capitalize themedropdown" (change)="themeFilter($event)">
                  <option selected disabled>Select</option>
                  <ng-container *ngFor="let item of update_theme">
                    <option [selected]="item == this.theme_filter_value" *ngIf="item
                  !== ''">{{
                      transform(item) }}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-md-1">
              <p class="available mb-0 ml-lg-n4 mt-3">Available in VR</p>
              <label class="switch ml-lg-n3">
                <input type="checkbox" (change)="showVr($event)">
                <span class="slider round"></span>
              </label>
              <p class="yesbtn mt-lg-n4 mt-n4">{{ vrLink? 'Yes' : 'No' }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3 d-sm-block d-md-none" *ngIf="gallerydataApartment && gallerydataApartment.length != 0">
        <div class="col viewall">
          Viewing All {{apartment_no}} apartments
        </div>
      </div>

      <div class="row justify-content-center" *ngIf="gallerydataApartment && gallerydataApartment.length > 0">
        <div class="col-md-12 mt-1">
          <div class="row">
            <div class="col-md-6 col-lg-6 col-sm-12 firsthover mt-0 mb-0 px-0 px-lg-3"
              *ngFor="let item of gallerydataApartment">
              <div class="parents">
                <div class="card mt-3 mb-4">
                  <img src="{{item.attributes.primary_image}}" class="lazyload cardimage"
                    onerror="src='../../../assets/images/Apartmentone.png'">
                  <div (click)="vrLinkApartment(item.attributes.vr_link)" *ngIf="item.attributes.vr_link" class="vrlink">
                    <img src="../../../assets/images/carosel-icons/Vr-circle.png">
                  </div>
                  <div class="secondContainer margin-left">
                    <div class="row">
                      <div class="container">
                        <button class="btn badge mt-2 text-capitalize">{{item.attributes.theme[0]}}</button>&nbsp;
                        <button class="btn badge mt-2 text-capitalize">{{item.attributes.lifestage[0]}}</button>
                      </div>
                    </div>
                    <div class="row" *ngIf="item.attributes.primary_image">
                      <div class="col-12 ml-n1">
                        <p class="apName">{{ item.attributes.apartment_name.replace('_', ' ')}}</p>
                      </div>
                    </div>

                    <div class="first">
                      <div class="location">
                       <p class="mb-0 text-capitalize ml-1">{{item.attributes.city}}</p>
                      </div>
                      <span class="mx-2 circle-1"></span>
                      <div class="layout">
                        <ng-container *ngIf="item.attributes.format.length === 1 else first ">
                         <p>Single Layout</p>
                        </ng-container>

                        <ng-template #first>
                         <p>Multiple Layouts</p>
                        </ng-template>
                      </div>
                      <span *ngIf ="item.attributes.boq_emi" class="mx-2 circle-1"></span>
                      <div *ngIf ="item.attributes.boq_emi">
                          <p class="emi mb-0">EMI Starts at Rs {{ item.attributes.boq_emi | number:"1.0-0" }}</p>
                      </div>
                  </div>

                    <div class="btnPar" *ngIf="item.attributes.primary_image">
                      <span>
                        <button class="btn bookConsBtn ml-1" [routerLink]="['/apartment-detail']" [queryParams]="{
                    apartment_name: item.attributes.apartment_name,
                    developer_name: item.attributes.developer_name,
                    unid: item.attributes.unid,
                    city : this.finalcity,
                    perPage : this.per_page,
                    lifestage : this.lifeStage_filter_value,
                    theme : this.theme_filter_value,
                    vrlink : this.vrLink,
                    search : this.search_string
                  }" routerLinkActive="active" (click)="apartmentClicked(item.attributes.apartment_name,item.attributes.theme[0],item.attributes.lifestage[0])">
                          View Designs
                        </button>
                        <button class="btn viewDesignBtn text-center" type="button"
                          (click)="getThisDesign( item.attributes.apartment_name , item.attributes.city , item.attributes.pincode , item.attributes.format[0], item.attributes.id )">
                          Get A Quote 
                        </button>
                      </span>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3 ml-5 mr-5" *ngIf="gallerydataApartment && gallerydataApartment.length == 0">
        <div class="col-md-12 not-found text-center">
          <p class="pb-4 noapartment">
            No Apartment Data Found
          </p>
        </div>
      </div>
    </div>    -->
  <app-footer></app-footer>
  <div class="loading d-flex justify-content-center" *ngIf="loader">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>