<div class="news-root">
    <div class="row">
        <div class="col-lg-12">
            <div class="row justify-content-center justify-content-lg-start">
                <div class="col-lg-12  col-10">
                    <p class="news">In The News</p>
                </div>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="row articles-col justify-content-between g-12">
                <div class="col-lg-4 col-12 custom-news">
                  <a href="https://pynr.in/article/designing-your-home-office-no-place-to-work-like-home" target="_blank">
                    <div class="px-4 py-3 inside-content">
                        <div class="my-3">
                            <img src="../../../assets/images/news-articles/Resized  - Post 1.png"/>
                        </div>
                        <div>
                            <p class="mb-0 news-title">Designing Your Home Office: No Place To Work Like Home</p>
                            <p class="news-text">The uncertain time of the pandemic led to a shift in the work-life scenario and made work from home a global format.
                            Homes took the avatar of an office.</p>
                        </div>
                   </div>
                  </a>
                 </div>
                 <div class="col-lg-4 col-12 custom-news">
                    <a href="https://realty.economictimes.indiatimes.com/news/allied-industries/arrivae-raises-rs-75-crore-from-think-investments-havells-group/89817160" target="_blank">
                        <div class="px-4 py-3 inside-content">
                            <div class="my-3">
                                <img src="../../../assets/images/news-articles/Resized - Post 2.png"/>
                            </div>
                            <div>
                                <p class="mb-0 news-title">Arrivae raises Rs 75 crore in funding from Think Investments, Havells Group</p>
                                <p class="news-text">
                                   Arrivae, a made-to-order furniture manufacturer and home improvement company, has closed its Series B funding round with a Rs. 75 crore investment from Think Investments and Havells Group, the company said in a media release. 
                               </p>
                            </div>
                       </div>
                    </a>
                 </div>
                 <div class="col-lg-4 col-12 custom-news">
                    <a href="https://hr.economictimes.indiatimes.com/news/workplace-4-0/recruitment/arrivae-plans-to-hire-500-designers/88054875" target="_blanks">
                        <div class="px-4 py-3 inside-content">
                            <div class="my-3">
                                <img src="../../../assets/images/news-articles/Resized - Post 3.png"/>
                            </div>
                            <div>
                                <p class="mb-0 news-title">Arrivae plans to hire 500 designers</p>
                                <p class="news-text">
                                  The company is planning to increase its store count by hiring 500 designers on payroll and associate with another 1000 designers, according to a statement.
                              </p>
                            </div>
                       </div>
                    </a>
                  </div>
            </div>
        </div>
    </div>
</div>

