<app-header></app-header>
<div class="container-fluid profile"></div>

<ul class="nav nav-tabs justify-content-center mt-3" id="myTab" role="tablist">
  <ng-container *ngFor="let lifes of themes; let i = index">
    <li class="nav-item" (click)="themeFilter(lifes)">
      <a
        class="nav-link textColor text-capitalize"
        id="love-vibes"
        data-toggle="tab"
        href="#happy"
        role="tab"
        aria-controls="happy"
        aria-selected="false"
        [ngClass]="{ active: i === 0 }"
        style="text-align: -webkit-right"
      >
        {{ lifes.name }}</a
      >
    </li>
  </ng-container>
</ul>

<div class="container-fluid">
  <div *ngIf="theme_filter_value.name === 'Contemporary'">
    <div
      class="tab-pane fade show active"
      id="young"
      role="tabpanel"
      aria-labelledby="young-tab"
    >
      <div class="row imgTexts justify-content-center">
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-5 col-sm-6">
              <img
                src="../../../assets/images/contemporary.png"
                class="img590"
              />
            </div>
            <div class="col-md-7 col-sm-6 textpara">
              While it's sometimes thought that "modern" interiors are cold and
              minimalist, in today's homes, contemporary interiors are
              comfortable and welcoming without being cluttered and dark.
              Today's contemporary style is equally appropriate for offices and
              stores, loft apartments and traditional homes. a contemporary
              style of decorating is defined by simplicity, subtle
              sophistication, deliberate use of texture, and clean lines.
              Interiors tend to showcase space rather than things.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div *ngIf="theme_filter_value.name === 'Eclectic'">
    <div
      class="tab-pane fade show active"
      id="young"
      role="tabpanel"
      aria-labelledby="young-tab"
    >
      <div class="row imgTexts justify-content-center">
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-5 col-sm-6">
              <img src="../../../assets/images/eclectic.png" class="img590" />
            </div>
            <div class="col-md-7 col-sm-6 textpara">
              “Eclectic” is a popular adjective in interior design parlance,
              meaning a decor that comprises heterogeneous elements — a mixture
              of textures, time periods, styles, trends, and colors. There is a
              fine line, however, between a beautifully eclectic room and a room
              that is merely haphazard and chaotic, with no unifying themes or
              motifs. The apartment in an eclectic style looks comfortable,
              beautiful, and unusual. The basic rule of the interior in this
              style is to choose two or three styles (historical styles
              predominately) as a base and blend their characteristics.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div *ngIf="theme_filter_value.name === 'Indian Ethnic'">
    <div
      class="tab-pane fade show active"
      id="young"
      role="tabpanel"
      aria-labelledby="young-tab"
    >
      <div class="row imgTexts justify-content-center">
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-5 col-sm-6">
              <img
                src="../../../assets/images/Indian Ethnic.png"
                class="img590"
              />
            </div>
            <div class="col-md-7 col-sm-6 textpara">
              <!-- “Eclectic” is a popular adjective in interior design parlance, meaning a decor that comprises heterogeneous elements — a mixture of textures, time periods, styles, trends, and colors. There is a fine line, however, between a beautifully eclectic room and a room that is merely haphazard and chaotic, with no unifying themes or motifs.
  The apartment in an eclectic style looks comfortable, beautiful, and unusual. The basic rule of the interior in this style is to choose two or three styles (historical styles predominately) as a base and blend their characteristics. -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div *ngIf="theme_filter_value.name === 'Minimalistic'">
    <div
      class="tab-pane fade show active"
      id="young"
      role="tabpanel"
      aria-labelledby="young-tab"
    >
      <div class="row imgTexts justify-content-center">
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-5 col-sm-6">
              <img
                src="../../../assets/images/Minimalistic.png"
                class="img590"
              />
            </div>
            <div class="col-md-7 col-sm-6 textpara">
              The main mantra of minimalist home decor is simplicity through and
              through. Keep it spare, tone decor down, pare every aspect back,
              and live by a “less is more” and “everything needs a place and a
              reason” approach. Think clean, modern lines, a tight edit, a
              concise color palette, and pared down silhouettes, yet don’t shy
              away from creating a layered, warm, rich, and inviting minimalist
              home with a gallery-like setting from room to room as this is,
              after all, a place of comfort and refuge.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div *ngIf="theme_filter_value.name === 'Modern'">
    <div
      class="tab-pane fade show active"
      id="young"
      role="tabpanel"
      aria-labelledby="young-tab"
    >
      <div class="row imgTexts justify-content-center">
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-5 col-sm-6">
              <img src="../../../assets/images/Modern.png" class="img590" />
            </div>
            <div class="col-md-7 col-sm-6 textpara">
              Modern design encompasses a lot of different incarnations, which
              can make it difficult to define. In its simplest terms, modern
              interior design refers to the reflection of the modern art
              movement on the interiors of the home. There are several central
              characteristics and design themes seen throughout modern design,
              however, that could be described in part as the definition.
              Modernism was a rejection of the ornate flourishes of other design
              styles, such as Gothic, Renaissance and Victorian styles of
              design. Therefore, many of the different modern designs have
              several common components of which the design themes have become
              associated with modern design.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div *ngIf="theme_filter_value.name === 'Rustic Industrial'">
    <div
      class="tab-pane fade show active"
      id="young"
      role="tabpanel"
      aria-labelledby="young-tab"
    >
      <div class="row imgTexts justify-content-center">
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-5 col-sm-6">
              <img src="../../../assets/images/Industrial.png" class="img590" />
            </div>
            <div class="col-md-7 col-sm-6 textpara">
              Rustic Industrial style takes cues from both the rural and urban
              worlds. Using elements like wood furniture (which look great in
              traditional home designs) and metal finishes (which feel right at
              home with modern designs). A wood chair might bring the Rustic
              look, while a metal table brings the Industrial side.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div *ngIf="theme_filter_value.name === 'Transitional'">
    <div
      class="tab-pane fade show active"
      id="young"
      role="tabpanel"
      aria-labelledby="young-tab"
    >
      <div class="row imgTexts justify-content-center">
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-5 col-sm-6">
              <img
                src="../../../assets/images/transitional-theme.png"
                class="img590"
              />
            </div>
            <div class="col-md-7 col-sm-6 textpara">
              Transitional style is a marriage of traditional and contemporary
              furniture, finishes, materials and fabrics equating to a classic,
              timeless design. Furniture lines are simple yet sophisticated,
              featuring either straight lines or rounded profiles. Fabric is as
              diverse as graphic patterns on overstuffed sofas and textured
              chenilles on sleek wood frames.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-md-10">
      <!-- <div class="row"> -->
        <ng-container
          *ngIf="theme_filter_value.name === 'Contemporary'"
        >
        <div class="d-flex mt-4 centermob">
          <div class="titleTriangletheme"
        >OUR WORK FOR CONTEMPORARY
    </div>
        <span class="arrow-down"></span>
      <img
        src="../assets/images/Fill-117.svg"
        class="smallLogoIcon zI1"
        alt="vwuy"
      />
        </div>
        </ng-container>

        <ng-container
          *ngIf="theme_filter_value.name === 'Eclectic'"
        >
        <div class="d-flex mt-4 centermob">
          <div class="titleTriangletheme"
        >OUR WORK FOR ECLECTIC
    </div>
        <span class="arrow-down"></span>
      <img
        src="../assets/images/Fill-117.svg"
        class="smallLogoIcon zI1"
        alt="vwuy"
      />
        </div>
        </ng-container>
        <ng-container
          *ngIf="theme_filter_value.name === 'Indian Ethnic'"
        >
        <div class="d-flex mt-4 centermob">
          <div class="titleTriangletheme"
        >OUR WORK FOR INDIAN ETHNIC
    </div>
        <span class="arrow-down"></span>
      <img
        src="../assets/images/Fill-117.svg"
        class="smallLogoIcon zI1"
        alt="vwuy"
      />
        </div>
        </ng-container>
        <ng-container
          *ngIf="theme_filter_value.name === 'Minimalistic'"
        >
        <div class="d-flex mt-4 centermob">
          <div class="titleTriangletheme"
        >OUR WORK FOR MINIMALISTIC
    </div>
        <span class="arrow-down"></span>
      <img
        src="../assets/images/Fill-117.svg"
        class="smallLogoIcon zI1"
        alt="vwuy"
      />
        </div>
        </ng-container>
        <ng-container
          *ngIf="theme_filter_value.name === 'Modern'"
        >
        <div class="d-flex mt-4 centermob">
          <div class="titleTriangletheme"
        >OUR WORK FOR MODERN
    </div>
        <span class="arrow-down"></span>
      <img
        src="../assets/images/Fill-117.svg"
        class="smallLogoIcon zI1"
        alt="vwuy"
      />
        </div>
        </ng-container>

        <ng-container
          *ngIf="theme_filter_value.name === 'Rustic Industrial'"
        >
        <div class="d-flex mt-4 centermob">
          <div class="titleTriangletheme"
        >OUR WORK FOR RUSTIC INDUSTRIAL
    </div>
        <span class="arrow-down"></span>
      <img
        src="../assets/images/Fill-117.svg"
        class="smallLogoIcon zI1"
        alt="vwuy"
      />
        </div>
        </ng-container>

        <ng-container
          *ngIf="theme_filter_value.name === 'Transitional'"
        >
        <div class="d-flex mt-4 centermob">
          <div class="titleTriangletheme"
        >OUR WORK FOR TRANSITIONAL
    </div>
        <span class="arrow-down"></span>
      <img
        src="../assets/images/Fill-117.svg"
        class="smallLogoIcon zI1"
        alt="vwuy"
      />
        </div>
        </ng-container>

        <div
          class="d-flex p-1 w-100 mt-auto"
          style="justify-content: flex-end"
        >
          <ul class="nav nav-tabs secondSection" id="myTab" role="tablist">
            <ng-container *ngFor="let space of abc; let i = index">
              <li class="nav-item mr-4" (click)="spaceFilter(space)">
                <a
                  class="nav-link"
                  id="contact-tab"
                  data-toggle="tab"
                  href="#contact"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                  [ngClass]="{ active: space_filter_value === space }"
                  >{{ space }}</a
                >
              </li>
            </ng-container>
          </ul>
        </div>
      <!-- </div> -->
    </div>
  </div>
</div>

<!--For Displaying Products-->

<!-- <div class="col-12"> -->
<!-- <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              > -->
<!-- <div class="row justify-content-between"> -->
<div class="d-none d-sm-none d-md-block">
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-md-10 col-12">
        <div class="row">
          <ng-container *ngFor="let images of list?.data; let i = index">
            <!-- <div
                  class=""
                  style="margin-top: 10px;"> -->
            <div class="col-md-3 pr-0 w-100 mt-3" *ngIf="i < 4">
              <!-- <a (click)="btnClick(images.attributes.id)" target="_blank"> -->

              <img
                (click)="btnClick(images.attributes.id)"
                [src]="images.attributes.attachment_file"
                alt="vwuy"
                style="
                  height: 15rem;
                  width: 260px;
                  cursor: pointer;
                  border-radius: 1rem;
                  object-fit: cover;
                "
                onerror="src='../../../assets/images/default-apartment.png'"
              />
              <!-- </a> -->
            </div>

            <!-- </div> -->
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row mt-3 ml-5 mr-5" *ngIf="list && list?.data.length === 0">
      <div class="col-md-12 not-found text-center">
        <p style="font-size: 16px; text-align: center; color: #8d0528">
          Oops No Data To Show
        </p>
      </div>
    </div>
  </div>
  <!-- </div> -->

  <!-- <a href="/spaces" routerLinkActive="current"> -->
  <div class="container">
    <div class="row">
      <div class="col">
        <p
          (click)="viewAllthemes()"
          class="viewAll justify-content-flex-end cursorPointer"
        >
          View All
        </p>
      </div>
    </div>
  </div>
  <!-- </a> -->
</div>

<div class="container-fluid">
  <div class="row d-block d-md-none d-flex" style="margin-top: 10px">
    <div class="col-12 d-flex" style="overflow-x: scroll">
      <ng-container *ngFor="let images of list?.data; let i = index">
        <div class="col-md-3 col-sm-12 mt-3 w-100" *ngIf="i < 4">
          <img
            (click)="btnClick(images.attributes.id)"
            [src]="images.attributes.attachment_file"
            alt="vwuy"
            style="height: 273px; width: 273px"
            onerror="src='../../../assets/images/default-apartment.png'"
          />
        </div>
      </ng-container>
    </div>
    <!-- <div class="row"> -->
    <!-- <div class="col-sm-12"> -->
    <!-- <a href="/spaces" routerLinkActive="current"> -->
    <div class="container">
      <div class="row">
        <div class="col-12">
          <p (click)="viewAllthemes()" class="viewAll justify-content-flex-end">
            View All
          </p>
        </div>
      </div>
    </div>
    <!-- </a> -->
    <!-- </div> -->
    <!-- </div> -->
  </div>
</div>

<div class="container-fluid">
  <div class="row mt-4 justify-content-center mb-5">
    <div class="rectangular4">
      <div class="row">
        <div class="col-3">
          <img src="../assets/images/consulation.png" class="consulation" />
        </div>
        <div class="col-8 free">
          <span>FREE CONSULTATION</span><br />
          <span>Easy Booking</span>
          <a (click)="bookConsultationLandingPage()"
            ><div class="know">KNOW MORE</div></a
          >
        </div>
      </div>
    </div>
    <div class="rectangular5">
      <div class="row">
        <div class="col-3">
          <img src="../assets/images/houseprice.png" class="consulation" />
        </div>
        <div class="col-8 free">
          <span>EMI OPTION ON</span><br />
          <span>COMPLETE PROJECT</span>
          <a style="text-decoration: none" href="/get-finance" target="_blank"
            ><div class="know">KNOW MORE</div></a
          >
        </div>
      </div>
    </div>
  </div>
</div>

<app-aboutusone></app-aboutusone>
<app-aboutustwo></app-aboutustwo>
<app-footer></app-footer>
