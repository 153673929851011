import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApartmentService } from '../apartment/apartment.service';
import { Directive, ElementRef } from '@angular/core';
import { Gtag } from 'angular-gtag';
import { Router, ActivatedRoute } from '@angular/router';

declare var gtag: any;
declare var triggerConsultationEvent2:Function;
declare var trigger:Function;
declare var $: any;


@Component({
  selector: 'app-get-quote-landing-page',
  templateUrl: './get-quote-landing-page.component.html',
  styleUrls: ['../../assets/css/bootstrap.css','./get-quote-landing-page.component.scss','../../assets/css/intlTelInput.min.css']
})
export class GetQuoteLandingPageComponent implements OnInit {
  utm_campaign:any
  utm_content:any
  utm_medium:any
  utm_term:any


  constructor(
    private fb: FormBuilder,
    private apartmentservice: ApartmentService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
   
    { nativeElement }: ElementRef<HTMLImageElement>

  ) { 
    const supports = 'loading' in HTMLImageElement.prototype;

    if (supports) {
      nativeElement.setAttribute('loading', 'lazy');
    }
    this.activatedRoute.queryParamMap.subscribe((params: any) => {
      this.utm_campaign = params.params.utm_campaign
      this.utm_content = params.params.utm_content
      this.utm_medium = params.params.utm_medium
      this.utm_term = params.params.utm_term
    });
  }
  
  BookingDetails:any = FormGroup
  UpadateLead:any = FormGroup
  leadsource:any;

  loadCount :any
  ngOnInit(): void {
    $('.lazy').css("display", "none");
    this.Loader2 = false;
    this.loadCount = 0;
    this.LoadCount2 = 0;
    this.loader = true

    window.addEventListener('scroll', this.scrollEvent, true);
   
   
   
    this.loadScripts();
    this.createForm();
    this.RunScript();
    this.LoadCustomjs();
    this.getNext12MonthNamesWithYear()
    this.toastr.toastrConfig.positionClass = 'toast-top-center';
    this.leadsourceSetter()
  
  }
  private toastOptions = { positionClass: 'toast-bottom-right', tapToDismiss: true };
  createForm(){
   
    this.BookingDetails = this.fb.group({
     name: ['',Validators.required],
     email: ['', [Validators.required,Validators.email]],
     contact: ['',[Validators.required,Validators.minLength(10)]],
     city:[''],
     location:[''],
     pincode :[''],
     apartment:['',Validators.required],
    
     
     
   });

   this.UpadateLead = this.fb.group({
    Property: [''],
    Possession: [''],
    Staying: [''],
    plan:[''],
    typeOFHome:[''], 
    possessionProperty_date:['']
  });
 
 }
 loadScripts(){

  
   
  let node5 = document.createElement('script');
  node5.src = '../../assets/newjs/jquery.min.js';
  node5.async = true;

 
  let node1 = document.createElement('script');
  node1.src = '../../assets/newjs/bootstrap.js';
  node1.async = true;
  document.getElementsByTagName('head')[0].appendChild(node1);
  

 
  

  let node4 = document.createElement('script');
  node4.src = '../../assets/newjs/slick.js';
  node4.async = true;
  document.getElementsByTagName('head')[0].appendChild(node4);
  let node3 = document.createElement('script');
  node3.src = '../../assets/newjs/intlTelInput.min.js';
  node3.async = true;
  document.getElementsByTagName('head')[0].appendChild(node3);
  let node = document.createElement('script');
  node.src = '../../assets/newjs/slick.js';
  node.async = true;
  document.getElementsByTagName('head')[0].appendChild(node);

 

  let node2 = document.createElement('script');
  node2.src = 'https://qr.arrivae.com/assets/js/custom.js';
  node2.async = true;
  setTimeout(() => {
    document.getElementsByTagName('head')[0].appendChild(node2);
    this.loader = false;
    
  }, 1000);
 


 
   
  
   let node7 = document.createElement('link');
   node7.href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css';
   node7.rel ="stylesheet"
   document.getElementsByTagName('head')[0].appendChild(node7);
   let node8 = document.createElement('link');
   node8.href = 'https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&amp;display=swap';
   node8.rel ="stylesheet"
   document.getElementsByTagName('head')[0].appendChild(node8);

   let node10 = document.createElement('link');
   node10.href = 'https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.css';
   node10.rel ="stylesheet"
   document.getElementsByTagName('head')[0].appendChild(node10);
   let node11 = document.createElement('link');
   node11.href = 'https://qr.arrivae.com/assets/css/bootstrap.css';
   node11.rel ="stylesheet"
   document.getElementsByTagName('head')[0].appendChild(node11);
   let node12 = document.createElement('link');
   node12.href = '../../assets/css/slick-theme.css';
   node12.rel ="stylesheet"
   document.getElementsByTagName('head')[0].appendChild(node12);
  
   let node14 = document.createElement('link');
   node14.href = '../../assets/css/slick.css';
   node14.rel ="stylesheet"
   document.getElementsByTagName('head')[0].appendChild(node14);


  


 }
 formattedaddress = ' ';
 PropertyName:any;
 Location:any;

  handleAddressChange(address: any) {

   
    
    this.PropertyName = address.name


    this.BookingDetails.controls['location'].setValue(this.PropertyName);

    this.formattedaddress = address.name;
  
    this.formattedaddress = address.name + "," + address.formatted_address;
    var str = this.formattedaddress;
    var arr = [];
    arr = str.split(",");
    var unique = [];
    for (i = 0; i < arr.length; i++) {
      if ((i == arr.indexOf(arr[i])) || (arr.indexOf(arr[i]) == arr.lastIndexOf(arr[i])))
        unique.push(arr[i]);
    }
    this.formattedaddress = unique.join(",");

    console.log(this.formattedaddress);
    console.log(address);
    for (var i = 0; i < address.address_components.length; i++) {
    
      for (var j = 0; j < address.address_components[i].types.length; j++) {
       
        if (address.address_components[i].types[j] == 'postal_code') {
         
        
          console.log(address.address_components[i].long_name);
          
        ;
        this.BookingDetails.controls['pincode'].setValue(address.address_components[i].long_name) 
      
          
        } else{
          this.BookingDetails.controls['pincode'].setValue("");
        }

        this.BookingDetails.controls['apartment'].setValue(
             this.formattedaddress)

            if (address.address_components[i].types[j] == 'locality') {
              this.BookingDetails.controls['city'].setValue(
                address.address_components[i].long_name
              );
              console.log(this.BookingDetails.controls['city'].value);
              console.log(address.address_components[i].long_name);

            } 

      }
    }

  } 
  DetailsSubmit(){
    this.loader = true
    console.log(this.BookingDetails.value)
    const postObj = {
      lead: {
        name: this.BookingDetails.value.name,
        contact: this.BookingDetails.value.contact,
        lead_source: this.leadsource,
        lead_campaign: this.utm_campaign,
        lead_utm_medium: this.utm_medium,
        lead_utm_term: this.utm_term,
        lead_utm_content: this.utm_content,
        email: this.BookingDetails.value.email,
        scope_of_work:'',
        city: this.BookingDetails.value.city,
        pincode: this.BookingDetails.value.pincode,
        location: this.BookingDetails.value.apartment,
        property_name:  this.PropertyName,
        additional_comments:''
        
          
      }


    };

    this.apartmentservice.createBookConsultation(postObj).subscribe(res=>{
     

      this.toastr.success(
        'Thanks for sharing your preferences. Our designers will get in touch with you in 24 hours','',{
          timeOut: 2000,
          positionClass:'toast-top-center',
          progressBar:true
        });
        this.sucessfun()
      $('#Edit').modal('show');
      this.leadData = res;
      this.loader = false

    },err=>{
      this.loader = false
      this.toastr.error(err.error.message,'',{
        timeOut: 2000,
        positionClass:'toast-top-center',
        progressBar:true

      });


    })

  }
  checkDisable(){
    if(this.BookingDetails.invalid){
      return true
    } else{
      return false
    }
  }

  Submitdetailsupdate(){
    console.log(this.UpadateLead.value);
   this.updateleadstatusdata()


  }

  updateLeadData:any;
  loader:boolean = false;
  leadData:any;

  updateleadstatusdata() {
    this.loader = true
    const formData = new FormData();
    formData.append('file', this.fileupload);
   

    let formdt = new FormData();

    formdt.append('lead_source', this.leadsource);
    formdt.append('email', this.BookingDetails.value.email);
    formdt.append(
      'scope_of_work',
      this.UpadateLead.value.typeOFHome
    );
    formdt.append('city', this.BookingDetails.value.city);
    formdt.append('location',this.BookingDetails.value.apartment);
    formdt.append('pincode', this.BookingDetails.value.pincode);
   
    formdt.append(
      'lead_campaign',
      this.utm_campaign
    );
    formdt.append(
      'lead_utm_medium',
      this.utm_medium
    );
    formdt.append(
      'lead_utm_term',
      this.utm_term
    );
    formdt.append(
      'lead_utm_content',
      this.utm_content
    );
    formdt.append(
      'possession_status',
       this.UpadateLead.value.Possession
    );
    formdt.append(
      'project_start_date',
    ""
    );
    formdt.append(
      'property_name',
     this.PropertyName
    );
    formdt.append(
      'customer_budget',
      ""
    );
    formdt.append(
      'accomodation_type',
   this.UpadateLead.value.Property
    );
    formdt.append(
      'purpose_of_property',
     ""
    );
    formdt.append('home_value', "");
    formdt.append(
      'have_home_loan',
     ""
    );
    formdt.append(
      'financial_solution_required',
     ""
    );
    formdt.append(
      'additional_comments',
     ""
    );
    formdt.append(
      'lead_status',
     "not_attempted"
    );
    formdt.append(
      'have_floorplan',
      this.UpadateLead.value.plan
    );
    if (this.fileupload) {
      formdt.append('lead_floorplan[]', this.fileupload, this.fileupload.name);
    } else {
      formdt.append('lead_floorplan[]', '');
    }
    formdt.append(
      'purpose_of_property',
      this.UpadateLead.value.Staying
    );
    formdt.append(
      'home_type',
     ""
        ? 'New (No one has ever stayed in that home)'
        : 'Old (Currently staying in the house)'
    );
    //Lead status data
    
    formdt.append('lead_questionaire_items_attributes', 'null');
    formdt.append(
      'possession_status_date',
      this.UpadateLead.value.possessionProperty_date
    );
    formdt.append(
      'project_type',
      "Residential"
    );

    this.apartmentservice
      .updateleadstatus(this.leadData.lead.id, formdt)
      .subscribe(
        (res) => {
          this.loader = false;
          this.updateLeadData = res;
          this.toastr.success(
            'Thanks for sharing your preferences. Our designers will get in touch with you in 24 hours',"",{
              timeOut: 2000,
              positionClass:'toast-top-center',
              progressBar:true

            }
          );

          this.sucessfun()


          $('#Edit').modal('hide');
          this.createForm();

        },
        (error) => {
          this.loader = false
          this.toastr.error(error.error.message,"",{
            timeOut: 2000,
            positionClass:'toast-top-center',
            progressBar:true
          });
        }
      );
  }

  scrollto(){
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

 RunScript(){
var myIndex = 0;
carousel();

function carousel() {
  var i;
  var x:any = document.getElementsByClassName("mySlides");
  for (i = 0; i < x.length; i++) {
    x[i].style.display = "none";  
  }
  myIndex++;
  if (myIndex > x.length) {myIndex = 1}    
  x[myIndex-1].style.display = "block";  
  setTimeout(carousel, 3000);    
}




  }
  LoadCustomjs(){
  
    let node2 = document.createElement('script');
  node2.src = 'https://sumitarrivae.github.io/landingpage2/assets/js/custom.js';
  node2.async = true;
  setTimeout(() => {
    document.getElementsByTagName('head')[0].appendChild(node2);
  }, 1000);
  }
  

  roletoSet(){
    var width = Math.max(window.screen.width, window.innerWidth)
    if(width>450){
      window.scrollTo({ top: 400, behavior: 'smooth' });

    } else{
      $([document.documentElement, document.body]).animate({
        scrollTop: $(".header-contact-form-item").offset().top
    }, 200);
    }
    

  }
  fileupload: any;
  onFileSelect(event: any) {
    if (event.target.files.length > 0) {
      this.fileupload = event.target.files[0];
    }
  }

  possession_date:any;
  project_start_date:any
  getNext12MonthNamesWithYear() {
    var now = new Date();
    var month = now.getMonth();
    var year = now.getFullYear();
    var names = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    this.possession_date = [];
    for (var i = 0; i < 13; ++i) {
      this.possession_date.push(names[month] + ' ' + year);
      if (++month === 12) {
        month = 0;
        ++year;
      }
    }
    this.project_start_date = this.possession_date;
    return this.possession_date;
  }

 lazyload(e:any){
   console.log(e)
  setTimeout(() => {
    $('.lazy').css("display", "block");

    
  }, 1000);

 
console.log("hh")
  





 }
 
 LoadCount2:any;
 Loader2:any
 

 scrollEvent = (event: any): void => {
  const number = event.srcElement.scrollTop;
  if(this.loadCount <1){
    $('.lazy').css("display", "block");
  let nodelove = document.createElement('script');
  nodelove.src =  "https://cdnjs.cloudflare.com/ajax/libs/fancybox/3.5.7/jquery.fancybox.js";
  nodelove.async = true;
  this.loadScript();
  setTimeout(() => {
   
    this.Loader2 = true;
  }, 1000);
 
  document.getElementsByTagName('head')[0].appendChild(nodelove);
  this.loadCount++

  }
  

}
sucessfun(){

  
  
  function gtag_report_conversion(url: any) {
    var callback = function () {
      if (typeof (url) != 'undefined') {  
        console.log(window.location.href)
      }
    };
   gtag('event', 'conversion', 
    { 'send_to': 'AW-825543995/1NecCM7y6ZIBELua04kD',
     'event_callback': callback });
     console.log(window.location.href)
     return false;
    
  }

  gtag_report_conversion(window.location.href)
  let url = window.location.href;
  let spl = url.split('/');

  if(spl.indexOf('f') || spl.indexOf('g')>-1){
    triggerConsultationEvent2()
    console.log("success")

  }
}
loadScript(){
 

    let google = document.createElement('script');
    google.src =  "https://maps.googleapis.com/maps/api/js?libraries=geometry,places&key=AIzaSyADhbP6QmjHlcay55cH4SklR2HmeJ_VC98";
    google.async = true;
    google.defer = true
    document.getElementsByTagName('head')[0].appendChild(google);
  


   

  }
  showgoogle(){
    this.Loader2 = true;
  }

  leadsourceSetter(){
    let url = window.location.href;
     let spl = url.split('/');

     if(spl.indexOf('f')>-1 || spl.indexOf('g')>-1 ){
       this.leadsource ="digital_marketing"
     } else{
      this.leadsource ="website"
     }
     console.log(this.leadsource)
  }

  


}


