<div class="wrapper">
    <div class="heading">
        <h1>Video gallery</h1>
        <!-- <a href="#">See all</a> -->
    </div>

    <div class="cards-container">
        <div *ngFor="let response of responses" class="card">
            <div class="card-video" (click)="playVideoModelComponent(response.url)">
                <div class="videoThumbnail">
                    <img src="{{response.videoThumbnail}}" alt="">
                </div>
                <div class="playpause">
                    <img src="../../../assets/images/play-fill.svg" alt="play-fill">
                </div>
            </div>
            <div class="card-body">
                <p class="card-text">{{response.content}}</p>
                <div class="card-footer">
                    <p>{{response.name}}</p>
                </div>
            </div>
        </div>
        <!-- <div class="card">
            <div class="card-video" (click)="playVideoModelComponent(url2)">
                <div class="videoThumbnail">
                    <img [src]="videoThumbnail2" alt="">
                </div>
                <div class="playpause">
                    <img src="../../../assets/images/play-fill.svg" alt="play-fill">
                </div>
            </div>
            <div class="card-body">
                <p class="card-text">We feel blessed that we choose Arrivae whose expert designer make our dream come true with our newly bought 2 BHK with his out of box design suggestions.</p>
                <div class="card-footer">
                    <p>Swathy Sajjalgud</p>
                </div>
            </div>
        </div> -->
        <!-- <div class="card">
            <div class="card-video" (click)="playVideoModelComponent(url='https://www.youtube.com/embed/21KjRjtrbJM')">                <div class="videoThumbnail">
                    <img src="https://img.youtube.com/vi/kvAfmYNtugQ/0.jpg" alt="">
                </div>
                <div class="playpause">
                    <img src="../../../assets/images/play-fill.svg" alt="play-fill">
                </div>
            </div>
            <div class="card-body">
                <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sagittis, sollicitudin nunc, sit at ac nulla diam. Urna, non risus pellentesque varius. Faucibus mattis non integer augue eu id vitae. Id feugiat ac in turpis tellus lacus scelerisque id. </p>
                <div class="card-footer">
                    <p>Jenny Wilson</p>
                </div>
            </div>
        </div> -->
    </div>

    <div class="key-specifications" style="position: relative;">
        <div class="background">
            <img style="width: 100%;" src="../../../assets/images/Key_specifications@3x.webp" alt="key-specifications">
        </div>
        <div class="row row-container" style="position: absolute;">
            <div class="grid-box col-6 col-xs-6 col-md-4 d-flex flex-column">
                <img src="../../../assets/images/key specifications icons/63-home-outline 1.svg" alt="63-home-outline">
              <p><span class="numberCountUp">13,500</span> Spaces Designed</p>
            </div>
            <div class="grid-box col-6 col-xs-6 col-md-4 d-flex flex-column">
                <img src="../../../assets/images/key specifications icons/18-location-pin-outline 1.svg" alt="63-home-outline">
              <p><span class="numberCountUp">19</span>+ Cities</p>
            </div>
            <div class="grid-box col-6 col-xs-6 col-md-4 d-flex flex-column">
                <img src="../../../assets/images/key specifications icons/497-truck-delivery-outline 1.svg" alt="63-home-outline">
              <p><span class="numberCountUp">60</span> Day Delivery</p>
            </div>
            <div class="grid-box col-6 col-xs-6 col-md-4 d-flex flex-column">
                <img src="../../../assets/images/key specifications icons/453-savings-pig-outline 2.svg" alt="63-home-outline">
              <p>Lowest Financial Rates</p>
            </div>
            <div class="grid-box col-6 col-xs-6 col-md-4 d-flex flex-column">
                <img src="../../../assets/images/key specifications icons/457-shield-security-outline 1.svg" alt="63-home-outline">
              <p><span class="numberCountUp">10</span> Years Warrranty</p>
            </div>
            <div class="grid-box col-6 col-xs-6 col-md-4 d-flex flex-column">
                <img src="../../../assets/images/key specifications icons/781-rulers-outline 1.svg" alt="63-home-outline">
              <p><span class="numberCountUp">10</span> Lakh Designs</p>
            </div>
        </div>
        <div class="row row-container row-container-mobile" style="position: absolute;">
            <div class="grid-box col-6 col-xs-6 col-md-4 d-flex flex-column">
                <img src="../../../assets/images/key specifications icons/63-home-outline 1.svg" alt="63-home-outline">
              <p><span class="numberCountUp">13,500</span> Spaces Designed</p>
            </div>
            <div class="grid-box col-6 col-xs-6 col-md-4 d-flex flex-column">
                <img src="../../../assets/images/key specifications icons/453-savings-pig-outline 2.svg" alt="63-home-outline">
              <p>Lowest Financial Rates</p>
            </div>
            <div class="grid-box col-6 col-xs-6 col-md-4 d-flex flex-column">
                <img src="../../../assets/images/key specifications icons/781-rulers-outline 1.svg" alt="63-home-outline">
              <p><span class="numberCountUp">10</span> Lakh Designs</p>
            </div>
            <div class="grid-box col-6 col-xs-6 col-md-4 d-flex flex-column">
                <img src="../../../assets/images/key specifications icons/18-location-pin-outline 1.svg" alt="63-home-outline">
              <p><span class="numberCountUp">19</span>+ Cities</p>
            </div>
            <div class="grid-box col-6 col-xs-6 col-md-4 d-flex flex-column">
                <img src="../../../assets/images/key specifications icons/497-truck-delivery-outline 1.svg" alt="63-home-outline">
              <p><span class="numberCountUp">60</span> Day Delivery</p>
            </div>
            <div class="grid-box col-6 col-xs-6 col-md-4 d-flex flex-column">
                <img src="../../../assets/images/key specifications icons/457-shield-security-outline 1.svg" alt="63-home-outline">
              <p><span class="numberCountUp">10</span> Years Warrranty</p>
            </div>
        </div>
    </div>
</div>