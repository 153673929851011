import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LifestageComponent } from './lifestage/lifestage.component';
import { SharedModule } from '../shared/shared.module';
import { RemoveUnderscorePipe } from './remove-underscore.pipe';



@NgModule({
  declarations: [LifestageComponent, RemoveUnderscorePipe],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [LifestageComponent]
})
export class LifestageModule { }
