import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-latest-blogs-new',
  templateUrl: './latest-blogs-new.component.html',
  styleUrls: ['./latest-blogs-new.component.scss']
})
export class LatestBlogsNewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.roletoSet()
  }
  roletoSet(){
    var width = Math.max(window.screen.width, window.innerWidth)
     return width

  }
}
