import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class SpaceService {
  private boardURL = environment.apiBaseUrl;
  // spaceName: string = '';
  //apiURL: string = 'http://13.233.79.39';
  constructor(public http: HttpClient) { }

  public getPortfolios(id: any) {
    return this.http.get(`${this.boardURL}/v1/portfolios/` + id)
  }

  public getPortfolioList(spaceName: string, lifeStage: string, theme: string, range : any ,current_page: any, per_page: any, search_string: any) {
    return this.http.get(`${this.boardURL}/v1/portfolios?space=${spaceName}&lifestage=${lifeStage}&element&theme=${theme}&range=${range}&page=${current_page}&limit=${per_page}&search=${search_string}`);
  }

  // id  , space ,lifeStage , theme , range

  public getSinglePortfolioList(id :any , space : any , lifeStage : any , theme :any , range:any ){
    // /portfolios/627?space=Dining Room&lifestage=happy_vibes&theme=&range=&range
    return this.http.get(`${this.boardURL}/v1/portfolios/${id}?space=${space}&lifestage=${lifeStage}&theme=${theme}&range=${range}`)
  }

  public getDropdownspace() {
    return this.http.get(`${this.boardURL}/v1/portfolios/filter_datas`);
  }

  public getSpacesItem() {
    return this.http.get(`${this.boardURL}/v1/portfolios/space_items`)
  }

  // https://qa-marketing-api.arrivae.com/v1/portfolios/all_other_spaces?id=664&page=1&per_page=2

  public getExtraSpaceItem(id :any , page:any , per_page :any){
    return this.http.get(`${this.boardURL}/v1/portfolios/all_other_spaces?id=${id}&page=${page}&per_page=${per_page}`)
  }

}
