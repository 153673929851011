import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
@Component({
  selector: 'app-preset-packages',
  templateUrl: './preset-packages.component.html',
  styleUrls: ['./preset-packages.component.scss']
})
export class PresetPackagesComponent implements OnInit {
  uid: any;
  access_token: any;
  client: any;
  loader: boolean = false;
  spaces: any;
  selectedSpace: any;
  selectedRange: any;
  spaceData: any;

  constructor(private sharedService: SharedService, public http: HttpClient) { }

  ngOnInit(): void {
    this.uid = localStorage.getItem("UID");
    this.access_token = localStorage.getItem("access-token");
    this.client = localStorage.getItem("client") ? localStorage.getItem("client") : '';
    this.spacesHandler();
    this.loadScript()
  }

  spacesHandler() {
    this.sharedService.spacesHandler(this.uid, this.client, this.access_token).subscribe(data => {
      var sData: any = data
      this.spaces = sData && sData.spaces;
      this.selectedSpace = this.spaces.length > 0 && this.spaces[0].space;
      this.selectedRange = this.spaces.length > 0 && this.spaces[0].range;
      this.selectedSpace && (this.selectedRange || this.selectedRange === 0) && this.spaceDataHandler();
    })
  }
  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  spaceDataHandler() {
    this.sharedService.spaceDataHandler(this.uid, this.client, this.access_token, this.selectedSpace, this.selectedRange).subscribe(data => {
      var sData: any = data
      this.spaceData = sData && sData.data;
    })
  }

  spaceTabHandler(spaceData: any) {
    this.selectedSpace = spaceData.space;
    this.selectedRange = spaceData.range;
    this.spaceDataHandler()
  }

  afterChangeHandler() {
    this.spaceDataHandler();
  }
}
