import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testing-map',
  templateUrl: './testing-map.component.html',
  styleUrls: ['./testing-map.component.scss'],
})
export class TestingMapComponent implements OnInit {
  constructor() {}
  formattedaddress = ' ';
  ngOnInit(): void {
    this.loadScript()
  }

   AddressChange(address: any) {
    //setting address from API to local variable
    this.formattedaddress = address.formatted_address;
  }
  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
