import { Component, OnInit, ViewChild , HostListener} from '@angular/core';
import { Chart } from 'chart.js';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../authentication/authentication.service';
import { Slick } from 'ngx-slickjs';
import { CurrencyPipe } from '@angular/common';
// import * as DataLabelPlugin from 'chartjs-plugin-datalabels' 
import { ToastrService } from 'ngx-toastr';
import {  HttpResponse } from '@angular/common/http';


import * as _moment from "moment";
const moment = (_moment as any).default ? (_moment as any).default : _moment;

declare var $: any;

@Component({
  selector: 'app-customer-boq',
  templateUrl: './customer-boq.component.html',
  styleUrls: ['./customer-boq.component.scss'],
})
export class CustomerBoqComponent implements OnInit {
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastr:ToastrService
  ) {}

  urlToken: any;
  boqToken: any;
  boqNumber: any;
  boq_status_data: any = [];
  linksActive: any = 'summary';
  loader_explore: boolean = false;
  paymentForm :any
  public doughChartOptions: any = {
    pieceLabel: {
      render: function (args:any) {
        return args.value + '\n' + '(' + args.percentage + '%)';
      },
      fontColor: '#000',
      fontSize: '8',
    },
  };
  deltauser:any
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      console.log(params)
      this.deltauser = params['deltauser']
    });
    $('#levelTwoModal').on('hide.bs.modal', function () {
      if ($('#level-two-modal').hasClass('level-two-modal-show')) {
        $('#level-two-modal').removeClass('level-two-modal-show');
        $('#level-two-modal').modal('hide');
      }
    });

    this.urlToken = localStorage.getItem('boqLogin');
    if (this.urlToken.split('=').length > 0) {
      this.boqToken = this.urlToken.split('=')[1];
      this.boqNumber = this.urlToken.split('=')[2];
    }

    $('#mychart').show();

    this.boqMagicLogin();
    this.boqusernamedetails();
    this.spaceString = 'spaceWise';
    this.initialLosseValue = 'furniture';
    this.spaceTypeWise('spaceWise');
    this.looseFurniture('furniture');
    this.linksActive = 'summary';
    this.paymentForm = this.formBuilder.group({
      amount : ['', 
      [
        Validators.required,
        Validators.max(this.maxVal)
      ]
      ],
      email: ['', [Validators.required, Validators.email]],
      mobile_number :  ['',  [Validators.required , Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$") , Validators.maxLength(10) ]  ],
      lead_id : [''],
      web_boq : true

    })
    $("#aggrementPoints").on("contextmenu", function(e:any) {
      return false;
    });
  }
  config: Slick.Config = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    autoplay: false,
    autoplaySpeed: 2000,
    draggable: false,
    mouseWheelMove: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  canvas: any;
  ctx: any;
  @ViewChild('mychart') mychart: any;
  @ViewChild('typeWise') typeWise: any;

  sideBarData: any;
  catDetails: any;
  catList: any;
  loader: boolean = false;
  loader_for_timeline :boolean = false
  show_moreDetail: boolean = false;
  typeForLevelOne: any;
  initialModal(e: any) {    
    this.typeForLevelOne = e;
    if (e === 'non_panel') {
      this.show_moreDetail = true;
    } else {
      this.show_moreDetail = false;
    }
    this.boqId = localStorage.getItem('Boqid');
    this.authService.sideBarLevelTwo(this.boqId, e).subscribe((res) => {
      this.sideBarData = res;
      this.catDetails = this.sideBarData.data[0].cat_details;
      this.displayCleaningFee =  this.catDetails[0].cleaning_fee
      this.catList = this.sideBarData.data[0].ctagory_list;
      this.typeofboq = this.catDetails[0].boq_type
    });
  }

  spaceModalData :any
  spaceHeadingOne :any
  lisitingWithImage :any
  displayCleaningFee :any
  typeofboq :any
  sidePanelSpaceApi(e:any){
    this.displayCleaningFee =  this.tableBreakUp.quote_type_caluclation[0].cleaning_fee 
    this.boqId = localStorage.getItem('Boqid');
    this.authService.spaceWiseApi(this.boqId,e).subscribe((res) => {
    this.spaceModalData = res
    this.spaceHeadingOne  = this.spaceModalData.data.category_list
    this.typeofboq = this.spaceHeadingOne[0].boq_type
    this.lisitingWithImage = this.spaceModalData.data.category_data.all_items
    })
  }
  levelThreeData: any;
  levelThreeModal(name: any, id: any) {
    // $('#levelTwoModal').modal("hide");
    // if($('#level-Three-Modal').hasClass('level-Three-Modal-show')) {
    //   $('#levelThreeModal').modal("hide");
    //   $('#level-Three-modal').removeClass('level-Three-modal-show')
    // } else {
    //   $('#levelThreeModal').modal("show");
    //   $('#level-Three-modal').addClass('level-Three-modal-show')
    // }
    this.authService.levelThreeModal(name, id).subscribe((res) => {
      this.levelThreeData = res;
    });
  }

  initialLosseValue: any;
  TotalItems: any;
  looseFurniture(e: any) {
    this.nextItemPage = 1;
    this.initialLosseValue = e;
    this.loader_explore = true;
    if (this.initialLosseValue === 'appliances') {
      this.authService
        .moreItemsToExplore('1', '6')
        .subscribe((res: HttpResponse<any>) => {
          this.exploreMoreItems = JSON.parse(res.body);
          this.TotalItems = res.headers.get('x-total');
          this.TotalItems = Math.round(this.TotalItems / 6);
          setTimeout(() => {
            this.loader_explore = false;
          }, 2000);
        });
    }
    if (this.initialLosseValue === 'furniture') {
      this.authService
        .customMoreItems('1', '6')
        .subscribe((res: HttpResponse<any>) => {
          this.exploreMoreItems = JSON.parse(res.body);
          this.TotalItems = res.headers.get('x-total');
          this.TotalItems = Math.round(this.TotalItems / 6);
          setTimeout(() => {
            this.loader_explore = false;
          }, 2000);
        });
    }
  }
  exploreMoreItems: any;
  nextItemPage: any = 1;
  previousItemPage: any;
  nextButton(e: any) {
    this.loader_explore = true;
    this.nextItemPage++;

    if (this.nextItemPage > this.TotalItems) {
      this.nextItemPage = 1;
    }
    if (e === 'furniture') {
      this.authService
        .customMoreItems(this.nextItemPage, '6')
        .subscribe((res: HttpResponse<any>) => {
          this.exploreMoreItems = JSON.parse(res.body);
          setTimeout(() => {
            this.loader_explore = false;
          }, 2000);
        });
    }
    if (e === 'appliances') {
      this.authService
        .moreItemsToExplore(this.nextItemPage, '6')
        .subscribe((res: HttpResponse<any>) => {
          this.exploreMoreItems = JSON.parse(res.body);
          setTimeout(() => {
            this.loader_explore = false;
          }, 2000);
        });
    }
  }
  previousValue: any;
  totalPages: any;
  previousButton(e: any) {
    this.loader_explore = true;
    if (this.nextItemPage === 1) {
      this.TotalItems--;
      this.previousValue = this.TotalItems;
      this.nextItemPage = this.previousValue;
    }

    if (this.nextItemPage > 1) {
      this.previousValue = this.nextItemPage - 1;
      this.nextItemPage = this.previousValue;
    }
    if (e === 'furniture') {
      this.authService
        .customMoreItems(this.previousValue, '6')
        .subscribe((res: HttpResponse<any>) => {
          this.exploreMoreItems = JSON.parse(res.body);
          setTimeout(() => {
            this.loader_explore = false;
          }, 2000);
        });
    }
    if (e === 'appliances') {
      this.authService
        .moreItemsToExplore(this.previousValue, '6')
        .subscribe((res: HttpResponse<any>) => {
          this.exploreMoreItems = JSON.parse(res.body);
          setTimeout(() => {
            this.loader_explore = false;
          }, 2000);
        });
    }
  }

  access_token: any;
  uid: any;
  client: any;
  boqCustomerDetails: any;
  projectId: any;
  boqId: any;

  boqMagicLogin() {
    this.authService.boqMagicLogin(this.boqToken, this.boqNumber).subscribe(
      (res) => {
        if (localStorage.getItem('client') == null) {
          this.boqCustomerDetails = res.body;
          this.projectId = this.boqCustomerDetails.project_id;
          this.boqId = this.boqCustomerDetails.boq_number;
          localStorage.setItem('projectId', this.projectId);
          localStorage.setItem('Boqid', this.boqId);
          this.access_token = res.headers.get('access-token');
          this.uid = res.headers.get('uid');
          this.client = res.headers.get('client');
          localStorage.setItem('uid', this.uid);
          localStorage.setItem('access_token', this.access_token);
          localStorage.setItem('client', this.client);
       
          this.boqusernamedetails();
          this.spaceTypeWise('spaceWise');
          this.looseFurniture('furniture');
          this.webBoqTimeLine();
        }
      },
      (err) => {
        this.webBoqTimeLine();
      }
    );
  }
  spaceString: any = 'spaceString';
  customerCardDetails: any;
  projectSummaryDetails: any;
  boqSummary: any;
  costBreakUpSpaceWise: any;
  costBreakupTypeWise: any;

  spaceWiseAmount: any = [];
  spaceWiseName: any = [];
  spaceWiseColors :any = []

  typeWiseAmount: any = [];
  typeWiseName: any = [];
  typeWiseCOlors :any = []

  tableBreakUp: any;

  nextStepByArrivae: any;
  nextStepByYou: any;
  teamDetails: any;
  otherActiveBoqs: any;
  leadPayementDetails : any

  ngAfterViewInit() {
    if (this.spaceString === 'spaceWise') {
      $('#typeWise').hide();
      $('#mychart').show();
      this.typeWiseAmount = [];
      this.typeWiseName = [];
      this.canvas = this.mychart.nativeElement;
      this.ctx = this.canvas.getContext('2d');
      new Chart(this.ctx, {
        type: 'doughnut',
        data: {
          labels: this.spaceWiseName,
          datasets: [
            {
              label: 'Space Wise',
              data: this.spaceWiseAmount,
              backgroundColor: this.spaceWiseColors
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem: any, data: any) {
                const sum = data.datasets[0].data.reduce(
                  (accumulator: any, curValue: any) => {
                    return accumulator + curValue;
                  }
                );
                return (
                  data['labels'][tooltipItem['index']] +
                  ': ' +
                  '₹' +
                  new Intl.NumberFormat('en-IN').format(
                    data['datasets'][0]['data'][tooltipItem['index']]
                  ) +
                  ' (' +
                  Number(
                    (data['datasets'][0]['data'][tooltipItem['index']] / sum) *
                      100
                  ).toFixed(0) +
                  '%' +
                  ')'
                );
              },
            },
          },
        },
      });
    }
  }

  erroralert: boolean  = false 
  // errorMessage :any
  // errorMessageShow(msg:any) {
  //   this.errorMessage = msg;
  //   setTimeout(() => {
  //     this.erroralert = false;
  //   }, 2000);
  // }
  srcArray :any
  boqCancelledMessage : boolean = false
  calculateDifferenceDate:any
  leastDate :any
  stage : any
  boqusernamedetails() {
    this.loader = true;
    this.projectId = localStorage.getItem('projectId');
    this.boqId = localStorage.getItem('Boqid');
    this.authService
      .BOqUserNameDetails(this.projectId, this.boqId)
      .subscribe((res) => {
        this.customerCardDetails = res;
        if(this.customerCardDetails.message === 'Quotation not found'){
          this.erroralert = false
          if (confirm('This Boq is Cancelled') == true) {
            this.router.navigate(['/']);
          }
        }
        else {
          this.erroralert = true
          this.projectSummaryDetails =
          this.customerCardDetails.data.project_summary;
          this.boqSummary = this.customerCardDetails.data.boq_summary;
          this.stage = this.boqSummary.boq_summary_data[0].wip_status
          this.tableBreakUp = this.customerCardDetails.data.cost_breakup_detailed;
  
        
          if(this.tableBreakUp.quote_type_caluclation[0].promo_applied.length > 1){
            this.tableBreakUp.quote_type_caluclation[0].promo_applied.map((e:any , i:any) => {
              if(e.expiry_date !== null){
                var given = moment( e.expiry_date, "YYYY-MM-DD");
                var current = moment().startOf('day', "YYYY-MM-DD" );
                this.calculateDifferenceDate = moment.duration(given.diff(current)).asDays()
                this.tableBreakUp.quote_type_caluclation[0].promo_applied[i].value = this.calculateDifferenceDate
                console.log(this.tableBreakUp.quote_type_caluclation[0].promo_applied);
              }
            })
          }
  
          if(this.tableBreakUp.quote_type_caluclation[0].promo_applied.length === 1){
            var given = moment(  this.tableBreakUp.quote_type_caluclation[0].promo_applied[0].expiry_date , "YYYY-MM-DD");
            var current = moment().startOf('day', "YYYY-MM-DD" );
            this.calculateDifferenceDate = moment.duration(given.diff(current)).asDays()
          }
  
          this.nextStepByArrivae =
            this.customerCardDetails.data.boq_next_step.next_step_by_arrivae;
          this.nextStepByYou =
            this.customerCardDetails.data.boq_next_step.next_step_by_you;
          this.teamDetails = this.customerCardDetails.data.team_details;
          this.otherActiveBoqs = this.customerCardDetails.data.other_active_boq;
          this.costBreakUpSpaceWise =
            this.customerCardDetails.data.modular_jobs.space_uniq;
          this.costBreakUpSpaceWise.map((e: any) => {
            this.spaceWiseAmount.push(Math.round(e.amount));
            this.spaceWiseName.push(e.space);
            this.spaceWiseColors.push(e.color)
          });
          this.leadPayementDetails = this.customerCardDetails.data.lead_payment_request
          this.paymentForm.controls["amount"].setValue(this.leadPayementDetails.amount_to_be_paid.toFixed(2).toString());
          this.paymentForm.controls["email"].setValue(this.leadPayementDetails.email);
          this.paymentForm.controls["mobile_number"].setValue(this.leadPayementDetails.phone_number);
          this.paymentForm.controls["lead_id"].setValue(this.leadPayementDetails.lead_id);
          this.paymentForm.controls["web_boq"].setValue("true");
        }
        setTimeout(() => {
          this.loader = false;
        }, 2000);
      });
  }

  check_1: boolean = false;
  check_2: boolean = false;
  check_3: boolean = false;

  webBoqTimeLine() {
    // this.loader_for_timeline = true
    this.projectId = localStorage.getItem('projectId');
    this.boqId = localStorage.getItem('Boqid');
    this.authService
      .webBoqTimeLine(this.projectId, this.boqId)
      .subscribe((res: any) => {
        this.boq_status_data = res;
        if (this.boq_status_data.data[0].boq_timeline_level === 'level_2' && this.boq_status_data.data[0].boq_status !== 'Sent to Production' ) {
          
          this.check_1 = true;
          this.check_2 = true;
          this.check_3 = false;
          this.check2();
          $('#vert-2').attr('checked', 'checked');
        }
        if (this.boq_status_data.data[0].boq_timeline_level === 'level_1') {
          
          this.check_1 = true;
          this.check_2 = false;
          this.check_3 = false;
          this.check1();
          $('#vert-1').attr('checked', 'checked');
        }
        if ( (this.boq_status_data.data[0].boq_timeline_level === 'level_2' &&  this.boq_status_data.data[0].boq_status === 'Sent to Production'   ) || (this.boq_status_data.data[0].boq_timeline_level === 'level_3')  ){
          
          this.check_1 = true;
          this.check_2 = true;
          this.check_3 = true;
          this.check3();
          $('#vert-3').attr('checked', 'checked');
        }
        // setTimeout(() => {
        //   if (this.boq_status_data.data[0].boq_timeline_level === 'level_2') {
        //     this.check2();
        //     $('#vert-2').attr('checked', 'checked');
        //   }
        //   if (this.boq_status_data.data[0].boq_timeline_level === 'level_1') {
        //     this.check1();
        //     $('#vert-1').attr('checked', 'checked');
        //   }
        //   if (this.boq_status_data.data[0].boq_timeline_level === 'level_3') {
        //     debugger;
        //     this.check3();
        //     $('#vert-3').attr('checked', 'checked');
        //   }
        //   this.loader_for_timeline = false;
        // }, 1500);
      });
  }

  chartTypes: any;
  tableDataForTypeWise :any
  spaceTypeWise(e: any) {
    this.loader = true;
    this.typeWiseAmount = [];
    this.typeWiseName = [];
    this.typeWiseCOlors = []

    this.spaceString = e;
    this.authService
      .BOqUserNameDetails(this.projectId, this.boqId)
      .subscribe((res) => {
        this.chartTypes = res;
        this.costBreakUpSpaceWise =
          this.chartTypes.data.modular_jobs.space_uniq;
        this.costBreakupTypeWise = this.chartTypes.data.cost_break_type_wise;
        this.costBreakupTypeWise.map((e: any, i:any) => {
          this.typeWiseAmount.push(Math.round(e.amount));
          // this.typeWiseName.push(
          //   e.quote_type === 'mto' ? e.quote_type.toUpperCase() :  e.quote_type[0].toUpperCase() +  e.quote_type.slice(1) 
          // );
          this.typeWiseName.push(e.capitalized_quote_type)
          this.typeWiseCOlors.push(e.color)
        });
        if (e === 'spaceWise') {
          $('#typeWise').hide();
          $('#mychart').show();
        }
        if (e === 'typeWise') {;
          this.tableDataForTypeWise = this.tableBreakUp
          $('#typeWise').show();
          $('#mychart').hide();
          this.canvas = this.typeWise.nativeElement;
          this.ctx = this.canvas.getContext('2d');
          new Chart(this.ctx, {
            type: 'doughnut',
            data: {
              labels: this.typeWiseName,
              datasets: [
                {
                  label: 'type wise',
                  data: this.typeWiseAmount,
                  backgroundColor: this.typeWiseCOlors
                },
              ],
            },
            options: {
              legend: {
                display: false,
              },
              
              tooltips: {
                callbacks: {
                  label: function (tooltipItem:any, data:any) {
                    const sum = data.datasets[0].data.reduce((accumulator:any, curValue:any) => {
            return accumulator + curValue;
          });
                    return (
                      data['labels'][tooltipItem['index']] +
                      ': ' +
                      '₹' +
                      new Intl.NumberFormat('en-IN').format(
                        data['datasets'][0]['data'][tooltipItem['index']]
                      ) +
                      ' (' +
                      Number(
                        (data['datasets'][0]['data'][tooltipItem['index']] /
                          sum) *
                          100
                      ).toFixed(0) +
                      '%' +
                      ')'
                    );
                  },
                },
              },
            },
          });
        }
        setTimeout(() => {
          this.loader = false;
        }, 2000);
      });
  }
  approveBoq(e: any, proposalId: any) {
    this.loader = true;
    this.authService.approveRejectBoq(e, proposalId).subscribe((res) => {

      setTimeout(() => {
        this.loader = false;
      }, 2000);
      this.boqusernamedetails();
      this.webBoqTimeLine()
    });
  }
  redirectToBoqLink(data: any) {
    if(data !== null){
      window.open(data, '_blank');
    }else {
      this.toastr.error('The BOQ Is Cancelled');
    }
  }

  costTop :any
  paymentTop :any
  nextTop :any
  moreItemsTop :any
  moreBoqTop :any
  termsTop :any

  @HostListener('window:scroll', ['$event'])
  scrollHandler() {
    this.costTop = $('#costBreak').offset().top
    this.paymentTop = $('#paymentSection').offset().top
    this.nextTop = $('#gdfgfdg').offset().top
    this.moreItemsTop = $('#moreItemsToExplore').offset().top
    this.moreBoqTop = $('#numberofBoqs').offset().top
    this.termsTop = $('#aggrementPoints').offset().top
    
    if(window.scrollY  >= this.costTop-15){
      this.linksActive = 'costBreak'
    }

    if(window.scrollY >= this.paymentTop-15){
      this.linksActive = 'paymentDetail'
    }
    if(window.scrollY >= this.nextTop-15){
      this.linksActive = 'nexxtSteps';
    }
    if(window.scrollY >= this.moreItemsTop-15){
      this.linksActive = 'mooreItems';
    }
    if(window.scrollY >= this.moreBoqTop-15){
      this.linksActive = 'otherActiveBoqs';
    }
    if(window.scrollY >= this.termsTop-15){
      this.linksActive = 'termsconditions';
    }
    if(window.scrollY === 0){
      this.linksActive = 'summary';
    }
  }
  scrollToCostBreak(e: any) {
    $(window).scrollTop($(e).offset().top);
  }
  scrollToSummary(e: any) {
    $(window).scrollTop($(e).offset().top);
  }

  nextSteps(l: any) {
    $(window).scrollTop($(l).offset().top);
  }

  moreItems(m:any) {
    $(window).scrollTop($(m).offset().top);
  }
  GoToPayment(p:any){
    $(window).scrollTop($(p).offset().top);
  }
  moreBoqs(b: any) {
    $(window).scrollTop($(b).offset().top);
  }
  terms(t: any) {
    $(window).scrollTop($(t).offset().top);
  }
  logout() {
    if (confirm('Are You Sure to Logout') == true) {
      localStorage.removeItem('projectId');
      localStorage.removeItem('Boqid');
      localStorage.removeItem('uid');
      localStorage.removeItem('access_token');
      localStorage.removeItem('client');
      this.router.navigate(['/']);
    }
  }

  DownloadFile() {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../assets/images/customerWarranty.pdf');
    link.setAttribute('download', `customerWarranty.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  checked1: boolean = true;
  checked2: boolean = false;
  checked3: boolean = false;

  check1() {
    this.checked1 = true;
    this.checked3 = false;
    this.checked2 = false;
  }
  check2() {
    this.checked2 = true;
    this.checked1 = false;
    this.checked3 = false;
  }
  check3() {
    this.checked3 = true;
    this.checked2 = false;
    this.checked1 = false;
  }


  messageForthreekTenK : boolean = false
  DisplayErrormsg :any

  checkThreepercenttenkValidation(){
    let x = this.leadPayementDetails.boq_value * 0.1
    if(this.paymentForm.value.amount < 1000000){
      if(x > 10000){
        if( this.paymentForm.value.amount >=10000 ){
          this.messageForthreekTenK = false
          this.paymentSubmit()
        }else{
          this.messageForthreekTenK = true
          this.DisplayErrormsg = "Amount should be greater than ₹10,000"
        }
      }else if( x <10000 && this.paymentForm.value.amount > this.leadPayementDetails.boq_value * 0.03){
        this.messageForthreekTenK = false
        this.paymentSubmit()
      }else{
        this.messageForthreekTenK = true
        this.DisplayErrormsg = "Amount should be greater than 3% of of BOQ value"
      }
    }else{
      this.messageForthreekTenK = true
      this.DisplayErrormsg = "10 lakh condition"
    }
  }
  minVal = 1000;
  maxVal = 1000000
  paymentUrl :any
  displayemailError : boolean = false
  cashFreeSubmit : boolean = false
  quotations_ids :any = []
  paymentSubmit(){
    console.log('check payment submit');
    
    this.boqId = localStorage.getItem('Boqid');
    let x = this.boqId.split('/')[3]
    console.log(x);

    this.quotations_ids.push(x)
  
    var email = /^[(A-Z)(a-z)0-9._%+-]+@[(A-Z)(a-z)0-9.-]+\.[(a-z)(A-Z)]{2,4}$/;

    this.cashFreeSubmit  =true
    console.log(this.paymentForm);
    if(this.paymentForm.invalid){
      return
    }
      if(this.paymentForm.value.email && this.paymentForm.value.email.match(email)){
          console.log('block api');
          this.loader = true
          this.displayemailError = false
          this.authService.submitDetailsForPayment(this.paymentForm.value , this.quotations_ids , this.stage).subscribe((res)=>{
           this.paymentUrl = res
           setTimeout(() => {
            this.loader = false;
          }, 1500);
           window.open(this.paymentUrl.payment_url, '_blank');
         }, (err) => {
          this.loader = false          
          this.toastr.error(err.error.message);
         })
      
      }else{
        console.log('else block');
         this.displayemailError = true
       }
  }
  promoData :any
  promoId : any = []
  promoCodeApis(){
    this.boqId = localStorage.getItem('Boqid');
    let x = this.boqId.split('/')[3]
    this.authService.promoApi(x).subscribe((res) => {      
      this.promoData = res
      if(this.promoData.promo_packs){
        this.promoId = this.promoData.promo_packs
      }
      if(this.promoData.message){
          this.promoId = []
      }
    })
    // var given = moment(  "2022-06-15"  , "YYYY-MM-DD");
    // var current = moment().startOf('day');
    // this.calculateDifferenceDate = moment.duration(given.diff(current)).asDays()
    // console.log(this.calculateDifferenceDate);
  }

  checkforOther(){
    let index = this.costBreakUpSpaceWise.findIndex(function(el:any){
      return el.space == 'Other'
    })
    if(index == -1){
      return true
    }else{
      return false
    }
  }
}
