import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-become-design-partner',
  templateUrl: './become-design-partner.component.html',
  styleUrls: ['./become-design-partner.component.scss']
})
export class BecomeDesignPartnerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.loadScript()
  }

  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

}
