import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spaces-landing',
  templateUrl: './spaces-landing.component.html',
  styleUrls: ['./spaces-landing.component.scss']
})
export class SpacesLandingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
