import { Component, OnInit,OnDestroy } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from "@angular/platform-browser";
import { Subject } from 'rxjs';
import { BookConsultationFormComponent } from '../shared/book-consultation-form/book-consultation-form.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'src/app/shared/shared.service';
import * as _moment from "moment";
import { ToastrService } from 'ngx-toastr';
import { Slick } from 'ngx-slickjs';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

declare var $: any;

@Component({
  selector: 'app-marathon-nexajone',
  templateUrl: './marathon-nexajone.component.html',
  styleUrls: ['./marathon-nexajone.component.scss']
})
export class MarathonNexajoneComponent implements OnInit {
  current_url: any;

  constructor(     private _DomSanitizationService: DomSanitizer,
    private modalService: NgbModal,private fb: FormBuilder,
        public sharedService: SharedService,private toastr: ToastrService, ) { }
  parentSubject: Subject<any> = new Subject();
  additionalApointmentform: any = FormGroup;
  additionalApartmentDetails : any = FormGroup
  today:any

  ngOnInit(): void {

    $('.whatsapp-img').css('display','none');
    this.additionalApointmentform = this.fb.group({
      name: ['',Validators.required],
      phoneNumber : ['',[Validators.required,Validators.minLength(10)]],
      date : ['',Validators.required],
      time :['',],
      experience_center:['',Validators.required]

    });
    this.additionalApartmentDetails = this.fb.group({
      namee: ['',Validators.required],
      phone_number : ['', [Validators.required , Validators.pattern("[0-9 ]{10}")]]
    });
    this.GetLocations()
    var currentdatee: any = new Date();
    this.today= moment(currentdatee).format('YYYY-MM-DD')
    
  }
  ngOnDestroy(): void{

    $('.whatsapp-img').css('display','block')


}

allVideos :any = false

  getQuote(){
    this.bookConsultationLandingPage()
  }
  bookConsultationLandingPage() {
    const modal = this.modalService.open(BookConsultationFormComponent, {
      size: 'md',
    });
    
  }

  roletoSet() {
    var width = Math.max(window.screen.width, window.innerWidth)
    return width
  }
  
  config: Slick.Config = {
    centerMode : true,
    centerPadding : '60px',
    infinite: true,
    slidesToShow: 4,
   
    autoplay: false,
    autoplaySpeed: 2000,
    draggable: true,
    mouseWheelMove: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding : '20px',

        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding : '20px',
          arrows : false,
          centerMode : true
        },
      },
    ],
  };
  
  videoPlayIn(url :any){
    console.log('clicking');
    this.allVideos = true
    this.current_url=this._DomSanitizationService.bypassSecurityTrustResourceUrl(url)
  }

  IntialvideoPlayIn(url :any){
    this.allVideos = true
    this.current_url=this._DomSanitizationService.bypassSecurityTrustResourceUrl(url)
  }
  
    mobilevideoPlayIn2(url :any){
    this.allVideos = true
    this.current_url=this._DomSanitizationService.bypassSecurityTrustResourceUrl(url)
  }

  ActiveSpan:any ="";

  transform(value: string): string {
    if(value =='140-Shivaji2'){
      value = '140-shivaji'
    }
    
  return value.replace(/-/g, " ");}
 
  BuildingNameGetter() {
    const url = window.location.href;
    const buildingNames:any = {
      'marathon-nexzone': 'marathon-nexzone',
      '25-south': '140-shivaji',
      'anantraj-villa': 'anantraj-villa',
      'southern-crest': 'southern-crest',
      'sky-forest': 'sky-forest',
      'm3m-skywalk': 'm3m-skywalk',
      'Tridhaatu-Morya': 'Tridhaatu-Morya',
      'Hubtown_Seasons': 'Hubtown_Seasons',
      'Hubtown_Heaven': 'Hubtown_Heaven',
      'Hubtown_Harmony': 'Hubtown_Harmony',
      'Tridhaatu-Aranya': 'Tridhaatu-Aranya',
      'adhiraj-capital-city': 'adhiraj-capital-city',
      'Runwal_Gardens' : 'Runwal_Gardens',
      'dosti-pine':'dosti-pine',
      'lodha-amara':'lodha-amara',
      'Birla-Alokya':'Birla-Alokya',
      'Rustomjee-Summit':'Rustomjee-Summit'

    };
  
    for (const name in buildingNames) {
      if (url.includes(name)) {
        return buildingNames[name];
      }
    }
  
    return '140-Shivaji2';
  }
  getUrl(){
 
    if(this.BuildingNameGetter() =='marathon-nexzone'){
      return "url('../../assets/images/Banner_marathon.png')";
    } else{
      return "url('../../assets/images/bannersivaji.png')";
    }

    
  }
  getUrlmid(){

    if(this.BuildingNameGetter() =='marathon-nexzone'){
      return "url('https://s3.amazonaws.com/arrivae-production/contents/documents/000/087/705/original/data?1681469133')";
    } else{
      return "url('https://s3.amazonaws.com/arrivae-production/contents/documents/000/074/979/original/data?1668247606')";
    }

    
  }
  BookApointment(){
    $('#ApointmentBook').modal('show');
    this.ActiveSpan ='';
    this.additionalApointmentform.controls['experience_center'].setValue('')
    this.additionalApointmentform.controls['date'].setValue(this.today)

  }

  StartTime:any
  EndTime:any;

  AddSpanTimeSlot(e1:any,e2:any){
    console.log(this.additionalApointmentform.value.date,'bbb')
    if(this.additionalApointmentform.value.date !='' && this.additionalApointmentform.value.date){

      this.ActiveSpan = e1;
    

      this.ActiveSpan = this.ActiveSpan.toString()
  
       console.log(typeof this.ActiveSpan)
      let e3 = this.ActiveSpan.split('-');
  
      if(e2=='AM'){
        this.StartTime = e3[0];
        this.EndTime = e3[1]
      } else{
        if (e3[0] == 12){
          e3[0]= 0
        }
        this.StartTime =parseInt(e3[0])+12;
        this.EndTime = parseInt(e3[1])+12
      }
  

    } else{
      this.toastr.error('Select Date First')
    }
   
    console.log( this.StartTime, this.EndTime,"timezone")

   
    
  }
  LocationsFormarket:any =[]
  GetLocations(){
    this.sharedService.getlocationsForMarketing().subscribe((res:any)=>{

  this.LocationsFormarket = res.meeting_locations;
  ;
    })
  }
  addapoint(){
    console.log( this.additionalApointmentform.value);

    let date = this.additionalApointmentform.value.date ;

    date = date.split('-');
    console.log(date,'splitted')

    console.log(new Date(date[0],date[1]-1,date[2],this.StartTime))
   
  }
  loader = false;

  CreateShedular(){


    let date = this.additionalApointmentform.value.date ;

    date = date.split('-');
    console.log(date,'splitted');


    let starttime =new Date(date[0],date[1]-1,date[2],this.StartTime);
    let endtime =new Date(date[0],date[1]-1,date[2],this.EndTime)
    let obj ={
      scheduler_appointment:{
        name : this.additionalApointmentform.value.name,
        contact_number : this.additionalApointmentform.value.phoneNumber,
        start_time : starttime,
        end_time : endtime,
        apartment_name : this.transform(this.BuildingNameGetter()=='Runwal_Gardens'?'Runwal Gardens':this.BuildingNameGetter()),
        meeting_location :this.additionalApointmentform.value.experience_center,
        apartment_id :''

      }

    }
    this.loader = true
    this.sharedService.CreateShedular(obj).subscribe((res:any)=>{
      console.log(res);
      this.loader = false;
      $('#ApointmentBook').modal('hide');
   

      this.ActiveSpan ='';
      this.additionalApointmentform.reset();
      this.additionalApointmentform.controls['experience_center'].setValue('')
      this.toastr.success(res.message)

    },err=>{

      this.loader = false;

      this.toastr.error('Error Occured')

    })
    
  }

  NameAndPhn(data:any){    
    let Mailerdata = {
      optin_mailer: {
        name : data.namee,
        contact_number : data.phone_number,
        apartment_name : this.transform(this.BuildingNameGetter()),
      }
    }
    this.loader = true
    this.sharedService.CreateMailer(Mailerdata).subscribe((res:any) => {
      this.toastr.success(res.message)
      this.loader = false
      this.additionalApartmentDetails.reset()
    }, err => {
      this.toastr.error('Error Occured')
    })
  }
successmessage(file:any){
  if(this.roletoSet() < 600){
    this.toastr.success('Pdf File is Downloading ...')

    setTimeout(() => {
      window.open(file,'_blank')
    }, 2000);

   
   
  } else{
    window.open(file,'_blank')
  }
}
}
