import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApartmentSearchComponent } from './apartment-search/apartment-search.component';
import { ApartmentLandingComponent } from './apartment-landing/apartment-landing.component';
import { SharedModule } from '../shared/shared.module';
import { ApartmentGalleryComponent } from './apartment-gallery/apartment-gallery.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchFilterPipe } from '../pipes/search-filter.pipe';
import { AppRoutingModule } from '../app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ApartmentGetThisDesignFormComponent } from './apartment-get-this-design-form/apartment-get-this-design-form.component';
import { RouterModule } from '@angular/router';
import { UpdatedApartmentPageComponent } from './updated-apartment-page/updated-apartment-page.component';
import { UpdatedApartmentComponent } from './updated-apartment/updated-apartment.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSlickJsModule } from 'ngx-slickjs';
import { LandingModule } from '../landing/landing.module';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';





@NgModule({
  declarations: [ApartmentSearchComponent, 
    ApartmentLandingComponent,
     ApartmentGalleryComponent,
     SearchFilterPipe,
     ApartmentGetThisDesignFormComponent,
     UpdatedApartmentPageComponent,
     UpdatedApartmentComponent,
     
    ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    AppRoutingModule,
    NgbModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxSlickJsModule.forRoot({
      links: {
        jquery: '',
        slickJs:
          'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.min.js',
        slickCss:
          'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css',
        slickThemeCss:
          'https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css',
      },
    }),

    LandingModule,
    
    NgSelectModule,
    Ng2SearchPipeModule,
    GooglePlaceModule,
  ],
  exports : [ApartmentSearchComponent , UpdatedApartmentComponent]
  
})
export class ApartmentModule { }
