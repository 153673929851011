<div class="container-fluid">
  <div class="row">
    <div class="col-md-3 offset-md-1 col-xs-12">
      <img
        src="../assets/images/Fill 116.svg"
        class="Fill-116-package"
        alt="vwuy"
      />
      <p class="packages-by-us">PACKAGES BY US</p>
      <img src="../assets/images/Fill 117.svg" class="Fill-117" alt="vwuy" />
    </div>

    <div class="col-md-2"></div>
    <div class="d-flex" style="margin-top: 29px">
      <div class="col-md-5 col-5">
        <a href="#" class="side-tab"><small>Modular Kitchen</small> </a>
      </div>
      <div class="col-md-5 col-5">
        <a href="#" class="side-tab"><small>Modular Wardrobe</small></a>
      </div>
      <div class="col-md-2 col-2">
        <a href="#" class="side-tab"><small>Essentials</small></a>
      </div>
    </div>
  </div>
  <div class="row d-none d-sm-none d-md-flex" style="margin-top: 10px">
    <div class="col-md-2 offset-md-1 px-0" style="margin-left: 223px">
      <img src="../assets/images/package 1.png" class="package-1" alt="vwuy" />
      <div class="package-house-1">
        <div class="text-style-1">The Modern Housewife</div>
        200 Sq Ft
        <div>EMI starts @5500 INR/ Month</div>
      </div>
      <button type="button" class="btn btn-sm consultbtn-1">
        <span class="text-btn">View More</span>
      </button>
      <button type="button" class="btn btn-sm consultbtn-2">
        <span class="text-btn-2">Consult With Us</span>
      </button>
    </div>
    <div class="col-md-2 col-xs-12 px-0">
      <img src="../assets/images/package 2.jpg" class="package-1" alt="vwuy" />
      <div class="package-house-1">
        <div class="text-style-1">The Modern Housewife</div>
        200 Sq Ft
        <div>EMI starts @5500 INR/ Month</div>
      </div>
      <button type="button" class="btn btn-sm consultbtn-1">
        <span class="text-btn">View More</span>
      </button>
      <button type="button" class="btn btn-sm consultbtn-2">
        <span class="text-btn-2">Consult With Us</span>
      </button>
    </div>
    <div class="col-md-2 col-xs-12 px-0">
      <img src="../assets/images/package 3.jpg" class="package-1" alt="vwuy" />
      <div class="package-house-1">
        <div class="text-style-1">The Modern Housewife</div>
        200 Sq Ft
        <div>EMI starts @5500 INR/ Month</div>
      </div>
      <button type="button" class="btn btn-sm consultbtn-1">
        <span class="text-btn">View More</span>
      </button>
      <button type="button" class="btn btn-sm consultbtn-2">
        <span class="text-btn-2">Consult With Us</span>
      </button>
    </div>
    <div class="col-md-2 col-xs-12 px-0">
      <img src="../assets/images/package 4.jpg" class="package-1" alt="vwuy" />
      <div class="package-house-1">
        <div class="text-style-1">The Modern Housewife</div>
        200 Sq Ft
        <div>EMI starts @5500 INR/ Month</div>
      </div>
      <button type="button" class="btn btn-sm consultbtn-1">
        <span class="text-btn">View More</span>
      </button>
      <button type="button" class="btn btn-sm consultbtn-2">
        <span class="text-btn-2">Consult With Us</span>
      </button>
    </div>
  </div>
  <!-- For Mobile Screen-->
  <div class="row d-block d-md-none d-flex" style="margin-top: 10px">
    <div class="col-12 d-flex" style="overflow-x: scroll">
      <div class="col-md-2 offset-md-1">
        <img
          src="../assets/images/package 1.png"
          class="package-1"
          alt="vwuy"
        />
        <div class="package-house-1">
          <div class="text-style-1">The Modern Housewife</div>
          200 Sq Ft
          <div>EMI starts @5500 INR/ Month</div>
        </div>
        <button type="button" class="btn btn-sm consultbtn-1">
          <span class="text-btn">View More</span>
        </button>
        <button type="button" class="btn btn-sm consultbtn-2">
          <span class="text-btn-2">Consult With Us</span>
        </button>
      </div>
      <div class="col-md-2 col-xs-12">
        <img
          src="../assets/images/package 2.jpg"
          class="package-1"
          alt="vwuy"
        />
        <div class="package-house-1">
          <div class="text-style-1">The Modern Housewife</div>
          200 Sq Ft
          <div>EMI starts @5500 INR/ Month</div>
        </div>
        <button type="button" class="btn btn-sm consultbtn-1">
          <span class="text-btn">View More</span>
        </button>
        <button type="button" class="btn btn-sm consultbtn-2">
          <span class="text-btn-2">Consult With Us</span>
        </button>
      </div>
      <div class="col-md-2 col-xs-12">
        <img
          src="../assets/images/package 3.jpg"
          class="package-1"
          alt="vwuy"
        />
        <div class="package-house-1">
          <div class="text-style-1">The Modern Housewife</div>
          200 Sq Ft
          <div>EMI starts @5500 INR/ Month</div>
        </div>
        <button type="button" class="btn btn-sm consultbtn-1">
          <span class="text-btn">View More</span>
        </button>
        <button type="button" class="btn btn-sm consultbtn-2">
          <span class="text-btn-2">Consult With Us</span>
        </button>
      </div>
      <div class="col-md-2 col-xs-12">
        <img
          src="../assets/images/package 4.jpg"
          class="package-1"
          alt="vwuy"
        />
        <div class="package-house-1">
          <div class="text-style-1">The Modern Housewife</div>
          200 Sq Ft
          <div>EMI starts @5500 INR/ Month</div>
        </div>
        <button type="button" class="btn btn-sm consultbtn-1">
          <span class="text-btn">View More</span>
        </button>
        <button type="button" class="btn btn-sm consultbtn-2">
          <span class="text-btn-2">Consult With Us</span>
        </button>
      </div>
    </div>
  </div>
</div>
