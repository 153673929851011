import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LandingService } from '../landing.service';

@Component({
  selector: 'app-design-with-us',
  templateUrl: './design-with-us.component.html',
  styleUrls: ['./design-with-us.component.scss']
})
export class DesignWithUsComponent implements OnInit {
  createdesigner!: FormGroup;
  successalert: boolean = false;
  erroralert: boolean = false;
  successMessage: string | undefined;
  errorMessage: string | undefined;
  loader:boolean=false

  constructor(private fb: FormBuilder, private landingservice: LandingService) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.remove()
    this.createdesigner = this.fb.group({
      name: ['', [Validators.required]],
      contact: ['', [Validators.required, Validators.pattern('^[1-9][0-9]{9}$')]],
      email: ['', [Validators.required, Validators.email]],
      lead_cv: [null],
    });
  }

  // Getter method to access form controls
  get formControls() {
    return this.createdesigner.controls;
  }
  base64:any=''
  files:any=''
  onFileSelected(event: any) {
    this.files = event.target.files[0];
    console.log(this.files)
    if (this.files) {
      this.convertToBase64(this.files).then(
        (base64: string) => {
        this.base64 = base64
        }
      );
    }
  }

  convertToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }

  remove(){
    this.files=''
    this.base64=''
  }

  createdesignerlead() {    
    if (this.createdesigner.valid) {
      this.loader=true
      const data = {
        lead: {
          name: this.createdesigner.get('name')?.value,
          contact: this.createdesigner.get('contact')?.value,
          email: this.createdesigner.get('email')?.value,
          lead_cv: this.base64,
          lead_type_name: 'designer',
          lead_source: 'website'
        }
      };
      this.landingservice.createdesignerlead(data).subscribe(
        response => {
          this.loader=false
          this.successalert = true;
          this.successMessage = "Designer is created successfully";
          this.createForm();
          setTimeout(() => {
            this.successalert = false;
          }, 3000);
        },
        error => {
          this.loader=false
          this.erroralert = true;
          this.errorMessage = error.error.message;
          setTimeout(() => {
            this.erroralert = false;
          }, 3000);
        }
      );
    } else {
      
      Object.values(this.createdesigner.controls).forEach(control => {
        control.markAsTouched();
      });
    }
  }
}
