import { Component, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { Slick } from 'ngx-slickjs';


declare var $ : any;


@Component({
  selector: 'app-madeby-you-stories-new',
  templateUrl: './madeby-you-stories-new.component.html',
  styleUrls: ['./madeby-you-stories-new.component.scss']
})
export class MadebyYouStoriesNewComponent implements OnInit {

  @ViewChild('myIframe1') myIframe1: ElementRef | any
  @ViewChild('myIframe2') myIframe2: ElementRef | any
  @ViewChild('myIframe3') myIframe3: ElementRef | any
  @ViewChild('myIframe4') myIframe4: ElementRef | any

  constructor(
    private _DomSanitizationService: DomSanitizer,
    private renderer: Renderer2,
   
  ) { }

  playerVars = {
    cc_lang_pref: 'en',
  };

  videoWidth: any  = 100+"%"
  id ='ljhxUFH01Uw'
  id1 = 'Sqr8WhpZSmM'
  id2 = 'HZMaErn5sjM'
  id3 = 'fzd7taoEDL8'
  id4 = 'UJeGixHIWRk';
  ids =[this.id1,this.id2,this.id3,this.id4]
  ngOnInit(): void {
  this.current_url=this._DomSanitizationService.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/ljhxUFH01Uw')
  const tag = document.createElement('script');
  tag.src = 'https://www.youtube.com/iframe_api';
  document.body.appendChild(tag);
  }

  ngAfterViewInit() {
    let node3 = document.createElement('script');
  node3.src = 'https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/js/bootstrap.min.js';
  node3.async = true;
  document.getElementsByTagName('head')[0].appendChild(node3);
   

  }
  public ytEvent:any

  private player! :YT.Player

  savePlayer(player:any) {
    this.player = player;
  }
  onStateChange(event:any) {
    this.ytEvent = event.data;
  }

  pauseVideo() {
    this.player.pauseVideo();
  }

  config: Slick.Config = {
    centerMode : true,
    centerPadding : '60px',
    infinite: true,
    slidesToShow: 4,
    // slidesToScroll: 1,
    // dots: false,
    autoplay: false,
    autoplaySpeed: 2000,
    draggable: true,
    mouseWheelMove: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: true,
          centerPadding : '40px',

        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding : '40px',
          arrows : false,
          centerMode : true
        },
      },
    ],
  };
  url: any = ''


  current_url :any = "https://www.youtube.com/embed/ljhxUFH01Uw"
  transform(url: any) {

    return this._DomSanitizationService.bypassSecurityTrustResourceUrl(url);

  }
  videoModal(url: any) {
    $('#VideoShower').modal('show');
    this.url = url
  }

  close() {
    this.url = ''
  }

  roletoSet() {
    var width = Math.max(window.screen.width, window.innerWidth)
    return width
  }


  videoPlayIn(url :any){
    console.log('clicking');
    this.allVideos = true
    this.current_url=this._DomSanitizationService.bypassSecurityTrustResourceUrl(url)
  }

  allVideos :any = false
 
  IntialvideoPlayIn(url :any){
    this.allVideos = true
    this.current_url=this._DomSanitizationService.bypassSecurityTrustResourceUrl(url)
  }

  indexId :any
  mobilevideoPlayIn(id :any , index:any){
    this.allVideos = true
    console.log(typeof id , 'dsfsdf');
    this.indexId = index
    this.id =  this.ids[index]
    this.playVideo(id)
  }
  playVideo(id:any) {
    this.player.loadVideoById(id)
    this.player.playVideo();

  }

  MobileIntialvideoPlayIn(iddd:any){
    this.allVideos = true
    this.playVideo(iddd)
  }

}
