<!-- 
<div class="modal fade"  tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
   aria-hidden="true">-->
<!-- <div class="modal-dialog modal-dialog-centered" role="document">  -->

<div class="modal-content justify-content-center">
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLongTitle">Get This Design</h5>
  </div>
  <div class="modal-body">
    <div>
      <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="pills-1-tab"
            data-toggle="pill"
            href="#pills-1"
            role="tab"
            aria-controls="pills-1"
            aria-selected="true"
            >1</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="pills-2-tab"
            data-toggle="pill"
            href="#pills-2"
            role="tab"
            aria-controls="pills-2"
            aria-selected="false"
            >2</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="pills-3-tab"
            data-toggle="pill"
            href="#pills-3"
            role="tab"
            aria-controls="pills-3"
            aria-selected="false"
            >3</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="pills-4-tab"
            data-toggle="pill"
            href="#pills-4"
            role="tab"
            aria-controls="pills-4"
            aria-selected="false"
            >4</a
          >
        </li>
      </ul>

      <!-- forms start -->
      <div class="tab-content" id="pills-tabContent">
        <!-- form 1 -->
        <div class="pills1" id="pills-1">
          <form [formGroup]="spacesGetThisDesignForm1">
            <div class="form-row">
              <div class="col-12">
                <label for="inputName"
                  >Enter Full Name <span class="astric">*</span></label
                >
                <input
                  type="text"
                  class="form-control mb-0"
                  placeholder="Enter your name"
                  formControlName="fullName"
                  required
                  (blur)="onBlurName($event)"
                />
                <span
                  class="text-danger fontSize12"
                  *ngIf="
                    (spacesGetThisDesignForm1.controls['fullName'].hasError(
                      'required'
                    ) &&
                      (spacesGetThisDesignForm1.controls['fullName'].dirty ||
                        spacesGetThisDesignForm1.controls['fullName']
                          .touched)) ||
                    errormsgname
                  "
                >
                  Please provide full name</span
                >
              </div>
              <div class="form-group col-md-6 mt-2">
                <label for="inputMobile"
                  >Mobile Number <span class="astric">*</span></label
                >
                <input
                  type="number"
                  class="form-control mb-0"
                  placeholder=" Enter your phone number"
                  formControlName="mobileNumber"
                  minlength="10"
                  maxlength="10"
                  required
                  onkeydown="return event.keyCode !== 69"
                  onKeyPress="if(this.value.length==10) return false;"
                  (blur)="onBlur($event)"
                  required pattern="[1-9]{1}[0-9]{9}"
                />
                <span
                  class="text-danger fontSize12"
                  *ngIf="
                    (spacesGetThisDesignForm1.controls['mobileNumber'].hasError(
                      'pattern'
                    ) &&
                      (spacesGetThisDesignForm1.controls['mobileNumber']
                        .dirty ||
                        spacesGetThisDesignForm1.controls['mobileNumber']
                          .touched)) ||
                          errormsgmobile
                  "
                >
                  Please provide valid contact number</span
                >
              </div>
              <div class="form-group col-md-6 mt-2">
                <label for="inputEmail4">Email ID</label>
                <input
                  type="text"
                  class="form-control"
                  id="inputEmail4"
                  placeholder="Enter your email"
                  formControlName="emailAddress"
                />
                <span class="text-danger fontSize12" *ngIf="email_validation">
                  Please provide valid Email Address</span
                >
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="select">Scope of work</label>
                <select
                  id="scopeworkid"
                  class="form-control"
                  style="cursor: pointer"
                  (change)="scopeWork($event)"
                  formControlName="scopeOfWork"
                >
                  <option value="" selected disabled>Scope of Work</option>
                  <option
                    *ngFor="let scope of ScopeWorkData; let i = index"
                    [value]="scope.scopeworkname"
                  >
                    {{ scope.scopeworkname }}
                  </option>
                </select>
              </div>

              <div class="form-group col-md-6">
                <label for="select">City</label>
                <select
                  id="cityid"
                  class="form-control"
                  style="cursor: pointer"
                  (change)="selectedCity($event)"
                  formControlName="city"
                >
                  <option selected value="" disabled>Choose City</option>
                  <option
                    *ngFor="let city of CityData; let i = index"
                    [value]="city.cityname"
                  >
                    {{ city.cityname }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="inputPin" for="wizards">Pin code of property</label>
                <input
                  type="number"
                  class="form-control"
                  id="inputZip"
                  placeholder="Pincode"
                  formControlName="pincode"
                  id="wizards"
                  name="wizards"
                  list="wizards-list"
                  autocomplete="off"
                  [ngModel]="mymodelpincode"
                  (ngModelChange)="valuechange($event)"
                  onkeydown="return event.keyCode !== 69"
                  onKeyPress="if(this.value.length==6) return false;" pattern="[0-9]{6}"
                />
                <div *ngIf="hidesearch">
                  <datalist
                    id="wizards-list"
                    style="max-height: 150px; overflow-y: auto"
                  >
                    <option *ngFor="let pincode of pincodedata; let i = index">
                      {{ pincode }}
                    </option>
                  </datalist>
                </div>
              </div>
            </div>
          </form>
          <div class="modal-footer mt-3 justify-content-center">
            <button
              type="button"
              class="btn btn1"
              data-dismiss="modal"
              (click)="cancelOne()"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn2 ml-auto"
              (click)="firstValidation()"
            >
              Next
            </button>
          </div>
        </div>

        <!-- form 2 -->
        <div class="pills2" id="pills-2">
          <form [formGroup]="spacesGetThisDesignForm2">
            <div class="form-row form-group">
              <div class="col-12">
                <label for="select">Name Of Your Property</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Property name"
                  formControlName="propertyName"
                />
              </div>
            </div>
            <div class="form-row form-group">
              <div class="col-12">
                <label for="select"
                  >What Type of accomodation do you have?</label
                >
                <select
                  id="accomandationid"
                  class="form-control"
                  style="cursor: pointer"
                  formControlName="accomandationType"
                >
                  <option selected value="" disabled>
                    Type of Accomandation
                  </option>
                  <option
                    *ngFor="
                      let accomandation of AccomandationTypeData;
                      let i = index
                    "
                    [value]="accomandation.accomandationtname"
                  >
                    {{ accomandation.accomandationtname }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row form-group">
              <div class="col-12">
                <label for="select"
                  >Give us an estimate of your home value</label
                >
                <select
                  id="homevalueid"
                  class="form-control"
                  style="cursor: pointer"
                  formControlName="homeValue"
                >
                  <option selected value="" disabled>Home Value</option>
                  <option
                    *ngFor="let home of HomeValueData; let i = index"
                    [value]="home.homevalue"
                  >
                    {{ home.homevalue }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <label for="select"
                >Please upload floor plan of your property?</label
              >

              <div class="col-12">
                <input
                  type="radio"
                  value="true"
                  formControlName="doYouHaveFloorPlan"
                  style="cursor: pointer"
                  (ngModelChange)="valuechangeUploadYes(true)"
                  [ngModel]="mymodelcheckboxYes"
                />
                Yes
                <input
                  type="radio"
                  value="false"
                  formControlName="doYouHaveFloorPlan"
                  style="cursor: pointer"
                  (ngModelChange)="valuechangeUploadNo(false)"
                  [ngModel]="mymodelcheckboxNo"
                />
                No
              </div>
              <br />
              <div class="col-12" *ngIf="radiobuttonUploadCondition">
                <div>
                  <!-- <p class="uploadBtn"> Upload</p> -->
                  <input
                    id="uploadFile"
                    type="file"
                    class="d-block w-100 uploadBtn"
                    formControlName="uploadfloorPlanFile"
                    name="profile"
                    (change)="onFileSelect($event)"
                  />
                </div>
              </div>
            </div>
          </form>

          <div class="modal-footer row mt-5 justify-content-between">
            <button
              type="button"
              class="btn btn3"
              data-dismiss="modal"
              (click)="cancelTwo()"
            >
              Cancel
            </button>
            <div class="">
              <button
                type="button"
                style="margin-right: 10px"
                class="btn btn3"
                (click)="
                  nextOne1('pills-1', 'pills-2', 'pills-1-tab', 'pills-2-tab')
                "
              >
                Previous
              </button>
              <button
                type="button"
                class="btn btn2"
                (click)="
                  nextOne('pills-3', 'pills-2', 'pills-3-tab', 'pills-2-tab')
                "
              >
                Next
              </button>
            </div>
          </div>
        </div>

        <!-- form 3 -->
        <div class="pills3" id="pills-3">
          <form [formGroup]="spacesGetThisDesignForm3">
            <div class="form-row form-group">
              <div class="col-12">
                <label for="select">What are you looking for ?</label>
                <select
                  id="lookingid"
                  class="form-control"
                  style="cursor: pointer"
                  formControlName="lookingFor"
                >
                  <option selected value="" disabled>Type of Home</option>
                  <option
                    *ngFor="let data of LookingForData; let i = index"
                    [value]="data.value"
                  >
                    {{ data.value }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row form-group">
              <div class="col-12">
                <label for="select"
                  >When do you get the possession of the property?</label
                >
                <select
                  class="form-control"
                  style="cursor: pointer"
                  formControlName="possessionProperty_date"
                >
                  <option selected value="" disabled>Possession Month</option>
                  <option
                    *ngFor="let possession of possession_date; let i = index"
                    [value]="possession"
                  >
                    {{ possession }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row form-group">
              <div class="col-12">
                <label for="select"
                  >When do you want to start the project?</label
                >
                <select
                  class="form-control"
                  style="cursor: pointer"
                  formControlName="startProject_date"
                >
                  <option selected value="" disabled>
                    Project Start Month
                  </option>
                  <option
                    *ngFor="let project of project_start_date; let i = index"
                    [value]="project"
                  >
                    {{ project }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-row form-group">
              <div class="col-12">
                <label for="select">Help us understand your budget?</label>
                <select
                  class="form-control"
                  style="cursor: pointer"
                  formControlName="yourBudge"
                >
                  <option selected value="" disabled>Budge Value</option>
                  <option
                    *ngFor="let budge of BudgeValueData; let i = index"
                    [value]="budge.budgevalue"
                  >
                    {{ budge.budgevalue }}
                  </option>
                </select>
              </div>
            </div>
          </form>
          <div class="modal-footer mt-3 justify-content-between">
            <button
              type="button"
              class="btn btn3"
              data-dismiss="modal"
              (click)="cancelThree()"
            >
              Cancel
            </button>
            <div class="">
              <button
                type="button"
                style="margin-right: 10px"
                class="btn btn3"
                (click)="
                  nextOne2('pills-2', 'pills-3', 'pills-2-tab', 'pills-3-tab')
                "
              >
                Previous
              </button>
              <button
                type="button"
                class="btn btn2"
                (click)="
                  nextOne('pills-4', 'pills-3', 'pills-4-tab', 'pills-3-tab')
                "
              >
                Next
              </button>
            </div>
          </div>
        </div>

        <!-- form 4 -->
        <div class="pills4" id="pills-4">
          <form [formGroup]="spacesGetThisDesignForm4">
            <div class="form-row form-group">
              <div class="col-12">
                <label>Do you have an existing home loan?</label>
                <select
                  class="form-control"
                  style="cursor: pointer"
                  formControlName="existingHomeLone"
                  (change)="onChangehomeloanvalue($event)"
                >
                  <option selected value="" disabled>Home loan</option>
                  <option
                    *ngFor="let home of homeloadData"
                    [value]="home.value"
                  >
                    {{ home.value }}
                  </option>
                </select>
                <!-- <div class="yesNo">
                        <p class="yes">Yes</p>
                        <p class="no">No</p> -->
                <!-- </div> -->
              </div>
            </div>
            <div class="form-row form-group" *ngIf="hideFinancialAssistance">
              <div class="col-12">
                <label>Would you like any financial assistance?</label>
                <select
                  class="form-control"
                  style="cursor: pointer"
                  formControlName="financialassistance"
                >
                  <option selected value="" disabled>
                    Financial assistance
                  </option>
                  <option
                    *ngFor="let financial of financialassistanceData"
                    [value]="financial.value"
                  >
                    {{ financial.value }}
                  </option>
                </select>
                <!-- <div class="yesNo">
                        <p class="yes">Yes</p>
                        <p class="no">No</p> -->
                <!-- </div> -->
              </div>
            </div>
            <div class="form-row form-group">
              <div class="col-12">
                <label>Purpose Of Property</label>
                <select
                  class="form-control"
                  style="cursor: pointer"
                  formControlName="purposeProperty"
                >
                  <option selected value="" disabled>
                    Select Your Purpose
                  </option>
                  <option
                    *ngFor="let data of PurposeOfProperty; let i = index"
                    [value]="data.value"
                  >
                    {{ data.value }}
                  </option>
                </select>
                <!-- <div class="yesNo">
                        <p class="yes">Yes</p>
                        <p class="no">No</p> -->
                <!-- </div> -->
              </div>
            </div>
            <div class="form-row form-group">
              <div class="col-12">
                <label>Would you like to tell us something else?</label>
                <textarea
                  placeholder="Comment box"
                  class="form-control heightbox"
                  rows="5"
                  id="comment"
                  formControlName="tellMeAboutYourself"
                ></textarea>
              </div>
            </div>
          </form>
          <div
            class="modal-footer justify-content-between"
            style="margin-top: 1.5rem"
          >
            <button
              type="button"
              class="btn btn3"
              data-dismiss="modal"
              (click)="cancelFour()"
            >
              Cancel
            </button>
            <div class="">
              <button
                type="button"
                style="margin-right: 10px"
                class="btn btn3"
                (click)="
                  nextOne3('pills-3', 'pills-4', 'pills-3-tab', 'pills-4-tab')
                "
              >
                Previous
              </button>
              <button type="submit" class="btn btn2" (click)="onSubmit()">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- </div> -->
<!-- </div> -->

<div class="loading d-flex justify-content-center" *ngIf="loader">
  <div class="spinner-border" style="color: #8d0528" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
