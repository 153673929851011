<div class="root-payment">
  <div class="image-form " *ngIf ="paymentStatusGetamount && paymentStatusGetamount.payment_status == 'ACTIVE'">
         <div class="root-row justify-content-center mx-0">
              <div class="px-lg-3 px-2 py-2 bg-whiteeee ">
                  <div  class="px-lg-5 py-lg-3 px-3 py-4 styleForForm">
                    <p class="text-center font-weight-bold colorBurguntee">Cheque Payment</p>
                  <form  [formGroup] = "paymentForm" >
                      <div class="form-group">
                        <label>Cheque Number<span class="colorBurguntee">*</span></label>
                        <input type="number" class="form-control" formControlName="cheque_number"  placeholder="Cheque Number">
                      </div>

                      <div class="form-group">
                        <label>Enter Amount<span class="colorBurguntee">*</span></label>
                        <input readonly type="number" class="form-control" formControlName="amount"  placeholder="Enter Amount">
                      </div>

                      
                      <div class="form-group">
                        <label>Cheque Date<span class="colorBurguntee">*</span></label>
                        <input type="date" class="form-control" formControlName="date_of_checque"  placeholder="Cheque Date">
                      </div>

                      <div>
                        <p class="mb-2">Cheque Image<span class="colorBurguntee">*</span></p>
                        <div class="input-group mb-3">
                          <input  style="background: none;" [value] = file_name class="form-control" readonly (click)="filePicker.click()"  aria-label="Recipient's username" aria-describedby="basic-addon2">
                          <div class="input-group-append">
                            <button class="btn btn-outline-secondary btn-burgntee px-4 font-weight-bold" (click)="filePicker.click()" type="button">Upload Image</button>
                          </div>
                          <input  #filePicker (change) = "onImagePicked($event)" type="file"  accept="image/png, image/gif, image/jpeg" class="form-control-file d-none" >
                        </div>
                      </div>
                      <div class="form-group">
                        <label>Bank Name<span class="colorBurguntee">*</span></label>
                        <input type="email" formControlName="bank" class="form-control"  placeholder="Bank Name">
                      </div>

                      <div class="form-group">
                        <label>Branch Name<span class="colorBurguntee">*</span></label>
                        <input type="email" formControlName="branch" class="form-control"  placeholder="Branch Name">
                      </div>

                      <div class="d-flex justify-content-between">
                        <button class="px-lg-5  py-1 btn btn-burgntee" [disabled] = "!paymentForm.valid || (!base64textString)" (click) = "submitPaymentDetails()" >Submit</button>
                        <button (click) = "requestNewLink()" class="px-3 py-1 btn btn-outline-danger">Request New Link</button>
                      </div>
                  </form>
                  </div>
              </div>
         </div>
  </div>
  <div class="d-flex justify-content-center align-items-center vh-100 flex-column" *ngIf ="paymentStatusGetamount && paymentStatusGetamount.payment_status == 'PAID'">
    <img src="../../assets/images/carosel-icons/accept.png" class="h-25">
    <p  class="font-weight-bold apply-font-size-status">Amount Paid Successfully</p>
  </div>
  <div class="d-flex justify-content-center align-items-center vh-100 flex-column" *ngIf ="paymentStatusGetamount && paymentStatusGetamount.payment_status == 'CANCELLED'">
    <img src="../../assets/images/carosel-icons/close.png" class="h-25">
    <p  class="font-weight-bold apply-font-size-status">The Payment Link Was Cancelled</p>
  </div>
  <div class="d-flex justify-content-center align-items-center vh-100 flex-column" *ngIf ="paymentStatusGetamount && paymentStatusGetamount.payment_status == 'EXPIRED'">
    <img src="../../assets/images/carosel-icons/expired (1).png" class="h-25">
    <p class="font-weight-bold apply-font-size-status">The Payment Link Was Expired</p>
  </div>
  <div class="d-flex justify-content-center align-items-center vh-100 flex-column" *ngIf ="paymentStatusGetamount && paymentStatusGetamount.payment_status == 'Invalid link'">
    <img src="../../assets/images/carosel-icons/transfer.png" class="h-25">
    <p class="font-weight-bold apply-font-size-status">New Link Was Generated to your Email</p>
  </div>
</div>