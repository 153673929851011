<div class="refer">


<app-new-header-for-home></app-new-header-for-home>

<div class="banner text-center">
    <img class="w-100" src="../../../assets/images/stock-photo-arabian-men-and-women-sitting-in-kitchen-and-discussing-a-news-1876843894-transformed 2.png" alt="">
    <!-- <div class="overlay"></div>
    <div class="descript d-flex flex-column justify-content-end align-items-center">
      <h1>TAKE YOUR RELATIONSHIP WITH <br>ARRIVAE TO THE NEXT LEVEL!</h1>
      <p>Refer us and reap rewards.</p>
    </div> -->
  </div>

<div class="earn-reward m-md-5 m-4 text-center ">
    <h1 class="mb-md-4">REFER & EARN REWARDS!</h1>
    <p>Loved working with Arrivae for your dream home interiors? Why not spread the joy? <br>Refer your friends, and earn</p>
    <span class="Reward "><i class="fa fa-inr"></i> 20,000</span>
    <p class="mt-3">on every successful referral to Arrivae.</p>
</div>

<div class="howitworks d-flex flex-column justify-content-center align-items-center p-md-5  ">
    <h1 class="pt-5 pt-md-0">How it works</h1>
    <div *ngIf="roletoSet()>650"  class="row d-flex justify-content-center align-items-start m-5" >

        <div class="column col text-center justify-content-center d-flex flex-column align-items-center p-0">
            <div class="icon bg-white rounded-circle justify-content-center align-items-center d-flex">
                <img src="../../../assets/images/share 1.jpg" alt="" style="width: 89px;height: 89px;">
            </div>
            <p class="pt-3">Refer your friend</p>
        </div>
        <div class="d-flex align-self-center justify-content-center mb-5">
            <img class="mb-5" src="../../../assets/Arrow 1.png">
        </div>

        <div class="column col text-center justify-content-center d-flex flex-column align-items-center p-0">
            <div class="icon bg-white rounded-circle justify-content-center align-items-center d-flex">
                <img src="../../../assets/images/Untitled-1cccss 1.png" alt="">
            </div>
            <p class="pt-3">Our team at Arrivae will set a meeting with your friend </p>
        </div>

        <div class="d-flex align-self-center justify-content-center mb-5">
            <img class="mb-5" src="../../../assets/Arrow 1.png">
        </div>


        <div class="column col text-center justify-content-center d-flex flex-column align-items-center p-0">
            <div class="icon bg-white rounded-circle justify-content-center align-items-center d-flex">
                <img src="../../../assets/images/book 1.png" alt="">
            </div>
            <p class="pt-3">Relax and be notified when your friend signs up</p>
        </div>

        <div class="d-flex align-self-center justify-content-center mb-5">
            <img class="mb-5" src="../../../assets/Arrow 1.png">
        </div>


        <div class="column col text-center justify-content-center d-flex flex-column align-items-center p-0">
            <div class="icon bg-white rounded-circle justify-content-center align-items-center d-flex">
                <img src="../../../assets/images/Untitled-2sdassdA 1.png" alt="">
            </div>
            <p class="pt-3" >Opt for cash back or store credits - the choice is yours!</p>
        </div>
        <div class="d-flex align-self-center justify-content-center mb-5">
            <img class="mb-5" src="../../../assets/Arrow 1.png">
        </div>

        <div class="column col text-center justify-content-center d-flex flex-column align-items-center p-0">
            <div class="icon bg-white rounded-circle justify-content-center align-items-center d-flex">
                <img src="../../../assets/images/sassasasas 1.png" alt="">
            </div>
            <p class="pt-3">Receive your referral bonus once your friend pays 50% of the total amount</p>
        </div>



        
    </div>
    <div *ngIf="roletoSet()<650" class="Buildings_container w-100  m-3">
        <div class="row d-flex align-items-center justify-content-center w-100 m-0">
            <div class="col-2 navigations d-flex justify-content-center">
                <div class="mb-5 pb-3">
                   <div class="other-mobile-boqs"  >
                     <button [ngClass]="slickIndex == 0 ?'d-none':''"   class="mx-1 prev_icon button-one" (click)="slickController.prev()">
                      <img class=" align-self-center " src="../../../assets/icons/Group 9.png" alt="" style="rotate: 180deg;">
                     </button>
               </div>   
             </div>
             </div>
            <div class="col-7 p-0">
                <div class="mobile-carosel-active-boqs w-100">
                    <div slickContainer #slickController="slick" [slickConfig]="config">
                        <ng-container *ngIf="slickController.initialize">
                            <div slickItem class="text-center align-items-center d-flex flex-column justify-content-center mobile_image">
                                <div class="icon bg-white rounded-circle justify-content-center align-items-center d-flex">
                                    <img src="../../../assets/images/share 1.jpg" alt="" style="width: 89px;height: 89px;">
                                </div>
                                <p class="pt-3">Refer your friend</p>
                            </div>

                            <div  slickItem class=" text-center align-items-center d-flex flex-column justify-content-center mobile_image" >
                                 <div class="icon bg-white rounded-circle justify-content-center align-items-center d-flex">
                                     <img src="../../../assets/images/Untitled-1cccss 1.png" alt="">
                                 </div>
                                 <p class="pt-3">Our team at Arrivae will set a meeting with your friend </p>                       
                            </div>

                            <div slickItem class="text-center align-items-center d-flex flex-column  justify-content-center mobile_image">
                                 <div class="icon bg-white rounded-circle justify-content-center align-items-center d-flex">
                                     <img src="../../../assets/images/book 1.png" alt="">
                                 </div>
                                 <p class="pt-3">Relax and be notified when your friend signs up</p>                      
                            </div>
                        
                            <div slickItem class="text-center align-items-center d-flex flex-column justify-content-center mobile_image">
                                 <div class="icon bg-white rounded-circle justify-content-center align-items-center d-flex">
                                     <img src="../../../assets/images/Untitled-2sdassdA 1.png" alt="">
                                 </div>
                                 <p class="pt-3">Opt for cash back or store credits - the choice is yours!</p>
                             </div>                       
                         
                            <div slickItem class="text-center align-items-center d-flex flex-column  justify-content-center mobile_image">
                                 <div class="icon bg-white rounded-circle justify-content-center align-items-center d-flex">
                                     <img src="../../../assets/images/sassasasas 1.png" alt="">
                                 </div>
                                 <p class="pt-3">Receive your referral bonus once your friend pays 50% of the total amount</p>
                             </div>

                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="col-2 navigations d-flex justify-content-center" >
                <div class="mb-5 pb-3 float-right">
                    <div class="other-mobile-boqs"  >  
                        <button [ngClass]="slickIndex == 4 ?'d-none':''"  class="mx-1 button-one" (click)="slickController.next()">
                            <img class=" align-self-center " src="../../../assets/icons/Group 9.png" alt="">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div> 

</div>


<!-- <div class="instantreward mb-md-5 d-flex flex-column justify-content-center align-items-center text-center p-5">
    <h1 class="mb-md-4">Instant Rewards, Just a Referral Away!</h1>
    <p>As soon as your referral hits the 50% payment<br> mark, your rewards are ready to roll!</p>
    <div  class="images row d-flex justify-content-center align-items-start pt-5 " >
        <div class="column col-12 col-md-4 text-center justify-content-center d-flex flex-column align-items-center ">
            <div class="icon rounded-circle justify-content-center align-items-center d-flex">
                <img src="../../../assets/images/2312906-200 1.png" alt="">
            </div>
            <span class="pt-3 px-md-5">Grab a quick <b>2% cashback </b>straight to your account</span>
        </div>
        <div class="col-12 col-md-1 d-flex align-self-center justify-content-center m-3 mb-md-3 pb-md-5 text-center"><span><b>OR</b></span></div>
        <div class="column col-12 col-md-4 p-0 text-center justify-content-center d-flex flex-column align-items-center ">
            <div class="icon rounded-circle justify-content-center align-items-center d-flex">
                <img src="../../../assets/images/creditcard_120378 1.png" alt="">
            </div>
            <span class="pt-3 px-md-3 mx-md-3">go big with a <b>3% store</b> credit on our range of exciting products!</span>
        </div>
    </div>
   

</div> -->

<div class="form p-5 ">
    <div class="form1 " [ngClass]="form1 ? 'd-block' : 'd-none'">
        <div class="d-flex flex-column justify-content-center">
        <h1 class="align-self-center">Ready to Refer? </h1>

        <form class="row flex-column justify-content-center align-items-center py-5" [formGroup]="refralform" (ngSubmit)="createReferal2()">
              <label class="col-12 col-md-6 mb-3 pl-1" for="exampleInputPassword1">Enter your Email .</label>
              <div class="col-12 col-md-6 mb-4 p-0 d-flex flex-column">
              <input class="form-control p-3 " type="email" formControlName="email"  id="exampleInputPassword1" placeholder="Enter email" (keyup)="validate()">
              <ng-container *ngIf="!validity">
                <span class="text-danger col-12 col-md-6 pt-2" >Enter a valid email address</span>
              </ng-container>
              </div>
            <button type="submit" [disabled]="!refralform.valid" class="next col-12 col-md-6 p-3">Next</button>
        </form>
        </div>
    </div>

   <div class="form2" [ngClass]="form2 ? 'd-block' : 'd-none'">
    <div class="row">
        <div class="col-12 col-md-6 d-flex flex-column  justify-content-center text-center p-md-5">
            <div class="mx-md-5 px-md-5">
                <h2 class="m-3 ">Refer Your Friend </h2>
                <form [formGroup]="referralForm" (ngSubmit)="CreateLead()">
                    <input type="text" name="client_name" placeholder="Name" formControlName="client_name" class="form-control leaddata  my-2 px-3">
                    <div *ngIf="referralForm.controls.client_name.invalid && referralForm.controls.client_name.dirty">
                      <div *ngIf="referralForm.controls.client_name.errors?.required">Name is required.</div>
                    </div>
                  
                    <input type="text" name="contact_number" placeholder="Phone no." formControlName="contact_number" class="form-control leaddata my-2 px-3">
                    <div *ngIf="referralForm.controls.contact_number.invalid && (referralForm.controls.contact_number.dirty || referralForm.controls.contact_number.touched)">
                      <div *ngIf="referralForm.controls.contact_number.errors?.required">Phone number is required.</div>
                      <div *ngIf="referralForm.controls.contact_number.errors?.pattern">Please enter a valid 10-digit phone number.</div>
                    </div>
                  
                    <input type="text" name="email_id" placeholder="Email" formControlName="email_id" class="form-control leaddata my-2 px-3">
                    <div *ngIf="referralForm.controls.email_id.invalid && (referralForm.controls.email_id.dirty || referralForm.controls.email_id.touched)">
                      <div *ngIf="referralForm.controls.email_id.errors?.required">Email is required.</div>
                      <div *ngIf="referralForm.controls.email_id.errors?.pattern">Please enter a valid email address.</div>
                    </div>
                  
                    <select placeholder="City" formControlName="city" class="form-control leaddata my-2 px-3 pr-4">
                      <option selected value="">City</option>
                      <option value="ahmedabad">Ahmedabad</option>
                        <option value="bangalore">Bangalore</option>
                        <option value="chandigarh">Chandigarh</option>
                        <option value="cochin">Cochin</option>
                        <option value="delhi">Delhi</option>
                        <option value="gurgaon">Gurgaon</option>
                        <option value="hyderabad">Hyderabad</option>
                        <option value="jaipur">Jaipur</option>
                        <option value="kochi">Kochi</option>
                        <option value="kolkata">Kolkata</option>
                        <option value="lucknow">Lucknow</option>
                        <option value="mumbai">Mumbai</option>
                        <option value="nashik">Nashik</option>
                        <option value="navi mumbai">Navi Mumbai</option>
                        <option value="pune">Pune</option>
                        <option value="trivandrum">Trivandrum</option>
                        <option value="vijayawada">Vijayawada</option>
                    </select>
                    
                    <button class="submit w-100 mx-0 px-0" [disabled]="!referralForm.valid" type="submit">Submit</button>
                  </form>
                  
                  
            </div>

        </div>

        <div class="part2 col-12 col-md-6 p-md-5 mt-4 mt-md-0 d-flex flex-column justify-content-center align-items-center text-center" >
                <span class="d-block d-md-none m-3">Or</span>
                <h2 class="m-md-3 d-flex" ><span class="d-md-block d-none">Or, </span><span>&nbsp;You can also share your link below:</span></h2>
                <div class="input-group mb-3 px-md-5">
                    <input   [(ngModel)]="copyText" class="form-control referal text-center" readonly>
                    <div class="input-group-append">
                      <button class="copy px-4 btn btn-outline-secondary" [disabled]="copy=='Copied'" type="button" (click)="copyToClipboard()">{{copy}}</button>
                    </div>
                </div>
        </div>




    </div>
   </div>

</div>

<div class="terms  d-flex flex-column justify-content-center align-items-center p-md-5 py-5 mt-4 mt-md-0" >
    <h1 class="text-center mb-md-4">T&C's apply</h1>
    <ol>
        <li>Referral bonus is only applicable for an Arrivae customer (Paid 10% booking amount).</li>
        <li>Your payment will be processed directly to the account linked with your profile, subject to TDS deduction after receiving 50% of the total payment from the referral.</li>
        <li>The minimum project value for a referral to be eligible for the referral bonus is ₹4 Lakhs</li>
        <li>Referral bonus cannot be combined or adjusted against any existing payment.</li>
        <li>If your friend has already filled an enquiry form with Arrivae, this referral will not be considered for any referral pay-outs.</li>
        <li>Arrivae reserves the right to end or modify any or all of the terms and conditions of the referral program at any time without prior notice.</li>
        <li>Arrivae also reserves the right to deny any referral bonus that is suspected to be illegitimate.</li>

    </ol>
</div>
<app-footer></app-footer>


</div>