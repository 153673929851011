<section class="what-we-cover">
    <div class="heading">
        <h1>Our Interior Solutions</h1>
        <!-- <a href="#">See all</a> -->
    </div>

    <!-- <div class="row row-custom">
        <div class="col-3 col-card">
            <div class="card card-custom" >
                <div class="card-img-wrapper">
                    <img src="../../../assets/images/image 41@3x.png" class="card-img-top" alt="Home">
                </div>
                <div class="card-body">
                    <h1 class="card-title">Home Interiors</h1>
                    <div class="card-content">
                        <p class="card-text">Living Rooms | Kitchens | Bedrooms | Bath Rooms</p>
                    </div>        
                </div>
            </div>
        </div>

        <div class="col-3 col-card">
            <div class="card card-custom" >
                <div class="card-img-wrapper">
                    <img src="../../../assets/images/what we cover/Section-2_ModularKitchen.png" class="card-img-top" alt="Home">
                </div>
                <div class="card-body">
                    <h1 class="card-title">Modular Kitchens</h1>
                    <div class="card-content">
                        <p class="card-text">Straight Kitchen | Parallel Kitchen | L-Shaped Kitchen | U-
                            Shaped Kitchen | Peninsula Kitchen | Island Kitchen</p>
                    </div>        
                </div>
            </div>
        </div>

        <div class="col-3 col-card">
            <div class="card card-custom" >
                <div class="card-img-wrapper">
                    <img src="../../../assets/images/what we cover/Section-2_BedroomWardrobe.png" class="card-img-top" alt="Home">
                </div>
                <div class="card-body">
                    <h1 class="card-title">Wardrobes</h1>
                    <div class="card-content">
                        <p class="card-text">Hinged Door Wardrobe | Free Standing Wardrobe | Sliding
                            Door Wardrobe | Walk-In-Wardrobe</p>
                    </div>        
                </div>
            </div>
        </div>
    
        <div class="col-3 col-card">
            <div class="card card-custom" >
                <div class="card-img-wrapper">
                    <img src="../../../assets/images/image 41@3x2.png" class="card-img-top" alt="Home">
                </div>
                <div class="card-body">
                    <h1 class="card-title">Furniture</h1>
                    <div class="card-content">
                        <p class="card-text">TV Units | Bar Units | Shoe Racks | Book Shelves | Beds | Bed
                            side table | Mandir | Cabinets</p>
                    </div>        
                </div>
            </div>
        </div>

        <div class="col-3 col-card">
            <div class="card card-custom" >
                <div class="card-img-wrapper">
                    <img src="../../../assets/images/image 41@3x2.png" class="card-img-top" alt="Home">
                </div>
                <div class="card-body">
                    <h1 class="card-title">Curtains</h1>
                    <div class="card-content">
                        <p class="card-text">Fabric | Pleats | Lining</p>
                    </div>        
                </div>
            </div>
        </div>
        
    </div> -->


    <div class="cards-carousel-container">
        <div class="carousel slide" id="main-carousel1" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#main-carousel1" data-slide-to="0" class="active"></li>
                <li data-target="#main-carousel1" data-slide-to="1"></li>
                <!-- <li data-target="#main-carousel1" data-slide-to="2"></li>
                <li data-target="#main-carousel1" data-slide-to="3"></li> -->
            </ol>
            
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="row row-custom">
                        <div class="col-3 col-card">
                            <div class="card card-custom" >
                                <div class="card-img-wrapper">
                                    <img src="../../../assets/images/what we cover/image 41@3x.png" class="card-img-top" alt="Home">
                                </div>
                                <div class="card-body">
                                    <h1 class="card-title">Home Interiors</h1>
                                    <div class="card-content">
                                        <p class="card-text">Living Rooms | Kitchens | Bedrooms | Bath Rooms</p>
                                    </div>        
                                </div>
                            </div>
                        </div>

                        <div class="col-3 col-card">
                            <div class="card card-custom" >
                                <div class="card-img-wrapper">
                                    <img src="../../../assets/images/what we cover/Section-2_ModularKitchen.png" class="card-img-top" alt="Home">
                                </div>
                                <div class="card-body">
                                    <h1 class="card-title">Modular Kitchens</h1>
                                    <div class="card-content">
                                        <p class="card-text">Straight Kitchen | Parallel Kitchen | L-Shaped Kitchen | U-
                                            Shaped Kitchen | Peninsula Kitchen | Island Kitchen</p>
                                    </div>        
                                </div>
                            </div>
                        </div>

                        <div class="col-3 col-card">
                            <div class="card card-custom" >
                                <div class="card-img-wrapper">
                                    <img src="../../../assets/images/what we cover/Section-2_BedroomWardrobe.png" class="card-img-top" alt="Home">
                                </div>
                                <div class="card-body">
                                    <h1 class="card-title">Wardrobes</h1>
                                    <div class="card-content">
                                        <p class="card-text">Hinged Door Wardrobe | Free Standing Wardrobe | Sliding
                                            Door Wardrobe | Walk-In-Wardrobe</p>
                                    </div>        
                                </div>
                            </div>
                        </div>
                    
                        <div class="col-3 col-card">
                            <div class="card card-custom" >
                                <div class="card-img-wrapper">
                                    <img src="../../../assets/images/what we cover/image 41@3x2.png" class="card-img-top" alt="Home">
                                </div>
                                <div class="card-body">
                                    <h1 class="card-title">Furniture</h1>
                                    <div class="card-content">
                                        <p class="card-text">TV Units | Bar Units | Shoe Racks | Book Shelves | Beds | Bed
                                            side table | Mandir | Cabinets</p>
                                    </div>        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="row row-custom">
                        <div class="col-3 col-card">
                            <div class="card card-custom" >
                                <div class="card-img-wrapper">
                                    <img src="../../../assets/images/what we cover/Curtain-318x240_06.png" class="card-img-top" alt="Home">
                                </div>
                                <div class="card-body">
                                    <h1 class="card-title">Curtains</h1>
                                    <div class="card-content">
                                        <p class="card-text">Made-To-Order Curtains | Custom Curtains | Custom Pleats | Custom Lining | Custom Hardware</p>
                                    </div>        
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-3 col-card">
                            <div class="card card-custom" >
                                <div class="card-img-wrapper">
                                    <img src="../../../assets/images/what we cover/Section-2_ModularKitchen.png" class="card-img-top" alt="Home">
                                </div>
                                <div class="card-body">
                                    <h1 class="card-title">Modular Kitchens</h1>
                                    <div class="card-content">
                                        <p class="card-text">Straight Kitchen | Parallel Kitchen | L-Shaped Kitchen | U-
                                            Shaped Kitchen | Peninsula Kitchen | Island Kitchen</p>
                                    </div>        
                                </div>
                            </div>
                        </div>

                        <div class="col-3 col-card">
                            <div class="card card-custom" >
                                <div class="card-img-wrapper">
                                    <img src="../../../assets/images/what we cover/Section-2_BedroomWardrobe.png" class="card-img-top" alt="Home">
                                </div>
                                <div class="card-body">
                                    <h1 class="card-title">Wardrobes</h1>
                                    <div class="card-content">
                                        <p class="card-text">Hinged Door Wardrobe | Free Standing Wardrobe | Sliding
                                            Door Wardrobe | Walk-In-Wardrobe</p>
                                    </div>        
                                </div>
                            </div>
                        </div>
                    
                        <div class="col-3 col-card">
                            <div class="card card-custom" >
                                <div class="card-img-wrapper">
                                    <img src="../../../assets/images/image 41@3x2.png" class="card-img-top" alt="Home">
                                </div>
                                <div class="card-body">
                                    <h1 class="card-title">Furniture</h1>
                                    <div class="card-content">
                                        <p class="card-text">TV Units | Bar Units | Shoe Racks | Book Shelves | Beds | Bed
                                            side table | Mandir | Cabinets</p>
                                    </div>        
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <!-- <div class="carousel-item">
                    <div class="row row-custom">
                        <div class="col-3 col-card">
                            <div class="card card-custom" >
                                <div class="card-img-wrapper">
                                    <img src="../../../assets/images/image 41@3x.png" class="card-img-top" alt="Home">
                                </div>
                                <div class="card-body">
                                    <h1 class="card-title">Home Interiors</h1>
                                    <div class="card-content">
                                        <p class="card-text">Living Rooms | Kitchens | Bedrooms | Bath Rooms</p>
                                    </div>        
                                </div>
                            </div>
                        </div>

                        <div class="col-3 col-card">
                            <div class="card card-custom" >
                                <div class="card-img-wrapper">
                                    <img src="../../../assets/images/what we cover/Section-2_ModularKitchen.png" class="card-img-top" alt="Home">
                                </div>
                                <div class="card-body">
                                    <h1 class="card-title">Modular Kitchens</h1>
                                    <div class="card-content">
                                        <p class="card-text">Straight Kitchen | Parallel Kitchen | L-Shaped Kitchen | U-
                                            Shaped Kitchen | Peninsula Kitchen | Island Kitchen</p>
                                    </div>        
                                </div>
                            </div>
                        </div>

                        <div class="col-3 col-card">
                            <div class="card card-custom" >
                                <div class="card-img-wrapper">
                                    <img src="../../../assets/images/what we cover/Section-2_BedroomWardrobe.png" class="card-img-top" alt="Home">
                                </div>
                                <div class="card-body">
                                    <h1 class="card-title">Wardrobes</h1>
                                    <div class="card-content">
                                        <p class="card-text">Hinged Door Wardrobe | Free Standing Wardrobe | Sliding
                                            Door Wardrobe | Walk-In-Wardrobe</p>
                                    </div>        
                                </div>
                            </div>
                        </div>
                    
                        <div class="col-3 col-card">
                            <div class="card card-custom" >
                                <div class="card-img-wrapper">
                                    <img src="../../../assets/images/image 41@3x2.png" class="card-img-top" alt="Home">
                                </div>
                                <div class="card-body">
                                    <h1 class="card-title">Furniture</h1>
                                    <div class="card-content">
                                        <p class="card-text">TV Units | Bar Units | Shoe Racks | Book Shelves | Beds | Bed
                                            side table | Mandir | Cabinets</p>
                                    </div>        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="row row-custom">
                        <div class="col-3 col-card">
                            <div class="card card-custom" >
                                <div class="card-img-wrapper">
                                    <img src="../../../assets/images/image 41@3x.png" class="card-img-top" alt="Home">
                                </div>
                                <div class="card-body">
                                    <h1 class="card-title">Home Interiors</h1>
                                    <div class="card-content">
                                        <p class="card-text">Living Rooms | Kitchens | Bedrooms | Bath Rooms</p>
                                    </div>        
                                </div>
                            </div>
                        </div>

                        <div class="col-3 col-card">
                            <div class="card card-custom" >
                                <div class="card-img-wrapper">
                                    <img src="../../../assets/images/what we cover/Section-2_ModularKitchen.png" class="card-img-top" alt="Home">
                                </div>
                                <div class="card-body">
                                    <h1 class="card-title">Modular Kitchens</h1>
                                    <div class="card-content">
                                        <p class="card-text">Straight Kitchen | Parallel Kitchen | L-Shaped Kitchen | U-
                                            Shaped Kitchen | Peninsula Kitchen | Island Kitchen</p>
                                    </div>        
                                </div>
                            </div>
                        </div>

                        <div class="col-3 col-card">
                            <div class="card card-custom" >
                                <div class="card-img-wrapper">
                                    <img src="../../../assets/images/what we cover/Section-2_BedroomWardrobe.png" class="card-img-top" alt="Home">
                                </div>
                                <div class="card-body">
                                    <h1 class="card-title">Wardrobes</h1>
                                    <div class="card-content">
                                        <p class="card-text">Hinged Door Wardrobe | Free Standing Wardrobe | Sliding
                                            Door Wardrobe | Walk-In-Wardrobe</p>
                                    </div>        
                                </div>
                            </div>
                        </div>
                    
                        <div class="col-3 col-card">
                            <div class="card card-custom" >
                                <div class="card-img-wrapper">
                                    <img src="../../../assets/images/image 41@3x2.png" class="card-img-top" alt="Home">
                                </div>
                                <div class="card-body">
                                    <h1 class="card-title">Furniture</h1>
                                    <div class="card-content">
                                        <p class="card-text">TV Units | Bar Units | Shoe Racks | Book Shelves | Beds | Bed
                                            side table | Mandir | Cabinets</p>
                                    </div>        
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            
        </div>
    </div>
        
</section>
