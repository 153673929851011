<div class="root-overflow" *ngIf="BuildingNameGetter() == 'marathon-nexzone' || BuildingNameGetter() == '140-shivaji'">
    <app-new-header-for-home [parentSubject]="parentSubject"  ></app-new-header-for-home>
    <div class="container-fluid marathon_banner sivaji-banner" 
    [ngStyle]="{'background-image': getUrl()}" *ngIf="BuildingNameGetter() == '140-shivaji'">

    
       
    
        <div class="marthon_wrapper sivajiwrapper   w-100 h-100 " >
            <div class="w-100" >
                <img class="float-right image_logo mt-2" src="../../assets/images/25 South logo 1.png" alt="">
            </div>
           
            
            <div class="Mobile_div  d-flex h-100 justify-content-center m-auto w-100">
                <div class="divforDes mx-auto">
    
                    <h2>ENTER THE WORLD OF GRANDEUR</h2>
                    <div class="w-100 d-flex justify-content-center">
    
    
                        <p>25 South Is A Result Of Top-Notch Quality Of Construction 
                            Using State-Of-The-Art Technology Combined With World Class Design</p>
            
    
                    </div>
                  
                    <div class="col-md-10 Mobile_div wrarpper_bhk m-auto">
                        <div class="row w-100">
                            <div class="col-12">
                                <div class="row" *ngIf="BuildingNameGetter() == 'marathon-nexzone'">
                                    <div class="col-4 format_type">
                                       <a href="http://pano.yfway.com/50918713" target="_blank"> Essenia</a>
                                                                           </div>
                                    <div class="col-4 format_type">
                                      <a href="http://pano.yfway.com/50917213" target="_blank">Luxuria</a>
                                                                            </div>
                                    <div class="col-4 lastFormat ">
                                       <a href="http://pano.yfway.com/50928244" target="_blank">Premia</a> 
                                                                           </div>
                                </div>
                            </div>
                            <div class="col-12 d-flex justify-content-center  mt-4 mt-lg-5">
                                <a [href]="BuildingNameGetter() == 'marathon-nexzone'?'https://web.arrivae.com/apartment-detail?apartment_name=Marathon%20Nexzone&developer_name=Marathon%20Reality&unid=4102062BHKMARNX&city=mumbai&perPage=6&lifestage=&theme=&vrlink=false&search=nexzone':'https://web.arrivae.com/apartment-detail?apartment_name=25%20South&developer_name=Wadhwa%20Group&unid=4000253BHKSOUTH&city=mumbai&perPage=6&lifestage=&theme=&vrlink=false&search=25%20south'" target="_blank">
                                    <button class="btn-tour px-4 px-lg-5" [ngClass]="BuildingNameGetter() == '140-shivaji'?'sivajiFont':''">
                                        BOOK NOW
                                    </button> 
                                </a>
                            </div>
                        </div>              
                    </div>
    
                </div>
               
               
            </div>
        </div>
        
    </div>
    <div class="container-fluid marathon_banner" *ngIf="BuildingNameGetter() == 'marathon-nexzone'" [ngStyle]="{'background-image': getUrl()}">
        <div class="marthon_wrapper d-flex w-100 h-100 justify-content-center align-items-center">
            <div class="m-auto Mobile_div col-md-8">
                <h2>EXPLORE OUR DESIGNS</h2>
                <p>{{BuildingNameGetter() == 'marathon-nexzone'?'Experience the beauty of green surrounds from the bedroom of your Panvel home':'Experience the beauty of great surrounds from the bedroom of your Dadar home'}}</p>
    
                <div class="col-md-10 Mobile_div wrarpper_bhk m-auto">
                    <div class="row w-100">
                        <div class="col-12">
                            <div class="row" *ngIf="BuildingNameGetter() == 'marathon-nexzone'">
                                <div class="col-4 format_type">
                                   <a    (click)="successmessage('https://arrivae-uat.s3.amazonaws.com/Marathon-Essentia.pdf')"> Essentia </a>
                                   <a class="padForFirst" href="http://pano.yfway.com/50918713" target="_blank"> <img src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>
                                                                   </div>
                                <div class="col-4 format_type">
                                    <a   (click)="successmessage('https://arrivae-uat.s3.amazonaws.com/Marathon-Premia.pdf')">Premia</a> 
                                    <a class="pl-2  pl-md-3" href="http://pano.yfway.com/50928244" target="_blank"> <img src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>
                                                                    </div>
                                <div class="col-4 lastFormat ">
                                    <a   (click)="successmessage('https://arrivae-uat.s3.amazonaws.com/Marathon-Luxuria.pdf')">Luxuria</a>  
                                    <a class="pl-2 pl-md-3" href="http://pano.yfway.com/50917213" target="_blank"> <img src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>
                                                                    </div>
                            </div>
                        </div>
                        <div class="col-12 d-flex justify-content-center  mt-4 mt-lg-5">
                            <a [href]="BuildingNameGetter() == 'marathon-nexzone'?'https://web.arrivae.com/apartment-detail?apartment_name=Marathon%20Nexzone&developer_name=Marathon%20Reality&unid=4102062BHKMARNX&city=mumbai&perPage=6&lifestage=&theme=&vrlink=false&search=nexzone':'https://web.arrivae.com/apartment-detail?apartment_name=140%20Shivaji%20Park&developer_name=Urbania%20Realty&unid=4000283BHKSVAJI&city=mumbai&perPage=6&lifestage=&theme=&vrlink=false&search=shivaji'" target="_blank">
                                <button class="btn-tour px-4 px-lg-5">
                                    BOOK NOW
                                </button> 
                            </a>
                        </div>
                    </div>              
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid py-5 perfect-fit-wrapper px-0 ">
        <h3 class="pb-3" [ngClass]="BuildingNameGetter() == '140-shivaji'?'sivajiFont':''">
            {{BuildingNameGetter() == 'marathon-nexzone'?'FIND THE PERFECT FIT':'A Lavish Life Awaits You At 25 South'}}
        </h3>
        <p *ngIf="BuildingNameGetter() == 'marathon-nexzone'" class="m-auto">
            {{BuildingNameGetter() == 'marathon-nexzone'?'Nexzone offers a wide range of configuration, area and possession date choices, Visit our show flats or take a virtual tour to get an experience of the township':'Experience the highest quality of living in the lap of stunning views and luxurious residence. Living at 25 South, Prabhadevi offers
            access to panoramic views of the Arabian sea, stellar amenities, lush spaces and homes designed with Arrivae’s exclusive elegant themes.'}}
        </p>
        <p *ngIf="BuildingNameGetter() == '140-shivaji'" id="sivajipan"  class="m-auto sivajipan">
            {{BuildingNameGetter() == 'marathon-nexzone'?'Nexzone offers a wide range of configuration, area and possession date choices, Visit our show flats or take a virtual tour to get an experience of the township':'Experience the highest quality of living in the lap of stunning views and luxurious residence. Living at 25 South, Prabhadevi offers
            access to panoramic views of the Arabian sea, stellar amenities, lush spaces and homes designed with Arrivae’s exclusive elegant themes.'}}
        </p>
     
        <div *ngIf="BuildingNameGetter() == 'marathon-nexzone'" class="w-100 d-flex justify-content-center mt-4">
            <button  (click)="getQuote()" class="btn-tour px-5">
                SCHEDULE VISIT
            </button>
        </div>
    </div>
    <app-catlouge-apartment></app-catlouge-apartment>
    <div class="container-fluid images_display">
        <div class="d-flex w-100 flex-wrap justify-content-space-between">
            <div class="image_dis">
                <img class="w-100 h-100" [src]="BuildingNameGetter() == 'marathon-nexzone'?'https://s3.amazonaws.com/arrivae-production/contents/documents/000/087/720/original/data?1681469558':'https://s3.amazonaws.com/arrivae-production/contents/documents/000/077/810/original/data?1670059383'" />
            </div>
            <div class="image_dis ml-2">
                <img class="w-100 h-100" [src]="BuildingNameGetter() == 'marathon-nexzone'?'https://s3.amazonaws.com/arrivae-production/contents/documents/000/087/722/original/data?1681469559':'https://s3.amazonaws.com/arrivae-production/contents/documents/000/077/791/original/data?1670058791'" />
            </div>
            <div class="image_dis ml-2">
                <img class="w-100 h-100" [src]="BuildingNameGetter() == 'marathon-nexzone'?'https://s3.amazonaws.com/arrivae-production/contents/documents/000/087/714/original/data?1681469369':'https://s3.amazonaws.com/arrivae-production/contents/documents/000/077/803/original/data?1670059206'" />
            </div>
        </div>
    </div>
    <div class="container-fluid py-5 perfect-fit-wrapper">
        <h3 class="pb-3" [ngClass]="BuildingNameGetter() == '140-shivaji'?'sivajiFont':''">
           {{BuildingNameGetter() == 'marathon-nexzone'?'IN THE MIDST OF NATURE':'Discover The Luxury Of Italian Design Through Our Themes'}} 
        </h3>
        <p *ngIf="BuildingNameGetter() == 'marathon-nexzone'" class="m-auto pb-3">
            {{BuildingNameGetter() == 'marathon-nexzone'?'Nexzone has lush green surrounds and the Gold Zone overlooks the majestic Panel creek & offers gorgeous views of the Matheran Hills.':'You Home Interior Solutions Gateway. Dream Homes Designed By You and Executed By Us.'}}
        </p>
    
        <p *ngIf="BuildingNameGetter() == 'marathon-nexzone'" class="m-auto">
            Selected apartments even have decks and massive full width C shaped windows in the master bedroom for you to soak in the natural beauty of the surroundings
        </p>
        <ng-container *ngIf="BuildingNameGetter() == '140-shivaji'">
            <div >
                <h4 class="heading-sivaji">
                    Dream Ballet- Modern Gray Light Luxury
            
                </h4>
        
               <div class="sivaji-details w-100 d-flex justify-content-center">
    
                <p>
                    Dream Ballet celebrates the versatility and sophistication of the color gray. The key elements are clean lines, marble and copper-frosted glass. 
        When combined with orange accents, the spaces designed in Dream Ballet themes exude sophistication.
                </p>
    
               </div>
               
                <h4 class="heading-sivaji">
                    Morandi- A Blend Of Steel And Wood
            
                </h4>
    
                <div class="sivaji-details w-100 d-flex justify-content-center ">
    
                    <p>
                        Morandi showcases a skillful use of steel and wood. Paired with a chic urban color palette, it is simple and sophisticated. 
            Hints of texture and smoky green make the designs visually appealing.
                    </p>
    
                </div>
               
                <h4 class="heading-sivaji">
                    Modern Chic- A Unique Rendition Of Art Deco
            
                </h4>
                <div class="sivaji-details w-100 d-flex justify-content-center">
    
                    <p>
                        Modern Chic is our version of the sumptuous Art Deco style. You get the best of both worlds with its integration of the luxurious elements of Art Deco with 
            contemporary minimalism. Key features include maximized storage, gold accents, arrowhead design, and metal and glass embellishment.
                    </p>
                
    
                </div>
             
    
            </div>
    
          
    
        </ng-container>
    
        <div *ngIf="BuildingNameGetter() == '140-shivaji'" class="w-100 d-flex justify-content-center mt-4">
            <button  (click)="getQuote()" class="btn-tour px-5" [ngClass]="BuildingNameGetter() == '140-shivaji'?'sivajiFont':''">
                DISCOVER MORE DESIGNS
            </button>
        </div>
       
    </div>
    
    <div class="w-100 mid_banner" [ngStyle]="{'background-image': getUrlmid()}" [ngClass]="BuildingNameGetter() == '140-shivaji'?'sivajiheight':''"   ></div>
    
    <div class="container-fluid py-5 perfect-fit-wrapper" *ngIf="BuildingNameGetter() == 'marathon-nexzone'" >
        <h3 class="pb-3 plan_heading" >
            PLANNED TO PERFECTION
        </h3>
        <p  class="m-auto">
            {{BuildingNameGetter() == 'marathon-nexzone'?'Nexzone has been planned with your need in mind.':'Our Designs for 140 Shivaji Park have been Planned with your needs in mind'}}
        </p>
    </div>
    <div class="w-100 d-flex align-items-center plans_do_right  py-5"  >
        <div class="col-12" >
    
       
            <div class="row justify-content-center">
                <div class="col-md-5">
                    <div  style="background-image: none !important; display: flex;  justify-content: center; ">
                        <iframe style="border: none;"  src="https://www.youtube.com/embed/x1OJxtkWavU" >
                        </iframe>
                     </div>   
                </div>
                <div class="col-md-5 d-flex flex-column justify-content-center mt-4 mt-md-4">
                    <h3  *ngIf="BuildingNameGetter() == 'marathon-nexzone'" class="text-lg-left marathonheadline pb-2">Discover the Transformative Journeys of Our Valued Customers</h3>
                    <div *ngIf="BuildingNameGetter() == 'marathon-nexzone'" class="w-100 d-flex mt-4 justify-content-lg-start justify-content-center align-self-end">
                        <button (click)="bookConsultationLandingPage()" class="btn-tour px-5" [ngClass]="BuildingNameGetter() == '140-shivaji'?'sivajiFont':''">
                            {{BuildingNameGetter() == 'marathon-nexzone'?'GET THIS DESIGN':'Book A Free Consultation'}}
                        </button>
                    </div>  
                </div>
            </div>
           

            <p *ngIf="BuildingNameGetter() == '140-shivaji'" class="m-auto pb-2" [ngClass]="BuildingNameGetter() == '140-shivaji'?'sivajiFont':''">
                Custom furniture, functional yet exquisite designs, top-quality materials — add in your unique personal 
                touch and we can create one-of-a-kind interiors together. Design your dream home today!
            </p>
    
            <div *ngIf="BuildingNameGetter() != 'marathon-nexzone'" class="w-100 d-flex justify-content-center mt-4">
                <button (click)="bookConsultationLandingPage()" class="btn-tour px-5" [ngClass]="BuildingNameGetter() == '140-shivaji'?'sivajiFont':''">
                    {{BuildingNameGetter() == 'marathon-nexzone'?'GET THIS DESIGN':'Book A Free Consultation'}}
                </button>
            </div>
        </div>
    </div>
        </div>

    <div class="root-overflow" *ngIf="BuildingNameGetter() == 'anantraj-villa'" >
        <app-new-header-for-home [parentSubject]="parentSubject"  ></app-new-header-for-home>
        <div class="container-fluid marathon_banner anantraj-villa" 
        style="background-image:url('../../assets/images/Banneranath.png');" >
        
        
           
        
            <div class="marthon_wrapper sivajiwrapper   w-100 h-100 " >
                <div class="w-100" >
                    <img class="float-right image_logo mt-2" src="../../assets/images/ananthlogo.png" alt="">
                </div>
               
                
                <div class="Mobile_div  d-flex h-100 justify-content-center m-auto w-100">
                    <div class="divforDes mx-auto">
        
                        <h2>EXPERIENCE THE LIFE SOPHISTICATED GRANDEUR</h2>
                        <div class="w-100 d-flex justify-content-center">
        
        
                            <p>
                                Move Into The Luxury Mansion Of Your Dreams
                            </p>
                
        
                        </div>
                      
                        <div class="col-md-10 Mobile_div wrarpper_bhk m-auto">
                            <div class="row w-100">
                                <div class="col-12">
                                    <div class="row" *ngIf="BuildingNameGetter() == 'marathon-nexzone'">
                                        <div class="col-4 format_type">
                                           <a href="http://pano.yfway.com/50918713" target="_blank"> Essenia</a>
                                                                                   </div>
                                        <div class="col-4 format_type">
                                          <a href="http://pano.yfway.com/50917213" target="_blank">Luxuria</a>  
                                                                                   </div>
                                        <div class="col-4 lastFormat ">
                                           <a href="http://pano.yfway.com/50928244" target="_blank">Premia</a> 
                                                                                   </div>
                                    </div>
                                </div>
                             
                            </div>              
                        </div>

                        
        
                    </div>
                   
                   
                </div>
            </div>
            
        </div>
       
        <div class="container-fluid py-5 perfect-fit-wrapper px-0 ">
            <h3 class="pb-3 sivajiFont">

                An Elegant And Unique Mansion
             
            </h3>
          
            <p  id="sivajipan"  class="m-auto sivajipan">
                We bring to you the sophistication of luxurious Italian design for the mansion of your dreams— inspired from the Bauhaus, 
bohemian and fusion styles, specially curated for those with a cultivated taste. Move into a fully-furnished home with modern and 
ecological amenities with chic and intimate interiors by Arrivae.
               
            </p>
          
          
        </div>
        <app-catlouge-apartment></app-catlouge-apartment>
        <div class="container-fluid images_display">
            <div class="d-flex w-100 flex-wrap justify-content-space-between">
                <div class="image_dis">
                    <img class="w-100 h-100" src='../../assets/images/anath1.png' />
                </div>
                <div class="image_dis ml-2">
                    <img class="w-100 h-100" src='../../assets/images/anath3.png'/>
                </div>
                <div class="image_dis ml-2">
                    <img class="w-100 h-100" src='../../assets/images/anath4.png' />
                </div>
            </div>
        </div>
        <div class="container-fluid py-5 perfect-fit-wrapper">
            <h3 class="pb-3 sivajiFont" >
                Discover The Luxury Of Italian Design Through Our Themes
            </h3>
           
            <ng-container>
                <div >
                    <h4 class="heading-sivaji">
                        Golden Murano- Luxury Read In Bold And Beautiful Design
                
                    </h4>
            
                   <div class="sivaji-details w-100 d-flex justify-content-center">
        
                    <p>
                        Perfect for large spaces, Golden Murano is chic and bold. It creates an ambience of luxury with the expert use of wood and shades of white punctuated with gold accents.

                    </p>
        
                   </div>
                   
                    <h4 class="heading-sivaji">
                        Modica- A Collaboration Of Minimal And Natural Finishes
                
                    </h4>
        
                    <div class="sivaji-details w-100 d-flex justify-content-center ">
        
                        <p>
                            Modica marries functionality and minimalism to create elegant interiors. Its simple and clean design is enhanced by the adept utilization of dark wood tones, leather and glass.


                        </p>
        
                    </div>
                   
                    <h4 class="heading-sivaji">
                        Dark Knight- Affordable Luxury Italian Designs

                
                    </h4>
                    <div class="sivaji-details w-100 d-flex justify-content-center">
        
                        <p>
                            The personification of opulence, Dark Knight uses a deep neutral palette, plush textiles, metal accents and sleek lighting to create elevated interiors.

                        </p>
                    
        
                    </div>
                 
        
                </div>
        
              
        
            </ng-container>
        
            <div class="w-100 d-flex justify-content-center mt-4">
                <button  (click)="getQuote()" class="btn-tour px-5 sivajiFont" >
                    DISCOVER MORE DESIGNS
                </button>
            </div>
           
        </div>
        
        <div class="w-100 mid_banner sivajiheight"  style="background-image:url('../../assets/images/ananthmidbanner.png') ;" ></div>
        
        
        <div class="w-100 d-flex align-items-center plans_do_right py-5">
            <div class="col-12">
                <h3  class="pb-2">Design The Home Of Your Dreams
                </h3>
        
                
                <p  class="m-auto pb-2 sivajiFont" >
                    Functional and elegant designs, custom furniture, top-quality materials, and a chance to personalize 
each room to your taste and requirements— design your dream home with us!

                </p>
        
                <div class="w-100 d-flex justify-content-center mt-4">
                    <button (click)="bookConsultationLandingPage()" class="btn-tour px-5 sivajiFont" >
                        Book A Free Consultation
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="root-overflow" *ngIf="BuildingNameGetter() == 'southern-crest'" >
        <app-new-header-for-home [parentSubject]="parentSubject"  ></app-new-header-for-home>
        <div class="container-fluid marathon_banner southern-crest" 
        style="background-image:url('../../assets/images/srirambanner.png') ;" >
        
        
            
        
            <div class="marthon_wrapper sivajiwrapper   w-100 h-100 " >
                <div class="w-100" >
                    
                </div>
                
                
                <div class="Mobile_div  d-flex h-100 justify-content-center m-auto w-100">
                    <div class="divforDes mx-auto">
        
                        <h2 class="mt-5">EXPERIENCE THE BEST OF URBAN LIFE</h2>
                        <div class="w-100 d-flex justify-content-center">
        
        
                            <p>
                                With Modern And Efficient Designs
                            </p> <br>
                
                            
        
                        </div>

                        <h3 class="mt-3">
                            2 AND 2.5 BHK LUXURY APARTMENTS

                        </h3>
                        
                        <div class="col-md-10 Mobile_div wrarpper_bhk m-auto">
                            <div class="row w-100">
                                <div class="col-12">
                                    <div class="row" *ngIf="BuildingNameGetter() == 'marathon-nexzone'">
                                        <div class="col-4 format_type">
                                            <a href="http://pano.yfway.com/50918713" target="_blank"> Essenia</a>
                                                                                     </div>
                                         <div class="col-4 format_type">
                                           <a href="http://pano.yfway.com/50917213" target="_blank">Luxuria</a>  
                                                                                     </div>
                                         <div class="col-4 lastFormat ">
                                            <a href="http://pano.yfway.com/50928244" target="_blank">Premia</a> 
                                                                                     </div>
                                    </div>
                                </div>
                                
                            </div>              
                        </div>
        
                    </div>
                    
                    
                </div>
            </div>
            
        </div>
        
        <div class="container-fluid py-5 perfect-fit-wrapper px-0 ">
            <h3 class="pb-3 sivajiFont">

                Find The Perfect Fit For You

                
            </h3>
            
            <p  id="sivajipan"  class="m-auto sivajipan">
                Life at Shriram Southern Crest, JP Nagar comes with a host of world-class amenities and 
                Vaastu compliant homes. Choose the best option for you from a host of apartment configurations. 
                Turn this house into an inviting home with Arrivae’s elegant, exclusive design themes.
                
                
            </p>
         
            
        </div>
        <app-catlouge-apartment></app-catlouge-apartment>
        <div class="container-fluid images_display">
            <div class="d-flex w-100 flex-wrap justify-content-space-between">
                <div class="image_dis">
                    <img class="w-100 h-100" src='../../assets/images/sriram1.png' />
                </div>
                <div class="image_dis ml-2">
                    <img class="w-100 h-100" src='../../assets/images/sriram3.png'/>
                </div>
                <div class="image_dis ml-2">
                    <img class="w-100 h-100" src='../../assets/images/sriram4.png' />
                </div>
            </div>
        </div>
        <div class="container-fluid py-5 perfect-fit-wrapper">
            <h3 class="pb-3 sivajiFont" >
                Discover The Luxury Of Italian Design Through Our Themes
            </h3>
            
            <ng-container>
                <div >
                    <h4 class="heading-sivaji">
                        Morandi- A Blend Of Steel And Wood
                
                    </h4>
            
                    <div class="sivaji-details w-100 d-flex justify-content-center">
        
                    <p>
                        Morandi showcases a skillful use of steel and wood. Paired with a chic urban color palette, it is simple and sophisticated. 
                        Hints of texture and smoky green make the designs visually appealing.

                    </p>
        
                    </div>
                    
                    <h4 class="heading-sivaji">
                        Urban Elegance- For Focused And Organized Spaces
                
                    </h4>
        
                    <div class="sivaji-details w-100 d-flex justify-content-center ">
        
                        <p>
                            Intelligent storage solutions and luxurious aesthetics are at the forefront of Urban Elegance. It also highlights the flair and versatility of a neutral color palette.


                        </p>
        
                    </div>
                    
                    <h4 class="heading-sivaji">
                        Macaron- Modern, Affordable, Textured, Contemporary

                
                    </h4>
                    <div class="sivaji-details w-100 d-flex justify-content-center">
        
                        <p>
                            Macaroon's primary color palette of whites and subtle pinks lends to charming and youthful designs. This contemporary aesthetic is 
                            peppered with textures and light wood accents for a dynamic and chic look.
                            
                            
                            

                        </p>
                    
        
                    </div>
                    
        
                </div>
        
                
        
            </ng-container>
        
            <div class="w-100 d-flex justify-content-center mt-4">
                <button  (click)="getQuote()" class="btn-tour px-5 sivajiFont" >
                    DISCOVER MORE DESIGNS
                </button>
            </div>
            
        </div>
        
        <div class="w-100 mid_banner sivajiheight"  style="background-image:url('../../assets/images/srirammid.png') ;" ></div>
        
        
        <div class="w-100 d-flex align-items-center plans_do_right py-5">
            <div class="col-12">
                <h3  class="pb-2">Design The Home Of Your Dreams
                </h3>
        
                
                <p  class="m-auto pb-2 sivajiFont" >
                    Functional and elegant designs, custom furniture, top-quality materials, a chance to add your unique 
                    touch to each room with hassle-free execution— design your dream home with us.

                </p>
        
                <div class="w-100 d-flex justify-content-center mt-4">
                    <button (click)="bookConsultationLandingPage()" class="btn-tour px-5 sivajiFont" >
                        Book A Free Consultation
                    </button>
                </div>
            </div>
        </div>
    </div>
    
    

    <div class="root-overflow" *ngIf="BuildingNameGetter() == 'Tridhaatu-Morya'" >
        <app-new-header-for-home [parentSubject]="parentSubject"  ></app-new-header-for-home>
        <div class="container-fluid marathon_banner southern-crest" 
        style="background-image:url('../../assets/images/mourya/Banner\ 1\ 1.png');" >
        
        
            
        
            <div class="marthon_wrapper sivajiwrapper   w-100 h-100 " >
                <div class="w-100" >
                    
                </div>
                
                
                <div class="Mobile_div  d-flex h-100 justify-content-center m-auto w-100">
                    <div class="divforDes mx-auto">
        
                        <h2 class="mt-5">EXPLORE SOPHISTICATED DESIGNS</h2>
                        <div class="w-100 d-flex justify-content-center">
        
        
                            <p style="width: unset !important;">
                                Finesse, Elegance And Convenience Define Life At Tridhaatu Morya
                            </p> <br>
                
                            
        
                        </div>

                        <h3 class="mt-3">
                            1 AND 2 BHK LUXURY APARTMENTS
                        </h3>

                        <div class="col-12">
                            <div class="row">
                                <div class="col-4 format_type">
                                   <a    (click)="successmessage('https://arrivae-production.s3.amazonaws.com/anarock%20Booklet.pdf')"> Essentia </a>
                                   <a class="padForFirst"  href="http://pano.yfway.com/56543138" target="_blank"> <img style="padding-bottom: 2px;" src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>
                                                                   </div>
                                <div class="col-4 format_type">
                                    <a   (click)="successmessage('https://arrivae-production.s3.amazonaws.com/anarock%20Booklet.pdf')">Premia</a> 
                                    <a class="pl-2  pl-md-3"  href=" http://pano.yfway.com/56543126" target="_blank"> <img style="padding-bottom: 2px;" src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>
                                                                    </div>
                                <div class="col-4 lastFormat ">
                                    <a   (click)="successmessage('https://arrivae-production.s3.amazonaws.com/anarock%20Booklet.pdf')">Luxuria</a>  
                                    <a class="pl-2 pl-md-3" href="https://pano.yfway.com/56544661" target="_blank"> <img style="padding-bottom: 2px;" src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>
                                                                    </div>
                            </div>
                        </div>
                        
                   
        
                    </div>
                    
                    
                </div>
            </div>
            
        </div>
        
        <div class="container-fluid py-5 perfect-fit-wrapper px-0 ">
            <h3 class="pb-3 sivajiFont">

                Ideal Urban Living
                
            </h3>
            
            <p  id="sivajipan"  class="m-auto sivajipan">
                At Tridhaatu Morya you get top-notch internal and external amenities, excellent connectivity and 
                sophisticated interiors by Arrivae at the prime location Chembur, Mumbai.
                
                
            </p>
        
            
        </div>
        <app-catlouge-apartment></app-catlouge-apartment>
        <div class="container-fluid images_display">
            <div class="d-flex w-100 flex-wrap justify-content-space-between">
                <div class="image_dis">
                    <img class="w-100 h-100" src='../../assets/images/mourya/1.png' />
                </div>
                <div class="image_dis ml-2">
                    <img class="w-100 h-100" src='../../assets/images/mourya/3.png'/>
                </div>
                <div class="image_dis ml-2">
                    <img class="w-100 h-100" src='../../assets/images/mourya/4.png' />
                </div>
            </div>
        </div>
        <div class="container-fluid py-5 perfect-fit-wrapper">
            <h3 class="pb-3 sivajiFont" >
                Discover The Luxury Of Italian Design Through Our Themes
            </h3>
            
            <ng-container>
                <div >
                    <h4 class="heading-sivaji">
                        Morandi- A Blend Of Steel And Wood
                
                    </h4>
            
                    <div class="sivaji-details w-100 d-flex justify-content-center">
        
                    <p>
                        Morandi showcases a skillful use of steel and wood. Paired with a chic urban color palette, it is simple and sophisticated. 
                        Hints of texture and smoky green make the designs visually appealing.

                    </p>
        
                    </div>
                    
                    <h4 class="heading-sivaji">
                        Urban Elegance- For Focused And Organized Spaces
                
                    </h4>
        
                    <div class="sivaji-details w-100 d-flex justify-content-center ">
        
                        <p>
                            Intelligent storage solutions and luxurious aesthetics are at the forefront of Urban Elegance. It also highlights the flair and versatility of a neutral color palette.


                        </p>
        
                    </div>
                    
                    <h4 class="heading-sivaji">
                        Macaron- Modern, Affordable, Textured, Contemporary

                
                    </h4>
                    <div class="sivaji-details w-100 d-flex justify-content-center">
        
                        <p>
                            Macaroon's primary color palette of whites and subtle pinks lends to charming and youthful designs. This contemporary aesthetic is 
                            peppered with textures and light wood accents for a dynamic and chic look.
                            
                            
                            

                        </p>
                    
        
                    </div>
                    
        
                </div>
        
                
        
            </ng-container>
        
            <div class="w-100 d-flex justify-content-center mt-4">
                <button  (click)="getQuote()" class="btn-tour px-5 sivajiFont" >
                    DISCOVER MORE DESIGNS
                </button>
            </div>
            
        </div>
        
        <div class="w-100 mid_banner sivajiheight"  style="background-image:url('../../assets/images/mourya/5\ 1\ \(1\).png') ;" ></div>
        
        
        <div class="w-100 d-flex align-items-center plans_do_right py-5">
            <div class="col-12">
                <h3  class="pb-2">Design The Home Of Your Dreams
                </h3>
        
                
                <p  class="m-auto pb-2 sivajiFont" >
                    Functional and elegant designs, custom furniture, top-quality materials, a chance to add your unique 
                    touch to each room with hassle-free execution— design your dream home with us.

                </p>
        
                <div class="w-100 d-flex justify-content-center mt-4">
                    <button (click)="bookConsultationLandingPage()" class="btn-tour px-5 sivajiFont" >
                        Book A Free Consultation
                    </button>
                </div>
            </div>
            </div>
            </div>

            <div class="root-overflow" *ngIf="BuildingNameGetter() == 'sky-forest'" >
                <app-new-header-for-home [parentSubject]="parentSubject"  ></app-new-header-for-home>
                <div class="container-fluid marathon_banner sivaji-banner" 
                style="background-image:url('../../assets/images/skyforest.png') ;" >
                
                
                   
                
                    <div class="marthon_wrapper sivajiwrapper   w-100 h-100 " >
                        <div class="w-100" >
                          
                        </div>
                       
                        
                        <div class="Mobile_div  d-flex h-100 justify-content-center m-auto w-100">
                            <div class="divforDes mx-auto">
                
                                <h2 class="mt-lg-5 mt-0">DISCOVER A LIFE OF URBAN LUXURY </h2>
                                <div class="w-100 d-flex justify-content-center">
                
                
                                    <p class="mb-0">
                                        With Magnificent Duplex Flats In Lower Parel, Mumbai

                                    </p> <br>
                        
                                  
                
                                </div>
    
                                <h3 class="mt-3">
                                    2 AND 2.5 BHK LUXURY APARTMENTS
    
                                </h3>
                              
                                <div class="col-md-10 Mobile_div wrarpper_bhk m-auto">
                                    <div class="row w-100">
                                        <div class="col-12 mt-4">
                                            <div class="row">
                                                <div class="col-3 format_type">
                                                    <a href="http://pano.yfway.com/51124654" target="_blank">3 BHK</a>
                                                </div>
                                                <div class="col-3 format_type">
                                                    <a href="http://pano.yfway.com/51124654" target="_blank">4 BHK</a>  
                                                </div>
                                                <div class="col-3 format_type ">
                                                   <a href="http://pano.yfway.com/51124654" target="_blank">5 BHK</a> 
                                                </div>
                                                <div class="col-3  lastFormat">
                                                    <a href="http://pano.yfway.com/51124654" target="_blank"> 6 BHK</a>
                                                 </div>
                                            </div>
                                        </div>

                                        
                                    </div>              
                                </div>

                                <div class="col-12">
                                    <a class="my-4 d-flex justify-content-center" href="https://web.arrivae.com/apartment-detail?apartment_name=Indiabulls%20Sky%20Forest&developer_name=Indiabulls&unid=4000133BHKIBLSF&city=mumbai&perPage=6&lifestage=&theme=&vrlink=false&search=sky%20forest" target="_blank">
                                        <button class="btn-tour px-4 px-lg-5 sivajiFont" >
                                            BOOK NOW
                                        </button> 
                                    </a>

                                    <div class="d-flex justify-content-center">
                                        <form class="nameAndPhone justify-content-center d-flex flex-lg-row flex-column" [formGroup]= "additionalApartmentDetails">
                                                <input  formControlName="namee" class="px-4 py-2 mx-lg-3 nameField mb-lg-0 mb-3" placeholder="Please Enter Your Name" type="text"/>
                                                <input oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"  formControlName="phone_number" class="px-4 py-2 mx-lg-3 nameField mb-lg-0 mb-3" placeholder="Phone Number"  type="number"/>
                                                <button [disabled] = "additionalApartmentDetails.invalid" class="nameDetailsButton px-5 py-2 mb-lg-0 mb-3" (click)="NameAndPhn(additionalApartmentDetails.value)" >GET A QUOTE</button>
                                        </form>
                                    </div>
                                 
                                </div>
                
                            </div>
                           
                           
                        </div>
                    </div>
                    
                </div>
               
                <div class="container-fluid py-5 perfect-fit-wrapper px-0 ">
                    <h3 class="pb-3 sivajiFont">
        
                        A Combination Of Opulent Spaces And Accessible Living
    
                     
                    </h3>
                  
                    <p  id="sivajipan"  class="m-auto sivajipan pb-4">
                        Escape the constraints of urban living with IndiaBulls Sky Forest’s glorious duplex 
                        adorned in Arrivae’s exclusive luxurious Italian design themes.
                        
                        
                       
                    </p>

                    <p  id="sivajipan"  class="m-auto sivajipan">
                        Located in the heart of Mumbai’s business district, the swankiest of eateries, luxury brands and 
                        best schools and hospitals, and more are only minutes away.
                        
                        
                        
                       
                    </p>
                
                  
                </div>
                <app-catlouge-apartment></app-catlouge-apartment>
                <div class="container-fluid images_display">
                    <div class="d-flex w-100 flex-wrap justify-content-space-between">
                        <div class="image_dis">
                            <img class="w-100 h-100" src='https://s3.amazonaws.com/arrivae-production/contents/documents/000/079/655/original/data?1671785845' />
                        </div>
                        <div class="image_dis ml-2">
                            <img class="w-100 h-100" src='https://s3.amazonaws.com/arrivae-production/contents/documents/000/079/659/original/data?1671785847'/>
                        </div>
                        <div class="image_dis ml-2">
                            <img class="w-100 h-100" src='https://s3.amazonaws.com/arrivae-production/contents/documents/000/079/658/original/data?1671785847' />
                        </div>
                    </div>
                </div>
                <div class="container-fluid py-5 perfect-fit-wrapper">
                    <h3 class="pb-3 sivajiFont" >
                        Discover The Luxury Of Italian Design Through Our Themes

                    </h3>
                   
                    <ng-container>
                        <div >
                            <h4 class="heading-sivaji">
                                Golden Murano- Luxury Read In Bold And Beautiful Design

                        
                            </h4>
                    
                           <div class="sivaji-details w-100 d-flex justify-content-center">
                
                            <p>
                                Perfect for large spaces, Golden Murano is chic and bold. It creates an ambience of luxury with the expert use of wood and shades of white punctuated with gold accents.


        
                            </p>
                
                           </div>
                           
                            <h4 class="heading-sivaji">
                                Morandi- A Blend Of Steel And Wood

                        
                            </h4>
                
                            <div class="sivaji-details w-100 d-flex justify-content-center ">
                
                                <p>
                                    Morandi showcases a skillful use of steel and wood. Paired with a chic urban color palette, it is simple and sophisticated. 
                                    Hints of texture and smoky green make the designs visually appealing.
        
        
                                </p>
                
                            </div>
                           
                            <h4 class="heading-sivaji">
                                Modern Chic- A Unique Rendition Of Art Deco
                        
                            </h4>
                            <div class="sivaji-details w-100 d-flex justify-content-center">
                
                                <p>
                                    Our unique take on the swanky Art Deco style, Modern Chic marries glamor and contemporary minimalism with a focus on maximizing storage, making it ideal for urban living.


                                    
                                    
        
                                </p>
                            
                
                            </div>
                         
                
                        </div>
                
                      
                
                    </ng-container>
                
                    <div class="w-100 d-flex justify-content-center mt-4">
                        <button  (click)="getQuote()" class="btn-tour px-5 sivajiFont" >
                            DISCOVER MORE DESIGNS
                        </button>
                    </div>
                   
                </div>
                
                <div class="w-100 mid_banner sivajiheight"  style="background-image:url('https://s3.amazonaws.com/arrivae-production/contents/documents/000/079/715/original/data?1671791875') ;" ></div>
                
                
                <div class="w-100 d-flex align-items-center plans_do_right py-5">
                    <div class="col-12">
                        <h3  class="pb-2">Design The Home Of Your Dreams
                        </h3>
                
                        
                        <p  class="m-auto pb-2 sivajiFont" >
                            Functional and elegant designs, custom furniture, top-quality materials, and a chance to add your unique 
                            touch to each room— design your dream home with us.
                            
        
                        </p>
                
                        <div class="w-100 d-flex justify-content-center mt-4">
                            <button (click)="bookConsultationLandingPage()" class="btn-tour px-5 sivajiFont" >
                                Book A Free Consultation
                            </button>
                        </div>
                    </div>
                </div>
                </div>
        <div class="root-overflow" *ngIf="BuildingNameGetter() == 'adhiraj-capital-city'" >
            <app-new-header-for-home [parentSubject]="parentSubject"  ></app-new-header-for-home>
            <div class="container-fluid marathon_banner adhiraj-mobile sivaji-banner" 
            style="background-image:url('../../assets/images/2-1-1.jpeg') ;height: 622px;" >
                <div class="marthon_wrapper sivajiwrapper   w-100 h-100 " >
                    <div class="w-100" >
                    </div>
                    <div class="Mobile_div  d-flex h-100 justify-content-center m-auto w-100">
                        <div class="divforDes adhr-mt-top mx-auto">
                            <h2 class="mt-lg-2 mt-0">DISCOVER A LIFE OF URBAN LUXURY </h2>
                            <div class="w-100 d-flex justify-content-center">
                                <p class="mb-0">
                                    With Magnificent Flats In Kharghar, New Mumbai
                                </p> <br>
                            </div>
                            <h3 class="mt-3 font-adhR">
                                Upgrade Urban Living with Adhiraj Capital City, Now Featuring Arrivae’s Elegant Home Interior Designs
                            </h3>
                            <div class="col-md-10 Mobile_div wrarpper_bhk m-auto">
                                <div class="row w-100">
                                    <div class="col-12 mt-4">
                                        <div class="row">
                                            <div class="col-4 format_type">
                                                <a   (click)="successmessage('https://arrivae-uat.s3.amazonaws.com/Adhiraj-Essentia.pdf')"> Essentia </a>
                                                <a class="padForFirst" href="http://pano.yfway.com/55134332" target="_blank"> <img src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>
                                            </div>
                                             <div class="col-4 format_type  ">
                                                <a (click)="successmessage('https://arrivae-uat.s3.amazonaws.com/Adhiraj-Premia.pdf')">Premia</a> 
                                                <a class="pl-2  pl-md-3" href="http://pano.yfway.com/55134402" target="_blank"> <img src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a> 
                                             </div>
                                             <div class="col-4 lastFormat ">
                                                <a  (click)="successmessage('https://arrivae-uat.s3.amazonaws.com/Adhiraj-Luxuria.pdf')">Luxuria</a>  
                                                <a class="pl-2 pl-md-3" href="http://pano.yfway.com/55092437" target="_blank"> <img src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a> 
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>              
                            </div>
                            <div class="col-12">
                                <a style="text-decoration: none;" class="my-4 d-flex justify-content-center" href="https://web.arrivae.com/apartment-detail?apartment_name=Adhiraj%20Codename%20Capital%20City&developer_name=Adhiraj%20Constructions%20Pvt.%20Ltd&unid=410208ACCC0012B&city=navi%20mumbai&perPage=6&lifestage=&theme=&vrlink=false&search=" target="_blank">
                                    <button class="btn-tour px-4 px-lg-5 sivajiFont" >
                                        EXPLORE NOW
                                    </button> 
                                </a>
                                <div class="d-flex justify-content-center">
                                    <form class="nameAndPhone justify-content-center d-flex flex-lg-row flex-column" [formGroup]= "additionalApartmentDetails">
                                            <input  formControlName="namee" class="px-4 py-2 mx-lg-3 nameField mb-lg-0 mb-3" placeholder="Please Enter Your Name" type="text"/>
                                            <input oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"  formControlName="phone_number" class="px-4 py-2 mx-lg-3 nameField mb-lg-0 mb-3" placeholder="Phone Number"  type="number"/>
                                            <button [disabled] = "additionalApartmentDetails.invalid" class="nameDetailsButton px-5 py-2 mb-lg-0 mb-3" (click)="NameAndPhn(additionalApartmentDetails.value)" >GET A QUOTE</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid py-5 perfect-fit-wrapper Adhiarj px-0 ">
                <h3 class="pb-3 sivajiFont">
                    Impeccable Home Interior Designs to Revitalize Spaces
                </h3>
          
                <p  id="sivajipan"  class="m-auto sivajipan">
                    At Arrivae, we breathe life into spaces and stitch your dreams with reality, meticulously crafting homes that resonate with your identity and lifestyle. Our home interior designs exclusively for Adhiraj is a splendid tapestry of sophistication, innovation, and functionality that harmoniously merge to create an abode where every corner speaks volumes about the inhabitants.
               
              
            </div>
            <app-catlouge-apartment></app-catlouge-apartment>
           
            <div class="container-fluid images_display">
                <div class="d-flex w-100 flex-wrap justify-content-space-between">
                    <div class="image_dis">
                        <img class="w-100 h-100" src='https://s3.amazonaws.com/arrivae-production/contents/documents/000/098/811/original/data?1696654063' />
                    </div>
                    <div class="image_dis ml-2">
                        <img class="w-100 h-100" src="https://s3.amazonaws.com/arrivae-production/contents/documents/000/098/826/original/data?1696657017"/>
                    </div>
                    <div class="image_dis ml-2">
                        <img class="w-100 h-100" src='https://s3.amazonaws.com/arrivae-production/contents/documents/000/098/809/original/data?1696654061' />
                    </div>
                </div>
            </div>
            <div class="container-fluid py-5 perfect-fit-wrapper">
                <h3 class="pb-3 sivajiFont" >
                    Discover The Luxury Of Italian Design Through Our Themes
                </h3>
                <ng-container>
                    <div >
                        <h4 class="heading-sivaji">
                            Golden Murano- Luxury Read In Bold And Beautiful Design
                        </h4>
                       <div class="sivaji-details w-100 d-flex justify-content-center">
                        <p>
                            Perfect for large spaces, Golden Murano is chic and bold. It creates an ambience of luxury with the expert use of wood and shades of white punctuated with gold accents.
                        </p>
                       </div>
                        <h4 class="heading-sivaji">
                            Morandi- A Blend Of Steel And Wood
                        </h4>
                        <div class="sivaji-details w-100 d-flex justify-content-center ">
                            <p>
                                Morandi showcases a skillful use of steel and wood. Paired with a chic urban color palette, it is simple and sophisticated. 
                                Hints of texture and smoky green make the designs visually appealing.
                            </p>
                        </div>
                        <h4 class="heading-sivaji">
                            Modern Chic- A Unique Rendition Of Art Deco
                        </h4>
                        <div class="sivaji-details w-100 d-flex justify-content-center">
                            <p>
                                Our unique take on the swanky Art Deco style, Modern Chic marries glamor and contemporary minimalism with a focus on maximizing storage, making it ideal for urban living.
                            </p>
                        </div>
                    </div>
                </ng-container>
                <div class="w-100 d-flex justify-content-center mt-4">
                    <button  (click)="getQuote()" class="btn-tour px-5 sivajiFont" >
                        DISCOVER MORE DESIGNS
                    </button>
                </div>
            </div>
            <div class="w-100 mid_banner sivajiheight"  style="background-image:url('https://s3.amazonaws.com/arrivae-production/contents/documents/000/098/803/original/data?1696653908') ;" ></div>
            <div class="w-100 d-flex align-items-center plans_do_right py-5">
                <div class="col-12">
                    <h3  class="pb-2">Discover the Transformative Journeys of Our Valued Customers
                    </h3>
                    <p  class="m-auto pb-2 sivajiFont" >
                        Functional and elegant designs, custom furniture, top-quality materials, and a chance to add your unique 
                        touch to each room— design your dream home with us.
                    </p>
                    <div class="row justify-content-center m-4">
                        <div class=" col-md-6 col-sm-6 h-100 ">
                            <div  class="w-100 cursorPointer groungimage1" *ngIf ="!allVideos" (click)="videoPlayIn('https://www.youtube.com/embed/GwWAiNnipcg?autoplay=1&loop=1')">
                                <div class="playIconStyles">
                                   <img src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
                                </div>
                             </div>
                             <div class="w-100" *ngIf ="allVideos" >
                                <iframe  allow="autoplay" class="w-100" width="420"  src="https://www.youtube.com/embed/GwWAiNnipcg?autoplay=1&loop=1'" frameborder="0" allowfullscreen>
                                </iframe>
                             </div>
                         </div>
                    </div>
                    <div class="w-100 d-flex justify-content-center mt-4">
                        <button (click)="bookConsultationLandingPage()" class="btn-tour px-5 sivajiFont" >
                            Book A Free Consultation
                        </button>
                    </div>
                </div>
            </div>
        </div>
                    <div class="root-overflow" *ngIf="BuildingNameGetter() == 'Runwal_Gardens'" >
                        <app-new-header-for-home [parentSubject]="parentSubject"  ></app-new-header-for-home>
                        <div class="container-fluid marathon_banner adhiraj-mobile sivaji-banner" 
                        style="background-image:url('../../assets/images/runwal_gardens.jpeg') ;height: 622px;" >
                        
                        
                           
                        
                            <div class="marthon_wrapper sivajiwrapper   w-100 h-100 " >
                                <div class="w-100" >
                                  
                                </div>
                               
                                
                                <div class="Mobile_div  d-flex h-100 justify-content-center m-auto w-100">
                                    <div class="divforDes adhr-mt-top mx-auto">
                        
                                        <h2 class="mt-lg-2 mt-0">DISCOVER A LIFE OF URBAN LUXURY </h2>
                                        <div class="w-100 d-flex justify-content-center">
                        
                        
                                            <p class="mb-0">
                                                With Magnificent Flats In Dombivali, New Mumbai
        
                                            </p> <br>
                                
                                          
                        
                                        </div>
            
                                        <h3 class="mt-3 font-adhR">
                                            Upgrade Urban Living with Runwal Gardens, Now Featuring Arrivae’s Elegant Home Interior Designs
                                        </h3>
                                      
                                        <div class="col-md-10 Mobile_div wrarpper_bhk m-auto">
                                            <div class="row w-100">
                                                <div class="col-12 mt-4">
                                                    <div class="row">
                                                         <div class="col-4 format_type">
                                                            <a   (click)="successmessage('https://arrivae-production.s3.amazonaws.com/Booklet%20Runwal%20Ref.pdf')"> Essentia </a>
                                                            <a class="padForFirst" href="http://pano.yfway.com/47543383" target="_blank"> <img src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>
                                                         </div>
                                                         <div class="col-4 format_type  ">
                                                            <a  (click)="successmessage('https://arrivae-production.s3.amazonaws.com/Booklet%20Runwal%20Ref.pdf')">Premia</a> 
                                                            <a class="pl-2  pl-md-3"  href="http://pano.yfway.com/47546028"  target="_blank"> <img src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>
                                                        </div>
                                                         <div class="col-4 lastFormat ">
                                                            <a  (click)="successmessage('https://arrivae-production.s3.amazonaws.com/Booklet%20Runwal%20Ref.pdf')">Luxuria</a>  
                                                            <a class="pl-2 pl-md-3" href="http://pano.yfway.com/47544119" target="_blank"> <img src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>
                                                        </div>
                                                         
                                                    </div>
                                                </div>
        
                                                
                                            </div>              
                                        </div>
        
                                        <div class="col-12">
                                            <a style="text-decoration: none;" class="my-4 d-flex justify-content-center" href="https://web.arrivae.com/apartment-detail?apartment_name=Runwal%20Gardens&developer_name=Runwal&unid=421204RU01NWALG&city=mumbai&perPage=6&lifestage=&theme=&vrlink=false&search=runwal" target="_blank">
                                                <button class="btn-tour px-4 px-lg-5 sivajiFont" >
                                                    EXPLORE NOW
                                                </button> 
                                            </a>
        
                                            <div class="d-flex justify-content-center">
                                                <form class="nameAndPhone justify-content-center d-flex flex-lg-row flex-column" [formGroup]= "additionalApartmentDetails">
                                                        <input  formControlName="namee" class="px-4 py-2 mx-lg-3 nameField mb-lg-0 mb-3" placeholder="Please Enter Your Name" type="text"/>
                                                        <input oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"  formControlName="phone_number" class="px-4 py-2 mx-lg-3 nameField mb-lg-0 mb-3" placeholder="Phone Number"  type="number"/>
                                                        <button [disabled] = "additionalApartmentDetails.invalid" class="nameDetailsButton px-5 py-2 mb-lg-0 mb-3" (click)="NameAndPhn(additionalApartmentDetails.value)" >GET A QUOTE</button>
                                                </form>
                                            </div>
                                         
                                        </div>
                        
                                    </div>
                                   
                                   
                                </div>
                            </div>
                            
                        </div>
                       
                        <div class="container-fluid py-5 perfect-fit-wrapper Adhiarj px-0 ">
                            <h3 class="pb-3 sivajiFont">
                
                                Impeccable Home Interior Designs to Revitalize Spaces
     
                            </h3>
                          
                       
        
                            <p  id="sivajipan"  class="m-auto sivajipan">
                                At Arrivae, we breathe life into spaces and stitch your dreams with reality, meticulously crafting homes that resonate with your identity and lifestyle. Our home interior designs exclusively for Runwal Gardens is a splendid tapestry of sophistication, innovation, and functionality that harmoniously merge to create an abode where every corner speaks volumes about the inhabitants.
                                
                                
                                
                               
                            </p>
                        
                          
                        </div>
                        <app-catlouge-apartment></app-catlouge-apartment>
                        <div class="container-fluid images_display">
                            <div class="d-flex w-100 flex-wrap justify-content-space-between">
                                <div class="image_dis">
                                    <img class="w-100 h-100" src='https://s3.amazonaws.com/arrivae-production/contents/documents/000/048/322/original/data?1633691902' />
                                </div>
                                <div class="image_dis ml-2">
                                    <img class="w-100 h-100" src='https://s3.amazonaws.com/arrivae-production/contents/documents/000/048/321/original/data?1633691901'/>
                                </div>
                                <div class="image_dis ml-2">
                                    <img class="w-100 h-100" src="https://s3.amazonaws.com/arrivae-production/contents/documents/000/048/315/original/data?1633691774" />
                                </div>
                            </div>
                        </div>
                        <div class="container-fluid py-5 perfect-fit-wrapper">
                            <h3 class="pb-3 sivajiFont" >
                                Discover The Luxury Of Italian Design Through Our Themes
        
                            </h3>
                           
                            <ng-container>
                                <div >
                                    <h4 class="heading-sivaji">
                                        Golden Murano- Luxury Read In Bold And Beautiful Design
        
                                
                                    </h4>
                            
                                   <div class="sivaji-details w-100 d-flex justify-content-center">
                        
                                    <p>
                                        Perfect for large spaces, Golden Murano is chic and bold. It creates an ambience of luxury with the expert use of wood and shades of white punctuated with gold accents.
        
        
                
                                    </p>
                        
                                   </div>
                                   
                                    <h4 class="heading-sivaji">
                                        Morandi- A Blend Of Steel And Wood
        
                                
                                    </h4>
                        
                                    <div class="sivaji-details w-100 d-flex justify-content-center ">
                        
                                        <p>
                                            Morandi showcases a skillful use of steel and wood. Paired with a chic urban color palette, it is simple and sophisticated. 
                                            Hints of texture and smoky green make the designs visually appealing.
                
                
                                        </p>
                        
                                    </div>
                                   
                                    <h4 class="heading-sivaji">
                                        Modern Chic- A Unique Rendition Of Art Deco
                                
                                    </h4>
                                    <div class="sivaji-details w-100 d-flex justify-content-center">
                        
                                        <p>
                                            Our unique take on the swanky Art Deco style, Modern Chic marries glamor and contemporary minimalism with a focus on maximizing storage, making it ideal for urban living.
        
        
                                            
                                            
                
                                        </p>
                                    
                        
                                    </div>
                                 
                        
                                </div>
                        
                              
                        
                            </ng-container>
                        
                            <div class="w-100 d-flex justify-content-center mt-4">
                                <button  (click)="getQuote()" class="btn-tour px-5 sivajiFont" >
                                    DISCOVER MORE DESIGNS
                                </button>
                            </div>
                           
                        </div>
                        
                        <div class="w-100 mid_banner sivajiheight"  style="background-image:url('https://s3.amazonaws.com/arrivae-production/contents/documents/000/048/314/original/data?1633691773') ;" ></div>
                        
                        
                        <div class="w-100 d-flex align-items-center plans_do_right py-5">
                            <div class="col-12">
                                <h3  class="pb-2">Discover the Transformative Journeys of Our Valued Customers
                                </h3>
                        
                                
                                <p  class="m-auto pb-2 sivajiFont" >
                                    Functional and elegant designs, custom furniture, top-quality materials, and a chance to add your unique 
                                    touch to each room— design your dream home with us.
                                    
                
                                </p>
                                <div class="row justify-content-center ">
                                    
                                    <div class=" col-md-6 col-sm-6 h-100  m-4 ">
                                        <div  class="w-100 cursorPointer groungimage1" *ngIf ="!allVideos" (click)="videoPlayIn('https://www.youtube.com/embed/GwWAiNnipcg?autoplay=1&loop=1')">
                                            <div class="playIconStyles">
                                               <img src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
                                            </div>
                                         </div>
                                         <div class="w-100" *ngIf ="allVideos" >
                                            <iframe  allow="autoplay" class="w-100" width="420"   [src]="current_url" frameborder="0" allowfullscreen>
                                            </iframe>
                                         </div>
                                     </div>
                                    
                                     <div  [ngClass]="roletoSet()> 650?'d-block':'d-none'" class="mx-4 px-2" >
                                    <div class="row mx-1 videoThumbnails  mt-3">
                                            <div class="col-lg-3 col-md-6 col-sm-6 px-2" >
                                               <a (click)="videoPlayIn('https://www.youtube.com/embed/Sqr8WhpZSmM?autoplay=1&loop=1')" class="cursorPointer">
                                                  <div  class="image_carrier  align-items-end cursorPointer " >
                                                       <img class="w-100" src="../../../assets/images/youtubeVideos/14.png"/>
                                                  </div>
                                               </a>
                                               <div class="thumb-content">
                                                    <p class="thumb-heading my-2">The Pandurangis Family</p>
                                                    <p class="thumb-para">“We are very, very happy the way Arrivae has done the job, the timely delivery and as well as the cooperation throughout the project! It was entirely a beautiful journey for us. We never felt that we have been doing such a great change in the house.”</p>
                                               </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-6 h-100 px-2 ">
                                               <a (click)="videoPlayIn('https://www.youtube.com/embed/ljhxUFH01Uw?autoplay=1&loop=1')" class="cursorPointer ">
                                                  <div class="image_carrier  align-items-end cursorPointer " >
                                                    <img class="w-100" src="../../../assets/images/thumbfix.png"/>
                                                  </div>
                                               </a>
                                               <div class="thumb-content">
                                                 <p class="thumb-heading my-2">The Sabus Family</p>
                                                 <p class="thumb-para">
                                                    “I'm so happy! What I saw in 3D is exactly what I got. So that is the best part! I got regular mails from them.They were connected with me. When there was a small concern, they attended it. Immediately I got a call from the Mumbai office. So I'm very happy with their service. What they promised they'd delivered.”
                                                 </p>
                                            </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-6 h-100 px-2">
                                               <a (click)="videoPlayIn('https://www.youtube.com/embed/fzd7taoEDL8?autoplay=1&loop=1')" class="cursorPointer ">
                                                  <div class="image_carrier  align-items-end" >
                                                    <img class="w-100" src="../../../assets/images/youtubeVideos/16.png"/>
                                                  </div>
                                               </a>
                                               <div class="thumb-content">
                                                 <p class="thumb-heading my-2">The Subramanian Family</p>
                                                 <p class="thumb-para">
                                                    “When I had this house interior project, I did extensive research on multiple agencies, multiple companies. They have a personal interaction with the client, so that's why I chose Arrivae. What I liked about Arrivae was their openness to my ideas and my suggestions and my thoughts, and then they took it from me and then they transformed into something that really worked for us.”
                                                 </p>
                                              </div>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-6  h-100 px-2">
                                               <a (click)="videoPlayIn('https://www.youtube.com/embed/UJeGixHIWRk?autoplay=1&loop=1')" class="cursorPointer ">
                                                  <div class="image_carrier  align-items-end cursorPointer " >
                                                    <img class="w-100" src="../../../assets/images/youtubeVideos/17.png"/>
                                                  </div>
                                               </a>
                                               <div class="thumb-content">
                                                 <p class="thumb-heading my-2">The Rishabh Family</p>
                                                 <p class="thumb-para">
                                                    “My experience with Arrivae was fabulous— perfectly blending my ideas, my imagery with their expertise and experience in this particular field to bring out a beautifully decorated home. Throughout the design and development process, Arrivae's attention to detail was impeccable and second to none.”
                                                 </p>
                                              </div>
                                            </div>
                                    </div>
                                    </div>
                                     <div [ngClass]="roletoSet() < 650?'d-block':'d-none'" class="w-100">
                                        <div  class=" videoThumbnails pt-3">    
                                        <div slickContainer #slickController="slick" [slickConfig]="config">
                                            <ng-container *ngIf="slickController.initialize">
                                           <div slickItem  (click)="mobilevideoPlayIn2('https://www.youtube.com/embed/Sqr8WhpZSmM?autoplay=1&loop=1')">
                                                      <img class="img-width" src="../../../assets/images/youtubeVideos/14.png"/>
                                              <div  class="thumb-content">
                                                   <p class="thumb-heading my-2">The Pandurangis Family</p>
                                                   <p class="thumb-para">“We are very, very happy the way Arrivae has done the job, the timely delivery and as well as the cooperation throughout the project! It was entirely a beautiful journey for us. We never felt that we have been doing such a great change in the house.”</p>
                                              </div>
                                           </div>
                                           <div slickItem   (click)="mobilevideoPlayIn2('https://www.youtube.com/embed/ljhxUFH01Uw?autoplay=1&loop=1')" >
                                                   <img class="img-width" src="../../../assets/images/thumbfix.png"/>
                                              <div class="thumb-content">
                                                <p class="thumb-heading my-2">The Sabus Family</p>
                                                <p class="thumb-para">
                                                   “I'm so happy! What I saw in 3D is exactly what I got. So that is the best part! I got regular mails from them.They were connected with me. When there was a small concern, they attended it. Immediately I got a call from Mumbai office. So I'm very happy with their service. What they promised they'd delivered.”
                                                </p>
                                           </div>
                                           </div>
                                           <div slickItem  (click)="mobilevideoPlayIn2('https://www.youtube.com/embed/fzd7taoEDL8?autoplay=1&loop=1')" >
                                                   <img class="img-width" src="../../../assets/images/youtubeVideos/16.png"/>
                                              <div class="thumb-content">
                                                <p class="thumb-heading my-2">The Subramanian Family</p>
                                                <p class="thumb-para">
                                                   “When I had this house interior project, I did extensive research on multiple agencies, multiple companies. They have a personal interaction with the client, so that's why I chose Arrivae. What I liked about Arrivae was their openness to my ideas and my suggestions and my thoughts, and then they took it from me and then they transformed into something that really worked for us.”
                                                </p>
                                             </div>
                                           </div>
                                           <div slickItem   (click)="mobilevideoPlayIn2('https://www.youtube.com/embed/UJeGixHIWRk?autoplay=1&loop=1')" >
                                                   <img class="img-width" src="../../../assets/images/youtubeVideos/17.png"/>
                                              <div class="thumb-content">
                                                <p class="thumb-heading my-2">The Rishabh Family</p>
                                                <p class="thumb-para">
                                                   “My experience with Arrivae was fabulous— perfectly blending my ideas, my imagery with their expertise and experience in this particular field to bring out a beautifully decorated home. 
                                                   Throughout the design and development process, Arrivae's attention to detail was impeccable and second to none.”
                                    
                                                </p>
                                             </div>
                                           </div>
                                            </ng-container>
                                        </div>
                    
                                     </div>
                                    </div>
                              
                                </div>
                        
                                <div class="w-100 d-flex justify-content-center mt-4">
                                    <button (click)="bookConsultationLandingPage()" class="btn-tour px-5 sivajiFont" >
                                        Book A Free Consultation
                                    </button>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="root-overflow" *ngIf="BuildingNameGetter() == 'dosti-pine'" >
                            <app-new-header-for-home [parentSubject]="parentSubject"  ></app-new-header-for-home>
                            <div class="container-fluid marathon_banner adhiraj-mobile sivaji-banner" 
                            style="background-image:url('../../assets/images/dostipine.jpeg') ;height: 622px;" >
                            
                            
                               
                            
                                <div class="marthon_wrapper sivajiwrapper   w-100 h-100 " >
                                    <div class="w-100" >
                                      
                                    </div>
                                   
                                    
                                    <div class="Mobile_div  d-flex h-100 justify-content-center m-auto w-100">
                                        <div class="divforDes adhr-mt-top mx-auto">
                            
                                            <h2 class="mt-lg-2 mt-0">DISCOVER A LIFE OF URBAN LUXURY </h2>
                                            <div class="w-100 d-flex justify-content-center">
                            
                            
                                                <p class="mb-0">
                                                    With Magnificent Flats In Thane,  Mumbai
            
                                                </p> <br>
                                    
                                              
                            
                                            </div>
                
                                            <h3 class="mt-3 font-adhR">
                                                Upgrade Urban Living with Dosti Pine, Now Featuring Arrivae’s Elegant Home Interior Designs
                                            </h3>
                                          
                                            <div class="col-md-10 Mobile_div wrarpper_bhk m-auto">
                                                <div class="row w-100">
                                                    <div class="col-12 mt-4">
                                                        <div class="row">
                    
                                                             <div class="col-4 format_type">
                                                                <a   (click)="successmessage('https://arrivae-uat.s3.amazonaws.com/DostiPine-Essentia.pdf')"> Essentia </a>
                                                                <a class="padForFirst" href="http://pano.yfway.com/47546028" target="_blank"> <img src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>
                                                             </div>
                                                             <div class="col-4 format_type  ">
                                                                <a  (click)="successmessage('https://arrivae-uat.s3.amazonaws.com/DostiPine-Premia.pdf')">Premia</a> 
                                                                <a class="pl-2  pl-md-3" href="http://pano.yfway.com/47543383" target="_blank"> <img src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>
                                                             </div>
                                                             <div class="col-4 lastFormat ">
                                                                <a  (click)="successmessage('https://arrivae-uat.s3.amazonaws.com/DostiPine-Luxuria.pdf')">Luxuria</a>  
                                                                <a class="pl-2 pl-md-3" href="http://pano.yfway.com/47544119" target="_blank"> <img src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>
                                                              </div>
                                                              
                                                        </div>
                                                    </div>
            
                                                    
                                                </div>              
                                            </div>
            
                                            <div class="col-12">
                                                <a style="text-decoration: none;" class="my-4 d-flex justify-content-center" href="https://web.arrivae.com/apartment-detail?apartment_name=Dosti%20Pine&developer_name=Dosti%20Group&unid=4006082BHKDSTIP&city=Thane&perPage=6&lifestage=&theme=&vrlink=false&search=" target="_blank">
                                                    <button class="btn-tour px-4 px-lg-5 sivajiFont" >
                                                        EXPLORE NOW
                                                    </button> 
                                                </a>
            
                                                <div class="d-flex justify-content-center">
                                                    <form class="nameAndPhone justify-content-center d-flex flex-lg-row flex-column" [formGroup]= "additionalApartmentDetails">
                                                            <input  formControlName="namee" class="px-4 py-2 mx-lg-3 nameField mb-lg-0 mb-3" placeholder="Please Enter Your Name" type="text"/>
                                                            <input oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"  formControlName="phone_number" class="px-4 py-2 mx-lg-3 nameField mb-lg-0 mb-3" placeholder="Phone Number"  type="number"/>
                                                            <button [disabled] = "additionalApartmentDetails.invalid" class="nameDetailsButton px-5 py-2 mb-lg-0 mb-3" (click)="NameAndPhn(additionalApartmentDetails.value)" >GET A QUOTE</button>
                                                    </form>
                                                </div>
                                             
                                            </div>
                            
                                        </div>
                                       
                                       
                                    </div>
                                </div>
                                
                            </div>
                           
                            <div class="container-fluid py-5 perfect-fit-wrapper Adhiarj px-0 ">
                                <h3 class="pb-3 sivajiFont">
                    
                                    Impeccable Home Interior Designs to Revitalize Spaces
         
                                </h3>
                              
                            
                                <p  id="sivajipan"  class="m-auto sivajipan">
                                    At Arrivae, we breathe life into spaces and stitch your dreams with reality, meticulously crafting homes that resonate with your identity and lifestyle. Our home interior designs exclusively for Dosti Pine is a splendid tapestry of sophistication, innovation, and functionality that harmoniously merge to create an abode where every corner speaks volumes about the inhabitants.
                                    
                                    
                                    
                                   
                                </p>
                           
                              
                            </div>
                            <app-catlouge-apartment></app-catlouge-apartment>
                            <div class="container-fluid images_display">
                                <div class="d-flex w-100 flex-wrap justify-content-space-between">
                                    <div class="image_dis">
                                        <img class="w-100 h-100" src='https://s3.amazonaws.com/arrivae-production/contents/documents/000/100/064/original/data?1698300683' />
                                    </div>
                                    <div class="image_dis ml-2">
                                        <img class="w-100 h-100" src='https://s3.amazonaws.com/arrivae-production/contents/documents/000/100/063/original/data?1698300683'/>
                                    </div>
                                    <div class="image_dis ml-2">
                                        <img class="w-100 h-100" src='https://s3.amazonaws.com/arrivae-production/contents/documents/000/100/062/original/data?1698300682' />
                                    </div>
                                </div>
                            </div>
                            <div class="container-fluid py-5 perfect-fit-wrapper">
                                <h3 class="pb-3 sivajiFont" >
                                    Discover The Luxury Of Italian Design Through Our Themes
            
                                </h3>
                               
                                <ng-container>
                                    <div >
                                        <h4 class="heading-sivaji">
                                            Golden Murano- Luxury Read In Bold And Beautiful Design
            
                                    
                                        </h4>
                                
                                       <div class="sivaji-details w-100 d-flex justify-content-center">
                            
                                        <p>
                                            Perfect for large spaces, Golden Murano is chic and bold. It creates an ambience of luxury with the expert use of wood and shades of white punctuated with gold accents.
            
            
                    
                                        </p>
                            
                                       </div>
                                       
                                        <h4 class="heading-sivaji">
                                            Morandi- A Blend Of Steel And Wood
            
                                    
                                        </h4>
                            
                                        <div class="sivaji-details w-100 d-flex justify-content-center ">
                            
                                            <p>
                                                Morandi showcases a skillful use of steel and wood. Paired with a chic urban color palette, it is simple and sophisticated. 
                                                Hints of texture and smoky green make the designs visually appealing.
                    
                    
                                            </p>
                            
                                        </div>
                                       
                                        <h4 class="heading-sivaji">
                                            Modern Chic- A Unique Rendition Of Art Deco
                                    
                                        </h4>
                                        <div class="sivaji-details w-100 d-flex justify-content-center">
                            
                                            <p>
                                                Our unique take on the swanky Art Deco style, Modern Chic marries glamor and contemporary minimalism with a focus on maximizing storage, making it ideal for urban living.
            
            
                                                
                                                
                    
                                            </p>
                                        
                            
                                        </div>
                                     
                            
                                    </div>
                            
                                  
                            
                                </ng-container>
                            
                                <div class="w-100 d-flex justify-content-center mt-4">
                                    <button  (click)="getQuote()" class="btn-tour px-5 sivajiFont" >
                                        DISCOVER MORE DESIGNS
                                    </button>
                                </div>
                               
                            </div>
                            
                            <div class="w-100 mid_banner sivajiheight"  style="background-image:url('https://s3.amazonaws.com/arrivae-production/contents/documents/000/100/014/original/data?1698238997') ;" ></div>
                            
                            
                            <div class="w-100 d-flex align-items-center plans_do_right py-5">
                                <div class="col-12">
                                    <h3  class="pb-2">Discover the Transformative Journeys of Our Valued Customers
                                    </h3>
                            
                                    
                                    <p  class="m-auto pb-2 sivajiFont" >
                                        Functional and elegant designs, custom furniture, top-quality materials, and a chance to add your unique 
                                        touch to each room— design your dream home with us.
                                        
                    
                                    </p>
                                    <div class="row justify-content-center ">
                                        
                                        <div class=" col-md-6 col-sm-6 h-100  m-4 ">
                                            <div  class="w-100 cursorPointer groungimage1" *ngIf ="!allVideos" (click)="videoPlayIn('https://www.youtube.com/embed/GwWAiNnipcg?autoplay=1&loop=1')">
                                                <div class="playIconStyles">
                                                   <img src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
                                                </div>
                                             </div>
                                             <div class="w-100" *ngIf ="allVideos" >
                                                <iframe  allow="autoplay" class="w-100" width="420"   [src]="current_url" frameborder="0" allowfullscreen>
                                                </iframe>
                                             </div>
                                         </div>
                                        
                                         <div  [ngClass]="roletoSet()> 650?'d-block':'d-none'" class="mx-4 px-2" >
                                        <div class="row mx-1 videoThumbnails  mt-3">
                                                <div class="col-lg-3 col-md-6 col-sm-6 px-2" >
                                                   <a (click)="videoPlayIn('https://www.youtube.com/embed/Sqr8WhpZSmM?autoplay=1&loop=1')" class="cursorPointer">
                                                      <div  class="image_carrier  align-items-end cursorPointer " >
                                                           <img class="w-100" src="../../../assets/images/youtubeVideos/14.png"/>
                                                      </div>
                                                   </a>
                                                   <div class="thumb-content">
                                                        <p class="thumb-heading my-2">The Pandurangis Family</p>
                                                        <p class="thumb-para">“We are very, very happy the way Arrivae has done the job, the timely delivery and as well as the cooperation throughout the project! It was entirely a beautiful journey for us. We never felt that we have been doing such a great change in the house.”</p>
                                                   </div>
                                                </div>
                                                <div class="col-lg-3 col-md-6 col-sm-6 h-100 px-2 ">
                                                   <a (click)="videoPlayIn('https://www.youtube.com/embed/ljhxUFH01Uw?autoplay=1&loop=1')" class="cursorPointer ">
                                                      <div class="image_carrier  align-items-end cursorPointer " >
                                                        <img class="w-100" src="../../../assets/images/thumbfix.png"/>
                                                      </div>
                                                   </a>
                                                   <div class="thumb-content">
                                                     <p class="thumb-heading my-2">The Sabus Family</p>
                                                     <p class="thumb-para">
                                                        “I'm so happy! What I saw in 3D is exactly what I got. So that is the best part! I got regular mails from them.They were connected with me. When there was a small concern, they attended it. Immediately I got a call from the Mumbai office. So I'm very happy with their service. What they promised they'd delivered.”
                                                     </p>
                                                </div>
                                                </div>
                                                <div class="col-lg-3 col-md-6 col-sm-6 h-100 px-2">
                                                   <a (click)="videoPlayIn('https://www.youtube.com/embed/fzd7taoEDL8?autoplay=1&loop=1')" class="cursorPointer ">
                                                      <div class="image_carrier  align-items-end" >
                                                        <img class="w-100" src="../../../assets/images/youtubeVideos/16.png"/>
                                                      </div>
                                                   </a>
                                                   <div class="thumb-content">
                                                     <p class="thumb-heading my-2">The Subramanian Family</p>
                                                     <p class="thumb-para">
                                                        “When I had this house interior project, I did extensive research on multiple agencies, multiple companies. They have a personal interaction with the client, so that's why I chose Arrivae. What I liked about Arrivae was their openness to my ideas and my suggestions and my thoughts, and then they took it from me and then they transformed into something that really worked for us.”
                                                     </p>
                                                  </div>
                                                </div>
                                                <div class="col-lg-3 col-md-6 col-sm-6  h-100 px-2">
                                                   <a (click)="videoPlayIn('https://www.youtube.com/embed/UJeGixHIWRk?autoplay=1&loop=1')" class="cursorPointer ">
                                                      <div class="image_carrier  align-items-end cursorPointer " >
                                                        <img class="w-100" src="../../../assets/images/youtubeVideos/17.png"/>
                                                      </div>
                                                   </a>
                                                   <div class="thumb-content">
                                                     <p class="thumb-heading my-2">The Rishabh Family</p>
                                                     <p class="thumb-para">
                                                        “My experience with Arrivae was fabulous— perfectly blending my ideas, my imagery with their expertise and experience in this particular field to bring out a beautifully decorated home. Throughout the design and development process, Arrivae's attention to detail was impeccable and second to none.”
                                                     </p>
                                                  </div>
                                                </div>
                                        </div>
                                        </div>
                                         <div [ngClass]="roletoSet() < 650?'d-block':'d-none'" class="w-100">
                                            <div  class=" videoThumbnails pt-3">    
                                            <div slickContainer #slickController="slick" [slickConfig]="config">
                                                <ng-container *ngIf="slickController.initialize">
                                               <div slickItem  (click)="mobilevideoPlayIn2('https://www.youtube.com/embed/Sqr8WhpZSmM?autoplay=1&loop=1')">
                                                          <img class="img-width" src="../../../assets/images/youtubeVideos/14.png"/>
                                                  <div  class="thumb-content">
                                                       <p class="thumb-heading my-2">The Pandurangis Family</p>
                                                       <p class="thumb-para">“We are very, very happy the way Arrivae has done the job, the timely delivery and as well as the cooperation throughout the project! It was entirely a beautiful journey for us. We never felt that we have been doing such a great change in the house.”</p>
                                                  </div>
                                               </div>
                                               <div slickItem   (click)="mobilevideoPlayIn2('https://www.youtube.com/embed/ljhxUFH01Uw?autoplay=1&loop=1')" >
                                                       <img class="img-width" src="../../../assets/images/thumbfix.png"/>
                                                  <div class="thumb-content">
                                                    <p class="thumb-heading my-2">The Sabus Family</p>
                                                    <p class="thumb-para">
                                                       “I'm so happy! What I saw in 3D is exactly what I got. So that is the best part! I got regular mails from them.They were connected with me. When there was a small concern, they attended it. Immediately I got a call from Mumbai office. So I'm very happy with their service. What they promised they'd delivered.”
                                                    </p>
                                               </div>
                                               </div>
                                               <div slickItem  (click)="mobilevideoPlayIn2('https://www.youtube.com/embed/fzd7taoEDL8?autoplay=1&loop=1')" >
                                                       <img class="img-width" src="../../../assets/images/youtubeVideos/16.png"/>
                                                  <div class="thumb-content">
                                                    <p class="thumb-heading my-2">The Subramanian Family</p>
                                                    <p class="thumb-para">
                                                       “When I had this house interior project, I did extensive research on multiple agencies, multiple companies. They have a personal interaction with the client, so that's why I chose Arrivae. What I liked about Arrivae was their openness to my ideas and my suggestions and my thoughts, and then they took it from me and then they transformed into something that really worked for us.”
                                                    </p>
                                                 </div>
                                               </div>
                                               <div slickItem   (click)="mobilevideoPlayIn2('https://www.youtube.com/embed/UJeGixHIWRk?autoplay=1&loop=1')" >
                                                       <img class="img-width" src="../../../assets/images/youtubeVideos/17.png"/>
                                                  <div class="thumb-content">
                                                    <p class="thumb-heading my-2">The Rishabh Family</p>
                                                    <p class="thumb-para">
                                                       “My experience with Arrivae was fabulous— perfectly blending my ideas, my imagery with their expertise and experience in this particular field to bring out a beautifully decorated home. 
                                                       Throughout the design and development process, Arrivae's attention to detail was impeccable and second to none.”
                                        
                                                    </p>
                                                 </div>
                                               </div>
                                                </ng-container>
                                            </div>
                        
                                         </div>
                                        </div>
                                        
                                      
                                    </div>
                            
                                    <div class="w-100 d-flex justify-content-center mt-4">
                                        <button (click)="bookConsultationLandingPage()" class="btn-tour px-5 sivajiFont" >
                                            Book A Free Consultation
                                        </button>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div class="root-overflow" *ngIf="BuildingNameGetter() == 'lodha-amara'" >
                                <app-new-header-for-home [parentSubject]="parentSubject"  ></app-new-header-for-home>
                                <div class="container-fluid marathon_banner adhiraj-mobile sivaji-banner" 
                                style="background-image:url('../../assets/images/Amara.webp') ;height: 622px;" >
                                
                                
                                   
                                
                                    <div class="marthon_wrapper sivajiwrapper   w-100 h-100 " >
                                        <div class="w-100" >
                                          
                                        </div>
                                       
                                        
                                        <div class="Mobile_div  d-flex h-100 justify-content-center  m-auto w-100">
                                            <div class="divforDes adhr-mt-top mx-auto">
                                
                                                <h2 class="mt-lg-2 mt-0">DISCOVER A LIFE OF URBAN LUXURY </h2>
                                                <div class="w-100 d-flex justify-content-center">
                                
                                
                                                    <p class="mb-0">
                                                        With Magnificent Flats In Thane,  Mumbai
                
                                                    </p> <br>
                                        
                                                  
                                
                                                </div>
                    
                                                <h3 class="mt-3 font-adhR">
                                                    Upgrade Urban Living with Lodha Amara, Now Featuring Arrivae’s Elegant Home Interior Designs
                                                </h3>
                                              
                                                <div class="col-md-10 Mobile_div wrarpper_bhk m-auto">
                                                    <div class="row w-100 ">
                                                        <div class="col-12 mt-4">
                                                            <div class="row">
                                                                 <div class="col-4 format_type">
                                                                    <a (click)="successmessage('https://arrivae-uat.s3.amazonaws.com/Essentia.pdf')"  > Essentia </a>
                                                                    <a class="padForFirst" href="http://pano.yfway.com/48394667" target="_blank"> <img src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>

                                                                 </div>
                                                                 <div class="col-4 format_type  ">
                                                                    <a  (click)="successmessage('https://arrivae-uat.s3.amazonaws.com/Premia.pdf')"  >Premia</a> 
                                                                    <a class="pl-2  pl-md-3" href="http://pano.yfway.com/48397851" target="_blank"> <img src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>

                                                                 </div>
                                                                 <div class="col-4 lastFormat ">
                                                                    <a (click)="successmessage('https://arrivae-uat.s3.amazonaws.com/Luxuria.pdf')"  >Luxuria</a>  
                                                                    <a class="pl-2 pl-md-3" href="http://pano.yfway.com/48397325" target="_blank"> <img src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>

                                                                  </div>
                                                                  
                                                            </div>
                                                        </div>
                
                                                        
                                                    </div>              
                                                </div>
                
                                                <div class="col-12 ">
                                                    <a style="text-decoration: none;" class="my-4 d-flex justify-content-center" href="https://web.arrivae.com/apartment-detail?apartment_name=Lodha%20Amara&developer_name=Lodha%20&unid=4006072BHKLDAMR&city=Thane&perPage=18&lifestage=&theme=&vrlink=false&search=" target="_blank">
                                                        <button class="btn-tour px-4 px-lg-5 sivajiFont" >
                                                            EXPLORE NOW
                                                        </button> 
                                                    </a>
                
                                                    <div class="d-flex justify-content-center ">
                                                        <form class="nameAndPhone justify-content-center d-flex flex-lg-row flex-column" [formGroup]= "additionalApartmentDetails">
                                                                <input  formControlName="namee" class="px-4 py-2 mx-lg-3 nameField mb-lg-0 mb-3" placeholder="Please Enter Your Name" type="text"/>
                                                                <input oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"  formControlName="phone_number" class="px-4 py-2 mx-lg-3 nameField mb-lg-0 mb-3" placeholder="Phone Number"  type="number"/>
                                                                <button [disabled] = "additionalApartmentDetails.invalid" class="nameDetailsButton px-5 py-2 mb-lg-0 mb-3" (click)="NameAndPhn(additionalApartmentDetails.value)" >GET A QUOTE</button>
                                                        </form>
                                                    </div>
                                                 
                                                </div>
                                
                                            </div>
                                           
                                           
                                        </div>
                                    </div>
                                    
                                </div>
                               
                                <div class="container-fluid py-5 perfect-fit-wrapper Adhiarj px-0 ">
                                    <h3 class="pb-3 sivajiFont">
                        
                                        Impeccable Home Interior Designs to Revitalize Spaces
             
                                    </h3>
                                 
                                    <p  id="sivajipan"  class="m-auto sivajipan">
                                        At Arrivae, we breathe life into spaces and stitch your dreams with reality, meticulously crafting homes that resonate with your identity and lifestyle. Our home interior designs exclusively for Lodha Amara is a splendid tapestry of sophistication, innovation, and functionality that harmoniously merge to create an abode where every corner speaks volumes about the inhabitants.
                                        
                                        
                                        
                                       
                                    </p>
                                 
                                  
                                </div>
                                <app-catlouge-apartment></app-catlouge-apartment>
                                <div class="container-fluid images_display">
                                    <div class="d-flex w-100 flex-wrap justify-content-space-between">
                                        <div class="image_dis">
                                            <img class="w-100 h-100" src='../../assets/images/sky1.png' />
                                        </div>
                                        <div class="image_dis ml-2">
                                            <img class="w-100 h-100" src='../../assets/images/sky3.png'/>
                                        </div>
                                        <div class="image_dis ml-2">
                                            <img class="w-100 h-100" src='../../assets/images/sky4.png' />
                                        </div>
                                    </div>
                                </div>
                                <div class="container-fluid py-5 perfect-fit-wrapper">
                                    <h3 class="pb-3 sivajiFont" >
                                        Discover The Luxury Of Italian Design Through Our Themes
                
                                    </h3>
                                   
                                    <ng-container>
                                        <div >
                                            <h4 class="heading-sivaji">
                                                Golden Murano- Luxury Read In Bold And Beautiful Design
                
                                        
                                            </h4>
                                    
                                           <div class="sivaji-details w-100 d-flex justify-content-center">
                                
                                            <p>
                                                Perfect for large spaces, Golden Murano is chic and bold. It creates an ambience of luxury with the expert use of wood and shades of white punctuated with gold accents.
                
                
                        
                                            </p>
                                
                                           </div>
                                           
                                            <h4 class="heading-sivaji">
                                                Morandi- A Blend Of Steel And Wood
                
                                        
                                            </h4>
                                
                                            <div class="sivaji-details w-100 d-flex justify-content-center ">
                                
                                                <p>
                                                    Morandi showcases a skillful use of steel and wood. Paired with a chic urban color palette, it is simple and sophisticated. 
                                                    Hints of texture and smoky green make the designs visually appealing.
                        
                        
                                                </p>
                                
                                            </div>
                                           
                                            <h4 class="heading-sivaji">
                                                Modern Chic- A Unique Rendition Of Art Deco
                                        
                                            </h4>
                                            <div class="sivaji-details w-100 d-flex justify-content-center">
                                
                                                <p>
                                                    Our unique take on the swanky Art Deco style, Modern Chic marries glamor and contemporary minimalism with a focus on maximizing storage, making it ideal for urban living.
                
                
                                                    
                                                    
                        
                                                </p>
                                            
                                
                                            </div>
                                         
                                
                                        </div>
                                
                                      
                                
                                    </ng-container>
                                
                                    <div class="w-100 d-flex justify-content-center mt-4">
                                        <button  (click)="getQuote()" class="btn-tour px-5 sivajiFont" >
                                            DISCOVER MORE DESIGNS
                                        </button>
                                    </div>
                                   
                                </div>
                                
                                <div class="w-100 mid_banner sivajiheight"  style="background-image:url('../../assets/images/skymidbanner.png') ;" ></div>
                                
                                
                                <div class="w-100 d-flex align-items-center plans_do_right py-5">
                                    <div class="col-12">
                                        <h3  class="pb-2">Discover the Transformative Journeys of Our Valued Customers
                                        </h3>
                                
                                        
                                        <p  class="m-auto pb-2 sivajiFont" >
                                            Functional and elegant designs, custom furniture, top-quality materials, and a chance to add your unique 
                                            touch to each room— design your dream home with us.
                                            
                        
                                        </p>
                                        <div class="row justify-content-center ">
                                            
                                            <div class=" col-md-6 col-sm-6 h-100  m-4 ">
                                                <div  class="w-100 cursorPointer groungimage1" *ngIf ="!allVideos" (click)="videoPlayIn('https://www.youtube.com/embed/GwWAiNnipcg?autoplay=1&loop=1')">
                                                    <div class="playIconStyles">
                                                       <img src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
                                                    </div>
                                                 </div>
                                                 <div class="w-100" *ngIf ="allVideos" >
                                                    <iframe  allow="autoplay" class="w-100" width="420"   [src]="current_url" frameborder="0" allowfullscreen>
                                                    </iframe>
                                                 </div>
                                             </div>
                                            
                                             <div  [ngClass]="roletoSet()> 650?'d-block':'d-none'" class="mx-4 px-2" >
                                            <div class="row mx-1 videoThumbnails  mt-3">
                                                    <div class="col-lg-3 col-md-6 col-sm-6 px-2" >
                                                       <a (click)="videoPlayIn('https://www.youtube.com/embed/Sqr8WhpZSmM?autoplay=1&loop=1')" class="cursorPointer">
                                                          <div  class="image_carrier  align-items-end cursorPointer " >
                                                               <img class="w-100" src="../../../assets/images/youtubeVideos/14.png"/>
                                                          </div>
                                                       </a>
                                                       <div class="thumb-content">
                                                            <p class="thumb-heading my-2">The Pandurangis Family</p>
                                                            <p class="thumb-para">“We are very, very happy the way Arrivae has done the job, the timely delivery and as well as the cooperation throughout the project! It was entirely a beautiful journey for us. We never felt that we have been doing such a great change in the house.”</p>
                                                       </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-6 col-sm-6 h-100 px-2 ">
                                                       <a (click)="videoPlayIn('https://www.youtube.com/embed/ljhxUFH01Uw?autoplay=1&loop=1')" class="cursorPointer ">
                                                          <div class="image_carrier  align-items-end cursorPointer " >
                                                            <img class="w-100" src="../../../assets/images/thumbfix.png"/>
                                                          </div>
                                                       </a>
                                                       <div class="thumb-content">
                                                         <p class="thumb-heading my-2">The Sabus Family</p>
                                                         <p class="thumb-para">
                                                            “I'm so happy! What I saw in 3D is exactly what I got. So that is the best part! I got regular mails from them.They were connected with me. When there was a small concern, they attended it. Immediately I got a call from the Mumbai office. So I'm very happy with their service. What they promised they'd delivered.”
                                                         </p>
                                                    </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-6 col-sm-6 h-100 px-2">
                                                       <a (click)="videoPlayIn('https://www.youtube.com/embed/fzd7taoEDL8?autoplay=1&loop=1')" class="cursorPointer ">
                                                          <div class="image_carrier  align-items-end" >
                                                            <img class="w-100" src="../../../assets/images/youtubeVideos/16.png"/>
                                                          </div>
                                                       </a>
                                                       <div class="thumb-content">
                                                         <p class="thumb-heading my-2">The Subramanian Family</p>
                                                         <p class="thumb-para">
                                                            “When I had this house interior project, I did extensive research on multiple agencies, multiple companies. They have a personal interaction with the client, so that's why I chose Arrivae. What I liked about Arrivae was their openness to my ideas and my suggestions and my thoughts, and then they took it from me and then they transformed into something that really worked for us.”
                                                         </p>
                                                      </div>
                                                    </div>
                                                    <div class="col-lg-3 col-md-6 col-sm-6  h-100 px-2">
                                                       <a (click)="videoPlayIn('https://www.youtube.com/embed/UJeGixHIWRk?autoplay=1&loop=1')" class="cursorPointer ">
                                                          <div class="image_carrier  align-items-end cursorPointer " >
                                                            <img class="w-100" src="../../../assets/images/youtubeVideos/17.png"/>
                                                          </div>
                                                       </a>
                                                       <div class="thumb-content">
                                                         <p class="thumb-heading my-2">The Rishabh Family</p>
                                                         <p class="thumb-para">
                                                            “My experience with Arrivae was fabulous— perfectly blending my ideas, my imagery with their expertise and experience in this particular field to bring out a beautifully decorated home. Throughout the design and development process, Arrivae's attention to detail was impeccable and second to none.”
                                                         </p>
                                                      </div>
                                                    </div>
                                            </div>
                                            </div>
                                             <div [ngClass]="roletoSet() < 650?'d-block':'d-none'" class="w-100">
                                                <div  class=" videoThumbnails pt-3">    
                                                <div slickContainer #slickController="slick" [slickConfig]="config">
                                                    <ng-container *ngIf="slickController.initialize">
                                                   <div slickItem  (click)="mobilevideoPlayIn2('https://www.youtube.com/embed/Sqr8WhpZSmM?autoplay=1&loop=1')">
                                                              <img class="img-width" src="../../../assets/images/youtubeVideos/14.png"/>
                                                      <div  class="thumb-content">
                                                           <p class="thumb-heading my-2">The Pandurangis Family</p>
                                                           <p class="thumb-para">“We are very, very happy the way Arrivae has done the job, the timely delivery and as well as the cooperation throughout the project! It was entirely a beautiful journey for us. We never felt that we have been doing such a great change in the house.”</p>
                                                      </div>
                                                   </div>
                                                   <div slickItem   (click)="mobilevideoPlayIn2('https://www.youtube.com/embed/ljhxUFH01Uw?autoplay=1&loop=1')" >
                                                           <img class="img-width" src="../../../assets/images/thumbfix.png"/>
                                                      <div class="thumb-content">
                                                        <p class="thumb-heading my-2">The Sabus Family</p>
                                                        <p class="thumb-para">
                                                           “I'm so happy! What I saw in 3D is exactly what I got. So that is the best part! I got regular mails from them.They were connected with me. When there was a small concern, they attended it. Immediately I got a call from Mumbai office. So I'm very happy with their service. What they promised they'd delivered.”
                                                        </p>
                                                   </div>
                                                   </div>
                                                   <div slickItem  (click)="mobilevideoPlayIn2('https://www.youtube.com/embed/fzd7taoEDL8?autoplay=1&loop=1')" >
                                                           <img class="img-width" src="../../../assets/images/youtubeVideos/16.png"/>
                                                      <div class="thumb-content">
                                                        <p class="thumb-heading my-2">The Subramanian Family</p>
                                                        <p class="thumb-para">
                                                           “When I had this house interior project, I did extensive research on multiple agencies, multiple companies. They have a personal interaction with the client, so that's why I chose Arrivae. What I liked about Arrivae was their openness to my ideas and my suggestions and my thoughts, and then they took it from me and then they transformed into something that really worked for us.”
                                                        </p>
                                                     </div>
                                                   </div>
                                                   <div slickItem   (click)="mobilevideoPlayIn2('https://www.youtube.com/embed/UJeGixHIWRk?autoplay=1&loop=1')" >
                                                           <img class="img-width" src="../../../assets/images/youtubeVideos/17.png"/>
                                                      <div class="thumb-content">
                                                        <p class="thumb-heading my-2">The Rishabh Family</p>
                                                        <p class="thumb-para">
                                                           “My experience with Arrivae was fabulous— perfectly blending my ideas, my imagery with their expertise and experience in this particular field to bring out a beautifully decorated home. 
                                                           Throughout the design and development process, Arrivae's attention to detail was impeccable and second to none.”
                                            
                                                        </p>
                                                     </div>
                                                   </div>
                                                    </ng-container>
                                                </div>
                            
                                             </div>
                                            </div>
                                          
                                        </div>
                                
                                        <div class="w-100 d-flex justify-content-center mt-4">
                                            <button (click)="bookConsultationLandingPage()" class="btn-tour px-5 sivajiFont" >
                                                Book A Free Consultation
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div class="root-overflow" *ngIf="BuildingNameGetter() == 'Birla-Alokya'" >
                                    <app-new-header-for-home [parentSubject]="parentSubject"  ></app-new-header-for-home>
                                    <div class="container-fluid marathon_banner adhiraj-mobile sivaji-banner" 
                                    style="background-image:url('../../assets/images/Birla Alokya 1.jpg') ;height: 622px;" >
                                    
                                    
                                       
                                    
                                        <div class="marthon_wrapper sivajiwrapper   w-100 h-100 " >
                                            <div class="w-100" >
                                              
                                            </div>
                                           
                                            
                                            <div class="Mobile_div  d-flex h-100 justify-content-center m-auto pt-5 w-100">
                                                <div class="divforDes adhr-mt-top mx-auto">
                                    
                                                    <h2 class="mt-lg-2 mt-0 textStylingalokya">DISCOVER A LIFE OF URBAN LUXURY </h2>
                                                    <div class="w-100 d-flex justify-content-center">
                                    
                                    
                                                        <p class="mb-0 textStylingalokya">
                                                            With Magnificent Flats In Whitefield, Bengaluru
                    
                                                        </p> <br>
                                            
                                                      
                                    
                                                    </div>
                        
                                                    <h3 class="mt-3 font-adhR textStylingalokya">
                                                        Upgrade Urban Living with Birla Alokya , Now Featuring Arrivae’s Elegant Home Interior Designs
                                                    </h3>
                                                  
                                                    <div class="col-md-10 Mobile_div wrarpper_bhk m-auto">
                                                        <div class="row w-100">
                                                            <div class="col-12 d-none mt-4">
                                                                <div class="row">
                                                                     <div class="col-4 format_type">
                                                                        <a   (click)="successmessage('https://arrivae-uat.s3.amazonaws.com/Runwal-Essentia.pdf')"> Essentia </a>
                                                                        <a class="padForFirst" href="http://pano.yfway.com/47546028" target="_blank"> <img src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>
                                                                     </div>
                                                                     <div class="col-4 format_type  ">
                                                                        <a  (click)="successmessage('https://arrivae-uat.s3.amazonaws.com/Ruwal-Premia.pdf')">Premia</a> 
                                                                        <a class="pl-2  pl-md-3" href="http://pano.yfway.com/47543383" target="_blank"> <img src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>
                                                                    </div>
                                                                     <div class="col-4 lastFormat ">
                                                                        <a  (click)="successmessage('https://arrivae-uat.s3.amazonaws.com/Runwal-Luxuria.pdf')">Luxuria</a>  
                                                                        <a class="pl-2 pl-md-3" href="http://pano.yfway.com/47544119" target="_blank"> <img src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>
                                                                    </div>
                                                                     
                                                                </div>
                                                            </div>
                    
                                                            
                                                        </div>              
                                                    </div>
                    
                                                    <div class="col-12">
                                                        <a style="text-decoration: none;" class="my-4 d-flex justify-content-center" href="
                                                        https://web.arrivae.com/apartment-detail?apartment_name=Birla%20Alokya%20Whitefield&developer_name=Birla&unid=560067SWT01BLR1&city=bangalore&perPage=6&lifestage=&theme=&vrlink=false&search=birla" target="_blank">
                                                            <button class="btn-tour px-4 px-lg-5 sivajiFont" >
                                                                EXPLORE NOW
                                                            </button> 
                                                        </a>
                    
                                                        <div class="d-flex justify-content-center">
                                                            <form class="nameAndPhone justify-content-center d-flex flex-lg-row flex-column" [formGroup]= "additionalApartmentDetails">
                                                                    <input  formControlName="namee" class="px-4 py-2 mx-lg-3 nameField mb-lg-0 mb-3" placeholder="Please Enter Your Name" type="text"/>
                                                                    <input oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"  formControlName="phone_number" class="px-4 py-2 mx-lg-3 nameField mb-lg-0 mb-3" placeholder="Phone Number"  type="number"/>
                                                                    <button [disabled] = "additionalApartmentDetails.invalid" class="nameDetailsButton px-5 py-2 mb-lg-0 mb-3" (click)="NameAndPhn(additionalApartmentDetails.value)" >GET A QUOTE</button>
                                                            </form>
                                                        </div>
                                                     
                                                    </div>
                                    
                                                </div>
                                               
                                               
                                            </div>
                                        </div>
                                        
                                    </div>
                                   
                                    <div class="container-fluid py-5 perfect-fit-wrapper Adhiarj px-0 ">
                                        <h3 class="pb-3 sivajiFont">
                            
                                            Impeccable Home Interior Designs to Revitalize Spaces
                 
                                        </h3>
                                      
                                     
                                        <p  id="sivajipan"  class="m-auto sivajipan">
                                            At Arrivae, we breathe life into spaces and stitch your dreams with reality, meticulously crafting homes that resonate with your identity and lifestyle. Our home interior designs exclusively for Birla Alokya  is a splendid tapestry of sophistication, innovation, and functionality that harmoniously merge to create an abode where every corner speaks volumes about the inhabitants.
                                            
                                            
                                            
                                           
                                        </p>
                                     
                                      
                                    </div>
                                    <app-catlouge-apartment></app-catlouge-apartment>
                                    <div class="container-fluid images_display">
                                        <div class="d-flex w-100 flex-wrap justify-content-space-between">
                                            <div class="image_dis">
                                                <img class="w-100 h-100" src='https://s3.amazonaws.com/arrivae-production/contents/documents/000/044/743/original/data?1629376524' />
                                            </div>
                                            <div class="image_dis ml-2">
                                                <img class="w-100 h-100" src='https://s3.amazonaws.com/arrivae-production/contents/documents/000/044/734/original/data?1629376249'/>
                                            </div>
                                            <div class="image_dis ml-2">
                                                <img class="w-100 h-100" src='https://s3.amazonaws.com/arrivae-production/contents/documents/000/044/731/original/data?1629376247' />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="container-fluid py-5 perfect-fit-wrapper">
                                        <h3 class="pb-3 sivajiFont" >
                                            Discover The Luxury Of Italian Design Through Our Themes
                    
                                        </h3>
                                       
                                        <ng-container>
                                            <div >
                                                <h4 class="heading-sivaji">
                                                    Golden Murano- Luxury Read In Bold And Beautiful Design
                    
                                            
                                                </h4>
                                        
                                               <div class="sivaji-details w-100 d-flex justify-content-center">
                                    
                                                <p>
                                                    Perfect for large spaces, Golden Murano is chic and bold. It creates an ambience of luxury with the expert use of wood and shades of white punctuated with gold accents.
                    
                    
                            
                                                </p>
                                    
                                               </div>
                                               
                                                <h4 class="heading-sivaji">
                                                    Morandi- A Blend Of Steel And Wood
                    
                                            
                                                </h4>
                                    
                                                <div class="sivaji-details w-100 d-flex justify-content-center ">
                                    
                                                    <p>
                                                        Morandi showcases a skillful use of steel and wood. Paired with a chic urban color palette, it is simple and sophisticated. 
                                                        Hints of texture and smoky green make the designs visually appealing.
                            
                            
                                                    </p>
                                    
                                                </div>
                                               
                                                <h4 class="heading-sivaji">
                                                    Modern Chic- A Unique Rendition Of Art Deco
                                            
                                                </h4>
                                                <div class="sivaji-details w-100 d-flex justify-content-center">
                                    
                                                    <p>
                                                        Our unique take on the swanky Art Deco style, Modern Chic marries glamor and contemporary minimalism with a focus on maximizing storage, making it ideal for urban living.
                    
                    
                                                        
                                                        
                            
                                                    </p>
                                                
                                    
                                                </div>
                                             
                                    
                                            </div>
                                    
                                          
                                    
                                        </ng-container>
                                    
                                        <div class="w-100 d-flex justify-content-center mt-4">
                                            <button  (click)="getQuote()" class="btn-tour px-5 sivajiFont" >
                                                DISCOVER MORE DESIGNS
                                            </button>
                                        </div>
                                       
                                    </div>
                                    
                                    <div class="w-100 mid_banner sivajiheight"  style="background-image:url('https://s3.amazonaws.com/arrivae-production/contents/documents/000/044/732/original/data?1629376248') ;" ></div>
                                    
                                    
                                    <div class="w-100 d-flex align-items-center plans_do_right py-5">
                                        <div class="col-12">
                                            <h3  class="pb-2">Discover the Transformative Journeys of Our Valued Customers
                                            </h3>
                                    
                                            
                                            <p  class="m-auto pb-2 sivajiFont" >
                                                Functional and elegant designs, custom furniture, top-quality materials, and a chance to add your unique 
                                                touch to each room— design your dream home with us.
                                                
                            
                                            </p>
                                            <div class="row justify-content-center ">
                                                
                                                <div class=" col-md-6 col-sm-6 h-100  m-4 ">
                                                    <div  class="w-100 cursorPointer groungimage1" *ngIf ="!allVideos" (click)="videoPlayIn('https://www.youtube.com/embed/GwWAiNnipcg?autoplay=1&loop=1')">
                                                        <div class="playIconStyles">
                                                           <img src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
                                                        </div>
                                                     </div>
                                                     <div class="w-100" *ngIf ="allVideos" >
                                                        <iframe  allow="autoplay" class="w-100" width="420"   [src]="current_url" frameborder="0" allowfullscreen>
                                                        </iframe>
                                                     </div>
                                                 </div>
                                                
                                                 <div  [ngClass]="roletoSet()> 650?'d-block':'d-none'" class="mx-4 px-2" >
                                                <div class="row mx-1 videoThumbnails  mt-3">
                                                        <div class="col-lg-3 col-md-6 col-sm-6 px-2" >
                                                           <a (click)="videoPlayIn('https://www.youtube.com/embed/Sqr8WhpZSmM?autoplay=1&loop=1')" class="cursorPointer">
                                                              <div  class="image_carrier  align-items-end cursorPointer " >
                                                                   <img class="w-100" src="../../../assets/images/youtubeVideos/14.png"/>
                                                              </div>
                                                           </a>
                                                           <div class="thumb-content">
                                                                <p class="thumb-heading my-2">The Pandurangis Family</p>
                                                                <p class="thumb-para">“We are very, very happy the way Arrivae has done the job, the timely delivery and as well as the cooperation throughout the project! It was entirely a beautiful journey for us. We never felt that we have been doing such a great change in the house.”</p>
                                                           </div>
                                                        </div>
                                                        <div class="col-lg-3 col-md-6 col-sm-6 h-100 px-2 ">
                                                           <a (click)="videoPlayIn('https://www.youtube.com/embed/ljhxUFH01Uw?autoplay=1&loop=1')" class="cursorPointer ">
                                                              <div class="image_carrier  align-items-end cursorPointer " >
                                                                <img class="w-100" src="../../../assets/images/youtubeVideos/image 23.png"/>
                                                              </div>
                                                           </a>
                                                           <div class="thumb-content">
                                                             <p class="thumb-heading my-2">The Sabus Family</p>
                                                             <p class="thumb-para">
                                                                “I'm so happy! What I saw in 3D is exactly what I got. So that is the best part! I got regular mails from them.They were connected with me. When there was a small concern, they attended it. Immediately I got a call from the Mumbai office. So I'm very happy with their service. What they promised they'd delivered.”
                                                             </p>
                                                        </div>
                                                        </div>
                                                        <div class="col-lg-3 col-md-6 col-sm-6 h-100 px-2">
                                                           <a (click)="videoPlayIn('https://www.youtube.com/embed/fzd7taoEDL8?autoplay=1&loop=1')" class="cursorPointer ">
                                                              <div class="image_carrier  align-items-end" >
                                                                <img class="w-100" src="../../../assets/images/youtubeVideos/16.png"/>
                                                              </div>
                                                           </a>
                                                           <div class="thumb-content">
                                                             <p class="thumb-heading my-2">The Subramanian Family</p>
                                                             <p class="thumb-para">
                                                                “When I had this house interior project, I did extensive research on multiple agencies, multiple companies. They have a personal interaction with the client, so that's why I chose Arrivae. What I liked about Arrivae was their openness to my ideas and my suggestions and my thoughts, and then they took it from me and then they transformed into something that really worked for us.”
                                                             </p>
                                                          </div>
                                                        </div>
                                                        <div class="col-lg-3 col-md-6 col-sm-6  h-100 px-2">
                                                           <a (click)="videoPlayIn('https://www.youtube.com/embed/UJeGixHIWRk?autoplay=1&loop=1')" class="cursorPointer ">
                                                              <div class="image_carrier  align-items-end cursorPointer " >
                                                                <img class="w-100" src="../../../assets/images/youtubeVideos/17.png"/>
                                                              </div>
                                                           </a>
                                                           <div class="thumb-content">
                                                             <p class="thumb-heading my-2">The Rishabh Family</p>
                                                             <p class="thumb-para">
                                                                “My experience with Arrivae was fabulous— perfectly blending my ideas, my imagery with their expertise and experience in this particular field to bring out a beautifully decorated home. Throughout the design and development process, Arrivae's attention to detail was impeccable and second to none.”
                                                             </p>
                                                          </div>
                                                        </div>
                                                </div>
                                                </div>
                                                 <div [ngClass]="roletoSet() < 650?'d-block':'d-none'" class="w-100">
                                                    <div  class=" videoThumbnails pt-3">    
                                                    <div slickContainer #slickController="slick" [slickConfig]="config">
                                                        <ng-container *ngIf="slickController.initialize">
                                                       <div slickItem  (click)="mobilevideoPlayIn2('https://www.youtube.com/embed/Sqr8WhpZSmM?autoplay=1&loop=1')">
                                                                  <img class="img-width" src="../../../assets/images/youtubeVideos/14.png"/>
                                                          <div  class="thumb-content">
                                                               <p class="thumb-heading my-2">The Pandurangis Family</p>
                                                               <p class="thumb-para">“We are very, very happy the way Arrivae has done the job, the timely delivery and as well as the cooperation throughout the project! It was entirely a beautiful journey for us. We never felt that we have been doing such a great change in the house.”</p>
                                                          </div>
                                                       </div>
                                                       <div slickItem   (click)="mobilevideoPlayIn2('https://www.youtube.com/embed/ljhxUFH01Uw?autoplay=1&loop=1')" >
                                                               <img class="img-width" src="../../../assets/images/youtubeVideos/image 23.png"/>
                                                          <div class="thumb-content">
                                                            <p class="thumb-heading my-2">The Sabus Family</p>
                                                            <p class="thumb-para">
                                                               “I'm so happy! What I saw in 3D is exactly what I got. So that is the best part! I got regular mails from them.They were connected with me. When there was a small concern, they attended it. Immediately I got a call from Mumbai office. So I'm very happy with their service. What they promised they'd delivered.”
                                                            </p>
                                                       </div>
                                                       </div>
                                                       <div slickItem  (click)="mobilevideoPlayIn2('https://www.youtube.com/embed/fzd7taoEDL8?autoplay=1&loop=1')" >
                                                               <img class="img-width" src="../../../assets/images/youtubeVideos/16.png"/>
                                                          <div class="thumb-content">
                                                            <p class="thumb-heading my-2">The Subramanian Family</p>
                                                            <p class="thumb-para">
                                                               “When I had this house interior project, I did extensive research on multiple agencies, multiple companies. They have a personal interaction with the client, so that's why I chose Arrivae. What I liked about Arrivae was their openness to my ideas and my suggestions and my thoughts, and then they took it from me and then they transformed into something that really worked for us.”
                                                            </p>
                                                         </div>
                                                       </div>
                                                       <div slickItem   (click)="mobilevideoPlayIn2('https://www.youtube.com/embed/UJeGixHIWRk?autoplay=1&loop=1')" >
                                                               <img class="img-width" src="../../../assets/images/youtubeVideos/17.png"/>
                                                          <div class="thumb-content">
                                                            <p class="thumb-heading my-2">The Rishabh Family</p>
                                                            <p class="thumb-para">
                                                               “My experience with Arrivae was fabulous— perfectly blending my ideas, my imagery with their expertise and experience in this particular field to bring out a beautifully decorated home. 
                                                               Throughout the design and development process, Arrivae's attention to detail was impeccable and second to none.”
                                                
                                                            </p>
                                                         </div>
                                                       </div>
                                                        </ng-container>
                                                    </div>
                                
                                                 </div>
                                                </div>
                                            </div>
                                    
                                            <div class="w-100 d-flex justify-content-center mt-4">
                                                <button (click)="bookConsultationLandingPage()" class="btn-tour px-5 sivajiFont" >
                                                    Book A Free Consultation
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div class="root-overflow" *ngIf="BuildingNameGetter() == 'Rustomjee-Summit'" >
                                        <app-new-header-for-home [parentSubject]="parentSubject"  ></app-new-header-for-home>
                                        <div class="container-fluid marathon_banner adhiraj-mobile sivaji-banner" 
                                        style="background-image:url('../../assets/images/Rustomjee-Summit.jpg') ;height: 622px;" >
                                        
                                        
                                           
                                        
                                            <div class="marthon_wrapper sivajiwrapper   w-100 h-100 " >
                                                <div class="w-100" >
                                                  
                                                </div>
                                               
                                                
                                                <div class="Mobile_div  d-flex h-100 justify-content-center m-auto pt-2 w-100">
                                                    <div class="divforDes adhr-mt-top mx-auto">
                                        
                                                        <h2 class="mt-lg-2 mt-0 textStylingalokya">DISCOVER A LIFE OF URBAN LUXURY </h2>
                                                        <div class="w-100 d-flex justify-content-center">
                                        
                                        
                                                            <p class="mb-0 textStylingalokya">
                                                                With Magnificent Flats In Borivali East, Mumbai
                        
                                                            </p> <br>
                                                
                                                          
                                        
                                                        </div>
                            
                                                        <h3 class="mt-3 font-adhR textStylingalokya">
                                                            Upgrade Urban Living with Rustomjee Summit , Now Featuring Arrivae’s Elegant Home Interior Designs
                                                        </h3>
                                                      
                                                        <div class="col-md-10 Mobile_div wrarpper_bhk m-auto">
                                                            <div class="row w-100">
                                                                <div class="col-12  mt-2">
                                                                    <div class="row">
                                                                         <div class="col-4 format_type">
                                                                            <a  a (click)="successmessage('https://arrivae-production.s3.amazonaws.com/Rustomjee%20Summit.pdf')"> Essentia </a>
                                                                            <a class="padForFirst"   > <img src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>
                                                                         </div>
                                                                         <div class="col-4 format_type  ">
                                                                            <a a (click)="successmessage('https://arrivae-production.s3.amazonaws.com/Rustomjee%20Summit.pdf')"> Premia</a> 
                                                                            <a class="pl-2  pl-md-3" > <img src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>
                                                                        </div>
                                                                         <div class="col-4 lastFormat ">
                                                                            <a a (click)="successmessage('https://arrivae-production.s3.amazonaws.com/Rustomjee%20Summit.pdf')">Luxuria</a>  
                                                                            <a class="pl-2 pl-md-3"  > <img src="../../assets/images/apartment-detail-images/VR apartment.png"alt=""> </a>
                                                                        </div>
                                                                         
                                                                    </div>
                                                                </div>
                        
                                                                
                                                            </div>              
                                                        </div>
                        
                                                        <div class="col-12">
                                                            <a style="text-decoration: none;" class="my-4 d-flex justify-content-center" href="
https://web.arrivae.com/apartment-detail?apartment_name=Rustomjee%20Summit&developer_name=Rustomjee&unid=4000662BHKRSTMS&city=mumbai&perPage=6&lifestage=&theme=&vrlink=false&search=Rustomjee" target="_blank">                                                                <button class="btn-tour px-4 px-lg-5 sivajiFont" >
                                                                    EXPLORE NOW
                                                                </button> 
                                                            </a>
                        
                                                            <div class="d-flex justify-content-center">
                                                                <form class="nameAndPhone justify-content-center d-flex flex-lg-row flex-column" [formGroup]= "additionalApartmentDetails">
                                                                        <input  formControlName="namee" class="px-4 py-2 mx-lg-3 nameField mb-lg-0 mb-3" placeholder="Please Enter Your Name" type="text"/>
                                                                        <input oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);"  formControlName="phone_number" class="px-4 py-2 mx-lg-3 nameField mb-lg-0 mb-3" placeholder="Phone Number"  type="number"/>
                                                                        <button [disabled] = "additionalApartmentDetails.invalid" class="nameDetailsButton px-5 py-2 mb-lg-0 mb-3" (click)="NameAndPhn(additionalApartmentDetails.value)" >GET A QUOTE</button>
                                                                </form>
                                                            </div>
                                                         
                                                        </div>
                                        
                                                    </div>
                                                   
                                                   
                                                </div>
                                            </div>
                                            
                                        </div>
                                       
                                        <div class="container-fluid py-5 perfect-fit-wrapper Adhiarj px-0 ">
                                            <h3 class="pb-3 sivajiFont">
                                
                                                Impeccable Home Interior Designs to Revitalize Spaces
                     
                                            </h3>
                                          
                                            <p  id="sivajipan"  class="m-auto sivajipan">
                                                At Arrivae, we breathe life into spaces and stitch your dreams with reality, meticulously crafting homes that resonate with your identity and lifestyle. Our home interior designs exclusively for Rustomjee Summit  is a splendid tapestry of sophistication, innovation, and functionality that harmoniously merge to create an abode where every corner speaks volumes about the inhabitants.
                                                
                                                
                                                
                                               
                                            </p>
                                          
                                        </div>
                                        <app-catlouge-apartment></app-catlouge-apartment>
                                        <div class="container-fluid images_display">
                                            <div class="d-flex w-100 flex-wrap justify-content-space-between">
                                                <div class="image_dis">
                                                    <img class="w-100 h-100" src='../../assets/images/Rust1.jpg' />
                                                </div>
                                                <div class="image_dis ml-2">
                                                    <img class="w-100 h-100" src='../../assets/images/Rust2.jpg'/>
                                                </div>
                                                <div class="image_dis ml-2">
                                                    <img class="w-100 h-100" src='../../assets/images/Rust3.jpg' />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container-fluid py-5 perfect-fit-wrapper">
                                            <h3 class="pb-3 sivajiFont" >
                                                Discover The Luxury Of Italian Design Through Our Themes
                        
                                            </h3>
                                           
                                            <ng-container>
                                                <div >
                                                    <h4 class="heading-sivaji">
                                                        Golden Murano- Luxury Read In Bold And Beautiful Design
                        
                                                
                                                    </h4>
                                            
                                                   <div class="sivaji-details w-100 d-flex justify-content-center">
                                        
                                                    <p>
                                                        Perfect for large spaces, Golden Murano is chic and bold. It creates an ambience of luxury with the expert use of wood and shades of white punctuated with gold accents.
                        
                        
                                
                                                    </p>
                                        
                                                   </div>
                                                   
                                                    <h4 class="heading-sivaji">
                                                        Morandi- A Blend Of Steel And Wood
                        
                                                
                                                    </h4>
                                        
                                                    <div class="sivaji-details w-100 d-flex justify-content-center ">
                                        
                                                        <p>
                                                            Morandi showcases a skillful use of steel and wood. Paired with a chic urban color palette, it is simple and sophisticated. 
                                                            Hints of texture and smoky green make the designs visually appealing.
                                
                                
                                                        </p>
                                        
                                                    </div>
                                                   
                                                    <h4 class="heading-sivaji">
                                                        Modern Chic- A Unique Rendition Of Art Deco
                                                
                                                    </h4>
                                                    <div class="sivaji-details w-100 d-flex justify-content-center">
                                        
                                                        <p>
                                                            Our unique take on the swanky Art Deco style, Modern Chic marries glamor and contemporary minimalism with a focus on maximizing storage, making it ideal for urban living.
                        
                        
                                                            
                                                            
                                
                                                        </p>
                                                    
                                        
                                                    </div>
                                                 
                                        
                                                </div>
                                        
                                              
                                        
                                            </ng-container>
                                        
                                            <div class="w-100 d-flex justify-content-center mt-4">
                                                <button  (click)="getQuote()" class="btn-tour px-5 sivajiFont" >
                                                    DISCOVER MORE DESIGNS
                                                </button>
                                            </div>
                                           
                                        </div>
                                        
                                        <div class="w-100 mid_banner sivajiheight"  style="background-image:url('https://s3.amazonaws.com/arrivae-production/contents/documents/000/095/385/original/data?1692169806') ;" ></div>
                                        
                                        
                                        <div class="w-100 d-flex align-items-center plans_do_right py-5">
                                            <div class="col-12">
                                                <h3  class="pb-2">Discover the Transformative Journeys of Our Valued Customers
                                                </h3>
                                        
                                                
                                                <p  class="m-auto pb-2 sivajiFont" >
                                                    Functional and elegant designs, custom furniture, top-quality materials, and a chance to add your unique 
                                                    touch to each room— design your dream home with us.
                                                    
                                
                                                </p>
                                                <div class="row justify-content-center ">
                                                    
                                                    <div class=" col-md-6 col-sm-6 h-100  m-4 ">
                                                        <div  class="w-100 cursorPointer groungimage1" *ngIf ="!allVideos" (click)="videoPlayIn('https://www.youtube.com/embed/GwWAiNnipcg?autoplay=1&loop=1')">
                                                            <div class="playIconStyles">
                                                               <img src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
                                                            </div>
                                                         </div>
                                                         <div class="w-100" *ngIf ="allVideos" >
                                                            <iframe  allow="autoplay" class="w-100" width="420"   [src]="current_url" frameborder="0" allowfullscreen>
                                                            </iframe>
                                                         </div>
                                                     </div>
                                                    
                                                     <div  [ngClass]="roletoSet()> 650?'d-block':'d-none'" class="mx-4 px-2" >
                                                    <div class="row mx-1 videoThumbnails  mt-3">
                                                            <div class="col-lg-3 col-md-6 col-sm-6 px-2" >
                                                               <a (click)="videoPlayIn('https://www.youtube.com/embed/Sqr8WhpZSmM?autoplay=1&loop=1')" class="cursorPointer">
                                                                  <div  class="image_carrier  align-items-end cursorPointer " >
                                                                       <img class="w-100" src="../../../assets/images/youtubeVideos/14.png"/>
                                                                  </div>
                                                               </a>
                                                               <div class="thumb-content">
                                                                    <p class="thumb-heading my-2">The Pandurangis Family</p>
                                                                    <p class="thumb-para">“We are very, very happy the way Arrivae has done the job, the timely delivery and as well as the cooperation throughout the project! It was entirely a beautiful journey for us. We never felt that we have been doing such a great change in the house.”</p>
                                                               </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 h-100 px-2 ">
                                                               <a (click)="videoPlayIn('https://www.youtube.com/embed/ljhxUFH01Uw?autoplay=1&loop=1')" class="cursorPointer ">
                                                                  <div class="image_carrier  align-items-end cursorPointer " >
                                                                    <img class="w-100" src="../../../assets/images/youtubeVideos/image 23.png"/>
                                                                  </div>
                                                               </a>
                                                               <div class="thumb-content">
                                                                 <p class="thumb-heading my-2">The Sabus Family</p>
                                                                 <p class="thumb-para">
                                                                    “I'm so happy! What I saw in 3D is exactly what I got. So that is the best part! I got regular mails from them.They were connected with me. When there was a small concern, they attended it. Immediately I got a call from the Mumbai office. So I'm very happy with their service. What they promised they'd delivered.”
                                                                 </p>
                                                            </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6 h-100 px-2">
                                                               <a (click)="videoPlayIn('https://www.youtube.com/embed/fzd7taoEDL8?autoplay=1&loop=1')" class="cursorPointer ">
                                                                  <div class="image_carrier  align-items-end" >
                                                                    <img class="w-100" src="../../../assets/images/youtubeVideos/16.png"/>
                                                                  </div>
                                                               </a>
                                                               <div class="thumb-content">
                                                                 <p class="thumb-heading my-2">The Subramanian Family</p>
                                                                 <p class="thumb-para">
                                                                    “When I had this house interior project, I did extensive research on multiple agencies, multiple companies. They have a personal interaction with the client, so that's why I chose Arrivae. What I liked about Arrivae was their openness to my ideas and my suggestions and my thoughts, and then they took it from me and then they transformed into something that really worked for us.”
                                                                 </p>
                                                              </div>
                                                            </div>
                                                            <div class="col-lg-3 col-md-6 col-sm-6  h-100 px-2">
                                                               <a (click)="videoPlayIn('https://www.youtube.com/embed/UJeGixHIWRk?autoplay=1&loop=1')" class="cursorPointer ">
                                                                  <div class="image_carrier  align-items-end cursorPointer " >
                                                                    <img class="w-100" src="../../../assets/images/youtubeVideos/17.png"/>
                                                                  </div>
                                                               </a>
                                                               <div class="thumb-content">
                                                                 <p class="thumb-heading my-2">The Rishabh Family</p>
                                                                 <p class="thumb-para">
                                                                    “My experience with Arrivae was fabulous— perfectly blending my ideas, my imagery with their expertise and experience in this particular field to bring out a beautifully decorated home. Throughout the design and development process, Arrivae's attention to detail was impeccable and second to none.”
                                                                 </p>
                                                              </div>
                                                            </div>
                                                    </div>
                                                    </div>
                                                     <div [ngClass]="roletoSet() < 650?'d-block':'d-none'" class="w-100">
                                                        <div  class=" videoThumbnails pt-3">    
                                                        <div slickContainer #slickController="slick" [slickConfig]="config">
                                                            <ng-container *ngIf="slickController.initialize">
                                                           <div slickItem  (click)="mobilevideoPlayIn2('https://www.youtube.com/embed/Sqr8WhpZSmM?autoplay=1&loop=1')">
                                                                      <img class="img-width" src="../../../assets/images/youtubeVideos/14.png"/>
                                                              <div  class="thumb-content">
                                                                   <p class="thumb-heading my-2">The Pandurangis Family</p>
                                                                   <p class="thumb-para">“We are very, very happy the way Arrivae has done the job, the timely delivery and as well as the cooperation throughout the project! It was entirely a beautiful journey for us. We never felt that we have been doing such a great change in the house.”</p>
                                                              </div>
                                                           </div>
                                                           <div slickItem   (click)="mobilevideoPlayIn2('https://www.youtube.com/embed/ljhxUFH01Uw?autoplay=1&loop=1')" >
                                                                   <img class="img-width" src="../../../assets/images/youtubeVideos/image 23.png"/>
                                                              <div class="thumb-content">
                                                                <p class="thumb-heading my-2">The Sabus Family</p>
                                                                <p class="thumb-para">
                                                                   “I'm so happy! What I saw in 3D is exactly what I got. So that is the best part! I got regular mails from them.They were connected with me. When there was a small concern, they attended it. Immediately I got a call from Mumbai office. So I'm very happy with their service. What they promised they'd delivered.”
                                                                </p>
                                                           </div>
                                                           </div>
                                                           <div slickItem  (click)="mobilevideoPlayIn2('https://www.youtube.com/embed/fzd7taoEDL8?autoplay=1&loop=1')" >
                                                                   <img class="img-width" src="../../../assets/images/youtubeVideos/16.png"/>
                                                              <div class="thumb-content">
                                                                <p class="thumb-heading my-2">The Subramanian Family</p>
                                                                <p class="thumb-para">
                                                                   “When I had this house interior project, I did extensive research on multiple agencies, multiple companies. They have a personal interaction with the client, so that's why I chose Arrivae. What I liked about Arrivae was their openness to my ideas and my suggestions and my thoughts, and then they took it from me and then they transformed into something that really worked for us.”
                                                                </p>
                                                             </div>
                                                           </div>
                                                           <div slickItem   (click)="mobilevideoPlayIn2('https://www.youtube.com/embed/UJeGixHIWRk?autoplay=1&loop=1')" >
                                                                   <img class="img-width" src="../../../assets/images/youtubeVideos/17.png"/>
                                                              <div class="thumb-content">
                                                                <p class="thumb-heading my-2">The Rishabh Family</p>
                                                                <p class="thumb-para">
                                                                   “My experience with Arrivae was fabulous— perfectly blending my ideas, my imagery with their expertise and experience in this particular field to bring out a beautifully decorated home. 
                                                                   Throughout the design and development process, Arrivae's attention to detail was impeccable and second to none.”
                                                    
                                                                </p>
                                                             </div>
                                                           </div>
                                                            </ng-container>
                                                        </div>
                                    
                                                     </div>
                                                    </div>
                                                </div>
                                        
                                                <div class="w-100 d-flex justify-content-center mt-4">
                                                    <button (click)="bookConsultationLandingPage()" class="btn-tour px-5 sivajiFont" >
                                                        Book A Free Consultation
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        </div>

               
               
                    <div class="root-overflow" *ngIf="BuildingNameGetter() == 'm3m-skywalk'" >
                        <app-new-header-for-home [parentSubject]="parentSubject"  ></app-new-header-for-home>
                        <div class="container-fluid marathon_banner southern-crest" 
                        style="background-image:url('../../assets/images/skywalkbanner.png') ;" >
                        
                        
                           
                        
                            <div class="marthon_wrapper sivajiwrapper   w-100 h-100 " >
                                <div class="w-100" >
                                    <img class="float-right image_logo mt-2" src="../../assets/images/skywalklogo.png" alt="">
                                </div>
                               
                                
                                <div class="Mobile_div  d-flex h-100 justify-content-center m-auto w-100">
                                    <div class="divforDes mx-auto">
                        
                                        <h2>EXPERIENCE THE BEST OF CITY LIFE</h2>
                                        <div class="w-100 d-flex justify-content-center">
                        
                        
                                            <p>
                                                Find Comfort, Convenience And Connection At M3M Skywalk, Gurgaon
                                            </p>
    
                                           
                                          
                                
                        
                                        </div>
                                        <h3 class="mt-3">
                                            2 AND 2.5 BHK LUXURY APARTMENTS
            
                                        </h3>
                                      
                                       
                
                                        
                        
                                    </div>
                                   
                                   
                                </div>
                        
                            </div>
                            
                        </div>
                       
                        <div class="container-fluid py-5 perfect-fit-wrapper px-0 ">
                            <h3 class="pb-3 sivajiFont">
                
                                Spacious Semi-Furnished Homes
                             
                            </h3>
                          
                            <p  id="sivajipan"  class="m-auto sivajipan">
                                Choose the perfect limited edition apartment for you with a host of options for layouts and area. 
                                Enjoy 5-tier security, other elite amenities and chic home interiors by Arrivae at M3M Skywalk.
                            </p>
                           
                          
                        </div>
                        <app-catlouge-apartment></app-catlouge-apartment>
                        <div class="container-fluid images_display">
                            <div class="d-flex w-100 flex-wrap justify-content-space-between">
                                <div class="image_dis">
                                    <img class="w-100 h-100" src='../../assets/images/skywalk1.png' />
                                </div>
                                <div class="image_dis ml-2">
                                    <img class="w-100 h-100" src='../../assets/images/skywalk3.png'/>
                                </div>
                                <div class="image_dis ml-2">
                                    <img class="w-100 h-100" src='../../assets/images/skywalk4.png' />
                                </div>
                            </div>
                        </div>
                        <div class="container-fluid py-5 perfect-fit-wrapper">
                            <h3 class="pb-3 sivajiFont" >
                                Discover The Luxury Of Italian Design Through Our Themes
                            </h3>
                           
                            <ng-container>
                                <div >
                                    <h4 class="heading-sivaji">
                                        Dark Knight- Affordable Luxury Italian Designs
    
                                
                                    </h4>
                            
                                   <div class="sivaji-details w-100 d-flex justify-content-center">
                        
                                    <p>
                                        The personification of opulence, Dark Knight uses a deep neutral palette, plush textiles, metal accents and sleek lighting to create elevated interiors.
                
                                    </p>
                        
                                   </div>
                                   
                                    <h4 class="heading-sivaji">
                                        Urban Elegance- For Focused And Organized Spaces
                                
                                    </h4>
                        
                                    <div class="sivaji-details w-100 d-flex justify-content-center ">
                        
                                        <p>
                                            Warm, welcoming and charming, Urban Elegance strikes a delicate balance between the sumptuous aesthetics of neutral hues and intelligent design solutions.
    
                
                
                                        </p>
                        
                                    </div>
                                   
                                    <h4 class="heading-sivaji">
                                        Golden Murano- Luxury Read In Bold And Beautiful Design
                
                                
                                    </h4>
                                    <div class="sivaji-details w-100 d-flex justify-content-center">
                        
                                        <p>
                                            Sublime and refined interiors, warm and plush white foundations topped with hints of wood and gold — Golden Murano is an excellent choice for lavish and chic homes.
    
                
                                        </p>
                                    
                        
                                    </div>
                                 
                        
                                </div>
                        
                              
                        
                            </ng-container>
                        
                            <div class="w-100 d-flex justify-content-center mt-4">
                                <button  (click)="getQuote()" class="btn-tour px-5 sivajiFont" >
                                    DISCOVER MORE DESIGNS
                                </button>
                            </div>
                           
                        </div>
                        
                        <div class="w-100 mid_banner sivajiheight"  style="background-image:url('../../assets/images/skywalkmidbanner.png') ;" ></div>
                        
                        
                        <div class="w-100 d-flex align-items-center plans_do_right py-5">
                            <div class="col-12">
                                <h3  class="pb-2">Design The Home Of Your Dreams
                                </h3>
                        
                                
                                <p  class="m-auto pb-2 sivajiFont" >
                                    Functional and elegant designs, custom furniture, top-quality materials, and a chance to add your 
                                    unique touch to each room— design your dream home with us.
                
                                </p>
                        
                                <div class="w-100 d-flex justify-content-center mt-4">
                                    <button (click)="bookConsultationLandingPage()" class="btn-tour px-5 sivajiFont" >
                                        Book A Free Consultation
                                    </button>
                                </div>
                            </div>
                        </div>
                        </div>

                        <div class="root-overflow" *ngIf="BuildingNameGetter() == 'Hubtown_Seasons'" >
                            <app-new-header-for-home [parentSubject]="parentSubject"  ></app-new-header-for-home>
                            <div class="container-fluid marathon_banner southern-crest" 
                            style="background-image:url('../../assets/images/HubtownBanner.png') ;" >
                            
                            
                               
                            
                                <div class="marthon_wrapper sivajiwrapper   w-100 h-100 " >
                                    <div class="w-100" >
                                        <img class="float-right image_logo hub_town mt-2" src="../../assets/images/Hubtownlogo.png" alt="">
                                    </div>
                                   
                                    
                                    <div class="Mobile_div  d-flex h-100 justify-content-center m-auto w-100">
                                        <div class="divforDes mx-auto">
                            
                                            <h2 class="mt-lg-0 mt-5">AN IDEAL RESIDENCE FOR CITY LIFE</h2>
                                            <div class="w-100 d-flex justify-content-center">
                            
                            
                                                <p>
                                                    Well Designed Apartments, Spacious Apartments In The Heart Of Mumbai
                                                </p>
        
                                               
                                              
                                    
                            
                                            </div>
                                           
                                            <div class="col-md-10 Mobile_div wrarpper_bhk m-auto">
                                                <div class="row justify-content-center w-100">
                                                    <div class="col-lg-8 col-md-8 col-sm-12 mt-4">
                                                        <div class=" sivajiFont row">
                                                            <div class="col-3 format_type">
                                                               <a class="sivajiFont"  target="_blank">2 BHK</a>
                                                            </div>
                                                            <div class="col-3 format_type">
                                                              <a class="sivajiFont" target="_blank">3 BHK</a>  
                                                            </div>
                                                            <div class="col-3 format_type ">
                                                               <a class="sivajiFont" target="_blank">4 BHK</a> 
                                                            </div>
                                                            <div class="col-3  lastFormat">
                                                                <a class="sivajiFont"  target="_blank"> 5 BHK</a>
                                                             </div>
                                                        </div>
                                                    </div>
                                                 
                                                </div>              
                                            </div>
                                           
                    
                                            
                            
                                        </div>
                                       
                                       
                                    </div>
                            
                                </div>
                                
                            </div>
                           
                            <div class="container-fluid py-5 perfect-fit-wrapper px-0 ">
                                <h3 class="pb-3 sivajiFont">
                    
                                    Perfect Combination Of Luxury And Accessibility
                                 
                                </h3>
                              
                                <p  id="sivajipan"  class="m-auto sivajipan">
                                    Experience the best that life in Mumbai has to offer at Hubtown Seasons. Located in Chembur, a part of the Mumbai Harbour, this residence
                                    offers multiple amenities that support an active, fast-paced lifestyle along with easy connectivity to various parts of the city. 
                                    Make the apartment your own with Arrivae’s sophisticated and chic design themes exclusively curated for urban life.
                                </p>
                             
                              
                            </div>
                            <app-catlouge-apartment></app-catlouge-apartment>
                            <div class="container-fluid images_display">
                                <div class="d-flex w-100 flex-wrap justify-content-space-between">
                                    <div class="image_dis">
                                        <img class="w-100 h-100" src='../../assets/images/Hubtown1.png' />
                                    </div>
                                    <div class="image_dis ml-2">
                                        <img class="w-100 h-100" src='../../assets/images/Hubtown3.png'/>
                                    </div>
                                    <div class="image_dis ml-2">
                                        <img class="w-100 h-100" src='../../assets/images/Hubtown4.png' />
                                    </div>
                                </div>
                            </div>
                            <div class="container-fluid py-5 perfect-fit-wrapper">
                                <h3 class="pb-3 sivajiFont" >
                                    Discover The Luxury Of Italian Design Through Our Themes
                                </h3>
                               
                                <ng-container>
                                    <div >
                                        <h4 class="heading-sivaji">
                                            Dream Ballet- Modern Gray Light Luxury
        
                                    
                                        </h4>
                                
                                       <div class="sivaji-details w-100 d-flex justify-content-center">
                            
                                        <p>
                                            Dream Ballet celebrates the versatility of the colour grey combined with orange accents and the key elements- clean lines, marble and copper-frosted glass exude sophistication.
                    
                                        </p>
                            
                                       </div>
                                       
                                        <h4 class="heading-sivaji">
                                            Golden Murano- Luxury Read In Bold And Beautiful Design
                                    
                                        </h4>
                            
                                        <div class="sivaji-details w-100 d-flex justify-content-center ">
                            
                                            <p>
                                                Sublime and refined interiors, warm and plush white foundations topped with hints of wood and gold — Golden Murano is an excellent choice for lavish and chic homes.
        
                    
                    
                                            </p>
                            
                                        </div>
                                       
                                        <h4 class="heading-sivaji">
                                            Dark Knight- Affordable Luxury Italian Designs
                    
                                    
                                        </h4>
                                        <div class="sivaji-details w-100 d-flex justify-content-center">
                            
                                            <p>
                                                The personification of opulence, Dark Knight uses a deep neutral palette, plush textiles, metal accents and sleek lighting to create elevated interiors.
                    
                                            </p>
                                        
                            
                                        </div>
                                     
                            
                                    </div>
                            
                                  
                            
                                </ng-container>
                            
                                <div class="w-100 d-flex justify-content-center mt-4">
                                    <button  (click)="getQuote()" class="btn-tour px-5 sivajiFont" >
                                        DISCOVER MORE DESIGNS
                                    </button>
                                </div>
                               
                            </div>
                            
                            <div class="w-100 mid_banner sivajiheight"  style="background-image:url('../../assets/images/Hubtownmidbanner.png') ;" ></div>
                            
                            
                            <div class="w-100 d-flex align-items-center plans_do_right py-5">
                                <div class="col-12">
                                    <h3  class="pb-2 sivajiFont">Design The Home Of Your Dreams
                                    </h3>
                            
                                    
                                    <p  class="m-auto pb-2 sivajiFont" >
                                        Functional and elegant designs, custom furniture, top-quality materials, and a chance to add your 
                                        unique touch to each room— design your dream home with us.
                    
                                    </p>
                            
                                    <div class="w-100 d-flex justify-content-center mt-4">
                                        <button (click)="bookConsultationLandingPage()" class="btn-tour px-5 sivajiFont" >
                                            Book A Free Consultation
                                        </button>
                                    </div>
                                </div>
                            </div>
                            </div>

                            <div class="root-overflow" *ngIf="BuildingNameGetter() == 'Hubtown_Heaven'" >
                                <app-new-header-for-home [parentSubject]="parentSubject"  ></app-new-header-for-home>
                                <div class="container-fluid marathon_banner southern-crest" 
                                style="background-image:url('../../assets/images/Hub_Hev.png') ;" >
                                
                                
                                   
                                
                                    <div class="marthon_wrapper sivajiwrapper   w-100 h-100 " >
                                        <div class="w-100" >
                                            <img class="float-right image_logo hub_town mt-2" src="../../assets/images/Hubtownlogo.png" alt="">
                                        </div>
                                       
                                        
                                        <div class="Mobile_div  d-flex h-100 justify-content-center m-auto w-100">
                                            <div class="divforDes mx-auto">
                                
                                                <h2 class="mt-lg-0 mt-5">DISCOVER OUR ONE-OF-A-KIND DESIGNS
                                                </h2>
                                                <div class="w-100 d-flex justify-content-center">
                                
                                
                                                    <p>
                                                        Comfort, Convenience And Accessible Luxury At Hubtown Heaven, Matunga (E), Mumbai
                                                    </p>
            
                                                   
                                                  
                                        
                                
                                                </div>
                                               
                                                <div class="col-md-6 Mobile_div wrarpper_bhk m-auto">
                                                    <div class="row w-100">
                                                        <div class="col-12 mt-4">
                                                            <div class=" sivajiFont row justify-content-center">
                                                                <div class="col-3 format_type">
                                                                   <a class="sivajiFont"  target="_blank">2 BHK</a>
                                                                </div>
                                                                <div class="col-3 format_type">
                                                                  <a  class="sivajiFont" target="_blank">3 BHK</a>  
                                                                </div>
                                                               
                                                                <div class="col-3  lastFormat">
                                                                    <a class="sivajiFont" target="_blank">4 BHK</a>
                                                                 </div>
                                                            </div>
                                                        </div>
                                                     
                                                    </div>              
                                                </div>
                                               
                        
                                                
                                
                                            </div>
                                           
                                           
                                        </div>
                                
                                    </div>
                                    
                                </div>
                               
                                <div class="container-fluid py-5 perfect-fit-wrapper px-0 ">
                                    <h3 class="pb-3 sivajiFont">
                        
                                        An Opulent Life For Every Budget
                                     
                                    </h3>
                                  
                                    <p  id="sivajipan"  class="m-auto sivajipan">
                                        Multiple apartment layouts and configurations, top-notch amenities, convenience of living in a prime location 
                                        and welcoming, elegant interiors by Arrivae make life at Hubtown Heaven the best the city has to offer.
                                    </p>
                                
                                  
                                </div>
                                <app-catlouge-apartment></app-catlouge-apartment>
                                <div class="container-fluid images_display">
                                    <div class="d-flex w-100 flex-wrap justify-content-space-between">
                                        <div class="image_dis">
                                            <img class="w-100 h-100" src='../../assets/images/Hub_Hev1.png' />
                                        </div>
                                        <div class="image_dis ml-2">
                                            <img class="w-100 h-100" src='../../assets/images/Hub_Hev3.png'/>
                                        </div>
                                        <div class="image_dis ml-2">
                                            <img class="w-100 h-100" src='../../assets/images/Hub_Hev4.png' />
                                        </div>
                                    </div>
                                </div>
                                <div class="container-fluid py-5 perfect-fit-wrapper">
                                    <h3 class="pb-3 sivajiFont" >
                                        Discover The Luxury Of Italian Design Through Our Themes
                                    </h3>
                                   
                                    <ng-container>
                                        <div >
                                            <h4 class="heading-sivaji">
                                                Dream Ballet- Modern Gray Light Luxury
            
                                        
                                            </h4>
                                    
                                           <div class="sivaji-details w-100 d-flex justify-content-center">
                                
                                            <p>
                                                Dream Ballet celebrates the versatility of the colour grey combined with orange accents and the key elements- clean lines, marble and copper-frosted glass exude sophistication.
                        
                                            </p>
                                
                                           </div>
                                           
                                            <h4 class="heading-sivaji">
                                                Modica- A Collaboration Of Minimal And Natural Finishes
                                        
                                            </h4>
                                
                                            <div class="sivaji-details w-100 d-flex justify-content-center ">
                                
                                                <p>
                                                    Modica marries functionality and minimalism to create elegant interiors. Its simple and clean design is enhanced by the adept utilisation of dark wood tones, leather and glass.
            
                        
                        
                                                </p>
                                
                                            </div>
                                           
                                            <h4 class="heading-sivaji">
                                                Golden Murano- Luxury Read In Bold And Beautiful Design
                        
                                        
                                            </h4>
                                            <div class="sivaji-details w-100 d-flex justify-content-center">
                                
                                                <p>
                                                    Sublime and refined interiors, warm and plush white foundations topped with hints of wood and gold — Golden Murano is an excellent choice for lavish and chic homes.
                        
                                                </p>
                                            
                                
                                            </div>
                                         
                                
                                        </div>
                                
                                      
                                
                                    </ng-container>
                                
                                    <div class="w-100 d-flex justify-content-center mt-4">
                                        <button  (click)="getQuote()" class="btn-tour px-5 sivajiFont" >
                                            DISCOVER MORE DESIGNS
                                        </button>
                                    </div>
                                   
                                </div>
                                
                                <div class="w-100 mid_banner sivajiheight"  style="background-image:url('../../assets/images/Hub_HevMidBanner.png') ;" ></div>
                                
                                
                                <div class="w-100 d-flex align-items-center plans_do_right py-5">
                                    <div class="col-12">
                                        <h3  class="pb-2 sivajiFont">Design The Home Of Your Dreams
                                        </h3>
                                
                                        
                                        <p  class="m-auto pb-2 sivajiFont" >
                                            Functional and elegant designs, custom furniture, top-quality materials, and a chance
                                            to add your unique touch to each room— design your dream home with us.
                                        </p>
                                
                                        <div class="w-100 d-flex justify-content-center mt-4">
                                            <button (click)="bookConsultationLandingPage()" class="btn-tour px-5 sivajiFont" >
                                                Book A Free Consultation
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div class="root-overflow" *ngIf="BuildingNameGetter() == 'Hubtown_Harmony'" >
                                    <app-new-header-for-home [parentSubject]="parentSubject"  ></app-new-header-for-home>
                                    <div class="container-fluid marathon_banner southern-crest" 
                                    style="background-image:url('../../assets/images/Hub_HarBanner.png') ;" >
                                    
                                    
                                       
                                    
                                        <div class="marthon_wrapper sivajiwrapper   w-100 h-100 " >
                                            <div class="w-100" >
                                                <img class="float-right image_logo mt-2 hub_town" src="../../assets/images/Hubtownlogo.png" alt="">
                                            </div>
                                           
                                            
                                            <div class="Mobile_div  d-flex h-100 justify-content-center m-auto w-100">
                                                <div class="divforDes mx-auto">
                                    
                                                    <h2 class="w-75 mt-lg-0 mt-5 m-auto lineheight pb-3">A MODERN ABODE IN THE HERITAGE AREA OF MATUNGA</h2>
                                                    <div class="w-100 d-flex justify-content-center">
                                    
                                    
                                                       
                                                      
                                            
                                    
                                                    </div>
                                                   
                                                    <div class="col-md-6 Mobile_div wrarpper_bhk m-auto">
                                                        <div class="row justify-content-center w-100">
                                                            <div class="col-lg-8 col-md-8 col-sm-12 mt-4">
                                                                <div class="row sivajiFont justify-content-center">
                                                                    <div class="col-3 format_type">
                                                                       <a   target="_blank" class="sivajiFont">2 BHK</a>
                                                                    </div>
                                                                    <div class="col-4 format_type">
                                                                      <a class="sivajiFont"  target="_blank">2.5 BHK</a>  
                                                                    </div>
                                                                   
                                                                    <div class="col-3  lastFormat">
                                                                        <a  class="sivajiFont" target="_blank"> 3 BHK</a>
                                                                     </div>
                                                                </div>
                                                            </div>
                                                         
                                                        </div>              
                                                    </div>
                                                   
                            
                                                    
                                    
                                                </div>
                                               
                                               
                                            </div>
                                    
                                        </div>
                                        
                                    </div>
                                   
                                    <div class="container-fluid py-5 perfect-fit-wrapper px-0 ">
                                        <h3 class="pb-3 sivajiFont">
                            
                                            Accessible, Affordable And Aspirational Home In The Heart Of Mumbai
                                         
                                        </h3>
                                      
                                        <p  id="sivajipan"  class="m-auto sivajipan">
                                            Located near King’s Circle, Matunga, Mumbai, Hubtown Heaven gives its residents a shot at life of accessibility, convenience and work-life balance. 
                                            With some of the best entertainment spots, educational and medical institutes minutes away, and top-notch 
                                            amenities within premises, you will want for nothing.
                                        </p>
                                       
                                      
                                    </div>
                                    <app-catlouge-apartment></app-catlouge-apartment>
                                    <div class="container-fluid images_display">
                                        <div class="d-flex w-100 flex-wrap justify-content-space-between">
                                            <div class="image_dis">
                                                <img class="w-100 h-100" src='../../assets/images/Hub_Har1.png' />
                                            </div>
                                            <div class="image_dis ml-2">
                                                <img class="w-100 h-100" src='../../assets/images/Hub_Har3.png'/>
                                            </div>
                                            <div class="image_dis ml-2">
                                                <img class="w-100 h-100" src='../../assets/images/Hub_Har4.png' />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="container-fluid py-5 perfect-fit-wrapper">
                                        <h3 class="pb-3 sivajiFont" >
                                            Discover The Luxury Of Italian Design Through Our Themes
                                        </h3>
                                       
                                        <ng-container>
                                            <div >
                                                <h4 class="heading-sivaji">
                                                    Dream Ballet- Modern Gray Light Luxury
                
                                            
                                                </h4>
                                        
                                               <div class="sivaji-details w-100 d-flex justify-content-center">
                                    
                                                <p>
                                                    Dream Ballet celebrates the versatility of the colour grey combined with orange accents and the key elements- clean lines, marble and copper-frosted glass exude sophistication.
                            
                                                </p>
                                    
                                               </div>
                                               
                                                <h4 class="heading-sivaji">
                                                    Morandi- A Blend Of Steel And Wood
                                            
                                                </h4>
                                    
                                                <div class="sivaji-details w-100 d-flex justify-content-center ">
                                    
                                                    <p>
                                                        Morandi showcases a skillful use of steel and wood. Paired with a chic urban colour palette, it is simple and sophisticated. 
                                                        Hints of texture and smoky green make the designs visually appealing.
                
                            
                            
                                                    </p>
                                    
                                                </div>
                                               
                                                <h4 class="heading-sivaji">
                                                    Macaron- Modern, Affordable, Textured, Contemporary
                            
                                            
                                                </h4>
                                                <div class="sivaji-details w-100 d-flex justify-content-center">
                                    
                                                    <p>
                                                        Macaroon's primary colour palette of whites and subtle pinks lends to charming and youthful designs. 
                                                        This contemporary aesthetic is peppered with textures and light wood accents for a dynamic and chic look.
                            
                                                    </p>
                                                
                                    
                                                </div>
                                             
                                    
                                            </div>
                                    
                                          
                                    
                                        </ng-container>
                                    
                                        <div class="w-100 d-flex justify-content-center mt-4">
                                            <button  (click)="getQuote()" class="btn-tour px-5 sivajiFont" >
                                                DISCOVER MORE DESIGNS
                                            </button>
                                        </div>
                                       
                                    </div>
                                    
                                    <div class="w-100 mid_banner sivajiheight"  style="background-image:url('../../assets/images/Hub_Har_mid_banner.png') ;" ></div>
                                    
                                    
                                    <div class="w-100 d-flex align-items-center plans_do_right py-5">
                                        <div class="col-12">
                                            <h3  class="pb-2 sivajiFont">Design The Home Of Your Dreams
                                            </h3>
                                    
                                            
                                            <p  class="m-auto pb-2 sivajiFont" >
                                                Functional and elegant designs, custom furniture, top-quality materials, and a chance
                                                to add your unique touch to each room— design your dream home with us.
                            
                                            </p>
                                    
                                            <div class="w-100 d-flex justify-content-center mt-4">
                                                <button (click)="bookConsultationLandingPage()" class="btn-tour px-5 sivajiFont" >
                                                    Book A Free Consultation
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    </div>

                                    <div class="root-overflow" *ngIf="BuildingNameGetter() == '140-Shivaji2'" >
                                        <app-new-header-for-home [parentSubject]="parentSubject"></app-new-header-for-home>
                                        <div class="container-fluid marathon_banner sivaji-banner sivaji-banner17 " 
                                        style="background-image:url('../../assets/images/Bannershiv.png') ;" >
                                        
                                        
                                           
                                        
                                            <div class="marthon_wrapper sivajiwrapper   w-100 h-100 " >
                                                <div class="w-100" >
                                                    <img class="float-right image_logo hub_townshiv mt-2" src="../../assets/images/logoshivpng.png" alt="">
                                                </div>
                                               
                                                
                                                <div class="Mobile_div  d-flex h-100 justify-content-center m-auto w-100">
                                                    <div class="divforDes mx-auto">
                                        
                                                        <h2 class="onlyforshivajipur">A SLICE OF TRANQUILLITY AND LUXE DESIGNS</h2>
                                                        <div class="w-100 d-flex justify-content-center">
                                                            <p>
                                                                Airy, Lavish And Vibrant Homes In Prestigious Location At Shivaji Park, Dadar
                                                            </p>    
                                                        </div>
                                                       
                                                        <div class="col-md-6 Mobile_div wrarpper_bhk m-auto">
                                                            <div class="row w-100 my-4">
                                                                <div class="col-12">
                                                                    <div class=" sivajiFont row justify-content-center">
                                                                        <div class="col-3 format_type">
                                                                           <a class="sivajiFont" href="http://pano.yfway.com/51118127"  target="_blank">3 BHK</a>
                                                                        </div>
                                                                        <div class="col-3 format_type">
                                                                          <a  class="sivajiFont" href="http://pano.yfway.com/51118127"  target="_blank">4 BHK</a>  
                                                                        </div>
                                                                       
                                                                        <div class="col-3  lastFormat">
                                                                            <a class="sivajiFont" href="http://pano.yfway.com/51118127"  target="_blank">5 BHK</a>
                                                                         </div>
                                                                    </div>
                                                                </div>

                                                               
                                                             
                                                            </div>              
                                                        </div>

                                                        <div class="d-flex justify-content-center">
                                                            <form class="nameAndPhone justify-content-center d-flex flex-lg-row flex-column" [formGroup]= "additionalApartmentDetails">
                                                                    <input  formControlName="namee" class="px-4 py-2 mx-lg-3 nameField mb-lg-0 mb-3" placeholder="Please Enter Your Name" type="text"/>
                                                                    <input  formControlName="phone_number" class="px-4 py-2 mx-lg-3 nameField mb-lg-0 mb-3" placeholder="Phone Number" onKeyPress="if(this.value.length==10) return false;"  type="number"/>
                                                                    <button [disabled] = "additionalApartmentDetails.invalid" class="nameDetailsButton px-5 py-2 mb-lg-0 mb-3" (click)="NameAndPhn(additionalApartmentDetails.value)" >GET A QUOTE</button>
                                                            </form>
                                                        </div>
                                                       
                                
                                                        
                                        
                                                    </div>
                                                   
                                                   
                                                </div>
                                        
                                            </div>
                                            
                                        </div>
                                       
                                        <div class="container-fluid py-5 perfect-fit-wrapper px-0 ">
                                            <h3 class="pb-3 sivajiFont">
                                
                                                Find A Peaceful Life Of Luxury In The City That Never Sleeps
                                             
                                            </h3>
                                          
                                            <p  id="sivajipan"  class="m-auto sivajipan">
                                                140 Shivaji Park, tucked in a quiet lush lane offers a chance at tranquil life away from the hustle-bustle of Mumbai. With amenities
                                                that promote a holistic lifestyle and homes adorned in Arrivae’s chic design themes, you can have it all.
                                            </p>
                                       
                                          
                                        </div>
                                        <app-catlouge-apartment></app-catlouge-apartment>
                                        <div class="container-fluid images_display">
                                            <div class="d-flex w-100 flex-wrap justify-content-space-between">
                                                <div class="image_dis">
                                                    <img class="w-100 h-100" src='../../assets/images/shiv1.png' />
                                                </div>
                                                <div class="image_dis ml-2">
                                                    <img class="w-100 h-100" src='../../assets/images/shiv3.png'/>
                                                </div>
                                                <div class="image_dis ml-2">
                                                    <img class="w-100 h-100" src='../../assets/images/shiv4.png' />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container-fluid py-5 perfect-fit-wrapper">
                                            <h3 class="pb-3 sivajiFont" >
                                                Discover The Luxury Of Italian Design Through Our Themes
                                            </h3>
                                           
                                            <ng-container>
                                                <div >
                                                    <h4 class="heading-sivaji">
                                                        Dream Ballet- Modern Gray Light Luxury
                    
                                                
                                                    </h4>
                                            
                                                   <div class="sivaji-details w-100 d-flex justify-content-center">
                                        
                                                    <p>
                                                        Dream Ballet celebrates the versatility of the colour grey combined with orange accents and the key elements- clean lines, marble and copper-frosted glass exude sophistication.
                                
                                                    </p>
                                        
                                                   </div>
                                                   
                                                    <h4 class="heading-sivaji">
                                                        Dark Knight- Affordable Luxury Italian Designs
                                                    </h4>
                                        
                                                    <div class="sivaji-details w-100 d-flex justify-content-center ">
                                        
                                                        <p>
                                                            The personification of opulence, Dark Knight uses a deep neutral palette, plush textiles, metal accents and sleek lighting to create elevated interiors.
                    
                                
                                
                                                        </p>
                                        
                                                    </div>
                                                   
                                                    <h4 class="heading-sivaji">
                                                        Modern Chic- A Unique Rendition Of Art Deco
                                
                                                
                                                    </h4>
                                                    <div class="sivaji-details w-100 d-flex justify-content-center">
                                        
                                                        <p>
                                                            Our unique take on the swanky Art Deco style, Modern Chic marries glamour and contemporary minimalism with a focus on maximising storage, making it ideal for urban living.
                                
                                                        </p>
                                                    
                                        
                                                    </div>
                                                 
                                        
                                                </div>
                                        
                                              
                                        
                                            </ng-container>
                                        
                                            <div class="w-100 d-flex justify-content-center mt-4">
                                                <button  (click)="getQuote()" class="btn-tour px-5 sivajiFont" >
                                                    DISCOVER MORE DESIGNS
                                                </button>
                                            </div>
                                           
                                        </div>
                                        
                                        <div class="w-100 mid_banner sivajiheight"  style="background-image:url('../../assets/images/shivmidbanner.png') ;" ></div>
                                        
                                        
                                        <div class="w-100 d-flex align-items-center plans_do_right py-5">
                                            <div class="col-12">
                                                <h3  class="pb-2 sivajiFont">Design The Home Of Your Dreams
                                                </h3>
                                        
                                                
                                                <p  class="m-auto pb-2 sivajiFont" >
                                                    Functional and elegant designs, custom furniture, top-quality materials, and a chance
                                                    to add your unique touch to each room— design your dream home with us.
                                                </p>
                                        
                                                <div class="w-100 d-flex justify-content-center mt-4">
                                                    <button (click)="bookConsultationLandingPage()" class="btn-tour px-5 sivajiFont" >
                                                        Book A Free Consultation
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="root-overflow" *ngIf="BuildingNameGetter() == 'Tridhaatu-Aranya'" >
                                        <app-new-header-for-home [parentSubject]="parentSubject"></app-new-header-for-home>
                                        <div class="container-fluid marathon_banner sivaji-banner" 
                                        style="background-image:url('../../assets/images/aranyaBanner.png') ;" >
                                            <div class="marthon_wrapper sivajiwrapper   w-100 h-100 " >
                                                <div class="Mobile_div  d-flex h-100 justify-content-center m-auto w-100">
                                                    <div style="margin-top: unset !important;" class="divforDes mx-auto d-flex justify-content-center align-self-center align-items-center flex-column">
                                                        <h2  class="mx-auto Aranya " >A PALATIAL TOWER NESTLED AT THE FOOT OF VERDANT MOUNTAINS</h2>
                                                            <p style="width: unset !important;" class="text-center">3 BHK and 4 BHK Luxury Apartment in central Mumbai- Chembur</p>
                                                            <div class="d-flex justify-content-center mt-4">
                                                                <form class="nameAndPhone justify-content-center d-flex flex-lg-row flex-column" [formGroup]= "additionalApartmentDetails">
                                                                        <input  formControlName="namee" class="px-4 py-2 mx-lg-3 nameField mb-lg-0 mb-3" placeholder="Please Enter Your Name" type="text"/>
                                                                        <input  formControlName="phone_number" class="px-4 py-2 mx-lg-3 nameField mb-lg-0 mb-3" placeholder="Phone Number" onKeyPress="if(this.value.length==10) return false;"  type="number"/>
                                                                        <button [disabled] = "additionalApartmentDetails.invalid" class="nameDetailsButton px-5 py-2 mb-lg-0 mb-3" (click)="NameAndPhn(additionalApartmentDetails.value)" >GET A QUOTE</button>
                                                                </form>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                       
                                        <div class="container-fluid py-5 perfect-fit-wrapper px-0 ">
                                            <h3 class="sivajiFont">
                                                A Towering Structure With Panoramic Views Of Lush Hills 
                                            </h3>
                                            <h3 class="pb-3 sivajiFont">
                                                And Vibrant Cityscape
                                            </h3>
                                            <p  id="sivajipan"  class="m-auto sivajipan">
                                                Opulent design, stunning views, central location and living in a community with like-minded neighbours characterise life at Aranya. 
                                                It boasts of amenities such as relaxing terrace garden and lounges, fitness zones, and a beautiful butterfly park. 
                                                Limited edition palatial apartments designed in Arrivae’s exclusive Italian design themes make Aranya a highly coveted location.
                                            </p>
                                        </div>
                                        <app-catlouge-apartment></app-catlouge-apartment>
                                        <div class="container-fluid images_display">
                                            <div class="d-flex w-100 flex-wrap justify-content-space-between">
                                                <div class="image_dis">
                                                    <img class="w-100 h-100" src='../../assets/images/aranya1.png' />
                                                </div>
                                                <div class="image_dis ml-2">
                                                    <img class="w-100 h-100" src='../../assets/images/aranya2.png'/>
                                                </div>
                                                <div class="image_dis ml-2">
                                                    <img class="w-100 h-100" src='../../assets/images/aranya3.png' />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="container-fluid py-5 perfect-fit-wrapper">
                                            <h3 class="pb-3 sivajiFont" >
                                                Discover The Luxury Of Italian Design Through Our Themes
                                            </h3>
                                           
                                            <ng-container>
                                                <div >
                                                    <h4 class="heading-sivaji">
                                                        Dream Ballet- Modern Gray Light Luxury
                    
                                                
                                                    </h4>
                                            
                                                   <div class="sivaji-details w-100 d-flex justify-content-center">
                                        
                                                    <p>
                                                        Dream Ballet celebrates the versatility of the colour grey combined with orange accents and the key elements- clean lines, marble and copper-frosted glass exude sophistication.
                                
                                                    </p>
                                        
                                                   </div>
                                                   
                                                    <h4 class="heading-sivaji">
                                                        Morandi- A Blend Of Steel And Wood
                                                    </h4>
                                        
                                                    <div class="sivaji-details w-100 d-flex justify-content-center ">
                                                        <p>
                                                            Morandi showcases a skillful use of steel and wood. Paired with a chic urban colour palette, it is simple and sophisticated. Hints of texture and smoky green make the designs visually appealing.
                                                        </p>
                                                    </div>
                                                   
                                                    <h4 class="heading-sivaji">
                                                        Modica- A Collaboration Of Minimal And Natural Finishes
                                                    </h4>
                                                    <div class="sivaji-details w-100 d-flex justify-content-center">
                                                        <p>
                                                            Modica marries functionality and minimalism to create elegant interiors. Its simple and clean design is enhanced by the adept utilisation of dark wood tones, leather and glass.
                                                        </p>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        
                                            <div class="w-100 d-flex justify-content-center mt-4">
                                                <button  (click)="getQuote()" class="btn-tour px-5 sivajiFont" >
                                                    DISCOVER MORE DESIGNS
                                                </button>
                                            </div>
                                           
                                        </div>
                                        
                                        <div class="w-100 mid_banner sivajiheight"  style="background-image:url('../../assets/images/arranya4.png') ;" ></div>
                                        <div class="w-100 d-flex align-items-center plans_do_right py-5">
                                            <div class="col-12">
                                                <h3  class="pb-2 sivajiFont">Design The Home Of Your Dreams
                                                </h3>
                                        
                                                
                                                <p  class="m-auto pb-2 sivajiFont" >
                                                    Functional and elegant designs, custom furniture, top-quality materials, and a chance
                                                    to add your unique touch to each room— design your dream home with us.
                                                </p>
                                        
                                                <div class="w-100 d-flex justify-content-center mt-4">
                                                    <button (click)="bookConsultationLandingPage()" class="btn-tour px-5 sivajiFont" >
                                                        Book A Free Consultation
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
    
    <app-footer></app-footer> 
    <div *ngIf="BuildingNameGetter() == '140-Shivaji2' || BuildingNameGetter() == 'sky-forest' || BuildingNameGetter() == 'Tridhaatu-Aranya' || BuildingNameGetter() == 'adhiraj-capital-city' || BuildingNameGetter() == 'marathon-nexzone' || BuildingNameGetter() == 'Runwal_Gardens' || BuildingNameGetter() == 'dosti-pine' || BuildingNameGetter() == 'lodha-amara' ||  BuildingNameGetter() == 'Birla-Alokya' ||  BuildingNameGetter() == 'Rustomjee-Summit' "  class="whatsapp-img2" >
        <a class="btn btn-whatsapp" (click)="BookApointment()">
          <img src="../assets/images/Schedule.png"> Book Appointment
        </a>
      </div>
      <div  class="whatsapp-img3">
        <ng-container *ngIf = "BuildingNameGetter() != 'adhiraj-capital-city'">
            <a href="https://api.whatsapp.com/send?phone=918976817214" class="btn btn-whatsapp" target="_blank">
                <img src="../assets/images/whats-app.png"> Chat on WhatsApp 
              </a>
        </ng-container>
        <ng-container *ngIf = "BuildingNameGetter() === 'adhiraj-capital-city'">
            <a href="https://api.whatsapp.com/send?phone=919975414342" class="btn btn-whatsapp" target="_blank">
                <img src="../assets/images/whats-app.png"> Chat on WhatsApp 
              </a>
        </ng-container>
      </div>

      <div class="modal fade" id="ApointmentBook" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" data-backdrop="static"
    aria-hidden="true">
    <div style="max-width: 36rem;" class="modal-dialog modal-dialog-centered modal__get-free-quote modal-lg" role="document">
       <div class="modal-content center">
          <div class="modal-body">
            

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span style="font-size: 1.8rem;" aria-hidden="true">&times;</span>
                  </button>

                <h3 class="w-100 text-center Schedule-heading">Book Appointment</h3>

                <form class="nameAndPhone " [formGroup]= "additionalApointmentform">
                    <div class="row w-104">

                        <div class="col-12 text-center mb-2">
                            <label class="mb-0">
                                Name <span class="text-danger">*</span>
                            </label>
                            <input  formControlName="name" class="px-4 py-2 mx-lg-3 nameField mb-lg-0  w-75" placeholder="Please Enter Your Name" type="text"/>
                        </div>
                        <div class="col-12 text-center mb-2">
                            <label class="mb-0">
                                Phone Number <span class="text-danger">*</span>
                            </label>
                            <input  formControlName="phoneNumber" class="px-4 py-2 mx-lg-3 nameField mb-lg-0  w-75" placeholder="Phone Number"   oninput="javascript: if (this.value.length > 10) this.value = this.value.slice(0, 10);" type="number"/>
    
                        </div>
                        <div class="col-12 text-center mb-2">

                            <label class="mb-0">
                               Experience Center <span class="text-danger">*</span>
                            </label>
                            <select formControlName="experience_center" class="px-4 py-2 mx-lg-3 nameField mb-lg-0  w-75" >
                              <option [value]="''" class="applyStyles">
                                  Select Experience center
                              </option>
                              <option value="{{loc}}" *ngFor="let loc of LocationsFormarket ">
                                  {{loc}}
                              </option>

                              <option value="{{transform(BuildingNameGetter()) | replaceChar }}">
                               {{transform(BuildingNameGetter()) | replaceChar   | titlecase }}
                              </option>
  
                            </select>
      
                          </div>
  
                          <div class="col-12 mb-2 text-center">

                            <label class="mb-0">
                               Date <span class="text-danger">*</span>
                             </label>

                              <input  formControlName="date" min="{{today}}" class="px-4 py-2 mx-lg-3 nameField mb-lg-0  w-75" placeholder="Date" type="date"/>
      
                          </div>


                          <div class="col-12 d-flex justify-content-center">

                            

                            <div class="row w-75">
                                <label class="mb-0">
                                    Time Slot <span class="text-danger">*</span>
                                  </label>
     
                                <div class="col-6">
                                    <span [ngClass]="{'activeSpan':ActiveSpan  == '09-12'}"   class="d-inline-block w-100 p-2 spanBox" (click)="AddSpanTimeSlot('09-12','AM')">
                                        09 AM - 12 PM

                                    </span>
                                    <span [ngClass]="{'activeSpan':ActiveSpan  == '12-03'}"   class="d-inline-block w-100 p-2 spanBox" (click)="AddSpanTimeSlot('12-03','PM')">
                                        12 PM - 03 PM

                                    </span>
                                   


                                </div>
                                <div class="col-6">
                                    <span [ngClass]="{'activeSpan':ActiveSpan  == '03-06'}"  class="d-inline-block w-100 p-2 spanBox" (click)="AddSpanTimeSlot('03-06','PM')">
                                        03 PM - 06 PM

                                    </span>
                                    <span [ngClass]="{'activeSpan':ActiveSpan  == '06-09'}" class="d-inline-block w-100 p-2 mt-2 spanBox"  (click)="AddSpanTimeSlot('06-09','PM')">
                                        06 PM - 09 PM

                                    </span>
                                   

                                </div>

                            </div>
                            
                        </div>


                        
                    </div>
                    
                    <div class="w-100 d-flex justify-content-center">

                        <button class="nameDetailsButton px-5 py-2 mb-lg-0 mb-3  mt-3" [disabled]="additionalApointmentform.invalid || ActiveSpan =='' " (click)="CreateShedular()"> SUBMIT</button>

                    </div>
                    
                  
                  
            </form>

          </div>
       </div>
    </div>
 </div>

 <div class="loading d-flex justify-content-center" *ngIf="loader">
    <div class="spinner-border" style="color: #8d0528" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  
</div>
    