import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-hear-from-clients',
  templateUrl: './hear-from-clients.component.html',
  styleUrls: ['./hear-from-clients.component.scss'],
})
export class HearFromClientsComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {
    $(document).ready(function () {
      $('.hearFromClients').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        nextArrow: $('.next'),
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      });
    });
    this.loadScript()
  }
  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
