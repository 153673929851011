<div [ngClass] = "newLandingPage()? 'justify-content-start mt-lg-5' : 'justify-content-center'" class="frequent-questions p-5  text-center row  w-100 mx-0">
    <h1 [ngClass] = "newLandingPage()? 'new-landing  text-uppercase d-inline-flex px-0' : 'main-page'" class="col-lg-4 col-md-6 col-sm-7">Frequently Asked Questions</h1>
    <div [ngClass] = "newLandingPage() ? 'my-md-5' : 'm-md-5' " class="row  my-md-5 quotionaree" >
      <ng-container *ngIf="roletoSet() > 650">
            <div  class=" collapsing23 col-xl-3 col-lg-4 col-md-6 col-sm-12 frequent px-2 mb-4" *ngFor="let question of questions ; let i = index">
              <div [ngClass] = "newLandingPage() ? 'mr-4' : '' " class="questions">
                <div  [ngClass]="collapsehadle.indexOf(i+1) > -1?'font-weight-bold':''" class="question d-flex align-items-center justify-content-between ">
                    
                    <span (click)="collpase(i+1)" class="d-flex text-align-start cursorPointer ">
                      <span class="index">{{i+1}}</span>
                      .&nbsp;<p class="m-0 ">{{question.Q}}</p></span>
                    <img src="../../../assets/images/arrivaeHomePageImages/plusFaq.png" *ngIf="collapsehadle.indexOf(i+1) == -1"  (click)="collpase(i+1)" class=" cursorPointer" aria-hidden="true">
                    <i *ngIf="collapsehadle.indexOf(i+1) > -1" (click)="collpase(i+1)" class="fa fa-minus cursorPointer" aria-hidden="true"></i>
                    
                <!-- <img src="../../../assets/icons/+.png"> -->
                </div>
                <div id ="collpa{{i+1}}"  [ngClass]="collapsehadle.indexOf(i+1) > -1?'show':''" class="answer ">
                 <p  id="ccpa{{i+1}}">{{question.a}}</p> 

                </div>
              </div>
            </div>
      </ng-container>      
        <ng-container *ngIf="roletoSet() < 650">
          <ng-container *ngFor="let question of questions ; let i = index">

          <div *ngIf="i<showIndex"  class=" collapsing23 col-xl-3 col-lg-4 col-md-6 col-sm-12 frequent px-2 mb-2" >
            <div class="questions">
              <div  [ngClass]="collapsehadle.indexOf(i+1) > -1?'font-weight-bold':''" class="question d-flex align-items-center justify-content-between px-4 py-1">
                  <span (click)="collpase(i+1)" class="text-align-start cursorPointer d-flex">
                    <span class="index">{{i+1}}</span>.&nbsp;
                    <p *ngIf="i !=0" class="m-0 mr-1">{{question.Q}}</p>
                    <p *ngIf="i ==0" class="m-0 mr-1">Why do I need an  interior <br> designer</p>
                  </span>
                 <img src="../../../assets/images/arrivaeHomePageImages/plusFaq.png" *ngIf="collapsehadle.indexOf(i+1) == -1"  (click)="collpase(i+1)" class=" cursorPointer" aria-hidden="true">
                  <i *ngIf="collapsehadle.indexOf(i+1) > -1" (click)="collpase(i+1)" class="fa fa-minus cursorPointer" aria-hidden="true"></i>
                  
              <!-- <img src="../../../assets/icons/+.png"> -->
              </div>
              <div id ="collpa{{i+1}}"  [ngClass]="collapsehadle.indexOf(i+1) > -1?'show':''" class="answer  ">
               <p class="p-2" id="ccpa{{i+1}}">{{question.a}}</p> 

              </div>
            </div>
          </div>
        </ng-container>

          <button [ngClass] = "newLandingPage() ? 'apply-font-weight' : '' " *ngIf="showIndex<questions.length" (click)="showmore()" class="d-md-none d-block see-more ">SEE MORE</button>

        </ng-container>
    </div>
</div>

