import { Component, OnInit } from '@angular/core';
import { LandingService } from '../landing.service';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from 'src/app/shared/shared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Slick } from 'ngx-slickjs';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-refer-a-friend',
  templateUrl: './refer-a-friend.component.html',
  styleUrls: ['./refer-a-friend.component.scss'],
})
export class ReferAFriendComponent implements OnInit {
 
  constructor(private landing : LandingService,
        private toastr: ToastrService,
        private sharedService: SharedService,
        private route:ActivatedRoute,
        private router: Router,
        private formBuilder : FormBuilder,
    ) { }
  Email:any
  form1=true
  form2=false
  referal:any
  citydata1: any = [];
  refralform:any;
  validity : boolean = true
  referralForm: any;

  email = /^[(A-Z)(a-z)0-9._%+-]+@[(A-Z)(a-z)0-9.-]+\.[(a-z)(A-Z)]{2,4}$/;

  ngOnInit(): void {

    this.refralform = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    })  
    
    this.referralForm = this.formBuilder.group({
      client_name: ['', [Validators.required]],
      contact_number: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      email_id: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}')]],
      city: ['', [Validators.required]],
      referral_code: "",
      lead_campaign: "referral",
      lead_source: "referral",
      property_name: "",
      property_type: "",
      pincode: "",
      scope_of_work: "Full Home Interiors",
      connect_whatsapp: 1
    });
  }

  validate(){
    if(this.refralform.value.email && this.refralform.value.email.match(this.email)){
      this.validity=true
    }
    else{
      this.validity=false
    }
  }

  createReferal2(){
    if(this.refralform.value.email && this.refralform.value.email.match(this.email)){
      this.landing.createreferal(this.refralform.value.email).subscribe(
        (res)=>{
          this.referal=res
          this.copyText=this.referal.result[0].url
          this.referal=this.referal.result[0].referral_code
          console.log(this.referal.result[0].referral_code)
          console.log(this.copyText)
          // this.router.navigate([], {
          //   relativeTo: this.route,
          //   queryParams: { tab: 'form2' },
          //   queryParamsHandling: 'merge'
          // }); 
  
        }
      )
      this.form1=!this.form1
      this.form2=!this.form2

      
     }
}

  showSuccessMessage(message: string): void {
    this.toastr.success(message, 'Success');
  }
  
    leaddata:any ={
    referral_code: "",
    client_name: "",
    contact_number: "",
    email_id: "",
    lead_campaign: "referral",
    lead_source: "referral",
    property_name: "",
    property_type: "",
    city: "",
    pincode: "",
    scope_of_work: "Full Home Interiors",
    connect_whatsapp: 1
  }
  response:any
  CreateLead(){

    this.leaddata.referral_code=this.referal
    this.leaddata.client_name=this.referralForm.controls.client_name.value
    this.leaddata.contact_number=this.referralForm.controls.contact_number.value
    this.leaddata.email_id=this.referralForm.controls.email_id.value
    this.leaddata.city=this.referralForm.controls.city.value

    this.landing.CreateLead(this.leaddata).subscribe((res)=>{
    this.response=res
    this.showSuccessMessage(this.response.message);
      
      this.form1=true

      this.form2=false
      this.refralform = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
      })

    })    
  }

  Filled(){
  }
 
copy="Copy"
  copyText: string = 'Text to copy';

  copyToClipboard() {
    this.copy="Copied"
    const textarea = document.createElement('textarea');
    textarea.value = this.copyText;

    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }

  roletoSet(){
    var width = Math.max(window.screen.width, window.innerWidth)
    this.checkactive();
     return width
  }

  Elements:any;
  slickIndex:any
  checkactive(){
   this.Elements = document.getElementsByClassName("mobile_image");
    let Indexofitem;
 
     for (var i = 0; i < this.Elements.length; i++) {
       
        let classes = this.Elements[i].className.split(' ')
        if(classes.includes('slick-active')) {
             Indexofitem = i;
             this.slickIndex = Indexofitem;
        }
        
   }
  }

  config: Slick.Config = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    autoplay: false,
    autoplaySpeed: 2000,
    draggable: true,
    mouseWheelMove: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {

        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll:2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


}
