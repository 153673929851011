import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutustwo',
  templateUrl: './aboutustwo.component.html',
  styleUrls: ['./aboutustwo.component.scss']
})
export class AboutustwoComponent implements OnInit {
 selectedCity:any
  constructor() { }

  ngOnInit(): void {
    this.loadScript()
  }
  centers(city:any){
    this.selectedCity = city;
    localStorage.setItem('selectedCity',this.selectedCity);
    }
    loadScript() {
      let node = document.createElement('script');
      node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
      node.async = true;
      document.getElementsByTagName('head')[0].appendChild(node);
    }
}
