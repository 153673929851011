import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BookConsultationFormComponent } from '../../shared/book-consultation-form/book-consultation-form.component';
import { LandingService } from '../landing.service';
import { Slick } from 'ngx-slickjs';
import { Router } from '@angular/router';


@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {
  reviews:any
  reviews2:any
  allreviews:any
  cities:any
  apartments:any
  city:any='';
  apartment:any=''
  size=6;
  loader:any

  constructor(private modalService: NgbModal,
    private landingService: LandingService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getreviews()
  }

  config: Slick.Config = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    centerPadding: '20%', 
    autoplaySpeed: 2000,
    draggable: true,
    mouseWheelMove: false,
    arrows: false,
    centerMode: true, 
    responsive: [
  
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '10%', 
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '10%', 
        },
      },
    ],
  };

  bookConsultationLandingPage() {
    const modal = this.modalService.open(BookConsultationFormComponent, {
      size: 'md',
    });
  }
  morereviews:any
  getreviews(){
    this.loader = true
    this.landingService.getreviews(this.city,this.apartment).subscribe((res)=>{
      const responce: any=res;
      setTimeout(()=>{
        this.loader = false
       },2000)

      this.allreviews=responce.customer_reviews
      this.morereviews=responce.more_reviews
      console.log(this.morereviews.length)
      this.cities=responce.all_cities
      this.apartments=responce.all_apartments
      this.reviews=this.allreviews.slice(0, 6)
      this.reviews2=this.allreviews.slice(0, 2)
    })

  }

  cityselected(event: any){
    console.log(event.target.value)
    this.city=event.target.value
    this.getreviews()

  }
  apartmentfilter(event :any){
    console.log(event)
    this.apartment=event.target.value
    this.getreviews()
  }

  all_apartments(){
    console.log("hello")
  }
  count=0
  addreviews(obj:any){
    if(obj){
    this.count=this.count+1
    }
    if(this.morereviews.length && this.count==1){
      for(let item of this.morereviews){
        console.log(item)
        this.allreviews.push(item)
      }
    }
    const length=this.allreviews.length
    let newreviews
    this.size=this.reviews.length

    if(length>(this.size+3)){
      newreviews=this.allreviews.slice(this.size,this.size+3)
      this.size=this.size+3
    }
    else{
      newreviews=this.allreviews.slice(this.size,length)
      this.size=length
    }
    // console.log(newreviews)

    this.reviews=this.reviews.concat(newreviews)



  }


  size2=2
  count2=0

  addreviews2(obj: any){
    if(obj){
      this.count2=this.count2+1
      }    if(this.morereviews.length && this.count2==1){
      for(let item of this.morereviews){
        console.log(item)
        this.allreviews.push(item)
        console.log(this.allreviews)
      }
    }
    const length=this.allreviews.length
    let newreviews
    this.size2=this.reviews2.length

    console.log(length,this.size2)
    if(length>(this.size2+2)){
      console.log(newreviews)
      newreviews=this.allreviews.slice(this.size2,this.size2+2)
      console.log(newreviews)

      this.size2=this.size2+2
    }
    else{
      newreviews=this.allreviews.slice(this.size2,length)
      this.size2=length
    }
    this.reviews2=this.reviews2.concat(newreviews)
  }

  navigate(id: any){
   
  }
  OpenInDeatil(id:any){
  
    window.open('/review/detail?Review_id=' + id, '_blank');
  }
  navigateAll(){
    this.router.navigate(['/Reviews'])
  }
  roletoSet(){
    var width = Math.max(window.screen.width, window.innerWidth)
     return width

  }

  clearfilters(){
    console.log("hii")
    this.city=''
    this.apartment=''
    this.getreviews()
  }

}
