<div class="fluid-container mt-n4 ">
<app-new-header-for-home ></app-new-header-for-home>
<div class="root">
   <div class="banner">
      <img [ngClass]="roletoSet() > 850?'d-block':'d-none'" src="../../../assets/images/Banner1 1.png">
      <img [ngClass]="roletoSet() < 850?'d-block':'d-none'" src="../../../assets/images/13 Video Banner 2.png">
      <img class="play_icon" src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
   </div>

   <div class="consultation  py-5 ">
      <div class="row mx-0"  >
         <div class="col-7 "  [ngClass]="roletoSet() > 850?'d-block':'d-none'">
             <h2 class=" ">
                 Our happy customers tell you why to choose Arrivae
             </h2>
         </div>
         <div class="col-5 mt-5 pt-4"  [ngClass]="roletoSet() > 850?'d-block':'d-none'">
             <button class="mt-5 button rounded-pill">BOOK A CONSULTATION</button>
         </div>
      </div>
      <div class="text-center px-3" [ngClass]="roletoSet() < 850?'d-block':'d-none'">
         <h2>
            Our happy customers tell you why to choose Arrivae
        </h2>
      </div>
      <div class="consultations mx-0 mx-md-5 ">
         <div class="row align-items-center Slider-mobile px-lg-5 m-lg-5 m-0">

            <div class=" prev" [ngClass]="roletoSet() > 850?'d-block':'d-none'">
                  <div class="other-mobile-boqs"  >
                    <button   class="mx-lg-1 button-one" (click)="slickController.prev()">
                      <img class="cursorPointer"  src="../../assets/images/sign-up-to-service/Arrow copy 2 2.png" alt="previous" >
                    </button>
                  </div> 
            </div> 

            <div class="col-12 p-0">
               <div class="Buildings_container row mx-0 mt-4">           
                  <div class="mobile-carosel-active-boqs w-100">
                     <div slickContainer #slickController="slick" [slickConfig]="config">
                       <ng-container *ngIf="slickController.initialize">
                           <div slickItem class="card p-0  m-1 cursorPointer " >
                              <a class="d-flex flex-column "href="https://web.arrivae.com/southern-crest" target="_blank">
                                 <img  class="image_carrier d-flex align-items-end" src='../../../assets/images/1.png'>
                                 <span class="imgheading">Made By The Rajvanashis</span>
                                 <img class="play_icon" [ngClass]="roletoSet() < 850?'d-block':'d-none'" src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
                              </a>
                              <div class="city pt-3">Mumbai</div>
                           </div>
                           <div slickItem class="card  p-0  m-1 cursorPointer"  >
                              <a class="d-flex flex-column "href="https://web.arrivae.com/sky-forest" target="_blank">
                                 <img class="image_carrier d-flex align-items-end" src='../../../assets/images/2.png'>
                                 <span class="imgheading">Made By <br>The Mishras </span>
                                 <img class="play_icon" [ngClass]="roletoSet() < 850?'d-block':'d-none'" src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
                              </a>
                              <div class="city pt-3">Pune</div>
                           </div>
                          
                         <div slickItem class="card  p-0  m-1  cursorPointer" >
                            <a  class="d-flex flex-column" href="https://web.arrivae.com/Tridhaatu-Morya" target="_blank">
                               <img  class="image_carrier d-flex align-items-end" src='../../../assets/images/3.png'>
                               <span class="imgheading">Made By <br>The Maheshwari's</span>
                               <img class="play_icon" [ngClass]="roletoSet() < 850?'d-block':'d-none'" src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
                              </a>
                            <div class="city pt-3">Mumbai</div>
                         </div>  

                         <div slickItem class="card  p-0  m-1 cursorPointer"   >
                           <a  class="d-flex flex-column" href="https://web.arrivae.com/Hubtown_Harmony" target="_blank">
                               <img  class="image_carrier d-flex align-items-end" src='../../../assets/images/4.png'>
                               <span class="imgheading">Made By <br>The Subramanians</span>
                               <img class="play_icon" [ngClass]="roletoSet() < 850?'d-block':'d-none'" src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
                              </a>
                              <div class="city pt-3">Bengaluru</div>
                         </div>
                         <div slickItem class="card p-0  m-1  cursorPointer" >
                            <a  class="d-flex flex-column" href="https://web.arrivae.com/Tridhaatu-Aranya" target="_blank">
                               <img  class="image_carrier d-flex align-items-end" src='../../../assets/images/aranya3.png'>
                               <span class="imgheading">Made By <br>The Rajvanashis</span>
                               <img class="play_icon" [ngClass]="roletoSet() < 850?'d-block':'d-none'" src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
                              </a>
                              <div class="city pt-3">Mumbai</div>
                           </div>              
                     </ng-container>
                   </div>
                  </div>
               </div>
            </div>

            <div class=" next" [ngClass]="roletoSet() > 850?'d-block':'d-none'">
               <div class="other-mobile-boqs"  >
                  <button  class="mx-lg-1 button-one" (click)="slickController.next()">
                    <img class="cursorPointer" src="../../assets/images/sign-up-to-service/Arrow copy 2 1.png" alt="next" >
                  </button>
               </div>
            </div>

         </div>    
      </div>
      <div [ngClass]="roletoSet() < 850?'d-block':'d-none'">
         <button class="mt-5 button rounded-pill">BOOK A CONSULTATION
            <span class="pl-2 d-md-none">
               <img src="../../../assets/images/Arrow 1.png"/>
               </span>
         </button>
      </div>
   </div>

    <div class="banner mt-2 mb-5">
      <img [ngClass]="roletoSet() > 850?'d-block':'d-none'" src="../../../assets/images/Banner 2 1.png">
      <img [ngClass]="roletoSet() < 850?'d-block':'d-none'" src="../../../assets/images/quotes 1.png">
    </div>

    <div class="testimonials" [ngClass]="roletoSet() > 850?'d-block':'d-none'">
         <div class="header text-center">
            <h2>Aapke Sapno ka Ghar </h2>
            <img src="../../../assets/images/arrivaeHomePageImages/Ho jayega logo 1.png">
         </div>

            <div class="testimonial1 row mx-0 pt-md-5 pb-0" style="background-color: #F4F4F4;">
               <div class="col-7 image mb-n4">
                  <img class="img-fluid" src="../../../assets/images/6 1.png">
                  <img class="play_icon" src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
               </div>
               <div class="col-5 descript ">
                  <h2>A Tour of Spanish Villa in  Gurugram</h2>
                  <span>Made By Thakur's, Executed by Us</span>
                  </div>
               </div>

            <div class="testimonial1 row mx-0 p-md-5 text-right"   >
               <div class="col-5 descript " style="align-items: end;">
                  <h2>Made By Bhardawaj’s  </h2>
                  <span>Bengaluru</span>
                  <p>“We are very, very happy the way Arrivae has done the job, the timely delivery and as well as the cooperation throughout the project! It was entirely a beautiful journey for us. We never felt that we have been doing such a great change in the house.”</p>
               </div>
               <div class="col-7 " style="justify-content: left;">
                  <div class="image">
                     <img class="img-fluid" src="../../../assets/images/6.png">
                     <img class="play_icon" (click)="playvideo()" src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
                  </div>
               </div>
            </div>

            <div class="testimonial1 row mx-0  p-md-5"  style="background-color: #F4F4F4;">
               <div class="col-7 ">
                  <div class="image">
                     <img class="img-fluid" src="../../../assets/images/7.png">
                     <img class="play_icon" src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
                  </div>
               </div>
               <div class="col-5 descript ">
                  <h2>Made By The Rajvanashis</h2>
                  <span>Bengaluru</span>
                  <p>Elegant and functional designs on a budget?<br> 
                     Ho Jayega! In this chapter of #HappyHandovers, take a look at the Rajvanashi's Mumbai home.</p>
               </div>
               </div>
            <div class="testimonial1 row mx-0  p-md-5 text-right"  >
               <div class="col-5 descript " style="align-items: end;">
                  <h2>Made By The Sabus</h2>
                  <span>Bengaluru</span>
                  <p>In addition of #HappyHandovers, come explore the beautiful home we created in #Malad, Mumbai for Mrs Reena Sabu's lovely family. </p>
               </div>
               <div class="col-7 " style="justify-content: left;">
                  <div class="image">
                     <img class="img-fluid" src="../../../assets/images/8.png">
                     <img class="play_icon" src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
                  </div>   
               </div>
            </div>
        
        <button class="d-flex button rounded-pill mb-5"> LOAD MORE</button>
        

    </div>
    <div class="testimonials " [ngClass]="roletoSet() < 850?'d-block':'d-none'" >
         <div class="header text-center">
            <h2>Aapke Sapno ka Ghar </h2>
            <img src="../../../assets/images/arrivaeHomePageImages/Ho jayega logo 1.png">
         </div>

            <div class="testimonial1 row mx-0 pb-0">
               <div class=" image">
                  <img class="img-fluid" src="../../../assets/images/6 1.png">
                  <img class="play_icon" src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
               </div>
               <div class=" descript p-3">
                  <h2>A Tour of Spanish Villa in  Gurugram</h2>
                  <span>Made By Thakur's, Executed by Us</span>
               </div>
            </div>

            <div class="testimonial1 row mx-0 " >
               
               <div class=" ">
                  <div class="image">
                     <img class="img-fluid" src="../../../assets/images/6.png">
                     <img class="play_icon" (click)="playvideo()" src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
                  </div>
               </div>
               <div class=" descript p-3" >
                  <h2>Made By Bhardawaj’s  </h2>
                  <span>Bengaluru</span>
                  <p>“We are very, very happy the way Arrivae has done the job, the timely delivery and as well as the cooperation throughout the project! It was entirely a beautiful journey for us. We never felt that we have been doing such a great change in the house.”</p>
               </div>
            </div>

            <div class="testimonial1 row mx-0" >
               <div class=" ">
                  <div class="image">
                     <img class="img-fluid" src="../../../assets/images/7.png">
                     <img class="play_icon" src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
                  </div>
               </div>
               <div class=" descript p-3">
                  <h2>Made By The Rajvanashis</h2>
                  <span>Bengaluru</span>
                  <p>Elegant and functional designs on a budget?<br> 
                     Ho Jayega! In this chapter of #HappyHandovers, take a look at the Rajvanashi's Mumbai home.</p>
               </div>
            </div>
            <div class="testimonial1 row mx-0 "  >
               
               <div class=" " >
                  <div class="image">
                     <img class="img-fluid" src="../../../assets/images/8.png">
                     <img class="play_icon" src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
                  </div>   
               </div>
               <div class="descript p-3" >
                  <h2>Made By The Sabus</h2>
                  <span>Bengaluru</span>
                  <p>In addition of #HappyHandovers, come explore the beautiful home we created in #Malad, Mumbai for Mrs Reena Sabu's lovely family. </p>
               </div>
            </div>
        <button class="d-flex button rounded-pill mb-5"> LOAD MORE
         <span class="pl-2 d-md-none">
            <img src="../../../assets/images/Arrow 1.png"/>
            </span>
        </button>
    </div>

    <div class="banner mb-3">
      <img [ngClass]="roletoSet() > 850?'d-block':'d-none'" src="../../../assets/images/9 1.png">
      <img [ngClass]="roletoSet() < 850?'d-block':'d-none'" src="../../../assets/images/9 12.png">
      <img class="play_icon" src="../../../assets/images/youtubeVideos/Play icon 1 (1).png"/>
   </div>


    <app-footer></app-footer>

</div>
</div>