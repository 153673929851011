<div class="footer">
    <div class="row main-row">
        <div class="address col-md-12 col-lg-3">
            <div class="logo">
                <img src="../../../assets/images/Arrivae-Logo-Footer .png" alt="logo">
            </div>
            <div class="contacts mt-3">
                <p>B 501/502, Everest House, Suren Road, <br>Gundavali, Andheri East, Mumbai,<br> Maharashtra 400093</p>
                <p>Customer care : <a href="tel:18001230680" style="color: black; text-decoration: none;">1800 123
                        0680</a></p>
                <p>E-mail ID : <a href="mailto:wecare@arrivae.com"
                        style="color: black; text-decoration: none;">wecare@arrivae.com</a></p>
                <p>Timings: 09:00 AM to 09:00 PM</p>
                <div class="social-links">
                    <a href="https://www.instagram.com/arrivae/?hl=en" target="_blank"><img
                            src="../../../assets/images/icons/instagram-line.svg" alt="instagram-line"></a>
                    <a href="https://www.facebook.com/Arrivae/" target="_blank"><img
                            src="../../../assets/images/icons/facebook-circle-fill.svg" alt="facebook-circle-fill"></a>
                    <a href="https://www.pinterest.com/arrivaecom/" target="_blank"><img
                            src="../../../assets/images/icons/pinterest-line.svg" alt="pinterest-line"></a>
                    <a href="https://www.linkedin.com/company/13439634/" target="_blank"><img
                            src="../../../assets/images/icons/linkedin-box-fill.svg" alt="linkedin-box-fill"></a>
                </div>
            </div>
        </div>

        <div class="links col-md-12 col-lg-9">
            <div class="row sub-row">
                <div class="col-md-4 col-links-container">
                    <h5 class="heading collapsed headingFooterLinks" data-toggle="collapse"
                        href="#multiCollapseQuickLinks" role="button" aria-expanded="false"
                        aria-controls="multiCollapseQuickLinks">
                        Quick links
                        <span data-toggle="collapse" href="#multiCollapseQuickLinks" role="button" aria-expanded="false"
                            aria-controls="multiCollapseQuickLinks"><img src="../../../assets/images/add-line.svg"
                                alt="add-line"></span>
                    </h5>
                    <div class="footerCollapseElements collapse multi-collapse show" id="multiCollapseQuickLinks">
                        <div class="col-links">
                            <a routerLink="/spaces" target="_blank">Interior Design Spaces</a>
                            <a href="../../../assets/images/arrivae_kitchen_buying_guide.pdf" target="_blank">Kitchen
                                Guide</a>
                            <a href="https://arrivae.com/become_a_design_partner" target="_blank">Become a design
                                partner </a>
                            <a href="https://arrivae.com/become_a_manufacturer" target="_blank">Become a vendor
                                partner</a>
                            <a href="../../../assets/images/Customisation_booklet_A4.pdf" target="_blank">Design
                                inspiration</a>
                            <a href="https://blog.arrivae.com/" target="_blank">Blog</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-links-container">
                    <h5 class="heading collapsed headingFooterLinks" data-toggle="collapse"
                        href="#multiCollapseAboutLinks" role="button" aria-expanded="false"
                        aria-controls="multiCollapseAboutLinks">
                        Company
                        <span data-toggle="collapse" href="#multiCollapseAboutLinks" role="button" aria-expanded="false"
                            aria-controls="multiCollapseAboutLinks"><img src="../../../assets/images/add-line.svg"
                                alt="add-line"></span>
                    </h5>
                    <div class="footerCollapseElements collapse multi-collapse show" id="multiCollapseAboutLinks">
                        <div class="col-links">
                            <a href="https://arrivae.com/arrivae_stories" target="_blank">About Us</a>
                            <a href="https://arrivae.com/media_engagements" target="_blank">Arrivae in media</a>
                            <a href="https://arrivae.com/made_by_you_stories" target="_blank">Made by you stories</a>
                            <a href="/terms-of-use" target="_blank">Terms of use</a>
                            <a href="/terms-and-conditions" target="_blank">Terms and conditions</a>
                            <a href="/privacy-policy" target="_blank">Privacy policy</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-links-container">
                    <h5 class="heading collapsed headingFooterLinks" data-toggle="collapse"
                        href="#multiCollapseExperienceCentres" role="button" aria-expanded="false"
                        aria-controls="multiCollapseExperienceCentres">
                        Experience Centres
                        <span data-toggle="collapse" href="#multiCollapseExperienceCentres" role="button"
                            aria-expanded="false" aria-controls="multiCollapseExperienceCentres"><img
                                src="../../../assets/images/add-line.svg" alt="add-line"></span>
                    </h5>
                    <div class="footerCollapseElements collapse multi-collapse show"
                        id="multiCollapseExperienceCentres">
                        <div class="row">
                            <div class="col-6 col-links">
                                <a href="/visit-us" target="_blank" (click)="centers('mumbai')">Mumbai</a>
                                <a href="/visit-us" target="_blank" (click)="centers('Delhi')">Delhi</a>
                                <a href="/visit-us" target="_blank" (click)="centers('Pune')">Pune</a>
                                <a href="/visit-us" target="_blank" (click)="centers('Bangalore')">Bengaluru</a>
                                <a href="/visit-us" target="_blank" (click)="centers('Chandigarh')">Chandigarh</a>
                                <a href="/visit-us" target="_blank" (click)="centers('Thane')">Thane</a>
                            </div>
                            <div class="col-6 col-links">
                                <a href="/visit-us" target="_blank" (click)="centers('Gurgaon')">Gurgaon</a>
                                <a href="/visit-us" target="_blank" (click)="centers('Noida')">Noida</a>
                                <a href="/visit-us" target="_blank" (click)="centers('Jaipur')">Jaipur</a>
                                <a href="/visit-us" target="_blank" (click)="centers('Kochi')">Kochi </a>
                                <a href="/visit-us" target="_blank" (click)="centers('Trivandrum')">Trivandrum</a>
                                <a href="/visit-us" target="_blank" (click)="centers('Hyderabad')">Hyderabad</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row sub-row">
                <div class="col-md-4 col-links-container">
                    <h5 class="heading collapsed headingFooterLinks" data-toggle="collapse"
                        href="#multiCollapseModularKitchen" role="button" aria-expanded="false"
                        aria-controls="multiCollapseModularKitchen">
                        Modular Kitchen
                        <span data-toggle="collapse" href="#multiCollapseModularKitchen" role="button"
                            aria-expanded="false" aria-controls="multiCollapseModularKitchen"><img
                                src="../../../assets/images/add-line.svg" alt="add-line"></span>
                    </h5>
                    <div class="footerCollapseElements collapse multi-collapse show" id="multiCollapseModularKitchen">
                        <div class="col-links">
                            <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}"
                                target="_blank">Modular kitchen in Bengaluru</a>
                            <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}"
                                target="_blank">Modular kitchen in Coimbatore</a>
                            <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}"
                                target="_blank">Modular kitchen in Delhi</a>
                            <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}"
                                target="_blank">Modular kitchen in Pune</a>
                            <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}"
                                target="_blank">Modular kitchen in Mumbai</a>
                            <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}"
                                target="_blank">Modular kitchen in Kolkata</a>
                            <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}"
                                target="_blank">Modular kitchen in Hyderabad</a>
                            <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}"
                                target="_blank">Modular kitchen in Chennai</a>
                            <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}"
                                target="_blank">Modular kitchen in Vijaywada</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-links-container">
                    <h5 class="heading collapsed headingFooterLinks" data-toggle="collapse"
                        href="#multiCollapseWardrobeDesign" role="button" aria-expanded="false"
                        aria-controls="multiCollapseWardrobeDesign">
                        Wardrobe Design
                        <span data-toggle="collapse" href="#multiCollapseWardrobeDesign" role="button"
                            aria-expanded="false" aria-controls="multiCollapseWardrobeDesign"><img
                                src="../../../assets/images/add-line.svg" alt="add-line"></span>
                    </h5>
                    <div class="footerCollapseElements collapse multi-collapse show" id="multiCollapseWardrobeDesign">
                        <div class="col-links">
                            <a routerLink="/spaces" target="_blank">Wardrobe design in Bengaluru</a>
                            <a routerLink="/spaces" target="_blank">Wardrobe design in Coimbatore</a>
                            <a routerLink="/spaces" target="_blank">Wardrobe design in Delhi</a>
                            <a routerLink="/spaces" target="_blank">Wardrobe design in Pune</a>
                            <a routerLink="/spaces" target="_blank">Wardrobe design in Mumbai</a>
                            <a routerLink="/spaces" target="_blank">Wardrobe design in Kolkata</a>
                            <a routerLink="/spaces" target="_blank">Wardrobe design in Hyderabad</a>
                            <a routerLink="/spaces" target="_blank">Wardrobe design in Chennai</a>
                            <a routerLink="/spaces" target="_blank">Wardrobe design in Vijaywada</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-links-container">
                    <h5 class="heading collapsed headingFooterLinks" data-toggle="collapse"
                        href="#multiCollapseApartmentsWeCover" role="button" aria-expanded="false"
                        aria-controls="multiCollapseApartmentsWeCover">
                        Apartments we cover
                        <span data-toggle="collapse" href="#multiCollapseApartmentsWeCover" role="button"
                            aria-expanded="false" aria-controls="multiCollapseApartmentsWeCover"><img
                                src="../../../assets/images/add-line.svg" alt="add-line"></span>
                    </h5>
                    <div class="footerCollapseElements collapse multi-collapse show"
                        id="multiCollapseApartmentsWeCover">
                        <div class="col-links">
                            <a routerLink="/apartment" target="_blank">Apartments design in Bengaluru</a>
                            <a routerLink="/apartment" target="_blank">Apartments design in Coimbatore</a>
                            <a routerLink="/apartment" target="_blank">Apartments design in Delhi</a>
                            <a routerLink="/apartment" target="_blank">Apartments design in Pune</a>
                            <a routerLink="/apartment" target="_blank">Apartments design in Mumbai</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="links-mobile col-md-12">
            <div id="accordion" class="accordion">
                <div class="links-card">
                    <div class="card-header collapsed" data-toggle="collapse" href="#collapseOne">
                        <a class="card-title">
                            Quick links
                        </a>
                    </div>
                    <div id="collapseOne" class="card-body collapse" data-parent="#accordion" >
                        <div class="links-container">
                            <a routerLink="/spaces" target="_blank">Interior Design Spaces</a>
                            <a href="../../../assets/images/arrivae_kitchen_buying_guide.pdf" target="_blank">Kitchen
                                Guide</a>
                            <a href="https://arrivae.com/become_a_design_partner" target="_blank">Become a design
                                partner </a>
                            <a href="https://arrivae.com/become_a_manufacturer" target="_blank">Become a vendor
                                partner</a>
                            <a href="../../../assets/images/Customisation_booklet_A4.pdf" target="_blank">Design
                                inspiration</a>
                            <a href="https://blog.arrivae.com/" target="_blank">Blog</a>
                        </div>
                    </div>
                </div>
                <div class="links-card">
                    <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                        <a class="card-title">
                            Company
                        </a>
                    </div>
                    <div id="collapseTwo" class="card-body collapse" data-parent="#accordion" >
                        <div class="links-container">
                            <a href="https://arrivae.com/arrivae_stories" target="_blank">About Us</a>
                            <a href="https://arrivae.com/media_engagements" target="_blank">Arrivae in media</a>
                            <a href="https://arrivae.com/made_by_you_stories" target="_blank">Made by you stories</a>
                            <a href="/terms-of-use" target="_blank">Terms of use</a>
                            <a href="/terms-and-conditions" target="_blank">Terms and conditions</a>
                            <a href="/privacy-policy" target="_blank">Privacy policy</a>
                        </div>
                    </div>
                </div>
                <div class="links-card">
                    <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
                        <a class="card-title">
                            Experience Centres
                        </a>
                    </div>
                    <div id="collapseThree" class="card-body collapse" data-parent="#accordion" >
                        <div class="links-container">
                            <div class="row">
                                <div class="col-6 col-links">
                                    <a href="/visit-us" target="_blank" (click)="centers('mumbai')">Mumbai</a>
                                    <a href="/visit-us" target="_blank" (click)="centers('Delhi')">Delhi</a>
                                    <a href="/visit-us" target="_blank" (click)="centers('Pune')">Pune</a>
                                    <a href="/visit-us" target="_blank" (click)="centers('Bangalore')">Bengaluru</a>
                                    <a href="/visit-us" target="_blank" (click)="centers('Chandigarh')">Chandigarh</a>
                                    <a href="/visit-us" target="_blank" (click)="centers('Thane')">Thane</a>
                                </div>
                                <div class="col-6 col-links">
                                    <a href="/visit-us" target="_blank" (click)="centers('Gurgaon')">Gurgaon</a>
                                    <a href="/visit-us" target="_blank" (click)="centers('Noida')">Noida</a>
                                    <a href="/visit-us" target="_blank" (click)="centers('Jaipur')">Jaipur</a>
                                    <a href="/visit-us" target="_blank" (click)="centers('Kochi')">Kochi </a>
                                    <a href="/visit-us" target="_blank" (click)="centers('Trivandrum')">Trivandrum</a>
                                    <a href="/visit-us" target="_blank" (click)="centers('Hyderabad')">Hyderabad</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="links-card">
                    <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFour">
                        <a class="card-title">
                            Modular Kitchen
                        </a>
                    </div>
                    <div id="collapseFour" class="card-body collapse" data-parent="#accordion" >
                        <div class="links-container">
                            <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}"
                            target="_blank">Modular kitchen in Bengaluru</a>
                            <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}"
                                target="_blank">Modular kitchen in Coimbatore</a>
                            <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}"
                                target="_blank">Modular kitchen in Delhi</a>
                            <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}"
                                target="_blank">Modular kitchen in Pune</a>
                            <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}"
                                target="_blank">Modular kitchen in Mumbai</a>
                            <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}"
                                target="_blank">Modular kitchen in Kolkata</a>
                            <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}"
                                target="_blank">Modular kitchen in Hyderabad</a>
                            <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}"
                                target="_blank">Modular kitchen in Chennai</a>
                            <a [routerLink]="['/spaces']" [queryParams]="{space_name:'Kitchen', page:'1'}"
                                target="_blank">Modular kitchen in Vijaywada</a>
                        </div>
                    </div>
                </div>
                <div class="links-card">
                    <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFive">
                        <a class="card-title">
                            Wardrobe Design
                        </a>
                    </div>
                    <div id="collapseFive" class="card-body collapse" data-parent="#accordion" >
                        <div class="links-container">
                            <a routerLink="/spaces" target="_blank">Wardrobe design in Bengaluru</a>
                            <a routerLink="/spaces" target="_blank">Wardrobe design in Coimbatore</a>
                            <a routerLink="/spaces" target="_blank">Wardrobe design in Delhi</a>
                            <a routerLink="/spaces" target="_blank">Wardrobe design in Pune</a>
                            <a routerLink="/spaces" target="_blank">Wardrobe design in Mumbai</a>
                            <a routerLink="/spaces" target="_blank">Wardrobe design in Kolkata</a>
                            <a routerLink="/spaces" target="_blank">Wardrobe design in Hyderabad</a>
                            <a routerLink="/spaces" target="_blank">Wardrobe design in Chennai</a>
                            <a routerLink="/spaces" target="_blank">Wardrobe design in Vijaywada</a>
                        </div>
                    </div>
                </div>
                <div class="links-card">
                    <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseSix">
                        <a class="card-title">
                            Apartments we cover
                        </a>
                    </div>
                    <div id="collapseSix" class="collapse" data-parent="#accordion" >
                        <div class="card-body">
                            <div class="links-container">
                                <a routerLink="/apartment" target="_blank">Apartments design in Bengaluru</a>
                                <a routerLink="/apartment" target="_blank">Apartments design in Coimbatore</a>
                                <a routerLink="/apartment" target="_blank">Apartments design in Delhi</a>
                                <a routerLink="/apartment" target="_blank">Apartments design in Pune</a>
                                <a routerLink="/apartment" target="_blank">Apartments design in Mumbai</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>