import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { LandingService } from '../landing.service';
import { ToastrService } from 'ngx-toastr';
import { Meta } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MadeByStoryViewData, MadeByYouStoryData } from '../data';
import { MadeByStoriesFirst } from '../data';
import { MadeByYouStories } from '../data';
import { ActivatedRoute } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-made-by-you-detail',
  templateUrl: './made-by-you-detail.component.html',
  styleUrls: ['./made-by-you-detail.component.scss'],
})
export class MadeByYouDetailComponent implements OnInit {
  selectedCity: any;
  cityData: any;
  citiesData: any;
  city_id: any;
  points: any;
  experience: any;
  linksActive: any;
  multiCenters: any;
  singleCenters: any;
  tokenForMagic: any;
  loader: boolean = false;
  accessToken: any;
  url: any;
  closeAndBack: boolean = false;
  @Input() verifyUser: any;
  mobileNumber: any;
  leadInvitationId: any;
  enteredOptNumber: any;
  data: any;

  nextFirst(quote: any, submit: any) {
    $('#' + quote).show();
    $('#' + submit).hide();
  }

  constructor(
    private landingService: LandingService,
    private router: Router,
    private toastr: ToastrService,
    private metaService: Meta,
    private cdRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private route: ActivatedRoute
  ) {}

  madebyyouModal() {
    // this.loader = true;
    if (
      $('#made-by-you-container').hasClass('made-by-you-container-header-show')
    ) {
      $('#madebyyouModal').modal('hide');
      $('#made-by-you-container').removeClass(
        'made-by-you-container-header-show'
      );
    } else {
      $('#madebyyouModal').modal('show');
      $('#made-by-you-container').addClass('made-by-you-container-header-show');
    }
    this.setSecondNumber = this.data;
    this.closeAndBack = true;
    this.image_container =
      this.ViewNextContent[this.ViewNextContentIndex].images;
  }
  setSecondNumber: any;

  closesecondReset() {
    this.verifyUser = !this.verifyUser;
    $('#madebyyouHeader').modal('hide');
    this.nextFirst('GetQuote', 'querySubmit');
    this.mobileNumber = '';
  }

  ngOnInit(): void {
    this.initSlickSlide();
    this.loadScript();
    $('.numberCountUp').counterUp({ delay: 10, time: 1000 });
    this.url = localStorage.getItem('loginuser');
    this.accessToken = localStorage.getItem('access-token')
      ? localStorage.getItem('access-token')
      : '';
    localStorage.setItem('logintoken', this.url);
    let tokenMagic = this.url.split('=');
    this.tokenForMagic = tokenMagic[1];

    this.ViewNextContentIndex = this.route.snapshot.queryParams['id'];
    this.ViewNextContentNextName =
      this.ViewNextContent[parseInt(this.ViewNextContentIndex) + 1]?.heading;
    this.ViewNextContentPreviousName =
      this.ViewNextContent[parseInt(this.ViewNextContentIndex) - 1]?.heading;
    this.fetchPoints();
    this.magicLogin();
    this.madebyyouModal();
    this.animateIntroText();
    this.animateStatsPanel();

    $('#getQuoteModal').on('hide.bs.modal', function () {
      if ($('#made-by-you-container').hasClass('made-by-you-container-show')) {
        $('#made-by-you-container').removeClass('made-by-you-container-show');
        $('#made-by-you-container').modal('hide');
      }
    });
    this.metaService.addTag({
      name: 'description',
      content:
        'End-to-end Full Home Interior Solutions & India’s first Made-To-Order Furniture Manufacturing Company. PAN India Presence in 21 Cities | 5 Experience Centers | 3 Manufacturing Units | 35 Experience Points | Robust Aftersales Support',
    });
    this.loadScript();
  }
  cname: any;

  ngOnDestroy() {
    this.metaService.removeTag("name='description'");
  }

  magicLogin() {
    this.landingService.Magiclogin(this.tokenForMagic).subscribe((res) => {
      this.cname = res;
      this.loader = true;
      setTimeout(() => {
        this, (this.loader = false);
        this.router.navigate(['/customer-dashboard']);
      }, 4000);

      localStorage.setItem('cname', this.cname.user.name);
    });
  }

  fetchPoints() {
    if (localStorage.getItem('selectedCity') == null) {
      this.selectedCity = 'mumbai';
    } else {
      this.selectedCity = localStorage.getItem('selectedCity');
    }
    this.landingService.getCities().subscribe((data) => {
      this.cityData = data;
      this.citiesData = this.cityData.data;
      for (let ct of this.citiesData) {
        if (ct.attributes.name == this.selectedCity) {
          this.city_id = ct.id;
        }
      }

      this.landingService.getpoints(this.city_id).subscribe((res) => {
        if (res) {
          this.points = res;
          this.experience = this.points.data;
          if (this.experience.length > 1) {
            this.multiCenters = true;
            this.singleCenters = false;
          }
          if (this.experience.length == 1) {
            this.singleCenters = true;
            this.multiCenters = false;
          }
        }
      });
    });
  }

  customFunc(data: any) {
    this.selectedCity = data.selectedCity;
    this.fetchPoints();
  }

  animateIntroText() {
    setTimeout(() => {
      $('#intro-text').addClass('intro-text-show');
    }, 500);
  }

  animateStatsPanel() {
    setTimeout(() => {
      $('#stats-panel').addClass('stats-panel-show');
    }, 500);
  }

  getaquoteShowHide() {
    if ($('#get-quote-container').hasClass('get-quote-container-show')) {
      $('#getQuoteModal').modal('hide');
      $('#get-quote-container').removeClass('get-quote-container-show');
    } else {
      $('#getQuoteModal').modal('show');
      $('#get-quote-container').addClass('get-quote-container-show');
    }
  }

  quoteModalShowHide() {
    if ($('#made-by-you-container').hasClass('made-by-you-container-show')) {
      $('#madebyyouModal').modal('hide');
      $('#made-by-you-container').removeClass('made-by-you-container-show');
    } else {
      $('#madebyyouModal').modal('show');
      $('#made-by-you-container').addClass('made-by-you-container-show');
    }
  }

  boolvalue: boolean = false;
  l_lead_id: any;
  namephn: any;
  imageObject: any = MadeByYouStoryData;
  ViewNextContent: any = MadeByStoryViewData;
  ViewContent: any = MadeByStoriesFirst;
  custData: any = MadeByYouStories;
  images: any = MadeByStoriesFirst;
  imageUrl: any = MadeByYouStoryData;

  ViewNextContentIndex: any;
  ViewNextContentNextName: any = this.ViewNextContent[1].heading;
  ViewNextContentPreviousName: any =
    this.ViewNextContent[this.ViewNextContent.length - 1].heading;
  active_slide: any = 0;

  initSlickSlide() {
    $(document).ready(function () {
      $('.carousel-class').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        arrows: true,
        nextArrow: $('#next-carousel-button'),
        prevArrow: $('#previous-carousel-button'),
      });
    });
    $('.carousel-class').on(
      'afterChange',
      (event: any, slick: any, currentSlide: any, nextSlide: any) => {
        var current = currentSlide;
        this.active_slide = current;
        this.cdRef.detectChanges();
      }
    );
    $(document).ready(function () {
      $('.images-class').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: $('#next-images-button'),
        prevArrow: $('#previous-images-button'),
        autoplay: true,
      });
    });
    $('.images-class').on(
      'afterChange',
      (event: any, slick: any, currentSlide: any, nextSlide: any) => {
        var current = currentSlide;
        this.active_slide = current;
        this.cdRef.detectChanges();
      }
    );
  }

  loadScript() {
    let node = document.createElement('script');
    node.src = 'https://www.googleoptimize.com/optimize.js?id=OPT-T99NZVW';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  prevSlider() {
    window.scrollTo(0,0);
    // this.ViewNextContentIndex = this.ViewNextContentIndex - 1;
    if (this.ViewNextContentIndex == 1) {
      this.ViewNextContentIndex--;
      // this.ViewNextContentIndex = this.ViewNextContent.length - 1;
      // -------
      this.ViewNextContentNextName = this.ViewNextContent[1].heading;
      this.ViewNextContentPreviousName =
        this.ViewNextContent[this.ViewNextContent.length - 1].heading;
    } else {
      this.ViewNextContentIndex--;
      // -------
      this.ViewNextContentPreviousName =
        this.ViewNextContent[this.ViewNextContentIndex - 1].heading;
      this.ViewNextContentNextName =
        this.ViewNextContent[
          this.ViewNextContentIndex + 1 == 8 ? 0 : this.ViewNextContentIndex + 1
        ].heading;
      this.image_container =
        this.ViewNextContent[this.ViewNextContentIndex].images;
    }
  }

  image_container: any = [];
  nextSlider() {
    window.scrollTo(0,0);
    // this.ViewNextContentIndex = this.ViewNextContentIndex + 1;
    if (this.ViewNextContentIndex === this.ViewNextContent.length - 1) {
      this.ViewNextContentIndex = 0;
      // -------
      this.ViewNextContentNextName = this.ViewNextContent[0].heading;
      this.ViewNextContentPreviousName =
        this.ViewNextContent[this.ViewNextContent.length - 1].heading;
    } else {
      this.ViewNextContentIndex++;
      // -------
      this.ViewNextContentNextName =
        this.ViewNextContent[
          this.ViewNextContentIndex + 1 == 8
            ? this.ViewNextContentIndex
            : this.ViewNextContentIndex + 1
        ].heading;
      this.ViewNextContentPreviousName =
        this.ViewNextContent[this.ViewNextContentIndex - 1].heading;
      this.image_container =
        this.ViewNextContent[this.ViewNextContentIndex].images;
    }
  }

  next_images: any = MadeByStoriesFirst;

}
